import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { search_items } from 'src/mesbro/modules/shared/generic-search/search';
import { faq_items } from 'src/mesbro/modules/shared/generic-search/search';
import { number_items } from 'src/mesbro/modules/shared/generic-search/search';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'm-web-search-context',
  templateUrl: './web-search-context.component.html',
  styles: [
  ]
})
export class WebSearchContextComponent implements OnInit {
  params: any = {
    application: undefined,
    query : '',
  }
  search_items = search_items;
  faq_items = faq_items;
  number_items = number_items;
  is_visible = {
    faq_answer:false
  }
  constructor( public gs : GlobalService, public ar : ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe( (params : any) =>{
      this.params= params;
    })
  }

}
