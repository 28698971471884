import { Component, OnInit } from '@angular/core';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-contact-list',
  templateUrl: './generic-contact-list.component.html',
  styles: [
  ]
})
export class GenericContactListComponent implements OnInit {

  users: IUserData;
  list:any={
    list:[],
    count:0
  }

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
      })   
    this.getContactList()
  }

  async getContactList(){
    let body = { 
      parent_id :this.users?.activeUser?._id
    }
    this.list = await this.gs.hps.post('idm','contacts','list',body);
  }

}
