<div minHeight='calc(100vh - 166px)' display="flex" justify="center" alignItems="center" a-style>
    <div w="70" h="36" a-style>
        <div a-style w="33.6" h="33.6" display="flex" position="relative" justify="flex-end" flexDirection="column" alignItems="center" brRadius="50%" bg="#f2f5f8" zIndex="-1">
            <div a-style display="flex" gap="2" position="absolute" left="10" top="11">
                <m-image a-style [src]="gs.enums.images.image37"> </m-image>
                <div a-style w="56" h="27.2" align="left">
                    <m-text [type]="gs.enums.texts.paragraph60" text="401"></m-text>
                    <m-text [type]="gs.enums.texts.heading1" text="Oops! Page Not Be Found"></m-text>
                    <m-text [type]="gs.enums.texts.paragraph12" textWrap="Wrap" my="1" text="Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable"></m-text>
                    <m-text [type]="gs.enums.texts.paragraph59" text="Back to homepage"></m-text>
                </div>
            </div>
        </div>
    </div>
</div>