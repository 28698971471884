<div a-style [p]="padwithBorder?1.6:0" w="100%" [br]="padwithBorder?'1px solid #E4E4E4':'unset'" [brRadius]="brRadius?brRadius:0.6" [h]="height" w="100%" h="23.5" title=""  *ngIf="params.type!='Products' && params.type!='Organizations' && params.type!='my-products' && params.type!='Open' && params.type!='Closed' && !gs.router.url.includes('product/category')">
    <!-- <div a-style [h]="h"> -->
    <div a-style [bgUrl]="image" bgRepeat="none" bgSize="cover" position="relative" bgPosition="center" [p]="p?p:2" [h]="h || '100%'" [brRadius]="brRadius?brRadius:0.6" display=flex justify=center align=center>
        <!-- [py]="py?py:1" -->
        <div a-style [maxWidth]="maxWidth" [mt]="mt">
            <!-- *ngIf="type =='card_7'" -->
            <div a-style *ngIf="type == 'main'">
                <div a-style (click)="filter.openModal()">
                    <img a-style w="2" h="2" top="2" position="absolute" cursor="pointer" left="79.8" align="center" src="../assets/images/assets/filter-icon.svg">
                </div>
            </div>
            <!-- 3;1336:2.6 -->
            <!-- <h2 *ngIf="title" a-style fs="3;1336:2.6" p=0 color="#ffffff" m="0" align="center" [fw]="fw"> {{title |  titlecase }}</h2>
            <p *ngIf="description" a-style fs="1.3;1366:0.9" [my]="my?my:1.2" color="#ffffff" align="center" maxWidth="76.6;1366:54.5"> {{description}} </p>
            <m-button *ngIf="emailCollection" [types]="gs.enums.buttons.button63" [text]="btn==false?'Unsubscribe':'Subscribe'" br="1px solid white" (click)="btn=!btn;submit()" [title]="btn==false?'Unsubscribe':'Subscribe'" align="center">
            </m-button> -->

            <!-- <form [formGroup]="form">
                <m-form [brNone]=false [paddingNone]=false bg="transparent">
                    <div *ngIf="emailCollection" a-style display="flex" gap="1.6" justify="center" w="100%"
                        align="center">
                        <m-input formControlName="email" placeHolder="Enter Email Address" px="1" maxWidth="38"
                            color='#ffffff' mb="0"></m-input>
                        <m-button [types]="gs.enums.buttons.button63" text="Subscribe" br="1px solid white"
                            (click)="submit()" [disable]="form?.invalid" title="Subscribe"></m-button>
                    </div>
                </m-form>
            </form> -->
            <!-- <button a-style br="1px solid #ffffff" h="3.9" w="10.4" px="2" py="1" ml="1.6" brRadius="0.4" fs="1.3" color="#ffffff" bg="transparent" shadow="hov:0px 5px 5px 0px #747474cf" bg="transparent;hov:#727272" cursor=pointer> Subscribe </button> -->
            <!-- <m-button [types]="gs.enums.buttons.button63" text="Subscribe" br="1px solid #2479AB"
            (click)="submit()"  title="Subscribe"></m-button> -->
        </div>
    </div>
</div>
<div>
    <m-modal #filter width="940px">
        <m-filter></m-filter>
    </m-modal>
</div>