import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-socialmedia-post',
  templateUrl: './socialmedia-post.component.html',
  styles: [
  ]
})
export class SocialmediaPostComponent implements OnInit {
  @ViewChild('post_model') post_modal : any;
  socialContext:boolean=false
  user: any
  comment:boolean=false
  users: IUserData | undefined;
  data:any
  @Output() activity = new EventEmitter();
  @Input() detail: any;
  @Input() activities: any;
  @Input() details: any;
  @Input() type: any;
  @Input() modal: any;
  is_visible: boolean = false;
  is_liked: boolean = false;
  isVisible_bookmark: boolean = false;
  // @Input() user:any;
  Text: boolean = false;

  params: any = {
    type: '',
    id: ''
  }
  list: any = {
    list: [],
    count: 0
  }
  contextmenu:any
  is_comment: any = {};
  post_comment: any
  application = 'Generic';
  component = 'Social';
  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) {
    this.gs.user.users$.subscribe(users => {
      this.users = users;
      // console.log(this.users);
    })
    // this.gs.$country.subscribe(async (item: any) => {
    //   this.country = await item;

    // })

  }

  async ngOnInit() {

    this.ar.queryParams.subscribe(async (param: any) => {
      this.params = param
      await this.get_post()
    })

    this.gs.user.users$.subscribe((users: IUserData) => {
      this.user = users.activeUser
      // console.log(this.user);

    })

    this.gs.$post.subscribe((post: any) => {
      this.list.list.unshift(post)
      console.log(this.user);

    })
// const contextmenu = document.getElementById('contextMenu')
// const verti = document.querySelector('vertical')
// verti?.addEventListener("contextmenu", (event) => {
//   event.preventDefault();
//   const { clientX: mouseX, clientY: mouseY} = event;
//   contextmenu.style.top = '${mouseY}px';
//   contextmenu.style.left = '${mouseY}px';
//   contextmenu?.classList.add('visible')
// });
  }
  click_function(){
      this.post_modal.openModal();  
  }
  async get_post() {
    let custom_filters: any;
    console.log(this.params);

    if (this.params.id) {
      custom_filters = { ['meta.roles.owner.user.' + this.params.id]: true }
    }

    console.log(custom_filters)
    let response = await this.gs.get_data_list('Generic', 'Social', custom_filters)
    this.list = response;
  }

  async delete(id: any, index: number) {
    await this.gs.hps.delete('generic', 'data', id)
    this.list.list.splice(index, 1);
  }


  

  // async like(post: any, index: any) {
  //   let body: any = { post_id: post._id }
  //   console.log(body);
  //   let response = await this.gs.hps.post('social', 'like', 'add', body);

  //   post.meta.activity.like[this.user._id] = !post?.meta?.activity?.like[this.user._id];
  //   this.is_liked = !this.is_liked
  //   if (post.meta.activity.like[this.user._id]) {
  //     this.list.list[index].meta.activity.count.like++
  //   } else {
  //     this.list.list[index].meta.activity.count.like--
  //   }


  // }
  async like(post:any,index:any){
    console.log('like');
    let body:any={post_id: post._id}
    console.log(body);
    let response=await this.gs.hps.post('social','like','add',body);
    // this.list.list=this.response
    post.meta.activity.like[this.user._id]=!post?.meta?.activity?.like[this.user._id];
    this.is_liked = !this.is_liked
    if(post.meta.activity.like[this.user._id]){
      this.list.list[index].meta.activity.count.like++
    }
    else{
      this.list.list[index].meta.activity.count.like--
    }
  }
  contextToggle(id:any){
    if(this.socialContext == false){
      this.socialContext = true
    }else{
      this.socialContext = false
    }
  }

 
}

// open_model_div: boolean = false
// open_model_context_div(id:any){
//   console.log(id)
//   this.open_model_div =! this.open_model_div;
// }