import { IUser, IUserData } from './../../../../applications/idm/interfaces/i-user';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
import { json } from 'express';
import { retry } from 'rxjs-compat/operator/retry';
import { range } from 'rxjs';

@Component({
  selector: 'm-review',
  templateUrl: './review.component.html',
  styles: [
  ]
})
export class ReviewComponent implements OnInit {

  application = 'Generic';
  component = 'Feedback';
  comment: any = {
    editComment: false
  };
  sahil: any = {
    editComment: false
  };

  Readmore = false;

  isVisible: boolean = false;
  show(id: any) {
    this.isVisible = !this.isVisible
  }
  rating = 0;
  // @Input() type: string = " "
  @Input() type: any;
  @Input() brNone: boolean = true;
  @Input() mt: boolean = true;
  @Input() p: boolean = true;
  @Input() productImage: string = '';
  @Output() activity = new EventEmitter;
  @Input() visible = false;
  @Input() activities: any
  @Input() brBottom: boolean = true;
  @Input() details: any;
  @Input() w: string = "";
  @Input() br: string = "";
  @Input() modal: any;

  //  review_slider: boolean = false;
  @Input() review_slider: string = '';
  afterReview: boolean = false;
  average_rating = false
  like_title = 'Like';
  form: FormGroup | undefined;
  user: IUser | undefined;
  users: IUserData;
  like_count: number = 0
  isVisible_like: boolean = false;
  isVisible_dislike: boolean = false;
  dislike_title = 'Dislike';

  r_height: any = 17.25 + 'rem'
  view: boolean = false
  data: any = []

  form_data = {

  }

  // form_data = {
  //   review: [null, [this.gs.form_validations.required('Review')]]
  // }
  params: any = {
    type: '',
    _id: ''
  }
  // list_url = `/dashboard/${this.application}/${this.component}/list`;
  reviews_list: any[] = []
  list = {
    list: <any[]>[],
    count: 0
  }
  // data:any=[]


  isReadMore: any = true

  showText() {
    this.isReadMore = !this.isReadMore
    //  this.isReadMore = '76px'
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit() {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    });
    this.ar.queryParams.subscribe(params => this.handleParams(params))
    this.get_list();
    this.initialize_form();
  }
  handleParams = async (params: Params) => {
    this.params = params;
    this.initialize_form();

  }
  initialize_form() {
  
      this.form = this.fb.group({
        // application: this.application,
        // component: this.component,
        review: [null, [this.gs.form_validations.required('Review')]],
        images: []
      })
    
    
  }
  get_rating_key(number: number) {
    switch (number) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
      case 5:
        return 'five';
      default:
        return '';
    }
  }
  async get_list() {
   if(this.gs.router.url.includes('generic/detail')){
    let body = {
      "filters":{
      "parents.data._id": this.params._id,
      "data.review":{"$exists":true}
      }
    }
    let response = await this.gs.hps.post('business', 'products', 'product-review', body)
    this.list = response;
   }
  else if(this.gs.router.url.includes('service/Detail')){
    let body = {
      "filters":{
      "parents.reviewService._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('business', 'services', 'service-review', body)
    this.list = response;
   }
  else if(this.gs.router.url.includes('article/article-detail')){
    let body = {
      "filters":{
      "parents.reviewArticle._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('article', 'articles', 'article-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('recipe/recipe-detail')){
    let body = {
      "filters":{
      "parents.reviewRecipes._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('food', 'recipes', 'recipe-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('project/project-detail')){
    let body = {
      "filters":{
      "parents.reviewProject._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('spiritual', 'spirituals', 'spiritual-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('properties/detail')){
    let body = {
      "filters":{
      "parents.reviewProperty._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('property', 'properties', 'property-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('guardian/guardian-detail')){
    let body = {
      "filters":{
      "parents.reviewAnimal._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('animal', 'animals', 'animal-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('nursery/nursery-detail')){
    let body = {
      "filters":{
      "parents.reviewNursery._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('nursery', 'nurseries', 'nursery-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('jobs/job-detail')){
    let body = {
      "filters":{
      "parents.reviewJob._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('job', 'jobs', 'job-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('scrap/detail')){
    let body = {
      "filters":{
      "parents.reviewScrap._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('scrap', 'scraps', 'scrap-review', body)
    this.list = response;
   }
   else if(this.gs.router.url.includes('used/used-detail')){
    let body = {
      "filters":{
      "parents.reviewUsed._id":this.params._id,
      "data.review":{"$exists":true}
      }
      
  }
    let response = await this.gs.hps.post('used', 'useds', 'used-review', body)
    this.list = response;
   }
    // console.log(this.list);
  }

  async submit() {
    try {
      // let body = { ...this.form?.value };
      // body.parents = {
      //   data: { ...this.details }
      // }
      // body.rating = this.rating;
      // body.type = this.type
      // if (this.form?.invalid) {
      //   this.form?.markAllAsTouched()
      //   return;
      // }
      // delete body.parents.data.meta;
     if(this.gs.router.url.includes('generic/detail')){
      let body = { ...this.form?.value }
      body.application = 'generic'
      body.component = 'review'
      body.key = this.params?._id
      console.log(body);
      let response = await this.gs.hps.post('business', 'products', 'add-product-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('service/Detail')){
      let body = { ...this.form?.value }
      body.application = 'service'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('business', 'services', 'add-service-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('article/article-detail')){
      let body = { ...this.form?.value }
      body.application = 'article'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('article', 'articles', 'add-article-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('recipe/recipe-detail')){
      let body = { ...this.form?.value }
      body.application = 'recipe'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('article', 'articles', 'add-article-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('spiritual/spiritual-detail')){
      let body = { ...this.form?.value }
      body.application = 'spiritual'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('spiritual', 'spirituals', 'add-spiritual-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('project/project-detail')){
      let body = { ...this.form?.value }
      body.application = 'project'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('project', 'projects', 'add-project-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('properties/detail')){
      let body = { ...this.form?.value }
      body.application = 'property'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('property', 'properties', 'add-property-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('guardian/guardian-detail')){
      let body = { ...this.form?.value }
      body.application = 'animal'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('animal', 'animals', 'add-animal-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('nursery/nursery-detail')){
      let body = { ...this.form?.value }
      body.application = 'nursery'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('nursery', 'nurseries', 'add-nursery-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('jobs/job-detail')){
      let body = { ...this.form?.value }
      body.application = 'job'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('job', 'jobs', 'add-job-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('scrap/detail')){
      let body = { ...this.form?.value }
      body.application = 'scrap'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('scrap', 'scraps', 'add-scrap-review', body);
      if (response) {
        this.get_list()
      }
     }
     else if (this.gs.router.url.includes('used/used-detail')){
      let body = { ...this.form?.value }
      body.application = 'used'
      body.component = 'review'
      body.key = this.params?._id,
      console.log(body);
      let response = await this.gs.hps.post('used', 'useds', 'add-used-review', body);
      if (response) {
        this.get_list()
      }
     }
      this.gs.toast('Review', 'Review Submitted Successfully')
      this.form?.reset()
    } catch (error) {
      console.log(error);
    }
  }

  async report_product() {
    this.form?.markAllAsTouched()
    let body = this.form?.value;
    body.application = 'Generic',
      body.component = 'Feedback',
      body.type = 'report product'
    if (this.form?.valid) {
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.form?.reset();
      this.modal.closeModal();
    }
  }


  toggle(activities: any, toggle_key: string, current_key: string) {
    console.log(activities);
    activities.my_activity[current_key] = !activities.my_activity[current_key]
    if (activities?.my_activity?.[current_key]) {
      activities.count[current_key] = activities?.count[current_key] + 1
    } else {
      activities.count[current_key] = activities?.count[current_key] - 1
    }
    if (activities?.my_activity?.[toggle_key]) {
      activities.count[toggle_key] = activities?.count[toggle_key] - 1
      activities.my_activity[toggle_key] = false
      // activities.my_activity[current_key] = !activities.my_activity[current_key]
    }
    // console.log(toggle_key);

  }




  async delete(id: any, index: number) {
    try {
      await this.gs.delete_from_list('data', id)
      this.list.list.splice(index, 1);
      this.gs.toast('Review', 'Review Deleted Successfully', 'warning')
    } catch (error) {
      console.log(error);
    }
  }

  // view== true ? '34.5' : '17.2'
  change_height() {
    this.view = !this.view

    if (this.view == true) {
      this.r_height = 'max-content'
      return this.r_height
    }
    else {
      this.r_height = 17.2 + 'rem'
      return this.r_height
    }
  }
  // open edit amd comment
  open_pop_over(name: 'editComment') {
    setTimeout(() => {
      if (this.comment[name]) {
        this.comment[name] = false
      } else {
        this.comment[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
  }

  // close
  close_other_pop_overs(name: 'editComment') {
    Object.keys(this.comment).forEach((key: any) => {
      if (key != name) {
        this.comment[key] = false;
      }
    })
  }

  open_pop_over1(name: 'editComment') {
    setTimeout(() => {
      if (this.sahil[name]) {
        this.sahil[name] = false
      } else {
        this.sahil[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
  }

  // close
  close_other_pop_overs1(name: 'editComment') {
    Object.keys(this.sahil).forEach((key: any) => {
      if (key != name) {
        this.sahil[key] = false;
      }
    })
  }


  viewMore() {
    let j = this.data.length
    for (let i = j; i < j + 4; i++) {
      this.data.push(this.list.list[i])

    }
    console.log(this.data);
  }


  // my_list = [1, 2, 3, 4, 5, 6, 7, 8];

  //  exmpl() {
  //     let my_list2 = [this.my_list[i:i+3] for i in range(0, len(this.my_list), 3))]
  //     console.log(this.my_list);
  //  }

}
