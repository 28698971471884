import { Component, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';


@Component({
  selector: 'm-check-in',
  templateUrl: './check-in.component.html',
  styles: [
  ]
})
export class CheckInComponent implements OnInit {
  @Input() modal: any


  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

}
