import { GlobalService } from './../../../../services/global.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import EventEmitter from 'events';
import { Subscription, timer } from 'rxjs';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';
import { th } from 'date-fns/locale';
import { style } from '@angular/animations';

@Component({
  selector: 'm-faq',
  templateUrl: './faq.component.html',
  styles: [
  ]
})
export class FaqComponent implements OnInit {
  radio:any
  comment:any
  is_visible = {
    faq: false,
    faq_take_question: false,
    faq_question: false,
    faq_answer: false
  }
  madhuri: any
  faq_toggle: any = {
  }
  like_toggle: any = {
  }
  activity: any
  selectedIndex = -1;
  answers: any
  @Input() type: any
  @Input() modal: any
  id: any = '';
  like_title = 'Like';
  like_count: number = 0
  activities: any;
  @Input() details: any;
  constructor(public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder, public hps: HttpProviderService) { }
  application = 'Generic';
  component = 'Question';
  form: FormGroup | undefined;
  list = {
    list: <any[]>[],
    count: 0
  }
  data: any = {
    list: <any[]>[],
    count: 0
  }
  form_data = {
    description: [null, [this.gs.form_validations.required('Question')]]
  }
  queryParams: any = {
    _id: ''
  }
  mapping: any
  user: IUser | undefined;
  users: IUserData;
  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    })
    this.get_list()
  }
  async get_list() {
    let faq = {
      application: 'Generic',
      component: 'Question',
      'parents.data._id': this.details?._id
    }
    this.data = await this.gs.hps.post('generic', 'data', 'list', faq)
    // console.log(this.data);
    this.data.answers = false
    this.data.list.filter((faq: any) => {
      if (faq.count) {
        this.data.answers = true;
        return
      }
    })
  }
  async get_answer(id: any) {
    let faq = {
      questionId: id
    }
    this.list = await this.gs.hps.post('generic', 'answer', 'list', faq)
  }
  async submit(form: any) {
    try {
      if (this.form?.invalid) {
        this.form?.markAllAsTouched()
        return;
      }
      let body = form;
      body.parents = {
        data: this.details
      }
      body.type = this.type
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.data.list.unshift(body);
      this.data.count++;
      this.gs.toast('Mesbro Questions', 'Question submitted successfully', 'success');
    } catch (error) {
      console.log(error);
    }
  }
  async answer(id: any) {
    try {
      if (this.form?.invalid) {
        this.form?.markAllAsTouched()
        return;
      }
      let body = {
        answers: this.answers,
        type: this.type ? this.type : (<any>this.form?.get('data'))?.get('type').value,
        questionId: id,
        parents: {
          data: this.details
        }
      }
      await this.gs.hps.post('generic', 'answer', 'add', body);
      this.list.list.push(body);
      console.log(this.list.list);

      this.list.count++;
      this.gs.toast('Mesbro Answer', 'Answer submitted successfully', 'success');
    } catch (error) {
      console.log(error);
    }
  }
  async delete(id: any, index: number) {
    await this.gs.hps.delete('generic', 'answer', id)
    this.list.list.splice(index, 1);
    this.gs.toast('Mesbro Answer', 'Answer Deleted successfully', 'warning');

  }
  changeimage() {

  }
  // async action(event: any ,id:any) {
  //   try {
  //     let body = {
  //       activity: 'like',
  //       type: 'FAQ',
  //       parents: {
  //         data: {
  //           _id: id
  //         }
  //       }
  //     }
  //     console.log(body);
  //     let response = await this.hps.post('generic', 'activity', 'add', body);
  //   } catch (error) {
  //     console.log(error);

  //   }
  // }
  // pradeep: boolean = false;
  async feedback(id:any) {
    console.log('madhuri');
      let body = {
        "application":"generic",
        "component":"feedback",
        "key": id,
        "feedback":this.comment,
        "description":this.radio,
      }
      console.log(body);
      let response = await this.gs.hps.post('business', 'products', 'add-product-feedback', body)
      this.gs.toast('Feedback', 'Feedback Send Successfully', 'success')
    
  }
  // submitThink(){
  //   this.gs.toast('Lorem ipsum dolor sit', 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed');
  // }

}
