<div a-style >

<div a-style w=31 class="chat-mobile mobile-4" br="0.5px solid rgb(228, 228, 228)" shadow=" 0 3px 6px 0 #f1f1f1" 
    brRadius="22px">
    <div a-style h=5 class="h-35" bg="#2479AB" brTopLeftRadius="22px" brTopRightRadius="22px" display="flex"
        justify="center" align="center">
        <m-image src="../assets/images/assets/product/Mesbro.png" a-style w="9.4" h="3.4"></m-image>
    </div>
    <div a-style minHeight="35" class="max-h-auto" p="1.6" brBottom="0.5px solid rgb(228, 228, 228)">


        <div a-style display="flex">
            <m-image src="../assets/images/assets/product/viewicon.svg" a-style w="1.6" h="1.6" ml="8.6"></m-image>

            <m-text a-style [type]="gs.enums.texts.paragraph10" text="00:00 AM" ml="0.7"></m-text>
        </div>
    </div>
    <div a-style display="flex" gap="1" p="1.6" class="p-12" brBottom="0.5px solid rgb(228, 228, 228) ">
        <div>
            <m-image src="../assets/images/assets/attchment_coloured.svg" h="1.8" w="1.8"></m-image>
        </div>
        <div a-style flexGrow="1">  
            <!-- <div a-style display="flex" justify="space-between" gap="1">
                <div *ngIf="details && inputVariable" a-style w=100% br="1px solid #E4E4E4" brRadius="0.6" p=1.2
                    h="63px">
                    <form [formGroup]="form" a-style w="100%">
                        <m-form formGroupName="data" [brNone]=false flexWrap="nowrap" [paddingNone]=false>
                            <input type="text">
                            <textarea (keyup.enter)="submit();send()" fs="1.2" formControlName="text" name="message"
                                align="center" outline=none br="none" a-style h=3.9 w="100%" placeholder="Aa"
                                overflowY=scroll id="scroll" bg="transparent" flexWrap="wrap">
                   </textarea>
                        </m-form>
                    </form>
                </div>
                <m-image src="../assets/images/assets/Path.svg" a-style w="1.4" h="1.8" ml="1" (click)="submit()">
                </m-image>
            </div> -->
            <m-input a-style outer_pr="0"  (postfix_clicked)='submit()' prefix="../assets/images/assets/mic.svg"
                postfix="../assets/images/assets/send.svg" postfix_bg="#05A6F0" postfix_px="1" formControlName="text"
                (keyup.enter)="submit()" *ngIf="!visible.text_format" shadow="0 3px 6px 0 #f1f1f1" (blur)="onBlur()"
                (focus)="onFocus()" name="message" [(ngModel)]="message" a-style placeHolder="Type your message here"
                outline=none px=0 a-style></m-input>
            <!-- <m-image src="../assets/images/assets/Path.svg" a-style w="1.4" h="1.8" ml="1" (click)="submit()">
            </m-image> -->
        </div>
    </div>
    <div a-style display="flex" p="1.6" class="p-12" justify="center" gap="1" h="5.8" class="h-auto" align="center">
        <m-text a-style text="powered by " [type]="gs.enums.texts.paragraph75"></m-text>
        <img *ngIf="gs.router.url != '/home'" src="/assets/images/logo.svg" routerLink="/" a-style w="5.1" h="1.9"
            cursor="pointer">
    </div>
</div>

</div>