
<div a-style mt="1.6">
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Status:', text2:'Active'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'First sync:', text2:'2019-07-14T19:46:03+05:30'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Last successful sync:', text2:'2020-11-14T19:46:03+05:30'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Folder synced:', text2:'2'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device name:', text2:'-'}}">
    </ng-container> 
     <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device model:', text2:'Android'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Phone Number', text2:'-'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Mobile network:', text2:'-'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device type:', text2:'Mesbro Mail'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device ID:', text2:'484484D8848E8488DS848445'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device IMEI:', text2:'-'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device OS:', text2:'10'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Device language:', text2:'English'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'User agent:', text2:'Mesbro Mail-Android'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Access state:', text2:'Access granted'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Access set by:', text2:'Global permissions'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Client type:', text2:'Mesbro Mail'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="text" [ngTemplateOutletContext]="{passedData:{text1:'Protocol version:', text2:'1.0'}}">
    </ng-container>
</div>

<ng-template #text let-passedData="passedData">
<div a-style display="flex" w="39.4" justify="space-between" mt="1.2">
 <div><p a-style fs="1.3" >{{passedData.text1}} </p></div>
<div a-style justify="flex-start" w="18.9"><p a-style fs="1.3" >{{passedData.text2}}</p></div>
    </div>
</ng-template>