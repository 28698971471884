import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-quotenow-banner',
  templateUrl: './quotenow-banner.component.html',
  styles: [
  ]
})
export class QuotenowBannerComponent implements OnInit {
  // details: any = undefined;
  details: any;
  detail: any = undefined;
  activities: any = undefined;
  make: any = undefined
  image_src: any = "https://new-api.mesbro.com/uploads/{{details?.profile_picture}}";
  constructor(public gs: GlobalService) { }
  bgP: boolean = true;
  bgO: boolean = false;
  // @Input() details: any;

  form: FormGroup | undefined;

  ngOnInit(): void {
  }
  change_image: any;
  select_image(path: any) {
    console.log(path);
    this.change_image = path
  }

  setProgress(elem: any, percent: any) {
    if (!elem) {
      return;
    }
    let degrees = percent * 3.6;
    let transform = /MSIE 9/.test(navigator.userAgent) ? 'msTransform' : 'transform';
    elem.querySelector('.counter').setAttribute('data-percent', Math.round(percent));
    elem.querySelector('.progress').style[transform] = 'rotate(' + degrees + 'deg)';
    if (percent >= 50 && !/(^|\s)fiftyPlus(\s|$)/.test(elem.className))
      elem.className += ' fiftyPlus';

  }

  limit: number = 0;

  calculate_percentage() {
    console.log(this.form?.value.basic_details?.description, this.form?.value.supplier_capability?.other_requirement, this.form?.value.shipping?.payment_term)
    let elem = document.querySelector('.circlePercent');
    let percent = this.limit;
    this.limit = 0;
    let description = this.form?.value.basic_details?.description
    if (description) {
      this.limit = this.limit + 33;
    }
    let other_requirement = this.form?.value.supplier_capability?.other_requirement
    if (other_requirement) {
      this.limit = this.limit + 33;
    }
    let payment_term = this.form?.value.shipping?.payment_term
    if (payment_term) {
      this.limit = this.limit + 34;
    }
    console.log(this.limit)
    if (this.limit > 100) {
      this.limit = 100;
    }
    let animate = () => {
      this.setProgress(elem, (percent += .25));
      if (percent < this.limit)
        setTimeout(animate, 15);

    };
    animate();
  }


  async submit() {
    const body = { ...this.form?.value };
    console.log(this.form?.value);

    body.parents = {
      user: {
        personal_details: this.gs.user.users?.activeUser?.personal_details,
        contact_details: this.gs.user?.users?.activeUser?.contact_details
      },
 
    }
    delete body.parents.user.meta
    delete body.parents.user.meta
    try {
      this.form?.markAllAsTouched()
      if (this.form?.invalid) {
        return;
      }
      if (this.form?.value._id) {
        let response = await this.gs.hps.put('generic', 'data', undefined, body);
      } else {
        let response = await this.gs.hps.post('generic', 'data', 'add', body);
        this.form?.reset()
      }

      this.gs.router.navigate(['/dashboard/group/rfq'], { queryParams: { type: 'sent_rfq' } });
    }
    catch (error) {
    }

  }

}
