import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-create-post',
  templateUrl: './create-post.component.html',
  styles: [
  ]
})
export class CreatePostComponent implements OnInit {

  application = 'Generic';
  component = 'Social';
  public:boolean=false
  form: FormGroup | undefined;
  @Output() cancel = new EventEmitter()
  is_visible: any = {

  };
  is_hov:any = {}
  feeling_activity: any;
  social_poll: any

  post_tab: any = [
  { label: 'Feeling', image: '../assets/images/Icon_Bank/Feeling_Coloured.svg', hov_image:'../assets/images/assets/bar-chart colour.svg' },
  { label: 'Video', image: '../assets/images/Icon_Bank/Video_Coloured-1.svg', hov_image:'../assets/images/assets/emoji colour.svg' }, 
  { label: 'Tagging', image: '../assets/images/Icon_Bank/Tag_Coloured.svg', hov_image:'../assets/images/assets/tag colours.svg' }, 
]

  form_data = {
    image: '',
    description: '',
    tag_friends: ''
  }
  queryParams: any = {
    id: '',
    type: ''
  }
  // list_url = `/dashboard/group/${this.application}/${this.component}/list`;

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))
  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'data', params._id); //update list
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: null,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }
  async submit() {
    try {
      let body: any = this.form?.value;
      body.parents = {}

      if (this.queryParams.id) {
        body.parents._id = this.queryParams.id
        body.parents.type = this.queryParams.type
      }
      body.data.feeling_activity = this.feeling_activity;
      body.data.social_poll = this.social_poll
      console.log(body);

      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      body._id = response._id;
    body.meta =   {
        "activity": {
            "created_at": new Date() ,
            "updated_at": new Date(),
            "updated_by": {
                "first_name": this.gs.user.users.activeUser?.personal_details.first_name,
                "last_name": this.gs.user.users.activeUser?.personal_details.last_name,
                "profile_picture": this.gs.user.users.activeUser?.personal_details.profile_picture,
            }
        },
       
    }
      this.gs.$post.next(body)
      this.gs.toast('Post', 'Added Successfully', 'success')
      // this.gs.router.navigate(    ['/social/']  );
      // this.gs.router.navigate(  ['social/social-group']  ,{queryParams:{type:'feed'}})
      this.cancel.emit();
      this.is_visible = {}

    }
    catch (error) {
      console.log(error);
      // social/social-group?type=feed
    }
  }

  friendList: any;

  async isClicked(item: any) {
    
    this.post_tab.forEach((element: any, i: any) => {
      this.is_visible[element.label] = false
    });
    this.is_visible[item.label] = true;
    if (item.label == 'Tag Friend') {
      let body = { ['meta.roles.owner.user.' + this.gs.user.users?.activeUser?._id]: true }
      let response: any = await this.gs.hps.post('social', 'connected_request', 'list', body);
      this.friendList = await response.list.map((item: any) => {
        item.receiver.name = (item.receiver._id?item.receiver._id:item.receiver).first_name + ' ' + (item.receiver._id?item.receiver._id:item.receiver).last_name
        item = item.receiver
        return item
      });
      console.log(this.friendList);

    }
  }

  poll(poll: any) {
    console.log(poll);
    this.social_poll = poll

  }
  openContext(){
    if(this.public == false){
      this.public = true
    }
    else{
      this.public = false
    }
  }

}
