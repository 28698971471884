import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import { encryptData } from 'src/mesbro/modules/shared/functions/common';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from 'express';


@Component({
  selector: 'm-add-rfq',
  templateUrl: './add-rfq.component.html',
  styleUrls: []
})
export class AddRfqComponent implements OnInit {
  searchDropdown = false
  secondaryName: any
  primaryName: any
  tertiaryName: any
  tyepName: any
  primaryList: any
  primary: any
  secondaryList: any
  tertiaryList: any
  typeList: any
  primaryArray: any;
  secondaryArray: any
  tertiaryArray: any
  typeArray: any
  typeId: any
  first: boolean = true
  second: boolean = false
  third: boolean = false
  fourth: boolean = false
  typeName: any
  @Input() type: any;
  @Input() details: any
  @Input() br: any
  control: FormControl | undefined;
  application = 'Product';
  component = 'Enquiry';
  submitModal: boolean = false;
  // search: any;
  error: any;
  quantity: any
  pradeep: boolean = false;
  shippingMethod: any
  image_src: any = "https://new-api.mesbro.com/uploads/{{details?.profile_picture}}";
  params = {
    type: ' ',
    subtype: '',
    // query: this.form?.value?.text
  }
  search = new FormControl('');
  paymentTerm: any
  maxBudget: any
  sourcingPurpose: any
  sourcingType: any
  unit: any
  seaPort: any
  tersmAndCondition: any
  @Input() modal: any

  form: FormGroup | undefined;
  @Input() is_visible: boolean = false;

  form_data = {
    primaryName: [null, Validators.required],
    secondaryName: [null, Validators.required],
    tertiaryName: [null, Validators.required],
    quantity: [null, Validators.required],
    currency: [null, Validators.required],
    destinationAirport: [null,],
    maxBudget: [null],
    sourcingType: [null],
    sourcingPurpose: [null],
    expectedPriceRange: [null],
    type: [null],
    unit: [null, Validators.required]
    // productName: [null  ],
    // quantity: [null ,],
    // unit: [null],
    // sourcingPurpose: [null ]  ,
    // requirement: [null ],
    // picture: [null],
  }
  change_image: any;
  select_image(path: any) {
    console.log(path);
    this.change_image = path
  }

  response: any
  location: any
  airport: any
  currency: any
  deliveryTime: any
  expectedCertifications: any
  descrption: any
  tradeTerms: any
  typeProductList = {
    list: [],
    count: 0
  }
  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, public http: HttpClient) {
    this.primaryArray = []
    this.secondaryArray = []
    this.tertiaryArray = []
    this.typeArray = []
    this.typeId = []
    this.gs.getMasters()
  }
  ngOnInit(): void {

    this.initializeForm()
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params
    })
    this.getPrimaryList()
  }


  queryParams = {
    type: '',
    subType: ''
  }

  first_time = true;

  openModal() {
    this.is_visible = false;
  }

  closeModal() {
    this.is_visible = false;
    this.first_time = true;
  }
  SourcingPurpose = ['Retail', 'Production Epuipment', 'Raw Materials Production', 'Corporate Consumption', 'Personal Use', 'other']
  typeSearch: any
  async onKeyUp(event: any) {
    let requestObj = {
      "query": this.search.value
    }
    this.typeSearch = await this.gs.hps.post('business', 'producttypes', 'list', requestObj)
    let primary: any = await this.gs.hps.get('business', 'productcategories', this.typeSearch.list[0]?.basic?.primaryId)
    this.primaryName = primary.basic?.name
    let secondary: any = await this.gs.hps.get('business', 'productcategories', this.typeSearch.list[0]?.basic?.secondaryId)
    this.secondaryName = secondary.basic?.name
    let tertiary: any = await this.gs.hps.get('business', 'productcategories', this.typeSearch.list[0]?.basic?.tertiaryId)
    this.tertiaryName = tertiary.basic?.name
    this.typeName = this.typeSearch?.list[0].basic?.name
  }

  image(e: any) {
    console.log(e);
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: null,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }

  async submit() {
    let body = {
      "primaryName": this.primaryName,
      "secondaryName": this.secondaryName,
      "tertiaryName": this.tertiaryName,
      "typeName": this.typeName,
      'typeId': this.response.list[0]._id,
      "quantity": this.quantity,
      "unit": this.unit,
      "sourcing_Type": this.sourcingType,
      "sourcing_Purpose": this.sourcingPurpose,
      "trade_terms": this.tradeTerms,
      "payment_terms": this.paymentTerm,
      "shipping_method": this.shippingMethod,
      "destination_sea_port": this.seaPort,
      "destination_air_port": this.airport,
      "location": this.location,
      "currency": this.currency,
      "max_budget": this.maxBudget,
      "Expected_delivery": this.deliveryTime,
      "Expected_certification": this.expectedCertifications,
      "description": this.descrption,
      "typeImage":this.response.list[0].basic?.image[0],
      "status":{
        'closeByMesbro':false,
        'open':true,
        'closeByMe':[],
        'notIntrested':[]
      }
    }
    if (this.gs.user.users.activeUser) {
      let response = await this.gs.hps.post('business', 'productenquiries', 'send-rfqs', body);
      this.first = true;
      if (response) {
        this.gs.toast('Success', 'RFQ sent successfully', 'success', 2)
      }
    } else {
      this.gs.toast('warning', 'Please login to send RFQ', 'warning', 2)
      this.first = true;
    }

  }
  //  else {
  //   let response = await this.gs.hps.post('generic', 'data', 'add', body)
  //   this.form?.get('data')?.get('product_name')?.reset()
  //   this.form?.get('data')?.get('attachments')?.reset()
  //   this.form?.get('data')?.get('qantity')?.reset()
  //   this.form?.get('data')?.get('unit')?.reset()
  //   this.form?.get('data')?.get('sourcing_purpose')?.reset()
  // this.form?.get('data')?.get('requirement')?.reset()
  // this.form?.get('data')?.get('checkbox')?.reset()
  //   this.gs.toast('Get Quotation', 'Submitted Successfully')
  //   console.log(this.form?.value)
  //   console.log(response)
  //   this.form?.reset()
  //   this.modal.closeModal()
  // }
  // try {
  //   this.form?.markAllAsTouched();
  //   let body = this.form?.value;
  //   if (!body?.email?.includes('@')) {
  //     body.email = body.email + '@mesbro.com'
  //   }
  //   body.email = body.email.trim()
  //   body.password = encryptData(body.password)
  // }
  // catch (error) {
  //   console.log(error);
  //   this.error = "invalid credentials provided"
  //   this.gs.toast('Error', 'Incorrect Credentials Provided', 'error', 3)
  //   throw error
  // }



  async add_activity(detail: any, activity: any) {
    let body = {
      application: "Product",
      component: "Product",
      activity: activity,
      parents: {
        data: detail,

      }

    }
    console.log(body);
    this.submitModal = true;
    let response = await this.gs.hps.post('generic', 'activity', 'add', body)
    // this.list = response;
    // console.log(this.list);
    this.gs.toast('Add Favourite', ' Successfully')
  }
  async getPrimaryList() {
    let requestObj = {
      'basic.type': {
        "$eq": "primary"
      }
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'list', requestObj)
    this.primaryList = response;
    this.primaryList.content.forEach((element: any) => {
      this.primaryArray.push(element.basic.name)
    });
  }
  async getsecondaryByName() {

    let requestObj = {
      "primaryName": this.primaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'primary-filter', requestObj)
    if (response) {
      this.secondaryArray = []
    }
    this.secondaryList = response;
    this.secondaryList.list.forEach((element: any) => {
      this.secondaryArray?.push(element?.basic?.name)
    });


    // this.typeList = response;
  }
  async getTertiaryByName() {
    let requestObj = {
      "secondaryName": this.secondaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'secondary-filter', requestObj)
    if (response) {
      this.tertiaryArray = []

    }
    this.tertiaryList = response;
    this.tertiaryList.list.forEach((element: any) => {
      this.tertiaryArray?.push(element?.basic?.name)
    });

    // this.typeList = response;
  }
  async getTypeByName() {
    let requestObj = {
      "tertiaryName": this.tertiaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'tertiary-filter', requestObj)
    if (response) {
      this.typeArray = []
    }
    this.typeList = response;
    this.typeList.list.forEach((element: any) => {
      this.typeArray?.push(element?.basic?.name)
      this.typeId?.push(element?._id)
    });
  }
  async getName(typeName: any) {
    let body = {
      "typeName": typeName
    }
    this.response = await this.gs.hps.post('business', 'productenquiries', 'get-typeid', body)
    console.log(this.response);

  }
  next() {
    if (this.primaryName == undefined && this.secondaryName == undefined && this.tertiaryName == undefined && this.typeName == undefined) {
      console.log(this.primaryName);

      this.second = false
      this.first = true
      this.gs.toast('warning', 'please fill all required fields', 'warning')
    }
    else {
      this.second = true
      this.first = false
    }
  }
  async producttypeList(event: string) {
    if (this.gs.router.url.includes('/product/home')) {
      let body = {
        "query": event
      }
      let response = await this.gs.hps.post('business', 'producttypes', 'list', body)
      this.typeProductList = response
      if (event != '') {
        this.searchDropdown = true
      } else {
        this.searchDropdown = false
      }

    }
  }
  onChange: any = (event: string) => {
    this.producttypeList(event)
  }

  // http://203.112.156.109/business/producttypes/list
}
