<div a-style bg=white p="3" brRadius="0.4">
    <div a-style display="flex" justify="space-between" mb="1.6">
        <p a-style fs="2.2" color="#333333">Change Cover Photo</p>
        <button a-style fs="1.3" w="15.3" h="3" bg="#006BFF" br="none" brRadius="0.4" color="#FFFFFF">Browse Photos</button>
    </div>
    <div a-style br="1px solid lightgrey" px="2" py="2" display="Flex" flexGap="2" brRadius="0.4">
        <div a-style br="1px solid lightgrey" w="16.3" h="16.3" brRadius="0.4">
        </div>
        <div a-style br="1px solid lightgrey" w="16.3" h="16.3" brRadius="0.4">
        </div>
    </div>
    <div a-style mt="2.4" display="flex" justify="flex-end" flexGap="1.6">
        <button a-style fs="1.3" w="9" br="none" brRadius="0.4 " color="#707070 " px="2" py="1" brRadius="0.4" outline=none  (click)="modal?.closeModal()">Cancel</button>
        <button a-style fs="1.4 " w="10.5 " bg="#006BFF " br="none " brRadius="0.4" color="#FFFFFF" px="5" py="2s" (click)="confirm()" >Confirm</button>
    </div>
</div>
