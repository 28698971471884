import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Pipe({
  name: 'attributeServicetype'
})
export class AttributeServicetypePipe implements PipeTransform {

  constructor(public gs: GlobalService) { }

  transform(attribute: any): any {
    // console.log(attribute);
    if (attribute?.dropdowns?.length>0) { return this.gs.enums.inputs.dropdown }
    if (attribute?.dataType == 'String') { return this.gs.enums.inputs.text }
    if (attribute?.dataType == 'Number') { return this.gs.enums.inputs.number }
    if (attribute?.dataType == "Image" )  { return this.gs.enums.inputs.media }
    if (attribute?.dataType == "Video" )  { return this.gs.enums.inputs.media }
    if (attribute?.dataType == "Markdown") { return this.gs.enums.inputs.ck_editor2 }
    if (attribute?.dataType == "Tags") { return this.gs.enums.inputs.tags }
    return this.gs.enums.inputs.text
  }


}
