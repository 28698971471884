import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-status-list',
  templateUrl: './status-list.component.html',
  styles: [
  ]
})
export class StatusListComponent implements OnInit {
  filters = []
  query_params = {
    status: <'Reported' | 'Blocked' | 'Approved' | 'Rejected' | 'Pending'>'',
    application: '',
    component: '',
  }
  list: any;

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(async (params: any) => {
      this.query_params = params;
      this.getList()
    })
  }
  async change_status(id:any,status : string) {
    console.log(status);
    console.log(id);

    let body={
      _id:id,
      status:status
    }
        await this.gs.hps.post('generic','data','change-status',body);
  }
  async getList() {
    this.list = await this.gs.get_data_list(this.query_params?.application, this.query_params?.component || this.query_params?.application, this.filters)
  }
}
