<div a-style mt=3 p=2.4>
   

    <!-- form -->
    <form [formGroup]="form" > 


        <div a-style display=flex gap=2 justify=center>
            <div>
                <div a-style zIndex="1" maxWidth=150rem w="100%" h=6 display=flex alignItems="center">
                    <div a-style position="relative" h="6" display="flex" justify="space-between">
                        <m-image b br="1px solid #E4E4E4"
                            [src]="form?.value.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+form?.value.personal_details?.profile_picture) : gs.enums.images.image154"
                            h="21.7" w="22.7"> </m-image>
                        <!-- <ng-container [formGroup]="form"> -->
                        <ng-container formGroupName="profilePic">
                            <m-input [type]="gs.enums.inputs.file" [user]="user" #profile formControlName="profile_picture"
                                [onlyInput]="true" [columns]="false" [aspect_ratio]="1"></m-input>
                            <m-image (click)="profile.image.openModal()" [src]="gs.enums.images.image154" top="0.7" h="2.6"
                                w="2.6" position="absolute" left="19.2"> </m-image>
                            <m-input [type]="gs.enums.inputs.file" formControlName="profile_picture" [onlyInput]="true" [columns]="false"
                                [aspect_ratio]="1">
                                <m-image [src]="gs.enums.images.image154" top="0.7" h="2.6" w="2.6" position="absolute"
                                left="18.2"></m-image>
                            </m-input>
                        </ng-container>
                        <!-- </ng-container> -->
                    </div>
                </div>
            </div>
        
            <div bg="#cccccc" a-style w=36% position="relative" h='22'>
                <div [style.backgroundImage]="'url(' +'https://new-api.mesbro.com/uploads/'+form?.value?.personal_details?.cover_picture +')'"
                    a-style w=100% h=100% bgRepeat="no-repeat" bgSize="cover" bgPosition="center">
                </div>
                <!-- <ng-container [formGroup]="form"> -->
                <ng-container formGroupName="personal_details">
                    <div a-style position="absolute" cursor='pointer' top="1" right="1">
                        <m-input [type]="gs.enums.inputs.file" formControlName="cover_picture" [onlyInput]="true"
                            [columns]="false" [aspect_ratio]="0.4">
                            <m-button file [types]="gs.enums.buttons.button31" [src]="gs.enums.images.image153"
                            text="Edit cover"></m-button>
                        </m-input>
                    </div>
                </ng-container>
                <!-- </ng-container> -->
            </div>
        
        </div>
        <div a-style align=right display=flex gap=1.5 w=100vw display=flex>  
            <p a-style fs="1.3" color="#006bff" routerLink="/idm/change-password" [queryParams]="{ type: 'change-password' }" >Change Password</p>
            <p a-style fs="1.3" color="#006bff" routerLink="/idm/change-mobile" [queryParams]="{ type: 'change-mobile' }" >Change Mobile Number</p>
        </div>
        <m-form title="Personal Details" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="personal_details">
                <m-input formControlName="appellation" bindLabel="label" bindValue="label" [list]="gs.masters.Appellation" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Appellation" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="first_name" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="First Name"></m-input>
                <m-input formControlName="middle_name" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Middle Name"></m-input>
                <m-input formControlName="last_name" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Last Name"></m-input>
                <m-input formControlName="gender" mt=2 bindLabel="label" bindValue="label" [list]="gs.masters.Gender" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Gender" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="blood_group" label="Blood Group" [list]="blood_groups" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" [type]="gs.enums.inputs.dropdown" dropdownHeight="20"></m-input>
                <m-input formControlName="age" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Age"></m-input>
                <m-input formControlName="zodic_sign" label="Zodic Sign" [list]="zodic_sign" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" [type]="gs.enums.inputs.dropdown" dropdownHeight="20"></m-input>
                <m-input formControlName="weight" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Weight"></m-input>
                <m-input formControlName="height" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Height"></m-input>
                <m-input formControlName="date_of_birth" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Date of Birth" [type]="gs.enums.inputs.date"></m-input>
                <m-input formControlName="birth_time" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Birth Time" [type]="gs.enums.inputs.time"></m-input>
                <m-input formControlName="birth_place" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Birth Place"></m-input>
                <m-input formControlName="name_of_hospital" mt=2 minWidth="35;460:32;320:30" [columns]="1/4 " flexGrow="0;1024:1" label="Name Of Hospital"></m-input>
                <m-input formControlName="nationality" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Nationality"></m-input>
                <m-input formControlName="profession" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Profession"></m-input>

                <m-input formControlName="hobbies" [type]="gs.enums.inputs.text_area" [columns]="1/2" maxLength="2000" label="Hobbies" rows=8 px=1></m-input>
                <m-input formControlName="bio" [type]="gs.enums.inputs.text_area" [columns]="1/2" maxLength="2000" label="Bio" rows=8 px=1></m-input>

            </ng-container>
        </m-form>
        <m-form title="Relationship Status" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="relationship_status">
                <m-input formControlName="relationship" label="relstiondhip" [list]="relationship" bindLabel="value" [type]="gs.enums.inputs.dropdown" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Relationship"></m-input>
                <m-input formControlName="marital_status" label="marital_status" [list]="Marital_status" bindLabel="value" [type]="gs.enums.inputs.dropdown" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Marital Status"></m-input>
            </ng-container>
        </m-form>
        <m-form title="Location" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="location_details">
                <m-input formControlName="Search_Location" [list]="locationList" bindLabel="value" [type]="gs.enums.inputs.dropdown" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Search Location"></m-input>
                <m-input formControlName="address_line_1" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Address Line 1"></m-input>
                <m-input formControlName="address_line_2" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Address Line 2"></m-input>
                <m-input formControlName="city" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="City"></m-input>
                <m-input formControlName="region" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="State"></m-input>
                <m-input formControlName="district" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="District"></m-input>
                <m-input label="Country *" label="Country " prefix="true" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" [direct_image]="true" formControlName="country_name" px="1.5" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" prefix="flag"
                    bindLabel='la' bindValue="la" [list]="gs.masters.Country"></m-input>
                <!-- <m-input label="Country *" prefix="true" country=true formControlName="country_name" px="1.5" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" prefix="flag" bindLabel='label' bindValue="label" bindImage="image" [list]="gs.masters.Country" minWidth="35;460:32;320:30" [columns]="1/4"></m-input> -->
                <m-input formControlName="postal" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Pin Code"></m-input>
            </ng-container>
        </m-form>
        <m-form title="Contact Details" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="contact_details">
                <m-input [disabled]=true formControlName="mobile_number_primary" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Primary Mobile Number"></m-input>
                <m-input formControlName="mobile_number_secondary" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label=" Alternate Mobile Number"></m-input>
                <m-input [disabled]=true formControlName="email_address_primary" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Email Address"></m-input>
                <m-input formControlName="email_address_secondary" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Recovery Email"></m-input>
                <m-input formControlName="website" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Website"></m-input>
                <m-input formControlName="alternate_website" mt=3 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Alternate Website"></m-input>
            </ng-container>
        </m-form>
        <ng-container a-style overflowX="hidden;786:auto;320:scroll" formArrayName="educations">
            <m-form title="Educational Qualification" *ngFor="let item of education_group; let i=index" formGroupName="{{i}}" mt="2.4" px="1.4">
                <m-input formControlName="educational_qualification" [list]="education" bindLabel="value" [type]="gs.enums.inputs.dropdown" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Education"></m-input>
                <m-input formControlName="marks" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Marks"></m-input>
                <m-input formControlName="university_name" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="University Name/Board Name"></m-input>
                <m-input formControlName="passout_year" mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Passout Year"></m-input>
            </m-form>
        </ng-container>
        <div display="flex" gap="2">
            <m-button [types]="gs.enums.buttons.button30" a-style fs='1.4' mx=0 px=2 text="+" (click)="add_education()"></m-button>
        </div>
        <m-form title="Social Connect" mt="2.4" px="1.4">
            <ng-container overflowX="hidden;786:auto;320:scroll" formGroupName="social_media">
                <m-input formControlName="facebook" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/facebook_colored.png' mt=0 [columns]="1/4" minWidth="10" label="Facebook"></m-input>
                <m-input formControlName="whatsapp_business" mt="0 ; 750:3" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/whatsapp_buisness_colored.png' [columns]="1/4" minWidth="10" label="Whatsapp-business"></m-input>
                <m-input formControlName="instagram" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/instagram_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Instagram"></m-input>
                <m-input formControlName="twitter" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/twitter_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Twitter"></m-input>
                <m-input formControlName="skype" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/skype_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Skype"></m-input>
                <m-input formControlName="telegram" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/telegram_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Telegram"></m-input>
                <m-input formControlName="linked_in" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/linkedin_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Linkedin"></m-input>
                <m-input formControlName="youtube" prefix='https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/youtube_colored.png' mt=3 [columns]="1/4" minWidth="10" label="Youtube"></m-input>

            </ng-container>
        </m-form>
        <m-form title="Security" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="security_details">
                <m-input formControlName="new_device_authentication" [type]="gs.enums.inputs.dropdown" label="2 Factor Authentication" bindLabel="label" bindValue="value" [list]="security" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="two_factor_authentication" [type]="gs.enums.inputs.dropdown" label="New Device Authentication" bindLabel="label" bindValue="value" [list]="authentication" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="private" [type]="gs.enums.inputs.dropdown" label="Make This Account Private ?" bindLabel="label" bindValue="value" [list]="security" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" dropdownHeight="fit-content"></m-input>
            </ng-container>
        </m-form>

        <m-form title="Identity  Details" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="Identity_details">
                <m-input formControlName="driving_licence" bindLabel="label" bindValue="label" [list]="drivingLicence" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Driving Licence" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="aadhar_card_no" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Aadhar Card No"></m-input>
                <m-input formControlName="pan_card_no" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="PAN Card No"></m-input>
                <m-input formControlName="voting_card_no" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Voting Card No"></m-input>
                <m-input formControlName="Passport" bindLabel="label" bindValue="label" [list]="passport" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Passport" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="senior_citizen_card" bindLabel="label" bindValue="label" [list]="seniorCitizenCard" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="senior Citizen Card" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                <m-input formControlName="disability" bindLabel="label" bindValue="label" [list]="disability" minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1" label="Disability" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>

               

            </ng-container>
        </m-form>
        <!-- <m-form  title="Payment Gateway" mt="2.4" px="1.4">
            <ng-container a-style overflowX="hidden;786:auto;320:scroll" formGroupName="payment_gateway">
                <div a-style display="flex" gap="4">
                    <m-text [type]="gs.enums.texts.paragraph23" text="bhim ID" mb="1"></m-text>
                    <m-image a-style src="https://mesbro.in/assets/images/qr.svg" w=3></m-image>
                    <m-input mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1"></m-input>
                </div>
                <div a-style display="flex" gap="4">
                    <m-text [type]="gs.enums.texts.paragraph23" text="bhim ID" mb="1"></m-text>
                    <m-image a-style src="https://mesbro.in/assets/images/qr.svg" w=3></m-image>
                    <m-input mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1"></m-input>
                </div>
                <div a-style display="flex" gap="4">
                    <m-text [type]="gs.enums.texts.paragraph23" text="bhim ID" mb="1"></m-text>
                    <m-image a-style src="https://mesbro.in/assets/images/qr.svg" w=3></m-image>
                    <m-input mt=2 minWidth="35;460:32;320:30" [columns]="1/4" flexGrow="0;1024:1"></m-input>
                </div>
            </ng-container>
        </m-form> -->
        <!-- <m-edit-profile></m-edit-profile> -->
        <div a-style w=100% display=flex justify=flex-end mt=2.4>
            <m-button [types]="gs.enums.buttons.button30" a-style fs='1.4' mx=1 h='4.5' px="2.8" text="Update Info" (click)="submit()"  ></m-button>
        </div>
    </form>
