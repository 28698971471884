<m-product-header></m-product-header>

<div a-style h="calc(100vh - 22.8rem)">
    <div a-style  mx="16" position="relative" *ngIf="true">
        <div a-style w="calc(100vw - 490px)" display="flex">
            <div a-style display="flex" flexDirection="column" mt="2.4">
                <div a-style fs="1.3" w="100%" align="left" display="flex" gap="0.5" cursor="pointer">
                    <span>{{primary?.basic.name}}</span> <span a-style
                        color="#2479AB">></span>{{secondary?.basic.name}}<span a-style color="#2479AB">></span>
                 <span >{{tertiary?.basic.name}}</span>   
                    <span a-style color="#2479AB">></span> <span a-style color="#2479AB" (click)="gs.router.navigate(['/generic/gallary'],{queryParams : {type:'product',typeId:typeId?._id}})">{{typeId?.basic?.name}}</span>
                </div>
           
                <m-image src="../assets/images/B2B/RFQbtn22.gif" a-style w="7" h="7" position="fixed" zIndex="999"
                    right="4" top="18.8" (click)="rfq.openModal()">
            </m-image>
                <m-image src="../assets/images/Icon_Bank/Page_Upword_Arrow_Coloured.svg" w="4.2" h="4.2"
                    onclick="document.getElementById('top').scrollIntoView({ behavior: 'smooth', block: 'start', inline:'start' })"
                    position="absolute" bottom="5" right="-10"> </m-image>
                <div a-style display="flex" gap="5" mt="2.4">
                    <div a-style w="48.2">
                        <div a-style class="no_scroll" display="flex" overflowY="scroll">
                            <m-image
                                src="https://new-api.mesbro.com/uploads/{{change_image ? change_image : detail?.Media?.attributes[0]?.value[0]}}"
                                br="1px solid #e4e4e4" brRadius="0.6" w="100%" h="48.2">
                            </m-image>
                        </div>
                        <div a-style position="relative">
                            <m-image *ngIf="detail?.Media.attributes[0].value" src="../assets/images/Icon_Bank/Slider_Left_Arrow_Coloured.svg" w="2.6" h="2.6"
                                position="absolute" left="-1.5" top="4.3" shadow="0 3px 6px 0 #17171729" brRadius="50%" (click)="scrollHorizontalLeft()">
                            </m-image>
                            <m-image src="../assets/images/Icon_Bank/Slider_Right_Arrow_Coloured.svg" w="2.6" h="2.6"
                                position="absolute" right="-1.5" top="4.3" shadow="0 3px 6px 0 #17171729" brRadius="50%"
                                (click)="scrollHorizontalRight()"> </m-image>
                            <div a-style w="48.2" display="flex" gap="1.4" mt="2.4"  class="no_scroll_bar"
                                overflowX="auto" flexDirection=row id="scrollMe"> 
                                <!-- #scrollMe [minWidth]="minWidth" [maxWidth]="maxWidth" [flexGap]="gap" class="no_scroll_bar" [mt]="mt" [mb]="mb" overflowX="auto" a-style [display]="display?display:'flex'" [flexWrap]="flexWrap" [flexDirection]="flexDirection ?flexDirection: 'row' " [align]="align" [justify]="justify" [px]="px" [py]="py" -->
                                <ng-container *ngFor="let item of detail?.Media.attributes[0].value; let i=index" >
                                    <m-image *ngIf="item" id="end" src='https://new-api.mesbro.com/uploads/{{item}}'
                                        brRadius="0.6" (click)="select_image(item)" a-style class="image-1 mobile-1"
                                        w="11" h="11" brRadius=8px br="0.5px solid #e4e4e4"
                                        shadow="hov:0 3px 6px 0 #f1f1f1" bgSize="cover" bgRepeat="no-repeat">
                                    </m-image>
                                </ng-container>
                            </div>
                        </div>

                        <div a-style display="flex" gap="2.4" mt="3.5" justify="space-around">
                            <div a-style display="flex" gap="1">
                                <img src="../assets/images/Icon_Bank/Like_Coloured.svg" alt="" a-style w="2" h="2">
                                <p a-style fs="1.3">{{detail?.LikesCount?detail?.LikesCount:0}} Likes</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img [hidden]="" src="../assets/images/Icon_Bank/Dislike_Coloured.svg" alt="" a-style
                                    w="2" h="2">
                                <p a-style fs="1.3">{{detail?.disLikesCount?detail?.disLikesCount:0}} DisLikes</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img src="../assets/images/Icon_Bank/View_Coloured.svg" alt="" a-style w="2" h="2">
                                <p a-style fs="1.3">{{detail?.views}} Views</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="2" h="2">
                                <p a-style fs="1.3">{{detail?.rating}} Rating</p>
                            </div>
                        </div>
                    </div>
                    <!-- right -->
                    <div a-style w="105.6" overflowX="auto" h="calc(100vh - 7.6rem - 7.2rem - 7.2rem)" class="no_scroll">
                        <p id="top" a-style fs="1.6" align="left">{{detail?.Basic_Details.attributes[1].value}}</p>
                        <p a-style fs="1.6" mt="1.6" align="left">Price : <span a-style color="#2479AB">{{
                                detail?.attributes?.basic_details?.currency?.value ?
                                detail?.attributes?.basic_details?.currency?.value : 'INR'}}
                                {{detail?.Basic_Details.attributes[6].value ? detail.Basic_Details.attributes[6].value :
                                0}} - {{detail?.Basic_Details.attributes[7].value ?
                                detail?.Basic_Details.attributes[7].value : 0}}
                            </span></p>
                        <div a-style display="flex" align="center" justify="space-between" mt="2">
                           <div a-style display="flex" gap="2.2" align="center">
                            <p a-style fs="1.3">Rate this product</p>
                            <ng-container [ngTemplateOutlet]="rating_input"
                            [ngTemplateOutletContext]="{passed_data : {rating : rating, editable : true } }">
                        </ng-container>
                           </div>
                            <div a-style display="flex" gap="1.2" align="center">
                                <img src="{{likeColorImage}}" (click)="likeProduct(detail?._id)" alt="" a-style w="3.8"
                                    h="3.8">
                                <img src="{{disLikeColorImage}}" (click)="dislikeProduct(detail?._id)" alt="" a-style
                                    w="3.8" h="3.8">
                                <img src="{{favColorImage}}" (click)="favouriteProduct(detail._id)"
                                    *ngIf="favProduct?.message!='Success'" alt="" a-style w="3.8" h="3.8">
                                <img src="{{saveImage}}" alt="" a-style w="3.8" h="3.8" (click)="saveProduct(detail._id)">
                                <ng-container [ngTemplateOutlet]=compare
                                    [ngTemplateOutletContext]="{data:{src:'../assets/images/Icon_Bank/Compare_Grey (1).svg'}}">
                                </ng-container>
                                <!-- <img src="../assets/images/Icon_Bank/Report_Coloured2.svg" alt="" a-style w="2" h="2"
                                    (click)="report.openModal()"> -->
                            </div>
                        </div>
                        <div a-style display="flex" p="1.6" mt="1.6"   brRadius="0.6"
                            mb="2.4" bg="#EEF5FB">
                            <div a-style display="flex" gap="2.9">
                                <img [src]="organizationDetail?.files?.profile_picture? ('https://new-api.mesbro.com/uploads/'+organizationDetail?.files?.profile_picture) : '../assets/images/Icon_Bank/Organization_Default_Image.svg'"
                                    a-style w="6.8" h="6.8" brRadius="0.6">
                                <div a-style display="flex" justify="spce-between">
                                    <div a-style w="33.8">
                                        <div a-style display="flex" justify="flex-start" gap="1" align="center">
                                            <span a-style fs="1.6" color="#2479AB" align="left" cursor="pointer"
                                                (click)="gs.router.navigate(['website/home'],{queryParams : {type : 'organization',_id:detail?.parent?.parentId}});gs.organizationHistory(queryParams.hid,'business','products','add-productorganization-to-history')">{{organizationDetail?.basic_details?.name}}</span>
                                        </div>
                                        <p a-style fs="1.3">{{organizationDetail?.basic_details?.category}}</p>
                                        <p a-style fs="1.3" mt="1" align="left" *ngIf="organizationDetail?.locations">
                                            Location :
                                            <span>{{organizationDetail?.locations[0]?.city}},
                                                {{organizationDetail?.locations[0]?.state}},
                                                {{organizationDetail?.locations[0].country}}</span>
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div a-style display="flex" gap="1" flexWrap="wrap">
                                <m-button [types]="gs.enums.buttons.button0" text="Send Enquiry" px="5" minHeight="2.9" maxHeight="2.9"
                                    (click)="eq.openModal()">
                                </m-button>
                                <m-button [types]="gs.enums.buttons.button0" text="Get Quotation" px="4.5"
                                    py="0.5" >
                                </m-button>
                                <m-button [types]="gs.enums.buttons.button0" text="Follow Organization" px="2.8"
                                    >
                                </m-button>
                                <m-button [types]="gs.enums.buttons.button0" text="Get Latest Price"  px="4.2"
                                (click)="getLatestPrice()">
                                </m-button> 
                                <m-button [types]="gs.enums.buttons.button0" text="Report Organization" px="2.7"
                                    (click)="Report.openModal()">
                                </m-button> 
                                <m-button [types]="gs.enums.buttons.button0" text="Subscribe Organization" px="1.6"
                                    (click)="Report.openModal()">
                                </m-button> 
                                
                            </div>
                        </div>
                        <div a-style display="flex" justify="space-between" mb="4">
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'productInfo' ? '#2479AB' : 'black'"  (click)="gs.router.navigate(['generic','detail'],{queryParams:{ _id:detail?._id , type:'productInfo'}})">Product Information</p>
                            <p a-style fs="1.6">Seller Profile</p>
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'contact' ? '#2479AB' : 'black'"  (click)="gs.router.navigate(['generic','detail'],{queryParams:{  _id:detail?._id ,type:'contact'}})">Contact Us</p>
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'people also Ask' ? '#2479AB' : 'black'"  (click)="gs.router.navigate(['generic','detail'],{queryParams:{  _id:detail?._id , type:'people also Ask'}})">People Also Ask</p>
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'Reviews' ? '#2479AB' : 'black'"  (click)="gs.router.navigate(['generic','detail'],{queryParams:{  _id:detail?._id , type:'Reviews'}})">Reviews</p>
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'Related' ? '#2479AB' : 'black'" (click)="gs.router.navigate(['generic','detail'],{queryParams:{  _id:detail?._id , type:'Related'}})">Related</p>
                            <p a-style fs="1.6" [style.color]="queryParams.type == 'Report' ? '#2479AB' : 'black'" (click)="gs.router.navigate(['generic','detail'],{queryParams:{  _id:detail?._id , type:'Report'}})">Report Product</p>
                        </div>
                        <!-- Report -->
                        <!-- new  -->
                        <div *ngIf="detail?._id && queryParams.type=='productInfo' ">
                            <div a-style >
                                <p a-style color="#2479AB" fs="1.6">Basic Details</p>
                            <!-- <div a-style brTop="0.5px solid #e4e4e4" py="1.6" mt="1.6">
                                <ng-container *ngFor="let data of detail?.Basic_Details.attributes">
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28" [hidden]="!data.value">{{data.name}}</p>
                                        <p a-style fs="1.3">{{data.value}}</p>
                                    </div>
                                </ng-container>
                            </div> -->
                            <div a-style pb="1.6" mt="1.6">
                                <ng-container>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[0].value">
                                            {{detail?.Basic_Details.attributes[0].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[0].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[2].value">
                                            {{detail?.Basic_Details.attributes[2].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[2].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[3].value">
                                            {{detail?.Basic_Details.attributes[3].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[3].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[4].value">
                                            {{detail?.Basic_Details.attributes[4].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[4].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[8].value">
                                            {{detail?.Basic_Details.attributes[8].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[8].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details.attributes[9].value">
                                            {{detail?.Basic_Details.attributes[9].name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details.attributes[9].value}}</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28"
                                            [hidden]="!detail?.Basic_Details?.attributes[10]?.value">
                                            {{detail?.Basic_Details?.attributes[10]?.name}}</p>
                                        <p a-style fs="1.3" w="60">{{detail?.Basic_Details?.attributes[10]?.value}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <p a-style color="#2479AB" fs="1.6">Product Details</p>
                            <div a-style  pb="1.6" mt="1.6">
                                <ng-container *ngFor="let data of detail?.Product_Details.attributes">
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28" [hidden]="!data.value">{{data.name}}</p>
                                        <p a-style fs="1.3" *ngIf="data.value" w="60">{{data.value}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <p a-style color="#2479AB" fs="1.6">Technical Details</p>
                            <div a-style  pb="1.6" mt="1.6">
                                <ng-container *ngFor="let data of detail?.Technical_Details.attributes">
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28" [hidden]="!data.value">{{data.name}}</p>
                                        <p a-style fs="1.3" w="60">{{data.value}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            <p a-style color="#2479AB" fs="1.6">Other Details</p>
                            <div a-style  pb="1.6" mt="1.6">
                                <ng-container *ngFor="let data of detail?.Other_Details.attributes">
                                    <div a-style display="flex" gap="1">
                                        <p a-style fs="1.3" w="28" [hidden]="!data.value">{{data.name}}</p>
                                        <p a-style fs="1.3" w="60">{{data.value}}</p>
                                    </div>
                                </ng-container>
                            </div>
                            </div>
                        </div>
                        <!-- media -->
                        <!-- <div [hidden]="!detail?.Media.attributes[1].value">
                            <p a-style fs="1.6" color="#2479AB" mt="4" align="left">Media</p>
                            <div a-style display="flex" flexWrap="wrap" justify="center" gap="2.4" mt="1.6"
                                brTop="0.5px solid #e4e4e4" p="1.6">
                                <img src="https://new-api.mesbro.com/uploads/{{detail?.Media.attributes[1].value}}"
                                    alt="" a-style w="26" h="13.3">
                            </div>
                        </div> -->
                        <div  *ngIf="detail?._id && queryParams.type=='Reviews'">
                            <m-review></m-review>
                        </div>
                        <div *ngIf="detail?._id && queryParams.type=='Related'">
                            <p *ngIf="relatedProductList.count>=1" a-style fs="1.6" color="#2479AB" p="1.6">Related
                                Products</p>
                            <div a-style w="87.6">
                                <div a-style position="relative" display=flex flexWrap="wrap" gap="2.4"
                                     pb="1.6" pl="5.6">
                                    <ng-container *ngFor="let detail of relatedProductList.list">
                                        <m-product-card type="test" [detail]="detail"></m-product-card>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="detail?._id && queryParams.type=='contact'" a-style >
                              <div a-style display="flex">
                                <div>
                                    <div a-style display="flex" flexWrap="wrap" gap="1.6" w="61" mr="2.4" >
                                        <div a-style display="flex" gap="1.6" >
                                            <img src="" alt="" a-style w="2.5" h="2.5">
                                            <p a-style fs="1.3" align="left" w="15">{{organizationDetail?.contact_details?.telephone}}</p>
                                      </div>
                                      <div a-style display="flex" gap="1.6" >
                                        <img src="" alt="" a-style w="2.5" h="2.5">
                                        <p a-style fs="1.3"align="left" w="15">{{organizationDetail?.contact_details?.mobile}}</p>
                                     </div>
                                     <div a-style display="flex" gap="1.6">
                                    <img src="" alt="" a-style w="2.5" h="2.5">
                                    <p a-style fs="1.3"align="left" w="15">{{organizationDetail?.contact_details?.fax}}</p>
                                     </div>
                                     <div a-style display="flex" gap="1.6">
                                <img src="" alt="" a-style w="2.5" h="2.5">
                                <p a-style fs="1.3"align="left" w="15">{{organizationDetail?.contact_details?.toll}}</p>
                                     </div>
                                     <div a-style display="flex" gap="1.6">
                            <img src="" alt="" a-style w="2.5" h="2.5">
                            <p a-style fs="1.3"align="left" w="15">{{organizationDetail?.contact_details?.email}}</p>
                                     </div>
                                     <div a-style display="flex" gap="1.6">
                        <img src="" alt="" a-style w="2.5" h="2.5">
                        <p a-style fs="1.3"align="left" w="15" ellipsis="true">{{organizationDetail?.contact_details?.website}}</p>
                                    </div>
                                      </div>
                                      <div a-style display="flex" gap="2.4" mt="2.7">
                                        <div a-style w="33.8" h="10.1" bg="#FFFADD"  display="flex" flexDirection="column" justify="center" align="center" brRadius="0.6">
                                            <img src="../assets/images/Icon_Bank/Mail_Logo_Coloured.svg" alt="" a-style w="4" h="4">
                                            <p a-style fs="1.3" mt="1">Send email to organization</p>
                                        </div>
                                        <div a-style w="33.8" h="10.1"  bg="#E3F8FB"  display="flex" flexDirection="column" justify="center" align="center" brRadius="0.6">
                                            <img src="../assets/images/Icon_Bank/Chat_Logo_Coloured.svg" alt="" a-style w="4" h="4">
                                            <p a-style fs="1.3" mt="1">Chat with organization</p>
                                        </div>
                                      </div>
                                      <div *ngFor="let alternateADD of organizationDetail?.locations" a-style >
                                        <div a-style w="70" p="1.6" br="1px solid #e4e4e4" brRadius="0.6" mt="2.4">
                                            <p a-style fs="1.3">Sales Office</p>
                                            <p a-style fs="1.3">{{alternateADD?.room_no}}, {{alternateADD.floor}}, {{alternateADD?.premise}}, {{alternateADD?.road}}, {{alternateADD?.landmark}}, {{alternateADD?.locality}}, {{alternateADD?.zip}}, {{alternateADD?.city}} , {{alternateADD?.district}}, {{alternateADD?.state}}, {{alternateADD?.country}}</p>
                                          </div>
                                      </div>
                                </div>
                                  <div  a-style brLeft="1px solid #e4e4e4" ml="2.4" px="2.4">
                                    <div>
                                        <p a-style fs="1.6" color="#2479AB">Contact Person</p>
                                        <div *ngFor="let item of organizationDetail?.contact" a-style display="flex" gap="1.6" br="1px solid #e4e4e4" w="30"  p="1" mt="2.4">
                                            <img src="https://new-api.mesbro.com/uploads/{{item?.picture}}" alt="" a-style w="9" h="9">
                                            <div >
                                                <p a-style fs="1.3" color="#2479AB">{{item?.name}}</p>
                                                <p a-style fs="1.1">{{item?.designation}}</p>
                                                <p a-style fs="1.1" mt="1.6">{{item?.mobile}}</p>
                                                <p a-style fs="1.1">{{item?.email}}</p>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                        </div>
                        <div *ngIf="detail?._id && queryParams.type=='people also Ask'" a-style >
                            <div a-style br="0.5px solid #e4e4e4" p="1.6">
                                <p a-style color="#2479AB" fs="1.3">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
                                <p a-style fs="1.3" mt="1.6">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                            </div>
                        </div>
                        <div *ngIf="detail?._id && queryParams.type=='Report'" a-style w="71.1" h="59.3">
                            <p a-style fs="1.6" color="#333333">Report</p>
                            <p a-style color="#333333" fs="1.3" mt="1.6">Help us to understand what's happening in this. How would you
                                describe it ?</p>
                    
                            <div a-style display="flex" flexDirection="column">
                                <form [formGroup]="form">
                                    <ng-container >
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason"
                                                value="Misleading or scam"><label a-style fs="1.3">Misleading or scam</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason"
                                                value="Sexually inappropriate"><label a-style fs="1.3">Sexually inappropriate</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason" value="Offensive"><label
                                                a-style fs="1.3">Offensive</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4" align="center">
                                            <input type="radio" a-style h="2" w="2" fs="1.3" formControlName="reportreason"
                                                value="Show Current"><label a-style fs="1.3">Show Current</label>
                                            <!-- <p a-style color="#333333" fs="1.3">Violence</p> -->
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason"
                                                value="Advertiser is pretending to be someone else"><label a-style fs="1.3">Advertiser is
                                                pretending to be someone else</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason"
                                                value="Prohibited Content  "><label a-style fs="1.3">Prohibited Content </label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason" value="Spam"><label
                                                a-style fs="1.3">Spam</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason" value="False News"><label
                                                a-style fs="1.3">False News</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason"
                                                value="Political Candidate or issue"><label a-style fs="1.3">Political Candidate or
                                                issue</label>
                                        </div>
                    
                                        <div a-style display="flex" gap="1.6" mt="2.4">
                                            <input type="radio" a-style h="2" w="2" formControlName="reportreason" value="Other"><label
                                                a-style fs="1.3">Other</label>
                                        </div>
                                    </ng-container>
                                </form>
                                <div a-style display="flex" gap="3" justify="space-between" mt="1.9">
                                    <m-button [types]="gs.enums.buttons.button0" text="Cancel" h=2.9>
                                    </m-button>
                                    <m-button [types]="gs.enums.buttons.button0" text="Submit" h=2.9
                                        (click)="productReport(detail?._id)">
                                    </m-button>
                                </div>
                    
                            </div>
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #review let-bereview="bereview">
            <div a-style display="flex" gap="1.6" mt="1.9">
                <img src="{{bereview.src1}}" a-style w="5.3" h="5.3" brRadius="50%">
                <div a-style display="flex" flexDirection="column">
                    <div a-style h="8.4" bg="#F8F8F8" p="1.6" display="flex" flexDirection="column" align="left"
                        justify="center" gap="1">
                        <p a-style fs="1.4">{{bereview.text}}</p>
                        <p a-style fs="1.3">{{bereview.text1}}</p>
                    </div>
                    <div a-style display="flex" justify="space-between" mt="1">
                        <div a-style display="flex" gap="2.4">
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src2}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Likes</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src3}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Dislikes</p>
                            </div>
                        </div>
                        <p a-style fs="1.3">{{bereview.text2}}</p>
                    </div>
                    <div a-style display="flex" gap="1.6" mt="2.4">
                        <img src="{{bereview.src1}}" a-style w="5.3" h="5.3" brRadius="50%">
                        <div a-style display="flex" flexDirection="column">
                            <m-new-input [type]="gs.enums.inputs.newText_area" a-style minWidth="73.8" rows="5">
                            </m-new-input>
                            <div a-style display="flex" justify="space-between" mt="1">
                                <div a-style display="flex" gap="2.4">
                                    <div a-style display="flex" gap="1">
                                        <img src="{{bereview.src2}}" a-style w="2" h="2">
                                        <p a-style fs="1.3">10 Likes</p>
                                    </div>
                                    <div a-style display="flex" gap="1">
                                        <img src="{{bereview.src3}}" a-style w="2" h="2">
                                        <p a-style fs="1.3">10 Dislikes</p>
                                    </div>
                                </div>
                                <p a-style fs="1.3">{{bereview.text2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #name let-passData="passData">
            <div a-style display="flex" left="5.0">
                <p a-style w="28.0" fs="1.3" py="0.5" top="3.2">{{passData.text}}</p>
                <p a-style w="28.0" fs="1.3" py="0.5" top="3.2">{{passData.text1}}</p>
            </div>
        </ng-template>
        <div>
            <m-modal #rfq width="72.6">
                <div a-style maxWidth="72.6" h="auto" shadow="0px 3px 6px #17171728" brRadius="0.6" bg="#fff" p="2.4"
                    display="flex" flexDirection="column" gap="3" [tabindex]="1">
                    <p a-style fs="2.4" display="flex" justify="center">One Request, Multiple Quotes</p>

                    <div *ngIf="first">
                        <div a-style mb="2.4">
                            <m-input a-style [capitalize]="false" [tabindex]="2"
                                prefix="../assets/images/assets/mesbro_logo.svg" (postfix_clicked)='onKeyUp($event)'
                                (keydown.enter)="onKeyUp($event)" h="4.6" minWidth="62.8" mb="0" outline=none px=0
                                fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" outline=none
                                postfix="../assets/images/assets/new-organization/Text search icon.svg"
                                placeHolder="Search Product Here" display="900:none">
                            </m-input>
                        </div>
                        <div a-style display="flex" gap="2.4" flexWrap="wrap">
                            <m-new-input tabindex="" [type]="gs.enums.inputs.dropdown" placeHolder="Primary Category"
                                label="Primary Category" minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Secondary Category"
                                label="Secondary Category" minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Tertiary Category"
                                label="Tertiary Category" minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Product Type"
                                label="Product Type" minWidth="32.7"></m-new-input>
                            <div a-style display="flex" flexDirection="column" gap="1.6">
                                <p a-style align="left" fs="1.3">Quantity</p>
                                <input type="text" placeholder="Quality" outline="none" a-style br="0.5px solid #e4e4e4"
                                    brRadius="0.6" w="32.7" h="3.9" p="1">
                            </div>
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Unit" label="Unit"
                                minWidth="32.7">
                            </m-new-input>
                        </div>
                        <div a-style w="67.8" display="flex" justify="flex-end" mt="2.4">
                            <m-button [types]="gs.enums.buttons.button0" text="Next" h=2.9
                                (click)="nfq.openModal() ; rfq.closeModal()"></m-button>
                        </div>
                    </div>
                </div>
            </m-modal>

            <m-modal #nfq width="72.6">
                <div a-style maxWidth="72.6" h="auto" shadow="0px 3px 6px #17171728" brRadius="0.6" bg="#fff" p="2.4"
                    display="flex" flexDirection="column" gap="3" [tabindex]="1">
                    <p a-style fs="2.4" display="flex" justify="center">One Request, Multiple Quotes</p>

                    <div a-style display="flex" gap="2.4" flexWrap="wrap">
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Sourcing Type" label="Sourcing Type"
                            minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Sourcing Purpose"
                            label="Sourcing Purpose" minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Trade Terms" label="Trade Terms"
                            minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Payment Term" label="Payment Term"
                            minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Shipping Method"
                            label="Shipping Method" minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Destination sea port"
                            label="Destination sea port" minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Destination AirPort"
                            label="Destination AirPort" minWidth="32.7"></m-new-input>
                        <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Location" label="Location"
                            minWidth="32.7">
                        </m-new-input>
                        <div a-style w="67.8" display="flex" justify="space-between">
                            <m-button [types]="gs.enums.buttons.button0" text="Back" h=2.9
                                (click)="rfq.openModal() ; nfq.closeModal() "></m-button>
                            <m-button [types]="gs.enums.buttons.button0" text="Next" h=2.9
                                (click)="mfq.openModal() ; nfq.closeModal()"></m-button>
                        </div>
                    </div>
                </div>
            </m-modal>

            <m-modal #mfq width="72.6">
                <div a-style maxWidth="72.6" h="auto" shadow="0px 3px 6px #17171728" brRadius="0.6" bg="#fff" p="2.4"
                    display="flex" flexDirection="column" gap="3" [tabindex]="1">
                    <p a-style fs="2.4" display="flex" justify="center">One Request, Multiple Quotes</p>

                    <div>
                        <div a-style display="flex" gap="2.4" flexWrap="wrap" mt="2">
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Currency" label="Currency"
                                minWidth="32.7">
                            </m-new-input>
                            <m-new-input [type]="gs.enums.inputs.dropdown" placeHolder="Max Budget" label="Max Budget"
                                minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Expected Delivery Time In Days"
                                label="Expected Delivery Time In Days" minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Expected Certifications"
                                label="Expected Certifications" minWidth="32.7"></m-new-input>
                            <m-new-input [type]="gs.enums.inputs.newText_area"
                                placeHolder=" sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem"
                                label="Is there any other requirement details like specification, usage, preference, etc. that you'd like to tell us?"
                                rows="3">
                            </m-new-input>
                        </div>
                        <div a-style display="flex" align="end" gap="0.6" mt="2.4">
                            <m-new-input [type]="gs.enums.inputs.newCheckbox"></m-new-input>
                            <p a-style fs="1.3">I agree to<span a-style color="#2479AB">Terms of Service</span> and send
                                this RFQ to
                                the all suppliers who can fulfill the requirement.</p>
                        </div>
                        <div a-style display="flex" justify="space-between" mt="2.7">
                            <m-button [types]="gs.enums.buttons.button0" text="Back" h=2.9
                                (click)="nfq.openModal() ; mfq.closeModal()"></m-button>
                            <m-button [types]="gs.enums.buttons.button0" text="Submit" h=2.9
                                (click)="zfq.openModal() ; mfq.closeModal()"></m-button>
                        </div>
                    </div>
                </div>
            </m-modal>

            <m-modal #zfq width="56.6">
                <div a-style w="100%" h="27.5" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center"
                    shadow="0px 3px 6px #17171728">
                    <div a-style h="21.5" display="flex" flexDirection="column" align="center" gap="2.4"
                        (click)="zfq.closeModal()">
                        <img src="../assets/images/GIF_Bank/Other/Green_Tick.gif" alt="" a-style w="14" h="14">
                        <p a-style fs="2">RFQ Successfully Submitted</p>
                        <p a-style fs="1.3" color="#2479AB">Rest assured to get faster and better response from the
                            right
                            suppliers. Let
                            suppliers give you the best deal for your request.</p>
                    </div>
                </div>
            </m-modal>

        </div>
    </div>

</div>
<m-modal #eq width="72.6">
    <div a-style p="2.4">
        <p a-style fs="1.6">Send Enquiry</p>
        <div a-style display="flex" flexWrap="wrap" gap="2.4" mt="2.4">
            <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Quantity" [(ngModel)]="quantity" label="Quantity"
                minWidth="32.7">
            </m-new-input>
            <m-input [type]="gs.enums.inputs.dropdown" label="Unit" minWidth="32.7" maxWidth="32.7" [(ngModel)]="unit"
                bindLabel="label" label="Unit" placeHolder="Unit" [list]="gs.masters['Unit']" cleareIcon="true">
            </m-input>
            <m-new-input [type]="gs.enums.inputs.newText_area" placeHolder="" [(ngModel)]="description"
                label="Is there any other requirement details like specification, usage, preference, etc. that you'd like to tell us?"
                rows="3">
            </m-new-input>
        </div>
        <div a-style display="flex" align="end" gap="0.6" mt="2.4">
            <m-new-input [type]="gs.enums.inputs.newCheckbox" [(ngModel)]="terms"></m-new-input>
            <p a-style fs="1.3">I agree to<span a-style color="#2479AB"> Terms of Service </span> & send this
                enquiry to supplier.</p>
        </div>
        <div a-style display="flex" justify="space-between" mt="2.4">
            <m-button [types]="gs.enums.buttons.button0" text=" Cancel" (click)="eq.closeModal()"></m-button>
            <m-button [types]="gs.enums.buttons.button0" text=" Send Enquiry"
                (click)="sendEnquiry();eq.closeModal();gs.toast('Success', 'Enquiry Send Successfully', 'success')">
            </m-button>
        </div>
    </div>
</m-modal>
<!-- Quotation -->
<m-modal #eqSucess>
    <div a-style w="56.0" h="27.5" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center" align="center"
        shadow="0px 3px 6px #17171728" w="100%" top="14" left="12">
        <div a-style w="47.6" h="21.5" display="flex" flexDirection="column" align="center" gap="2.4">
            <img src="../assets/images/GIF_Bank/Other/Green_Tick.gif" alt="" a-style w="10" h="10">
            <p a-style fs="2">Request Submitted</p>
            <p a-style fs="1.3" color="#2479AB">Your request for the latest price has been sent to the seller, you
                will get notified as soon as possible.</p>
        </div>
    </div>
</m-modal>
<m-modal #Report width="72.6">
    <div a-style p="2.4">
        <p a-style fs="2.4" align="center">Request Report</p>
        <div a-style mt="2.4">
            <m-new-input [type]="gs.enums.inputs.newText_area" [(ngModel)]="reportDescription" placeHolder=""
                label="Reason for report" rows="3">
            </m-new-input>
        </div>
        <div a-style display="flex" align="end" gap="0.6" mt="2.4">
            <m-new-input [type]="gs.enums.inputs.newCheckbox"></m-new-input>
            <p a-style fs="1.3">I agree to<span a-style color="#2479AB">Terms of Service</span></p>
        </div>
        <div a-style display="flex" justify="flex-end" mt="2.4">
            <m-button [types]="gs.enums.buttons.button0" text="Request Report"
                (click)="reportproduct(detail?.parent?.parentId);Report.closeModal()">
            </m-button>
        </div>
    </div>
</m-modal>
<m-modal #qr width="43.4">
    <m-bar-code [details]="detail"></m-bar-code>
</m-modal>
<m-modal #print width="63.0">
    <div class="no_scroll" a-style py="2.7" px="4" overflowX="auto" h="80">
        <div a-style display="flex" align="center" justify="space-between">
            <div a-style display="flex">
                <img src="" alt="" a-style ml="0.8">
                <p a-style fs="1.3">MPIN : </p>
                <p a-style fs="1.3" color="#2479AB"> 98756789766789</p>
            </div>
            <img src="https://mesbro.com/assets/images/mesbro.svg" alt="" a-style w="12.6" h="4.8">
        </div>
        <img src="../assets/images/ME/line_image.svg" alt="line" a-style py="1.6">
        <p a-style fs="1.3">Supreme Fusion 02 Multi Purpose for Home Medium Size Plastic Cupboard (Finish Color -
            Dark Beige & Globus Brown)</p>
        <div a-style display="flex" gap="1.6" mt="1.6">
            <img src="../assets/images/ME/NoPath.svg" alt="" a-style w="18.5" h="18.5">
            <div>
                <p a-style fs="1.3">Price : INR 42000 - 50000 Negotiable</p>
                <p a-style fs="1.3">Origin Of Goods : India</p>
                <p a-style fs="1.3">Min Order Quantity : 500 Pieces</p>
                <p a-style fs="1.3" mt="1.8" color="#2479AB">BHARAT FURNITURE PVT LTD</p>
                <p a-style fs="1.3">Mumbai, Maharashtra, India</p>
                <p a-style fs="1.3">+91 7057218152, +91 9876543211</p>
                <p a-style fs="1.3">vbs209ulb@gmail.com</p>
                <p a-style fs="1.3">www.ertyuiou.com</p>
            </div>
        </div>
        <p a-style fs="1.3" mt="1.6">Basic Details</p>
        <div a-style display="flex" justify="space-between" py="1.6" brTop="1px solid #e4e4e4" mt="1.6">
            <div a-style w="22.4">
                <p a-style fs="1.3">Model Number</p>
                <p a-style fs="1.3">Type</p>
                <p a-style fs="1.3">Dimensionr</p>
                <p a-style fs="1.3">Power</p>
                <p a-style fs="1.3">Voltage</p>
                <p a-style fs="1.3">Phase</p>
                <p a-style fs="1.3">High Speed Printing</p>
                <p a-style fs="1.3">Speed</p>
                <p a-style fs="1.3">ODM</p>
                <p a-style fs="1.3">Capacity</p>
                <p a-style fs="1.3">Automatic Grade</p>
            </div>
            <div a-style w="43">
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
                <p a-style fs="1.3">1-8 Color</p>
                <p a-style fs="1.3">RP4554JJ</p>
                <p a-style fs="1.3">14200 kg</p>
                <p a-style fs="1.3">13950X3100X2600 mm</p>
                <p a-style fs="1.3">50 Piece/Month</p>
                <p a-style fs="1.3">380 V</p>
                <p a-style fs="1.3">3 Phase</p>
                <p a-style fs="1.3">10-150m/min</p>
                <p a-style fs="1.3">Automatic</p>
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
            </div>
        </div>
        <p a-style fs="1.3" mt="1.6">Technical Details</p>
        <div a-style display="flex" justify="space-between" py="1.6" brTop="1px solid #e4e4e4" mt="1.6">
            <div a-style w="22.4">
                <p a-style fs="1.3">Model Number</p>
                <p a-style fs="1.3">Type</p>
                <p a-style fs="1.3">Dimensionr</p>
                <p a-style fs="1.3">Power</p>
                <p a-style fs="1.3">Voltage</p>
                <p a-style fs="1.3">Phase</p>
                <p a-style fs="1.3">High Speed Printing</p>
                <p a-style fs="1.3">Speed</p>
                <p a-style fs="1.3">ODM</p>
                <p a-style fs="1.3">Capacity</p>
                <p a-style fs="1.3">Automatic Grade</p>
            </div>
            <div a-style w="43">
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
                <p a-style fs="1.3">1-8 Color</p>
                <p a-style fs="1.3">RP4554JJ</p>
                <p a-style fs="1.3">14200 kg</p>
                <p a-style fs="1.3">13950X3100X2600 mm</p>
                <p a-style fs="1.3">50 Piece/Month</p>
                <p a-style fs="1.3">380 V</p>
                <p a-style fs="1.3">3 Phase</p>
                <p a-style fs="1.3">10-150m/min</p>
                <p a-style fs="1.3">Automatic</p>
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
            </div>
        </div>
        <p a-style fs="1.3" mt="1.6">Other Details</p>
        <div a-style display="flex" justify="space-between" py="1.6" brTop="1px solid #e4e4e4" mt="1.6">
            <div a-style w="22.4">
                <p a-style fs="1.3">Model Number</p>
                <p a-style fs="1.3">Type</p>
                <p a-style fs="1.3">Dimensionr</p>
                <p a-style fs="1.3">Power</p>
                <p a-style fs="1.3">Voltage</p>
                <p a-style fs="1.3">Phase</p>
                <p a-style fs="1.3">High Speed Printing</p>
                <p a-style fs="1.3">Speed</p>
                <p a-style fs="1.3">ODM</p>
                <p a-style fs="1.3">Capacity</p>
                <p a-style fs="1.3">Automatic Grade</p>
            </div>
            <div a-style w="43">
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
                <p a-style fs="1.3">1-8 Color</p>
                <p a-style fs="1.3">RP4554JJ</p>
                <p a-style fs="1.3">14200 kg</p>
                <p a-style fs="1.3">13950X3100X2600 mm</p>
                <p a-style fs="1.3">50 Piece/Month</p>
                <p a-style fs="1.3">380 V</p>
                <p a-style fs="1.3">3 Phase</p>
                <p a-style fs="1.3">10-150m/min</p>
                <p a-style fs="1.3">Automatic</p>
                <p a-style fs="1.3">Rotogravure Printing Machine</p>
            </div>
        </div>
    </div>
</m-modal>
<ng-template #compare let-data=data>
    <div a-style display="flex" align="center" title="Add To Compare">
        <m-image *ngIf="data" [src]="data?.src" w="3.8" h="3.8" [hover]="data.hover_src" [current_value]="current"
            (click)="isVisible_compare=!isVisible_compare;add_to_compare()"></m-image>
    </div>
</ng-template>
<ng-template #rating_input let-passed_data="passed_data">
    <div a-style display="flex" align=center>
        <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:3.2"
            [style.color]="passed_data.rating<1? '#f1f1f1' : 'gold'"
            (click)="passed_data.editable && rating=1;Rating()">★
        </p>
        <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:3.2"
            [style.color]="passed_data.rating<2? '#f1f1f1' : 'gold'"
            (click)="passed_data.editable && rating=2;Rating()">★
        </p>
        <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:3.2"
            [style.color]="passed_data.rating<3? '#f1f1f1' : 'gold'"
            (click)="passed_data.editable && rating=3;Rating()">★
        </p>
        <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:3.2"
            [style.color]="passed_data.rating<4? '#f1f1f1' : 'gold'"
            (click)="passed_data.editable && rating=4;Rating()">★
        </p>
        <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:3.2"
            [style.color]="passed_data.rating<5? '#f1f1f1' : 'gold'"
            (click)="passed_data.editable && rating=5;Rating()">★
        </p>
        <!-- {{rating}} -->
    </div>
</ng-template>


   
