<div a-style maxHeight="76" >
    <div a-style  pb="1.6">
        <p a-style fs="1.6" align="left" color="#2479AB">Reviews</p>
    </div>
    <form [formGroup]="form">
        <div a-style display="flex" flexDirection="column" gap="2.4">
            <div a-style display="flex" align="center" gap="1.6" mt="1.4" h="5.3" >
                <img [src]="users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.profile_picture) : 'https://new-api.mesbro.com/uploads/old_files/assets/Avatar_24.svg'" a-style w="5.3" h="5.3" brRadius="50%">
                <div a-style display="flex" w="80.7" h="5.3" br="0.5px solid #e4e4e4" align="center" brRadius="0.6"
                    justify="space-between" px="1.6">
                    <input type="text" a-style w="70" h="3.2" outline="none" br="none" placeHolder="Write here.."
                        formControlName="review">
                    <m-input (ngModelChange)="gs.toggle_display()" [type]="gs.enums.inputs.mail_attachments" cursor="pointer"
                    formControlName="images" [onlyInput]="true" [columns]="false" [aspect_ratio]="0.4">
                    <!-- <m-image src="../assets/images/B2B/attchment_coloured.svg" a-style w="2.4" h="2.4"
                       ></m-image> -->
                </m-input>
                </div>
            </div>
            <div a-style align="right">
                <button a-style w="8.5" h="2.9" br="1px solid #2479AB" brRadius="6px" bg="#fff" display="flex"
                    justify="center" align="center" (click)="submit()">Submit</button>
            </div>
        </div>
    </form>
    <div a-style  overflowY="auto" h="40" class="no_scroll">
        <div a-style display="flex" justify="flex-end" gap="1.6" mt="1.9" *ngFor="let review of list.list">
            <div a-style display="flex" flexDirection="column">
                <div a-style  w="86.6" bg="#F8F8F8" p="1.6" display="flex" flexDirection="column" align="left"
                    justify="center" gap="1" brRadius="0.6">
                    <p a-style fs="1.4">{{review.meta.activity.updated_by.first_name}} {{review.meta.activity.updated_by.last_name}}</p>
          <p a-style fs="1.3">{{review?.data?.review}}</p>
          <div a-style display="flex" justify="space-between">
            <!-- <div a-style display="flex" gap="2.4">
                <div a-style display="flex" gap="1">
                    <img src="../assets/images/Icon_Bank/Like_Coloured.svg" a-style w="2" h="2">
                    <p a-style fs="1.3">0 Likes</p>
                </div>
                <div a-style display="flex" gap="1">
                    <img src="../assets/images/Icon_Bank/Dislike_Coloured.svg" a-style w="2" h="2">
                    <p a-style fs="1.3">0 Dislikes</p>
                </div>
            </div> -->
            <p a-style fs="1.3" color="#e4e4e4">{{review.meta.activity?.updated_at | date : 'dd MMM YYYY'}} {{review.meta.activity?.updated_at | date : 'shortTime'}}</p>
        </div>
                </div>
                
              
            </div>
        </div>
    </div>
 
      <!-- <div a-style display="flex" gap="1.6" mt="2.4">
                <img src="{{bereview.src1}}" a-style w="5.3" h="5.3" brRadius="50%">
                <div a-style display="flex" flexDirection="column">
                    <m-new-input [type]="gs.enums.inputs.newText_area" a-style minWidth="73.8" rows="5"></m-new-input>
                    <div a-style display="flex" justify="space-between" mt="1">
                        <div a-style display="flex" gap="2.4">
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src2}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Likes</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src3}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Dislikes</p>
                            </div>
                        </div>
                        <p a-style fs="1.3">{{bereview.text2}}</p>
                    </div>
                </div>
            </div> -->

</div>

<!-- *ngIf="!Readmore" (click)="Readmore=true" -->
<div a-style mt="1.6" align="right" >
    <!-- <button a-style w="8.5" h="2.9" br="1px solid #2479AB" brRadius="6px" bg="#fff"
        display="flex" justify="center" align="Center">View More</button> -->
    <m-button *ngIf="list.count >= 4" [types]="gs.enums.buttons.button0" text="View More" h=2.9 (click)="viewMore()"></m-button>
</div>
<ng-template #review let-bereview="bereview">
    <div a-style display="flex" gap="1.6" mt="1.9">
        <img src="{{bereview.src1}}" a-style w="5.3" h="5.3" brRadius="50%" br="1px solid red"> 
        <div a-style display="flex" flexDirection="column">
            <div a-style h="8.4" bg="#F8F8F8" p="1.6" display="flex" flexDirection="column" align="left"
                justify="center" gap="1">
                <p a-style fs="1.4">{{bereview.text}}</p>
                <p a-style fs="1.3">{{bereview.text1}}</p>
            </div>
            <div a-style display="flex" justify="space-between" mt="1">
                <div a-style display="flex" gap="2.4">
                    <div a-style display="flex" gap="1">
                        <img src="{{bereview.src2}}" a-style w="2" h="2">
                        <p a-style fs="1.3">10 Likes</p>
                    </div>
                    <div a-style display="flex" gap="1">
                        <img src="{{bereview.src3}}" a-style w="2" h="2">
                        <p a-style fs="1.3">10 Dislikes</p>
                    </div>
                </div>
                <p a-style fs="1.3">{{bereview.text2}}</p>
            </div>

            <!-- <div a-style display="flex" gap="1.6" mt="2.4">
                <img src="{{bereview.src1}}" a-style w="5.3" h="5.3" brRadius="50%">
                <div a-style display="flex" flexDirection="column">
                    <m-new-input [type]="gs.enums.inputs.newText_area" a-style minWidth="73.8" rows="5"></m-new-input>
                    <div a-style display="flex" justify="space-between" mt="1">
                        <div a-style display="flex" gap="2.4">
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src2}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Likes</p>
                            </div>
                            <div a-style display="flex" gap="1">
                                <img src="{{bereview.src3}}" a-style w="2" h="2">
                                <p a-style fs="1.3">10 Dislikes</p>
                            </div>
                        </div>
                        <p a-style fs="1.3">{{bereview.text2}}</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>