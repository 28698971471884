<div a-style display="flex">
  <div a-style bg="#1A73E8" p="4" textWrap='nowrap'>
      <m-text text="Add Your Campaign" [type]="gs.enums.texts.paragraph56" mb="4"></m-text>
      <div a-style display=flex flexDirection=column h=10>
          <div a-style display=flex alignItems=flex-end>
              <div a-style brRadius=50% h=1.8 w=1.8 bg="white" display=grid placeContent=center mr=1.5>1</div>
              <m-text text="Enter Campaign" [type]="gs.enums.texts.paragraph57"></m-text>
          </div>
          <!-- <div a-style flexGrow=1 w=.15 bg=white ml=0.8>
          </div> -->
          <!-- <div a-style display=flex>
              <div a-style brRadius=50% h=1.8 w=1.8 bg="white" display=grid placeContent=center mr=1.5>2</div>
              <m-text text="Verify Ownwership" [type]="gs.enums.texts.paragraph57"></m-text>
          </div> -->
      </div>
  </div>
  <!-- <div a-style br="1px solid #e2e2e2" display="flex" flexDirection='column' justify="space-between" bg="white" py="4" px="2.4">
      <m-form columns="2"  [formGroup]="form" w=55 [paddingNone]=false [brNone]="false">
          <ng-container formGroupName="data">
              <div *ngIf="!verify">
                  <m-text text="Lorem ipsum dolors it amet, consetetur sadipscing elitr, sed diam nonx eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." [type]="gs.enums.texts.paragraph23" mb="2.4"></m-text>
                  <div a-style display="flex" justify="space-between">
                      <m-input  formControlName='campaign_name' label="Campaign Name" clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" [columns]="1/2" placeHolder="Campaign Name" pl="none" minWidth="unset"></m-input>
                      <m-input  formControlName='campaign_note' label=" Note" clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" [columns]="1/2" placeHolder="Campaign Name" pl="none" minWidth="unset"></m-input>
                    </div>
                    <m-input [columns]="1/2" flexGrow=0 [aspect_ratio]='1' mr=0 label="Profile Picture" formControlName='campaign_picture' [type]="gs.enums.inputs.media"></m-input>
              </div>
          </ng-container>
      </m-form>
      <div a-style display="flex" justify="flex-end" gap=1.6 px=1.5 mt=8>
           <m-button  [types]="gs.enums.buttons.button7" a-style fs="1.4" w="35" text="Submit" (click)="submit();"></m-button>
      </div>
  </div> -->
</div>
