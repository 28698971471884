import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-billing-details',
  templateUrl: './billing-details.component.html',
  styles: [
  ]
})
export class BillingDetailsComponent implements OnInit {
  @Input() details:any
  @Input() billing_id:any;
  queryParams:{
    billing_id:'',
    _id:''
  }
  AddBillingAddress:boolean=true
  form: FormGroup | undefined;
  constructor(public gs:GlobalService, public ar:ActivatedRoute , public fb:FormBuilder) { }

  async ngOnInit() {
    console.log(this.details)

    this.ar.queryParams.subscribe(async (params: any) => {
      this.queryParams = params
    })
    
    this.initialize_form()
      let details = await this.gs.hps.get('generic', 'data', this.details); //update list
      this.form?.patchValue(details)
      this.gs.toast('Billing Details', 'Modified Successfully')
  }

  initialize_form(){
    this.form = this.fb.group({
      application:'Generic',
      component:'Billing',
      orgName:'',
      personName: '',
      billingAdd: '',
      mobile: '',
      email: '',
      gstin: '',
    })
  }

  async submit(){
    let body = this.form?.value
    let response = await this.gs.hps.post('generic' , 'data' , 'add' , body);
    this.gs.toast('Billing Details', 'Submitted Successfully')
    this.form?.reset()
  }

}
