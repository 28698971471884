import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
// import { IUser, IUserData } from '../../interfaces/i-user';



@Component({
  selector: 'm-organization-block',
  templateUrl: './organization-block.component.html',
  styles: [
  ]
})


export class OrganizationBlockComponent implements OnInit {
  is_visible: boolean = false;

  @Input() detail: any
  @Input() w: any


  categories: any[] = []
  list_url = {
    chat: `/chat/chat-details`,
    mail: `/mail/home`,
  }
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {

    console.log(this.detail);

  }

  select_application(data: any) {
    if (data == "chat") {
      this.gs.router.navigateByUrl(this.list_url.chat);
    }
    else {
      this.gs.router.navigateByUrl(this.list_url.mail);
    }
  }

}
