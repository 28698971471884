<m-organization-header> </m-organization-header>
<!-- sidebar -->
<div a-style display="flex" h="calc(100vh - 15rem)">
	<!-- SideBar -->
	<div a-style minWidth=49.1 h="calc(100vh-149px)" brRight="1px solid #e4e4e4" overflowY=auto class="no_scroll_bar">
		<div a-style p="2.4" bg="" opacity="1" w="49.1">
			<div a-style align="center" display="flex" gap="1.6" mb="2.4">
				<img src="../assets/images/ME/B2B_Product_Coloured_42x42.svg" alt="" h='4.2' a-style w="4.2">
				<p a-style color="#333" fs="2" m="0">Add B2B Product</p>
			</div>
			<div a-style display="flex" gap="2.4" align="center">
				<div a-style display="flex" gap="3.8"  flexDirection="column">
					<ng-container *ngFor="let image of images">
						<img src="{{image}}" alt="" h='3' a-style w="3">
					</ng-container>
				</div>
				<div  a-style a-style display="flex" gap="4.3" flexDirection="column">
					<ng-container *ngFor="let groupHeading of groupHeadings">
							<p a-style color="#333" fs="1.6" m="0" class="lineClamp1" align="left" (click)="setHeadingValue(groupHeading)" >
								{{ groupHeading }}</p>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div>
		<!-- subHeader -->
		<div a-style display="flex" align="center" justify="space-between" py="1" px="2.4"
			brBottom="0.5px solid #e4e4e4" w="calc(100vw - 49.1rem)">
			<div a-style display="flex" gap="2.4" align="center">
				<img a-style h=4.2 w=4.2 src="../assets/images/assets/Basic Details (1).svg">
				<p a-style m=0 fs=2 color=#333333>{{subHeadingCurrent | replaceUnder : '_':' ' }} </p>
			</div>
			<div a-style display="flex" gap="2.4" justify="flex-end">
				<button a-style bg="#fff" brRadius="0.6" opacity="1" br="1px solid #2479AB" py="0.5" px="2" color="#333"
					fs="1.3" m="0" (click)="handlePrevBtn()" [hidden]="attGrpHeadIndex == 0">Prev</button>
				<button a-style bg="#fff" brRadius="0.6" opacity="1" br="1px solid #2479AB" py="0.5" px="2" color="#333"
					fs="1.3" m="0" (click)="handleNextBtn()"
					[hidden]="attGrpHeadIndex >= attGrpHead.length - 1">Next</button>
				<button a-style bg="#fff" brRadius="0.6" opacity="1" br="1px solid #2479AB" py="0.5" px="2" color="#333"
					[hidden]="attGrpHeadIndex != attGrpHead.length - 1" fs="1.3" m="0"
					(click)="submit()">Submit</button>
			</div>
		</div>
		<!-- form -->
		<div a-style>
			<div *ngIf="queryParams.step == 'Form'" a-style bg="white">
				<div a-style h="100%">
					<div>
						<div a-style brRadius="0.6" p="2.4" w="138.1" a-style>
							<form a-style [formGroup]="attForm">
								<div a-style>
									<m-form [brNone]=false [paddingNone]=0 columns="4" >
										<div *ngFor="let attribute of allAttributes">
											<div [hidden]="attribute.groupHeading != subHeadingCurrent" a-style mr="0.5">
												<ng-container>
													<m-input a-style minWidth="32.7" maxWidth="32.7" [capitalize]=false
														[uppercases]=false [list]="attribute.dropdowns" flexGrow=0
														desc_width=24.5 desc_height=6.8 [aspect_ratio]='1' 
														label="Gallery" desc_width=24.5 desc_height=6.8 multiple="true"
														[formControlName]="attribute.id" bindLabel=name bindValue=name
														[type]="attribute | attributeType | lowercase" brBottom=true mr="1"
														fs="1.3" placeHolder="{{attribute.name}}"
														label="{{attribute.name}}" [columns]="1" mt="0" px="0" tabIndex="1"
														clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg">
													</m-input>
												</ng-container>
											</div>
										</div>
										<div *ngFor="let groupHeading of groupHeadings">
											<div [hidden]="groupHeading != 'Media' || subHeadingCurrent != 'Media'">
												<ng-container
													*ngFor="let data of attForm?.value['1ddd3ed4-aec4-468b-b5c1-167be2a708d4']">
													<img src="https://new-api.mesbro.com/uploads/{{data}}" a-style
														w="20" h="20">
												</ng-container>
											</div>
										</div>
										<div *ngFor="let groupHeading of groupHeadings">
											<div [hidden]="groupHeading != 'FAQ' || subHeadingCurrent != 'FAQ'">
												<div *ngFor="let faq of detail?.basic.faqs">
													<ul>
														<li>
															<p>{{faq.question}}</p>
														</li>
														<m-input minWidth="35;321:30" placeHolder="Faq Content" mb=0 formControlName="answer">
														</m-input>
													</ul>
												</div>
											</div>
										</div>
									</m-form>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>