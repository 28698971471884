import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-call',
  templateUrl: './call.component.html',
  styles: [
  ]
})
export class CallComponent implements OnInit {
isVisible:boolean=false;
  constructor(public gs : GlobalService) { }

  ngOnInit(): void {
  }
  
  openModal(){
    this.isVisible=true;
  }

  closeModal(){
    this.isVisible=false;
  }
}
