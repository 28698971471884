<div class="modal" a-style w=40 h=8 bg=#fffff brRadius="0.6" position="fixed" top="6.3" zIndex=1 right="0.7" bg="white" zIndex="10000" minHeight=45 maxHeight=20 class="no_scroll_bar" overflowY=auto>
    <div a-style display="flex" br="1px solid #E4E4E4" justify="space-between" px=1.5 align="center">
        <div a-style display="flex" h=4.8 display="flex" justify="space-between" align="center">
            <img a-style src="https://mesbro.in/assets/images/notification-bell.svg" h="2.4" w="2" mr="1.6">
            <m-text [type]="gs.enums.texts.paragraph38" text="Notifications"> </m-text>
        </div>
        <m-text [type]="gs.enums.texts.paragraph52" text="Clear" (click)="list.list=[]; list.count=0; $event.stopPropagation() "> </m-text>
    </div>
    <div a-style  p="1.5" bg=#F8F8F8 br="1px solid #E4E4E4" class="no_scroll_bar" overflowY=auto maxHeight="45" minHeight="20" >
        <ng-container *ngFor="let item of list?.list;let i=index" [ngTemplateOutlet]="notification" [ngTemplateOutletContext]="{passedData : item}"></ng-container>
        <div *ngIf="!list.count" a-style bg=#F8F8F8 display=grid placeContent="center" flexDirection=column cursor=pointer flexGap=2 h="50%">
            <img src="https://www.pinclipart.com/picdir/middle/185-1850576_png-file-white-bell-notification-icon-transparent-clipart.png" alt="" a-style ml="5.4" mt=5 w="auto" h="5" srcset="">
            <m-text [type]="gs.enums.texts.paragraph38" text=" No notifications to read"> </m-text>
        </div>
    </div>
</div>

<ng-template let-passeData="passedData" #notification>
    <div a-style w="36.8" bg=#ffffff h="9.2" mb="1" p="1.5" brRadius="0.4">
        <div a-style display="flex" gap="2">
            <img *ngIf="users?.activeUser?.token" a-style cursor="pointer" [src]=" 'https://new-api.mesbro.com/uploads/'+passeData?.user?.personal_details?.profile_picture" h="6" w="6" ml="1.6" brRadius="0.5">
            <div a-style>
                <div a-style display="flex" justify="space-between" w="24" h="2.5">
                    <p a-style fs="1.3" fw="500" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;">{{passeData?.component || passeData?.title}} </p>
                    <m-text text="17 Mar,2021" [type]="gs.enums.texts.paragraph107" w="7"></m-text>
                </div>
                <p a-style fs="1.3" fw="500" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;">{{passeData?.notification || passeData?.message}} </p>
            </div>
        </div>
    </div>
</ng-template>
