import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-user-list',
  templateUrl: './user-list.component.html',
  styles: [
  ]
})
export class UserListComponent implements OnInit {
  list = {
    list: [],
    count: 0
  }
  @Input() modal:any
  application = 'domain';
  component = 'user';
  isVisible: boolean = false;

  visible: any = {
    dots: false,
  }

  constructor(public gs: GlobalService) { }

  async get_list() {
    let response = await this.gs.hps.post(this.application, this.component, 'list', {})
    this.list = response;
    // console.log(this.list);

  }

  async ngOnInit() {
    await this.get_list()
    // console.log(this.list);
    let getDetail: any = localStorage.getItem('testing')
    let data = JSON.parse(getDetail)
    // console.log(data);


  }

 

  async delete(id: any, index: number) {
    await this.gs.hps.delete(this.application,this.component,id)
    this.list.list.splice(index, 1);
  }

  open_pop_over(name: 'dots' ) {
    setTimeout(() => {
      if (this.visible[name]) {
        this.visible[name] = false
      } else {
        this.visible[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
  }

  close_other_pop_overs(name: 'dots') {
    Object.keys(this.visible).forEach((key: any) => {
      if (key != name) {
        this.visible[key] = false;
      }
    })
  }

}


