import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any[] {
    let search_term: string = args[0];
    let filtered_array = value.filter(element => {
      let string_value = typeof element == 'object' ? JSON.stringify(element) : element;
      return string_value.toLowerCase().includes(search_term?.toLowerCase() || '')
    })
    return filtered_array
  }

}
