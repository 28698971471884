<div a-style mx=2.4 mt=7.4 brRadius=0.6 bg="#ffffff" br="1px solid #e4e4e4">
  <div a-style brBottom="1px solid #e4e4e4" p=2.4>
    <m-text [type]="gs.enums.texts.paragraph12" text="User List {{list?.count}}"></m-text>
  </div>
  <div a-style w=100% overflowX="auto;460:scroll" class="no_scroll_bar">
   
      <div a-style p=2.4 w=100% display=flex justify="space-between" align="flex-end">
        <m-input flexGrow=none label="Search" minWidth="35;450:30" maxWidth="450:30" mb=0 px=0 flexGrow="0;1024:1;1280:1;1336:1" [columns]="1/4" cleareIcon="true"></m-input>
        <div>
          <m-button [types]="gs.enums.buttons.button32" text="Add User" py=1.2 px=2.4 (click)="add_user.openModal();" br="none"></m-button>
      </div>
      </div>
      <div a-style p=2.4>
        <table a-style br="none">
          <tr>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text>
            </th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Profile"></m-text>
            </th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Name"></m-text>
            </th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Email Id"></m-text>
            </th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Mobile Number"></m-text>
            </th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Edit"></m-text></th>
            <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text>
            </th>
          </tr>
          <tr *ngFor="let user of list?.list; let i=index">
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{i+1}}"></m-text>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-image src='https://new-api.mesbro.com/uploads/{{user?.data?.image}}' w="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" h="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" my="1.6" mx="2.4"> </m-image>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{user?.personal_details?.first_name}} {{user?.personal_details?.last_name}}"></m-text>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{user?.security_details?.name}}"></m-text>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts" text="{{user?.communication_emailid?.email}}"></m-text>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
              <m-image (click)="add_user.openModal();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVhnUBDsSFZy2lScmmUXBfDePZvaFDBGC5KMxos6LaPAlIknifNst2K3AkOJKAPTuwfYpSxA9Rw5kUmQ&usqp=CAU" h="1.5" w="1.5"></m-image>
            </td>
            <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-image (click)="delete(user._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
            </td>
          </tr>
          <!-- <tr *ngFor="let user of list?.list; let i=index">
            <td a-style align="center" brLeft="2px solid #e4e4e4" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{i+1}}"></m-text>
            </td>
            <td a-style align="center" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-image src='https://new-api.mesbro.com/uploads/{{user?.data?.image}}' w="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" h="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" my="1.6" mx="2.4"> </m-image>
            </td>
            <td a-style align="center" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{user?.personal_details?.first_name}} {{user?.personal_details?.last_name}}"></m-text>
            </td>
            <td a-style align="center" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-text [type]="gs.enums.texts.paragraph35" text="{{user?.security_details?.name}}"></m-text>
            </td>
            <td a-style align="center" brBottom="2px solid #e4e4e4" br="1px solid #E4E4E4" p="1">
              <m-text [type]="gs.enums.texts" text="{{user?.communication_emailid?.email}}"></m-text>
            </td>
            <td a-style align="center" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-image (click)="add_user.openModal();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVhnUBDsSFZy2lScmmUXBfDePZvaFDBGC5KMxos6LaPAlIknifNst2K3AkOJKAPTuwfYpSxA9Rw5kUmQ&usqp=CAU" h="1.5" w="1.5"></m-image>
            </td>
            <td a-style align="center" brRight="2px solid #e4e4e4" brBottom="2px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
              <m-image (click)="delete(user._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
            </td>
          </tr> -->
        </table>
      </div>
 
  </div>
</div>

<m-modal #add_user width=98>
  <m-add-user [modal]=add_user></m-add-user>
</m-modal>
