
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { saveAs } from 'file-saver';
import { MailService } from '../../services/mail.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'm-mail-attachments',
  templateUrl: './mail-attachments.component.html',
  styles: [
  ]
})
export class MailAttachmentsComponent implements OnInit {
  @Input() detail: any
  @Input() attachments: any;
  isVisible_favorite: boolean = false;
  @Input() isvisible: boolean = false;
  @Input() img: any | undefined
  @Input() delete_icon: boolean = true;
  isStar: boolean = false;
  is_overlay: any = {
  }
  changeText: boolean
  id: any = ''
  @Input() attachment: any
  urlimage: any = ''
  is_visible: boolean = false
  is_visible1: boolean = false
  show: boolean = false
  select_file: any
  element: any
  imageurl:string
  constructor(public gs: GlobalService, public ms: MailService, public sanitizer:DomSanitizer) { }


  ngOnInit(): void {
    // console.log('attachments.....', this.attachments);
    this.gs.$selected_file.subscribe((file: any) => { this.select_file = file })
    // console.log(this.select_file);
    this.listForImage()
  }
  close() {
    this.show = false
  }
  closeButton() {
    this.is_visible = false
  }
  closeButtonpdf() {
    this.is_visible1 = false
  }
  preview(list: any) {
    this.is_visible = true
    this.is_visible1 = true
    console.log(list);
    this.urlimage = list.url;
    this.imageurl='https://new-api.mesbro.com/uploads/'+this.urlimage
    console.log(this.imageurl);
    
    console.log(this.urlimage);
    this.id = list._id
    this.show = true
    console.log(this.id)
  }

  download(file: any)   {
 
    let path = 'https://new-api.mesbro.com/uploads/' + file.url
    // console.log(file);
    const downloadedFile = new Blob([path], { type: file.content_type });
    // console.log(downloadedFile);

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = file.original_name;
    // a.href = URL.createObjectURL(downloadedFile);
    // a.href = path;
    // a.target = '_blank';
    saveAs(path, file.original_name)
    a.click();
    document.body.removeChild(a);
  }

  select(attachment: any) {

    // this.gs.$selected_file = attachment
    this.gs.$selected_file.next(attachment);
    // console.log(attachment);
  }

  async add_to_star(id: any) {
    let body = { '_id': id }
    // console.log(body);
    let response = await this.gs.hps.post('storage', 'file', 'starred', body);
    // console.log(response);
  }

  listForImage() {
    for (let index = 0; index < this.attachments?.headers?.attachments?.length; index++) {
      this.element = this.attachments.headers.attachments[index];
      console.log(this.element);

      console.log(this.element.content_type);
      if (this.element.content_type == 'application/pdf') {
      }
    }
  }

}
