
export enum Inputs {
    text = 'text',
    select = 'select',
    date = 'date',
    time = 'time',
    tags = 'tags',
    color = 'color',
    password = 'password',
    checkbox = 'checkbox',
    textarea = 'textarea',
    number = 'number',
    file = 'file',
    dropdown = 'dropdown',
    radioButton = 'radioButton',
    emailTags = 'emailTags',
    Country = 'Country',
    label = 'label',
    mobilelable = 'mobilelable',
    text_area = 'text_area',
    media = 'media',
    mediaGallary = 'mediaGallary',
    switch = 'switch',
    multiSelect = 'multiSelect',
    file_upload = 'file_upload',
    mail_attachments = 'mail_attachments',
    ck_editor = 'ck_editor',
    ck_editor2 = 'ck_editor2',
    media_flip = 'media_flip',
    mediaCover = 'mediaCover',
    mediaBrochure = 'mediaBrochure',
    newText = 'newText',
    date_picker = 'date_picker',
    newText_area = 'newText_area',
    newPassword = 'newPassword',
    newCheckbox = 'newCheckbox',
    newRadioButton = 'newRadioButton',
    mediaNew = 'mediaNew',
    mediaMulti = 'mediaMulti',

}
