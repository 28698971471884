import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-share',
  templateUrl: './share.component.html',
  styles: [
  ]
})
export class ShareComponent implements OnInit {

  @Input() title = '';
  @Input() summary = '';
  @Input() url = '';

  url_format: any;

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

  handle_redirect(platform: string) {
    this.url_format = {
      mesbro_mail: `/mail/home/compose?action=compose&u=${this.url}`,
      chat: `/chat/chat-details?u=${this.url}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
      twitter: `https://twitter.com/intent/tweet?text=${this.title}&url=${this.url}`,
      linked_in: `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${this.title}+&summary=${this.summary}&source=mesbro&u=${this.url}`,
      instagram: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
      whatsapp: `https://web.whatsapp.com/send?text=${this.title}%0A%0A${this.url}`,
      mail: `mailto:?&subject=Found this new exciting stuff&cc=&bcc=&body=Hey, %0AThis is seriously great. %0ACheckout this link out. %0A %0A${this.url}%0A %0A`,
      gmail:`https://mail.google.com/mail/?view=cm&to=%7Bemail_address%7D&su=${this.url}&body=Hey, %0AThis is seriously great. %0ACheckout this link out. %0A %0A${this.url}%0A %0A&bcc=%7Bemail_address%7D&cc=%7Bemail_address%7D`,
      telegram:`https://telegram.me/share/url?url=${this.url}&text=Hey, %0AThis is seriously great. %0ACheckout this link out. %0A %0A${this.url}%0A %0A`
    }
    window.open(this.url_format[platform], "_blank");
  }
}
