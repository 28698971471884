import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryParams } from 'angular-routing';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { ProductServiceService } from 'src/mesbro/applications/product/service/product-service.service';
import { GlobalService } from 'src/mesbro/services/global.service';
import { OrganizationService } from '../../service/organization.service';

@Component({
  selector: 'm-generic-dashboard',
  templateUrl: './generic-dashboard.component.html',
  styles: [
  ]
})
export class GenericDashboardComponent implements OnInit {
  @Input() details: any;
  user: IUser | undefined;
  users: any;
  params = {
    type: '',
    org_id: '',
    id: '',
    _id: ''
  }
  org: any

  constructor(public gs: GlobalService, public ps: ProductServiceService, public ar: ActivatedRoute, public os: OrganizationService) {
  }
  ngOnInit() {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    });
    this.ar.queryParams.subscribe(async (params: any) => {
      this.params = params
      if (this.params.id) {
        this.organization()
      }
    });
  }
  async organization() {
    this.org = await this.gs.hps.get('idm', 'group', this.params.id);
    console.log(this.org);
    
  }

  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['gdashboard','group', 'org-detail'], { queryParams: { id: item._id ,   type:'details'} });
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  async block_org(id:any){
    console.log(id);
    let body={  
        "_id":id   
    }
    let response= await this.gs.hps.post('idm', 'group', 'block' , body);
    console.log(response);
  }





}
