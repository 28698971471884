<div a-style br="1px solid rgb(228, 228, 228)" mt="2.4" p="2.4" overflowX="auto" brRadius="0.6" bg="#ffffff">

    <div a-style display="flex" justify="space-between" alignItems="center" pb=2.4>
        <p a-style fw=300 fs=1.6 fw=500 opacity=1 h=46px brBottom="1px solid #E4E4E4" w="100%" color=#333333>Supplier
            Location
        </p>
    </div>
    <div></div>
    <div a-style display="flex" gap="4.8">
        <div a-style h="40" w="80">
            <img src="https://i.natgeofe.com/n/a25e30a6-4081-41e0-a494-cdb469343b58/kids-beginners-world-edu-k-3-clip-1900x1900-gradient_3x2.jpg"
                h="40" w="80" a-style>
        </div>
        <div a-style flexGrow="1">
            <!-- {{details | json}} -->
            <div a-style display="flex" gap="3">
                <div>
                    <p a-style fs="1.6" fw="500" color="#2479AB">{{details?.parents?.group?.basic_details?.legal_name}}
                    </p>
                    <div a-style display="flex" gap="3">
                        <div>
                            <div a-style display="flex" mt="1">
                                <p a-style fs="1.3" color="#333333">Location :</p>
                                <p a-style fs="1.3" fw="300" color="#707070">
                                    {{details?.attributes?.material_available_location?.search_material_available_location?.value?.city}},
                                    {{details?.attributes?.material_available_location?.search_material_available_location?.value?.district}}
                                    {{details?.attributes?.material_available_location?.search_material_available_location?.value?.state}},
                                    {{details?.attributes?.material_available_location?.search_material_available_location?.value?.country}}
                                </p>
                            </div>
                            <p a-style fs="1.3" color="#333333" mt="1.6" mb="0.8">How to reach?</p>
                            <div a-style w="47" h="11">
                                <p a-style fs="1.3" pt="0.8" fw="400" color="#333333">
                                    {{ details?.parents?.group?.location?.howToReach}}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div a-style display="flex" align="center">
                                <img src="../assets/images/assets/Nearest Bus stop.svg" alt="" a-style w="2.4" h="2.4"
                                    mr="1.2">
                                <div a-style display=flex gap="6.4">
                                    <p a-style fs="1.3" color="#333333" fw="300">Nearest Bus stop</p>
                                    <p a-style fs="1.3" color="#333333" fw="300">{{ details?.parents?.group?.location?.nearestBusstop}}</p>
                                </div>
                            </div>
                            <div a-style display="flex" align="center" mt="2.9">
                                <img src="../assets/images/assets/Nearest Railway Station.svg" alt="" a-style w="2.4"
                                    h="2.4" mr="1.2">
                                <div a-style display=flex gap="2.1">
                                    <p a-style fs="1.3" color="#333333" fw="300">Nearest Railway Station</p>
                                    <p a-style fs="1.3" color="#333333" fw="300">{{ details?.parents?.group?.location?.nearestRailwayStation}}</p>
                                </div>
                            </div>
                            <div a-style display="flex" align="center" mt="2.9">
                                <img src="../assets/images/assets/Nearest Airport.svg" alt="" a-style w="2.4" h="2.4"
                                    mr="1.2">
                                <div a-style display=flex gap="7.6">
                                    <p a-style fs="1.3" color="#333333" fw="300">Nearest Airport</p>
                                    <p a-style fs="1.3" color="#333333" fw="300">{{ details?.parents?.group?.location?.nearest_air_port}}</p>
                                </div>
                            </div>
                            <div a-style display="flex" align="center" mt="2.9">
                                <img src="../assets/images/assets/Nearest Sea Port.svg" alt="" a-style w="2.4" h="2.4"
                                    mr="1.2">
                                <div a-style display=flex gap="6.6">
                                    <p a-style fs="1.3" color="#333333" fw="300">Nearest Sea Port</p>
                                    <p a-style fs="1.3" color="#333333" fw="300">{{ details?.parents?.group?.location?.nearest_sea_port}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div a-style mt="4.4">
            <div a-style display="flex" gap="4">
                <div a-style px="2.4" pt="2.4" pb="1.6" br="1px solid #E4E4E4" w="34" h="14.7" brRadius="6px">
                    <div a-style align="center">
                        <div a-style mb="1">
                            <img src="../assets/images/assets/product/pin.svg">
                        </div>
                        <div a-style>
                            <p a-style fs=1.6 color="#333333">{{users?.activeUser?.location_details?.city
                                }},
                                <br>
                                {{users?.activeUser?.location_details?.region }},
                                {{users?.activeUser?.location_details?.country_name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div a-style display="flex" justify="center" align="center">
                    <img src="../assets/images/assets/right-arrow.svg" a-style w="4.5" h="2.6" mr="1">
                    <div display="flex">
                        <p a-style fs="2" fw="300" color="#333333">{{_d}}</p>
                    </div>
                    <img src="../assets/images/assets/left-arrow.svg" a-style w="4.5" h="2.6" ml="1">
                </div>
                <div a-style px="2.4" pt="2.4" w="34" h="14.7" pb="1.6" br="1px solid #E4E4E4" brRadius="6px">
                    <div a-style align="center">
                        <div a-style mb="1.">
                            <img src="../assets/images/assets/product/pin.svg">
                        </div>
                        <div a-style>
                            <p a-style fs=1.6 color="#333333">
                                {{details?.attributes?.material_available_location?.search_material_available_location?.value?.city}},
                                {{details?.attributes?.material_available_location?.search_material_available_location?.value?.district}}
                                <br>
                                {{details?.attributes?.material_available_location?.search_material_available_location?.value?.state}},
                                {{details?.attributes?.material_available_location?.search_material_available_location?.value?.country}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>