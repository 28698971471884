import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-news-card',
  templateUrl: './news-card.component.html',
  styles: [
  ]
})
export class NewsCardComponent implements OnInit {
  
  @HostBinding('style.display') display = 'contents'

  @Input() type = ''
  @Input() index: any;
  @Input() detail: any;
  @Input() list: any;
  constructor(public gs : GlobalService,) { }

  ngOnInit(): void {
  }

}
