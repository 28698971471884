<ng-container [ngTemplateOutlet]="enquiry_card_1" *ngIf="type=='sent_enquiry' || type=='received_enquiry'"></ng-container>

<!-- Akash -->
<ng-template #enquiry_card_1>
    <div w=100% h=19.2 a-style br="1px solid #e4e4e4" flexGrow="1" brRadius="0.6" mb=2 bg="#ffffff" shadow=" hov:0 3px 6px 0 #f1f1f1" cursor="pointer">
        <div a-style h=78% display=flex gap=1 p=1>
            <div>
                <m-image *ngIf="detail.parents?.data?.profile_picture" src="https://new-api.mesbro.com/uploads/{{detail.parents?.data?.profile_picture}}" h="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5" w="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5"></m-image>
            </div>
            <table a-style br=none h="50%">
                <tr>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="{{detail?.parents?.data?.parents?.group?.basic_details?.legal_name}}"></m-text>
                                   <!-- label:detail?.parents?.data?.parents?.group?.basic_details?.legal_name  -->
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Enquired By"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Quantity"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Date & Time"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Mobile Number"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Email ID"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Location"></m-text>
                    </th>
                    <th>
                        <m-image (clicked)="delete.emit('')" src="https://mesbro.in/assets/images/delete-faded.svg" h="1.6" w="1.2"></m-image>
                    </th>
                </tr>
                <tr>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.data?.description}}"></m-text>
                    </th>
                    <th a-style display="flex" gap="0.5">
                        <div a-style display=flex align="center" bgSize="cover" bgUrl="https://new-api.mesbro.com/uploads/{{users?.activeUser?.personal_details?.profile_picture}}" w="2" h="2" brRadius="50%" br="1px solid #E4E4E4" p="0.5"> </div>
                        <m-text [type]="gs.enums.texts.paragraph10 " textWrap="nowrap" text="{{detail?.data?.name}}"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.data?.unit}}"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.meta?.activity.updated_at | date :'dd MMM yyyy'}}"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parents?.data?.parents?.group?.contact_details?.mobile}}"></m-text>
                    </th>
                    <ng-container >
                     
                    </ng-container>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parents?.data?.parents?.group?.contact_details?.email}}"></m-text>
                    </th>
                    <th>
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parents?.data?.parents?.group?.location?.primary_address?.city}}"></m-text>
                    </th>
                </tr>
            </table>
        </div>
        <div a-style p=1 display=flex justify="space-between" brTop="1px solid #e4e4e4">
            <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg', label:detail?.parents?.data?.title }}"></ng-container>
            <div a-style display="flex">
    
                <!-- <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/72.svg', label:'Send Catalogue',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/71.svg', label:'Send Offer',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/57.svg', label:'Send Quotation',mr:'2.4'}}"></ng-container> -->
                <!-- <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/72.svg', label:'Send Catalogue',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/71.svg', label:'Send Offer',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/57.svg', label:'Send Quotation',mr:'2.4'}}"></ng-container>
                </div> -->
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/chat.svg', label:'Chat',mr:'2.4'}}"></ng-container>
                </div>
                <!-- <div a-style routerLink="/mail/home">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/mail.svg', label:'Mail',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/mail/home">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/70.svg', label:'SMS'}}"></ng-container>
                </div>  -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #social let-media="media ">
    <div a-style display="flex" mr={{media.mr}} cursor=pointer (click)="allot_data(); go_to(media.label)">
        <m-image src={{media.src}} w=1.9 h=1.9 mr=0.8></m-image>
        <!-- <div a-style display="1044:none"> -->
            <m-text [type]="gs.enums.texts.paragraph38" text={{media.label}}></m-text>
        <!-- </div> -->
    </div>
</ng-template>
<m-modal #catalogue_modal>
    <m-send-catalogue></m-send-catalogue>
</m-modal>