import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isThisISOWeek } from 'date-fns';
import { deleted_categories } from 'src/mesbro/modules/shared/components/side-bar/side-bar-data';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-attribute-list',
  templateUrl: './attribute-list.component.html',
  styles: [
  ]
})
export class AttributeListComponent implements OnInit {
  @Input()
  isVisible: boolean = false;
  @Input() width: string = '';
  @Input() height: string = '';
  // @ViewChild('slide') slide:any

  selected = {
    index : -1,
    id : undefined
  }
  @Output() delete = new EventEmitter()
  
  queryParams = {
    type: <'Category' | 'Type' | 'Fixed' | undefined>undefined,
    sub_type: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'Fixed' | undefined>undefined,
    _id: <string | undefined>undefined,
    application: <string | undefined>undefined,
    query: <string | undefined>undefined,
    page: 0,
    group: <'Attribute' | 'Image' | 'Description' | 'FAQ' | 'Application' | 'Tags' | 'Basic Details' | undefined>undefined
  }

  list = {
    list: <any[]>[],
    count: 0,
  }

  dropdowns = {
    Primary: [],
    Secondary: [],
    Tertiary: []
  }

  deleted_categories: any = {};
  
 
  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  async ngOnInit() {
    this.ar.queryParams.subscribe(async (params: any) => {
      this.queryParams = { ...params };
      await this.get_list()
      this.map_parents()
      this.deleted_categories = {}
      deleted_categories[<any>this.queryParams.application]?.forEach((category: any) => {
        this.deleted_categories[category] = true;
      })
    })



  }
 basic :any
 technical:any;
 other:any
  async get_list() {
    if (this.queryParams.group) {
      switch (this.queryParams.group) {
        case 'Attribute':
          console.log(1)
          console.log(2)
          break;
        case 'Description':
          console.log(3)
          console.log(4)
          break;
        default:
          console.log(5)
          console.log(6)
          break;
      }
    }
    this.list = await this.gs.get_list('structure', this.queryParams.type, this.queryParams.sub_type, this.queryParams.application, [], { page: this.queryParams.page, size: 20, query: this.queryParams.query })
    console.log(this.list);
    for(let i=0; i<=this.list.list.length; i++){
      this.basic = this.list?.list[i]?.attributes?.basic_details;
      this.technical = this.list?.list[i]?.attributes?.technical_details;
      this.other = this.list?.list[i]?.attributes?.other_details;
       let basic_count : any = Object.keys(this.basic)?.length;
       let technical_count :any  = Object.keys(this.technical)?.length;
      //  let other_count :any = Object.keys(this.other)?.length;
        let count = basic_count + technical_count ;
        console.log( count); 
    }
    

  }


  map_parents() {
    this.list.list = this.list.list.map((structure: any) => {
      if (this.queryParams.sub_type == 'Secondary') {
        structure.primary = structure.parents.parent;
        structure.parent1 = structure.parents.parent;
        console.log( structure.parent1);
        
      }
      if (this.queryParams.sub_type == 'Tertiary') {

        structure.secondary = structure.parents?.parent;
        structure.parent1 = structure.parents?.parent;

        structure.primary = structure.secondary.parents?.parent;
        structure.parent2 = structure.secondary.parents?.parent;
      }
      if (this.queryParams.sub_type == 'Type') {
        structure.tertiary = structure.parents?.parent;

        structure.parent1 = structure.parents?.parent;

        structure.secondary = structure.tertiary.parents?.parent;
        structure.parent2 = structure.tertiary.parents?.parent;

        structure.parent3 = structure.secondary?.parents?.parent;
        structure.parent3 = structure.secondary?.parents?.parent;
      }
      return structure;
    })
  }


  delete_category(id: any,index?:any) {
    this.gs.delete_from_list('structure', id,index)
    this.list.list.splice(index,1)
  }

  closeModal() {
    this.isVisible = false;
  }


}
  