import { Component, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styles: [
  ]
})
export class DashboardHomeComponent implements OnInit {
  @Input() modal: any
 

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }
  checkopenModal(){
    
  }

}
