<div a-style bg="#fff" opacity="1" p="2.4" brRadius="0.6">
    <div a-style mb="1.6">
        <p a-style fs="1.6" color="#333" m="0"> What do you think?</p>
    </div>
    <div a-style mb="1.6">
        <m-input a-style mt="0" type="radioButton" fs="1.2" label="this is helpful" radioName="Schedule" onlyInput=true>
        </m-input>
        <m-input a-style mt="1" type="radioButton" label="This isn't relevent" radioName="Schedule" onlyInput=true>
        </m-input>
        <m-input a-style mt="1" type="radioButton" label="Something is wrong" radioName="Schedule" onlyInput=true>
        </m-input>
        <m-input a-style mt="1" type="radioButton" label="This isn't useful" radioName="Schedule" onlyInput=true>
        </m-input>
        <div a-style br="1px solid #E4E4E4" mt="1.6">
            <textarea formControlName="requirement" a-style w="36.5" fs=1.3;1366:0.9 h="8.7" p="0.8" br="none" resize="none"
                brRadius="0.6" placeholder="Comment" mb="0">
            </textarea>
        </div>
    </div>
    <div a-style display="flex" justify="flex-end">
        <button a-style br="1px solid #2479AB" px="1.4" py="0.5" bg="#FFFFFF" brRadius="0.6">Submit</button>
    </div>
</div>