import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-sign-in-required',
  templateUrl: './sign-in-required.component.html',
  styles: [
  ]
})
export class SignInRequiredComponent implements OnInit {

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
