<div class="modal" a-style brRadius=4px bg=#fffff align="center" zIndex=1 bg="white" gap=2 p="2.4" bg=#ffffff>
    <div a-style align="center" >
        <ngx-qrcode [elementType]="elementType" value='https://mesbro.com/generic/detail?_id={{details?._id}}' errorCorrectionLevel="L" >
        </ngx-qrcode>
    </div>
    <div a-style>
        <!-- <m-text [type]="gs.enums.texts.paragraph21" text=" To open product details on mobile :" mb="1.6"></m-text> -->
        <!-- <ul a-style m=0>
            <li a-style fs=1.4 mb=1 mt=1.2 style="list-style-type: number;"><b>Unlock</b>/<b>Open</b> your phone.</li>
            <li a-style fs=1.4 mb=1 style="list-style-type: number;">Tap <b>phone camera</b> or <b>QR/Barcode Reader</b> App.</li>
            <li a-style fs=1.4 style="list-style-type: number;"><b>Point your phone</b> to this screen to capture the code.</li>
        </ul> -->
        <div a-style align="center" w="39" h="7.6">
            <p a-style fs="1.6" color="#333333">{{details?.Basic_Details?.attributes[1].value }}</p>
        </div>
        <p a-style fs="1.6" fw="400">To view product scan this QR Code</p>
    </div>
</div>