import { ChatCreateLabelComponent } from './../../applications/new-chat/modals/chat-create-label/chat-create-label.component';
import { OrganizationConversationComponent } from './pages/organization-conversation/organization-conversation.component';
import { ProductSidebarComponent } from './../../applications/product/components/product-sidebar/product-sidebar.component';
import { DashboardHomeComponent } from './pages/dashboard-home/dashboard-home.component';
import { CommanListComponent } from './pages/comman-list/comman-list.component';
import { ToastComponent } from './components/toast/toast.component';
import { AttributeListComponent } from './../../applications/attribute/pages/attribute-list/attribute-list.component';
import { AttributeFormComponent } from './../../applications/attribute/pages/attribute-form/attribute-form.component';
import { PolicesComponent } from './pages/polices/polices.component';
import { SuggestFeaturesComponent } from './pages/suggest-features/suggest-features.component';
import { ReportBugComponent } from './pages/report-bug/report-bug.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from 'src/mesbro/applications/product/pages/product-card/product-card.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AstyleDirective } from './directives/a-style.directive';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { CommonLayoutComponent } from './components/common-layout/common-layout.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './components/modal/modal.component';
import { AddDomainComponent } from 'src/mesbro/applications/organization/modals/add-domain/add-domain.component';
import { UploadComponent } from 'src/mesbro/applications/file-manager/modals/upload/upload.component';
import { ShareComponent } from 'src/mesbro/applications/file-manager/modals/share/share.component';
import { DeleteComponent } from 'src/mesbro/applications/file-manager/modals/delete/delete.component';
import { CallUsComponent } from 'src/mesbro/applications/file-manager/modals/call-us/call-us.component';
// import { RfqComponent } from 'src/mesbro/applications/file-manager/modals/rfq/rfq.component';
import { EnquiryCardComponent } from './components/enquiry-card/enquiry-card.component';
import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { FormComponent } from './components/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannerComponent } from './components/banner/banner.component';
import { SuccessComponent } from './components/success/success.component';
import { ShortLinkCardComponent } from 'src/mesbro/applications/short-link/components/short-link-card/short-link-card.component';
import { ChatModalComponent } from 'src/mesbro/applications/chat/modals/chat-modal/chat-modal.component';
import { WallpaperComponent } from 'src/mesbro/applications/chat/modals/wallpaper/wallpaper.component';
import { SolidColorComponent } from 'src/mesbro/applications/chat/modals/solid-color/solid-color.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { AttributeTypePipe } from './pipes/attribute-type.pipe';
import { CategoryCardComponent } from 'src/mesbro/applications/product/pages/category-card/category-card.component';
import { TextComponent } from './components/text/text/text.component';
import { ImageComponent } from './components/image/image.component';
import { ProductBannerComponent } from 'src/mesbro/applications/product/pages/product-banner/product-banner.component';
import { SimilarEntitiesComponent } from 'src/mesbro/modules/shared/components/similar-entities/similar-entities.component';
import { EnquiryComponent } from './pages/enquiry/enquiry.component';
import { HelpComponent } from './pages/help/help.component';
import { CallComponent } from './components/call/call.component';
import { ImageCropprComponent } from './components/image-croppr/image-croppr.component';
import { CenterComponent } from './components/center/center.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { ApplicationTrayComponent } from './modals/application-tray/application-tray.component';
import { AccountTrayComponent } from './modals/account-tray/account-tray.component';
import { LanguageTrayComponent } from './modals/language-tray/language-tray.component';
import { NotificationTrayComponent } from './modals/notification-tray/notifications-tray.component';
import { CasePipe } from './pipes/case.pipe';
import { GenericFormComponent } from './pages/generic-form/generic-form.component';
import { DomianListComponent } from './pages/domian-list/domian-list.component';
import { ControlComponent } from './components/control/control.component';
import { FileManagerModalComponent } from 'src/mesbro/applications/file-manager/modals/file-manager-modal/file-manager-modal.component';
import { SendCatalogueComponent } from 'src/mesbro/applications/file-manager/modals/send-catalogue/send-catalogue.component';
import { AddUserComponent } from 'src/mesbro/applications/organization/modals/add-user/add-user.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { BarCodeComponent } from './modals/bar-code/bar-code.component';
import { StatusListComponent } from './pages/status-list/status-list.component';
import { TempConversationComponent } from './components/temp-conversation/temp-conversation.component';
import { GroupCardComponent } from './components/group-card/group-card.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { RelatedProductComponent } from './components/related-product/related-product.component';
import { ProductHeaderComponent } from 'src/mesbro/applications/product/components/product-header/product-header.component';
import { FeedbackListComponent } from './components/feedback-list/feedback-list.component';
import { SentEnquiriesComponent } from './components/sent-enquiries/sent-enquiries.component';
import { RfqCardComponent } from './pages/rfq-card/rfq-card.component';
import { SendOfferComponent } from 'src/mesbro/applications/organization/modals/send-offer/send-offer.component';
import { CollaboratorComponent } from './modals/collaborator/collaborator.component';
import { ApplicationListComponent } from './modals/application-list/application-list.component';
import { SharedCardComponent } from './components/shared-card/shared-card.component';
import { FolderFormComponent } from 'src/mesbro/applications/mail/modals/folder-form/folder-form.component';
import { MailLabelFormComponent } from 'src/mesbro/applications/mail/modals/mail-label-form/mail-label-form.component';
import { OrganizationBlockComponent } from 'src/mesbro/applications/organization/components/organization-card/organization-block/organization-block.component';
import { ArticalCardComponent } from './pages/artical-card/artical-card.component';
import { CheckInComponent } from './modals/check-in/check-in.component';
import { ReviewComponent } from './pages/review/review.component';
import { BrandCardComponent } from './components/brand-card/brand-card.component';
import { SearchPipe } from './pipes/search.pipe';
import { LeadsListComponent } from './pages/leads-list/leads-list.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FaqComponent } from './pages/faq/faq.component';
import { SideMenuPermissionPipe } from './pipes/side-menu-permission.pipe';
import { GenericFeedbackListComponent } from './pages/generic-feedback-list/generic-feedback-list.component';
import { AmountInWordsPipe } from './pipes/amount-in-words.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ContentFormComponent } from './pages/content-form/content-form.component';
import { DurationAgoPipe } from './pipes/duration-ago.pipe';
import { VehicleCardComponent } from 'src/mesbro/applications/vehicles/pages/vehicle-card/vehicle-card.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { EmojiComponent } from 'src/mesbro/applications/mail/modals/emoji/emoji.component';
import { ReviewListComponent } from './pages/review-list/review-list.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
// import { AutosizeModule } from 'ngx-autosize';
import { ProductFeedbackComponent } from './components/product-feedback/product-feedback.component';
import { SortPipe } from './pipes/sort.pipe';
import { CreateNewLabelComponent } from 'src/mesbro/applications/mail/modals/create-new-label/create-new-label.component';
import { AddMailTemplateComponent } from 'src/mesbro/applications/mail/modals/add-mail-template/add-mail-template.component';
import { EditTemplaetComponent } from 'src/mesbro/applications/mail/modals/edit-templaet/edit-templaet.component';
import { SearchComponent } from 'src/mesbro/applications/mail/modals/search/search.component';
import { DeviceInformationComponent } from 'src/mesbro/applications/mail/modals/device-information/device-information.component';
import { MailFormgroupComponent } from 'src/mesbro/applications/mail/components/mail-formgroup/mail-formgroup.component';
import { AttachmentPipe } from './pipes/attachment.pipe';
import { FaqModalComponent } from './modals/faq-modal/faq-modal.component';
import { VehicleBannerComponent } from 'src/mesbro/applications/vehicles/pages/vehicle-banner/vehicle-banner.component';
import { GenericSearchComponent } from './generic-search/generic-search.component';
import { FaqPipe } from './pipes/faq.pipe';
import { PrivilegeFormComponent } from './pages/privilege-form/privilege-form.component';
import { PrivilegeListComponent } from './pages/privilege-list/privilege-list.component';
import { ChatModule } from 'src/mesbro/applications/chat/chat.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SliderComponent } from './modals/slider/slider.component';
import { WebSearchContextComponent } from './components/web-search-context/web-search-context.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { GlobalBannerComponent } from 'src/mesbro/applications/product/components/global-banner/global-banner.component';
import { AddCampaignComponent } from './components/add-campaign/add-campaign.component';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { CampaignListComponent } from './components/campaign-list/campaign-list.component';
import { ShortlinkFormComponent } from './components/shortlink-form/shortlink-form.component';
import { ShortlinkListComponent } from './components/shortlink-list/shortlink-list.component';
import { ShortLinkListComponent } from 'src/mesbro/applications/short-link/pages/short-link-list/short-link-list.component';
import { FilterComponent } from 'src/mesbro/applications/product/pages/filter/filter.component';
import { GenericCardComponent } from 'src/mesbro/applications/generic/pages/generic-card/generic-card.component';
import { GenericBannerComponent } from 'src/mesbro/applications/generic/pages/generic-banner/generic-banner.component';
import { ProductListComponent } from 'src/mesbro/applications/product/pages/product-list/product-list.component';
import { SignInRequiredComponent } from './modals/sign-in-required/sign-in-required.component';
import { MailAttachmentsComponent } from 'src/mesbro/applications/mail/components/mail-attachments/mail-attachments.component';
import { GenericCategoryComponent } from 'src/mesbro/applications/generic/pages/generic-category/generic-category.component';
import { SocialPostPipe } from './pipes/social-post.pipe';
import { RfqComponent } from 'src/mesbro/applications/product/pages/rfq/rfq.component';
import { CommentComponent } from 'src/mesbro/applications/social/comment/comment.component';
import { FaqAnswerComponent } from './pages/faq-answer/faq-answer.component';
import { GenericContactsComponent } from './components/generic-contacts/generic-contacts.component';
import { GenericContactListComponent } from './components/generic-contact-list/generic-contact-list.component';
import { ChatModalsComponent } from './components/chat-modals/chat-modals.component';
import { GenericListComponent } from './components/generic-list/generic-list.component';
import { GenericLocationComponent } from './components/generic-location/generic-location.component';
import { NiceDateFormatPipePipe } from './pipes/nice-date-format-pipe.pipe';
import { AddRfqComponent } from './pages/add-rfq/add-rfq.component';
import { QuotenowBannerComponent } from './pages/quotenow-banner/quotenow-banner.component';
import { RelatedSearchComponent } from './pages/related-search/related-search.component';
import { RfqFeedbackComponent } from './pages/rfq-feedback/rfq-feedback.component';
import { EditCommentComponent } from './pages/edit-comment/edit-comment.component';
import { PrintComponent } from 'src/mesbro/applications/product/pages/print/print.component';
import { SocialmediaPostComponent } from 'src/mesbro/applications/social/pages/socialmedia-post/socialmedia-post.component';
import { NewInputComponent } from './components/new-input/new-input.component';
import { OtpComponent } from 'src/mesbro/applications/idm/pages/otp/otp.component';
import { BillingDetailsComponent } from 'src/mesbro/applications/payment/modal/billing-details/billing-details.component';
import { OrganizationCardComponent } from 'src/mesbro/applications/new-organization/components/organization-card/organization-card.component';
import { ChangeMobileComponent } from 'src/mesbro/applications/idm/pages/change-mobile/change-mobile.component';
import { ChangePasswordComponent } from 'src/mesbro/applications/idm/pages/change-password/change-password.component';
import { ChangeRecoveryEmailComponent } from 'src/mesbro/applications/idm/pages/change-recovery-email/change-recovery-email.component';
import { CardsComponent } from './components/cards/cards.component';
import { CategoryComponent } from 'src/mesbro/applications/product/category/category.component';
import { OrganizationHeaderComponent } from 'src/mesbro/applications/new-organization/components/organization-header/organization-header.component';
import { ProductGallaryComponent } from 'src/mesbro/applications/product/pages/product-gallary/product-gallary.component';
import { RecipeHeaderComponent } from 'src/mesbro/applications/mesbro-recipe/components/recipe-header/recipe-header.component';
import { ServiceHeaderComponent } from 'src/mesbro/applications/mesbro-service/components/service-header/service-header.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { CreatePostComponent } from 'src/mesbro/applications/social/pages/create-post/create-post.component';
import { ReplaceUC } from './pipes/replace-string.pipe';
import { AttributeServicetypePipe } from './pipes/attribute-servicetype.pipe';
import { MeHeaderComponent } from 'src/mesbro/applications/me/components/me-header/me-header.component';
// import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';

// import {MatDatepickerModule} from '@angular/material/datepicker';


let sharedComponents = [
  MeHeaderComponent,
  ProductGallaryComponent,
  CreatePostComponent,
  OrganizationHeaderComponent,
  CategoryComponent,
  ChangeMobileComponent,
  ChangePasswordComponent,
  ChangeRecoveryEmailComponent,
  OrganizationCardComponent,
  SocialmediaPostComponent,
  ProductSidebarComponent,
  AmountInWordsPipe,
  SearchPipe,
  ReviewComponent,
  CenterComponent,
  HeaderComponent,
  FooterComponent,
  ProductCardComponent, //Product Module
  AstyleDirective,
  SideBarComponent, //Dashboard Module
  DashboardLayoutComponent, //Dashboard Module
  CommonLayoutComponent,
  GenericListComponent,
  GenericLocationComponent,
  ModalComponent,
  SuccessModalComponent,
  QuotenowBannerComponent,
  AddDomainComponent, //Organization Module
  SendCatalogueComponent, //Organization Module
  UploadComponent, //Organization Module
  ShareComponent, //Organization Module
  DeleteComponent,
  CallUsComponent,
  RfqComponent, //Product Module
  EnquiryCardComponent, //Product Module
  //ModalComponent,
  InputComponent,
  NewInputComponent,
  ButtonComponent,
  FormComponent,
  BannerComponent,
  SuccessComponent,
  ShortLinkCardComponent,
  ChatModalComponent,
  ChatModalsComponent,
  WallpaperComponent,
  SolidColorComponent,
  ConfirmModalComponent,
  AttributeTypePipe,
  AttributeServicetypePipe,
  CategoryCardComponent,
  TextComponent,
  ImageComponent,
  ReportBugComponent,
  SuggestFeaturesComponent,
  ProductBannerComponent,
  OrganizationBlockComponent,
  EnquiryComponent,
  SimilarEntitiesComponent,
  HelpComponent,
  PolicesComponent,
  CallComponent,
  AttributeFormComponent,
  AttributeListComponent,
  ImageCropprComponent,
  ErrorPageComponent,
  SuccessPageComponent,
  ApplicationTrayComponent,
  AccountTrayComponent,
  LanguageTrayComponent,
  NotificationTrayComponent,
  CasePipe,
  GenericFormComponent,
  FileManagerModalComponent,
  ToastComponent,
  BarCodeComponent,
  DomianListComponent,
  AddUserComponent,
  UserListComponent,
  UploadComponent,
  ShareComponent,
  StatusListComponent,
  TempConversationComponent,
  BarCodeComponent,
  GroupCardComponent,
  InvoiceComponent,
  RfqCardComponent,
  RelatedProductComponent,
  ProductHeaderComponent,
  FeedbackListComponent,
  SentEnquiriesComponent,
  CommanListComponent,
  ApplicationListComponent,
  CollaboratorComponent,
  SendOfferComponent,
  OrganizationBlockComponent,
  SharedCardComponent,
  FolderFormComponent,
  MailLabelFormComponent,
  DashboardHomeComponent,
  RfqCardComponent,
  ArticalCardComponent,
  CheckInComponent,
  BrandCardComponent,
  LeadsListComponent,
  FaqComponent,
  SideMenuPermissionPipe,
  GenericFeedbackListComponent,
  ContentFormComponent,
  DurationAgoPipe,
  VehicleCardComponent,
  FileUploadComponent,
  EmojiComponent,
  ProductFeedbackComponent,
  CreateNewLabelComponent,
  AddMailTemplateComponent,
  EditTemplaetComponent,
  SearchComponent,
  DeviceInformationComponent,
  MailFormgroupComponent,
  SortPipe,
  AttachmentPipe,
  FaqModalComponent,
  VehicleBannerComponent,
  GenericSearchComponent,
  FaqPipe,
  PrivilegeFormComponent,
  PrivilegeListComponent,
  SliderComponent,
  WebSearchContextComponent,
  SearchHeaderComponent,
  NewsCardComponent,
  GlobalBannerComponent,
  AddCampaignComponent,
  CampaignFormComponent,
  CampaignListComponent,
  ShortlinkFormComponent,
  ShortlinkListComponent,
  FilterComponent,
  GenericCardComponent,
  GenericBannerComponent,
  ProductListComponent,
  SignInRequiredComponent,
  GenericCategoryComponent,
  MailAttachmentsComponent,
  SocialPostPipe,
  CommentComponent,
  FaqAnswerComponent,
  GenericContactsComponent,
  GenericContactListComponent,
  NiceDateFormatPipePipe,
  AddRfqComponent,
  RelatedSearchComponent,
  RfqFeedbackComponent,
  EditCommentComponent,
  OrganizationConversationComponent,
  PrintComponent,
  ChatCreateLabelComponent,
  OtpComponent,
  BillingDetailsComponent,
  CardsComponent,
  RecipeHeaderComponent,
  ServiceHeaderComponent,
  ReplaceUC,


]

@NgModule({
  declarations: sharedComponents,
  exports: sharedComponents,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxQRCodeModule,
    CKEditorModule,
   
  
    //  MatDatepickerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
