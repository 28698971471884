<ng-container *ngIf="type =='news_card'" [ngTemplateOutlet]="news_card " ></ng-container>

<ng-template #news_card >
    <div *ngIf="detail?.urlToImage" display="flex" a-style position="relative">
        <div a-style w="30.4" br="1px solid #E4E4E4" brRadius="0.6"   shadow=" hov:0 3px 6px 0 #f1f1f1">
            <div a-style w=100% h=13.6>
                <m-image   [src]="detail?.urlToImage" w=100% h="100%"></m-image>  
            </div>
            <div a-style justify="space-between" p=1>
                <m-text [type]="gs.enums.texts.paragraph20" mb=0.5 style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" [text]="detail?.title"></m-text>
                <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" [text]=' detail?.content'></m-text>
                <!-- <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" text=' 166 views'></m-text> -->
             
                <div a-style display="flex" justify="space-between">
                    <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" [text]=' detail?.author'></m-text>
                    <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"  text="{{detail?.publishedAt | durationAgo}}" ></m-text>
                </div>
            </div>
        </div>
        <!-- <div a-style bg="#333333" w="5" h=2 p=0.5 brRadius="0.6" display="flex" align="center" position="absolute" top="2.4" right=1.4>
            <m-image src="../assets/images/assets/Group 74964.svg" w=1.7 mr=0.8></m-image>
            <m-image [src]='gs.enums.images.image332' a-style w=1.5></m-image>
        </div> -->
    </div>
</ng-template>

<!-- {{detail | json}} -->
