<div a-style maxWidth="100%" align="center" justify="center" h="calc(100vh - 15rem)" flexDirection="column" *ngIf=true>
    <div a-style display="flex" align="center" justify="center" flexDirection="column" h=100%>
        <m-text [type]="gs.enums.texts.paragraph30" text="Mesbro Help" mb="2.2"></m-text>
        <div a-style display="flex" mt=2.9  w=100% justify="center" align="center" >
            <div a-style brBottom="1px solid #E4E4E4" w=18%></div>
            <p a-style fs=1.3 fw=400 color=#333 px=3.5>Help</p>
            <div a-style brBottom="1px solid #E4E4E4" w=18%> </div>
        </div>
        <div a-style maxWidth="60.2" h="20.7" display="flex" flexWrap="wrap" overflow="auto" id='scroll' mb="9">
            <ng-container [ngTemplateOutlet]="apps" *ngFor="let item of gs.applications" [ngTemplateOutletContext]="{passedData: {src:item.src, application:item.application}}"></ng-container>
        </div>
    </div>
</div>

<ng-template #apps let-passedData="passedData">
    <div (click)="select_application(passedData)" cursor=pointer a-style w=7.5 flexGrow=1 h=11.0 align=center mx=1 py=0.9>
        <div a-style w="7.5" h="7.5">
            <img [src]="passedData.src" a-style h="100%" w="5.5">
        </div>
        <m-text [type]="gs.enums.texts.paragraph11" [text]="passedData.application"></m-text>
    </div>
</ng-template>