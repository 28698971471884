<div a-style p="2.4" bg="#F6F8FB" mt=4.4>
    <form [formGroup]="form">
        <p a-style fs="1.6" fw="600">Create Content</p>
        <m-form title_mb=0.6 formGroupName="front_banner_link" columns="3" title="Front Banner Link" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="banner_text" label="Enter banner text" placeHolder="Enter text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="banner_link" label="Enter banner Link" placeHolder="Enter link here "></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="tradeshow_text" label="Enter tradeshow text" placeHolder="Enter text here "></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="tradeshow_link" label="Enter Tradeshow Link" placeHolder="Enter link here "></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="subsideries_text" label="Enter Govt.Schemes & Subsideries text" placeHolder="Enter text here "></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="subsideries_link" label="Enter Govt.Schemes & Subsideries Link" placeHolder="Enter link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="join_now" title="Join now banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="text" label="Enter banner Text" placeHolder="Enter banner text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter banner Link" placeHolder="Enter banner link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="job_banner" title="Jobs banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="text" label="Enter Jobs Text" placeHolder="Enter Jobs text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter Jobs Link" placeHolder="Enter Jobs link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="property_banner" title="Property banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="text" label="Enter Property Text" placeHolder="Enter Property text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter Property Link" placeHolder="Enter Property link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="business_email_banner" title="Business Email banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="text" label="Enter Business Email Text" placeHolder="Enter Business Email text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter Business Email Link" placeHolder="Enter Business Email link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="global_marketplace" title="Global Marketplace banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="heading" label="Heading" placeHolder="Enter Global Marketplace text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="sub_heading" label="Sub Heading" placeHolder="Enter Global Marketplace text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="sub_heading_two" label="Sub Heading" placeHolder="Enter Global Marketplace text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="sub_heading_three" label="Sub Heading" placeHolder="Enter Global Marketplace text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="sub_heading_four" label="Sub Heading" placeHolder="Enter Global Marketplace text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="description" label="Description" placeHolder="Enter Global Marketplace Description here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter Global Marketplace Link" placeHolder="Enter Global Marketplace link here "></m-input>
        </m-form>
        <m-form title_mb=0.6 columns="3" formGroupName="newsletter_banner" title="Newsletter banner" mt="2.4" px="1.5">
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="heading" label="Enter Newsletter Text" placeHolder="Enter Newsletter text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="description" label="Enter Newsletter Description" placeHolder="Enter Newsletter text here"></m-input>
            <m-input [columns]="1/4" flexGrow="0;1024:1" minWidth="35;321:30" formControlName="link" label="Enter Newsletter Link" placeHolder="Enter Newsletter link here "></m-input>
        </m-form>
        <div a-style display="flex" justify="flex-end" w="100%" mt=2.4>
            <m-button [types]="gs.enums.buttons.button32" text="Submit" py=1.2 px=2.4  br="none" (click)=submit()></m-button>
        </div>
    </form>
</div>