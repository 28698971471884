import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-privilege-form',
  templateUrl: './privilege-form.component.html'
})
export class PrivilegeFormComponent implements OnInit {

  application = 'Generic';
  component = 'Privilege';
  form: FormGroup | undefined;


  form_data = {
    username: '',
    name: '',
    title: '',
    description: '',
    image: '',
  }
  queryParams: any = {
    _id: ''
  }
  // list_url = `/dashboard/group/${this.application}/${this.component}/list`;

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))
  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'data', params._id); //update list
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: null,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }
  async submit() {
    try {
      let body = this.form?.value;
      if (this.form?.value._id) {
        let response = await this.gs.hps.put('generic', 'data', undefined, body);
        this.gs.toast('Brand', 'Modified Successfully', 'success')
      } else {
        let response = await this.gs.hps.post('generic', 'data', 'add', body);
        this.gs.toast('Brand', 'Added Successfully', 'success')
        this.gs.router.navigate(['dashboard/privilege-user/list']);
      }
    } catch (error) {
      console.log(error);

    }
  }
}
