import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-group-card',
  templateUrl: './group-card.component.html',
  styles: [
  ]
})
export class GroupCardComponent implements OnInit {
  @HostBinding('style.display') display = 'contents';
  @Input() mt: any = '';
  @Input() index: any;
  @Input() detail: any;
  @Input() type: any;
  @Input() list: any;
  @Output() delete = new EventEmitter();

  default_image: any = 'https://media.istockphoto.com/vectors/gallery-icon-vector-id953463676?k=20&m=953463676&s=170667a&w=0&h=5eVQZWhvtwJH2Ag6Za0FGkhm-Dpg70GlFRUEmPyhQN8='
  params: any = {
    type: undefined,
    query: '',
    application: '',
    _id : '',
    org_id:''
  }
  constructor(public gs: GlobalService , public ar:ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params
    })

  }

  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['gdashboard','group', 'org-detail'], { queryParams: { org_id: item._id ,   type:'details'} });
  }

  // redirect_to_detail(item: any) {
  //   this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  // }

  // redirect_to_form(item: any) {
  //   this.gs.router.navigate(['generic', 'form'], { queryParams: { _id: item._id } });
  // }

}
