import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-feedback-list',
  templateUrl: './feedback-list.component.html',
  styles: [
  ]
})
export class FeedbackListComponent implements OnInit {
  application = 'Generic';
  component = 'Feedback';

  list = {
    list: [],
    count: 0
  }

  product:boolean=false;
  query_params: any = {
    type: <'bug' | 'feature' | 'feedback'| 'reportissue' | undefined>undefined,
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => {
      this.query_params = { ...params };
      this.get_list();
    })
  }

  async get_list() {
    let filters = [{ type: this.query_params.type }]
    let response = await this.gs.get_data_list(this.application, this.component, filters)
    this.list = response;
    console.log(this.list);

  }

  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }

}
