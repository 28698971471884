import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-privilege-list',
  templateUrl: './privilege-list.component.html',
  styles: [
  ]
})
export class PrivilegeListComponent implements OnInit {

  list = {
    list: [],
    count: 0
  }
  application = 'Generic';
  component = 'Privilege';
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    this.get_list()

  }
  async get_list() {
    let response = await this.gs.get_data_list('Generic', 'Privilege', [])
    this.list = response;
  }
  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }

}
