<!-- 
<div bg="#ffffff" a-style w="100%" br="1px solid #E4E4E4"  brRadius=0.6 *ngIf="false">
  <div a-style display="flex" align="center" justify="space-between" p=1.6>
    <m-text [type]="gs.enums.texts.paragraph26" text="Q&A"></m-text>
    <div a-style display=flex justify="flex-end" gap=0.5 *ngIf="users.activeUser" (click)="faq.openModal()">
      <img src="https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/32.svg" cursor="pointer" alt="" a-style
        w="auto">
      <m-text *ngIf="users.activeUser" [type]="gs.enums.texts.paragraph28" text="Add Question" cursor="pointer">
      </m-text>
    </div>
  </div>
  <div brTop="1px solid #e4e4e4" *ngIf="!data.answers && !gs.router.url.includes('generic/form')" a-style display=grid
    placeContent="center" flexDirection=column cursor=pointer flexGap=2 h="50%">
    <img src="../assets/images/assets/faq_banner.png" alt="" h=22 a-style w="auto">
    <m-text [type]="gs.enums.texts.paragraph38" a-style ml=2 mb=1 text=" No Q&A's to read"> </m-text>
  </div>
  <div brTop="1px solid #e4e4e4" *ngIf="!data.count && gs.router.url.includes('generic/form')" a-style display=grid
    placeContent="center" flexDirection=column cursor=pointer flexGap=2 h="50%">
    <img src="../assets/images/assets/faq_banner.png" alt="" h=20 a-style w="auto">
    <m-text [type]="gs.enums.texts.paragraph38" a-style ml=2 mb=1 text=" No FAQ's to read"></m-text>
  </div>
  <div p=1.6 a-style class="no_scroll_bar" overflowY=auto maxHeight=34.5>
    <div a-style pt=1 mb=1 flexGap=1 minWidth='100%'>
      <ng-container *ngFor="let question of data.list ;let i = index">
        <div *ngIf="question.count || gs.router.url.includes('generic/form')"
          (click)="get_answer(question?._id);faq_toggle[i]=!faq_toggle[i]" a-style h="5" bg="#F7F8F9" brRadius="0.4"
          display="flex" mb=1 align="center" justify="space-between" px=1.5>
          <m-text [type]="gs.enums.texts.paragraph39" text={{question?.data?.description}} align="left"></m-text>
          <div a-style display="flex" justify="flex-end" gap=1>
            <m-text [type]="gs.enums.texts.paragraph116" text={{question?.meta?.activity?.updated_by?.first_name}}
              align="left"></m-text>
            <m-text [type]="gs.enums.texts.paragraph116"
              text="{{question?.meta?.activity?.created_at | date :'dd MMM YYYY'}}" align="left"></m-text>
            <img src="https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/31.svg" a-style w=1.4 h=1.4
              (click)="faq_toggle[i]=!faq_toggle[i]">
          </div>
        </div>
        <div a-style *ngIf="faq_toggle[i]">
          <ng-container>
            <div a-style display=flex #ans justify=space-between mt=1 *ngFor="let item of list.list; let j = index">
              <m-text [type]="gs.enums.texts.paragraph39" text='Answer : {{item.answers}}' align="left"></m-text>
              <div a-style display=flex justify=flex-end gap=1>
                <m-text *ngIf="item?.type=='Mesbro'" [type]="gs.enums.texts.paragraph116" text="Mesbro Pvt.Ltd"
                  align="left"></m-text>
                <m-text *ngIf="item?.type=='seller'" [type]="gs.enums.texts.paragraph116"
                  text={{details?.parents?.group?.title}} align="left"></m-text>
                <m-text *ngIf="item?.type=='user'" [type]="gs.enums.texts.paragraph116"
                  text={{item.meta?.activity?.updated_by?.first_name}} align="left"></m-text>
                <m-image *ngIf="gs.router.url.includes('generic/form')" (click)="delete(item._id,j)"
                  src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
              </div>
            </div>
          </ng-container>
          <textarea w=100% br="1px solid #E4E4E4" mt=1 placeholder="Your ans here" p=1 a-style h=6.5
            [(ngModel)]="answers" brRadius="0.4" resize="none"></textarea>
          <div a-style display="flex" justify="flex-end" mt=1 w="100%" mb=1>
            <m-button [types]="gs.enums.buttons.button18" text="Submit" (click)="answer(question._id)"></m-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

new design in rfq
<div a-style bg="#fff" brRadius="0.6" br="1px solid #e4e4e4" p="2.4">
  <div a-style mb="0.5  ">
    <p a-style color="#333333" fs="1.6" m="0">People Also Ask</p>
  </div>
  <div class='faq'>
    <input id='faq-a' type='checkbox'>
    <label for='faq-a'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Can you send the sample for us?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Yes,We provide the sample 1 times price of the product.</p>
    </label>
    <input id='faq-b' type='checkbox'>
    <label for='faq-b'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Can you accept small orders?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We can accept trial orders for the starting of business. But according to different product the price will be higher as normal quantity.</p>
    </label>
    <input id='faq-c' type='checkbox'>
    <label for='faq-c'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">How is the after-sales service?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We offer one year warranty period</p>
    </label>
    <input id='faq-d' type='checkbox'>
    <label for='faq-d'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Do you inspect the product?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Yes, each step of production and finished products will be carried out inspection by QC before shipping</p>
    </label>
    <input id='faq-e' type='checkbox'>
    <label for='faq-e'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">What's your factory production capacity?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Normally 30,000pcs/per month. Depends on the products.</p>
    </label>
    <input id='faq-f' type='checkbox'>
    <label for='faq-f'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">How is your product quality?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We offer a high-quality product</p>
    </label>
    <input id='faq-g' type='checkbox'>
    <label for='faq-g'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">What are your payment terms?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We can offer UC, T/T, Western Union, Money Gram</p>
    </label>
    <div a-style display="flex" justify="flex-end">
      <p a-style fs="1.3" color="#2479AB" cursor="pointer" pt="1" m="0" (click)="feedback.openModal()">Feedback</p>
    </div>
  </div>
  
</div>

<m-modal #faq title="Q&A" subTitle="" button1="Cancel" width="74" top="14.5">
  <m-faq-modal [modal]="faq" (submit)="submit($event)" [details]="details"></m-faq-modal>
</m-modal>
<div>
<m-modal a-style top="14.5" #feedback>
  <m-rfq-feedback></m-rfq-feedback>
</m-modal>
</div> -->

<div>
  <div a-style mb="0.5" brBottom="0.5px solid #e4e4e4"  >
    <p a-style color="#2479AB" fs="1.6" mb="1.6">People Also Ask</p>
  </div>
  <div class='faq' a-style  >
    <input id='faq-a' type='checkbox'>
    <label for='faq-a'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet tempus. Aliquam v sit amet tempus. Aliquam vitae felis elit. Duis nec placerat justo. Vestibulum egestas hendrerit libero, quis mus massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet tempus. Aliquam vitae felis elit. Duis nec placerat justo. Vestibulum egestas hendrerit libero, quis mus massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet tempus. Aliquam vitae felis elit. Duis nec placerat justo. Vestibulum egestas hendrerit libero, quis mus massa. Lorem ipsum dolor sit amet, consectetur ad</p>
    </label>
    <input id='faq-b' type='checkbox'>
    <label for='faq-b'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
    </label>
    <input id='faq-c' type='checkbox'>
    <label for='faq-c'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We offer one year warranty period</p>
    </label>
    <input id='faq-d' type='checkbox'>
    <label for='faq-d'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Yes, each step of production and finished products will be carried out inspection by QC before shipping</p>
    </label>
    <input id='faq-e' type='checkbox'>
    <label for='faq-e'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">Normally 30,000pcs/per month. Depends on the products.</p>
    </label>
    <input id='faq-f' type='checkbox'>
    <label for='faq-f'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We offer a high-quality product</p>
    </label>
    <input id='faq-g' type='checkbox'>
    <label for='faq-g'>
      <p class="faq-heading" a-style fs="1.3" color="#333" mb="2" h="3.5" pt="2">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet?</p>
      <div class='faq-arrow'></div>
        <p class="faq-text" a-style fs="1.3" color="#707070">We can offer UC, T/T, Western Union, Money Gram</p>
    </label>
    <div a-style display="flex" justify="flex-end">
      <p a-style fs="1.3" color="#2479AB" cursor="pointer" pt="1" m="0" (click)="Think.openModal()" >Feedback</p>
    </div>
  </div>
</div>


<m-modal #Think width="72.6">
  <div a-style p="2.4">
      <p a-style fs="2.4" >What do you think?</p>
      <div a-style display="flex" gap="2.4" mt="2.4">
          <div a-style display="flex" align="center" gap="1">
              <!-- <m-new-input [type]="gs.enums.inputs.newRadioButton" p="0" name="rm"></m-new-input> -->
              <input type="radio" [(ngModel)]="radio" name="name" value="This is helpful" a-style w="2.0" h="2.0" /> 
              <p a-style w="29" align="left" fs="1.3">This is helpful</p>
          </div>
          <div a-style display="flex" align="center" gap="1">
              <!-- <m-new-input [type]="gs.enums.inputs.newRadioButton" p="0" name="rm"></m-new-input> -->
              <input type="radio" [(ngModel)]="radio"  name="name" value="Something is wrong" a-style w="2.0" h="2.0" /> 
              <p a-style w="29" align="left" fs="1.3"> Something is wrong</p>
          </div>
      </div>
      <div a-style display="flex" gap="2.4" mt="1.6">
          <div a-style display="flex" align="center" gap="1">
              <!-- <m-new-input [type]="gs.enums.inputs.newRadioButton" p="0" name="rm"></m-new-input> -->
              <input type="radio"  [(ngModel)]="radio" name="name" value="This isn't relevent" a-style w="2.0" h="2.0" /> 
              <p a-style w="29" align="left" fs="1.3">This isn't relevent</p>
          </div>
          <div a-style display="flex" align="center" gap="1">
              <!-- <m-new-input [type]="gs.enums.inputs.newRadioButton" p="0" name="rm"></m-new-input> -->
              <input type="radio" [(ngModel)]="radio"  name="name" value="This isn't useful" a-style w="2.0" h="2.0" /> 
              <p a-style w="29" align="left" fs="1.3">This isn't useful</p>
          </div>
      </div>
      <div a-style mt="2.4">
          <m-new-input [type]="gs.enums.inputs.newText_area" [(ngModel)]="comment" placeHolder="Write here" label="Comment" rows="3">
          </m-new-input>
      </div>


      <div a-style display="flex" align="end" gap="0.6" mt="2.4">
          <m-new-input [type]="gs.enums.inputs.newCheckbox"></m-new-input>
          <p a-style fs="1.3">I agree to <span a-style color="#2479AB">Terms of Service</span></p>
      </div>
      <div a-style display="flex" justify="space-between" mt="2.4">
          <m-button [types]="gs.enums.buttons.button0" text="Cancel" (click)="Think.closeModal()"></m-button>
          <m-button [types]="gs.enums.buttons.button0" text="Submit" (click)="feedback(details?._id);Think.closeModal()"></m-button>
      </div>
  </div>
</m-modal>
<!-- this.gs.toast('success', 'verifie', 'success'); -->