<div *ngIf="is_visible" [top] ="top ? top : '14.5'"  left="0"  zIndex="1000" a-style bg="rgb(0,0,0,0.2)" display="flex" justify="center" alignItems="center"  position="fixed" [h]="h? h :'calc(100vh - 7.6rem - 6.5rem)'" w="100vw">
  <ng-container [ngTemplateOutlet]="modalWithFormat"></ng-container>
</div>
<ng-template #modalWithFormat>
    <div class="modal no_scroll" brRadius=0.6 a-style [h]="height"  [maxHeight]='maxHeight?maxHeight:80' [bottom]="bottom? bottom : 0"  [w]="width" bg=white [p]="title? 2.4 : 0" position=relative >
        <div a-style display=flex justify=space-between *ngIf="title">
            <p a-style [fs]='fs?fs:1.6'>{{title}}</p>
        </div>
        <p *ngIf="subTitle" a-style fs=1.4>{{subTitle}}</p>
        <ng-content></ng-content>
        <!-- <div *ngIf="close_button" a-style w="2.5" h="2.5" cursor=pointer display="grid" placeContent="center" position="absolute" right=1 top=1  (click)="closeModal()" >
            <img src="../assets/images/cross.svg" a-style w="2.5" h="2.5" align="right">
          </div> -->
    </div>
</ng-template>
