<div a-style mt="2.4" p="2.4" pb=0 brRadius="0.5">
    <div a-style p="1.6" brRadius="0.6" *ngIf="queryParams.step != 'generic_category'" br="1px solid #E4E4E4">
        <m-image a-style w="100%" zIndex="-1" h="39.1" [src]="gs.enums.images.image52"> </m-image>
    </div>
    <div a-style display="flex" justify="space-between" mt="2.4" flexGap="2.4" textCenter="center">
        <div *ngIf="(queryParams.step == 'Tertiary' || queryParams.step == 'Secondary' || queryParams.step == 'Type')" a-style align="center" maxWidth="29.8">

            <ng-container *ngFor="let step of ['Primary','Secondary','Tertiary']">
                <!-- category side bar -->
                <div a-style mb=2.4 br="1px solid #e2e2e2" brRadius="0.6" w="30; 1440:29; 1336:28; 1280:27; 1024:26; 786:25" h="30; 1440:29; 1336:28; 1280:27; 1024:26; 786:25" *ngIf="selected[step]">
                    <div a-style display="flex" justify="center" w="100%" pt="1.2" brBottom="1px solid #E4E4E4" bg="#f8f8f8" h="6.174" px="1.6">
                        <m-text [type]="gs.enums.texts.heading1" fs="2; 1336:1.6; 1024:1.4" text="{{step}} Category"></m-text>
                    </div>
                    <div a-style py="2.4" px="8.665">
                        <m-image a-style h="12.4; 1920:11.4; 1440:10.4; 1336:9.4; 1280:8.4; 1024:7.4; 786:6.4" w="12.4; 1920:11.4; 1440:10.4; 1336:9.4; 1280:8.4; 1024:7.4; 786:6.4" [src]="selected[step]?.images?.colored? 'https://new-api.mesbro.com/uploads/'+selected[step]?.images?.colored : gs.enums.images.image33"> </m-image>
                        <m-text [type]="gs.enums.texts.paragraph38" fs="1.3; 1336:1.2; 1024:1.1" align="center" a-style text="{{selected[step]?.title}}" mt="2.4"></m-text>
                    </div>
                </div>
            </ng-container>

        </div>
        <div a-style w="100%" brRadius="0.4" mb=2.4 br="1px solid #E4E4E4">
            <div a-style display="flex" justify="space-between" align=center textCenter="center" w="100%" brBottom="1px solid #E4E4E4" bg="#f8f8f8" h="6.174" px="1.6" *ngIf="queryParams.step != 'generic_category'">
                <m-text [type]="gs.enums.texts.heading1" fs="2; 1336:1.6; 1024:1.4" text="{{queryParams.step}} Category"></m-text>
                <m-input [(ngModel)]="search_term" [list]="primary" fs="1.3" [columns]="1/4" maxWidth="35.2" px="0" a-style placeHolder="Search {{queryParams.step}} Category" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-black.svg"></m-input>
            </div>
            <ng-container *ngIf="queryParams.step == 'Primary'" [ngTemplateOutlet]="selectionStep"></ng-container>
            <ng-container *ngIf="queryParams.step == 'Secondary'" [ngTemplateOutlet]="selectionStep"></ng-container>
            <ng-container *ngIf="queryParams.step == 'Tertiary'" [ngTemplateOutlet]="selectionStep"></ng-container>
            <ng-container *ngIf="queryParams.step == 'Type'" [ngTemplateOutlet]="selectionStep"></ng-container>
            <ng-container *ngIf="queryParams.step == 'generic_category'" [ngTemplateOutlet]="product"></ng-container>

        </div>
    </div>
</div>

<ng-template #selectionStep>
    <ng-container *ngIf="queryParams.step != 'generic_category'">
        <m-category-card card_type="categoryCard2" [search_term]="search_term" (select)="select(queryParams.step,$event)" (previous_button)="redirectToPreviousStep()" [list]="list" [type]="queryParams.step"></m-category-card>
    </ng-container>
</ng-template>
<ng-template #inputitem>
    <div a-style display="flex" flexGap="1" pb="2">
        <m-image a-style w="1.9" h="1.9" [src]="gs.enums.images.image54"> </m-image>
        <m-text [type]="gs.enums.texts.paragraph38" a-style text="Musical Instrument"></m-text>
    </div>
</ng-template>
<ng-template #primarybox let-passedData="passedData">
    <div a-style w="23.075" h="21.7" align="center" dispaly="flex" justify="center" py="2.4" routerLink="/product/category" [queryParams]="{type: 'secondary'}">
        <m-image a-style w="11" h="11" [src]="gs.enums.images.image55"> </m-image>
        <div a-style mt="2.4">
            <m-text [type]="gs.enums.texts.paragraph38" a-style text="{{passedData.text}}"></m-text>
        </div>
    </div>
</ng-template>
<ng-template #product>
    <m-product-list></m-product-list>
</ng-template>