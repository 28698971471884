import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import sub from 'date-fns/sub';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-filter',
  templateUrl: './filter.component.html',
  styles: [
  ]
})
export class FilterComponent implements OnInit {
  @Input() modal: any
  visible = {
    price_range: true,
    brand: true,
    location: true,
    rating: true,
    history: true,
    offer: true,
  }
  // application = 'Product'
  // component = 'Product'
  @Input() application: any
  @Input() component: any
  @Output() close_button_clicked = new EventEmitter()
  @Output() filter_list = new EventEmitter()

  clearIcon: boolean = true;
  params: any = {
    type: '',
  }
  list = {
    brand: <any[]>[],
    offer: <any[]>[],
    // brand:[ 'HP',"LENOVO","DELL","ASUS","ACER","MAC" ],
    // offer:['Special offer','Exchange Offer',"No Cost EMI","Special Price","Buy More, Save More"]
  }
  // localStorageGet
  country: FormControl = new FormControl(localStorage)
  form: FormGroup | undefined;
  filters = [{
    title: '',
    selects: {},
    ranges: {},
    location: {
      amenity: "",
      road: " ",
      town: " ",
      state: " ",
      postcode: " ",
      country: " ",
      country_code: " "
    },
    itemId: " ",
    type: " ",
    typeId: " ",
    page: 0,
    count: true,
    status: "Approved",
    isPublished: true,
    isDraft: false
  }
  ]
  constructor(public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }
  first_time = true;

  ngOnInit(): void {
    this.initialize_form();
    this.get_list()
  }

  initialize_form() {
    this.form = this.fb.group({
      price_range_form: [''],
      price_range_to: [''],
      brand: [''],
      location: [''],
      rating: this.fb.group({
        one_and_above: [false],
        two_and_above: [false],
        three_and_above: [false],
        four_and_above: [false],
      }),
      history: this.fb.group({
        one_day_ago: [false],
        one_week_ago: [false],
        one_month_ago: [false],
        one_year_ago: [false],
      }),
      offer: [''],
    })
  }



  async get_list() {
    let brand = await this.gs.get_data_list('Generic', 'Brand', [])
    this.list.brand = brand.list
    this.list.brand = await this.list.brand.map(d => d.data)

    let offer = await this.gs.get_data_list('Generic', 'Offer', [])
    this.list.offer = offer.list
    this.list.offer = await this.list.offer.map(d => d.data)



  }
  async filter() {
    let body = this.filters;
    this.filter_list.emit(body[0].title ? body : [])
    // let response = await this.gs.get_data_list(this.application, this.component, body)
  }
  submit() {
    console.log(this.form?.value);
    let last_history = undefined;
    let history = ['year', 'month', 'week', 'day'].reverse()
    for (let i = history.length - 1; i >= 0; i--) {
      if (this.form?.value.history['one_' + history[i] + '_ago']) {
        console.log(new Date(), history[i], { [history[i] + 's']: 1 }, sub(new Date(), { years: 1 }))
        last_history = sub(new Date(), { [history[i] + 's']: 1 })
        break;
      }
    }

    let ratings = ['one_and_above', 'two_and_above', 'three_and_above', 'four_and_above']
    let last_rating = undefined;
    for (let i = ratings.length - 1; i >= 0; i--) {
      if (this.form?.value.rating[ratings[i]]) {
        last_rating = i + 1
        break;
      }
    }
    let filter = [
      {
        $and: <any>[]
      }
    ]
    let array = []
    if (this.form?.value.price_range_form) array.push({ 'price_from': { $gte: this.form?.value.price_range_form } })
    if (this.form?.value.price_range_form) array.push({ 'price_to': { $lte: this.form?.value.price_range_to } })
    if (this.form?.value.brand) array.push({ 'brandControl': { $in: this.form?.value.brand } })
    if (this.form?.value.offer) array.push({ 'offerControl': { $in: this.form?.value.offer } })
    if (last_rating) array.push({ 'meta.activity.average_rating': { $gte: last_rating } })
    if (last_history) array.push({ 'meta.activity.updated_at': { $gte: last_history } })

    filter[0].$and = array;

    console.log(filter)
    this.filter_list.emit(filter);
  }
}
