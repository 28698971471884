<ng-container *ngIf="type =='Brand_card' " [ngTemplateOutlet]="Brand_card"></ng-container>
<ng-container *ngIf="type =='card_2' " [ngTemplateOutlet]="card_2"></ng-container>

<!-- <ng-template #Brand_card>

    <div a-style textCenter="center" w="16.2" h="16.2" shadow="hov:0 3px 6px 0 #f1f1f1" pb="1.2">
        m="1.2"
        <div a-style br="1px solid #E4E4E4" brRadius="0.4" p=1.2>
        <m-image src='https://new-api.mesbro.com/uploads/{{detail?.data?.image}}' w="16.2" h="16.2"> </m-image>
        <p a-style fs="1.4">{{detail?.data?.brand}}</p>
        </div>
    </div>
</ng-template> -->

<ng-template #Brand_card>
    <!-- <div a-style w="20.3"> -->
    <div a-style m="1.2">
        <m-image src='https://new-api.mesbro.com/uploads/{{detail?.data?.image}}' w="16.2" h="16.2"
            shadow="hov:0 3px 6px 0 #f1f1f1" brRadius="0.6" title=""> </m-image>
        <!-- shadow="hov:0 3px 6px 0" -->
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #card_2>
    <!-- <div a-style br="0.5px solid #e4e4e4" brRadius=0.6 p=2.4 pb=1.3 textCenter=center>
        <m-image src="https://new-api.mesbro.com/uploads/{{detail?.data?.image}}" h=12.47 w=12.47></m-image>
        <m-text [type]="gs.enums.texts.paragraph1" mt="0"  text="{{detail?.data?.brand}}"></m-text>
    </div> -->
    <div>
        <div class="MagicScroll" a-style p=1 w="17" h="20" br="0.5px solid #E4E4E4" brRadius="0.6" bg="#FFFFFF;hov:#333333">
          <div a-style position="relative" align="center">
            <m-image src="https://new-api.mesbro.com/uploads/{{detail?.files?.profile_picture}}" h=12.3 w=12.3></m-image>
            <m-image *ngIf="false" src="../assets/images/assets/product/ad.svg" a-style w=2.6 h=1.6 title="Compare" a-style
              w=2.5 h=2.5 top=0.5 left="0.9" position="absolute"> </m-image>
          </div>
          <div a-style h=1.8 textCenter=center align="center" gap="0.3" justify="center">
            <m-text [type]="gs.enums.texts.paragraph1" my="1.6"  text="{{detail?.brand_details?.brand}}"></m-text>
          </div>
        </div>
      </div>
</ng-template>