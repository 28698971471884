<!-----------------------------------Add new label-------------------------------------------->

<div *ngIf="true" a-style bg="white" p="2.4" brRadius="6px" top="0" w="37.5">
  <div a-style display="flex" justify="space-between">
    <div>
      <p a-style fs="16px" fw="400" color="#333333" opacity="1">Add new label</p>
    </div>
  </div>
  <p a-style fs="1.3" mt="2.5">Select Color</p>
  <!-- <div a-style w="15.9" ml="12" h="15.9"
      br="1px solid #e2e2e2" mb="2.4" brRadius="50%" formControlName="label_color" [style.background]=color (click)="colors=!colors"
      align="center"></div> -->
  <div a-style bg="white" brRadius="0.4" display="flex" gap="1.5">
    <!-- <div a-style w="50px" h="50px" bg="#EC4F6F" brRadius="0.4" (click)="changeBG('#EC4F6F') ; clicked=!clicked" align="center" pt="1.5" brRadius="50%" > 
      <img *ngIf="clicked" src="assets/images/assets/new-chat/right-tick_ coloured.svg" alt="" a-style w="23px" h=1.7>
  </div> -->
    <div a-style pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('EFECEC');nextcolor=!nextcolor;clicked=false;thridcolor=false;fourthcolor=false;fifthcolor=false;sixthcolor=false">
      <!-- <img a-style position="relative" w="4.1" h="4.1" src="assets/images/assets/Group_3.svg" alt=""> -->
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#F3F1F1"> </div>
      <img *ngIf="nextcolor" src="..assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px" h=1.7
        position="absolute" top="12.3" left="3.4">
    </div>
    <div a-style w="50px" h="50px" pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('FFF5BD');clicked=!clicked;thridcolor=false;fourthcolor=false;fifthcolor=false;sixthcolor=false;nextcolor=false">
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#FFF7C8"> </div>
      <img *ngIf="clicked" src="../assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px" h=1.7
        position="absolute"top="12.2" left="9.4">
    </div>
    <div a-style w="4.1" h="4.1" pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('9CE3BF');thridcolor=!thridcolor;clicked=false;fourthcolor=false;fifthcolor=false;sixthcolor=false;nextcolor=false">
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#A5EEC9"> </div>
      <img *ngIf="thridcolor" src="../assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px" h=1.7
        position="absolute"top="12.3" left="15.2">
    </div>
    <div a-style w="4.1" h="4.1" pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('C4E5F9');fourthcolor=!fourthcolor;clicked=false;thridcolor=false;fifthcolor=false;sixthcolor=false;nextcolor=false">
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#D7ECF9"> </div>
      <img *ngIf="fourthcolor" src="../assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px"
        h=1.7 position="absolute"top="12.3" left="20.8">
    </div>
    <div a-style w="4.1" h="4.1" pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('FFA7B6');fifthcolor=!fifthcolor;clicked=false;thridcolor=false;fourthcolor=false;sixthcolor=false;nextcolor=false">
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#FFC1CB"> </div>
      <img *ngIf="fifthcolor" src="../assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px" h=1.7
        position="absolute"top="12.3" right="8.9">
    </div>
    <div a-style w="4.1" h="4.1" pt="1.5" align="center" brRadius="25px"
      (click)="changeBG('C7CEFF');sixthcolor=!sixthcolor;clicked=false;thridcolor=false;fourthcolor=false;fifthcolor=false;nextcolor=false">
      <div a-style w="4.1" h="4.1" brRadius="50%" bg="#DADEFB"> </div>
      <img *ngIf="sixthcolor" src="../assets/images/Icon_Bank/Selection_Tick_Coloured.svg" alt="" a-style w="23px" h=1.7
        position="absolute"top="12.3" right="3.4">
    </div>
  </div>
  <form [formGroup]="form">
    <div a-style pt="13px">
      <m-input formControlName="Label_name" type="text" label="Label Name" a-style w="32.7" pl=0 pr=0 h="33px"
        [uppercases]=false [capitalize]=false br="0.5px solid #E4E4E4" brRadius="6px"></m-input>
    </div>
    <div a-style>
      <p a-style fs="1.3">Description</p><br>
      <textarea formControlName="description" a-style w="372px" col=12 p="1" fs="1.3" w="32.7" h="143px"
        br="0.5px solid #E4E4E4" brRadius="6px">
      </textarea>
      <!-- p="0.8px 0.7px 0.8px 0.7px" -->
    </div>
  </form>
  <div a-style display="flex" mt="2.4" justify="space-between">
    <m-button [types]="gs.enums.buttons.button0" text="Cancel" (click)="labelForm.closeModal()"></m-button>
    <m-button [types]="gs.enums.buttons.button0" text="Create" (click)="createLabel();labelForm.closeModal();success.openModal()"></m-button>
  </div>
</div>
<m-success-modal #success title="Label Created Successfully"></m-success-modal>

<!-----------------------------------Add new label end-------------------------------------------->