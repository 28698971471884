import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-form',
  templateUrl: './form.component.html',
  styles: [
  ]
})
export class FormComponent implements OnInit {

  @HostBinding('style.form_') display = 'contents'
  @Input() title: string = '';
  @Input() justify_title: any;
  @Input() block: string = '';
  @Input() mt: string = '';
  @Input() mb: string = '';
  @Input() ml: string = '';
  @Input() title_mt: string = '';
  @Input() title_mb: string = '';
  @Input() title_ml: string = '';
  @Input() px: string = '';
  @Input() list_px: string = '';
  @Input() pb: string = '';
  @Input() form_padding: string = '';
  @Input() pt: string = '';
  @Input() pl: string = '';
  @Input() m: string = '';
  @Input() mr: string = '';
  @Input() mx: string = '';
  @Input() fs: string = '';
  @Input() w: string = '';
  @Input() maxWidth: string = '';
  @Input() minWidth: string = '';
  @Input() gap: string = '';
  @Input() flexWrap: string = '';
  @Input() br: string = '';
  @Input() justify: string = '';
  @Input() overflowX: string = '';
  @Input() bg: string = '';
  @Input() my: string = '';
  @Input() labelWeight: string = '';
  @Input() brNone: boolean = true;
  @Input() brBottom: boolean = true;
  @Input() paddingNone: boolean = true;
  @Input() text: any;
  @Input() brRadius: any;
  @Input() flexDirection: any;
  @Input() form_display: any;

  @Output() clicked = new EventEmitter();

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }
}
