import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-related-product',
  templateUrl: './related-product.component.html',
  styles: [
  ]
})
export class RelatedProductComponent implements OnInit {

  @Input() index:any;
  @Input() detail:any;
  @Input() type:any;
  @Input() list:any;
  constructor(public gs : GlobalService) { }

  ngOnInit(): void {
  }

}
