<!-- <div a-style w="43.2" br= "1px solid #E4E4E4" bg="#ffffff" h="25.5" brRadius="0.6" p="2.4">
    <p fs="2" a-style>Ignore conversation?</p>
    <p a-style color="#707070" fs="1.3" mt="1.6">This will delete all messages for the selected conversations from all folder except Sent items.
        All new item that are part of the selected conversations will also be deleted.</p>
        <div a-style display="flex" mt="1.6" align="center">
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" a-style mr="0.8" br="1px solid #E4E4E4">
            <p a-style fs="1.3">Don't  me this message again.</p>
        </div>
        <div a-style display="flex" justify="flex-end"  mt="1.6">
            <button a-style py="0.8" px="1.6" br="1px solid #E4E4E4" color="#707070" brRadius="0.6" bg="white"
                mr="1.6">Cancel</button>
            <button a-style py="0.8" px="2" br="1px solid #E4E4E4" brRadius="0.6" bg="#006BFF" color="#FFFFFF">Clear
                filter</button>
        </div>
</div> -->

<!-- <ng-container [ngTemplateOutlet]="mains" [ngTemplateOutletContext]="{data:{title:'Ignore conversation?',description:'This will delete all messages for the selected conversations from all folder except Sent items.
All new item that are part of the selected conversations will also be deleted.'}}">
</ng-container> -->
<!--
<ng-template #mains >
    <p a-style color="#333333" fs="2" *ngIf="title">{{title}}</p>
    <p a-style color="#707070" fs="1.3" *ngIf="description">{{description}}</p>

    <div a-style display="flex" justify="flex-end"  mt="1.6">
        <button a-style py="0.8" px="1.6" br="1px solid #E4E4E4" color="#707070" brRadius="0.6" bg="white"
            mr="1.6">Cancel</button>
        <button a-style py="0.8" px="2" br="1px solid #E4E4E4" brRadius="0.6" bg="#006BFF" color="#FFFFFF">Clear
            filter</button>
    </div>
    <p a-style color="#333333" fs="1.3" *ngIf="description">{{description}}</p>

    <div a-style mt="1">
        <label  fs="1.3" a-style>{{inputstitle}}</label><br>
        <input type="text" id="fname" name="fname" a-style w="27.9" mt="1"  br="1px solid #E4E4E4" brRadius="0.6"
            mr="1.6" h="3.9">
    </div>
</ng-template>  -->

<div *ngIf="isVisible" top="0" left="0" zIndex="2000" a-style bg="rgb(0,0,0,0.8)" display="grid" placeContent="center" cursor="pointer" position="fixed" h="100vh" w="100vw">
    <ng-container [ngTemplateOutlet]="modalWithFormat"></ng-container>
</div>
<ng-template #modalWithFormat>
    <div a-style [h]="height" [w]="width" bg=white [p]="title? 2.4 : 0" brRadius="0.6">
        <div a-style display=flex justify=space-between *ngIf="title">
            <p a-style fs=1.6>{{title}}</p>
            <a a-style bg=white>
                <span a-style aria-hidden="true" fs=2 (click)="closeModal()">&times;</span>
            </a>
        </div>
        <p *ngIf="subTitle" a-style fs=1.4>{{subTitle}}</p>
        <m-text></m-text>
        <p *ngIf="description" a-style fs="1.3" mt="1.2">{{description}}</p>
        <ng-content></ng-content>
        <div *ngIf="checkText" a-style display="flex" flexGap="0.8" mt="2.5">
            <input type="checkbox" a-style h="1.8" w="1.8">
            <p a-style mb="1.5" fs="1.3">{{ checkText }}</p>
        </div>
        <div *ngIf="button3" a-style mt="5" display="flex" flexGap="1.6" [justify]="space?'space-between':'flex-end'">
            <m-button [types]="gs.enums.buttons.button3" (click)="closeModal()" >{{button4}}</m-button>
            <button *ngIf="button2" a-style fs="1.3" cursor="pointer" br="none" color="#FFFFFF" bg="#006BFF" px="2" py="0.7" brRadius="0.4" (click)="snooze.emit('');ok.emit(); closeModal()">{{button2}}</button>
        </div>
        <div>
            
        </div>
    </div>
</ng-template>
