<div>
    <form [formGroup]="form" a-style br="1px solid #e4e4e4" brRadius="0.6">
        <m-form columns="6" [brNone]=false >
            <m-input [type]="gs.enums.inputs.text" [columns]="1/2" formControlName="firstName" px="1" placeHolder="First Name" mr="1" label="First Name"></m-input>
            <m-input [type]="gs.enums.inputs.text" [columns]="1/2" formControlName="lastName" px="1" placeHolder="Last Name" label="Last Name"></m-input>
            <P a-style fs="1.3" fw="400" mt="0.5" mx="1.5">Address</P>
            <textarea formControlName="address" mx="1" a-style h=20 w=100% label="Address" placeholder="Address" mt=1 br="1px solid #f1f1f1" brRadius="0.5" p="1" mb="0.5"></textarea>
            <m-input [type]="gs.enums.inputs.dropdown" [columns]="1/4" formControlName="productType" px="1" placeHolder="Product Type" label="Product Type"></m-input>
            <m-input [type]="gs.enums.inputs.text" [columns]="1/4" formControlName="description" px="1" placeHolder="Description" label="Description"></m-input>
            <m-input [type]="gs.enums.inputs.text" [columns]="1/4" formControlName="quantity" px="1" placeHolder="Quantity" label="Quantity"></m-input>
            <m-input [type]="gs.enums.inputs.text" [columns]="1/5" formControlName="price" px="1" placeHolder="Price" label="Price"></m-input>
            <m-button [types]="gs.enums.buttons.button16" text="+" align="right" mt="3.8" mb="1"></m-button>
        </m-form>
        <div a-style align="right" mr="1.6">
            <m-button [types]="gs.enums.buttons.button18" text="Submit" align="right" (click)="submit()"></m-button>
        </div>
    </form>
</div>
<!-- {{form?.value|json}} -->