import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'm-artical-card',
  templateUrl: './artical-card.component.html',
  styles: [
  ]
})
export class ArticalCardComponent implements OnInit {

  @Input() detail: any;
  @Input() type: any;
  @Input() details: any;
  constructor(public gs: GlobalService, private router: Router, public ar:ActivatedRoute) { }
  application='Article' 

  icon : Boolean = false;

  ngOnInit(): void {
  }
  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id} });
  }

  add_to_compare() {
    console.log('Function called', this.detail)
    if (!this.detail) {
      return;
    }
    let current = this.gs.localStorageGet('compare-items') || {};
    current[this.detail._id] = this.detail;
    this.gs.localStorageSet('compare-items', current)
    this.gs.toast('Compare ' + this.detail.application, this.detail.application + ' was added to compare list', 'success', 3)
    // this.current = !this.current
    this.gs.$refresh_compare.next()
  }

}
