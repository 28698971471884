export const applications_array = ['Product', 'Vehicle', 'Subsidy', 'Recipe', 'Article']
let application_images : any = {
  Product : 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
  Vehicle : 'https://mesbro.in/assets/images/app-icon/vehicle.svg',
  // Welfare : 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
  Subsidy : 'https://mesbro.in/assets/images/app-icon/subsidy.svg',
  Recipe : 'https://mesbro.in/assets/images/app-icon/recipe.svg',
  Article : 'https://mesbro.in/assets/images/app-icon/article.svg',
}

export const deleted_categories: any = {
  Vehicle: ['Secondary', 'Tertiary'],
  Welfare: ['Tertiary'],
  Subsidy: ['Tertiary'],
}

function all_my_list() {
  let app_list: any = []
  applications_array.forEach(app => {
    app_list.push({
      title: app + 's',
      image: application_images[app],
      link: '/dashboard/comman/list',
      link_params: { application: app }
    });
  })
  return app_list;
}

function single_attribute_application(application: string) {

  let children = [
    {
      title: 'Primary',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/primary-colored.svg',
      application: 'Attribute',
      permission: 'primary_list',
      children: [
        { title: 'Add', image: '../assets/images/assets/add.svg', application: 'Attribute', permission: 'primary_add', link: '/dashboard/attribute/form', link_params: { type: 'Category', sub_type: 'Primary', application: application, } },
        { title: 'List', image: '../assets/images/assets/list.svg', application: 'Attribute', permission: 'primary_list', link: '/dashboard/attribute/list', link_params: { type: 'Category', sub_type: 'Primary', application: application, } },
      ]
    },
    {
      title: 'Secondary',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/secondary-colored.svg',
      application: 'Attribute',
      permission: 'secondary_list',
      children: [
        { title: 'Add', image: '../assets/images/assets/add.svg', application: 'Attribute', permission: 'secondary_add', link: '/dashboard/attribute/form', link_params: { type: 'Category', sub_type: 'Secondary', application: application, } },
        { title: 'List', image: '../assets/images/assets/list.svg', application: 'Attribute', permission: 'secondary_list', link: '/dashboard/attribute/list', link_params: { type: 'Category', sub_type: 'Secondary', application: application, } },
      ]
    },
    {
      title: 'Tertiary',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/tertiary-colored.svg',
      application: 'Attribute',
      permission: 'tertiary_list',
      children: [
        { title: 'Add', image: '../assets/images/assets/add.svg', application: 'Attribute', permission: 'tertiary_add', link: '/dashboard/attribute/form', link_params: { type: 'Category', sub_type: 'Tertiary', application: application, } },
        { title: 'List', image: '../assets/images/assets/list.svg', application: 'Attribute', permission: 'tertiary_list', link: '/dashboard/attribute/list', link_params: { type: 'Category', sub_type: 'Tertiary', application: application, } },
      ]
    },

    {
      title: 'Fixed',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b98d6590-0b67-11ea-8bf1-cf96fd5dbf2b.png',
      application: 'Attribute',
      permission: 'type_list',
      children: [
        { title: 'Add', image: '../assets/images/assets/add.svg', link: '/dashboard/attribute/form', application: 'Attribute', permission: 'type_add', link_params: { type: 'Fixed', sub_type: 'Fixed', application: application, } },
        { title: 'List', image: '../assets/images/assets/list.svg', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'type_list', link_params: { type: 'Fixed', sub_type: 'Fixed', application: application, } },
      ]
    },
    {
      title: 'Type',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b98d6590-0b67-11ea-8bf1-cf96fd5dbf2b.png',
      application: 'Attribute',
      permission: 'type_list',
      children: [
        { title: 'Add', image: '../assets/images/assets/add.svg', link: '/dashboard/attribute/form', application: 'Attribute', permission: 'type_add', link_params: { type: 'Type', sub_type: 'Type', application: application, } },
        { title: 'List', image: '../assets/images/assets/list.svg', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'type_list', link_params: { type: 'Type', sub_type: 'Type', application: application, } },
      ]
    },
    {
      title: application,
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5e8ef62f4ba657003fa6f2a2.png',
      application: 'Attribute',
      permission: 'product_list',
      children: [
        { title: 'Add Product Type', image: '../assets/images/assets/add.svg', application: 'Attribute', permission: 'product_add', link: '/dashboard/attribute/form', link_params: { type: 'Category', sub_type: application, application: application, } },
        { title: 'Approvals List Type', image: '../assets/images/assets/list.svg', application: 'Attribute', permission: 'product_list', link: '/dashboard/attribute/list', link_params: { type: 'Category', sub_type: application, application: application, } },
      ]
    },

    {
      title: 'Attribute',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/attribute-colored.svg',
      application: 'Attribute',
      permission: 'attribute',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'attribute', link_params: { type: 'Type', sub_type: 'Type', group: 'Attribute', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'attribute', link_params: { type: 'Type', sub_type: 'Type', group: 'Attribute', application: application, } },
      ]
    },
    {
      title: 'Image',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/image-colored.svg',
      application: 'Attribute',
      permission: 'image',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'image', link_params: { type: 'pending', sub_type: 'Type', group: 'Image', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'image', link_params: { type: 'completed', sub_type: 'Type', group: 'Image', application: application, } },
      ]
    },
    {
      title: 'Description',
      image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/description-colored.svg',
      application: 'Attribute',
      permission: 'description',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'description', link_params: { type: 'pending', sub_type: 'Type', group: 'Description', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'description', link_params: { type: 'completed', sub_type: 'Type', group: 'Description', application: application, } },
      ]
    },
    {
      title: 'FAQ',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5ee8cea257fa8100341c08dd.png',
      application: 'Attribute',
      permission: 'faq',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'faq', link_params: { type: 'pending', sub_type: 'Type', group: 'FAQ', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'faq', link_params: { type: 'completed', sub_type: 'Type', group: 'FAQ', application: application, } },
      ]
    },
    {
      title: 'Applications',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5ee8cd1457fa8100341c0874.png',
      application: 'Attribute',
      permission: 'applications',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'applications', link_params: { type: 'pending', sub_type: 'Type', group: 'Application', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'applications', link_params: { type: 'completed', sub_type: 'Type', group: 'Application', application: application, } },
      ]
    },
    {
      title: 'Tags',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5ee8cab157fa8100341c0811.png',
      application: 'Attribute',
      permission: 'tags',
      children: [
        { title: 'Pending', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'tags', link_params: { type: 'pending', sub_type: 'Type', group: 'Tags', application: application, } },
        { title: 'Completed', link: '/dashboard/attribute/list', application: 'Attribute', permission: 'tags', link_params: { type: 'completed', sub_type: 'Type', group: 'Tags', application: application, } },
      ]
    },
    {
      title: 'Report',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/5fde4280-0b67-11ea-8bf1-cf96fd5dbf2b.png',
      link: '/dashboard/attribute/reports',
      application: 'Attribute', permission: 'report',
    },
  ]

  let single_application = {
    title: application,
    image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/primary-colored.svg',
    // application: 'Attribute',
    // permission: application,
    children: children
  }
  if (deleted_categories[application]) {
    deleted_categories[application].forEach((category: string) => {
      let i = single_application.children.findIndex(e => { return e?.title == category });
      if (i > 0) {
        delete single_application.children[i]
      }
    })
  }

  return single_application;

}
function all_attribute_applications() {
  let attribute_app_paths: any = []
  applications_array.forEach(app => {
    let single_app = single_attribute_application(app);
    attribute_app_paths.push(single_app);
  })
  return attribute_app_paths;
}

export const side_bar_items = [
  {
    title: 'Dashboard',
    image: '../assets/images/assets/dashboard.svg',
    link: '/dashboard/group/list',
  },
  {
    title: 'Masters',
    image: '../assets/images/assets/masters.svg',
    application : 'Admin',
    permission : 'masters',
    children: [
      {
        title: 'Gender',
        image: '../assets/images/assets/gender.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Gender' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Gender' }
          },
        ]
      },
      {
        title: 'Appellation',
        image: '../assets/images/assets/appllation.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Appellation' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Appellation' }
          },
        ]
      },
      {
        title: 'Nature Of Business',
        image: '../assets/images/assets/naturebusiness.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Nature Of Business' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Nature Of Business' }
          },
        ]
      },
      {
        title: 'Primary Citizenship',
        image: '../assets/images/assets/primarycitizonship.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Primary Citizenship' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Primary Citizenship' }
          },
        ]
      },
      {
        title: 'Nearest Airport',
        image: '../assets/images/assets/nearbyairpot.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Nearest Airport' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Nearest Airport' }
          },
        ]
      },
      {
        title: 'Privacy',
        image: '../assets/images/assets/privacy.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Privacy' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Privacy' }
          },
        ]
      },
      {
        title: 'Question',
        image: '../assets/images/assets/question.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Question Category' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Question Category' }
          },
        ]
      },
      {
        title: 'Sourcing Type',
        image: '../assets/images/assets/sourcing_purpose.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Sourcing Type' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Sourcing Type' }
          },
        ]
      },
      {
        title: 'Sourcing Purpose',
        image: '../assets/images/assets/sourcing_purpose.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Sourcing Purpose' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Sourcing Purpose' }
          },
        ]
      },
      {
        title: 'Trade Terms',
        image: '../assets/images/assets/tradeterms.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Trade Terms' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Trade Terms' }
          },
        ]
      },
      {
        title: 'Location',
        image: '../assets/images/assets/location.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Location' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Location' }
          },
        ]
      },
      {
        title: 'Currency',
        image: '../assets/images/assets/currancy.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Currency' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Currency' }
          },
        ]
      },
      {
        title: 'Unit',
        image: '../assets/images/assets/unit.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Unit' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Unit' }
          },
        ]
      },
      {
        title: 'Certifications',
        image: '../assets/images/assets/certificate.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Certifications' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Certifications' }
          },
        ]
      },
      {
        title: 'Shipping Method',
        image: '../assets/images/assets/shipping_method.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Shipping Method' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Shipping Method' }
          },
        ]
      },
      {
        title: 'Destination',
        image: '../assets/images/assets/destination.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Destination' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Destination' }
          },
        ]
      },
      {
        title: 'Payment Term',
        image: '../assets/images/assets/payment_term.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Payment Term' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Payment Term' }
          },
        ]
      },
      {
        title: 'Constitution of Business',
        image: '../assets/images/assets/constitution_of_business.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Constitution of Business' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Constitution of Business' }
          },
        ]
      },
      {
        title: 'Country',
        image: '../assets/images/assets/country.svg',
        children: [
          {
            title: 'Add',
            image: '../assets/images/assets/add.svg',
            link: 'master/form',
            link_params: { application: 'Master', component: 'Country' }
          },
          {
            title: 'List',
            image: '../assets/images/assets/list.svg',
            link: 'master/list',
            link_params: { application: 'Master', component: 'Country' }
          },
        ]
      },

    ]
  },
  {
    title: 'My Profile',
    image: '../assets/images/assets/dummy.svg',
    link: '/dashboard/profile/form',

  },
  {
    title: 'Organizations',
    image: '../assets/images/assets/organization.svg',
    link: '/dashboard/group/list',

  },
  {
    title: 'My List',
    image: '../assets/images/assets/mylist.svg',
    children: all_my_list()
  },

  {
    title: 'RFQ',
    image: '../assets/images/assets/rfq.svg',
    link: '/dashboard/group/rfq',
    link_params: { type: 'received_rfq' }
  },
  {
    title: 'Enquiry',
    image: '../assets/images/assets/inquiry.svg',
    link: '/dashboard/group/enquiry/list',
    link_params: { type: 'received_enquiry' }
  },
  {
    title: 'Feedback',
    image: '../assets/images/assets/feedback.svg',
    application: 'generic',

    children: [
      { title: 'Product Feedback', application: 'admin', link: '/dashboard/product/feedback/list', link_params: { type: 'feedback' } },
      { title: 'Report Bug', application: 'admin', link: '/dashboard/feedback/list', link_params: { type: 'bug' } },
      { title: 'Suggest Feature', application: 'admin', link: '/dashboard/feedback/list', link_params: { type: 'feature' } },
      { title: 'Report Product', application: 'admin', link: '/dashboard/feedback/list', link_params: { type: 'report product' } },
      { title: 'Report comment', application: 'admin', link: '/dashboard/feedback/list', link_params: { type: 'report comment' } },
      { title: 'Report FAQ', application: 'admin', link: '/dashboard/feedback/list', link_params: { type: 'report faq' } },
    ]
  },
  // {
  //   title: 'Activity',
  //   image: '../assets/images/assets/activity.svg',
  //   link: '/dashboard/generic-feedback/list'
  // },
  {
    title: 'Brand',
    image: '../assets/images/assets/brand.svg',
    application: 'Admin',
    permission: 'brand',
    children: [
      {
        title: 'Add',
        image: '../assets/images/assets/add.svg',
        link: '/dashboard/group/brand/form',
      },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/group/brand/list',
      },
    ]
  },
  {
    title: 'Campaign',
    image: '../assets/images/assets/brand.svg',
    children: [
      {
        title: 'Add',
        image: '../assets/images/assets/add.svg',
        link: '/dashboard/campaign/form',
      },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/campaign/list',
      },
    ]
  },
  {
    title: 'Shortlink',
    image: '../assets/images/assets/brand.svg',
    children: [
      {
        title: 'Add',
        image: '../assets/images/assets/add.svg',
        link: '/dashboard/shortlink/form',
      },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/shortlink/list',
      },
    ]
  },
  {
    title: 'User',
    image: '../assets/images/assets/user.svg',
    children: [
      // {
      //   title: 'Add',
      //   image: '../assets/images/assets/add.svg',
      //   link: '/dashboard/user/list',
      // },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/user/list',
      },

    ]
  },
  {
    title: 'Domain',    
    image: '../assets/images/assets/mylist.svg',

    children: [
      // {
      //   title: 'Add',
      //   image: '../assets/images/assets/add.svg',
      //   link: '/dashboard/domain/list',
      // },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/domain/list',
      },
    ]
  },
  {
    title: 'Privilege User',
    image: '../assets/images/assets/privilege_user.svg',
    application : 'Admin',
    permission : 'privilege_users',
    children: [
      {
        title: 'Add',
        image: '../assets/images/assets/add.svg',
        link: '/dashboard/privilege-user/form',
      },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/privilege-user/list',
      },
    ]
  },

  {
    title: 'Offer',
    image: '../assets/images/assets/offer.svg',
    application: 'Admin',
    permission: 'brand',
    children: [
      {
        title: 'Add',
        image: '../assets/images/assets/add.svg',
        link: '/dashboard/group/offer/form',
      },
      {
        title: 'List',
        image: '../assets/images/assets/list.svg',
        link: '/dashboard/group/offer/list',
      },
    ]
  },
  // {
  //   title: 'Content',
  //   image: '../assets/images/assets/content.svg',
  //   link: '/dashboard/content/form',
  // },
  {
    title: 'Billing',
    image: '../assets/images/assets/billing.svg',
    link: '/dashboard/payment/billing',
  },
  {
    title: 'Attribute',
    image: '../assets/images/assets/attribute.svg',
    application: 'Attribute', permission: 'main_permission',
    children: all_attribute_applications()
  },
  {
    title: 'Permissions',
    image: '../assets/images/assets/permissin.svg',
    link: '/dashboard/attribute/user-permission',
    application: 'Admin', permission: 'permissions',

  },
  {
    title: 'Approvals',
    image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/a575ba30-ced6-11e9-8bf1-cf96fd5dbf2b.png',
    application: 'Product',
    permission: 'approved',
    children: [
      { title: 'Approved', application: 'Product', permission: 'approved', link: '/dashboard/product-status', link_params: { status: 'Approved', application: 'Product', component: 'Product' } },
      { title: 'Blocked', application: 'Product', permission: 'blocked', link: '/dashboard/product-status', link_params: { status: 'Blocked', application: 'Product', component: 'Product' } },
      { title: 'Reported', application: 'Product', permission: 'reported', link: '/dashboard/product-status', link_params: { status: 'Reported', application: 'Product', component: 'Product' } },
      { title: 'Pending', application: 'Product', permission: 'pending', link: '/dashboard/product-status', link_params: { status: 'Pending', application: 'Product', component: 'Product' } }
    ]
  },
  {
    title: 'Brand Approval',
    image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/a575ba30-ced6-11e9-8bf1-cf96fd5dbf2b.png',
    application: 'Brand',
    permission: 'brand_list',
    children: [
      { title: 'Approved', application: 'Brand', permission: 'pending', link: '/dashboard/product-status', link_params: { status: 'Approved', application: 'Generic', component: 'Brand' } },
      { title: 'Blocked', application: 'Brand', permission: 'blocked', link: '/dashboard/product-status', link_params: { status: 'Blocked', application: 'Generic', component: 'Brand' } },
      { title: 'Reported', application: 'Brand', permission: 'reported', link: '/dashboard/product-status', link_params: { status: 'Reported', application: 'Generic', component: 'Brand' } },
      { title: 'Pending', application: 'Brand', permission: 'pending', link: '/dashboard/product-status', link_params: { status: 'Pending', application: 'Generic', component: 'Brand' } }
    ]
  },
  {
    title: 'Subscriptions',
    image: '../assets/images/assets/subscriptions.svg',
    link: '/dashboard/group/subscriptions',
    application: 'Admin', permission: 'subscriptions',

  },
  {
    title: 'Leads List',
    image: '../assets/images/assets/lead.svg',
    link: '/dashboard/leads/list',
    application: 'Admin', permission: 'leads',

  },
  {
    title: 'Payment',
    image: '../assets/images/assets/payment.svg',
    application: 'Admin',
    permission: 'packages',
    children: [
      { title: 'Add', image: '../assets/images/assets/add.svg', link: '/dashboard/payment/package/form', link_params: { type: 'Type', sub_type: 'group', application: 'Payment', } },
      { title: 'List', image: '../assets/images/assets/list.svg', link: '/dashboard/payment/package/list', link_params: { type: 'Type', sub_type: 'group', application: 'Payment', } },
    ]
  },
  {
    title: 'Discount',
    image: '../assets/images/assets/discount.svg',
    application: 'Admin',
    permission: 'discount_codes',
    children: [
      { title: 'Add', image: '../assets/images/assets/add.svg', link: '/dashboard/payment/discount/form', link_params: { type: 'Type', sub_type: 'group', application: 'General', } },
      { title: 'List', image: '../assets/images/assets/list.svg', link: '/dashboard/payment/discount/list', link_params: { type: 'Type', sub_type: 'group', application: 'General', } },
    ]
  },
  {
    title: 'package',
    image: 'https://mesbro-product.mesbro.in/assets/images/sideMenu-icon/primary-colored.svg',
    application: 'Attribute',
    permission: 'packages',
    children: [
      { title: 'Add', image: '../assets/images/assets/add.svg', application: 'Attribute', permission: 'package_add', link: '/dashboard/payment/discount/form', link_params: { type: 'Type', sub_type: 'group', application: 'General', } },
      { title: 'List', image: '../assets/images/assets/list.svg', application: 'Attribute', permission: 'package_add', link: '/dashboard/payment/discount/list', link_params: { type: 'Type', sub_type: 'group', application: 'General', } },
    ]
  },
]

