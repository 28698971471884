<div position="fixed" a-style w=100% bg="#ffffff" zIndex=1000 gap="2" display="flex" justify="space-between" h='7.6'
    align="center" px="2.4">
    <div a-style display="flex" gap="2.4" w="auto" align="center">
        <span a-style display="flex" align="center">
            <img *ngIf="gs.router.url != '/home'" src="/assets/images/mesbro.svg" routerLink="/" a-style w="12.7"
                cursor="pointer">
        </span>
    </div>
    <!-- prefix_h="2" prefix_w="2" prefix="../assets/images/assets/mesbro_logo.svg" ../assets/images/assets/new-organization/Text search icon   ../assets/images/img search icon.svg -->
    <m-input [uppercases]=false *ngIf="gs.router.url != '/home' && !gs.router.url.includes('/idm/sign-in') && !gs.router.url.includes('/me/') && !gs.router.url.includes('/org/')" a-style prefix="../assets/images/assets/new-organization/Text search icon.svg" [capitalized]="false"
        [formControl]="search" (postfix_clicked)='onKeyUp($event)'
        (keydown.enter)="onKeyUp($event)" w="72" h="4.6"  minWidth="47.6" mb="0" outline=none  px=0 fs="1.3"
        shadow="hov:0 3px 6px 0 #f1f1f1" outline=none  (ngModelChange)="onChange($event)"
          placeHolder="Search here" display="900:none" position="relative"></m-input>

    <div a-style display="flex" align="center">
        <m-button
            *ngIf="!gs.router.url.includes('/idm/sign-in') && !users?.activeUser?.token || gs.router.url.includes('/idm/forgot-password')"
            [types]="gs.enums.buttons.button30" text="Sign In" color="#f35325" px=2 py=0.5 marginTop="0" a-style
            routerLink="/idm/sign-in"></m-button>

        <!-- <m-button [types]="gs.enums.buttons.button30" text="Home" color="#f35325" px=2 py=0.5 marginTop="0" a-style routerLink="/generic/home" [queryParams]="{application:'Product'}"></m-button> -->

        <!-- <img display="480:none" (click)="open_pop_over('language')" mr="0.5" a-style cursor="pointer" src="https://mesbro.in/assets/images/language-icon.svg" h="2.5" w="2.4" ml="1.6">
        <span display="480:none" (click)="open_pop_over('language')">
            <p display="480:none" mr="0.5" a-style fs="1.4" color="#727272" fw="400">EN</p>
        </span>
        <img a-style cursor="pointer" display="480:none" w="9px" h="1" (click)="open_pop_over('language')" src="https://mesbro.in/assets/images/down-arrow-black.svg" alt=""> -->
        <!-- <img a-style cursor="pointer" [src]="'https://new-api.mesbro.com/uploads/'+country.image" h="2.5" w="3.5" ml="1.6"> -->
        <!-- <img a-style cursor="pointer" [src]="'https://new-api.mesbro.com/uploads/'+ country.image" h="2.5" w="3.5" ml="1.6"> -->
        <img a-style cursor="pointer" [src]="'https://new-api.mesbro.com/uploads/'+ country.image" h="2.6" w="4"
            ml="1.6">
        <!-- <m-text [type]="gs.enums.texts.paragraph52" text="Clear" (click)="list.list=[]; list.count=0; $event.stopPropagation() "> </m-text> -->

        <img *ngIf="users?.activeUser?.token && list.count>=1 " (click)="open_pop_over('notifications')"
            (click)="list.list=[]; list.count=0; $event.stopPropagation() " a-style display="480:none" cursor="pointer"
            src="https://mesbro.in/assets/images/notification-bell.svg" h="2.2" w="2" ml="1.6">
        <img (click)="open_pop_over('applications')" a-style cursor="pointer"
            src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/all-apps.svg" h="2.6" w="2.6" ml="1.6">
        <!-- *ngIf="users?.activeUser?.token" -->
        <img *ngIf="users?.activeUser?.token" (click)="open_pop_over('profile')" a-style cursor="pointer"
            [src]="users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.profile_picture) : gs.enums.images.image293"
            h="4.6" w="4.6" ml="1.6" brRadius="50%" br="1px solid #E4E4E4" shadow="0 3px 6px 0 #f1f1f1">
    </div>
</div>
<div class="no_scroll_bar" a-style w="72.0" maxHeight="20.6" br="1px solid #e4e4e4" bg="#fff" position="absolute"
    top="7" left="58.6" overflowX="auto" *ngIf="searchDropdown">
    <div class="Edit" *ngFor="let detail of searchList.list" a-style display="flex" justify="space-between" p="1.6"
        align="center">
        <div a-style display="flex" gap="1.6">
            <img src="../assets/images/Icon_Bank/Text_Search_Grey.svg" alt="" a-style h="2" w="2">
            <p a-style fs="1.3" align="left"
                (click)="redirect_to_detail(detail);gs.history('history',detail._id,'business','products','add-product-to-history')">
                {{detail?.Basic_Details?.attributes[1]?.value}}</p>
        </div>
        <p a-style fs="1.3" color="#E4E4E4">Remove</p>
    </div>
</div>
<div a-style h=7.5></div>
<m-account-tray (close_modal)="visible.profile = false" *ngIf="visible.profile"></m-account-tray>
<m-language-tray *ngIf="visible.language"></m-language-tray>
<m-application-tray (close_modal)="visible.applications = false" *ngIf="visible.applications"></m-application-tray>
<!-- <m-call #call_modal></m-call> -->
<m-notifications *ngIf="visible.notifications"></m-notifications>
<ng-template #tags let-passedData="passedData">
    <div *ngIf="suggested_users.length" class="no_scroll_bar" py=2 overflowX=scroll a-style w="100%" display=flex px=1.5
        flexGap=2>
        <div cursor=pointer (click)="search?.get('search')?.patchValue(search); suggested_users=[]"
            *ngFor="let search of suggested_users" a-style px="1" py="0.5" br="1px solid #e2e2e2" brRadius="0.5"
            shadow="0 3px 6px 0 #b2b2b2">
            <m-text mb=0 [type]="gs.enums.texts.paragraph9" [text]='search'></m-text>
        </div>
    </div>
</ng-template>

<!-- <m-input [capitalize]="false" *ngIf="gs.router.url != '/home' && !gs.router.url.includes('/idm/sign-in')" (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)" src="/assets/images/logo.svg" prefix="../assets/images/assets/mesbro_logo.svg" fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" [(ngModel)]="global_search" (ngModelChange)="global_search" a-style placeHolder="Search Mesbro or Type a URL " h="4" minWidth="71.9" mb="0" outline=none px=0 a-style display="900:none"
            postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg"></m-input> -->