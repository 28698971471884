<div *ngIf="!gs.router.url.includes('/org/swapnil')" a-style py="1.3" px="2.4"  h=7.1 bg=#F8F8F8 display=flex align="center" justify="center" shadow="0 3px 6px 0 #17171729">
    <div a-style display="flex" gap="3.5" align="center">
      <div a-style display=flex justify=center gap="1.6" align=center
        (click)="gs.router.navigate(['org','organization-form'],{queryParams:{ section:'Add Organization',type:'Basic Details'}})">
        <!-- <img cursor="pointer" a-style h=2.6 w=2.6 src="../assets/images/B2B/Add Organization-blackandwhite.svg" *ngIf="!gs.router.url.includes('/org/organization-form')"> -->
        <img cursor="pointer" a-style h=2.6 w=2.6 src="../assets/images/Icon_Bank/Add_Coloured.svg" >
        <p *ngIf="!gs.router.url.includes('/org/organization-form?section=Add%20Organization&type=Basic%20Details')" cursor="pointer" a-style m=0 fs=1.4 color=#333333>Add Organization</p>
        <p  *ngIf="gs.router.url.includes('/org/organization-form?section=Add%20Organization&type=Basic%20Details')" cursor="pointer" a-style m=0 fs=1.4 color=#2479AB>Add Organization</p>
      </div>
      <div a-style display=flex justify=center gap="1.6" align=center routerLink="/org/organization-home" >
        <img a-style h=2.6 w=2.6 cursor="pointer" src="../assets/images/Icon_Bank/Home_Coloured.svg" > 
        <!-- *ngIf="gs.router.url.includes('/org/organization-home') || gs.router.url.includes('/org/organization-dashboard')" -->
        <!-- <img a-style h=2.6 w=2.6 cursor="pointer" src="../assets/images/B2B/Home-blackandwhite.svg" *ngIf="!gs.router.url.includes('/org/organization-home')&& !gs.router.url.includes('/org/organization-dashboard')"> -->
        <p *ngIf="!gs.router.url.includes('/org/organization-home')" cursor="pointer" a-style m=0 fs=1.4 color=#333333>My Organization</p>
        <p *ngIf="gs.router.url.includes('/org/organization-home')" cursor="pointer" a-style m=0 fs=1.4 color=#2479AB>My Organization</p>
      </div>
      <div a-style display=flex justify=center gap="1.6" align=center routerLink="/org/shared-organization" >
        <img a-style h=2.6 w=2.6 cursor="pointer" src="../assets/images/Icon_Bank/Home_Coloured.svg" > 
        <!-- *ngIf="gs.router.url.includes('/org/organization-home') || gs.router.url.includes('/org/organization-dashboard')" -->
        <!-- <img a-style h=2.6 w=2.6 cursor="pointer" src="../assets/images/B2B/Home-blackandwhite.svg" *ngIf="!gs.router.url.includes('/org/organization-home')&& !gs.router.url.includes('/org/organization-dashboard')"> -->
        <p *ngIf="!gs.router.url.includes('/org/shared-organization')" cursor="pointer" a-style m=0 fs=1.4 color=#333333>Shared With Me</p>
        <p *ngIf="gs.router.url.includes('/org/shared-organization')" cursor="pointer" a-style m=0 fs=1.4 color=#2479AB>Shared With Me</p>
      </div>
      <div a-style display=flex justify=center gap="1.6" align=center (click)="gs.router.navigate(['org/organization-block'])" >
        <!-- <img cursor="pointer" a-style h=2.6 w=2.6 src="../assets/images/B2B/Block Organization-blackand white.svg" *ngIf="!gs.router.url.includes('/org/organization-block')"> -->
        <img cursor="pointer" a-style h=2.6 w=2.6 src="../assets/images/Icon_Bank/Blocked_Organization_Coloured.svg" >
        <p *ngIf="!gs.router.url.includes('/org/organization-block')"  cursor="pointer" a-style m=0 fs=1.4 color=#333333>Blocked Organization</p>
        <p *ngIf="gs.router.url.includes('/org/organization-block')"  cursor="pointer" a-style m=0 fs=1.4 color=#2479AB>Blocked Organization</p>
      </div>
    </div>
</div>


