<div a-style minWidth="60" bg="#ffffff" p="1.6">
    <m-text [type]="gs.enums.texts.paragraph21" py="1" text="akshay {{chat_list.length}} "></m-text>
    <hr a-style color="#e4e4e4">
    <div a-style w="100%" h="43.1"    overflowY="auto" p="1.6" mb="1.5">
        <div *ngFor="let chat of chat_list; let i=index" a-style maxWidth="100%" display="flex" [style.justifyContent]="chat.meta?.roles?.owner[gs.user?.users?.activeUser?._id || '']? 'flex-end' : 'flex-start' ">
            <div a-style>
                <ng-container [ngTemplateOutlet]="chat_text" [ngTemplateOutletContext]="{chat:chat}"></ng-container>
            </div>
        </div>
    </div>
    <div a-style pt="1" mt="2.5">
        <form [formGroup]="form">
            <m-form formGroupName="data" [brNone]=false>
                <m-input formControlName="text" [columns]="1" (keyup.enter)="submit();form?.reset()" placeHolder="Your Quesions Here" mb="1" px="0" [type]="gs.enums.inputs.text_area"></m-input>
                <div a-style display="flex" justify="flex-end" mt="1.5" w="100%">
                    <m-input [type]="gs.enums.inputs.file" formControlName="file" [onlyInput]="true" [columns]="false" [aspect_ratio]="1">
                        <m-button [types]="gs.enums.buttons.button73" text="Attachment" br="none" a-style (click)="type='file'"></m-button>
                    </m-input>
                    <m-button [types]="gs.enums.buttons.button32" (click)="submit();form?.reset()" text="Send" br="none" a-style ml="1.5"></m-button>
                </div>
            </m-form>
        </form>
    </div>
</div>
<!-- {{form?.value.data?.file}} -->
<ng-template #chat_text let-chat=chat>
    <div a-style p="1" w="27" mb=1 brRadius="0.5" br="1px solid #e4e4e4">
        <div a-style display="flex" justify="space-between">
            <m-text [type]="gs.enums.texts.paragraph20" textWrap="nowrap" text="Jun 28, 2021 17:43"></m-text>
            <m-text [type]="gs.enums.texts.paragraph80" textWrap="nowrap" text="{{chat.meta.activity.updated_by.first_name | titlecase}} {{chat.meta.activity.updated_by.last_name | titlecase}}"></m-text>
        </div>
        <div a-style display="flex" justify="flex-end" mt="1">
            <m-text [type]="gs.enums.texts.paragraph20" textWrap="nowrap" text={{chat.data.text}}></m-text>
        </div>
        <div *ngIf="chat.data.file" a-style h="10" mt="1" br="1px solid #e4e4e4" bg="#ffffff" brRadius="0.5">
            <m-image bgSize="cover" a-style [src]="'https://new-api.mesbro.com/uploads/'+chat.data.file" brRadius="0.5" h=100% w="100%"></m-image>
        </div>
    </div>
</ng-template>