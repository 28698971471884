<div a-style p=2.4 mt=4.4>
    <div a-style display=flex align=center mb=2.4>
        <m-text [type]="gs.enums.texts.heading2" text="One Request Multiple Leads"></m-text>
    </div>
    <table style width="100%">
        <tr style background-color="#b2b2b2" height="51px">
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Sr.No</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Product Name</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Quantity</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Unit</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Sourcing Purpose</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Requirment</th>
            <th style='background-color: #f5f5f5; font-size:13px;padding-left:15px'>Delete</th>
        </tr>

        <tr style align="center" height="51px" *ngFor="let subscription of list.list;let i=index;">
            <td style='font-size:13px;padding-left:30px' align="left">{{i+1}}</td>
            <td style='font-size:13px' align="left">{{subscription?.data?.productName}}</td>
            <td style='font-size:13px' align="left">{{subscription?.data?.qantity}}</td>
            <td style='font-size:13px' align="left">{{subscription?.data?.unit}}</td>
            <td style='font-size:13px' align="left">{{subscription?.data?.sourcingPurpose}}</td>
            <td style='font-size:13px' align="left">{{subscription?.data?.requirement}}</td>
            <td style='font-size:13px' align="left">
                <!-- <m-image [src]="gs.enums.images.image68" h=1.2 w=1.2 ></m-image> -->
                <m-image src="https://mesbro.in/assets/images/delete-red.svg" (click)="delete(subscription._id,i)" h="1.5" w="1.5"></m-image>
            </td>
        </tr>
    </table>
</div>