<div *ngIf="false">
    <m-input label="Title" placeHolder="Title"></m-input>
<m-input label="Description" placeHolder="Description"></m-input>
</div>

<!--  same code varti 
    <p a-style color="#333333" fs="1.4" pb="1">Title</p>
        <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{ passedData: { text: 'Title' } }"></ng-container>
    <p a-style color="#333333" fs="1.4" pb="1" pt="1.6">Decsription</p>
    <div a-style px="1" mb="1.6" py="1" br="1px solid lightgray" brRadius="0.4">
        <p a-style pb="5.5">Description</p>
    </div>

<ng-template #input let-passedData="passedData">
    <div a-style br="1px solid #e2e2e2" p="1" display="flex" justify="space-between">
        <p a-style fs='1.3'>{{passedData.text}}</p>
        <p a-style fs='1.3' color="#006BFF">{{passedData.text2}}</p>
    </div>
</ng-template> -->

<!-- add new template  with  -->
<div a-style bg="#ffffff" brRadius="0.6" p="2.4">
<div a-style mb="2.4" align="left"> 
    <p a-style fs="1.6" color="#333333">Add new template</p>
</div>
<div a-style mb="1.3"  >
<m-input a-style label="Title" pl="0" pr="0" placeHolder="Title"></m-input>
</div>
<div a-style mb="1" align="left">
    <p a-style color="#333333" fs="1.3">Description</p>
</div>
  <div a-style br="1px solid #e4e4e4" brRadius="0.6" p="1" mb="2">
    <textarea formControlName="address" pl="0" pr="0"  br="none" a-style h=15 w=100% label="Description"  placeholder="lorem50" ></textarea>
  </div>
  <div a-style display="flex" justify="flex-end">
    <!-- <m-button a-style [types]="gs.enums.buttons.button93" cursor="pointer" (click)="modal.closeModal()" text="Cancel" ></m-button> -->
    <m-button a-style [types]="gs.enums.buttons.button93" cursor="pointer" (click)="Save()" text="Save"></m-button>
</div>
</div>