<ng-container *ngIf="type=='card_3'" [ngTemplateOutlet]="card_3 "></ng-container>

<ng-template #card_3>
    <div display="flex" a-style position="relative">
        <div a-style w="30" h=29.6 br="1px solid #E4E4E4" brRadius="0.6" m=1.2 shadow=" hov:0 3px 6px 0 #f1f1f1">
            <div a-style w=100% h=15.7 brTopLeftRadius=0.6 brTopRightRadius=0.6>
                <m-image [src]='gs.enums.images.image61' w=100% h="100%" brTopLeftRadius=0.6 brTopRightRadius=0.6>
                </m-image>
            </div>
            <div a-style justify="space-between" p=1>
                <m-text [type]="gs.enums.texts.paragraph20" mb=0.5
                    style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                    text=' The 7 Main Steps You Need to Take to Get Your Startup Off the Ground'></m-text>
                <div a-style display="flex" justify="space-between">
                    <m-text [type]="gs.enums.texts.paragraph23"
                        style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                        text=' Professional Blogs'></m-text>
                    <m-text [type]="gs.enums.texts.paragraph23"
                        style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                        text=' 166 views'></m-text>
                </div>
            </div>
        </div>
    </div>
    <!-- <div a-style bg="#333333" w="5" h=2 p=0.5 brRadius="0.6" display="flex" align="center" position="absolute"
        top="1.5" right=1.5>
        <m-image [src]='gs.enums.images.image331' a-style w=1.5 mr=1></m-image>
        <m-image src="../assets/images/assets/Group 74964.svg" w=1.7 mr=0.8></m-image>
        <m-image [src]='gs.enums.images.image332' a-style w=1.5></m-image>
    </div> -->

</ng-template>

<div *ngIf="type=='card_1'" (mouseover)="icon=true" (mouseleave)="icon=false" a-style cursor=pointer bg="#ffffff"
    brRadius=0.6 minWidth=30.5 maxWidth=30.5 fs="1.3" a-style shadow=" hov:0 3px 6px 0 #f1f1f1"
    br="1px solid rgba(228, 228, 228, 0.47)" position="relative">
    <div *ngIf="icon" a-style bg="#333333" h=2 p=0.5 brRadius="0.6" display="flex" gap=0.6 align="center"
        position="absolute" top=0.5 right=0.5>
        <m-image src="../assets/images/assets/Group 74964.svg" w=1.7 (click)="gs.action('save',detail)"></m-image>
        <m-image [src]='gs.enums.images.image332' a-style w=1.5 (click)="gs.action('favourite',detail)"></m-image>
        <m-image src="../assets/images/assets/compare_white_bg.svg" w=1.5 (click)="add_to_compare()"></m-image>
    </div>
    <m-image [src]="'https://new-api.mesbro.com/uploads/'+(detail?.profile_picture || detail?.gallery[0]) "
        brTopLeftRadius=0.6 brTopRightRadius=0.6 h=16.2 w=30.3 mb=0.8 (click)="redirect_to_detail(detail)"></m-image>
    <div a-style px=1.2>
        <m-text [type]="gs.enums.texts.paragraph38" text="{{detail?.title}}" mt=0.5 [ellipsis]=true lineClamp=1
            (click)="redirect_to_detail(detail)"></m-text>
    </div>
    <div a-style display=flex justify="space-between" p=1.2>
        <m-text [type]="gs.enums.texts.paragraph23" a-style
            text="{{detail.meta.activity.updated_by.first_name | case:'capital'}} {{detail.meta.activity.updated_by.last_name | case : 'capital'}}"
            maxWidth=21.5 [ellipsis]=true lineClamp=1 (click)="redirect_to_detail(detail)"></m-text>
        <m-text [type]="gs.enums.texts.paragraph23" a-style
            text="{{detail?.meta?.activity?.count?.view? detail?.meta?.activity?.count?.view : 0}} views"
            (click)="redirect_to_detail(detail)"></m-text>
    </div>
</div>

<div *ngIf="type=='article'">
    <div a-style w="30" h=29.6 br="1px solid #E4E4E4" brRadius="0.6" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <div a-style w=100% h=15.7 brTopLeftRadius=0.6 brTopRightRadius=0.6>
            <m-image [src]="'https://new-api.mesbro.com/uploads/'+(detail?.profile_picture )" h=100% w=100% brTopLeftRadius=0.6 brTopRightRadius=0.6></m-image>
        </div>
        <div a-style p=1.2>
            <m-text [type]="gs.enums.texts.paragraph9"
                style="overflow: hidden;text-overflow: ellipsis;max-width: 277px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"
                text='{{detail?.title}}'>
            </m-text>
            <m-text [type]="gs.enums.texts.paragraph124"
                style="overflow: hidden;text-overflow: ellipsis;max-width: 277px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"
                text='{{detail?.description}}'>
            </m-text>
            <div a-style mt=0.8 display=flex justify=space-between align=center>
                <div a-style display=flex align=center>
                    <m-image [src]="gs.enums.images.image61" h=2.9 w=3 mr=1.3 brRadius=50%></m-image>
                    <m-text [type]="gs.enums.texts.paragraph129" fs=1.3 color="#333333"
                        style="overflow: hidden;text-overflow: ellipsis;max-width: 170px;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                        text='{{detail?.meta?.activity?.updated_by?.first_name}} {{detail?.meta?.activity?.updated_by?.last_name}} '></m-text>
                </div>
                <m-text [type]="gs.enums.texts.paragraph129" fs=1.3 color="#333333"
                    style="overflow: hidden;text-overflow: ellipsis;max-width: 56px;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                    text='500k+ Views'></m-text>
            </div>
        </div>
    </div>
</div>
<div *ngIf="type=='video'">
    <div a-style w="30" h=29.6 br="1px solid #E4E4E4" brRadius="0.6" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <div a-style w=100% h=15.7 brTopLeftRadius=0.6 brTopRightRadius=0.6>
            <m-image [src]="gs.enums.images.image61" h=100% w=100% brTopLeftRadius=0.6 brTopRightRadius=0.6></m-image>
        </div>
        <div a-style p=1.2>
            <m-text [type]="gs.enums.texts.paragraph9"
                style="overflow: hidden;text-overflow: ellipsis;max-width: 277px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"
                text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet tempus. Aliquam vitae felis elit. 
                Duis nec placerat justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet temp'>
            </m-text>
            <m-text [type]="gs.enums.texts.paragraph124"
                style="overflow: hidden;text-overflow: ellipsis;max-width: 277px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"
                text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet tempus. Aliquam vitae felis elit. 
                    Duis nec placerat justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor ut dui sit amet temp'>
            </m-text>
            <div a-style mt=0.8 display=flex justify=space-between align=center>
                <div a-style display=flex align=center>
                    <m-image [src]="gs.enums.images.image61" h=2.9 w=3 mr=1.3 brRadius=50%></m-image>
                    <m-text [type]="gs.enums.texts.paragraph129" fs=1.3 color="#333333"
                        style="overflow: hidden;text-overflow: ellipsis;max-width: 170px;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                        text='Bhaskar Ajgaonkar'></m-text>
                </div>
                <m-text [type]="gs.enums.texts.paragraph129" fs=1.3 color="#333333"
                    style="overflow: hidden;text-overflow: ellipsis;max-width: 56px;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"
                    text='500k+ Views'></m-text>
            </div>
        </div>
    </div>
</div>