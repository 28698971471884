<m-center *ngIf="false">
  <div a-style display="flex" alignItems="center" justify="center" h=100% px="3">
    <div a-style w="56.93">
      <div a-style textCenter="center">
        <m-text [type]="gs.enums.texts.heading1" mb="1.3" text="CHANGE PASSWORD"></m-text>
        <m-text [type]="gs.enums.texts.paragraph1"
          text="Enter your new password. Do remember it and change it frequently for a better security"></m-text>
      </div>
      <div a-style display="flex" justify="center " mb="1.8" mt="2.4">
        <div a-style w="38">
          <form [formGroup]="form">
            <m-form [brNone]=false>
              <m-input *ngIf="params?.type!='forgot-password'"
                (postfix_clicked)="visible.old_password = !visible.old_password "
                [postfix]="images[visible.old_password? 'true' : 'false']"
                [type]="visible.old_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]="1" px="0"
                formControlName="old_password" label="Old Password"></m-input>
              <m-input mt="1.8" (postfix_clicked)="visible.new_password = !visible.new_password "
                [postfix]="images[visible.new_password? 'true' : 'false']" [capitalize]=false
                [type]="visible.new_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]="1" px="0"
                formControlName="new_password" label="New Password"></m-input>
              <m-input mt="1.8" (postfix_clicked)="visible.confirm_new_password = !visible.confirm_new_password "
                [postfix]="images[visible.confirm_new_password? 'true' : 'false']" [capitalize]=false
                [type]="visible.confirm_new_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]="1"
                px="0" formControlName="confirm_new_password" label="Confirm New Password" [error]=error></m-input>
              <m-button [disable]="form?.invalid" button_type=submit [types]="gs.enums.buttons.button75"
                (click)="submit()" mt="2.8" w="35" text="Change My Password "></m-button>
            </m-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</m-center>
<div a-style display="flex" justify="space-evenly" w=100vw align="center" h="calc(100vh - 7.6rem)">
  <div>
    <m-image a-style src="../assets/images/images/sign_animation.gif" w="500px" h="500px">
    </m-image>
    <p a-style fs=1.6 maxWidth=75>With a single account, you can search, write emails, save and share your files,
      find stuff you want, get directions and use other services on all your devices and platforms</p>
  </div>
  <div a-style p=2.4 br="0.5px solid #e4e4e4" brRadius="0.6" align=left>
    <img src="../assets/images/images/Mesbro_log.png" routerLink="/home" cursor="pointer">
    <p a-style fs=2 mt=2>Update your password</p>
    <p a-style fs=1.3 mt=2>Enter the password you want to set for your account</p>
    <p a-style fs=1.6 mt=2 color=#81BC06>{{details?.contact_details?.email_address_primary}}</p>
    <form [formGroup]="form">
      <m-form [brNone]=false flexWrap='none' [paddingNone]=false>
        <div a-style display="flex" flexWrap=wrap gap=2.4 align="left">
          <!-- <m-input *ngIf=
            (postfix_clicked)="visible.old_password = !visible.old_password "
            [postfix]="images[visible.old_password? 'true' : 'false']"
            [type]="visible.old_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]=".40" px="0"
            formControlName="old_password" label="Old Password"></m-input> -->
          <m-input mt="1.8" (postfix_clicked)="visible.new_password = !visible.new_password " minWidth="unset"
            [postfix]="images[visible.new_password? 'true' : 'false']" [capitalize]=false [uppercases]="false"
            [type]="visible.new_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]="0.40" px="0"
            formControlName="new_password" label="Password" maxWidth="21"></m-input>
          <m-input mt="1.8" (postfix_clicked)="visible.confirm_new_password = !visible.confirm_new_password " [uppercases]="false"
            minWidth="unset" [postfix]="images[visible.confirm_new_password? 'true' : 'false']" [capitalize]=false
            [type]="visible.confirm_new_password? gs.enums.inputs.text : gs.enums.inputs.password" [columns]="0.40"
            px="0" formControlName="confirm_new_password" label="Confirm Password" [error]=error maxWidth="21">
          </m-input>
          <!-- <m-button [disable]="form?.invalid" button_type=submit [types]="gs.enums.buttons.button75"
              (click)="submit()" mt="2.8" w="35" text="Change My Password "></m-button> -->
        </div>
      </m-form>
      <p a-style fs=1.1>You can use any language letters, numbers & symbols with min 8 characters.</p>
    

    </form>
    <div a-style display="flex" justify="flex-end" mt="2.4">
      <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5"
        (click)="submit();this.gs.toast('Success', 'Password updated successfully', 'success', 2)">Yes, I'm in</button>
    </div>
  </div>
</div>
<!-- sear1.openModal(); -->

<!-- <m-modal #sear1>
  <div a-style h="21.2" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center" align="center">
    <div a-style display="flex" flexDirection="column" align="center" gap="2.4">
      <img src="../assets/images/ME/tick (1).svg" alt="" a-style w="10" h="10">
      <p a-style fs="2">Password Updated Successfully</p>
    </div>
  </div>
</m-modal> -->
<m-success-modal #sear1 title="Password Updated Successfully" >
</m-success-modal>  