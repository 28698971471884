<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Text" label="Text" maxWidth="21"></m-new-input> -->

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Text" label="Text" maxWidth="32.7"></m-new-input> -->

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Text" label="Text(optional)" maxWidth="44.4"></m-new-input> -->
    
<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Text" label="Text(optional)" maxWidth="44.4"></m-new-input> -->
    


 With Postfix Text 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Username" label="Username" maxWidth="44.4" postfix_text="@mesbro.com"></m-new-input> -->

 With Postfix Image 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Username" label="Username" maxWidth="44.4" postfix="../assets/images/assets/new-organization/mesbro_logo.svg"></m-new-input> -->

 With Prefix Image 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Username" label="Username" maxWidth="44.4" prefix="../assets/images/assets/new-organization/mesbro_logo.svg" ></m-new-input> -->

With Prefix and Postfix Image 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Username" label="Username" maxWidth="44.4" prefix="../assets/images/assets/new-organization/mesbro_logo.svg" postfix="../assets/images/assets/new-organization/mesbro_logo.svg"></m-new-input>  -->

With Prefix Image and Postfix Text 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Username" label="Username" maxWidth="44.4" prefix="../assets/images/assets/new-organization/mesbro_logo.svg" postfix_text="@mesbro.com"></m-new-input>  -->

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Enter 6 digit code" label="Enter Verification Code" maxWidth="15" prefix_text="M :  "></m-new-input>  -->


For Password 

<!-- <m-new-input [type]="gs.enums.inputs.password" placeHolder="Password" label="Password" maxWidth="15" postfix="../assets/images/hideeye.svg"></m-new-input>  -->


Input with Prefix Div 

<!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Mobile Number" label="Mobile Number" maxWidth="35.1" prefix_text="+91  " prefix_div="../assets/images/Browse.svg"></m-new-input>  -->

 
Textarea

<!-- <m-new-input [type]="gs.enums.inputs.newText_area" label="Amruta"></m-new-input>  -->

If more rows require (min or default 5) 

<!-- 2.<m-new-input [type]="gs.enums.inputs.newText_area" label="Amruta" rows="8"> </m-new-input>  -->

For width same as input  


Radio Button 

This isn't relevent

Check Box 

<!-- <m-new-input [type]="gs.enums.inputs.newCheckbox"  label="Mobile Number" ></m-new-input>  -->


Media 

<!-- <m-new-input [type]="gs.enums.inputs.media" w="20" h="20" text="Add Profile Photo"></m-new-input> 

<m-new-input [type]="gs.enums.inputs.media" w="44.4" h="17.1" text="Add Album"></m-new-input> 

<m-new-input [type]="gs.enums.inputs.media" w="21" h="24.2" text="Add Award"></m-new-input>  -->


Cover Image 

<!-- <m-new-input [type]="gs.enums.inputs.media" w="44.6" h="20" text="Add Cover Photo" py="0" flexDirection="row" src="assets/images/assets/product/coverphoto.svg"></m-new-input>  -->


File(PDF/Excel/Document ) 

<!-- <m-new-input [type]="gs.enums.inputs.file" text="Amruta"></m-new-input>  -->


Tags 

<!-- <m-new-input [type]="gs.enums.inputs.tags" label="Amruta" maxWidth="32"></m-new-input> -->


DropDown 

<!-- <m-new-input [type]="gs.enums.inputs.dropdown" maxWidth="7.7"></m-new-input>  -->

<!-- <m-new-input [type]="gs.enums.inputs.dropdown" maxWidth="32"></m-new-input>  -->