import { Pipe, PipeTransform } from '@angular/core';
import { capitalCase } from 'capital-case';
import { snakeCase } from 'snake-case';

@Pipe({
  name: 'case'
})
export class CasePipe implements PipeTransform {

  transform(string_value: string | undefined, case_name: string) {
    if (case_name == 'snake') {
      return snakeCase(string_value || '');
    }
    if (case_name == 'capital') {
      return capitalCase(string_value || '');
    }
    return null;
  }

}
