import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { response } from 'express';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';
import { ProductServiceService } from '../../service/product-service.service';
import RecordRTC from 'recordrtc';

@Component({
  selector: 'm-product-header',
  templateUrl: './product-header.component.html',
  styles: [
  ]
})
export class ProductHeaderComponent implements OnInit {
  // Users= Iuser | undefined;

  Users: IUser | undefined;
  form: FormGroup | undefined;

  params: any = {
    type: '',
    step: '',
  }
  users: any;
  title = {
    home_title: 'Home',
    All_title: 'All',
    MyOrganization_title: 'My Organization',
    MyProducts_title: 'My Products',
    Saved_title: "Saved",
    Favourite_title: 'Favourite',
    compare_title: "Compare",
    History_title: "History"
  }
  // Users: import("c:/Users/User/Desktop/Projects/mesbro-new-v8/src/mesbro/applications/idm/interfaces/i-user").IUser | undefined;

  // headerImage = [
  //   {src:this.gs.enums.images.image311,width:2.5,height:2.5},
  //   {src:this.gs.enums.images.image312,width:2.5,height:2.5},
  //   {src:this.gs.enums.images.image313,width:2.5,height:2.5},
  //   {src:this.gs.enums.images.image314,width:2.5,height:2.5},
  //   ]
  constructor(public gs: GlobalService, public ar: ActivatedRoute, private router: Router, public ps: ProductServiceService) { }
  onKeyUp(event: any) {
    this.gs.router.navigate(['/product', 'gallary'], { queryParams: { type: 'All', query: this.search } });
  }
  compare_object: any | undefined
  compare_count: any | undefined
  label1: boolean = false;
  org_list: {
    list: [],
    count: 0
  }
  dropdown: boolean = false
  isVisiblefav: boolean = false
  isVisibleSave: boolean = false
  isVisiblecompare: boolean = false
  closeIcons: boolean = true
  showSend: boolean = true

  favourite_list: any = {
    list: [],
    count: 0
  }
  save_list: any = {
    list: [],
    count: 0
  }
  productList: {
    list: [],
    count: 0
  }
  search = new FormControl('');
  ngOnInit(): void {
    this.get_organization()
    this.gs.user.users$.subscribe((user: IUserData) => {
      this.users = user;
    })
  }
  loginRequired: any

  fav_not_found() {
    if (this.favourite_list.count == 0 && this.users?.activeUser?._id) {
      this.isVisiblefav = true
    }
    else if (!this.users?.activeUser?._id) {
      console.log(this.users?.activeUser?._id);

      this.gs.router.navigate(['idm', 'sign-in']);
    }
    else {
      this.gs.router.navigate(['product', 'gallary'], { queryParams: { type: 'favourite products' } });
    }
  }

  openInput() {
    this.closeIcons = false
    this.showSend = true
  }

  save_not_found() {
    if (this.save_list.count == 0 && this.users?.activeUser?._id) {
      this.isVisibleSave = true
    } else if (!this.users?.activeUser?._id) {
      this.gs.router.navigate(['idm', 'sign-in']);
    }
    else {
      this.gs.router.navigate(['product', 'gallary'], { queryParams: { type: 'save products' } });
    }
  }

  compare_not_found() {
    this.compare_object = this.gs.localStorageGet('compare-items');
    this.compare_count = (this.gs.localStorageGet('compare-items'));
    this.compare_count = Object.keys(this.compare_object)?.length
    if (this.compare_count == 0) {
      this.isVisiblecompare = true
      this.gs.toast('Information', 'No product to compare', 'Info', 2)
    }
    else {
      this.gs.router.navigate(['product', 'compare'], { queryParams: { type: 'compare-products' } });
    }
  }

  async favourite() {
    let body = {
      ["meta.roles.owner.user." + this.gs.user?.users?.activeUser?._id]: true,
      activity: "favourite",
      'parents.data.application': "Product",
      'parents.data.component': "Product",
    }

    this.favourite_list = await this.gs.hps.post('generic', 'activity', 'list', body)
  }

  async save() {
    let body = {
      ["meta.roles.owner.user." + this.gs.user.users?.activeUser?._id]: true,
      activity: "save",
      'parents.data.application': "Product",
      'parents.data.component': "Product",
    }
    this.save_list = await this.gs.hps.post('generic', 'activity', 'list', body)
  }


  isVisible: boolean = false;

  openModal() {
    if (this.compare_count == 0) {
      this.isVisible = true;
    }
    else {
      this.label1 = true
    }
  }

  visible: any = {
    location: false,
    camera: false,
    recording: false,
    forward: false,
    label: false
  };

  open_pop_over: any = (name: 'recording') => {
    setTimeout(() => {
      if (this.visible[name]) {
        this.visible[name] = false
      } else {
        this.visible[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
    console.log('open popup')
  }
  close_other_pop_overs(name: 'recording') {
    Object.keys(this.visible).forEach((key: any) => {
      if (key != name) {
        this.visible[key] = false;
        console.log('xdxcfyguigh')
      }
    })
  }
  recording = false;
  error: any
  record: any;
  url = 'https://mesbro.com';
  upload: any
  mic = false;

  errorCallback(error: any) {
    this.error = 'Can not play audio in your browser';
  }

  initiateRecording() {
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream: any) {
    var options = {
      mimeType: "audio/wav",
      numberOfAudioChannels: 1
    };
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
  }

  stopRecording() {
    this.recording = false;
    this.record?.stop(this.processRecording.bind(this));
  }

  processRecording(blob: any) {
    this.url = URL.createObjectURL(blob);
    let reader: any = new FileReader();
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onloadend = async () => {
        let file = new File([blob], "voice.mp3")
        let fd = new FormData();
        fd.append('name', 'avatar');
        fd.append('avatar', file);
        // let response = await this.http.post("https://new-api.mesbro.com/upload", fd, { headers: { token: '613ef48e5d00dcd92344d0b5' } }).toPromise()
        this.upload = await this.gs.hps.post('', '', 'upload', fd)
        console.log(this.upload);
        let au = document.createElement('audio');
        console.log(au);
        au.src = 'https://new-api.mesbro.com/uploads/' + this.upload.url
        au.addEventListener('loadedmetadata', function () {
          let duration = au.duration;
          console.log(duration)
          console.log("The duration of the song is of: " + duration + " seconds");
        }, false)
      }
    })
  }

  playAudio(file: any) {
    let path = 'https://new-api.mesbro.com/uploads/' + file?.url
    console.log(path)
    let audio = new Audio();
    audio.src = 'https://new-api.mesbro.com/uploads/' + file?.url
    audio.load();
    audio.play();
    console.log(audio)
    this.mic = true;
  }

  closeModal() {
    this.isVisible = false;
  }
  closefavmodal() {
    this.isVisiblefav = false;
  }
  closesavemodal() {
    this.isVisibleSave = false;
  }
  closecomparemodal() {
    this.isVisiblecompare = false;
  }
  openCompareModal() {
    if (this.compare_count >= 0) {
      this.label1 = true
    }
    else (this.compare_count == 0); {
      this.label1 = false
    }
  }

  message: any = 'Please Create at Least One Organization'

  org_not_found() {
    if (this.org_list?.count < 0 && !this.users?.activeUser?._id) {
      this.gs.router.navigate(['idm', 'sign-in']);
    }
    else {
      this.gs.router.navigate(['product', 'list'], { queryParams: { type: 'my-organization' } });
    }
  }

  product_not_found() {
    console.log(this.org_list?.count);

    if (this.org_list?.count > 0) {
      this.gs.router.navigate(['product', 'list'], { queryParams: { type: 'my-products' } });
    }
    else {
      this.gs.router.navigate(['gdashboard', 'group', 'dashboardDetail'], { queryParams: { type: 'organization' } });
    }
  }

  async get_organization() {
    let id: any
    let body = { ["meta.roles.owner.user." + this.gs.user.users.activeUser?._id]: true }
    this.org_list = await this.gs.hps.post('idm', 'group', 'list', body)
  }
  async getProductList() {
    let body = { application: 'Product', component: 'Product', query: this.search.value }
    this.productList = await this.gs.hps.post('generic', 'data', 'list', body)
    this.dropdown = true
  }
  redirectToFav(type: any) {
    if (this.users?.activeUser?._id && type == 'favourite') {
      this.gs.router.navigate(['/product/verfied-suppliers'], { queryParams: { type: 'favourite' } })
    } else {
      this.gs.toast('Warning', 'Please Login', 'warning')
    }
  }
  redirectToSave(type: any) {
    if (this.users?.activeUser?._id && type == 'save') {
      this.gs.router.navigate(['/product/verfied-suppliers'], { queryParams: { type: 'mycollection' } })
    } else {
      this.gs.toast('Warning', 'Please Login', 'warning')
    }
  }
  redirectToHistory(type: any) {
    if (this.users?.activeUser?._id && type == 'history') {
      this.gs.router.navigate(['product/history'])
    } else {
      this.gs.toast('Warning', 'Please Login', 'warning')
    }
  }
}

