import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-change-mobile',
  templateUrl: './change-mobile.component.html',
  styles: [
  ]
})
export class ChangeMobileComponent implements OnInit {
  response:any
  countryList = [
    { country: 'India', countryCode: '+91' },
    { country: 'Australia', countryCode: '+61' },
    { country: 'Bhutan', countryCode: '+975' },
    { country: 'Brazil', countryCode: '+55' },
    { country: 'China', countryCode: '+86' },
    { country: 'Colombia', countryCode: '+57' },
    { country: 'Cuba', countryCode: '+53' },
    { country: 'Denmark', countryCode: '+45' },
    { country: 'Egypt', countryCode: '+20' },
    { country: 'Indonesia', countryCode: '+62' },
    { country: 'Afghanistan', countryCode: '+93' },
    { country: 'Iraq', countryCode: '+964' },
    { country: 'Israel', countryCode: '+972' },
    { country: 'Japan', countryCode: '+81' },
    { country: 'Kenya', countryCode: '+254' },
    { country: 'Maldives', countryCode: '+960' },
    { country: 'Mexico', countryCode: '+52' },
    { country: 'Nepal', countryCode: '+977' },
    { country: 'Oman', countryCode: '+968' },
    { country: 'Pakistan', countryCode: '+92' },
    { country: 'Sweden', countryCode: '+46' },
  ]
  otp_id: string;
  type='change-mobile'
  constructor(public fb: FormBuilder, public gs: GlobalService, public ar: ActivatedRoute) { }

  form: FormGroup | undefined;

  ngOnInit(): void {
    this.initalizeForm()
  }
  initalizeForm() {
    this.form = this.fb.group({
      country_calling_code: ['+91'],
      mobile_number_primary: [null, [this.gs.form_validations.mobile_number, this.gs.form_validations.required]],
    })
  }

  async changeMobileNumber() {
    let body = this.form?.value;
    if (this.form?.valid){
      try {
      this.response = await this.gs.hps.post('idm', 'user', 'change-mobile', body);
      // this.gs.router.navigate(['idm/otp'], { queryParams: { otp_id: response.otp_id, type: 'change-mobile' } });
      this.gs.localStorageSet('temp-mobile',this.form?.value);  
    }
    catch (error) {
      console.log(error);
      throw error
    }
  }
}
}
