import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-file-manager-modal',
  templateUrl: './file-manager-modal.component.html',
  styles: [
  ]
})
export class FileManagerModalComponent implements OnInit {
  @Input() modal: any
  isVisible:boolean=false;
  isUpload:boolean=false;
  isUpload2:boolean=false;
  isSubmit:boolean=false;

  constructor(public gs:GlobalService) { }

  ngOnInit(): void {
  }

  openModal() {
    this.isVisible = true;
  }
  closeModal() {
    this.isVisible = false;
  }

}
