import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { localStorageRemove } from 'src/mesbro/modules/shared/functions/common';
import { GlobalService } from 'src/mesbro/services/global.service';
import { IUser, IUserData } from '../../interfaces/i-user';

interface IParams {
  otp_id: string;
  type: string;
  _id: string;
}

@Component({
  selector: 'm-otp',
  templateUrl: './otp.component.html',
  styles: [],
})
export class OtpComponent implements OnInit {
  showMyMessage: any;
  user: IUser | undefined;
  form: FormGroup | undefined;
  four: boolean = false;
  images = {
    false: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/eye.svg',
    true: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/eye-orange-orange.svg',
  };
  allUser: {
    list: any;
    count: 0;
  };
  @Input() otp_id: any;
  @Input() type: any;
  @Input() _id: any;
  @Input() mobileNumber: any;
  initialize_form() {
    this.form = this.fb.group({
      otp: [
        null,
        [
          this.gs.form_validations.required('otp'),
          this.gs.form_validations.length_equals(6),
        ],
      ],
    });
  }
  error: any;
  params: IParams | undefined;
  mobile: any;
  constructor(
    public ar: ActivatedRoute,
    public gs: GlobalService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    // console.log(this.type);
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
      // console.log(this.params);
    });
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.user = users.activeUser;
      this.mobile = this.user?.contact_details?.mobile_number_primary;
      // console.log(this.mobile);

      // console.log(this.user);
    });
    this.initialize_form();
  }

  async verify_otp() {
    try {
      let body = {
        type: this.type,
        otp_id: this.otp_id,
        otp: this.form?.value.otp,
      };
      if (this.form?.invalid) {
        this.form?.markAllAsTouched();
        return;
      }
      let response: { token_id: string; user_id?: string;_id:string } =
        await this.gs.hps.post('idm', 'otp', 'verify-otp', body);
      this.gs.toast('OTP', 'OTP verified successfuly', 'success', 5);
      if (this.type == 'sign_up') {
        this.gs.router.navigate(['idm', 'sign-up'], {
          queryParams: { subtype: 'four', id: response.user_id,otp_id:this.otp_id ,_id:response?._id},
        });
      }
      if (this.type == 'sign-in') {
        this.sign_in_process(response);
      }
      if (this.params?.type == 'change-mobile') {
        this.change_mobile_process(response);
      }
      if (this.params?.type == 'forgot-password') {
        this.forgot_password_process(response);
      }
      if (body?.type == 'forgot-username') {
        this.forgot_username_process(response);
      }
      if (body?.type == 'delete-organization') {
        this.delete_organization_process(response);
      }
      if (body?.type == 'deactivate-organization') {
        this.deactivate_organization_process(response);
      }
      if (body?.type == 'ownership-transfer') {
        // this.deactivate_organization_process(response);
      }
    } catch (error: any) {
      this.error = error.error.message;
      this.gs.toast('OTP', error.error.message, 'warning', 5);
    }
  }

  sign_up_process(response: any) {
    try {
      let temp_user = this.gs.localStorageGet('temp-user');
      temp_user.personal_details.profile_picture =
        temp_user.personal_details.gender.toLowerCase() == 'male'
          ? '99/99/99/99/99/83/56.svg'
          : '99/99/99/99/99/83/57.svg';
      temp_user.personal_details.cover_picture = '99/99/99/99/99/83/55.svg';
      let user = {
        ...temp_user,
        _id: response.user_id,
        token: response.token_id,
      };
      setTimeout(() => {
        localStorageRemove('temp-user');
      }, 100);
      this.gs.user.add_user(user);
    } catch (error) {
      console.log(error);
    }
  }

  change_mobile_process(response: any) {
    try {
      let mobile_detail = this.gs.localStorageGet('temp-mobile');
      if (this.user) {
        this.user.contact_details.mobile_number_primary =
          mobile_detail.mobile_number_primary;
        this.gs.user.update_user(this.user);
      }
      this.gs.router.navigate(['/idm/user/details'], {
        queryParams: { type: 'Personal' },
      });
    } catch (error) {
      console.log(error);
    }
  }
  sign_in_process(response: any) {
    try {
      response.user.token = response?.token?._id || response.token;
      this.gs.user.add_user(response.user);
      this.gs.router.navigate(['/home']);
      // this.gs.router.navigate(['/idm/user/details'], { queryParams: { type: 'Personal' } });
    } catch (error) {
      console.log(error);
    }
  }

  forgot_password_process(response: any) {
    console.log(response);
    this.gs.router.navigate(['idm/change-password'], {
      queryParams: {
        type: 'forgot-password',
        otp_id: this.params?.otp_id,
        _id: this.params?._id,
      },
    });
  }
  forgot_username_process(response: any) {
    this.gs.router.navigate(['idm/sign-in'], {
      queryParams: {
        type: 'forgot-username',
        subtype: 'third',
        _id: response?.userid,
      },
    });
  }
  delete_organization_process(response: any) {
    this.gs.router.navigate(['org']);
  }
  deactivate_organization_process(response: any) {
    this.gs.router.navigate(['org']);
  }
  async resendOTP() {
    const body = {
      otp_id: this.otp_id,
    };
    try {
      let resendOtp = await this.gs.hps.post('idm', 'otp', 'resend-otp', body);
      this.showMyMessage =
        'Remaining Attempts' + ' ' + resendOtp.ReamingAttempt;
      console.log(resendOtp);
    } catch (error: any) {
      this.error = error.error.message;
      console.log(error.error);
      throw error;
    }
  }
}
