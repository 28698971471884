<div a-style w=100% minWidth=100% mt=7.4>
    <m-form title="{{queryParams.sub_type}} List {{list?.count}}" title_mt="1.5" title_mb="1.5" ml=2.3 fs="1.6" paddingNone labelWeight="500" mx="2.4" my="2.4" w="initial" pb="1.5">
        <hr a-style color="#e4e4e4" w="100%" mb="1">
        <div a-style ml=1.4>
            <m-input [(ngModel)]="queryParams.query" (keyup.enter)="queryParams.page=0; gs.router.navigate([],{queryParams : queryParams})" flexGrow=0 label="Search {{queryParams.sub_type}}" [columns]="1/4" cleareIcon="true" [capitalize]=false></m-input>
            <!-- <m-input [(ngModel)]="queryParams.query" (keyup.enter)="queryParams.page=0; gs.router.navigate([],{queryParams : queryParams})" flexGrow=0 label="Search {{queryParams.sub_type}}" [columns]="1/4" cleareIcon="true" [capitalize]=false></m-input> -->


        </div>
        <table a-style mx="1.4" mt="2.4" p="1" br="none">
            <tr>
                <th a-style align="ce   nter" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type !='Fixed'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Primary Category"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="(!deleted_categories['Secondary']) && (queryParams.sub_type =='Secondary' || queryParams.sub_type =='Tertiary' || queryParams.sub_type =='Type' || queryParams.group == 'Attribute' || queryParams.group == 'Basic Details' || queryParams.group == 'Image' || queryParams.group == 'Description' || queryParams.group == 'FAQ' || queryParams.group == 'Application' || queryParams.group == 'Tags')">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Secondary Category"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="(!deleted_categories['Tertiary']) && (queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Type' || queryParams.group == 'Attribute' || queryParams.group == 'Basic Details' || queryParams.group == 'Image' || queryParams.group == 'Description' || queryParams.group == 'FAQ' || queryParams.group == 'Application' || queryParams.group == 'Tags')">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Tertiary Category"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type =='Type' || queryParams.group == 'Attribute' || queryParams.group == 'Basic Details' || queryParams.group == 'Image' || queryParams.group == 'Description' || queryParams.group == 'FAQ' || queryParams.group == 'Application' || queryParams.group == 'Tags'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Product Type Name"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Secondary' || queryParams.sub_type == 'Primary'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Color Icon"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Secondary' || queryParams.sub_type == 'Primary'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Faded Icon"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Attribute'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Attribute"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Image'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Image"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Description'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Description"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'FAQ'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="FAQ"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Application'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Application"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Tags'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Tags"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Username"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Attribute' || queryParams.group == 'Basic Details' || queryParams.group == 'Image' || queryParams.group == 'Description' || queryParams.group == 'FAQ' || queryParams.group == 'Application' || queryParams.group == 'Tags'">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Last Updated by"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Updated At"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Modify"></m-text>
                </th>
                <th a-style align="center" br="1px solid #e4e4e4" fs="1.6" p="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text>
                </th>
            </tr>
            <tr *ngFor="let structure of list?.list; let i=index">
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{(queryParams.page || 0)*20+ i+1}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="(!deleted_categories['Tertiary']) && (queryParams.sub_type =='Type')">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{structure.parent3?.title}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="(!deleted_categories['Secondary']) && (queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Type')">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{structure.parent2?.title}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type =='Secondary' || queryParams.sub_type =='Tertiary' || queryParams.sub_type =='Type' ">
                    <m-text [type]="gs.enums.texts.paragraph35" textCenter="left" text="{{structure.parent1?.title}} "></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type !='Fixed'">
                    <m-text [type]="gs.enums.texts.paragraph35" textCenter="left" text="{{structure.title}}"></m-text>
                </td>
                <td a-style align="center" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Secondary' || queryParams.sub_type == 'Primary'">
                    <m-image h=15 w=15 [src]="structure.images.colored? ('https://new-api.mesbro.com/uploads/'+structure.images.colored) : 'assets/images/assets/category-image-color.svg'"></m-image>
                </td>
                <td a-style align="center" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.sub_type == 'Tertiary' || queryParams.sub_type == 'Secondary' || queryParams.sub_type == 'Primary'">
                    <m-image h=15 w=15 [src]="structure.images.faded? ('https://new-api.mesbro.com/uploads/'+structure.images.faded) : 'assets/images/assets/category-image-color.svg'"></m-image>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Attribute'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{3}}"></m-text>
                </td>
                <td a-style align="center" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Image'">
                    <m-image h=6 w=6 src="https://new-api.mesbro.com/uploads/{{structure.images.image}}"></m-image>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Description'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="0"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'FAQ'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="0"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Application'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="0"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Tags'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{structure.tags?.length}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{structure.meta?.activity?.updated_by?.first_name}} {{structure.meta?.activity?.updated_by?.last_name}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2" *ngIf="queryParams.group == 'Attribute' || queryParams.group == 'Basic Details' || queryParams.group == 'Image' || queryParams.group == 'Description' || queryParams.group == 'FAQ' || queryParams.group == 'Application' || queryParams.group == 'Tags'">
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{structure.meta?.activity?.updated_by?.first_name}} {{structure.meta?.activity?.updated_by?.last_name}}"></m-text>
                </td>
                <td a-style align="left" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2">
                    <m-text [type]="gs.enums.texts.paragraph35" text=" {{structure.meta.activity?.updated_at | date : 'dd MMM YYYY'}} at {{ structure.meta.activity?.updated_at | date:'shortTime'}} "></m-text>
                </td>
                <td a-style align="center" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2">
                    <m-image routerLink="/dashboard/attribute/form" [queryParams]="{ type : queryParams.type, sub_type : queryParams.sub_type, application : queryParams.application, _id : structure._id }" src="assets/images/assets/draft grey.svg" h="1.5" w="1.5"></m-image>
                </td>
                <td a-style align="center" br="1px solid #e4e4e4" py=1 px="1.6;1280:1.4;1024:1.2">
                    <m-image (click)="delete.openModal(); selected.index = i; selected.id=structure._id" src="assets/images/assets/trash discard grey.svg" h="1.5" w="1.5"></m-image>
                    <!-- <button  (click)="delete.openModal(); selected.index = i; selected.id=structure._id">Delete {{structure._id}} </button> -->
                </td>
            </tr>
        </table>
        <div a-style display="flex" gap="3" justify="space-between" w="100%" mx=2.5>
            <div>
                <m-button *ngIf="queryParams.page>0" [types]="gs.enums.buttons.button9" text="Prev" (click)="gs.previous(queryParams)"></m-button>
            </div>
            <m-button *ngIf="((queryParams.page || 0)*20 + 20) <list.count" [types]="gs.enums.buttons.button9" text="Next" (click)="gs.next(queryParams)"></m-button>
        </div>
    </m-form>
</div>

<m-confirm-modal #delete title="Delete" (ok)="delete_category(selected.id,selected.index)" description="Are you sure you want to permanently delete this {{queryParams.sub_type}} field?" button1="Cancel" button2="Delete"></m-confirm-modal>

<!-- (click)="gs.delete_from_list('structure',structure._id,list.list,i)" -->
