import { GlobalService } from './../../../../services/global.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'm-chat-create-label',
  templateUrl: './chat-create-label.component.html',
  styles: [
  ]
})
export class ChatCreateLabelComponent implements OnInit {
  form: FormGroup
  group_name: any
  group_image: any
  group_description: any;
  members: any = {};
  color: any;
  colors: boolean = false;
  clicked: boolean = false;
  nextcolor: boolean = false;
  thridcolor: boolean = false;
  fourthcolor: boolean = false;
  fifthcolor: boolean = false;
  sixthcolor: boolean = false
  @Input() labelForm: any;
  constructor(public gs: GlobalService, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.initialization_value()
  }

  initialization_value() {
    this.form = this.fb.group({
      Label_name: ['', this.gs.form_validations.required('Name')],
      description: ['']
    })
  }

  bgColors: any = {
    color1: 'EFECEC',
    color2: 'FFF5BD',
    color3: '9CE3BF',
    color4: 'C4E5F9',
    color5: 'FFA7B6',
    color6: 'C7CEFF',
    // color7: '#6E91EF',
    // color8: '#EFECEC',
  }
  changeBG(color: any) {
    if (this.bgColors.color1 == color) {
      this.color = this.bgColors.color1
    }
    else if (this.bgColors.color2 == color) {
      this.color = this.bgColors.color2
    }
    else if (this.bgColors.color3 == color) {
      this.color = this.bgColors.color3
    }
    else if (this.bgColors.color4 == color) {
      this.color = this.bgColors.color4
    }
    else if (this.bgColors.color5 == color) {
      this.color = this.bgColors.color5
    }
    else if (this.bgColors.color6 == color) {
      this.color = this.bgColors.color6
    }

    else if (this.bgColors.color7 == color) {
      this.color = this.bgColors.color7
    }
    else if (this.bgColors.color8 == color) {
      this.color = this.bgColors.color8
    }
  }


  label() {
    try {
      if (this.form?.invalid) {
        this.form?.markAllAsTouched()
        return;
      }
      let current_user = this.gs.user.users.activeUser;
      let body = {

        application: "Generic",

        component: "label",

        description: this.group_description,

        subtype: "label",

        title: this.form?.value.Label_name,

        color: this.color,
        members: {
          users: {
            [current_user?._id || '']: {
              first_name: current_user?.personal_details.first_name,
              last_name: current_user?.personal_details.last_name,
              profile_picture: current_user?.personal_details.profile_picture,
              countrycode: current_user?.location_details.country_calling_code,
              emailID: current_user?.contact_details.email_address_primary,
              mobile_number: current_user?.contact_details?.mobile_number_primary,
              _id: current_user?._id,

            },
            ...this.members
          },
        },
      }
      console.log(body) 
      // if (body.title) {
      let response = this.gs.hps.post('generic', 'label', 'add', body)
      this.gs.toast('Label', 'Created Successfully', 'success')
      console.log(response) 
      // }
      console.log(this.form.value)
    } catch (error) {
      console.log(error)
      throw error
      // this.gs.router.navigate(['new-chat'], { queryParams: { type: 'group' } });

    }

  }
  createLabel() {
    let body = {
      "application": "Generic",
      "component": "label",
      "subtype": "mail",
      "label_name": this.form?.value.Label_name,
      'description': this.form?.value.description,
      'color': this.color
    }
    let response = this.gs.hps.post('generic', 'label', 'add', body)
  }
}