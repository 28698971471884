<div a-style h="7.2" bg="#F8F8F8" br="0.5 solid #E4E4E4" shadow="0px 3px 6px #17171729">
    <div a-style h="7.2" br="0.5px solid #E4E4E4" display="flex" align="center" justify="center" gap="2.5">
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/product/home'])"
            [style.color]="gs.router.url.includes('/product/home')?'#2479AB':'black'" cursor="pointer">
            <img a-style h=2.6 w=2.6 src="../assets/images/Icon_Bank/Home_Coloured.svg">
            <p a-style fs="1.4">Home</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"
            (click)="gs.router.navigate(['/product/category'],{queryParams:{category:'primary',type:'product'}})"
            [style.color]="gs.router.url.includes('/product/category')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/Category_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Categories</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="compare_not_found()"
            [style.color]="gs.router.url.includes('/product/compare')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/Compare_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Compare</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" 
           (click)="gs.router.navigate(['/product/verfied-suppliers'],{queryParams:{type:'suppliers'}})">
            <img src="../assets/images/Icon_Bank/Organization_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4"  [style.color]="gs.router.url.includes('/product/verfied-suppliers?type=suppliers')?'#2479AB':'black'"   cursor="pointer">Verified Suppliers</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="redirectToFav('favourite')">
            <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4"  [style.color]="gs.router.url.includes('/product/verfied-suppliers?type=favourite')?'#2479AB':'black'"   cursor="pointer">Favourite</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"  (click)="redirectToSave('save')">
            <img src="../assets/images/Icon_Bank/Save_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4"  [style.color]="gs.router.url.includes('/product/verfied-suppliers?type=mycollection')?'#2479AB':'black'"   cursor="pointer">My Collection</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="redirectToHistory('history')"
            [style.color]="gs.router.url.includes('/product/history')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/History_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">History</p>
        </div>
    </div>
</div>

<!-- <div *ngIf="isVisiblecompare">
    <img src="../assets/images/Icon_Bank/No_Compare_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
    <p a-style fs="2">Select at least two products for comparison</p>
</div> -->