<div a-style minWidth="100%" maxWidth="100" >
    <div a-style bgRepeat=no-repeat bgPosition=center bgSize=cover bgUrl="../assets/images/GIF_Bank/B2B_Product/B2B_Product_Home_Page_Banner_3.gif"   w="auto" brRadius="0.6" pt="6"  display=flex flexDirection=column justify="center" textCenter="center">
        <div>
            <img src="../assets/images/Global.png" a-style w="9.6" h="9.6" mb="3">
        </div>
        <!-- <p>amruta</p> -->
        <p a-style fs="3.2" color="#707070">Global Marketplace</p>
        <div a-style display="flex;655:block" justify="space-evenly" mt="6.6" textCenter="center">
            <div a-style display="655:flex" align="center">
                <p a-style fs="3.2" color="#333333">{{response?.count?.RFQs}}</p>
                <p a-style fs="2" color="#333333">RFQs</p>
            </div>
            <div a-style display="655:flex" align="center">
                <p a-style fs="3.2" color="#333333">{{response?.count?.active_Suppliers}}</p>
                <p a-style fs="2" color="#333333">Active Suppliers</p>
            </div>
            <div a-style display="655:flex" align="center">
                <p a-style fs="3.2" color="#333333">{{response?.count?.Industries}}</p>
                <p a-style fs="2" color="#333333">Industries</p>
            </div>
        </div>
        <div a-style mt="4" display="flex" justify="center" align="center">
            <p a-style fs="2"  w="96.3" >We are trusted B2B Marketplace where only genuine business enquiries between buyers, suppliers, exporters and importers of every origin from globe.</p>
        </div>
        <div a-style mt=3 align=center>
            <!-- <button a-style br="1px solid #2479AB" color="#333333" brRadius="0.6" px="1" py="0.5"  bg="white">Know More</button> -->
            <m-button [types]="gs.enums.buttons.button0" text="Know More" h=2.9></m-button>
        </div>
    </div>
</div>
<!-- py="6;655:2.4" px="9.4;1450:4.4;1336:9.4;773:5.4;666:2.4" -->