<div  *ngIf="isVisible" top="0" left="0" zIndex="2000" a-style bg="rgb(0,0,0,0.8)" display="grid" placeContent="center" position="fixed" h="100vh" w="100vw">
    <div a-style w="42.6" h="28.3" br="1px solid lightgray" shadow="0 2px 10px rgb(0 0 0 / 20%)" brRadius="0.5" align="center" display="flex" justify="center" bg="#FFFFFF" position="absolute" top="3" left="50%" transform="translateX(-50%)" bg="white">
        <div a-style w="20.4" h="19.6">
            <img src="https://mesbro.in/assets/images/phone-call.svg" alt="" >
            <p a-style fs="2" fw="bold" mt="2.4">Call us now</p>
            <p a-style fs="1.3" mt="0.8">We're here to help.</p>
            <p a-style fs="2.4" fw="bold" mt="1.6">1800 120 7454</p>
        </div>
        <div a-style position="absolute" right="1" top="1">
            <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg"  a-style w=1.6 h=1.6 alt="" (click)="closeModal()" cursor=pointer>
        </div>
    </div>
</div>
