import { applications } from './../../../../configuration/data/applications';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'm-folder-form',
  templateUrl: './folder-form.component.html',
  styles: [
  ]
})
export class FolderFormComponent implements OnInit {
  @Input() modal: any
  @Input() updateid: any
  isVisible: boolean = false;
  @Output() folder_added = new EventEmitter()

  listener: () => void;

  application = 'Generic';
  component = 'Folder';

  form_data = {
    folder_name: '',
    description:''
  }
  queryParams: any = {
    _id: ''
  }
  params: any
  mail_id: any
  form: FormGroup | undefined;
  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, public ms: MailService) {
  }
  async ngOnInit() {
    console.log(this.updateid);
    this.initializeForm()
    if (this.updateid) {
      console.log(this.updateid);
      this.form?.patchValue(this.updateid)
    }
    if (this.updateid) {
      let details = await this.gs.hps.get('generic', 'data', this.updateid._id);
      console.log(details);
      this.form?.patchValue(details)
    }
    // this.patch()
  }
  // async patch() {
  //   await 
  // }
  // @Input() folder_name: any
  // handleParams = async (params: Params) => {
  //   this.queryParams = params

  // }
  initializeForm() {
    this.form = this.fb.group({
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }
  async submit() {
    let body = this.form?.value
    let response = await this.gs.hps.post('generic', 'data', 'add', body)
    this.folder_added.emit(body);
    this.modal.closeModal()

  }
}
