import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';


@Component({
  selector: 'm-domian-list',
  templateUrl: './domian-list.component.html',
  styles: [
  ]
})
export class DomianListComponent implements OnInit {
  isVisible: any = {}

  
  
  
  application = 'Generic';
  component = 'Domain';

  list = {
    list: [],
    count: 0
  }
 
  queryParams = {
    application: '',
    component: '',
    type: ''
  }
  

  users: IUserData;

  constructor(public hps: HttpProviderService, public gs: GlobalService, public router: Router, public ar: ActivatedRoute) { }


  async get_list() {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
    })

    let custom_filters = [{ ['meta.roles.owner.user.' + this.users?.activeUser?._id]: true }]
    console.log(custom_filters,",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    let response = await this.gs.get_data_list(this.application, this.component , custom_filters )
// console.log(response);
  this.list = response;
// console.log(custom_filters,",,,,,,,,,,,,*******,,,,,,,,,,,,,,,,,,");
  }

async  ngOnInit() {

   await this.get_list()
    console.log(this.list); 
    this.ar.queryParams.subscribe((params: any) => {
      this.queryParams = params;

    })


  }

  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }
}


    // let response = await this.gs.get_data_list(this.application, this.component, [])
    // this.list = response;