import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-mail-formgroup',
  templateUrl: './mail-formgroup.component.html',
  styles: [
  ]
})
export class MailFormgroupComponent implements OnInit {
  @Input() title: string = '';
  @Input() heading: string = '';
  @Input() mt: string = '';
  @Input() px: string = '';
  @Input() mr: string = '';
  @Input() mx: string = '';
  @Input() mb: string = '';
  @Input() labelWeight: string = '';
  @Input() brNone: boolean = true;
  @Input() paddingNone: boolean = true;


  constructor() { }

  

  ngOnInit(): void {
    console.log(this.heading);
    console.log(this.title);

    
  }

}
