import { GlobalService } from './../../../../services/global.service';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { IStructure } from 'src/mesbro/modules/shared/interfaces/IType';
import { el } from 'date-fns/locale';


@Component({
  selector: 'm-generic-details',
  templateUrl: './generic-details.component.html',
  styles: [
  ],
  providers: [
    { provide: Window, useValue: window }
  ],
})
export class GenericDetailsComponent implements OnInit {
  @ViewChild('scrollMe') scrollMe: ElementRef
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  private itemWidth: number;
  private currentSlide = 0;
  @Input() application: string = '';
  @Input() component: string = '';
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() card: boolean = true;
  @Input() card_application: string = '';
  @Input() wrap: string = 'wrap';
  @Input() px: any;
  @Input() gap: any;
  @Input() size: any;
  @Input() minWidth: any;
  @Input() maxWidth: any;
  @Input() py: any;
  @Input() w: any;
  @Input() my = ''
  @Input() mb = ''
  @Input() ml = ''
  @Input() mt = ''
  @Input() justify = ''
  @Input() align = ''
  @Input() h = ''
  @Input() flexDirection = ''
  @Input() data_action = ''
  @Input() filters: any = {};
  @Input() global_search: boolean = false;
  @Input() news_search: boolean = false;
  @Input() load_more: boolean = false;
  @Input() index: any;
  @Input() flexWrap: any;
  @Input() add_card: boolean = false;
  @Input() add_item_text: string = '';
  @Input() add_item_link: string = '';
  @Input() selected_data: any = undefined;
  @Input() map_function: any;
  @Input() display: any;
  @Input() add_item_margin: any;
  @Input() add_card_mr: any;
  @Input() add_card_ml: any;
  organization: any;
  queryParams: any = {
    hid: '',
    _id: '',
    application: ''

  }
  likeList: {
    list: []
  }
  favList: {
    list: []
  }
  saveList: {
    list: []
  }
  dislikeList: {
    list: []
  }
  form: FormGroup | undefined
  @Output() activity = new EventEmitter();

  quantity: any
  unit: any
  description: any
  terms: any
  count: any
  value: any
  comment: any
  favProduct: any
  isRefreshBanner: boolean = false
  imageObject: Array<object> = [];
  videoObject: Array<object> = [];
  averageRating: any
  public isOrganization: boolean = false
  public organizationDetail: any
  first: boolean = true
  second: boolean = false
  third: boolean = false
  fourth: boolean = false
  search: any;
  grey1: boolean = true
  grey2: boolean = true
  grey3: boolean = true
  grey4: boolean = true
  grey5: boolean = true
  colored1: boolean = false
  colored2: boolean = false
  colored3: boolean = false
  colored4: boolean = false
  colored5: boolean = false
  compColored: boolean = true
  compGrey: boolean = false
  isVisible_compare: boolean = false;

  reportDescription: any
  // @Input() type: any;
  generic_banner: any = {
    Vehicle: true,
    Recipe: true,
    Subsidy: true,
    Service: true,
    Nursery: true,
    Spiritual: true,
    Horoscope: true,
    Article: true,
  }

  query_params = {
    _id: '',
    type: '',
    step: '',
    application: '',
    primaryId:''

  }


  public selected = {
    Primary: <IStructure | undefined>undefined,
    Secondary: <IStructure | undefined>undefined,
    Tertiary: <IStructure | undefined>undefined,
    Type: <IStructure | undefined>undefined,
  };
  structure: any;
  poonam: any;
  fixed_attributes: any = {}
  productImage = [{ src: this.gs.enums.images.image40, width: 1.7, height: 1.6 },
  { src: this.gs.enums.images.image41, width: 1.7, height: 1.6 },
  { src: this.gs.enums.images.image42, width: 1.6, height: 1.6 },
  { src: this.gs.enums.images.image43, width: 1.3, height: 1.6 },
  { src: this.gs.enums.images.image44, width: 1.8, height: 1.6 },
  { src: this.gs.enums.images.image45, width: 1.6, height: 1.6 },
  { src: this.gs.enums.images.image46, width: 1.4, height: 1.6 }
  ]
  SnapshotImage = [
    { src: this.gs.enums.images.image49, width: 0.8, height: 0.8 },
    { src: this.gs.enums.images.image48, width: 17.7, height: 0.6 }
  ]
  rating = 0;
  id = ''
  public list = [];
  current: boolean = false;
  public relatedProductList = {
    list: [],
    count: 0
  };
  // isVisible_compare: boolean = false;
  data: any = []
  details: any = undefined;
  detail: any = undefined;
  activities: any = undefined;
  // rating: any = undefined;
  list_data: any;
  filtersRelated: any;
  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, @Inject(Window) private window: Window) { }
  onKeyUp(event: any) {
    this.gs.router.navigate(['/product', 'list'], { queryParams: { type: 'product', query: this.search } });
  }
  ngOnInit(): void {
    if (this.quantity != 0) {
      this.gs.getMasters()
    }
    this.current = Object.keys(this.gs.localStorageGet('compare-items') || {}).includes(this.details?.parent?._id)
    this.ar.queryParams.subscribe(params => this.handleParams(params));
    // this.queryParams = { ...params };
    let scroll = document.createElement('style')
    scroll.innerHTML = `#scroll::-webkit-scrollbar {display: none;}`
    document.head.appendChild(scroll);
    this.likeProductList()
    this.dislikeProductList()
    this.saveProductList()
    this.ratingProductList()
    this.favouriteProductList()
    let storeRfq: any = []
    this.initialize_form()
  }
  handleParams = async (params: Params) => {
    this.queryParams = params;
    if (params._id) {
      // this.gs.scrollToTop(this.myScrollContainer)
      this.getDetail()
      this.ratingList(this.queryParams._id)
      // this.gs.scrollHorizontalRight(this.myScrollContainer)
      // this.gs.$selected_application.next(this.details.application)
      // console.log('generic Detail...............................', this.details);
      // this.activities = await this.gs.get_activity(this.queryParams._id);
      setTimeout(async () => {
        // await this.gs.action('view', this.details)
      }, 1000);
    }
  }
  initialize_form() {
    this.form = this.fb.group({
        reportreason: [""]
        // reportreason:this.fb.array([this.reportreasons])
    })
  }
  async productReport(_id: any) {
    try {
      let body = {
        ...this.form?.value,
        "product_id": _id
      }
      if(this.gs.user?.users?.activeUser?._id){
        let response = await this.gs.hps.post("business", "products", "report-product", body)
        this.form?.reset()
      }else{
        this.gs.toast('Warning','Please login to report the product','warning')
      }


    }
    catch (error) {

    }
  }


  async getDetail() {
    try {
      this.detail = await this.gs.hps.get('business', 'products', this.queryParams._id)
      if (this.detail.parent.parentId) {
        this.getOrganizationDetail(this.detail.parent.parentId)
      }
      if (this.detail) {
        this.getPrimary()
        this.getSecondary()
        this.getTertiary()
        this.getType()
      }
      this.relatedProducts(this.queryParams?._id)
      // this.detail.basic.rating.sum = await this.gs.sumOfRatings(this.detail.basic.rating);

    } catch (error) {
    }
  }
  primary: any
  secondary: any
  tertiary: any
  typeId: any
  async getPrimary() {
    this.primary = await this.gs.hps.get('business', 'productcategories', this.detail.primaryId)
  }
  async getSecondary() {
    this.secondary = await this.gs.hps.get('business', 'productcategories', this.detail.secondaryId)
  }
  async getTertiary() {
    this.tertiary = await this.gs.hps.get('business', 'productcategories', this.detail.tertiaryId)
    
  }
  async getType() {
    this.typeId = await this.gs.hps.get('business', 'producttypes', this.detail?.categoryInfo.typeId)
  }
  async getOrganizationDetail(id: any) {
    try {
      this.organizationDetail = await this.gs.hps.get('idm', 'group', id)
    } catch (error) {
      // //// console.logerror)
      this.isOrganization = false

    }
  }
  compare_object: any | 0
  compare_count: any | undefined

  public fieldObject: any
  public initialEntitySchema: any
  public entitySchema: any
  public primaryCategoryArray: any
  public secondaryCategoryArray: any
  public tertiaryCategoryArray: any
  public attributes: any[] = []
  public imagesToloop: any[] = []
  public videosToloop: any[] = []
  public iconToloop: any[] = []
  public faqs: any[] = []
  formEntitySchema(attributes: any[]) {
    let checks = {}
    let returnObject: any[] = [];

    const findHeadingIndex = (heading: any) => {
      for (let i = 0; i < returnObject.length; i++) {
        let tempObject = returnObject[i];
        if (tempObject.groupHeading == heading) {
          return i;
        }
      }
      return -1;
    }

    const findSubHeadingIndex = (headingIndex: number, subHeading: any) => {
      return 0
      let checkObject = returnObject[headingIndex].children;
      for (let i = 0; i < checkObject.length; i++) {
        let tempObject = checkObject[i];
        if (tempObject.subHeading == subHeading) {
          return i;
        }
      }
      return -1;
    }


    attributes.forEach(attribute => {
      // console.log(attribute)
      let key1 = attribute.fixed ? 'basic' : 'attributes'
      let key2 = attribute.fixed ? 'name' : 'id'
      let hasValue = this.detail[key1][attribute['id']] || this.detail[key1][attribute['name']]
      // console.log(hasValue)
      if (!attribute.hidden && hasValue && hasValue.value != undefined && hasValue.value != null && hasValue.value.length > 0 && attribute.dataType != 'Image' && attribute.dataType != 'Video' && attribute.dataType != 'Link Type') {
        let headingIndex = findHeadingIndex(attribute.groupHeading);
        let subHeadingIndex = -1;
        // // //// console.logheadingIndex, subHeadingIndex);
        if (headingIndex > -1) {
          subHeadingIndex = findSubHeadingIndex(headingIndex, attribute.subHeading);
        }

        if (headingIndex == -1) {
          returnObject.push({
            groupHeading: attribute.groupHeading,
            children: [{
              subHeading: attribute.subHeading,
              fields: [attribute]
            }]
          })
          headingIndex = 0;
          subHeadingIndex = 0;
          // // //// console.logreturnObject[headingIndex], returnObject[headingIndex][subHeadingIndex])
        } else if (headingIndex > -1 && subHeadingIndex == -1) {
          returnObject[headingIndex].children.push({
            subHeading: attribute.subHeading,
            fields: [attribute]
          })
          subHeadingIndex = 0;
          // // //// console.logreturnObject[headingIndex], returnObject[headingIndex][subHeadingIndex])
        } else if (headingIndex > -1 && subHeadingIndex > -1) {
          // // //// console.logreturnObject[headingIndex], returnObject[headingIndex][subHeadingIndex])
          returnObject[headingIndex].children[subHeadingIndex].fields.push(attribute);
        }
      }

      if (attribute.dataType == 'Icon' || attribute.dataType == 'Dropdown Master') {
        // this.gs.publicMasters[attribute.application]
        // this.gs.publicMasters[attribute.application][attribute.component] 
        // console.log(attribute)
        // if (!this.gs.publicMasters[attribute.application] || !this.gs.publicMasters[attribute.application][attribute.component])
        //   this.getMasters(attribute.application, attribute.component)

      }


    });

    // console.log(returnObject);
    // if(returnObject){
    //     this.getSpeceficDetail(returnObject , this.detail.attributes) 
    // }

    return returnObject;

  }
  public fixedEntityDetail: any

  async getFixedAttribute() {
    try {
      let body = {
        "basic.name": {
          "$eq": "product"
        }
      }
      this.fixedEntityDetail = await this.gs.hps.post('fixedattribute', 'fixedattributetypes', 'list', body);
      return this.fixedEntityDetail.list[0].attributes
    } catch (error) {
      // //// console.logerror)
    }
  }
  createImageVideosIcon() {
    // console.log(this.detail)

    if (this.detail.basic['Images'] || this.detail.basic['Photos']) {
      this.imagesToloop = (this.detail.basic['Images'] || this.detail.basic['Photos'])
    }
    else {
      let Temp = this.gs.getFieldById(this.attributes, 'Images')
      let Temp2 = this.gs.getFieldById(this.attributes, 'Photos')
      // console.log(Temp)
      // console.log(Temp2);
      if (this.detail.attributes[(Temp || Temp2)]) {
        this.imagesToloop = this.detail.attributes[(Temp || Temp2)].value
      }
      else {
        this.imagesToloop = this.detail.basic[(Temp || Temp2)].value
      }
      // this.imagesToloop = this.detail.attributes[(Temp || Temp2)].value
      // this.imagesToloop = this.detail.basic[(Temp || Temp2)].value

      // console.log(this.imagesToloop)

    }
    // this.getImages(this.imagesToloop)


    if (this.detail.basic['Icon']) {
      this.iconToloop = this.detail.basic['Icon']
    }
    else {
      let Temp3 = this.gs.getFieldById(this.attributes, 'Preferred Audience')
      this.fieldObject = this.gs.getFieldByObject(this.attributes, 'Preferred Audience')

      // console.log(Temp3)
      // console.log(this.fieldObject)

      if (this.detail.attributes[Temp3]) {
        this.iconToloop = this.detail.attributes[Temp3].value
      }
      else if (this.detail.basic[Temp3]) {
        this.iconToloop = this.detail.basic[Temp3].value
      }
    }

    // console.log(this.iconToloop)


    if (this.detail.basic['Videos']) {
      this.videosToloop = this.detail.basic['Videos']
    } else {
      let Temp = this.gs.getFieldById(this.attributes, 'Videos')
      // console.log(Temp)

      if (this.detail.attributes[Temp]) {
        this.videosToloop = this.detail.attributes[Temp].value
      }
      else {
        this.videosToloop = this.detail.basic[Temp].value
      }
    }
    this.getVideos(this.videosToloop)
  }
  getVideos(videos: any) {
    this.videoObject = []
    if (videos.length >= 1) {
      videos.forEach((element: any) => {
        this.videoObject.push({
          video: this.gs.imageAssetsPath + element,
        })
      });
    }
    console.log(this.videoObject)

  }
  async getList() {
    for (let i = 0; i < this.list.length; i++) {
      const element = this.list[i];
      this.list_data = element
    }

  };
  // this.detail?.categoryInfo?.typeId
  async relatedProducts(id: any) {
    try {
      let body =
      {
        "key": id
      }
      this.relatedProductList = await this.gs.hps.post('business', 'products', 'related-products', body)
    } catch (error) {

    }
  }

  change_image: any;
  select_image(path: any) {
    this.change_image = path
  }

  async favouriteProduct(id: any) {
    try {
      let body = {
        "key": id
      }
      if (this.gs.user?.users?.activeUser?._id) {
        this.favProduct = await this.gs.hps.post('business', 'products', 'favourite', body)
      if (this.favProduct.message == 'favourite the product') {
        this.favColorImage = '../assets/images/Icon_Bank/Favourite_Coloured (1).svg'
        this.gs.toast('Favourite', 'Product favourite successfully', 'Info')
      } else {
        this.favColorImage = '../assets/images/Icon_Bank/Favourite_Grey (1).svg'
        this.gs.toast('Unfavourite', 'Product unfavourite successfully', 'Info')
      }
      }else{
        this.gs.toast('Warning','Please login to Favourite the product', 'warning')
      }
     
    } catch (error) {

    }
  }

  async favouriteProductList() {
    let body = {
      "$and": [
        {
          ["activity.favourite." + this.gs.user.users.activeUser?._id]: { "$eq": true },
        }
      ]
    }
    let response = await this.gs.hps.post('generic', 'activity', 'list', body)
    this.favList = response
    this.favColorImage = '../assets/images/Icon_Bank/Favourite_Grey (1).svg'
    for (let index = 0; index < this.favList.list.length; index++) {
      this.favouriteElement = this.favList.list[index];
      let data = Object.keys(this.favouriteElement.activity)
      let dataValue = Object.values(this.favouriteElement.activity)
      for (let index = 0; index < dataValue.length; index++) {
        const element: any = dataValue[index];
        let key = Object.values(element)
        if (data[0] == 'favourite' && this.favouriteElement?.parents?.data?._id == this.queryParams?._id && key[0] == true) {
          this.favColorImage = '../assets/images/Icon_Bank/Favourite_Coloured (1).svg'
        }
      }

    }
  }

  async likeProduct(id: any) {
    try {
      let body = {
        "key": id,
        "action": "like"
      }
      if(this.gs.user.users?.activeUser?._id){
        let response = await this.gs.hps.post('business', 'products', 'like-dislike', body)
        this.likeColorImage = '../assets/images/Icon_Bank/Like_Coloured (1).svg'
        this.disLikeColorImage = '../assets/images/Icon_Bank/Dislike_Grey (1).svg'
        this.gs.toast('Like', 'Product liked successfully', 'Info')
      }else{
        this.gs.toast('Warning','Please login to like the product','warning')
      }
 
    } catch (error: any) {
      this.gs.toast('Like', error.error.message, 'warning')
    }
  }

  // likeKeys: any
  // dislikeKeys: any
  likeColorImage: any
  favColorImage: any
  disLikeColorImage: any
  likeElement: any
  favouriteElement: any
  saveElement: any
  disLikeElement: any

  async likeProductList() {
    try {
      let body = {
        "action": "like",
        ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true,
      }
      let response = await this.gs.hps.post('generic', 'activity', 'list', body)
      this.likeList = response
      this.likeColorImage = '../assets/images/Icon_Bank/Like_Grey (2).svg'

      for (let index = 0; index < this.likeList.list.length; index++) {
        this.likeElement = this.likeList.list[index];
        if (this.likeElement?.action == 'like' && this.likeElement?.parents?.data?._id == this.queryParams?._id) {
          this.likeColorImage = '../assets/images/Icon_Bank/Like_Coloured (1).svg'
        }
      }

    } catch (error) {

    }
  }

  // dislikeColorImage: any
  async dislikeProductList() {
    try {
      let body = {
        "action": "dislike",
        ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true,
      }
      let response = await this.gs.hps.post('generic', 'activity', 'list', body)
      this.dislikeList = response
      this.disLikeColorImage = '../assets/images/Icon_Bank/Dislike_Grey (1).svg'
      for (let index = 0; index < this.dislikeList.list.length; index++) {
        this.disLikeElement = this.dislikeList.list[index];
        if (this.disLikeElement?.action == "dislike" && this.disLikeElement?.parents?.data?._id == this.queryParams?._id) {
          this.disLikeColorImage = '../assets/images/Icon_Bank/Dislike_Coloured (1).svg'
        }
      }
    } catch (error) {

    }
  }

  async dislikeProduct(id: any) {
    try {
      let body = {
        "key": id,
        "action": "dislike"
      }
      if(this.gs.user.users?.activeUser?._id){
        let response = await this.gs.hps.post('business', 'products', 'like-dislike', body)
        this.disLikeColorImage = '../assets/images/Icon_Bank/Dislike_Coloured (1).svg'
        this.likeColorImage = '../assets/images/Icon_Bank/Like_Grey (2).svg'
        this.gs.toast('Dislike', 'Product disliked successfully', 'Info')
      }else{
        this.gs.toast('Warning','Please login to dislike the product','warning')
      }
      
      // console.log(this.relatedProductList)
    } catch (error) {

    }
  }

  async getLatestPrice() {
    try {
      let body = {
        "key": this.detail._id,
        "quantity": "1",
        "type": "latest price"
      }
      if (this.gs.user?.users?.activeUser?._id) {
        let response = await this.gs.hps.post('business', 'productenquiries', 'get-latest-price', body)
      this.gs.toast('Success', 'Request sent successfully', 'success')
      }else{
      this.gs.toast('Warning', 'Please login to get latest price', 'warning')

      }
      
    } catch (error) {
    }
  }

  async sendEnquiry() {
    if (this.terms) {
      try {
        let body = {
          "key": this.detail._id,
          "quantity": this.quantity,
          "type": "enquiry",
          "description": this.description,
          "unit": this.unit,
        }
        if (this.gs.user?.users?.activeUser?._id) {
          let response = await this.gs.hps.post('business', 'productenquiries', 'product-enquiry', body)
          this.gs.toast('Enquiry', 'Enquiry Send Successfully', 'success')
        }else{
          this.gs.toast('Warning', 'Please login to send enquiry', 'warning')

        }
       
      } catch (error) {
      }
    }
  }

  async sendQuotation() {
    if (this.terms) {
      try {
        let body = {
          "key": this.detail._id,
          "quantity": this.quantity,
          "type": "enquiry",
          "description": this.description,
          "unit": this.unit,
        }
        let response = await this.gs.hps.post('business', 'productenquiries', 'product-enquiry', body)
        this.gs.toast('Enquiry', 'Enquiry Send Successfully', 'success')
      } catch (error) {
      }
    }
  }
  async Rating() {
    try {
      let body = {
        "application": "business",
        "component": "review",
        "key": this.detail._id,
        "rating": this.rating,
      }
      if (this.gs.user.users?.activeUser) {
        let response = await this.gs.hps.post('business', 'products', 'add-product-rating', body)
        this.getDetail()
        this.gs.toast('Rating', 'Rating Send Successfully', 'success')
      }else{
        this.gs.toast('warning', 'Please login to send rating', 'warning')
      }
    } catch (error) {
    }
  }

  saveImage: any
  async saveProduct(id: any) {
    try {
      let body = {
        "key": id,
      }
      if (this.gs.user.users?.activeUser) {
        let response = await this.gs.hps.post('business', 'products', 'saved', body)
        if (response.message == 'saved the product') {
          this.saveImage = '../assets/images/Icon_Bank/Save_Coloured (1).svg'
          this.gs.toast('Save', 'Product Save successfully', 'Info')
        } else {
          this.saveImage = '../assets/images/Icon_Bank/Save_Grey (1).svg'
          this.gs.toast('Unsave', 'Product unsave successfully', 'Info')
        }
      }else{
        this.gs.toast('warning', 'Please login to save product', 'warning')
      }
    } catch (error) {
    }
  }

  async saveProductList() {
    try {
      let body = {
        "$and": [
          {
            ['activity.saved.' + this.gs.user.users.activeUser?._id]: { "$eq": true },
          },
          { "parents.savedService._id": { "$exists": false } }
        ]
      }
      let response = await this.gs.hps.post('generic', 'activity', 'list', body)
      this.saveList = response
      this.saveImage = '../assets/images/Icon_Bank/Save_Grey (1).svg'
      for (let index = 0; index < this.favList.list.length; index++) {
        this.saveElement = this.favList.list[index];
        let data = Object.keys(this.saveElement.activity)
        let dataValue = Object.values(this.saveElement.activity)
        for (let index = 0; index < dataValue.length; index++) {
          const element: any = dataValue[index];
          let key = Object.values(element)
          if (data[0] == 'favourite' && this.saveElement?.parents?.data?._id == this.queryParams?._id && key[0] == true) {
            this.saveImage = '../assets/images/Icon_Bank/Save_Coloured.svg'
          }
        }

      }

    } catch (error) {
    }
  }
  editable=false

  async ratingList(id: any) {
    try {
      let body = {
        "parents": {
          "data": {
            "_id": id
          }
        }
      }
      let response = await this.gs.hps.post('business', 'products', 'product-rating', body)
   
      this.averageRating = response.count?.averageRating
      
    } catch (error) {
    }
  }

  add_to_compare() {
    this.compare_object = this.gs.localStorageGet('compare-items');

    // this.compare_count = (this.gs.localStorageGet('compare-items'));
    if (this.compare_object) {
      this.compare_count = Object.keys(this.compare_object)?.length
      console.log(this.compare_object);

    }
    else {
      this.compare_count = 0;
    }
    if (this.compare_count >= 4) {
      console.log('not allowed');
    } else {
      if (this.compare_count == 0) {
        let current = this.gs.localStorageGet('compare-items') || {};
        current[this.detail._id] = this.detail;
        this.gs.localStorageSet('compare-items', current)

        this.current = !this.current
        this.gs.$refresh_compare.next()
        let data: any = Object.values(current)
        this.gs.localStorageSet('category', data[0]?.categoryInfo.typeId)
      } else {

        let category = this.gs.localStorageGet('category')
        if (category === this.detail.categoryInfo.typeId) {
          let current = this.gs.localStorageGet('compare-items') || {};
          current[this.detail._id] = this.detail;
          this.gs.localStorageSet('compare-items', current)
          this.gs.toast('Information', 'Product was added to compare list', 'Info', 3)
          this.current = !this.current
          this.gs.$refresh_compare.next()
        }
      }
    }
  }
  async reportproduct(id: any) {
    try {
      let body = {
        "orgId": id,
        "reportreason": this.reportDescription,
      }
      if (this.gs.user.users?.activeUser) {
        let response = await this.gs.hps.post('idm', 'group', 'report', body)
        this.gs.toast('Report', 'Report sent successfully', 'success')
      }else{
        this.gs.toast('warning', 'Please login to report product', 'warning')
      }
    
    } catch (error) {
    }
  }
  ratingCount: any
  async ratingProductList() {
    try {
      let body = {
        "parents": {
          "data": {
            "_id": this.queryParams?._id
          }
        }
      }
      let response = await this.gs.hps.post('business', 'products', 'product-rating', body)
      this.ratingCount = response.count

      // this.dislikeList=response
    } catch (error) {

    }
  }

  nextImg() {
    const el:any = document.getElementById('scrollMe');
    console.log(el); // 👉️ div#box
    
    // ✅ Works
    console.log(el.clientWidth); // 👉️ 1567
  }
  scrollHorizontalRight(): void {
    const el:any = document.getElementById('scrollMe');
    try {
      setTimeout(() => {
        console.log(el?.nativeElement, el?.clientWidth, el?.scrollLeft)
        el?.scrollTo({ left: el?.scrollLeft + el?.clientWidth, behavior: 'smooth' });
      }, 10);
    } catch (err) { }
  }
  scrollHorizontalLeft(): void {
    const el:any = document.getElementById('scrollMe');
    try {
      setTimeout(() => {
        console.log(el?.nativeElement, el?.clientWidth, el?.scrollLeft)
        el?.scrollTo({ left: el?.scrollLeft - el?.clientWidth, behavior: 'smooth' });
      }, 10);
    } catch (err) { }
  }

  // prev() {
  //   if( this.currentSlide === 0 ) return;

  //    this.currentSlide = ((this.currentSlide - 1) + this.items.length) % this.items.length;
  //    const offset = this.currentSlide * this.itemWidth;

  //    const myAnimation : AnimationFactory = this.builder.build([
  //      animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
  //    ]);

  //    this.player = myAnimation.create(this.carousel.nativeElement);
  //    this.player.play();
  //  }

}
