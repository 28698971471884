import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';

@Component({
  selector: 'm-shared-card',
  templateUrl: './shared-card.component.html',
  styles: [
  ]
})
export class SharedCardComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'

  @Input() detail:any;
  @Input() type:any;
  // application = 'idm';
  // component = 'group';
  params = {
    type: ''
  };
  // count:any;
  // statusList = [
  //   { status: 'Pending' },
  //   { status: 'Acknowledged' },
  //   { status: 'Closed' },
  // ]
  list = {
    list: [],
    count: 0
  }
  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    // this.get_list();
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
    });
  }

  // async get_list() {
  //   let response = await this.gs.get_data_list(this.application, this.component, [])
  //   this.list = response;
  //   this.count=this.list.count
  //   console.log(this.count);
  // }



}
