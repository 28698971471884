import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-chat-modal',
  templateUrl: './chat-modal.component.html',
  styles: [
  ]
})
export class ChatModalComponent implements OnInit {
  @Input() modal: any
  @Input() details: any
  constructor(public gs :GlobalService) { }

  ngOnInit(): void {
  }

}
