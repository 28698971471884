<div a-style display="flex" justify="center " mb="1.8" mt="2.4">
  <form  [formGroup]="form" a-style>
    <m-form [brNone]=false flexWrap='none' display=flex gap="1.6">
      <m-input formControlName="country_calling_code" px="0" [type]="gs.enums.inputs.dropdown" dropdownHeight="20"
        prefix_h="100%" prefix_w="3" bindLabel="countryCode" bindValue="countryCode" [list]="countryList"
        [columns]="0.41" minWidth="7.7" maxWidth="210px"></m-input>
      <m-input formControlName="mobile_number_primary" px="0"
        postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/mobile.svg" fs="1.3" [columns]="0.41"
        minWidth="25.8">
      </m-input>
      <!-- <m-button [disable]="form?.invalid" [types]="gs.enums.buttons.button30" (click)="changeMobileNumber()" mt="2.8"
          w="35" text="Send OTP "></m-button> -->
    </m-form>
    <div a-style mt=5 display="flex" justify="space-between">
      <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5" >Back</button>
      <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5"
        (click)="changeMobileNumber();sear1.openModal();">Next</button>
    </div>
  </form>
</div>

<m-modal *ngIf="false"  #otp width="45">
  <div a-style p=2.4 br="1px solid #e4e4e4" brRadius="0.6" align=left maxWidth="492px" h=fit-content>
    <img src="../assets/images/images/Mesbro_log.png" routerLink="/home">
    <p mb=1.3 color="##333333" a-style fs=2 mt=2.4>Verifying your mobile
      number</p>
    <p mb=1.3 color="##333333" a-style fs=1.3 mt=2.4>For your security,
      Mesbro wants to make sure that it's
      really you. We will send 6 digit verification code on your mobile.</p>
    <!-- <p a-style color=#81BC06 fs=1.6>{{users?.activeUser?.contact_details?.mobile_number_primary}}</p> -->
    <m-otp [type]="type" [otp_id]=otp_id>
    </m-otp>
    <div a-style mt=5 display="flex" justify="space-between">
      <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5">Back</button>
      <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5">Next</button>
    </div>
  </div>
</m-modal>

<m-modal #sear1>
  <div a-style h="21.2" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center" align="center">
    <div a-style display="flex" flexDirection="column" align="center" gap="2.4">
      <img src="../assets/images/GIF_Bank/Other/Green_Tick.gif" alt="" a-style w="10" h="10">
      <p a-style fs="2">Primary Mobile Number Updated Successfully</p>
    </div>
  </div>
</m-modal>