import { GlobalService } from 'src/mesbro/services/global.service';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-generic-setting',
  templateUrl: './generic-setting.component.html',
  styles: [
  ]
})
export class GenericSettingComponent implements OnInit {
  user: IUser | undefined;
  language = ['Primary', 'Secondary', 'Tertiary', 'Type', 'Form'];

  constructor(public gs: GlobalService) {

  }

  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.user = users.activeUser;
      console.log(this.user);
    })

  }
  open(text: any) {
    text.openModal()
  }


}
