import { Pipe, PipeTransform } from '@angular/core';
import formatDistance from 'date-fns/formatDistance';

@Pipe({
  name: 'durationAgo'
})
export class DurationAgoPipe implements PipeTransform {

  transform(date: any): unknown {
    let value =  formatDistance(new Date(date), new Date())
    if(value.includes('less than a minute')){
      value = 'Just now'
    }
    return value;
  }
}
