<ng-container>
    <ng-container *ngIf="onlyInput" [ngTemplateOutlet]="inputs"></ng-container>
    <div *ngIf="!onlyInput" a-style [w]="(columns*100 || 100) + '%'" [style.visibility]="hide"
        [minWidth]="minWidth?minWidth:'unset'" [maxWidth]="maxWidth?maxWidth:'unset'" [flexGrow]="flexGrow || 1"
        [px]="px?px:0" [pl]="pl? pl : 0" [pr]="pr? pr : 0" [mb]="mb?mb:0">
        <div a-style align="left">
            <p *ngIf="label && type != 'media' && type != gs.enums.inputs.file_upload " a-style fs="1.3"
                [mt]="mt?mt:'0'" [mb]="mb?mb:'1.6'" [align]="align?align:'left'">{{label}}</p>
            <img a-style [mt]="" *ngIf="info" h=1.4 [title]="info"
                src="https://mesbro.com/assets/images/information.svg" alt="">
        </div>
        <ng-container [ngTemplateOutlet]="inputs"></ng-container>
        <m-text [type]="gs.enums.texts.paragraph72" *ngIf="control?.errors?.message && control?.touched"
            [text]="control?.errors?.message" mt="0.8"></m-text>
    </div>
</ng-container>


<ng-template #inputs>
    <div *ngIf="(type==gs.enums.inputs.newText  || type==gs.enums.inputs.password) && !(prefix || postfix || postfix_text || prefix_text )"
        (mouseleave)="hovered = false" (mouseover)="hovered=true" [style.background]=" disabled? '#e4e4e4' : 'unset' "
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        a-style [bg]="bg" [brRadius]="brRadius? brRadius:'0.6'" w="100%" [h]="h?h:3.9" [p]="p?p:1"
        [shadow]="'hov:'+shadow" display="flex" alignItems="center">
        <input #input_value [disabled]="disabled" [type]="type" [value]="value"
            
            (ngModelChange)="capitalize && value = capitalize_text(value); onChange($event); control?.markAsTouched();clearIcon='cross'"
            [(ngModel)]="value" [min]="min" [placeholder]="placeHolder" a-style w="100%" h="100%" pl="0" br="none"
            [brRadius]="brRadius? brRadius:'0.6'" min="0" [bg]="bg? bg : 'initial'" [fs]="fs"
            [color]="color? color : '#000000'" autofocus>
        <img (click)="control?.reset();control?.markAsTouched();input_value.focus()" (mouseover)="clearIcon='cross-red'"
            (mouseleave)="clearIcon='cross'"
            [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'"
            [style.visibility]="value && hovered && !disabled?'unset':'hidden'" a-style h="1.3" w="1.3" cursor="pointer"
            [mr]="mr?mr:0 ">
    </div>

    <div *ngIf="(type==gs.enums.inputs.newText || type==gs.enums.inputs.password)" a-style gap="1.6">
        <div *ngIf="(type==gs.enums.inputs.newText || type==gs.enums.inputs.password)  && (prefix || postfix || postfix_text || prefix_text || postfix2 )"
            (mouseover)="hovered=true" (mouseleave)="hovered = false"
            [style.border]="'1px solid '+((control?.invalid && control?.touched) ?'red':'#e4e4e4')" a-style
            [shadow]="'hov:'+shadow" [h]="3.9" [maxWidth]="w?w:'100%'" [pl]='pl?pl:1' [pr]="outer_pr?outer_pr:1"
            bg="#ffffff" display="flex" align="center" brRadius="0.6">
            <div *ngIf="prefix" (click)="prefix_clicked.emit()" a-style h="100%" display="flex" alignItems="center"
                [pl]="prefix_pl" [pr]="country?1:0">
                <img [src]="country?'https://new-api.mesbro.com/uploads/'+country_dropDown.image:prefix" a-style
                    [h]="prefix_h?prefix_h:2.3" [w]="prefix_w?prefix_w:2.3">
            </div>
            <div *ngIf="country" a-style fs="1.3" px=1>{{country_dropDown.country_code}}</div>
            <div *ngIf="prefix_text" textWrap=nowrap a-style a-style fs=1.3 fw=400>{{prefix_text}}</div>
            <input [(ngModel)]="value"
                (ngModelChange)="capitalize && value = capitalize_text(value); onChange($event); control?.markAsTouched();clearIcon='cross'"
                a-style [bg]="bg? bg : 'initial' " (keyup.enter)="postfix_clicked.emit()" [type]="type"
                [pl]="prefix?1:0" [pr]="p?p:1" [h]="h?h:'3.6'" [fs]="fs?fs:1.1" [placeholder]="placeHolder"
                [cursor]="cursor" [br]="none? br : 'none'" outline="none" w="100%">
            <img (click)="control?.reset();control?.markAsTouched();" (mouseover)="clearIcon='cross-red'"
                (mouseleave)="clearIcon='cross'" [style.visibility]="value && hovered?'unset':'hidden'"
                [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3"
                w="1.3" cursor="pointer" mr=2>
            <div a-style [bg]="postfix_bg" h="3.9" w="max-content" [px]="postfix_px?postfix_px:0" py="1.1"
                align="center" brRadius="3px" display="flex" [brTopLeftRadius]="brTopLeftRadius?brTopLeftRadius:0"
                [brTopRightRadius]="brTopRightRadius?brTopRightRadius:0"
                [brBottomRightRadius]="brBottomRightRadius?brBottomRightRadius:0"
                [brBottomLeftRadius]="brBottomLeftRadius?brBottomLeftRadius:0">
                <span *ngIf="postfix_text" a-style fs=1.3 fw=400> {{postfix_text}} </span>
                <img *ngIf="postfix3" (click)="postfix2_clicked.emit()" [src]="postfix3" a-style h="1.7" w="1.7"
                    cursor="pointer">
                <img *ngIf="postfix2" (click)="postfix2_clicked.emit()" [src]="postfix2" a-style h="1.7" w="1.7"
                    cursor="pointer">
                <img *ngIf="postfix" (click)="postfix_clicked.emit()" [src]="postfix" a-style h="1.7" w="1.7"
                    cursor="pointer">
            </div>
        </div>
    </div>

    <div *ngIf="type==gs.enums.inputs.newText_area" [style.background]=" disabled? '#e4e4e4' : 'unset' "
        (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style display="flex" alignItems=flex-end
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        brRadius="0.6" w="width?width:100%" p=1 overflow=auto minWidth="w?w:100%" maxwidth="w?w:100%">
        <textarea autosize minRows="5" [mt]="mt" [m]="m" [rows]="rows?rows:5" name="" id="" [placeholder]="placeHolder"
            style="resize: none;" [disabled]="disabled" [(ngModel)]="value" [placeholder]="placeHolder"
            (ngModelChange)="onChange($event); control?.markAsTouched();clearIcon='cross'" a-style [fs]="fs"
            [bg]="bg? bg : 'initial' " w="100%" pl="0" [pr]="pr" br="none"></textarea>
        <img [style.visibility]="value && hovered && !disabled?'unset':'hidden'"
            (click)="control?.reset();control?.markAsTouched();" (mouseover)="clearIcon='cross-red'"
            (mouseleave)="clearIcon='cross'"
            [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3"
            w="1.3" cursor="pointer" [mr]="mr?mr:0 ">
    </div>

    <div *ngIf="type==gs.enums.inputs.newCheckbox" (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style
        display="flex" [alignItems]="alignItems?alignItems:'center'" flexGap="1" [mt]="mt? mt :1.2"
        [brRadius]="onlyInput? '50%' : 0" [p]="p" [bg]="bg">
        <input a-style [bg]="bg? bg : '#CFEEFC' " type="checkbox" a-style [h]="h?h:'2'" [w]="h?h:'2'" cursor=pointer
            [id]="label" [style.border]="'1px solid '+((control?.invalid && control?.touched) ?'red':'#e4e4e4')"
            (ngModelChange)="onChange($event); changed.emit(value)" [(ngModel)]="value">
        <label *ngIf="label" a-style mt="0.1" fs="1.3" color="#333333" display="flex" flexGap="0.2" cursor=pointer
            [for]="label" h=auto textWrap=nowrap> {{label}}</label>
        <ng-container *ngIf="labelTemplate" [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{passedData :passedData }"></ng-container>
    </div>

    <div *ngIf="type==gs.enums.inputs.newRadioButton" (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style
        display="flex" align="center" flexGap="1" [mt]="mt? mt :0" [brRadius]="onlyInput? '50%' : 0">
        <input a-style [bg]="bg? bg : 'initial' " [w]="w?w:2" [h]="h?h:2" cursor=pointer type="radio" [id]="label"
            [name]="radioName" [value]="label" (change)="onChange(label)">
        <label [for]="label" a-style mt="0.1" fs="1.3" color="#333333" align="center" cursor=pointer display="flex"
            flexGap="0.2" textWrap=nowrap> {{label}}</label>
    </div>

    <div *ngIf="type==gs.enums.inputs.file" (click)="image.openModal()">
        <ng-content></ng-content>
        <m-modal #image width="60" [close_on_overlay]="false">
            <m-image-croppr *ngIf="image.is_visible" [value]="value" [aspect_ratio]="aspect_ratio"
                (file_upload)="image.closeModal(); set_image($event)"></m-image-croppr>
        </m-modal>
    </div>

    <div *ngIf="type==gs.enums.inputs.dropdown" [style.background]=" disabled? '#e4e4e4' : 'unset' "
        (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style w="100%" position="relative"
        [shadow]="'hov:'+shadow">

        <div a-style [style.border]="br? br : '1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')"
            w="100%" brRadius=0.6 [px]="input_px?input_px:1" h="3.9" textCenter=justify display="flex"
            alignItems="center" [mb]="mb">
            <div a-style w='100%' [h]="h? h : 3.9" display="flex" justify="space-between">
                <input #input_value [disabled]="disabled" [bg]="bg? bg : 'initial' "
                    (click)="!disabled && is_visible=true; filtered_list=list;"
                    (keydown)="dropdown_keyboard_actions($event)"
                    (focus)="filtered_list=list; is_visible=true; focused.emit('Focused')"
                    (blur)="is_visible = false; blurred.emit('Focused')" [type]="gs.enums.inputs.text"
                    [placeholder]="placeHolder" a-style [px]='prefix?1:0' h="100%" flexGrow=1
                    [w]="inputWidth?inputWidth:'100%'" maxWidth="100%" br="none" [(ngModel)]="inputValue.input">
                <div a-style display="flex" alignItems="center" (click)="input_value.focus()">
                    <img *ngIf="!disabled && value && hovered"
                        (click)="is_visible=false;control?.reset();control?.markAsTouched();inputValue.input=undefined;selected_item.image=undefined;"
                        (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'"
                        [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style
                        h="1.3" w="1.3" cursor="pointer">
                    <img src="../assets/images/assets/downarrow.svg" *ngIf="!value || !hovered" cursor=pointer a-style
                        w="1.3" h="1.3" (click)="!disabled && is_visible=!is_visible;">
                </div>
            </div>
        </div>
        <div class="no_scroll" *ngIf="is_visible" a-style w="100%"  bg=white position="absolute" bottom="0" [style.outline]="filtered_list?'0.5px solid #e4e4e4':''"
            transform=translatey(100%) left=0 zIndex=2 [h]="" minWidth="9"  maxHeight="20" class="no_scroll" overflow="auto" brRadius="0.4" 
            id='scroll'>
            <div a-style display="flex" gap="1" *ngFor="let item of filtered_list ;let i=index " p="1"
                alignItems="center" (mousedown)="select_dropdown( $event, item);" (mouseover)="hover[i]=true"
                (mouseleave)="hover[i]=false" [style.background-color]="hover[i]?'#EEF5FB':'white'"
                [style.background]="selected_item==item?'#e4e4e4':'white'">
                <m-image *ngIf="bindImage && (item.image || item[bindImage])"
                    [src]="direct_image? (item.image || item[bindImage]) :  'https://new-api.mesbro.com/uploads/'+ (item.image || item[bindImage])"
                    h="2.5" w="auto"></m-image>
                <p a-style #country fs=1.3 textCenter=justify cursor=pointer>{{ (item[bindLabel] || item)+'' }} </p>
            </div>
        </div>
       
    </div>

    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.tags" a-style p="1"
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        brRadius="0.4" display="flex" justify="flex-start" flexGap="1" flexWrap="wrap" w="100%" position="relative"
        h="13.3" overflowY="scroll" class="no_scroll_bar">
        <div a-style bg="#d2d2d273" bg fs="1.1" fw="400" display="flex" flexGap="0.8" brRadius="0.5"
            br="1px solid #E4E4E4" py="0.6" px="1.6" *ngFor="let item of value;let i = index" h="3.1">
            <p [attr.contenteditable]="contentEditables[i]" (click)="contentEditables[i]=true"
                (blur)="changeSingleTag($event,i)">{{item}}</p>
            <p a-style (click)="remove(i)" color="#B2B2B2" cursor=pointers>X</p>
        </div>
        <input #input_value type="text" [bg]="bg? bg: 'white' " (paste)="onPaste($event,input_value)"
            (focus)="is_visible=true" (blur)="is_visible=false" [placeholder]="placeHolder" a-style h="2" flexGrow=1 w=2
            br="none" (keydown.backspace)="pop($event)" (keydown.enter)="push($event)" [(ngModel)]="inputValue.input">
    </div>

    <ng-container *ngIf="type==gs.enums.inputs.media" [ngTemplateOutlet]="media"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>

    <ng-container *ngIf="type==gs.enums.inputs.file" [ngTemplateOutlet]="file"
        [ngTemplateOutletContext]="{passedData : {name : label} }"></ng-container>

    <!-- <div *ngIf="type==gs.enums.inputs.date" [formGroup]="date_form_group">
        <div a-style br="1px solid #e4e4e4" [p]="p?p:0.9" w="fit-content" brRadius="0.6">
            <input formControlName="date" type="date" a-style br="none" color="#b2b2b2" [w]="w?w:30">
        </div>
    </div> -->

    <div *ngIf="type==gs.enums.inputs.time" [formGroup]="time_form_group">
        <div a-style br="1px solid #e4e4e4" brRadius="0.6" [p]="p?p:1" w="fit-content">
            <input formControlName="time" type="time" a-style br="none" color="#b2b2b2" fs="1.3" [w]="w?w:30">
        </div>
    </div>


</ng-template>


<ng-template #media let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style display="flex" align="center" gap="2.4" brRadius="0.6">
        <div a-style br="1px solid #E4E4E4" brRadius="0.6">
            <div a-style display="flex" [flexDirection]="flexDirection ? flexDirection : 'column'" justify="center"
                align="center" [w]="w ? w : '20'" [h]="h ? h : '20'" [px]="px? px : 0" [py]="py? py : '0'"
                brRadius="0.6">
                <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
                    [src]="src ? src :(value? ('https://new-api.mesbro.com/uploads/'+value):'../assets/images/assets/new-organization/Browse (1).svg')"
                    mt=1 brRadius=0.6> </m-image>
                <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()" w="18">{{text}}</p>
            </div>

            <div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #file let-passedData='passedData'>
    <input #file_input type="file" [multiple]="true" name="" [(ngModel)]="value" (change)="upload_event($event)" hidden
        id="">
    <div a-style w="20" h="20" br="1px solid #e4e4e4" brRadius=".5">
        <div a-style brRadius=".4" align="center" h="19.8" p="2.6" display="flex" flexDirection="column" position="relative">
            <m-image *ngIf="!value?.length || !multiple" (click)="file_input.click()"
                [src]="value? 'assets/images/Icon_Bank/PDF_Coloured.svg' :'assets/images/Icon_Bank/Add_File_Coloured.svg'" h="8.5" mt=1 w="8.5"
                brRadius=0.6> </m-image>
            <p a-style [fs]="fs? fs :'1.6'" w="18" color=#707070 mt=1.5 (click)="file_input.click()">{{text}}</p>
            <img src="../assets/images/Card Icon/Delete Icon.svg" *ngIf="value" alt="" (click)="deleteDocument.emit()" a-style w="2.5" h="2.5" position="absolute" top="1" right="1">
        </div>
    </div>
</ng-template>