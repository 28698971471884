import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-organization-card',
  templateUrl: './organization-card.component.html',
  styles: [
  ]
})
export class OrganizationCardComponent implements OnInit {
  users: IUserData;
  response: any;
  mobileNumber: any;
  type = "delete-organization"

  @Output() deleted = new EventEmitter()
  @Input() detail: any;
  @Input() istype: any;
  constructor(public gs: GlobalService,) { }

  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    });
  }


  async delete_organization(id: any) {
    let body = {
      _id: this.detail?._id
    }
    this.response = await this.gs.hps.post('idm', 'group', 'delete-group', body);
  }
  redirection() {
    if (this.gs.router.url.includes('/org/shared-organization')) {
      this.gs.router.navigate(['org', 'organization-dashboard'], { queryParams: { _id: this.detail?._id, type: 'shared' } })
    }
    if (this.gs.router.url.includes('/org/organization-ERp')) {
      this.gs.router.navigate(['org', 'organization-smart-erp'], { queryParams: { _id: this.detail?._id } })
    }
    if (this.gs.router.url.includes('/org/organization-home')) {
      this.gs.router.navigate(['org', 'organization-dashboard'], { queryParams: { _id: this.detail?._id } })
    }

    // if (this.gs.router.url.includes('/org/shared-organization')) {
    //   this.gs.router.navigate(['org', 'organization-dashboard'], { queryParams: { _id: this.detail?._id, type: 'shared' } })
    // } else {
    //   this.gs.router.navigate(['org', 'organization-dashboard'], { queryParams: { _id: this.detail?._id } })
    // }
  }
  async delete_organization_with_product() {
    let body = {
      deleted_orgId: this.detail?._id
    }
    this.response = await this.gs.hps.post('idm', 'group', 'delete-org-product', body);
  }
}
