import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: []
})
export class FileUploadComponent implements OnInit {

 
  @Input() aspect_ratio = 1;
  @Input() value = '';

  @Output() file_upload = new EventEmitter();
  @Output() cancel = new EventEmitter();

  file: any;
  constructor(public gs: GlobalService,) { }
data:any;
  url = ''
  reinitialize() {
    this.url = ''
  }

  ngOnInit(): void {
  }

 
 async upload_event(event: any) {
    this.file = event.target.files[0];
    let form_data = new FormData()
    form_data.append('name', 'avatar');
    form_data.append('avatar', this.file);
    console.log(this.file)

    console.log('Before Api Call')
    this.file = await this.gs.hps.post('', '', 'upload', form_data)
    
  }


  upload_file(){
    console.log(this.file)
    this.file_upload.emit(this.file)
  }

}
