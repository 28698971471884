import { Component,OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styles: [
  ]
})
export class DashboardLayoutComponent implements OnInit {
  
toggle:boolean = true;
@ViewChild('sidebar') sidebar:any
section_width:any = '28'
width:any;
minWidth:any;
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
 

  if(window.innerWidth <= 1024){
    this.width = '0'
    this.minWidth = '0'
    this.toggle = false
  }else{
    this.toggle = true
    this.width = '28'
    this.minWidth = '25'
  }
    setTimeout(() => {
      if (!this.gs.user.users.activeUser) {
        this.gs.toast('User', 'Kindly Login to proceed', 'error')
        console.log('Redirecting Now From Dashboard Layout')
        this.gs.router.navigateByUrl('/idm/sign-in');
      }
    }, 50)
   
  }
  toggle_sidebar(sidebar:any){
    this.toggle = !this.toggle;
    this.toggle?sidebar.width='28':sidebar.width='0'
    this.toggle?sidebar.minWidth='25':sidebar.minWidth='0'
  }  

  onResize(event:any,sidebar:any) {
    // console.log(event.target.innerWidth);
    if(event.target.innerWidth <= 1024){
      sidebar.width = '0'
      sidebar.minWidth = '0'
      this.toggle = false
    }else{
      this.toggle = true
      sidebar.width = '28'
      sidebar.minWidth = '25'
    }
  }

}
