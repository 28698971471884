<!-- --Vehicle Details Page-- -->
<!-- <div a-style mt=20></div> -->
<div a-style brRadius=0.6 br="1px solid #e4e4e4" shadow="hov:0 3px 6px 0 #e4e4e4">
    <div a-style brBottom="1px solid #e4e4e4">
        <div a-style position=relative>
            <m-image [src]="'https://new-api.mesbro.com/uploads/'+(details?.profile_picture||details.gallery[0])" w=100% h=47.3 brTopLeftRadius=0.6 brTopRightRadius=0.6></m-image>
        </div>
        <div a-style display=flex position=relative align="flex-end" w=100%>
            <div a-style br="1px solid #e4e4e4" w=11.4 h=11.4 position=absolute brRadius=0.6 left=2.4 bottom=2.4 zIndex=0>
                <m-image [src]="'https://new-api.mesbro.com/uploads/'+(details?.parents?.group?.files?.profile_picture || details?.parents?.parent?.images?.image)" w=11.2 h=11.2 brRadius="0.6"> </m-image>
            </div>
            <div a-style w=100% p=1.2 pb=2.2 pr=2 pl=16.2>
                <m-text [type]="gs.enums.texts.paragraph26" [text]="details?.title"></m-text>
                <div a-style display=flex justify="space-between" mt=1>
                    <div a-style display=flex gap=0.4>
                        <m-text [type]="gs.enums.texts.paragraph49" color="#ff100d" [text]="details?.parents?.group?.basic_details?.legal_name"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph49" text="{{details?.parents?.group?.basic_details?.legal_name? ' | ' : ''}}Type :"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph49" color="#ff100d" [text]="details?.parents?.parent?.title"></m-text>
                    </div>
                    <m-text *ngIf="details?.attributes?.price_details?.ex_showroom_price" [type]="gs.enums.texts.paragraph49" text="Ex Price : {{details?.attributes?.price_details?.ex_showroom_price?.value?details?.attributes?.price_details?.ex_showroom_price?.value:0}}" mb=0.2></m-text>
                </div>
            </div>
        </div>
    </div>
    <div a-style display="flex" justify="space-between" align="center" p="2.4" flexWrap="wrap">
        <m-text [type]="gs.enums.texts.paragraph38" text="{{details?.meta?.activity?.count?.view? details?.meta?.activity?.count?.view : 0}} View"></m-text>
        <div a-style display="flex" flexGap=2>
            <m-image *ngFor="let item of vehicle_image" [src]='item.src' [w]="item.width" [h]="item.height"> </m-image>
            <ng-container [ngTemplateOutlet]=like [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/like grey.svg',hover_src:'../assets/images/assets/like colour.svg',text:activities?.count?.like? activities?.count?.like : 0, type : 'like'}}"></ng-container>
            <ng-container [ngTemplateOutlet]=dislike [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/dislike grey.svg',hover_src:'../assets/images/assets/dislike colour.svg',text:activities?.count?.dislike? activities?.count?.dislike : 0, type : 'dislike'}}"></ng-container>
            <ng-container [ngTemplateOutlet]=compare [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/compare normal.svg',hover_src:'../assets/images/assets/compare hover1.svg'}}"></ng-container>
            <ng-container [ngTemplateOutlet]=bookmark [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/save normal.svg',hover_src:'../assets/images/assets/save hover.svg', type : 'save'}} "></ng-container>
            <ng-container [ngTemplateOutlet]=icons [ngTemplateOutletContext]="{data:{src:'https://mesbro.in/assets/images/print.svg',label : 'print'}}"></ng-container>
            <m-image a-style (click)="qr_code.openModal()" src="https://mesbro.in/assets/images/qr.svg" w=1.7 [title]="QR_title"></m-image>
            <m-image a-style (click)="share_modal.openModal()" src="https://mesbro.in/assets/images/share-grey.svg" w=1.7 [title]="share_title"></m-image>
        </div>
    </div>  
</div>
  <!-- ---------------- -->
  
  <ng-template #icons let-data=data>
    <div a-style display=flex align=center>
        <m-image src={{data.src}} w=1.7 mr=0.8 (click)="activity.emit(data.type || data.label);" [title]="print"></m-image>
        <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
    </div>
  </ng-template>
  
  <m-modal #qr_code width=70>
    <m-bar-code></m-bar-code>
  </m-modal>
  
  <m-modal #share_modal width=55.5>
    <m-share [title]="details?.title" [url]="'https://new.mesbro.com/generic/detail?_id='+ details?._id"></m-share>
  </m-modal>
  
  <!-- like -->
  <ng-template #like let-data=data>
    <div a-style display=flex align=center>
        <m-image [src]="data.src" w=1.7 mr=0.8 (clicked)="toggle(activities,'dislike','like')" [title]="like_title" [hover]="data.hover_src"  count_key="like" [current_value]="activities?.my_activity?.like" (click)="activity.emit(data.type || data.label);"></m-image>
        <!-- [count]="activities?.count" -->
        <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
        <!-- text={{activities?.count?.like}} -->
    </div>
  </ng-template>
  
  <!-- dislike -->
  <ng-template #dislike let-data=data>
    <div a-style display="flex" align="center">
        <m-image [src]="data.src" w="1.7" mr="0.8" (clicked)="toggle(activities,'like','dislike')" [title]="dislike_title" [hover]="data.hover_src"  count_key="dislike" [current_value]="activities?.my_activity?.dislike" (click)="isVisible_dislike=!isVisible_dislike;activity.emit(data.type || data.label);"></m-image>
        <!-- [count]="activities?.count" -->
        <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
    </div>
  </ng-template>
  
  <!-- compare -->
  <ng-template #compare let-data=data>
    <div a-style display="flex" align="center">
        <m-image [src]="data.src" w="1.7" mr="0.8" [hover]="data.hover_src" [title]="compare_title" [current_value]="current" (click)="isVisible_compare=!isVisible_compare;add_to_compare()"></m-image>
    </div>
  </ng-template>
  
  <!-- bookmark -->
  <ng-template #bookmark let-data=data>
    <div a-style display="flex" align="center">
        <m-image [src]="data.src" w="1.7" mr="0.8" [title]="save_title" [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src" [current_value]="activities?.my_activity?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities.my_activity.save?'unsave':data.type || data.label);activities.my_activity.save =!activities.my_activity.save"></m-image>
    </div>
  </ng-template>
  
  <!-- {{details|json}} -->