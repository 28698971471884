import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niceDateFormatPipe'
})
export class NiceDateFormatPipePipe implements PipeTransform {

  transform(value: any) {
    let _value = (value);
    let date2:any=new Date(value)
    let date: any = new Date()
    let dif = Math.floor(((date - date2) / 1000) / 86400);
    if (dif < 30) {
      value = this.convertToNiceDate(value);
      return value
    } else {
      let datePipe = new DatePipe("en-US");
      value = datePipe.transform(value, 'dd/MM/yyyy');
      return value;
    }
  }

  convertToNiceDate(time: string) {
    var date = new Date(time),
      diff = (((new Date()).getTime() - date.getTime()) / 1000),
      daydiff = Math.floor(diff / 86400);
  
    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
      return '';
  
    return daydiff == 0 && (
      diff < 60 && "Just now" ||
      diff < 120 && "1 minute ago" ||
      diff < 3600 && Math.floor(diff / 60) + " min ago" ||
      diff < 7200 && "1 hour ago" ||
      diff < 86400 && Math.floor(diff / 3600) + " hour ago") ||
      daydiff == 1 && "Yesterday" ||
      daydiff < 7 && daydiff + " days ago" ||
      daydiff < 31 && Math.ceil(daydiff / 7) + " week(s) ago";
  }
}
