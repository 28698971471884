import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-organization-header',
  templateUrl: './organization-header.component.html',
  styles: [
  ]
})
export class OrganizationHeaderComponent implements OnInit {

  params:{
    type:any
    subType:any
  }

  constructor( public ar:ActivatedRoute , public router : Router , public gs : GlobalService) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params
    })
  }

}
