import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-success',
  templateUrl: './success.component.html',
  styles: [
  ]
})
export class SuccessComponent implements OnInit {

  @Input() image: string = '';
  @Input() description: string = '';

  @HostBinding('style.display') display = 'contents'

  constructor() { }

  ngOnInit(): void {
  }

}
