import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'm-notifications',
  templateUrl: './notification-tray.component.html',
  styles: [
  ]
})
export class NotificationTrayComponent implements OnInit {

  application = 'generic';
  component = 'notification';
  list = {
    list: [],
    count : 0
  }
  users: IUserData | undefined;
  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) {
    this.gs.user.users$.subscribe(users => {
      this.users = users;

    })
  }
  async get_list() {
    let filters = {'user._id' : this.gs.user.users?.activeUser?._id}
    let response = await this.gs.hps.post(this.application, this.component, 'list', filters)
    this.list = response;
    console.log(this.list);
    
  }

  ngOnInit(): void {
    this.get_list()
  }

}
