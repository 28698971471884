<div a-style display="flex" justify="center">
    <div a-style p="3" br="1px solid #17171729">
        <div a-style display="flex" gap="0.8" justify="space-between" align="center" mb="3.2">
            <div a-style display="flex" gap="0.8">
                <img src="../assets/images/assets/Mask print.svg" alt="">
                <p a-style fs="1.3" color="#333333">MPIN</p>
                <p a-style fs="1.3" color="#2479AB">98756789766789</p>
            </div>
            <p a-style fs="4" color="#333333" fw="400">Mesbro</p>
        </div>
        <p a-style fs="1.6" color="#333333">{{details?.title}}</p>

        <div a-style display="flex" gap="1.6" pt="1.6">
            <img src="https://new-api.mesbro.com/uploads/{{details?.profile_picture}}" alt="" a-style w="20" h="20">
            <div a-style>
                <div a-style display="flex">
                    <p a-style fs="1.3" color="#333333">Price : </p>
                    <p a-style fs="1.3" color="#2479AB">INR
                        {{details?.attributes?.basic_details?.price_from?.value ?
                        details?.attributes?.basic_details?.price_from?.value : 0}} -
                        {{details?.attributes?.basic_details?.price_to?.value ?
                        details?.attributes?.basic_details?.price_to?.value : 0}}
                    </p>
                </div>
                <div a-style display="flex" py="0.5">
                    <p a-style fs="1.3" color="#333333">Origin Of Goods : </p>
                    <p a-style fs="1.3" color="#333333">{{details?.attributes?.basic_details?.origin_of_goods?.value}}
                    </p>
                </div>
                <div a-style display="flex">
                    <p a-style fs="1.3" color="#333333">Min Order Quantity :</p>
                    <p a-style fs="1.3" color="#333333">
                        {{details?.attributes?.basic_details?.minimum_order_quantity?.value}}</p>
                </div>
                <p a-style fs="1.3" color="#2479AB" pt="2">{{details?.parents?.group?.basic_details?.legal_name}}</p>
                <div a-style display="flex">
                    <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                        text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.city}},">
                    </m-text>
                    <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                        text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.state}},">
                    </m-text>
                    <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                        *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.country"
                        text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.country}}">
                    </m-text>
                </div>
                <!-- <p a-style fs="1.3" color="#333333" pt="0.5">Maharashtra,</p>
                <p a-style fs="1.3" color="#333333">India</p> -->
                <p a-style fs="1.3" color="#333333" py="0.5">{{details?.parents?.group?.contact_details?.telephone}},
                    {{details?.parents?.group?.contact_details?.mobile}}</p>
                <!-- <p a-style fs="1.3" color="#333333">+91 9876543211</p> -->
                <p a-style fs="1.3" color="#333333" py="0.5">{{details?.parents?.group?.contact_details?.email}}</p>
                <p a-style fs="1.3" color="#333333">{{details?.parents?.group?.contact_details?.website}}</p>
            </div>
        </div>
        <p a-style fs="1.3" color="#333333" mt="1.6">{{details?.attributes?.product_details?.description?.value}}</p>
        <div a-style mt="2.4">
            <div a-style br="0.5px solid #e4e4e4" brRadius="0.6" bg="#ffffff">
                <div *ngIf="product_br==true && company_br==false" a-style h=100%>
                    <div a-style p=2.4 gap="2.6" brBottom="0.5px solid #e4e4e4">
                        <ng-container *ngFor="let group of (details?.attributes | keyvalue); let i = index">
                            <!-- <p a-style fs="1.6" (click)="check_details(group.key)"
                                [style.color]="active == group.key? '#2479AB':'#333333'">{{group.key | case :
                                'capital'}}</p> -->
                            <div a-style display="flex" brBottom="0.5px solid #e4e4e4">
                                <p a-style fs="1.6" fw="500" pb="2.4">{{group.key | case : 'capital'}}</p>
                            </div>
                            <ng-container *ngFor="let attribute of (group.value | keyvalue) ">
                                <div a-style mt=2 display="flex">
                                    <!-- keys -->
                                    <div a-style>
                                        <p a-style fs="1.3" class="lineClamp" ellipsis=true w="40.82">{{attribute?.key |
                                            case :
                                            'capital'}}</p>
                                    </div>
                                    
                                    </div>
                            </ng-container>


                        </ng-container>
                    </div>
                    <div a-style h="calc(100% - 8rem)" overflowY="auto" class="no_scroll_bar">
                        <ng-container *ngFor="let group of (details?.attributes | keyvalue)">
                            <ng-container *ngFor="let attribute of group?.value | keyvalue" [ngTemplateOutlet]="Text"
                                [ngTemplateOutletContext]="{attribute: attribute,group:group}">

                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #keyValue let-passedData="passedData">
    <div a-style display="flex" gap="5.9" pl="2.4">
        <p a-style fs="1.3" color="#333333" w="18" align=left mb="1">{{passedData.key}}</p>
        <p a-style fs="1.3" color="#333333" w="74.8" align="left" maxHeight="6" overflowY="auto" class="no_scroll_bar">
            {{passedData.value}}</p>
    </div>
</ng-template>

<ng-template #Text let-attribute="attribute" let-group="group">
    <div a-style mx=2.4 mb=2.4>
        <!-- <div a-style display="flex" flexGap="3" mt=1.6
            *ngIf="attribute?.value?.value && (structure[group?.key][attribute?.key]?.display?.hide_in_public_view == 'No') && group?.key == details_type ">
            <m-text w=40 [type]="gs.enums.texts.paragraph20" fs="1.3"
                *ngIf="(structure[group?.key][attribute?.key]?.display.hide_label)"
                [text]="(structure[group?.key][attribute?.key]?.title)"></m-text>
            <span *ngIf="attribute?.value?.value" a-style display=flex gap=1>
                <m-text [type]="gs.enums.texts.paragraph39"
                    [text]="(attribute?.value?.value || '') + (attribute?.value?.unit || '')+''"></m-text>
            </span>
        </div> -->
    </div>
</ng-template>

<ng-template #companyText let-attribute="attribute" let-group="group">
    <div a-style mx=2.4 mb=2.4 display="flex" flexGap="3" *ngIf=" group?.key == details_type">
        <div a-style mt=1.6>
            <m-text w=32 [type]="gs.enums.texts.paragraph23" fs="1.3" text="{{attribute.key  | case : 'capital'}}">
            </m-text>
        </div>
        <span a-style display="flex" gap=1 mt=1.6>
            <m-text [type]="gs.enums.texts.paragraph39" text={{attribute.value}}></m-text>
        </span>
    </div>
</ng-template>


<ng-template #Group let-group="group">
    <div a-style br="1px solid #E4E4E4" brRadius="0.6" pb=1.6>
        <div a-style h="6" brBottom="1px solid #E4E4E4" p="1.6">
            <m-text [type]="gs.enums.texts.paragraph135" [text]="group.key | case : 'capital'"></m-text>
        </div>
        <ng-container *ngFor="let attribute of group.value | keyvalue" [ngTemplateOutlet]="Text"
            [ngTemplateOutletContext]="{attribute: attribute,group:group}"></ng-container>
    </div>
</ng-template>