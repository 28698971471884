<div a-style w="100%" brRadius=4px mt=5.4 p="2.4">
    <form [formGroup]="form">
        <m-form columns="2" p="1.6" form_display="flex;1024:''" h=6 title="Privilege User" title_mb=0.8 title_mt=0.6 fs="2" labelWeight="400" brBottom="1px solid #e2e2e2" formGroupName="data">
            <m-input [columns]="1/3" [capitalize]="false" [lowercase]="true" flexGrow="0;1024:1" formControlName="username" label="Username" minWidth="5%" required="true"></m-input>
            <m-input [columns]="1/3" flexGrow="0;1024:1" formControlName="name" label="Name" minWidth="5%" required="true"></m-input>
            <m-input [columns]="1/3" flexGrow="0;1024:1" formControlName="title" label="Title" minWidth="5%" required="true"></m-input>
            <m-input [columns]="1" flexGrow="0;1024:1" formControlName="description" type="text_area" label="Description" required="true"></m-input>
            <m-input [columns]="2/4" flexGrow=0 type=media formControlName="image" label="image* " minWidth="5%" required="true" ></m-input>
            <div a-style display="flex" justify="flex-end" w="100%" mt=2.4>
                <m-button [types]="gs.enums.buttons.button32" text="Submit" py=1.2 px=2.4 (click)="submit()" br="none"></m-button>
            </div>
        </m-form>
    </form>
</div>