import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styles: [
  ]
})
export class VehicleCardComponent implements OnInit {

  @Input() type: any;
  @Input() detail: any;
  @Input() owner: any;
  @Input() text: any;
  @Input() src: any;
  @Input() name: any;
  @Input() desc: any;
  @Input() price: any;
  @Input() minWidth: any;
  @Input() maxWidth: any;
  @Input() brTop: any;
  @Output() delete = new EventEmitter();

  icon : Boolean = false;

  list = {
    list : [],
  };

  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) { }

  ngOnInit(): void {
  }

  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  }

  async action(event: any) {
    try {
      if (event == 'print') {
        this.gs.print_component('printSection');
        return;
      }

      let body =   {
        activity: event,
        device: this.gs.device_details,
        language: this.gs.$language.getValue(),
        location: this.gs.localStorageGet('location'),
        parents: {
          data: this.detail,
        }
      }
      let response = await this.gs.hps.post('generic', 'activity', 'add', body);
      // if (event != 'view')
      // this.gs.toast('Activity', 'Activity submitted successfully')
    }
    catch (error:any) {
      this.gs.toast('Security', error?.error?.message, 'error')
      throw error;
    }
  }

  add_to_compare() {
    console.log('Function called', this.detail)
    if (!this.detail) {
      return;
    }
    let current = this.gs.localStorageGet('compare-items') || {};
    current[this.detail._id] = this.detail;
    this.gs.localStorageSet('compare-items', current)
    this.gs.toast('Compare ' + this.detail.application, this.detail.application + ' was added to compare list', 'success', 3)
    // this.current = !this.current
    this.gs.$refresh_compare.next()
  }

}
