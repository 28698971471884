import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { observable } from 'rxjs';
let io = require("socket.io-client");
@Injectable({
  providedIn: 'root'
})
export class NewChatService {
  public socket = io
  selected_chat = new Subject()
  view_count: any = new Subject()
  new_chat_added = new Subject()
  select = new Subject()
  list = new Subject()
  selected_msg = new Subject()
  selected_label = new Subject()
  index = new Subject()

  private text: BehaviorSubject<string>; 
  text$: Observable<string>;
  private currentSelection: string;

  constructor() { 
    this.text = new BehaviorSubject('HElllooo mesbrooo');
    this.text$ = this.text.asObservable();
  }


  changeSelectedText(type:any) {
    let doc = this.text.getValue();
    doc = doc.replace(
      this.currentSelection,
      `<span class="${type}">${this.currentSelection}</span>`
    );
    this.text.next(doc); 
  }

  setSelection(selection: string) {
    this.currentSelection = selection; 
  }






  downloadFile(data: any, filename = 'data'): void {
    console.log(data,);

    let csvData = this.ConvertToCSV(data);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: string) {
    let tempStringfy = JSON.stringify(objArray)
    let splitValue = tempStringfy.split(',').join('\n')
    console.log(splitValue);
    return splitValue;
  }

}
