<div a-style [w]="w?w:'100%'" [maxWidth]="maxWidth?maxWidth:'100%'" [minWidth]="minWidth?minWidth:'100%'"
    [p]="paddingNone?'0':'none'" [m]="m" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [pl]="pl" [pb]="pb" [pt]="pt"
    [px]="px" [br]="brNone?'brNone':'none'" [brRadius]="brRadius? brRadius:'0.6'"
    [brBottom]="brBottom" [bg]="bg?bg:'#ffffff'" [mx]="mx" [my]="my" [overflowX]='overflowX'>
    <div *ngIf="title" a-style display=flex [justify]="justify_title?justify_title:'space-between'">
        <!-- <p a-style [ml]="ml?ml:1" [mt]='title_mt' [mb]='title_mb' [fs]="fs?fs:1.6" [fw]="labelWeight?labelWeight:600"
            color="#333333">{{title}} </p> -->
        <m-button *ngIf="text" [types]="gs.enums.buttons.button18" [text]="text" (click)="clicked.emit()"></m-button>
    </div>
    <div a-style [display]="form_display?form_display:'flex'" [overflowX]="overflowX" class="no_scroll_bar"
        [flexDirection]="flexDirection" [flexWrap]="flexWrap?flexWrap:'wrap'" [gap]="gap?gap:'0'" [px]="list_px"
        [w]="w?w:'100%'">
        <ng-content></ng-content>
    </div>
</div>