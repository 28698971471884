<div *ngIf="attachment?.content_type!='application/pdf'">
    <div a-style position=relative (mouseover)="changeText=true" (mouseout)="changeText=false">
        <img a-style h="11.6" w="8.7" cursor="pointer" src="../assets/images/assets/mail-image.svg"
            (click)="preview(attachment)">
        <p a-style fs=.8 position=absolute top=9.2 left=3.5 color=#FFFFFF> {{attachment?.size/1048576 | number :
            '1.2-2'}} MB
        </p>
    </div>
    <div *ngIf="is_visible" top="0" left="0" zIndex="1000" a-style bg="rgb(84 98 88 / 35%)" display="flex"
        justify="center" alignItems="center" position="fixed" h="100vh" w="100vw">
        <div a-style position="relative" align=center>
            <img src="https://new-api.mesbro.com/uploads/{{attachment?.url}}" a-style h=50% w=50% br="1px solid #E4E4E4"
                brRadius="0.4" v>
            <div a-style w="2.5" h="2.5" bg="hov:gray" brRadius="50%" cursor=pointer display="grid"
                (click)="closeButton()" placeContent="center" position="absolute" right=39 top=1.6 bg=gray>
                <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" a-style w="1" h="1">
            </div>
        </div>
    </div>
</div>


<!-- pdf -->
<div *ngIf="attachment?.content_type=='application/pdf'">
    <div a-style position=relative (mouseover)="changeText=true" (mouseout)="changeText=false">
        <img a-style h="11.6" w="8.7" cursor="pointer" src="../assets/images/pdfImage.svg"
            (click)="preview(attachment)">
        <p a-style fs=.8 position=absolute top=9.2 left=3.5 color=#FFFFFF> {{attachment.size/1048576 | number :
            '1.2-2'}} MB
        </p>
    </div>
    <div *ngIf="is_visible1" top="0" left="0" zIndex="1000" a-style bg="rgb(84 98 88 / 35%)" display="flex"
    justify="center" alignItems="center" position="fixed" h="100vh" w="100vw">
    <div a-style position="relative" align=center>
        <!-- <img src="https://new-api.mesbro.com/uploads/{{attachment?.url}}" a-style h=50% w=50% br="1px solid #E4E4E4"
            brRadius="0.4" v> -->
                <embed src=imageurl type="application/pdf"  height="700px" width="500"/>
            <div a-style w="2.5" h="2.5" bg="hov:gray" brRadius="50%" cursor=pointer display="grid"
                (click)="closeButtonpdf()" placeContent="center" position="absolute" right=39 top=1.6 bg=gray>
                <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" a-style w="1" h="1">
            </div>
        </div>
    </div>
</div>