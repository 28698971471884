import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faq'
})
export class FaqPipe implements PipeTransform {

  transform(list: any, id:any ): any {

   list =  list.filter((faq:any)=> faq.questionId == id )
   
   return list.reverse()
  }

}
