import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-add-mail-template',
  templateUrl: './add-mail-template.component.html',
  styles: [
  ]
})
export class AddMailTemplateComponent implements OnInit {
  @Input() modal: any
  @Output() folder_added = new EventEmitter()
  queryParams: any = {
    _id: ''
  }
  form: any;
  form_data = {
     text: '',
     html: '',
     shortText: '',
  }

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute){ }

  ngOnInit(): void {

    // this.initializeForm()
    this.ar.queryParams.subscribe((params: any) => this.handleParams(params))
  }
  // initializeForm() {
  //   throw new Error('Method not implemented.');
  // }

  handleParams = async (params:any) => {
    this.queryParams = params
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'data', params._id);
      this.form?.patchValue(details)

    }
  }

  async Save() {
    let body = this.form?.value
    body.action='template',
    body.type='mail'
    let response = await this.gs.hps.post('generic', 'conversation', 'template', body)
    this.folder_added.emit(body);
    this.modal.closeModal()
  }

}
