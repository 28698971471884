<div a-style mx=2.4 mt=7.4 br="1px solid #e4e4e4" brRadius=0.6 bg="#ffffff">
  <div a-style brBottom="1px solid #e4e4e4" p=2.4>
      <m-text [type]="gs.enums.texts.paragraph12" text="Shortlink List {{list?.count}}"></m-text>
  </div>
  <div a-style w=100% overflowX="auto;460:scroll" class="no_scroll_bar">
      <m-form form_display="flex" flexDirection="column" brNone>
          <div a-style p=0.8 w=100%>
              <m-input flexGrow=none label="Search" minWidth="35;450:30" maxWidth="450:30" px=0 flexGrow="0;1024:1;1280:1;1336:1" [columns]="1/4" cleareIcon="true"></m-input>
          </div>
          <div a-style p=0.8> 
              <table a-style br="none">
                  <tr>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text>
                      </th>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Shortlink Logo"></m-text>
                      </th>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Shortlink URL"></m-text>
                      </th>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Updated At"></m-text>
                      </th>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Edit"></m-text>
                      </th>
                      <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text>
                      </th>
                  </tr>
                  <tr *ngFor="let detail of list?.list; let i=index">
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph35" text="{{i+1}}"></m-text>
                      </td>
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-image  [src]="'https://new-api.mesbro.com/uploads/'+detail?.meta?.activity?.updated_by?.profile_picture" w="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" h="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" my="1.6" mx="2.4"> </m-image>
                      </td>
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph35" text="{{detail?.shortUrl}}"></m-text>
                      </td>
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
                          <m-text [type]="gs.enums.texts.paragraph35" text=" {{detail?.meta?.activity?.created_at | date: 'dd/MM/yyyy  \'at\' HH:mm:ss' }} "></m-text>
                      </td>
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" p="1">
                         <m-image  (click)="gs.router.navigate(['/short-link/home'],{queryParams:{_id:detail._id}})" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVhnUBDsSFZy2lScmmUXBfDePZvaFDBGC5KMxos6LaPAlIknifNst2K3AkOJKAPTuwfYpSxA9Rw5kUmQ&usqp=CAU" h="1.5" w="1.5"></m-image>
                      </td>
                      <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" p="1">
                          <m-image (click)="delete(detail._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
                      </td>
                  </tr>
              </table>
          </div>
      </m-form>
  </div>
</div>

