<!-- Request For Quatation page 8 -->

<div *ngIf="query_params.type=='rfqHome'" a-style py="4" px="2.5" align="center">
    <m-text [type]="gs.enums.texts.paragraph73" a-style text="Request For Quotation"></m-text>
    <m-text [type]="gs.enums.texts.paragraph1" mt="1.2" a-style text="Get Quotes for your Buying Requirement. Tell suppliers what you want"></m-text>
    <div a-style py="2.4" display="flex" justify="center" flexGap="2.4">
        <ng-container [ngTemplateOutlet]="rfqCard" [ngTemplateOutletContext]="{passedData : {text :'Im a Buyer', text2:'A buyer is a person who is buying something or who  intends to buy it and raises an Request for Quotation  for those products.' , text3:'Request For Quotation' ,link:'/product/rfq-form',image:'https://mesbro.in/assets/images/rfq-buyer.svg', queryParams :{step:'Primary',application:'Product'}}}"></ng-container>
        <ng-container [ngTemplateOutlet]="rfqCard" [ngTemplateOutletContext]="{passedData : {text :'Im a Supplier' , text2:'A supplier is a person, company, or organization that sells or supplies something such as goods or equipment to customers and reply to those Request for Quotation.' , text3:'View RFQs' ,link:'/product/rfq',image:'https://mesbro.in/assets/images/rfq-supplier.svg' , queryParams :{type:'rfqList'}}}"></ng-container>
    </div>
</div>
<ng-template #rfqCard let-passedData="passedData">
    <div a-style br="1px solid rgba(228, 228, 228, 0.47)" brRadius="0.6" w="52.1" px="2.4" py="2.4" shadow="hov:0 3px 6px 0 #f1f1f1">
        <m-text [type]="gs.enums.texts.paragraph73" a-style text="{{passedData.text}}"></m-text>
        <m-image a-style mt="2.4" w="6" h="6" src={{passedData.image}}> </m-image>
        <m-text [type]="gs.enums.texts.paragraph1" a-style mt="2.5" text="{{passedData.text2}}"></m-text>
        <m-button [types]="gs.enums.buttons.button18" [routerLink]="passedData.link" [queryParams]="passedData.queryParams" px="2.9" py="1.3" align="center" mt="2.4" br="none" a-style text="{{passedData.text3}}"></m-button>
    </div>
</ng-template>
<div *ngIf="query_params.type=='rfqList'" a-style align="center">
    <m-text [type]="gs.enums.texts.paragraph73" pb="1.2" mt="2.5" a-style text="Most Recent RFQs from Various Industries"></m-text>
    <m-text [type]="gs.enums.texts.paragraph1" mt="1.2" a-style text="Get access to quotations from the most suitable suppliers for your business"></m-text>
    <div a-style px="2.5" mt="2.5">
        <div a-style px="1.2" overflowX="auto" display="flex" brRadius="0.5" br="1px solid #E4E4E4" class='no_scroll_bar'>
            <ng-container [ngTemplateOutlet]="categories" [ngTemplateOutletContext]="{item:title}" *ngFor="let title of rfq_component_items"></ng-container>
        </div>
    </div>
    <div a-style br="1px solid #E4E4E4" mx="2.5" my="2.5" brRadius="0.5" px="2.5" py="2.5">
        <m-text [type]="gs.enums.texts.heading1" a-style text="Request For Quotation List"></m-text>
        <div a-style mt="2.4">
            <!-- <ng-container [ngTemplateOutlet]="rfqCard2" *ngFor="let rfq of list.list let i=index" [ngTemplateOutletContext]="{passedData : rfq}"></ng-container> -->
            <m-generic-list application="Product" component="Lead" type="rfq_card_3" gap="2.4" wrap="wrap"></m-generic-list>
        </div>
    </div>
</div>
<ng-template #categories let-item=item>
    <div a-style w="24" py="1.5" px="1.5" display="flex" align="center" justify="center">
        <div a-style p="1" w="17">
            <m-image *ngIf="item.image" [src]='item.image' w="10" h="10" pb="2.4"></m-image>
            <m-text [type]="gs.enums.texts.paragraph1" a-style mt="2.4" textWrap="wrap" [text]='item.title'></m-text>
        </div>
    </div>

</ng-template>
<div *ngIf="query_params.type=='rfqNotFound'" a-style my="2.5" mx="2.5" br="1px solid #e4e4e4" px="2.4" pb="1.7" pt="2.4" brRadius="0.4">
    <div a-style display="flex" justify="space-between" pb="1">
        <div a-style display="flex" align="center" gap="3.2">
            <ng-container *ngFor="let title of ['Product','My Products','Saved Products','My Organization','My RFQs']" [ngTemplateOutlet]="tabHeading" [ngTemplateOutletContext]="{data:title}"></ng-container>
        </div>
    </div>
    <hr a-style color="#E4E4E4" fs="0.2">
    <div a-style align="center" justify="center" py="8">
        <m-image a-style w="63.3" h="38.0" mb="3.2" bgRepeat="no-repeat" bgSize="cover" [src]="gs.enums.images.image31"> </m-image>
        <m-text [type]="gs.enums.texts.paragraph24" a-style text="You still not raise RFQ's"></m-text>
        <m-button a-style py="0.8" px="2" brRadius="0.4" text="Raise RFQ"></m-button>
    </div>
</div>
<ng-template #tabHeading let-heading="data">
    <m-text [type]="gs.enums.texts.paragraph35" a-style text="{{heading}}"></m-text>
</ng-template>



<!-- <m-modal #QuoteNow>
    <ng-container [ngTemplateOutlet]="modelQuoteNow"> </ng-container>
</m-modal>
<m-modal #requestSubmittedModal>
    <ng-container [ngTemplateOutlet]="requestSubmitted"> </ng-container>
</m-modal> -->