<div>
  <div *ngIf="params.type=='product' && params.typeId">
    <m-product-header></m-product-header>
    <div a-style display="flex" gap="2.4" flexWrap="wrap" p="2.4">
    <ng-container>
      <div *ngFor="let item of productLists?.list">
        <m-product-card type="test" [detail]="item" ></m-product-card>
      </div>
    </ng-container>
  </div>
  </div> 
  <div *ngIf="params.type=='recipe'">
    <m-recipe-header></m-recipe-header>
    <div  a-style display="flex" gap="2.4" flexWrap="wrap" px="4" py="6.4" >
    <ng-container >
      <div  *ngFor="let item of recipeLists?.list">
        <m-generic-card [detail]="item" type="recipecard"  ></m-generic-card>
      </div>
    </ng-container>
  </div>
  </div>
  <div *ngIf="params.type == 'spirituals'">
    <div  a-style h="7.2"  bg="#F8F8F8" br="0.5 solid #E4E4E4" shadow="0px 3px 6px #17171729"  >
      <div  a-style h="7.2"  br="0.5px solid #E4E4E4" display="flex" align="center"
          justify="center" gap="2.5" >
          <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/spiritual/home'])" [style.color]="gs.router.url.includes('/spiritual/home')?'#2479AB':'black'" cursor="pointer">
              <!-- <img *ngIf="!gs.router.url.includes('/product/home')" src="../assets/images/ME/Home.svg" a-style w="2.6"
                  h="2.6" routerLink="/product/home"> -->
              <img  a-style h=2.6 w=2.6 
                  src="../assets/images/Icon_Bank/Home_Coloured.svg">
              <p a-style fs="1.4">Home</p>    
          </div>
          <div a-style display="flex" align="center" gap="1.6" [style.color]="gs.router.url.includes('/spiritual/category')?'#2479AB':'black'" (click)="gs.router.navigate(['/spiritual/category'],{queryParams:{category:'type',type:'product'}})"> 
              <img  src="../assets/images/Icon_Bank/Category_Coloured.svg"
                  a-style w="2.6" h="2.6" cursor="pointer" >
              <p a-style fs="1.4" cursor="pointer" >Categories</p>
          </div>
          <div a-style display="flex" align="center" gap="1.6" [style.color]="gs.router.url.includes('/spiritual/fav')?'#2479AB':'black'" (click)="gs.router.navigate(['/spiritual/fav'],{queryParams:{category:'type',type:'product'}})"> 
              <img  src="../assets/images/Icon_Bank/Rating_Coloured.svg"
                  a-style w="2.6" h="2.6" cursor="pointer" >
              <p a-style fs="1.4" cursor="pointer" >Favourite</p>
          </div>
          <div a-style display="flex" align="center" gap="1.6" [style.color]="gs.router.url.includes('/spiritual/save')?'#2479AB':'black'" (click)="gs.router.navigate(['/spiritual/save'],{queryParams:{category:'type',type:'product'}})"> 
              <img  src="../assets/images/Icon_Bank/Save_Coloured.svg"
                  a-style w="2.6" h="2.6" cursor="pointer" >
              <p a-style fs="1.4" cursor="pointer" >My Collection</p>
          </div>
          <div a-style display="flex" align="center" gap="1.6"  [style.color]="gs.router.url.includes('/spiritual/history')?'#2479AB':'black'" (click)="gs.router.navigate(['/spiritual/history'])">
              <img src="../assets/images/Icon_Bank/History_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
              <p a-style fs="1.4" cursor="pointer">History</p>
          </div>
      </div>
  </div>
    <div class="no_scroll"  a-style display="flex" gap="3.4" flexWrap="wrap" px="4" py="6.4" h="calc(100vh - 22.8rem)"  overflowX="auto">
    <ng-container >
      <div  *ngFor="let item of spiritualLists?.list">
        <m-generic-card [detail]="item" type="spiritualcard"></m-generic-card>
      </div>
    </ng-container>
  </div>
  </div>
  <div *ngIf="params.query" >
    <m-product-header></m-product-header>
    <div  a-style display="flex" gap="2.4" flexWrap="wrap" px="4" py="6.4" >
      <div  *ngFor="let item of searchList?.list">
        <m-product-card type="test"  [detail]="item"  ></m-product-card>
      </div>
  </div>
  </div>
</div>
