import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-collaborator',
  templateUrl: './collaborator.component.html',
  styles: [
  ]
})
export class CollaboratorComponent implements OnInit {

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

}
