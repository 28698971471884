<div a-style mx=2.4 mb=2.4 mt=7.4 brRadius=0.6 bg="#ffffff">
    <div a-style brBottom="1px solid #e4e4e4" p=2.4>
        <m-text [type]="gs.enums.texts.paragraph12" text="Brand List {{list?.count}}"></m-text>
    </div>
    <div>
        <div a-style p=2.4 w=100%>
            <m-input formControlName="search" flexGrow=none label="Search" minWidth="35;450:30" maxWidth="450:30" px=0 flexGrow="0;1024:1;1280:1;1336:1" [columns]="1/4" cleareIcon="true"></m-input>
        </div>
        <div a-style p=2.4 > 
            <table a-style br="none">
                <tr>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="First Name"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Last Name"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Activity"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Description"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Updated At"></m-text></th>
                    <th a-style align="center" br="1px solid #e4e4e4" p="1"><m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text></th>
                </tr>
                <tr *ngFor="let feedback of list?.list; let i=index">
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text="{{i+1}}"></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text="{{feedback.data?.first_name}}"></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text="{{feedback.data?.last_name}}"></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text=" {{feedback?.activity}} "></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text=" {{feedback.data?.description}}" ellipsis=true lineClamp=2></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-text [type]="gs.enums.texts.paragraph49" text=" {{feedback.meta.activity?.updated_at | date : 'dd MMM YYYY'}}"></m-text>
                    </td>
                    <td a-style align="center" br="1px solid #e4e4e4" p="1">
                        <m-image (click)="delete(feedback._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>