import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';
import { search_items } from './search';
import { faq_items } from './search';
import { number_items } from './search';

@Component({
  selector: 'm-generic-search',
  templateUrl: './generic-search.component.html',
  styles: [
  ]
})
export class GenericSearchComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'
  users: IUserData | undefined;

  details: any = undefined;
  // list = {
  //   list: [],
  //   count: 0
  // }
  isSearch : boolean = false
  search_items = search_items;
  faq_items = faq_items;
  number_items = number_items;
  search: any
  is_visible = {
    faq_answer: false
  }
  params: any = {
    type: undefined,
    query: '',
    application: '',
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit() {
   this.ar.queryParams.subscribe((params : any ) => {
   this.params = params
   this.isSearch = false
   setTimeout(() => {
    this.isSearch = true
}, 100);
   
   }) 


// this.ar.queryParams.subscribe((params : any) => {
// this.params = params
// this.isSearch = false
// setTimeout(() => {
//   this.isSearch = true
// }, 100);
// })
  }



}


