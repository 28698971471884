<div a-style display=flex>
    <ng-container [ngTemplateOutlet]="apps" [ngTemplateOutletContext]="{passedData : {text : 'No Wallpaper' , path:'https://mesbro.in/assets/images/one-call.svg' }}"> </ng-container>
    <ng-container [ngTemplateOutlet]="apps" [ngTemplateOutletContext]="{passedData : {text : 'Gallery' , path:'https://mesbro.in/assets/images/one-call.svg' }}"></ng-container>
    <ng-container [ngTemplateOutlet]="apps" [ngTemplateOutletContext]="{passedData : {text : 'Solid Colour' , path:'https://mesbro.in/assets/images/one-call.svg' }}"> </ng-container>
    <ng-container [ngTemplateOutlet]="apps" [ngTemplateOutletContext]="{passedData : {text : 'System Default' , path:'https://mesbro.in/assets/images/one-call.svg' }}"></ng-container>
</div>
<ng-template #apps let-passedData="passedData">
    <div a-style align="center" mx="1.2" py="0.9">
        <div a-style>
            <m-image src={{passedData.path}} a-style h="5.5" w="5.5"></m-image>
        </div>
        <m-text [type]="gs.enums.texts.paragraph1" mt=1.6 text={{passedData.text}}></m-text>
    </div>
</ng-template>
<div a-style display="flex" justify="flex-end" mt=2.4 flexGap=1.6 align=center>
    <m-text [type]="gs.enums.texts.paragraph1" mt=0 text="Cancel" (click)="modal.closeModal()"></m-text>
    <m-button [types]="gs.enums.buttons.button8" text="Confirm"></m-button>
</div>