<div a-style w="100%" brRadius=0.6 cursor=pointer mt=2>
    <form [formGroup]="form">
        <div a-style br="1px solid #E4E4E4 " brRadius="0.6">
            <div (click)="is_visible.Enquiry_answer = true" a-style display="flex" align="center" justify="space-between" p=1.6 brBottom="1px solid #E4E4E4">
                <m-text [type]="gs.enums.texts.paragraph26" text="Enquiry"></m-text>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAElBMVEX///8AAACWlpYwMDCfn58iIiIKqGLNAAAAf0lEQVR4nO3ZwRWAIAxEQRTov2Vb8OXiBmcqyL9uxgAAAAAAAAAAAAAAAAAAAAAAAAAAAOCX1t3DKhfuq4ddLpxfn/7SVKgwnkKF+RQqzKdQYT6FCvMpVJhPocJ8ChXmqxeev3mf/7cAAAAAAAAAAAAAAAAAAAAAAAAAAACgtQfadBT0iTJOWQAAAABJRU5ErkJggg==" a-style w=1.4 h=1.4 >
            </div>
            <div a-style *ngIf="is_visible.Enquiry_answer">
                <m-form columns="3" p="1.6" h=6 a-style [brNone]=false formGroupName="data">
                    <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Name" formControlName="name" label="Name" minWidth="5%" required="this.gs.user.users?.activeUser"></m-input>
                    <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Phone Number" formControlName="phone_number" label="Phone Number *" minWidth="5%"></m-input>
                    <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Email Address" formControlName="email_address" label="Email Address *" minWidth="5%"></m-input>
                    <m-input [columns]="1/3" formControlName="title" label="Enquiry Subject " minWidth="5%"></m-input>
                    <m-input [columns]="1/3" formControlName="quantity" label="Quantity " minWidth="5%"></m-input>
                    <m-input [columns]="1/3" formControlName="unit" label="Unit " minWidth="5%"></m-input>
                    <m-input formControlName="description" [columns]="1" label="Description *" [type]="gs.enums.inputs.text_area"></m-input>
                    <div a-style display="flex" justify="center" w="100%" mt=1>
                        <m-button [disable]="form?.invalid" [types]="gs.enums.buttons.button18" text="I'm Interested" py=1.2 px=2.4 (click)="submit()" br="none"></m-button>
                    </div>
                </m-form>
            </div>
        </div>
    </form>
</div>

<div a-style *ngIf="is_visible.Enquiry_answer">
    <m-form columns="3" p="1.6" h=6 a-style [brNone]=false formGroupName="data">
        <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Name" formControlName="name" label="Name" minWidth="5%" required="this.gs.user.users?.activeUser"></m-input>
        <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Phone Number" formControlName="phone_number" label="Phone Number *" minWidth="5%"></m-input>
        <m-input [disabled]='this.gs.user.users?.activeUser' [columns]="1/3" placeHolder="Email Address" formControlName="email_address" label="Email Address *" minWidth="5%"></m-input>
        <m-input [columns]="1/3" formControlName="title" label="Enquiry Subject " minWidth="5%"></m-input>
        <m-input [columns]="1/3" formControlName="quantity" label="Quantity " minWidth="5%"></m-input>
        <m-input [columns]="1/3" formControlName="unit" label="Unit " minWidth="5%"></m-input>
        <m-input formControlName="description" [columns]="1" label="Description *" [type]="gs.enums.inputs.text_area"></m-input>
        <div a-style display="flex" justify="center" w="100%" mt=1>
            <m-button [disable]="form?.invalid" [types]="gs.enums.buttons.button18" text="I'm Interested" py=1.2 px=2.4 (click)="submit()" br="none"></m-button>
        </div>
    </m-form>
</div>