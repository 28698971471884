import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

const encryptSecretKey = 'my-key';

export const encryptData = (data: any, encryptSecretKey?: string) => {
    try {
        return data;
    } catch (e) {
        return e;
    }
}

export const decryptData = (data: any, encryptSecretKey: string) => {
    try {
        return data;
    } catch (e) {
        return e;
    }
}

export const localStorageGet = (key: any) => {
    if (typeof window !== "undefined" && window?.localStorage) {
        let stringItem = localStorage.getItem(key);
        if (stringItem) {
            stringItem = decryptData(stringItem, encryptSecretKey);
            if (stringItem) {
                return JSON.parse(stringItem);
            }
            else {
                return undefined;
            }
        }
        return undefined;
    }
}
export const localStorageSet = (key: any, value: any) => {
    let stringItem: any = JSON.stringify(value)
    stringItem = encryptData(stringItem, encryptSecretKey);
    localStorage.setItem(key, stringItem);
}

export const localStorageRemove = (key: any) => {
    localStorage.removeItem(key);
}

export const get_control = (form: FormGroup, controls: any[]) => {
    let control_reference: AbstractControl | null | undefined = form;
    controls.forEach(control => {
        control_reference = control_reference?.get(control);
    });
    return control_reference;
}

export const push_control_in_array = (form: FormGroup, controls: any[], control: AbstractControl) => {
    let control_reference = <FormArray>get_control(form, controls);
    control_reference.push(control);
}

export const remove_control_in_array = (form: FormGroup, controls: any[], i: number) => {
    let control_reference = <FormArray>get_control(form, controls);
    control_reference.removeAt(i);
}

export const random_number = () => {
  return Math.floor((Math.random() * 100) + 1);
}
