import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-send-offer',
  templateUrl: './send-offer.component.html',
  styles: [
  ]
})
export class SendOfferComponent implements OnInit {
  form: FormGroup | undefined;

  application = 'generic';
  component = 'conversation';

  list_url = `/dashboard/${this.application}/${this.component}/list`;

  constructor(public fb: FormBuilder , public gs: GlobalService ) { }

  ngOnInit(): void {
    this.initialize_form();
  }

  
  initialize_form() {
    this.form = this.fb.group({
      application: this.application,
      component: this.component,
       firstName: [null],
       lastName: null,
       address: null,
       productType: null,
       description: null,
       quantity: null,
       price: null,
       type: 'quotation'
     });
   }

   async submit() {
    let body = this.form?.value
    let response = await this.gs.hps.post(this.application, this.component, 'add', body);
  }


}