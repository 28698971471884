import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sideMenuPermission'
})
export class SideMenuPermissionPipe implements PipeTransform {

  transform(application: string, permission: string, permission_object: any): unknown {
    if (application || permission) {
      return permission_object?.[application]?.[permission] || false
    } else {
      return true;
    }
  }

  constructor() { }
}
