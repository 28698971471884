<div >
<p a-style fs="2" color="#2479AB">B2B Product Card</p>
<div a-style display="flex" flexWrap="Wrap" gap="3" >

    <div a-style   mt="2.4" h="36"  >
     <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1">
        <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
         <div align="left">
              <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
              <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
            <div a-style display="flex" justify="space-between" mt="0.6">
                   <div a-style display="flex" gap="0.3">
                       <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                    </div>
                      <img src="../assetimages/s/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
            </div>
        </div>
      </div>
      <p a-style fs="1.6" mt="2" align="center" >Default Public View.</p>
    </div>

     <div a-style   mt="2.4" h="36">
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
           <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <img src="../assets/images/Card Icon/AD Icon.svg" a-style w="2.6" h="1.6" position="absolute" top="2" left="2"  >
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Advertisement Card</p>
       </div>

       <div a-style   mt="2.4" h="36"  >
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
             <img src="../assets/images/Card Icon/Toggle button on - active card icon.svg" a-style w="3.4" h="2"  >
             <div a-style display="flex" gap="1"  >
                <img src="../assets/images/Card Icon/Edit Icon.svg" a-style  >
                <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  >
             </div>
           </div>
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. hgbvjbb </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Active Card</p>
       </div>

       <div a-style   mt="2.4" h="36" >
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
             <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2"  >
             <img src="../assets/images/Card Icon/Delete Icon.svg" >
           </div>
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Deactive Card</p>
       </div>

       <div a-style   mt="2.4" h="36" >
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  position="absolute" top="2" right="2"  >
           <img src="../assets/images/Card Icon/Block Icon.svg" a-style  position="absolute" top="9.3" right="6.9"  >
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Blocked card</p>
       </div>

       <div a-style   mt="2.4" h="36" >
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
             <img src="../assets/images/Card Icon/toggle button on - website view allow icon.svg" a-style w="3.4" h="2"  >
             <img src="../assets/images/Card Icon/Delete Icon.svg" >
           </div>
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Website View Allow Card</p>
     </div>

       <div a-style   mt="2.4" h="36" >
        <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
             <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2.4"  >
             <img src="../assets/images/Card Icon/Delete Icon.svg" >
           </div>
            <div align="left">
                 <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                 <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
               <div a-style display="flex" justify="space-between" mt="0.6">
                      <div a-style display="flex" gap="0.3">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                          <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                       </div>
                         <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
               </div>
           </div>
         </div>
         <p a-style fs="1.6" mt="2" align="center" >Website View Disallow Card</p>
       </div>
</div>

</div>

<div a-style mt="5" >
  <p a-style fs="2" color="#2479AB">Used Product Card</p>
  <div a-style display="flex" flexWrap="Wrap" gap="3" >
  
      <div a-style   mt="2.4" h="36"  >
       <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1">
          <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
           <div align="left">
                <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
              <div a-style display="flex" justify="space-between" mt="0.6">
                     <div a-style display="flex" gap="0.3">
                         <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                      </div>
                        <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
              </div>
          </div>
        </div>
        <p a-style fs="1.6" mt="2" align="center" >Default Public View.</p>
      </div>
  
       <div a-style   mt="2.4" h="36">
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
             <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <img src="../assets/images/Card Icon/AD Icon.svg" a-style w="2.6" h="1.6" position="absolute" top="2" left="2"  >
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Advertisement Card</p>
         </div>
  
         <div a-style   mt="2.4" h="36"  >
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
               <img src="../assets/images/Card Icon/Toggle button on - active card icon.svg" a-style w="3.4" h="2"  >
               <div a-style display="flex" gap="1"  >
                  <img src="../assets/images/Card Icon/Edit Icon.svg" a-style  >
                  <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  >
               </div>
             </div>
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. hgbvjbb </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Active Card</p>
         </div>
  
         <div a-style   mt="2.4" h="36" >
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
               <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2"  >
               <img src="../assets/images/Card Icon/Delete Icon.svg" >
             </div>
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Deactive Card</p>
         </div>
  
         <div a-style   mt="2.4" h="36" >
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  position="absolute" top="2" right="2"  >
             <img src="../assets/images/Card Icon/Block Icon.svg" a-style  position="absolute" top="9.3" right="6.9"  >
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Blocked card</p>
         </div>
  
         <div a-style   mt="2.4" h="36" >
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
               <img src="../assets/images/Card Icon/toggle button on - website view allow icon.svg" a-style w="3.4" h="2"  >
               <img src="../assets/images/Card Icon/Delete Icon.svg" >
             </div>
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Website View Allow Card</p>
       </div>
  
         <div a-style   mt="2.4" h="36" >
          <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
               <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2.4"  >
               <img src="../assets/images/Card Icon/Delete Icon.svg" >
             </div>
              <div align="left">
                   <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                   <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                 <div a-style display="flex" justify="space-between" mt="0.6">
                        <div a-style display="flex" gap="0.3">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                            <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                         </div>
                           <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                 </div>
             </div>
           </div>
           <p a-style fs="1.6" mt="2" align="center" >Website View Disallow Card</p>
         </div>
  </div>
  
  </div>

<div a-style mt="5" >
    <p a-style fs="2" color="#2479AB">Refurbished Product Card</p>
    <div a-style display="flex" flexWrap="Wrap" gap="3" >
    
        <div a-style   mt="2.4" h="36"  >
         <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1">
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div align="left">
                  <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                  <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                <div a-style display="flex" justify="space-between" mt="0.6">
                       <div a-style display="flex" gap="0.3">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                        </div>
                          <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                </div>
            </div>
          </div>
          <p a-style fs="1.6" mt="2" align="center" >Default Public View.</p>
        </div>
    
         <div a-style   mt="2.4" h="36">
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
               <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
                 <img src="../assets/images/Card Icon/AD Icon.svg" a-style w="2.6" h="1.6" position="absolute" top="2" left="2"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Advertisement Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36"  >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button on - active card icon.svg" a-style w="3.4" h="2"  >
                 <div a-style display="flex" gap="1"  >
                    <img src="../assets/images/Card Icon/Edit Icon.svg" a-style  >
                    <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  >
                 </div>
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. hgbvjbb </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Active Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Deactive Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  position="absolute" top="2" right="2"  >
               <img src="../assets/images/Card Icon/Block Icon.svg" a-style  position="absolute" top="9.3" right="6.9"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Blocked card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/toggle button on - website view allow icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Allow Card</p>
         </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2.4"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Disallow Card</p>
           </div>
    </div>
    
  </div>

  <div a-style mt="5" >
    <p a-style fs="2" color="#2479AB">Nursery Product Card</p>
    <div a-style display="flex" flexWrap="Wrap" gap="3" >
    
        <div a-style   mt="2.4" h="36"  >
         <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1">
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div align="left">
                  <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                  <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                <div a-style display="flex" justify="space-between" mt="0.6">
                       <div a-style display="flex" gap="0.3">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                        </div>
                          <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                </div>
            </div>
          </div>
          <p a-style fs="1.6" mt="2" align="center" >Default Public View.</p>
        </div>
    
         <div a-style   mt="2.4" h="36">
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
               <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
                 <img src="../assets/images/Card Icon/AD Icon.svg" a-style w="2.6" h="1.6" position="absolute" top="2" left="2"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Advertisement Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36"  >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button on - active card icon.svg" a-style w="3.4" h="2"  >
                 <div a-style display="flex" gap="1"  >
                    <img src="../assets/images/Card Icon/Edit Icon.svg" a-style  >
                    <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  >
                 </div>
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. hgbvjbb </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Active Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Deactive Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  position="absolute" top="2" right="2"  >
               <img src="../assets/images/Card Icon/Block Icon.svg" a-style  position="absolute" top="9.3" right="6.9"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Blocked card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/toggle button on - website view allow icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Allow Card</p>
         </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2.4"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Disallow Card</p>
           </div>
    </div>
    
  </div>


  <div a-style mt="5" >
    <p a-style fs="2" color="#2479AB">Scrap Product Card</p>
    <div a-style display="flex" flexWrap="Wrap" gap="3" >
    
        <div a-style   mt="2.4" h="36"  >
         <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1">
            <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
             <div align="left">
                  <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                  <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                <div a-style display="flex" justify="space-between" mt="0.6">
                       <div a-style display="flex" gap="0.3">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                        </div>
                          <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                </div>
            </div>
          </div>
          <p a-style fs="1.6" mt="2" align="center" >Default Public View.</p>
        </div>
    
         <div a-style   mt="2.4" h="36">
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
               <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
                 <img src="../assets/images/Card Icon/AD Icon.svg" a-style w="2.6" h="1.6" position="absolute" top="2" left="2"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Advertisement Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36"  >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button on - active card icon.svg" a-style w="3.4" h="2"  >
                 <div a-style display="flex" gap="1"  >
                    <img src="../assets/images/Card Icon/Edit Icon.svg" a-style  >
                    <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  >
                 </div>
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. hgbvjbb </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Active Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Deactive Card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <img src="../assets/images/Card Icon/Delete Icon.svg" a-style  position="absolute" top="2" right="2"  >
               <img src="../assets/images/Card Icon/Block Icon.svg" a-style  position="absolute" top="9.3" right="6.9"  >
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Blocked card</p>
           </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/toggle button on - website view allow icon.svg" a-style w="3.4" h="2"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Allow Card</p>
         </div>
    
           <div a-style   mt="2.4" h="36" >
            <div a-style h="32.2" w="24.0" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column" gap="1" position="relative" >
              <img src="../assets/images/Card Icon/Product Image.svg" a-style w="22" h="22" br="0.5px solid #e4e4e4" brRadius="6px" >
               <div a-style display="flex" align="center" justify="space-between" position="absolute" top="2" right="1" left="2" w="20"  > 
                 <img src="../assets/images/Card Icon/Toggle button off - deactive card icon.svg" a-style w="3.4" h="2.4"  >
                 <img src="../assets/images/Card Icon/Delete Icon.svg" >
               </div>
                <div align="left">
                     <p a-style fs="1.3" color=" #2479AB">Lorem ipsum dolor sit amet</p>
                     <p  class="lineClamp2" [ellipsis]=true a-style fs="1.3" >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed. </p>
                   <div a-style display="flex" justify="space-between" mt="0.6">
                          <div a-style display="flex" gap="0.3">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                              <img src="../assets/images/Card Icon/rating star icon.svg" a-style w="1.2" h="1.2">
                           </div>
                             <img src="../assets/images/Card Icon/Flag faded icon.svg" a-style w="2" h="1.2">
                   </div>
               </div>
             </div>
             <p a-style fs="1.6" mt="2" align="center" >Website View Disallow Card</p>
           </div>
    </div>
    
  </div>
    
  
