import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styles: [
  ]
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() description: string = '';
  @Input() text: string = '';
  @Input() inputstitle: string = '';
  @Input() height: number = 0;
  @Input() width: number = 50;
  @Input() isVisible: boolean = false;
  @Input() button1: string = 'Cancel';
  @Input() button2: string = 'Confirm';
  @Input() checkText: string = '';
  @Input() button3: boolean = true;
  @Input() button4: boolean = true;
  @Input() space: string = '';
  @Output() snooze = new EventEmitter();
  @Output() ok = new EventEmitter()
  listener: any;
  constructor(public renderer: Renderer2, public gs: GlobalService) {
    this.listener = this.renderer.listen('document', 'keyup', (event) => {
      if (event && event.key == 'Escape') {
        this.closeModal()
      }
    })
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    if (this.listener) {
      this.listener();

    }
  }
  openModal() {
    this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
  }

  toggleModal() {
    this.isVisible = !this.isVisible;
  }

  delete(){
    console.log('aaa');
    
  }
}
