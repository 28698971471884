<div a-style  bg="rgb(0,0,0,0.3)" zIndex="1000"  w="100vw" h="100vh" position="fixed" top="0">
    <div class="no_scroll" a-style   br="1px solid lightgray" brRadius="11px" maxWidth="68.3"  minWidth="68.3" h="32"  position="fixed" top="30.2" overflowX="auto"  zIndex="10000" right="60.4" bg="white" shadow="0 2px 10px rgb(0 0 0 / 20%)">
        <!-- <div a-style class="modal" display="flex" justify="center" flexWrap="wrap">
            <m-input prefix="../assets/images/assets/mesbro_logo.svg" [type]="gs.enums.inputs.text" px=0 [formControl]="search" fs='1.1' position="fixed" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" postfix_bg="transparent" placeHolder="Search Application"></m-input>
            
            <m-input [formControl]="search" prefix="../assets/images/assets/mesbro_logo.svg" [capitalize]=false [uppercases]=false (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)" a-style minWidth="5" maxWidth="54" px=0 fs="1.3" shadow="0 3px 6px 0 #f1f1f1"  outline=none postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" placeHolder="Search application here"></m-input>
        </div> -->
        <!-- <div a-style display="flex" justify="space-around" py="3">
            <p a-style fs="1.3"  [queryParams]="{type: 'All Applications'}" [style.color]="params.type == 'All Applications' ? 'red' : 'black'">All Applications</p>
            <p a-style fs="1.3">For Individual</p>
            <p a-style fs="1.3">For Business</p>
            <p a-style fs="1.3">For Welfare</p>
        </div> -->
            <div class="no_scroll" a-style  display="flex" justify="center" py=3.5 flexWrap="wrap" gap="3.3">
                <ng-container [ngTemplateOutlet]="apps" *ngFor="let item of gs.applications | search : search.value" [ngTemplateOutletContext]="{passedData: item}"></ng-container>
            </div>
        </div>
</div>

<ng-template #apps let-passedData="passedData">
    <div (click)="select_application(passedData);"   a-style cursor=pointer   align=center >
        <div a-style >
            <!-- <ng-container *ngIf="users?.activeUser?.token"> -->
            <img a-style w="7.1" h="7.1" [src]="passedData.src">
                <m-text [type]="gs.enums.texts.paragraph11" [text]="passedData.application" a-style mt="1.7"></m-text>
            <!-- </ng-container> -->
        </div>
    </div>
</ng-template>
<!-- <m-modal #signIn>
    <m-sign-in-required [modal]="signIn"></m-sign-in-required>
</m-modal> -->