import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { async } from 'rxjs';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-short-link-card',
  templateUrl: './short-link-card.component.html',
  styles: [
  ]
})
export class ShortLinkCardComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'
  @Input() application: string = ''
  @Input() detail: any
  @Input() type: any;
  @Input() index: any;
  @Input() details: any
  @Input() list: any;
  @Input() visible = false;
  isVisible: boolean = false;
  isVisible_favorite: boolean = false;
  isVisible_bookmark: boolean = false;
  // @Output() addProduct = new EventEmitter<any>();
  @Output() addshortlink = new EventEmitter<any>();
  @Output() activity = new EventEmitter;
  @Output() delete = new EventEmitter();
  queryParams = {
    type: '',
    _id: ''
  }
 
  constructor(public gs: GlobalService, public ar:ActivatedRoute) { }

  ngOnInit(): void {
    this.current = Object.keys(this.gs.localStorageGet('compare-items') || {}).includes(this.details?.parent?._id)
  this.ar.queryParams.subscribe((param:any)=>{
    this.queryParams = param
  })
  }

  async get_list() {
    let response: any = await (this.gs.hps.http.get('https://new-api.mesbro.com/' + this.detail?.unique_key));
  
    console.log(response);
  }




  activities: any ={
    save:false,
    favourite_title:false,

  }

  rating = 0;
  flag_title = 'India';
  like_title = 'Like';
  compare_title = 'Add To Compare';
  save_title = 'Saved Product';
  share_title = 'Share Product'
  favourite_title='Favourite Product'
  icon: boolean = false
  @Input() shadow: string = ""
  hovered = false;
  _id: any;
  

  async saved_product(id: any) {
    event?.stopPropagation();
    let body = {
      parent: {
        data: {
          _id: id
        }
      }
    };
    this.activities = await this.gs.hps.post('generic', 'activity', 'get-activities', body)
    console.log(this.activities);
 }
 async favourite_products(id:any){
  event?.stopPropagation();
    let body = {
      parent: {
        data: {
          _id: id
        }
      }
    };
    this.activities = await this.gs.hps.post('generic', 'activity', 'get-activities', body)
  console.log(this.activities);
}
  goto_saved_list() {
    this.addshortlink.emit(this.detail);
  }
  @Input() hover: any = undefined;
  mouse_over: boolean = false;
  current: boolean = false;



  product_list = {
    list: [],
    count: 0,

  }

  toggle(activities: any, toggle_key: string) {
    if (activities?.my_activity?.[toggle_key]) {
      activities.count[toggle_key] = activities?.count[toggle_key] - 1
      activities.my_activity[toggle_key] = false
    }
  }
  save() {
    console.log("hello");
  }


  async action(event: any) {
    try {
      if (event == 'print') {
        this.gs.print_component('printSection');
        return;
      }

      let body =   {
        activity: event,
        device: this.gs.device_details,
        language: this.gs.$language.getValue(),
        location: this.gs.localStorageGet('location'),
        parents: {
          data: this.details
        }
      }
      let response = await this.gs.hps.post('generic', 'activity', 'add', body);

    }
    catch (error:any) {
      this.gs.toast('Security', error?.error?.message, 'error')
      throw error;
    }
  }


  visible1: any = {
    app: false,
  };
 
  first_time = true;




  open_pop_over(name: 'emojis' | 'set_delivery' | 'attachments' | 'app' | 'all') {
    setTimeout(() => {
      if (this.visible1[name]) {
        this.visible1[name] = false
      } else {
        this.visible1[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
  }

  close_other_pop_overs(name: 'emojis' | 'set_delivery' | 'attachments' | 'app' | 'all') {
    Object.keys(this.visible1).forEach((key: any) => {
      if (key != name) {
        this.visible1[key] = false;
      }
    })
  }

  click_event(event: any) {
    if (!this.first_time) {
      let is_modal = false;
      event.path.forEach((path: any) => {
        let class_list = path.classList?.value;
        if (class_list?.includes('modal')) {
          is_modal = true;
        }
      });
      if (!is_modal) {
        this.first_time = true;
        this.close_other_pop_overs('all')
      }
    } else {
      this.first_time = false;
    }
  }


















}
function action(event: Event | undefined, any: any) {
  throw new Error('Function not implemented.');
}



  // @Output() activity = new EventEmitter;
  // @Input() visible = false;
  // ngOnInit(): void {
  //   this.current = Object.keys(this.gs.localStorageGet('compare-items') || {}).includes(this.details?.parent?._id)
  // }





  // application = 'Product';
  // component = 'Product';



  // activities: any ={
  //   save:false,
  //   favourite_title:false,

  // }
  // goto_saved_list() {
  //   this.addshortlink.emit(this.detail);
  // }



  
  // async action(event: any) {
  //   try {
  //     if (event == 'print') {
  //       this.gs.print_component('printSection');
  //       return;
  //     }

  //     let body =   {
  //       activity: event,
  //       parents: {
  //         data: this.detail
  //       }
  //     }
  //     let response = await this.gs.hps.post('generic', 'activity', 'add', body);

  //   }
  //   catch (error) {

  //   }
  // }


  //   async saved_shortlink(id: any) {
//     event?.stopPropagation();
//     let body = {
//       parent: {
//         data: {
//           _id: id
//         }
//       }
//     };
//     this.activities = await this.gs.hps.post('generic', 'activity', 'get-activities', body)
//     console.log(this.activities);
//  }
//  async favourite_products(id:any){
//   event?.stopPropagation();
//     let body = {
//       parent: {
//         data: {
//           _id: id
//         }
//       }
//     };
//     this.activities = await this.gs.hps.post('generic', 'activity', 'get-activities', body)
//   console.log(this.activities);
// }



  // toggle(activities: any, toggle_key: string, current_key: string) {
  //   console.log(activities);
  //   activities.my_activity[current_key] = !activities.my_activity[current_key]
  //   if (activities?.my_activity?.[current_key]) {
  //     activities.count[current_key] = activities?.count[current_key] + 1
  //   } else {
  //     activities.count[current_key] = activities?.count[current_key] - 1
  //   }
  //   if (activities?.my_activity?.[toggle_key]) {
  //     activities.count[toggle_key] = activities?.count[toggle_key] - 1
  //     activities.my_activity[toggle_key] = false
  //   }
  // }