import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';
import { rfq_component_items } from './rfq-component-data'

@Component({
  selector: 'm-rfq',
  templateUrl: './rfq.component.html'
})
export class RfqComponent implements OnInit {

  // application = 'Product';
  // component = 'Lead';
  rfq_component_items = rfq_component_items;
  is_visible: boolean = false;
  query_params = {
    type: '',
    step: '',
    application: ''
  }
  // list = {
  //   list: [],
  //   count: 0
  // }
  custom_filter : any = {}
  constructor(public hps: HttpProviderService, public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    // this.get_list();
    this.ar.queryParams.subscribe((params: any) => {
      this.query_params = params
    })
  }
  // async get_list() {
  //   let response = await this.gs.get_data_list(this.application, this.component, [])
  //   this.list = response;
  // }

  // async delete(id: any, index: number) {
  //   await this.gs.delete_from_list('data', id)
  //   this.list.list.splice(index, 1);
  // }
}




