import { random_number } from './../modules/shared/functions/common';
import { HttpProviderService } from './http-provider.service';
import { Inputs } from 'src/enums/inputs';
import { Buttons } from 'src/enums/buttons';
import { UserService } from './user.service';
import { localStorageGet, localStorageSet, push_control_in_array, remove_control_in_array, } from '../modules/shared/functions/common';
import { LocationDetails } from '../applications/idm/interfaces/i-user';
import { form_validations } from '../modules/shared/functions/form-validations';
import { Texts } from 'src/enums/texts';
import { Subject } from 'rxjs/internal/Subject';
import { Images } from 'src/enums/images';
import { applications } from '../configuration/data/applications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { banks, countries, nearest_air_ports, nearest_sea_ports } from '../modules/shared/data/external-data';
import { capitalCase } from 'capital-case';

import { async, BehaviorSubject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { dropdowns } from '../modules/shared/functions/dropdown';
import { environment } from 'src/environments/environment';

interface IVariables {
  location?: LocationDetails | undefined;
  temp?: any
}


interface IEnums {
  inputs: typeof Inputs;
  buttons: typeof Buttons;
  texts: typeof Texts;
  images: typeof Images
}


@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  activeUserData: any;
  sweetAlert1(arg0: string, arg1: string, arg2: string) {
    throw new Error("Method not implemented.");
  }
  getCharaLength(temp: void) {
    throw new Error('Method not implemented.');
  }
  removeHtmlTags(data: any) {
    throw new Error('Method not implemented.');
  }
  conventIntoTitleCase(arg0: any): string {
    throw new Error('Method not implemented.');
  }
  request: any;
  toastyService: any;
  navigate(arg0: string) {
    throw new Error('Method not implemented.');
  }
  public imageAssetsPath = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/';
  public app: any;
  public totalCount = 0
  public ReadTime = 0
  imageUrl = environment.IMAGE_URL
  primaryObject: any = {}
  secondaryObject: any = {}
  tertiaryObject: any = {}
  public primaryArray = []
  public secondaryArray = []
  public tertiaryArray = []
  public typeArray = []
  dropdown = dropdowns;

  public application: any
  public component: any
  public publicMasters = {}
  public genericSearch = new Subject<any>()
  form_validations = form_validations;
  applications = applications;
  public masters: any = {}
  localStorageSet = localStorageSet;
  localStorageGet = localStorageGet;
  push_control_in_array = push_control_in_array;
  remove_control_in_array = remove_control_in_array;
  device_details = {}

  temp_display = true;

  $language = new BehaviorSubject(localStorageGet('language'))
  $country = new BehaviorSubject(localStorageGet('country') || { label: 'India', country_code: '+91', image: "old_files/country-flags/india.svg" })
  $groups = new BehaviorSubject([])
  $selected_application = new Subject();
  $click = new Subject();
  $notifications = new Subject();
  $refresh_compare = new Subject();
  $refresh_user = new Subject();
  $refresh_removeUser = new Subject();
  $keyup = new Subject();
  variables: IVariables = {
    temp: {}
  };

  $selected_file = new BehaviorSubject({})
  $deleted_file = new BehaviorSubject({})
  drive_list: any
  $post = new Subject();

  follower_list: any
  following_list: any
  post_list: any

  mastersList = ['Height', 'Constitution of Organization', 'Airport', 'Phobia', 'Organization Category', 'Country', 'Dosh', 'Time Zone', 'Skin Disorder', 'Relationship Status', 'Appellation', 'Sexuality', 'Profession', 'Sub Community', 'Community', 'Language', 'Religion', 'Passive Income Source', 'Annual Income', 'City', 'District', 'Star', 'State', 'Nature Of Business', 'Sea Port', 'Railway Station', 'Metro Station', 'Industry', 'Currency', 'Gender', 'Appellation', 'Nature Of Business', 'Primary Citizenship', 'Nearest Airport', 'Privacy', 'Question Category', 'Sourcing Type', 'Sourcing Purpose', 'Unit', 'Trade Terms', 'Location', 'Certifications', 'Shipping Method', 'Destination', 'Payment Term', 'Gotra'];
  toasts: any[] = []
  audio: any = {};

  enums: IEnums = {
    inputs: Inputs,
    buttons: Buttons,
    texts: Texts,
    images: Images
  };

  data = {
    countries: countries,
    nearest_sea_ports: nearest_sea_ports,
    nearest_air_ports: nearest_air_ports,
    banks: banks
  }
  activities: any
  notifications$ = new Subject();
  innerWidth = new Subject();
  // User: any;
  users: any;
  constructor(public hps: HttpProviderService, public router: Router, public user: UserService) {
    this.innerWidth.next(window.innerWidth)
  }

  async get_data_list(application: string, component: string, custom_filters?: any, time?: any, page?: any, query?: any) {
    // if (!Array.isArray(custom_filters)) {
    //   custom_filters = []
    // }
    let list = { list: [], count: 0 }
    if (!application || !component) {
      return list
    }
    application = capitalCase(application)
    component = capitalCase(component)
    try {
      let filters: any = { ...{ component }, ...(custom_filters || []) }

      // console.log(filters, custom_filters)
      if (page) {
        filters.page = page
      }
      if (query) {
        filters.query = query
      }
      if (filters['0']) {
        let temp = { ...filters['0'] }
        delete filters['0'];
        filters = { ...temp, ...filters }
      }
      list = await this.hps.post('generic', 'data', 'list', filters, time);
    } catch (error) {
    }
    return list;
  }

  async get_list(data_type: 'structure' | 'data', type: string | undefined, sub_type: string | undefined, application: string | undefined, custom_filters?: any, extras?: any) {
    application = capitalCase(application || '');
    let list = { list: [], count: 0 };
    console.log(extras, custom_filters)
    try {
      let filters = { ...extras, $and: [{ application }, { type }, { sub_type }, ...(custom_filters || [])] };
      list = await this.hps.post('generic', data_type, 'list', filters);
    } catch (error) {
    }
    return list;
  }
  async delete_from_list(data_type: 'structure' | 'data', _id: string, list?: any[], index?: number) {
    try {
      await this.hps.delete('generic', data_type, _id);
      if (index || index == 0) {
        list?.splice(index, 1);
      }
    } catch (error) {
    }
  }
  counter = 0;
  toast = (title: string, message: string, type: 'success' | 'warning' | 'error' | 'Info' = 'success', duration: number = 2.5) => {
    this.playAudio(type)
    let id = ++this.counter;
    let toast = { id, title, type, message, duration };
    this.toasts.push(toast);
    setTimeout(() => {
      let index = this.toasts.findIndex(f => f.id == id);
      this.toasts.splice(index, 1);
    }, duration * 1000);
  }

  playAudio(type: any) {
    if (!this.audio[type]) {
      this.audio[type] = new Audio();
      console.log(`/assets/audio/${type}.mp3`)
      this.audio[type].src = `/assets/audio/${type}.mp3`;
      this.audio[type].volume = 0.1;
      this.audio[type].load();
    }
    this.audio[type].play();
  }

  test = () => {
    console.log('Test');
  }

  back = () => {
    window.history.back();
  }

  print_component(cmpName: string) {
    let printContents = document.getElementById(cmpName)?.outerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents || '';
    let style = document.createElement('style');
    style.innerHTML = `
    .printHidden {
    display: none;
    }
    #printSection{
      width : 100vw !important
    }
    `;
    document.head.appendChild(style);
    window.print();

    window.location.reload();
  }

  group_ids: any[] = []
  async get_groups() {
    let filter = { ['meta.roles.owner.user.' + this.user.users.activeUser?._id]: true }
    let response: any = await this.hps.post('idm', 'group', 'list', filter);
    let list: any[] = response?.list;
    this.group_ids = list.map(e => e._id)
    list = list.map((g: any) => { g.title = g.basic_details.legal_name; return g; });

    return list;
  }
  async getMasters() {
    if (!this.user.users.activeUser?._id) {
      return;
    }
    try {
      this.mastersList.forEach(async (master: any) => {
        let body = {
          application: 'Master',
          component: master
        }
        this.masters[master] = await this.hps.post('generic', 'master', 'list', body, 2);
        this.masters[master] = await this.masters[master].list.map((g: any) => g.data)


      });
    } catch (error) {
      console.log(error);
    }
  }
  // async getMasterList(master:any){
  //       if (!this.user.users.activeUser?._id) {
  //       return;
  //     }
  //     try {
  //       // this.mastersList.forEach(async (master: any) => {
  //         console.log(master);

  //         let body = {
  //           component: master
  //         }
  //         this.masters[master] = await this.hps.post('generic', 'master', 'list', body, 2);
  //         this.masters[master] = await this.masters[master].list.map((g: any) => g.data)
  //         console.log(this.masters[master]);

  //       // });
  //     } catch (error) {
  //       console.log(error);
  //     }
  // }
  async action(event: any, details: any) {
    try {
      if (event == 'print') {
        this.print_component('printSection');
        return;
      }
      let body = {
        activity: event,
        device: this.device_details,
        language: this.$language.getValue(),
        location: this.localStorageGet('location'),
        parents: {
          data: details
        }
      }
      let response = await this.hps.post('generic', 'activity', 'add', body);
      console.log(response);

    }
    catch (error: any) {
      this.toast('Security', error?.error?.message, 'error')
      throw error;
    }

  }
  async history(event: any, details: any, application: any, component: any, action: any) {
    try {
      let body = {
        action: event,
        device: this.device_details,
        language: this.$language.getValue(),
        location: this.localStorageGet('location'),
        key: details
      }
      if (this.user.users.activeUser) {
        let response = await this.hps.post(application, component, action, body);
      }

    }
    catch (error: any) {
      this.toast('Security', error?.error?.message, 'error')
      throw error;
    }
  }

  async organizationHistory(_id: any, application: any, component: any, action: any) {
    try {
      let body = {
        "key": _id
      }
      let response = await this.hps.post(application, component, action, body);
    }
    catch (error: any) {
      this.toast('Security', error?.error?.message, 'error')
      throw error;
    }
  }
  async get_activity(id: any) {
    let body = {
      parents: {
        data: {
          _id: id
        }
      }
    };
    return await this.hps.post('generic', 'activity', 'get-activities', body)
  }


  scrollToBottom(container: any): void {
    try {
      setTimeout(() => {
        container?.nativeElement?.scrollTo({ top: container?.nativeElement.scrollHeight, behavior: 'smooth' });
      }, 50);
    } catch (err) { }
  }

  circular(container: any): void {
    try {
      setTimeout(() => {
        console.log(container?.nativeElement, container?.nativeElement?.clientWidth, container?.nativeElement?.scrollLeft)
        container?.nativeElement?.scrollTo({ left: container?.nativeElement?.scrollLeft + container?.nativeElement?.clientWidth, behavior: 'smooth' });
      }, 10);
    } catch (err) { }
  }

  scrollHorizontalRight(container: any): void {
    try {
      setTimeout(() => {
        console.log(container?.nativeElement, container?.nativeElement.clientWidth, container?.nativeElement.scrollLeft)
        container?.nativeElement.scrollTo({ left: container?.nativeElement.scrollLeft + container?.nativeElement.clientWidth, behavior: 'smooth' });
      }, 10);
    } catch (err) { }
  }

  scrollHorizontalLeft(container: any): void {
    try {
      container.nativeElement.scrollTo({ left: container.nativeElement.scrollLeft - container.nativeElement.clientWidth, behavior: 'smooth' });
    } catch (err) { }
  }

  scrollToTop(container: any): void {
    try {
      container.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) { }
  }

  toggle_display() {
    this.temp_display = false;
    setTimeout(() => {
      this.temp_display = true;
    }, 200);
  }


  // addFormArrayControls(form: FormGroup, controls: any[], count: number, object: any, formBuilderInstance: FormBuilder, classInstance?: any) {
  //   let formArrayControl: AbstractControl = form;
  //   // console.log(form, controls)
  //   controls.forEach(control => {
  //     // console.log(control)
  //     formArrayControl = (<any>formArrayControl).get(control);
  //   });
  //   this.pushControlInArray(form, controls, object, count, formBuilderInstance, classInstance)
  // }

  // pushControlInArray(form: FormGroup, controls: any[], object: any, count, formBuilderInstance: FormBuilder, classInstance?: any) {
  //   // console.log('Add Form Group', form,controls,object,count)
  //   let formArrayControl: AbstractControl = form;
  //   // console.log(formArrayControl)
  //   controls.forEach(control => {
  //     formArrayControl = (<any>formArrayControl).get(control) || (<any>formArrayControl).get('basic').get(control);
  //     // console.log(formArrayControl)

  //   });
  //   // console.log(formArrayControl);
  //   for (let i = 0; i < count; i++) {
  //     (<FormArray>formArrayControl).push(object(formBuilderInstance, classInstance))
  //   }
  //   return (<FormArray>formArrayControl).controls.length - 1;
  // }



  home_page_banner =
    {
      img1: '../assets/images/assets/product_banner_one.png',
      img2: '../assets/images/assets/product_banner_two.png',
      img3: '../assets/images/assets/product_banner_two.png',
      img4: '../assets/images/assets/product_banner_one.png',
      img5: 'https://mesbro.in/assets/images/temp-images/home-page-banner.png',
      img6: 'https://mesbro.in/assets/images/temp-images/my-product-organization.png',
      img7: 'https://mesbro.in/assets/images/temp-images/my-product-organization.png',

    };

  next(queryParams: any) {
    queryParams.page = parseInt(queryParams.page || 0) + 1
    this.router.navigate([], { queryParams: queryParams })
  }

  previous(queryParams: any) {
    if (parseInt(queryParams.page) < 1) {
      return
    }
    queryParams.page = parseInt(queryParams.page || 0) - 1
    this.router.navigate([], { queryParams: queryParams })
  }

  async getPrimaryList(application?: any, component?: any) {
    console.log('Primary List Was Called')
    try {
      let requestObj = {
        'basic.type': {
          "$eq": "primary"
        }
      }
      let res = await this.hps.post(application, component, 'list', requestObj);
      this.primaryArray = res
      // console.log(this.primaryArray)

      this.primaryArray.forEach(element => {
        // this.primaryObject[element._id] = element;
        // console.log(this.primaryObject)
      })

    } catch (error) {
      console.log(error);
      // this.showToast("error", "Error", error.error.content.message);
    }


  }

  getSecondaryList(application?: any, component?: any) {
    let requestObj = {
      'basic.type': {
        "$eq": "secondary"
      }
    }
    this.hps.post(application, component, 'list', requestObj).then(res => {
      // console.log(res)
      this.secondaryArray = res
      this.secondaryArray.forEach(element => {
        // this.secondaryObject[element._id] = element;
      })

    })
      .catch(err => {
        console.log(err)
      })
  }

  getTertiaryList(application?: any, component?: any) {
    let requestObj = {
      'basic.type': {
        "$eq": "tertiary"
      }
    }
    this.hps.post(application, component, 'list', requestObj).then(res => {
      this.tertiaryArray = res
      this.tertiaryArray.forEach(element => {
        // this.tertiaryObject[element._id] = element;
      })

    })
      .catch(err => {
        console.log(err)
      })
  }
  public selectedOrganization = undefined
  getTypeList(application?: any, component?: any) {
    let requestObj = {
      'basic.type': {
        "$eq": "type"
      }
    }
    this.hps.post('business', 'producttypes', 'get-single-list', requestObj).then(res => {
      this.typeArray = res
      this.typeArray.forEach(element => {
        // this.tertiaryObject[element._id] = element;
      })

    })
      .catch(err => {
        console.log(err)
      })
  }
  showToast(type: 'error' | 'info' | 'success' | 'warning', title: string, message?: string, data?: any) {
    // alert(title + " - " + message); //type - error,success,warning,info
    // this.toast[type](title, message);
    // //console.log(type, message, title)
    this.toastyService[type]({
      msg: message,
      title: title,
    });
    // alert(type +' | '+ title + ' | ' +  message);
    // this.toast.
  }
  getFieldById(fields: any[], keyName: any) {
    // let TempArray :any[] = [...this.detail.basic]
    // // //// console.logfields)
    if (fields.length) {
      // // //// console.logfields)

      fields = [...fields]
      let Temp: any[] = fields.filter((field: any, index) => {
        return (<string>field.name).toLowerCase() == keyName.toLowerCase()
      })
      if (
        Temp.length > 0) {
        return Temp[0].id
      }
    }

  }
  getFieldByObject(fields: any[], keyName: any) {
    // let TempArray :any[] = [...this.detail.basic]
    // // //// console.logfields)
    if (fields.length) {
      // // //// console.logfields)

      fields = [...fields]
      let Temp: any[] = fields.filter((field: any, index) => {
        return (<string>field.name).toLowerCase() == keyName.toLowerCase()
      })
      if (
        Temp.length > 0) {
        return Temp[0]
      }
    }

  }

  getControl(formGroup: FormGroup, controls?: any) {
    let finalControl: any = formGroup;
    if (controls?.length) {
      controls?.forEach((element: any) => {
        // console.log(finalControl, element);
        finalControl = finalControl.get(element.toString());
      })
    }
    // console.log(finalControl)
    return finalControl;
  }

  getControlValue(formGroup: FormGroup, controls?: Array<string>) {
    let finalControl: any = formGroup;
    if (controls?.length) {
      controls.forEach(element => {
        // console.log(finalControl, element);
        finalControl = finalControl?.get(element?.toString());
      })
    }
    // console.log(finalControl)
    return finalControl.value;
  }
  addFormArrayControls(form: FormGroup, controls: any[], count: number, object: any, formBuilderInstance: FormBuilder) {
    let formArrayControl: any = form;
    // console.log(form,controls)
    controls.forEach(control => {
      formArrayControl = formArrayControl.get(control);
    });
    this.pushControlInArray(form, controls, object, count, formBuilderInstance)
  }

  removeFormArrayControl(form: FormGroup, controls: any[], index?: any) {
    let formArrayControl: any = form;
    controls.forEach(control => {
      formArrayControl = formArrayControl.get(control) || formArrayControl.get('basic').get(control);
    });
    (<FormArray>formArrayControl).removeAt(index);
  }


  pushControlInArray(form: FormGroup, controls: any[], object: any, count: any, formBuilderInstance: FormBuilder) {
    // console.log('Add Form Group', form,controls,object,count) 
    let formArrayControl: any = form;
    // console.log(formArrayControl)
    controls.forEach(control => {
      formArrayControl = formArrayControl.get(control) || formArrayControl.get('basic').get(control);
      // console.log(formArrayControl)

    });

    for (let i = 0; i < count; i++) {
      (<FormArray>formArrayControl).push(object(formBuilderInstance))
    }
    return (<FormArray>formArrayControl).controls.length - 1;
  }
}
// {
//   "application":"business",
//   "component":"producttype",
//   "page": "1",
//   "query": "",
//   "count": true,
//   "basic": {
//   "type": "product"
//   }
//   }