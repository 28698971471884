<div a-style p=2.4>
  <m-text *ngIf="queryParams.type!=='Domain' " [type]="gs.enums.texts.paragraph12" text="My Domain" my="2.4"></m-text>
  <div a-style display="flex" gap="1.6" flexWrap="wrap" overflowX="auto;460:scoll" class="no_scroll_bar">
    <div a-style minWidth="43" p="2.4" br="1px solid #e2e2e2" align="center" display="flex" flexDirection="column" justify="center" brRadius="0.5" ml="0.2" bg="white">
      <m-image [src]="gs.enums.images.image329" w=3 (click)="addDomain.openModal()"></m-image>
      <m-text [type]="gs.enums.texts.paragraph38" text="Add Domain" mt=2.4 (click)="addDomain.openModal()"></m-text>
    </div>
    <div *ngFor="let domain of list?.list; let i=index" a-style minWidth="40" p="1.6" br="1px solid #e2e2e2" display="flex" justify="space-between" bg="white" position="relative" brRadius=0.5>
      <div a-style display="flex" flexDirection="column" gap="0.5">
        <!-- <m-text [type]="gs.enums.texts.paragraph25" [text]="domain?.data?.domain_name"></m-text> -->
        <!-- {{domain | json}} -->
        <div a-style display="flex" alignItems="center" gap="1">
          <m-image src="../assets/images/assets/domain colour.svg" a-style h="1.8" w="1.8"></m-image>
          <m-text [type]="gs.enums.texts.paragraph39" [text]="'Domain :'+' '+domain.data.domain_name"></m-text>
        </div>
        <div a-style display="flex" alignItems="center" gap="1">
          <m-image src="../assets/images/assets/user colour.svg" a-style h="1.8" w="1.8"></m-image>
          <m-text [type]="gs.enums.texts.paragraph39" [text]="'User Name :'+' '+users?.activeUser?.contact_details?.email_address_primary"   mt="1.2"></m-text>
        </div>
        <div a-style display="flex" alignItems="center" gap="1">
          <m-image src="../assets/images/assets/status colour.svg" a-style h="1.8" w="1.8"></m-image>
          <m-text [type]="gs.enums.texts.paragraph39"   [text]="'Status :'+' '+domain?.meta?.status" mt="1.2"></m-text>
        </div>
        <div a-style minWidth="40" w="100%" display="flex" alignItems="center" justify="space-between">
          <div a-style display="flex" alignItems="center" gap="1">
            <m-image src="../assets/images/assets/user validity colour.svg" a-style h="1.8" w="1.8"></m-image>
            <m-text [type]="gs.enums.texts.paragraph39" text="User Validity : Jan 30, 2021" mt="1.2"></m-text>
          </div>
          <m-image hover="../assets/images/assets/delete colour.svg" a-style w="1.8" h=1.8 src="../assets/images/assets/delete grey.svg" title="Delete"></m-image>
        </div>
      </div>
      <div a-style display=flex flexDirection=column justify=space-between>
        <div a-style display=flex gap=2.4 flexDirection="row-reverse">
        </div>
      </div>
    </div>
  </div>
</div>



<m-modal width=43.8% #addDomain (cancel)="addDomain.closeModal()">
  <m-add-domain [modal]=addDomain></m-add-domain>
</m-modal>


<!-- <m-image [src]="gs.enums.images.image277" w=2 (click)="isVisible[i] =!isVisible[i]"></m-image> -->
<!-- <m-image  title="Delete" (click)="delete(domain._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image> -->

<!-- <div *ngIf="isVisible[i]"  a-style br="1px solid #e2e2e2" maxWidth="18.4" p="1" bg="#FFFFFF" position="absolute" right="0" bottom="3">
          <m-text [type]="gs.enums.texts.paragraph1" text="Plain Text Mode"  mb="1.6"></m-text>
          <m-text [type]="gs.enums.texts.paragraph1" text="HTML Mode" ></m-text>
        </div> -->

<!-- <img src="{{'https://new-api.mesbro.com/uploads/'+domain?.data?.upload_pic}}" a-style w="10" h="10"> -->
<!-- <m-image src="../assets/images/assets/domain_demo.svg" w="10" h="10"></m-image> -->
