import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { applications } from 'src/mesbro/configuration/data/applications';
import { GlobalService } from 'src/mesbro/services/global.service';


@Component({
  selector: 'm-campaign-form',
  templateUrl: './campaign-form.component.html',
  styles: [
  ]
})
export class CampaignFormComponent implements OnInit {

  @Input() modal: any
  verify = false;
  notVerify =  false;
  user: any;
   @Output() cancel = new EventEmitter();

  application = 'generic';
  component = 'campaign';

  records:any;
  


  form_data = {
    campaign: this.fb.control('',[Validators.required],),
    title: '',
    groupControl:'',
    image: [null],

  }

  queryParams: any = {
    _id: ''
  }

  form: FormGroup | undefined;


  constructor(public renderer: Renderer2, public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))

  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'campaign', params._id);
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: undefined,
      token: undefined,
      application: this.application,
      component: this.component,
      campaign: this.fb.control('',[Validators.required],),
      title: '',
      groupControl:'',
      image: [null],
    })
  }

 

  async submit() {
    let body = this.form?.value;
    console.log(body);
    if (this.form?.value._id) {
      let response = await this.gs.hps.put('generic', 'campaign', undefined, body);
    } else {
      let response = await this.gs.hps.post('generic', 'campaign', 'add', body);
      this.gs.toast('Campaign', 'Added Successfully', 'success')
      this.gs.router.navigate([ '/dashboard/campaign/list' ] );
    }
  }



}




  // async dns_record(){
  //   let body = this.domain;
  //   let response = await this.gs.hps.post('Idm', this.component, body,[])
  //   console.log(response);
    
  // }



    // application = 'generic';
  // component = 'campaign';
  // form: FormGroup | undefined;

  // applications = applications

  // form_data = {
  //   campaign: [null, this.gs.form_validations.required('campaign')],
  //   link: '',
  //   title:'',
  //   groupControl: '',
  //   image: [null]
  // }
  // queryParams: any = {
  //   _id: ''
  // }
  

  // constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  // ngOnInit(): void {
  //   this.ar.queryParams.subscribe(params => this.handleParams(params))
  // }

  // handleParams = async (params: Params) => {
  //   this.queryParams = params
  //   this.initializeForm();
  //   if (params._id) {
  //     let details = await this.gs.hps.get('generic', 'data', params._id); //update list
  //     this.form?.patchValue(details)
  //   }
  // }

  // initializeForm() {
  //   this.form = this.fb.group({
  //     _id: null,
  //     application: this.application,
  //     component: this.component,
  //     data: this.fb.group(this.form_data)
  //   })
  // }
  // async submit() {
  //   try {
  //     let body = this.form?.value;
  //     if (this.form?.value._id) {
  //       let response = await this.gs.hps.put('generic', 'data', undefined, body);
  //       this.gs.toast('campaign', 'Modified Successfully', 'success')
  //     } else {
  //       let response = await this.gs.hps.post('generic', 'data', 'add', body);
  //       this.gs.toast('campaign', 'Added Successfully', 'success')
  //       this.gs.router.navigate(['dashboard/campaign/list']);
  //     }
  //   } catch (error) {
  //     console.log(error);
      
  //   }
  // }