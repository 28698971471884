import { keyframes } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { response } from 'express';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-mesbro-home',
  templateUrl: './mesbro-home.component.html',
  styles: [],
})
export class MesbroHomeComponent implements OnInit {
  @HostBinding('style.display') display = 'contents';
  icon: boolean = false;
  @Input() detail: any;
  search: any;
  params = {
    type: <'product' | 'mail' | undefined>undefined,
    _id: '',
    score_id: '',
  };

  location: any
  my_score: any = []

  my_weather: any = []
  arrayObj: any;
  objectData: any;
  length: any;
  push: any;

  details: any


  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit() {
    // let current_user = this.gs.user.users.activeUser;
    // this.location=current_user?.location_details?.city || "pune"
    this.ar.queryParams.subscribe((param: any) => {
      this.params = { ...param };
      if (this.gs.router.url.includes('dashboard')) {
        this.gs.router.navigateByUrl('/dashboard/group/list');
      }
    });
    this.search = new FormControl('');
    this.wheather_report();
    this.cricket_score();

  }

  select_application(data: any) {
    // console.log(data);
    if (data.link) {
      if (
        (data.application == 'Mail' ||
          data.application == 'Chat' ||
          data.application == 'Social' ||
          data.application == 'Drive' ||
          data.application == 'Notes') &&
        !this.gs.user.users.activeUser?._id
      ) {
        this.gs.router.navigateByUrl('idm/sign-in');
        return;
      }
      this.gs.router.navigateByUrl(`/${data.link}`);
    } else {
      this.gs.router.navigateByUrl(`${data.application.toLowerCase()}/home`);
    }
  }

  onKeyUp(event: any) {
    console.log(this.search);
    this.gs.router.navigate(['/generic/search'], {
      queryParams: { application: 'Product', query: this.search.value },
    });
  }

  value = -1

  async cricket_score() {
    let body = {

    }
    let response = await this.gs.hps.post('external-api', 'cricket', 'live-cricket', body)
    this.my_score = response
    // console.log(response.data.matches);
    this.nextItem()
  }

  async nextItem() {
    if (this.value == this.my_score?.data?.matches?.lenght - 1) {
      this.value = 0
    }
    else this.value = this.value + 1;
  }

  async prevItem() {
    if (this.value == 0) {
      this.value = this.my_score.data.matches.length - 1
    }
    else this.value = this.value - 1;
  }



  async wheather_report() {
    let body = {
      "city": "Navi Mumbai",
      "country": "india"
    }
    let getwheather = await this.gs.hps.post('external-api', 'weather', 'current-weather', body)
    if (getwheather) {
      this.my_weather = getwheather

    }

  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }

}
