import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'socialPost'
})
export class SocialPostPipe implements PipeTransform {

  transform(value: any): any {
 value =  value.filter((item:any)=>{
if(item?.data?.image){
return item
}
 })
 return value
  }

}
