import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-wallpaper',
  templateUrl: './wallpaper.component.html',
  styles: [
  ]
})
export class WallpaperComponent implements OnInit {
  @Input() modal: any
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

}
