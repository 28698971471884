export const countries = [
  { country: 'India', flag: "india", countryCode: '+91' },
  { country: 'Australia', flag: "australia", countryCode: '+61' },
  { country: 'Bhutan', flag: "bhutan", countryCode: '+975' },
  { country: 'Brazil', flag: "brazil", countryCode: '+55' },
  { country: 'China', flag: "china", countryCode: '+86' },
  { country: 'Colombia', flag: "colombia", countryCode: '+57' },
  { country: 'Cuba', flag: "cuba", countryCode: '+53' },
  { country: 'Denmark', flag: "denmark", countryCode: '+45' },
  { country: 'Egypt', flag: "egypt", countryCode: '+20' },
  { country: 'Indonesia', flag: "indonesia", countryCode: '+62' },
  { country: 'Afghanistan', flag: "afghanistan", countryCode: '+93' },
  { country: 'Iraq', flag: "iraq", countryCode: '+964' },
  { country: 'Israel', flag: "israel", countryCode: '+972' },
  { country: 'Japan', flag: "japan", countryCode: '+81' },
  { country: 'Kenya', flag: "kenya", countryCode: '+254' },
  { country: 'Maldives', flag: "maldives", countryCode: '+960' },
  { country: 'Mexico', flag: "mexico", countryCode: '+52' },
  { country: 'Nepal', flag: "nepal", countryCode: '+977' },
  { country: 'Oman', flag: "oman", countryCode: '+968' },
  { country: 'Pakistan', flag: "pakistan", countryCode: '+92' },
  { country: 'Sweden', flag: "sweden", countryCode: '+46' },
]
export const banks = [
  { bank: 'SBI' },
  { bank: 'Kotak' },
  { bank: 'Saraswat' },
  { bank: 'TDC' },
]
export const nearest_sea_ports = [
  { bank: 'SBI' },
  { bank: 'Kotak' },
  { bank: 'Saraswat' },
  { bank: 'TDC' },
]
export const nearest_air_ports = [
  { bank: 'SBI' },
  { bank: 'Kotak' },
  { bank: 'Saraswat' },
  { bank: 'TDC' },
]