import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-enquiry',
  templateUrl: './enquiry.component.html',
  styles: [
  ]
})
export class EnquiryComponent implements OnInit {
  @Input() details: any;
  application = 'Generic';
  component = 'Enquiry';

  form_data = {
    name: [(this.gs.user.users?.activeUser?.personal_details.first_name || '') + ' ' + (this.gs.user.users?.activeUser?.personal_details.last_name || ''), this.gs.form_validations.required('Name')],
    phone_number: (this.gs.user.users?.activeUser?.location_details.country_calling_code || '') + ' ' + (this.gs.user.users?.activeUser?.contact_details.mobile_number_primary || ''),
    email_address: (this.gs.user.users?.activeUser?.contact_details.email_address_primary || ''),
    title: '',
    quantity: '',
    unit: '',
    description: [null, this.gs.form_validations.required('Description')]
  }
  is_visible = {

    Enquiry_answer:false
  }
  queryParams: any = {
    _id: ''
  }

  form: FormGroup | undefined;

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.initializeForm();
    setTimeout(() => {
      this.form?.get('data')?.get('unit')?.patchValue(this.details?.parents?.type?.unit || 'Units')
    }, 100);
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: null,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }
  async submit() {
    try {
      this.form?.markAllAsTouched()
      if (this.form?.invalid) {
        return;
      }
      let body = { ...this.form?.value };
      body.data.location = this.gs.variables?.location
      body.parents = {
        user: {
          personal_details: this.gs.user.users?.activeUser?.personal_details,
          contact_details: this.gs.user?.users?.activeUser?.contact_details
        },
        data: this.details,
      }
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.form?.get('data')?.get('enquiry_subject')?.reset()
      this.form?.get('data')?.get('quantity')?.reset()
      this.form?.get('data')?.get('unit')?.reset()
      this.form?.get('data')?.get('description')?.reset()
      this.gs.toast('Enquiry', 'Submitted Successfully')
      console.log(response);
    } catch (error) {
      console.log(error)
    }

  }

}
