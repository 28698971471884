import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-service-header',
  templateUrl: './service-header.component.html',
  styles: [
  ]
})
export class ServiceHeaderComponent implements OnInit {

  Users: IUser | undefined;

  params: any = {
    type: '',
    step: '',
  }
  users: IUserData;

  constructor(public gs:GlobalService , public ar : ActivatedRoute) { }

  compare_object: any | undefined
  compare_count: any | undefined
  isVisiblecompare: boolean = false


  ngOnInit(): void {
    this.gs.user.users$.subscribe((user:IUserData) => {
      this.users = user;
    })
  }


  
  compare_not_found() {
    this.compare_object = this.gs.localStorageGet('compare-service');
    this.compare_count = (this.gs.localStorageGet('compare-service'));
    this.compare_count = Object.keys(this.compare_object)?.length
   
    if (this.compare_count == 0) {
      this.isVisiblecompare = true
      this.gs.toast('Information', 'No Service to compare', 'Info', 2)
    }
    else {
      this.gs.router.navigate(['service', 'compare'], { queryParams: { type: 'compare-products' } });
    }
  }

  // compare_not_found() {
  //   this.compare_object = this.gs.localStorageGet('compare-items');
  //   this.compare_count = (this.gs.localStorageGet('compare-items'));
  //   this.compare_count = Object.keys(this.compare_object)?.length
    
  //   if (this.compare_count ==0) {
  //     this.isVisiblecompare = true
  //     this.gs.toast('Information', 'No Service to compare', 'Info', 2)
  //   }
  //   else {
  //     this.gs.router.navigate(['service', 'compare'], { queryParams: { type: 'compare-products' } });
  //   }
  // }

}
