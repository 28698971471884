<div a-style display="flex" justify="space-around" align="center" gap="3" >
    <m-image a-style src="../assets/images/assets/faq.png" w="28.2" display="850:none"></m-image>
    <div a-style align="left">
        <form [formGroup]="form">
            <m-form columns="3" h=6 fs="1.4" labelWeight="500" formGroupName="data" [brNone]=false>
                <m-input formControlName="description" [type]="gs.enums.inputs.text_area" rows=6 label="Ask your question here" px=0></m-input>
            </m-form>
        </form>
    </div>
</div>
<div a-style mt="1.5" display="flex" flexGap="2.4" justify="flex-end">
    <m-button [types]="gs.enums.buttons.button7" text="Submit" (click)="submit_qus();modal.closeModal()"></m-button>
</div>