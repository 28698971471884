import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-footer',
  templateUrl: './footer.component.html',
  styles: [
  ]
})
export class FooterComponent implements OnInit {
  @HostBinding('class') class = 'hidden-print';

  params: any = {
    type: ''
  }
users: IUserData

  constructor(public gs : GlobalService , public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
    })
    this.ar.queryParams.subscribe(async (params: any)=> {
      this.params = params;
      // console.log(this.params)

    })
  }

}
