import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LocationDetails } from 'src/mesbro/applications/idm/interfaces/i-user';
import { AstyleDirective } from 'src/mesbro/modules/shared/directives/a-style.directive';
import { GlobalService } from 'src/mesbro/services/global.service';
import { SocketService } from 'src/mesbro/services/socket.service';
import addDays from 'date-fns/addDays'
import DeviceDetector from "device-detector-js";

@Component({
  selector: 'm-main',
  templateUrl: './main.component.html',
  styles: [],
})
export class MainComponent implements OnInit {
  @ViewChild(AstyleDirective) styleDirective: any;


  listener1: any;
  listener2: any;


  constructor(public gs: GlobalService, public socket: SocketService, public renderer: Renderer2) { }

  async ngOnInit() {
    this.get_location();
    this.set_device_details()
    // this.socket.connect_socket()

    this.listener1 = this.renderer.listen('document', 'keydown', (event) => {
      // console.log(event)
      this.gs.$keyup.next(event);
    })

    this.listener2 = this.renderer.listen('document', 'click', (event) => {
      // console.log(event)

      this.gs.$click.next(event);
    })

    this.gs.user.users$.subscribe(async users => {
      this.gs.$groups.next(<any>await this.gs.get_groups())
      try {
        // let permissions = await this.gs.hps.get('generic', 'permission', users.activeUser?._id || '');
        // this.gs.user.permissions = permissions;
      } catch (error) {
      }
    });
    // this.gs.getMasters();

  }

  ngOnDestroy() {
    if (this.listener1) {
      this.listener1();
    }
    if (this.listener2) {
      this.listener2();
    }
  }

  set_device_details() {
    try {
      const deviceDetector = new DeviceDetector();
      const userAgent = window.navigator.userAgent;
      const device = deviceDetector.parse(userAgent);
      this.gs.device_details = device;
      // alert(JSON.stringify(device))
    } catch (error) {
      console.log(error)
    }
  }

  async get_location() {
    let stored_location = this.gs.localStorageGet('location');

    try {
      if (!stored_location || stored_location.expiry >= new Date()) {
        this.gs.variables.location = <LocationDetails>await this.gs.hps.http.get('https://ipapi.co/json/').toPromise();
        this.gs.variables.location.expiry = addDays(new Date(), 3);
        this.gs.localStorageSet('location', this.gs.variables.location);
        console.log(this.gs.masters) 
      } else {
        this.gs.variables.location = stored_location
      }
    } catch (error) {
      console.log(error)
    }
  }
}
