import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { IUserData } from './../../../../applications/idm/interfaces/i-user';
import { IUser } from 'src/mesbro/applications/idm/interfaces/i-user';

@Component({
  selector: 'm-enquiry-card',
  templateUrl: './enquiry-card.component.html',
  styles: [
  ]
})
export class EnquiryCardComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'
  @ViewChild('catalogue_modal') catalogue_modal: any;

  @Input() detail: any;
  @Input() type: any;

  user: IUser | undefined;
  users: IUserData;
  @Output() delete = new EventEmitter();
  @Output() action = new EventEmitter();

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
      console.log(users);
    });

  }
  async allot_data() {
    try {
      console.log(this.detail.meta?.alloted)
      if (this.detail.meta?.alloted || this.detail.meta?.roles?.owner?.user[this.gs.user?.users?.activeUser?._id || '']) {
        if (this.detail.meta.roles.owner.user[this.gs.user?.users?.activeUser?._id || '']) {
          console.log('Is Owner, Not Alloted')
        }
        return;
      }
      let body = {
        data_id: this.detail._id
      }
      let response = await this.gs.hps.post('generic', 'data', 'allot-data', body);
      this.detail.meta.alloted = true;
      this.gs.toast('Enquiry Allotment', 'You are alloted to this enquiry')
    } catch (error) {
      console.log(error);
    }
  }
  
  go_to(label: string) {
    if (label == 'Send Catalogue' || label == 'Send Offer'  || label == 'Send Quotation' || label == 'Chat' || label == 'Mail' || label == 'SMS') {
      this.catalogue_modal?.openModal()
    }
  }

}
