<div a-style mx=2.4 mb=2.4 mt=7.4 bg=#ffffff brRadius=0.6 br="1px solid #e4e4e4" >
    <div a-style p=2.4 brBottom="1px solid #e4e4e4">
        <m-text [type]="gs.enums.texts.paragraph12" text="Suggest Feature"></m-text>
    </div>
    <div a-style p=2.4 bg=#ffffff overflowX="auto;460:scroll;321:scoll" class="no_scroll_bar">
        <div a-style w=100%>
            <m-input flexGrow="0;460:1" minWidth="35;460:30" label="Search" [columns]="1/4" px=0 cleareIcon="true"></m-input>
        </div>
        <table a-style mt=2.4 br="none">
            <tr>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=4.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=12.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Name"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=9.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Did This Help You"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=18.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Description"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=7.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Image"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=10.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Updated on"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" py="1" minWidth=6.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text>
                </th>
            </tr>
            <tr *ngFor="let Feedback of list.list;let i=index">
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=4.5>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{i+1}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=12.5>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{Feedback?.meta?.activity?.updated_by?.first_name +' '+ Feedback?.meta?.activity?.updated_by?.last_name}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=9.5>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{Feedback?.type}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=18.5>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{Feedback?.data?.description}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=7.5>
                    <m-image h="3;1336:2.9;1280:2.8;1024:2.7;789:2.6;460:2.5;320:2.4" w="3;1336:2.9;1280:2.8;1024:2.7;789:2.6;460:2.5;320:2.4" src="https://new-api.mesbro.com/uploads/{{Feedback?.upload}}"></m-image>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=10.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text= "{{Feedback?.meta?.activity?.updated_at | date : 'dd MMM YYYY'}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" minWidth=6.5>
                    <m-image (click)="delete(Feedback._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
                </td>
            </tr>
        </table>

    </div>
</div>

