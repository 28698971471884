<div a-style h="calc(100vh - 15rem - 10rem)">
    <div a-style bg=#F5F5F5 h=21 align="center" display="flex" justify="space-between">
        <div a-style display="flex" gap=1.4 px=10>
            <m-image bg=white brRadius='.6' br="1px solid #E4E4E4" shadow="0 3px 6px 0 #f1f1f1"
                [src]="user?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+user?.personal_details?.profile_picture) : gs.enums.images.image154"
                mr="1.3" w=13.7 h=13.7>
              </m-image>
            <div>
                <p a-style color=#2479AB fs=2 align="left" >{{user?.personal_details?.first_name}} {{user?.personal_details?.last_name}}</p>
                <p a-style color=#707070 fs=1.3 align="left" mt=.5>{{user?.contact_details?.mobile_number_primary}}</p>
                <p a-style color=#707070 fs=1.3 align="left">{{user?.contact_details?.email_address_primary}}</p>
                <div a-style align="left">
                    <button a-style br="1px solid #F35325" bg=#FFFFFF brRadius="0.4" mt=2.9 py=.5 px=1.4 fs=1.3>My Profile</button>
                </div>
            </div>
        </div>

            <div a-style display="flex"  gap=5  px=5.3>
                <ng-container [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{passdata : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Mesbro Cloud',title:'Mail Display Languagea Mail Display Language'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{passdata : { img : '../assets/images/assets/settingMessage.svg',heading:'Mesbro Cloud',title:'Mail Display Languagea Mail Display Language'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{passdata : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Mesbro Cloud',title:'Mail Display Languagea Mail Display Language'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{passdata : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Mesbro Cloud',title:'Mail Display Languagea Mail Display Language'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{passdata : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Mesbro Cloud',title:'Mail Display Languagea Mail Display Language'}}"></ng-container>
            </div>
    </div>
    <div a-style display="flex" justify=center mt=6.6>
        <m-input mt=4 maxWidth="57" [type]="gs.enums.inputs.text" [columns]="1/3" placeHolder="Search"
            postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg"
            postfix_bg="transparent" maxWidth=40.1></m-input>
    </div>
    <div a-style display="flex" gap=6.6 justify=center  mt=7.09 flexWrap="wrap" mx=20.3 >
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Language',title:'US English',openModal:language}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Time Zone',title:user?.location_details?.timezone,openModal:timeZone}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Change mobile number',title1:user?.location_details?.country_calling_code,title:user?.contact_details?.mobile_number_primary,openModal:changeMobileNumber}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Change Password',title:'***********',openModal:changePassword}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Font Size',title:'Medium',openModal:fontSize}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Spelling suggestions',title:'No',openModal:spellingSuggestions}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Grammar suggestions',title:'No',openModal:grammarSuggestions}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Activity History',title:'Clear Activity History',openModal:activityHistory}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Search history',title:'Clear Search history',openModal:serchHistory}}"></ng-container>
        <ng-container [ngTemplateOutlet]="settingCard" [ngTemplateOutletContext]="{setting : { img : '../assets/images/assets/setting_Coloured.svg',heading:'Watch History',title:'Clear Watch History',openModal:watchHistory}}"></ng-container>
    </div>

<ng-template #imgAtr let-passdata=passdata>
    <div a-style w=15.4>
    <img src={{passdata?.img}} mb=1.6 w=3 h=3 a-style>
    <p a-style fs=1.3>{{passdata?.heading}}</p>
    <p a-style color=#B2B2B2 fs=1.3 >{{passdata?.title}}</p>
    </div>
</ng-template>

<ng-template #settingCard let-setting=setting >
 <div a-style display=flex gap=2 (click)="open(setting.openModal)">
    <img mb=1.6 w=3 h=3 src="{{setting?.img}}">
    <div>
        <p a-style fs=1.3>{{setting?.heading}}</p>
        <div a-style display="flex" w=15.4>
          <p a-style color=#B2B2B2 fs=1.3  ml=0 mr=0>{{setting?.title1}}</p>
          <p a-style color=#B2B2B2 fs=1.3 ml=0 mr=0>{{setting?.title}}</p>
        </div>
    </div>
  </div>
</ng-template>
<m-modal #language a-style width="46.7" >
  <div a-style m=2.4>
    <p a-style fs=1.6  color=##333333 mb=2.4>Language</p>
    <p a-style fs=1.3  color=##333333 mb=2.4>Display Language</p>
  <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9" color="#333333"
      [list]="['language']" bindValue="label"
      bindLabel="label" placeHolder="English (US)"
      [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>

      <div a-style w="100%" align="right" mt=2.1 >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Update" br="none"  a-style></m-button>
    </div>
 </div>

</m-modal>
<m-modal #timeZone a-style width="46.7">
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Date & Time</p>
    <p a-style fs=1.3  color=##333333 mb=1.6 mt=0>Date format</p>
    <m-input pl=0 pr=0 mb=1.6 [columns]="1/3" minWidth="13.3"
        [list]="['language']" bindValue="label"
        bindLabel="label" placeHolder="10 / 8 / 2020"
        [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>

        <p a-style fs=1.3  color=#333333 mb=1.6 mt=0>Time format</p>
    <m-input pl=0 pr=0 mb=1.6 [columns]="1/3" minWidth="27.1"
        [list]="gs.masters['language']" bindValue="label"
        bindLabel="label" placeHolder="1:01 AM - 11:55 PM"
        [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>

        <p a-style fs=1.3  color=#333333 mb=1.6 mt=0>Time zone</p>
    <m-input pl=0 pr=0 mb=1.6 [columns]="1/3" minWidth="41.9"
        [list]="gs.masters['language']" bindValue="label"
        bindLabel="label" placeHolder="(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
        [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Update" br="none"  a-style></m-button>
     </div>
 </div>
</m-modal>
<m-modal #changeMobileNumber width="46.7">
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Clear search history</p>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [type]="gs.enums.inputs.text" placeHolder="XXXXX"></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
</m-modal>
<m-modal #changePassword  width="46.7">
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Clear search history</p>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [list]="['language']" bindValue="label"
      bindLabel="label" placeHolder="English (US)"
      [type]="gs.enums.inputs.text" placeHolder="XXXXX"></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
</m-modal>
<m-modal #fontSize  width="46.7">
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Font Size</p>
    <p a-style fs=1.3 color=#333333 mb=2.4> Select font size for better user interface</p>

    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton" radioName="fontsize"  label="Small" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" radioName="fontsize" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton"  label="Medium" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" radioName="fontsize" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton"  label="Turn Off" [onlyInput]=true></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
</m-modal>
<m-modal #spellingSuggestions  width="46.7">
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Spelling suggestions</p>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [list]="['language']" bindValue="label"
      bindLabel="label"
      [type]="gs.enums.inputs.radioButton"  label="Turn On" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [list]="['language']" bindValue="label"
      bindLabel="label"
      [type]="gs.enums.inputs.radioButton"  label="Turn Off" [onlyInput]=true></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
</m-modal>
<m-modal #grammarSuggestions>
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Grammar suggestions</p>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [list]="['language']" bindValue="label"
      bindLabel="label"
      [type]="gs.enums.inputs.radioButton"  label="Turn On" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [list]="['language']" bindValue="label"
      bindLabel="label"
      [type]="gs.enums.inputs.radioButton"  label="Turn Off" [onlyInput]=true></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
</m-modal>
<m-modal #activityHistory>
  <div a-style m=2.4>
    <p a-style fs=1.6  color=#333333 mb=2.4>Grammar suggestions</p>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton"  label="Turn On" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton"  label="Turn Off" [onlyInput]=true></m-input>
    <m-input pl=0 pr=0 mb=1.4 [columns]="1/3" minWidth="41.9"
      [type]="gs.enums.inputs.radioButton"  label="Turn Off" [onlyInput]=true></m-input>

      <div a-style w="100%" align="right" >
        <m-button [types]="gs.enums.buttons.button87" gap=0 text="Delete" br="none"  a-style></m-button>
    </div>
 </div>
 </m-modal>
<m-modal #serchHistory>Madhuri</m-modal>
<m-modal #watchHistory>Madhuri</m-modal>


