import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-review-list',
  templateUrl: './review-list.component.html',
  styles: [
  ]
})
export class ReviewListComponent implements OnInit {
  @Input() modal: any
  application = 'Generic';
  component = 'review-list';

  list = {
    list: [],
    count: 0
  }
  params = {
    type: ''
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }
  async get_list() {
    let response = await this.gs.get_data_list(this.application, this.component, [])
    this.list = response;
    console.log(this.list.list);

  }
  ngOnInit(): void {
    this.get_list()
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
      console.log(this.params);
    })
  }
}
