<h1 *ngIf="type==gs.enums.texts.heading1" a-style [fs]="fs?fs:1.6" color="#333333" fw="500" [my]="my" [mb]="mb" [ml]="ml" [textCenter]="textCenter" [mt]="mt">{{text}}</h1>
<h1 *ngIf="type==gs.enums.texts.heading2" a-style [fs]="fs?fs:2.4" color="#333333" fw="500" [mb]="mb" [ml]="ml" [mt]="mt" [align]="align"> {{text}}</h1>
<p *ngIf="type==gs.enums.texts.paragraph1" a-style fs="1.4" [textWrap]="textWrap" cursor="pointer" [ellipsis]="ellipsis" color="#333333" [px]="px" [bg]="bg" [py]="py" fw="400" [mt]="mt" [mx]="mx" [my]="my" [textCenter]="textCenter" [gap]="gap" [flexGrow]="flexGrow" [mb]="mb" [ml]="ml" [mt]="mt?mt:1.3" [w]="w" [style.color]="color" [innerHTML]="innerHTML || text"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph2" a-style cursor="pointer" fs="1.4" color="#f35325" fw="400" [align]="align" a-style [pl]="pl?pl:1" [mb]="mb" [pb]="pb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph3" a-style fs="1.4" color="#b2b2b2" fw="400" [mt]="mt" wordBreak='break-word' [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph4" a-style fs="1.8" [textWrap]="textWrap" color="#FFFFFF" fw="400" [mt]="mt" [align]="align" a-style [ml]="ml?ml:36.8"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph5" a-style fs="1.4" cursor="pointer" [color]="color?color:'#333333'" [mt]="mt" fw="400" a-style [ml]="ml?ml:1.6" [mb]="mb" [mr]="mr" [textWrap]="textWrap"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph6" a-style fs="2" color="#f35325" fw="500"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph7" a-style fs="1.4" color="#f35325" fw="500" cursor="pointer" [mt]="mt" [ml]="ml"> {{text}} </p>
<p *ngIf="type==gs.enums.texts.paragraph8" a-style fs="1.3" color="#b2b2b2" fw="300" [pb]="pb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph9" a-style fs="1.3" color="#333333" [textWrap]="textWrap" fw="500" [align]="align" [cursor]="cursor" [mt]="mt" [ml]="ml" [mb]="mb" [px]="px"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph10" a-style fs="1.3;1366:0.9" [textWrap]="textWrap" color="#707070" fw="400" [mb]="mb" [mx]="mx" [textCenter]="textCenter" [w]="w" [ellipsis]='ellipsis'> {{text}}</p>
<!-- <p *ngIf="type==gs.enums.texts.paragraph9" a-style fs="1.3" color="#333333" fw="500" [mt]="mt" [mb]="mb?mb:1"> {{text}}</p>29.8;1024:100% -->
<!-- <p *ngIf="type==gs.enums.texts.paragraph10" a-style fs="1.1" color="#707070" fw="400" [mb]="mb" [mx]="mx" [textCenter]='text_align'> {{text}}</p> -->
<!-- <p *ngIf="type==gs.enums.texts.paragraph11" a-style fs="1.1" color="#333333" fw="400" [textCenter]="textCenter" [ml]=ml> {{text}}</p> -->
<p *ngIf="type==gs.enums.texts.paragraph11" a-style fs="1.1" color="#333333" fw="400" wordBreak='break-word' [textCenter]="textCenter" [w]=w [ml]=ml [mt]=mt> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph12" a-style fs="1.6" [fs]="fs" color="#333333" fw="400" [mb]="mb" [px]="px" [mx]="mx" cursor="pointer" [ml]="ml" [my]="my" [mt]="mt" [textWrap]="textWrap" [align]='align' [w]="w" [ellipsis]="ellipsis">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph13" a-style fs="1.1" color="#B2B2B2" fw="300" [align]="align" [mb]="mb" [mt]="mt" [textCenter]="textCenter "> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph14" a-style fs="1.1" color="#333333" fw="300" [align]="align" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph15" a-style fs="1.4" color="#333333" fw="600" [mb]="mb" [ml]="ml" [mt]="mt">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph16" a-style fs="1.6;1366:1.3" color="#FFFFFF" fw="400" [mb]="mb" [align]="align" [mt]="mt" [position]="position" [bg]="bg" [bottom]="bottom" [w]="w" [h]="h" [pt]="pt" [brRadius]="brRadius" [brTopLeftRadius]="brTopLeftRadius?brTopLeftRadius:0" [brTopRightRadius]="brTopRightRadius?brTopRightRadius:0" [brBottomRightRadius]="brBottomRightRadius?brBottomRightRadius:0" [brBottomLeftRadius]="brBottomLeftRadius?brBottomLeftRadius:0" [textCenter]="textCenter ">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph17" a-style fs="3.2" color="#333333" fw="900" [mb]="mb" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph18" a-style fs="3.2;1366:2" color="#FFFFFF" fw="300" [mb]="mb" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph19" a-style fs="1.3" color="#FFFFFF" fw="400" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph20" a-style fs="1.3" color="#333333" fw="400" [textCenter]="textCenter" [textWrap]="textWrap" [mb]="mb" [mt]="mt" [mx]="mx" [w]="w" [ml]="ml"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph21" a-style fs="1.6" color="#333333" fw="600" [ml]="ml" [py]="py" [textWrap]="textWrap"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph22" a-style fs="1.4" color="#006BFF" fw="300" [textCenter]="textCenter" [textWrap]="textWrap" cursor="pointer"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph23" a-style fs="1.3" [fs]="fs" color="#707070" fw="300" [mt]="mt" [textWrap]="textWrap" [ellipsis]="ellipsis" [w]="w" [lineHeight]="lineHeight" [textCenter]="textCenter " [mb]="mb" [maxWidth]="maxWidth" [innerHTML]="text"> </p>
<p *ngIf="type==gs.enums.texts.paragraph24" a-style fs="3" color="#333333" fw="400" [mt]="mt"> {{text}}</p>
<h1 *ngIf="type==gs.enums.texts.paragraph25" a-style fs="2" color="#333333" fw="600" [p]="p" [mt]="mt">{{text}}</h1>
<h1 *ngIf="type==gs.enums.texts.paragraph26" a-style fs="2" color="#333333" fw="400" [ml]="ml" [p]="p" [textCenter]="textCenter" [ellipsis]="ellipsis" [lineClamp]="lineClamp" [lineHeight]="lineHeight" [mb]="mb" [mt]="mt" [textWrap]="textWrap" [maxWidth]="maxWidth" [minWidth]="minWidth" [w]="w" [brBottom]="brBottom">{{text}}
</h1>
<h1 *ngIf="type==gs.enums.texts.paragraph27" a-style fs="3.6" color="#333333" fw="400">{{text}}</h1>
<p *ngIf="type==gs.enums.texts.paragraph28" cursor="pointer" a-style fs="1.4" color="#2479AB" fw="400"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph29" a-style fs="1.3" color="#006BFF" fw="400" cursor="pointer" [mt]="mt" [ml]="ml" [mb]="mb" [textWrap]="textWrap" [align]="align" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph30" a-style fs="4.5" color="#707070" fw="400" [mt]="mt" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph31" a-style fs="3.8" color="#707070" fw="400" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph32" a-style fs="1.2" color="#707070" fw="400" [mb]="mb" textCenter="right">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph33" a-style fs="2.2" color="#333333" fw="400" [mb]="mb" textCenter="right">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph34" a-style fs="1.6" color="#006BFF" fw="400" [mt]="mt" [mb]="mb" [mx]="mx">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph35" a-style fs="1.4" color="#333333" fw="400" [mt]="mt" [mb]="mb" [mr]="mr" [ml]="ml" [pl]="pl" [pb]="pb" [p]="p" [bg]="bg" [color]="color" [ellipsis]="ellipsis" [lineClamp]="lineClamp" [textWrap]="textWrap" [cursor]="cursor" [align]="align" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph36" a-style fs="3.2" color="#333333" fw="600" [mb]="mb" [pb]="pb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph37" a-style fs="1.3" color="#006BFF" fw="400" [mt]="mt" [mb]="mb"> {{text}}</p>
<!-- h="21.05;700:9.5" -->
<p *ngIf="type==gs.enums.texts.paragraph38" class="lineClamp5" a-style [title]="title" [fs]="fs?fs:'1.3;1366:0.9'" fw="400" [mt]="mt" [ml]="ml" [ellipsis]="ellipsis" [brBottom]="brBottom" cursor="pointer" [my]="my" [py]="py" [px]="px" [h]="h" [pt]="pt" [minHeight]="minHeight" [bg]="bg" [p]="p" [pl]="pl" [minWidth]="minWidth" [maxWidth]="maxWidth" [lineClamp]="lineClamp" [mr]="mr" [mb]="mb" [flexGrow]="flexGrow" [cursor]="cursor" [w]="w" [textWrap]="textWrap" [textCenter]="textCenter"
    [style.color]="hover && (mouse_over || current_value)?hover:'#333333'" (mouseover)="mouse_over=true" (mouseleave)="mouse_over=false" [innerHTML]="text"> </p>
<!-- 1.3;1366:0.9 -->
<!-- [fs]="fs" -->
<p *ngIf="type==gs.enums.texts.paragraph39" a-style fs="1.4" [color]="color" [fw]="fw?fw:400" fw="400" [mt]="mt" a-style [mb]="mb" [pl]="pl" [px]="px" [textWrap]="textWrap" [textCenter]='text_align' [align]="align" [innerHTML]="text" wordBreak='break-word'> </p>
<p *ngIf="type==gs.enums.texts.paragraph40" a-style fs="1.6;1366:0.9" color="#333333" fw="500" [ellipsis]="ellipsis" [textWrap]="textWrap" [mb]="mb" [ml]="ml" [px]="px" [mt]="mt" [pb]="pb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph41" a-style fs="2.4" color="#333333" fw="500" [ml]="ml" [mb]="mb" [textCenter]='text_align'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph42" a-style fs="1.6" color="#006BFF" fw="400" [mt]="mt" [mb]="mb" [mr]="mr" [ml]="ml"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph43" a-style fs="1.3" color="#333333" fw="600" [mt]="mt" [mb]="mb" [ellipsis]="ellipsis" [textWrap]="textWrap" [minWidth]="minWidth"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph44" a-style fs="1.3" color="#727272" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph45" a-style fs="1.3" color=#B2B4B4 [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph46" a-style fs="1.3" color="#B2B2B2" fw="400" cursor="pointer" [ellipsis]="ellipsis" [w]='w' [align]="align" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr" [minWidth]="minWidth">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph47" a-style fs="1.6" color="#727272" fw="400" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph48" a-style fs="1.4" color="#FFFFFF" fw="500" [mx]="mx" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph49" a-style fs="1.3" [color]="color?color:'#333333'" fw="400" [mx]="mx" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr" [textCenter]="textCenter" [textWrap]="textWrap" [ellipsis]="ellipsis"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph50" a-style fs="1.1" color="#FFFFFF" fw="400" cursor="pointer" [mx]="mx" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph51" a-style fs="1.8" color="#333333" fw="500" [mb]="mb" [mt]="mt" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph52" a-style fs="1.3" color="#ea2f00" fw="400" [mb]="mb" [mt]="mt" [textCenter]="textCenter" cursor="pointer"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph53" cursor="pointer" a-style fs="1.4" color="#707070" fw="400" [mb]="mb" [mt]="mt" [px]="px"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph54" a-style fs="2" color="#b2b2b2" fw="400" [mb]="mb" [mt]="mt" [p]="p">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph55" a-style fs="1.4" color="#e4e4e4" fw="400" [mb]="mb" [mt]="mt" [p]="p" [mr]="mr">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph56" a-style fs="1.8" color="#FFFFFF" fw="400" [mb]="mb" [mt]="mt" [align]="align" a-style> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph57" a-style fs="1.4" color="#F6F8FB" fw="300" [mb]="mb" [mt]="mt" [align]="align" a-style> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph58" a-style fs="1.6" color="#008000" fw="600" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph59" a-style fs="1.6" color="#FF0000" cursor="pointer" fw="600" [mt]="mt">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph60" a-style fs="6.5" color="#151723" fw="700" [mt]="mt" [my]="my"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph61" a-style fs="1.6" color="#999fa5" fw="400" [mb]="mb" [ml]="ml" [my]="my" [mt]="mt" [align]='align'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph62" a-style fs="1.4" color="#767676" fw="400" [mb]="mb" [mt]="mt" [p]="p" [mr]="mr">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph63" a-style fs="1.2" color="#767676" fw="400" [mb]="mb" [mt]="mt" [p]="p" [mr]="mr" [pl]="pl">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph64" a-style fs="1.4" color="#707070" fw="400" [mb]="mb" [textWrap]=textWrap [w]=w> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph65" a-style fs="2.9" color="#333333" fw="400" [ml]="ml" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph66" a-style fs="1.2" color="#707070" fw="400" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph67" a-style fs="2.8" color="#333333" fw="400" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph68" a-style fs="4.8" color="#FFFFFF" fw="400" [mb]="mb" [h]="h"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph69" a-style fs="1.6" color="#FF1313" fw="400" [mb]="mb" [h]="h"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph70" a-style fs="1.8" color="#333333" fw="400" [mb]="mb" [mt]="mt" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph71" a-style fs="2.6" color="#333333" fw="700" [mb]="mb" [mt]="mt" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph72" a-style fs="1.3" color="#FF0000" fw="500" [mt]="mt" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph73" a-style fs="3.2" color="#333333" fw="500" [mb]="mb" [ml]="ml" [mr]="mr" cursor="pointer" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph74" a-style fs="1.4" color="#006BFF" fw="400" [textWrap]="textWrap" cursor="pointer" [mb]="mb"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph75" a-style fs="1.1" color="#b2b2b2" fw="400" [textWrap]="textWrap" cursor="pointer" [ml]="ml"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph76" a-style fs="3" color="#333333" fw="500" [mt]="mt" [mb]=1.2> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph77" a-style fs="1.4" color="#FFFFFF" fw="400" [mx]="mx" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph78" a-style fs="3.6" color="#333333" fw="600" [mx]="mx" [fs]="fs" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph79" a-style fs="2.5" textCenter=center color="#FFFFFF" fw="500" [mt]="mt" [mb]=1.2> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph80" a-style fs="1.3" textCenter=center color="#b2b2b2" fw="500" [mt]="mt" [mb]=1.2> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph81" a-style fs="1.3" color="#333333" fw="600" [mb]="mb" [align]="align" [pl]="pl"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph102" a-style fs="1.2" color="#707070" fw="400" [mb]="mb" [align]="align">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph103" a-style fs="1.1" color="#1d8ecf" fw="300" [align]="align" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph104" a-style fs="2.2" [color]="color?color:'#333333'" fw="500" [mx]="mx" [my]="my" [mb]="mb" [mt]="mt" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph105" a-style fs="2.5" color="#0b38ed" fw="400" [mb]="mb" [align]="align" [pl]="pl"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph106" a-style fs="1.1" color="#727272" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph107" a-style fs="1.3" [textWrap]="textWrap" color="#000000" fw="400" [flexGrow]="flexGrow" [mb]="mb" [w]="w" [ml]="ml" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph108" a-style fs="1.7" color="#707070" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [align]="align"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph109" a-style fs="3.5" color="#333333" fw="500" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [align]="align"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph110" a-style fs="1.1" color="#333333" fw="500" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [align]="align" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph111" a-style fs="1.6" color="#767676" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [align]="align"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph112" a-style fs="1.6" color="#3082FF" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [align]="align"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph113" a-style fs="1.4" color="#3082FF " fw="400" [mb]="mb" [mt]="mt" [p]="p" [mr]="mr">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph114" a-style [fs]="fs?fs:1.4" [color]="color" [fw]="fw?fw:500" [textCenter]="textCenter" [textWrap]="textWrap" [mb]="mb" [mt]="mt" [mx]="mx" [w]="w" [ml]="ml" [ellipsis]='ellipsis' [right]="right" [position]="position" cursor="pointer">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph115" a-style fs="1.3" [color]="color" fw="500" [textCenter]="textCenter" [textWrap]="textWrap" [mb]="mb" [mt]="mt" [mx]="mx" [w]="w" [ml]="ml" [ellipsis]='ellipsis'>{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph116" a-style fs="1.1" color="#707070" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr" [maxWidth]="maxWidth" wordBreak='break-word'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph117" a-style fs="1.2" color="#1d8ecf" fw="500" [align]="align" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph118" a-style fs="2" color="#660099" fw="500"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph119" a-style fs="3" color="#202124" fw="400" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph120" a-style fs="1.8" color="#202124" fw="400" [mb]="mb" [mt]="mt" [textCenter]="textCenter"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph121" a-style fs="1.6" color="#202124" fw="400" [ml]="ml" [py]="py" [textWrap]="textWrap"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph122" a-style fs="1.6" color="#202124" fw="600" [ml]="ml" [py]="py" [textWrap]="textWrap"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph123" a-style fs="2.2" [fs]="fs" color="#202124" fw="400" [mt]="mt"> {{text}}</p>
<!-- <p *ngIf="type==gs.enums.texts.paragraph124" a-style fs="1.3" color="#B2B2B2" fw="400" [mt]="mt" cursor="pointer"> {{text}}</p> -->
<p *ngIf="type==gs.enums.texts.paragraph124" a-style fs="1.3" color="#B2B2B2" fw="400" cursor="pointer" [mr]="mr">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph125" a-style fs="1" color="#333333" fw="400" cursor="pointer" [mb]="mb?mb:0.5">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph126" a-style fs="0.9" color="#333333" fw="400" cursor="pointer" [mb]="mb?mb:0.5"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph127" a-style fs="0.9" color="#05A6F0" fw="400" cursor="pointer"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph128" a-style fs="0.9" color="#05A6F0" fw="400" cursor="pointer"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph129" a-style [fs]="fs" fs="1.6" [color]="color?color:'#F35325'" [w]=w [h]=h [fw]="fw?fw:400" cursor="pointer" [ellipsis]="ellipsis" [overflow]=overflow [lineClamp]="lineClamp" [textCenter]="textCenter" [textWrap]="textWrap" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph130" a-style fs="0.9" color="#81BC06" fw="400" cursor="pointer"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph131" a-style fs="1.3;1366:0.9 " color="#2479AB" [fw]="fw?fw:300" cursor="pointer" [ellipsis]='ellipsis'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph132" a-style fs="1.8" color="#05A6F0" fw="400" cursor="pointer" [ellipsis]='ellipsis'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph133" a-style fs="1.3" color="#05A6F0" fw="400" cursor="pointer" [ellipsis]='ellipsis' [ml]="ml"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph134" a-style [fs]="fs" color="#333333" fw="400" [mb]="mb" [mt]="mt" [p]="p" [mr]="mr" [pl]="pl">{{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph135" a-style fs="1.6" cursor="pointer" color="#707070" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph136" a-style fs="2.4" color="#333333" fw="400" [ml]="ml" [mb]="mb" [mt]="mt" [textCenter]='text_align'> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph137" a-style fs="1.2" color="#B2B2B2" fw="400" cursor="pointer" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph138" cursor="pointer" a-style fs="1.1" color="#2479AB" fw="400" ml="0.8">
    {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph139" a-style fs="1.3" [align]="align" color="#05A6F0" fw="400" cursor="pointer" [mb]="mb" [mt]="mt"> {{text}}</p>
<!-- <p *ngIf="type==gs.enums.texts.paragraph135" a-style fs="1.6" color="#707070" fw="400" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr"> {{text}}</p> -->
<p *ngIf="type==gs.enums.texts.paragraph140" a-style fs="1.6" [align]="align" color="#05A6F0" fw="500" cursor="pointer" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph141" a-style fs="1.3" [align]="align" color="#707070" fw="400" cursor="pointer" [mb]="mb" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph142" a-style fs="3" color="#ffffff " fw="500" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph143" a-style fs="1.5" color="#333333" fw="400" [mt]="mt"> {{text}}</p>
<p *ngIf="type==gs.enums.texts.paragraph144" a-style fs="1.1" color="#2479AB" [fw]="fw?fw:300" cursor="pointer" [ellipsis]='ellipsis'> </p>
<p *ngIf="type==gs.enums.texts.paragraph145" a-style fs="1" bg="#e4e5e5" align="center" fs="1.3" w="fit-content" p="1" brRadius="0.6" >{{text}} </p>