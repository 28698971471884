<div a-style [style.width.rem]="width" [style.minWidth.rem]="minWidth" position="relative" w="49.1"
    maxHeight="calc(100vh - 7.5rem)" overflowY="scroll" class="no_scroll_bar" draggable="true" (drag)="drag($event)">
    <div a-style mt="1.7">
        <ng-container *ngFor="let item of side_bar_items" [ngTemplateOutlet]="mainMenu"
            [ngTemplateOutletContext]="{item:item}"></ng-container>

    </div>
</div>
<ng-template #mainMenu let-item=item>
    <ng-container *ngIf="item">
        <div (click)="item.expanded=!item.expanded"
            *ngIf="!item.application || !item.permission || (item.application | sideMenuPermission : item.permission : gs.user.permissions)"
            [routerLink]="item.link" [queryParams]="item.link_params" a-style display="flex" align="center"
            justify="space-between" maxWidth="80%" cursor="pointer" ml="2">
            <div a-style display="flex" my="1.2" ml="0.8" bg="#ffffff">
                <m-image *ngIf="item.image" src={{item.image}} h="1.6" w="1.6" mr="2.2"></m-image>
                <m-text [type]="gs.enums.texts.paragraph62" text={{item.title}}></m-text>
            </div>
            <m-image *ngIf="item.children?.length" src="https://mesbro.in/assets/images/mail/mail-right-direction.svg"
                h="1.6" w="1.6" ml="2.5"></m-image>
        </div>
        <div *ngIf="item.expanded && (!item.application || !item.permission || (item.application | sideMenuPermission : item.permission : gs.user.permissions))"
            a-style ml=4>
            <ng-container *ngFor="let nestedItem of item.children" [ngTemplateOutlet]="mainMenu"
                [ngTemplateOutletContext]="{item:nestedItem}">

            </ng-container>
        </div>
    </ng-container>
</ng-template>