export const side_menu = {
  home: [
    { title: 'inbox', icon: 'assets/images/Icon_Bank/Inbox_Coloured.svg', isClicked: false},
    { title: 'draft', icon: 'assets/images/Icon_Bank/Edit_Without_Circle_Coloured.svg',isClicked: false },
    { title: 'sent', icon: 'assets/images/Icon_Bank/Sent_Mail_Coloured.svg' ,isClicked: false},
    { title: 'snooze', icon: 'assets/images/Icon_Bank/Snooze_Coloured.svg' ,isClicked: false},
    { title: 'scheduled', icon: 'assets/images/Icon_Bank/Schedule_Mail_Coloured.svg' ,isClicked: false},
    { title: 'spam', icon: 'assets/images/Icon_Bank/Spam_Coloured.svg' ,isClicked: false},
    { title: 'archive', icon: 'assets/images/Icon_Bank/Archive_Coloured.svg' ,isClicked: false},
    // { title: 'favourite', icon: 'assets/images/Icon_Bank/Favourite_Coloured.svg' ,isClicked: false},
    { title: 'trash', icon: 'assets/images/Icon_Bank/Trash_Coloured.svg',isClicked: false},
  
   
  ],
  mail_general_settings: [
    { title: "Language and time", icon: '' },
    { title: "Appearance", icon: '' },
    { title: "Notification", icon: '' },
    { title: "Accessibility", icon: '' },
    { title: "Grammar", icon: '' },
    { title: "Spelling", icon: '' },
    { title: "Auto-correct", icon: '' },
    { title: "Smart Compose", icon: '' },
    { title: "Converstion Setting", icon: '' },
    { title: "Devices", icon: '' },
    { title: "Distribution groups", icon: '' },
    { title: "Storage", icon: '' },
    { title: "Privacy and data", icon: '' },
    { title: "Search", icon: '' },
  ]
}
