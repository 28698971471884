import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';

@Component({
  selector: 'm-faq-answer',
  templateUrl: './faq-answer.component.html',
  styles: [
  ]
})
export class FaqAnswerComponent implements OnInit {
  list = {
    list: <any[]>[],
    count: 0
  }
  answers: any
  constructor(public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder, public hps: HttpProviderService) { }
  details:any;
  ngOnInit(): void {
  }
  async get_answer() {
    let faq = {
    }
    this.list = await this.gs.hps.post('generic', 'answer', 'list', faq)
    this.list.list.filter((map: any) => {
    })
    console.log(this.list);
  }
  async submit(form: any) {
    try {
      let body = form;
      body.parents = {
        data: this.details
      }
      // body.type = this.type
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.list.list.unshift(body);
      this.list.count++;
      this.gs.toast('Mesbro Questions', 'Question submitted successfully', 'success');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
}
