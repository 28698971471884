import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { NewChatService } from 'src/mesbro/applications/new-chat/service/new-chat.service';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';

@Component({
  selector: 'm-organization-conversation',
  templateUrl: './organization-conversation.component.html',
  styles: [
  ]
})
export class OrganizationConversationComponent implements OnInit {
  @Input() details: any
  @Input() detail: any;
chatOrganization: ''
closeIcons:boolean=true;
showSend:boolean=false;
chat: any;
chat_list: any[] = []
file: any
showParentDiv:boolean=false
formdata: any
users: IUserData;
message = '';
showEmojiPicker = false;
visible: any = {
  emojis: false,
  set_delivery: false,
  attachments: false,
  app: false,
  text_format: false
};
onFocus() {
  console.log('focus');
  this.showEmojiPicker = false;
}
onBlur() {
  console.log('onblur')
}

@ViewChild('scrollMe') private myScrollContainer: ElementRef;

  inputVariable: boolean = true;
  form: FormGroup | undefined;
  constructor(public gs: GlobalService,  public cs: NewChatService, public fb: FormBuilder) { }

  ngOnInit(): void {

    this.cs.selected_chat.subscribe(chat => {
      this.details = chat;
      console.log(this.details);
      this.initializeForm();
    })
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
      console.log(this.users);
    });
  }

  

  send(){
    this.showParentDiv=true
    this.closeIcons=true
    this.showSend=false
  }

  openInput(){
    this.closeIcons=false
    this.showSend=true
  }
  form_data: any = {
    text: null,
    file: null,
    html : null,
    chatOrganization: ''
  }



  async add_conversation_group(details: any) {
    try {
      let current_user = this.gs.user.users.activeUser;
      let body = {
        subtype: 'personal',
        members: {
          users: {
            [current_user?._id || '']: {
              first_name: current_user?.personal_details.first_name,
              last_name: current_user?.personal_details.last_name,
              profile_picture: current_user?.personal_details.profile_picture,
              emailID: current_user?.contact_details.email_address_primary,
              mobile_number: current_user?.contact_details.mobile_number_primary,
              country_code: current_user?.location_details.country_calling_code,
              _id: current_user?._id
            },
            [details?.basicDetails?.userId || '']: {
              first_name: details?.meta?.activity?.updated_by?.first_name,
              last_name: details?.meta?.activity?.updated_by?.last_name,
              profile_picture: details?.meta?.activity?.updated_by?.profile_picture,
              emailID: details?.meta?.activity?.updated_by?.first_name.email,
              mobile_number: details?.meta?.activity?.updated_by?.first_name.mobile,
              _id: details?.meta?.activity?.updated_by?.first_name.userId,
              // _id: user?._id
            }
          },
        },
        last_message: null,
        type: 'list'
      }
      await this.gs.hps.post('generic', 'conversation-group', 'add', body);
      // this.gs.router.navigate(['/new-chat', 'details'], { queryParams: { type: 'conversation' } });
    } catch (error: any) {
      this.gs.toast('Security', error?.error?.message, 'error')
    }
  }



  initializeForm() {
    let receiver: any = undefined
    if (this.details.title) {
      let user_ids = Object.keys(this.details.members.users);
      user_ids = user_ids.filter((u: any) => u != this.gs.user?.users?.activeUser?._id);
      user_ids.forEach(u => {
        receiver = receiver || {}
        receiver.user = receiver.user || {}
        receiver.user[u] = true;
      })
    }
    this.form = this.fb.group({
      application: 'Generic',
      component: 'Conversation',
      type: 'chat',
      sender: {
        user: {
          [this.gs.user?.users?.activeUser?._id || '']: true,
        }
      },
      receiver: receiver || {
        user: {
          [this.details?.receiver?._id || '']: true,
        }
      },
      data: this.fb.group(this.form_data),
      parents: {
        list: this.details._id
      }
    })
  }

  // async submit() {
  //   console.log("this.file", this.form?.value);
  
  //   let body = this.form?.value;

  //   console.log("this.file", body);
  //   body.data.file = this.file;
  //   console.log(body.data.file)
  //   let response = await this.gs.hps.post('generic', 'conversation', 'add', body);
    
  //   body.meta = {
  //     roles: {
  //       owner: {
  //         user: {
  //           [this.gs.user.users?.activeUser?._id || '']: true
  //         }
  //       }
  //     },
  //     activity: {
  //       created_at: new Date(),
  //       updated_by: {
  //         first_name: this.gs.user.users.activeUser?.personal_details.first_name,
  //         last_name: this.gs.user.users.activeUser?.personal_details.last_name,
  //       }
  //     }
  //   }
  //   body._id = response._id
  //   this.form?.get('data')?.get('text')?.setValue('')
  //   this.form?.get('data')?.get('file')?.setValue([])
  //   this.chat_list.push(body);
  //   this.cs.new_chat_added.next(body);
  //   this.details.last_message = body;
  //   // console.log(this.details.last_message);p
  //   this.gs.scrollToBottom(this.myScrollContainer)
  //   this.file="";
  // }

  

  async submit() {
 
    console.log("this.file", this.file);
    let body = this.form?.value;
    body.data.file = this.file
    console.log(body.data)
    let response = await this.gs.hps.post('generic', 'conversation', 'add', body);
    body.meta = {
      roles: {
        owner: {
          user: {
            [this.gs.user.users?.activeUser?._id || '']: true
          }
        }
      },
      activity: {
        created_at: new Date(),
        updated_by: {
          first_name: this.gs.user.users.activeUser?.personal_details.first_name,
          last_name: this.gs.user.users.activeUser?.personal_details.last_name,
        }
      }
    }
    body._id = response._id
    this.form?.get('data')?.get('text')?.setValue('')
    this.chat_list.push(body);
    this.cs.new_chat_added.next(body);
    console.log(this.cs.new_chat_added.next(body),"jjjjjjjjjjjjjjjjj");
    this.details.last_message = body;
    // console.log(this.details.last_message);
    this.gs.scrollToBottom(this.myScrollContainer)
  }
}

