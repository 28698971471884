<div *ngIf="false" a-style brRight=none brTop=none p="1.6" br="1px solid #E4E4E4" brRadius="0.6" class="no_scroll_bar"
    overflowY="auto" h="71">
    <div a-style brBottom="1px solid #e4e4e4">
        <div a-style display="flex" align="center" justify="space-between" p="1.6">
            <m-text [type]="gs.enums.texts.paragraph12" text="Filter"></m-text>
            <!-- <div a-style bg='hov:#e4e4e4' brRadius="50%" h=2 w=2 display=flex alignItems=center justify=center (mouseover)="clearIcon=false" (mouseleave)="clearIcon=true">
                <m-image (click)="close_button_clicked.emit('Close button clicked')" [src]='clearIcon?gs.enums.images.image272:gs.enums.images.image338' a-style h="1" w="1"> </m-image>
            </div> -->
        </div>
    </div>
    <form [formGroup]="form">
        <div a-style p="1.6" brBottom="1px solid #E4E4E4" display=flex flexDirection=column gap=1.5>
            <div a-style display="flex" align="center" justify="space-between">
                <m-text [type]="gs.enums.texts.paragraph20" text="Price Range"></m-text>
                <m-text [type]="gs.enums.texts.paragraph37" text="Clear" a-style cursor=pointer
                    (click)="form?.get('price_range_form')?.reset();form?.get('price_range_to')?.reset();"></m-text>
            </div>
            <ng-container>
                <!-- <div a-style w="100%" align="center"  position=relative >
                <div class="price-field">
                    <input  formControlName="price_range_form" type="range" min="0" max="99999" [value]="form?.get('price_range_form')?.value" id="lower">
                    <input formControlName="price_range_to" type="range"  min="1" max="100000" [value]="form?.get('price_range_to')?.value" id="upper">
                  </div>   
            </div> -->
                <div a-style display="flex" justify="space-between" mt="1.2" align=center>
                    <m-input [type]="gs.enums.inputs.text" formControlName="price_range_form" a-style
                        placeHolder="Price Form" minWidth="5%" pl='0'> </m-input>
                    <m-text [type]="gs.enums.texts.paragraph10" text="-" mx="1.8" mb=0.8> </m-text>
                    <m-input [type]="gs.enums.inputs.text" formControlName="price_range_to" a-style
                        placeHolder="Price To" minWidth="5%" pl='0' pr='0'> </m-input>
                </div>
                <!-- px="0" minWidth="5%" prefix_pl=1 pr='0' postfix_px="1" -->
            </ng-container>
        </div>
        <div a-style p="1.6" brBottom="1px solid #E4E4E4" display=flex flexDirection=column gap=1.5>
            <div a-style display=flex justify="space-between" align=center (click)="visible.brand = !visible.brand">
                <m-text [type]="gs.enums.texts.paragraph20" text="Brand"> </m-text>
                <div a-style bg='hov:#e4e4e4' brRadius="50%" h=2 w=2 display=flex alignItems=center justify=center>
                    <img [src]="!visible.brand?'https://mesbro.in/assets/images/down-arrow-black.svg':'https://image.flaticon.com/icons/png/512/61/61148.png'"
                        cursor=pointer a-style w="0.9">
                </div>
            </div>
            <m-input *ngIf="visible.brand" a-style formControlName="brand" label="Search Brand" px="0" minWidth="5%"
                [type]="gs.enums.inputs.multiSelect" py="1" bindLabel="brand" bindValue="brand" [list]="list.brand"
                alignItems="center" pb="0" ml="0" minHeight="3.5"> </m-input>
            <!-- <m-input [columns]="1/4" flexGrow=0 label="Privacy" type=dropdown bindLabel="label" bindValue="label" [list]="gs.masters['Privacy']" formControlName="privacy"></m-input> -->

        </div>
        <div *ngIf='false' a-style p="1.6" brBottom="1px solid #E4E4E4" display=flex flexDirection=column gap=1.5>
            <div a-style display=flex justify="space-between" align=center
                (click)="visible.location = !visible.location">
                <m-text [type]="gs.enums.texts.paragraph20" text="Location"></m-text>
                <img [src]="!visible.location?'https://mesbro.in/assets/images/down-arrow-black.svg':'https://image.flaticon.com/icons/png/512/61/61148.png'"
                    cursor=pointer a-style w="0.9">
            </div>
            <m-input *ngIf="visible.location" a-style formControlName="location" placeHolder="Type to Search..."
                label="Search Location" mt="1.2" px=0 minWidth="5%"></m-input>
        </div>
        <div a-style p="1.6" brBottom="1px solid #E4E4E4" w=100% display=flex flexDirection=column gap=1.5>
            <div a-style display=flex justify="space-between" align=center w=100%
                (click)="visible.rating = !visible.rating">
                <m-text [type]="gs.enums.texts.paragraph20" text="Customer Rating"></m-text>
                <div a-style bg='hov:#e4e4e4' brRadius="50%" h=2 w=2 display=flex alignItems=center justify=center>
                    <img [src]="!visible.rating?'https://mesbro.in/assets/images/down-arrow-black.svg':'https://image.flaticon.com/icons/png/512/61/61148.png'"
                        cursor=pointer a-style w="0.9">
                </div>
            </div>
            <div *ngIf="visible.rating">
                <ng-container [ngTemplateOutlet]="rating"
                    [ngTemplateOutletContext]="{coustom:{path03:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png' ,path04:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',path05:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',path06:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',text01:'4 & Above',control:'four_and_above',marginTop:0}}">
                </ng-container>
                <ng-container [ngTemplateOutlet]="rating"
                    [ngTemplateOutletContext]="{coustom:{path03:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png' ,path04:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',path05:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',text01:'3 & Above',control:'three_and_above',marginTop:0}}">
                </ng-container>
                <ng-container [ngTemplateOutlet]="rating"
                    [ngTemplateOutletContext]="{coustom:{path03:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png' ,path04:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png',text01:'2 & Above',control:'two_and_above',marginTop:0}}">
                </ng-container>
                <ng-container [ngTemplateOutlet]="rating"
                    [ngTemplateOutletContext]="{coustom:{path03:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png' ,text01:'1 & Above',control:'one_and_above',marginTop:0}}">
                </ng-container>
            </div>
        </div>
        <div a-style p="1.6" brBottom="1px solid #E4E4E4" formGroupName='history' display=flex flexDirection=column
            gap=1.5>
            <div a-style display=flex justify="space-between" align=center (click)="visible.history = !visible.history">
                <m-text [type]="gs.enums.texts.paragraph20" text="History"></m-text>
                <div a-style bg='hov:#e4e4e4' brRadius="50%" h=2 w=2 display=flex alignItems=center justify=center>
                    <img [src]="!visible.history?'https://mesbro.in/assets/images/down-arrow-black.svg':'https://image.flaticon.com/icons/png/512/61/61148.png'"
                        cursor=pointer a-style w="0.9">
                </div>
            </div>
            <div *ngIf="visible.history">
                <m-input type="checkbox" formControlName="one_day_ago" label="1 day" onlyInput=true></m-input>
                <m-input type="checkbox" formControlName="one_week_ago" label="1 week ago" onlyInput=true></m-input>
                <m-input type="checkbox" formControlName="one_month_ago" label="1 month ago" onlyInput=true></m-input>
                <m-input type="checkbox" formControlName="one_year_ago" label="1 month ago" onlyInput=true></m-input>
            </div>
        </div>
        <div a-style p="1.6" brBottom="1px solid #e4e4e4" display=flex flexDirection=column gap=1.5>
            <!-- <m-input a-style [type]="gs.enums.inputs.dropdown" br="none" placeHolder="Offers" color="#333333" px=0 minWidth="5%"></m-input> -->
            <div a-style display=flex justify="space-between" align=center (click)="visible.offer = !visible.offer">
                <m-text [type]="gs.enums.texts.paragraph20" text="Offers"></m-text>
                <div a-style bg='hov:#e4e4e4' brRadius="50%" h=2 w=2 display=flex alignItems=center justify=center>
                    <img [src]="!visible.offer?'https://mesbro.in/assets/images/down-arrow-black.svg':'https://image.flaticon.com/icons/png/512/61/61148.png'"
                        cursor=pointer a-style w="0.9">
                </div>
            </div>
            <m-input *ngIf="visible.offer" a-style formControlName='offer' label="Search Offer" px="0" minWidth="5%"
                [type]="gs.enums.inputs.multiSelect" bindLabel="title" bindValue="title" [list]="list.offer"
                alignItems="center" ml="0" pb="0"> </m-input>
        </div>
        <div a-style display=flex justify=center alignItems=center p=1.6>
            <m-button button_type=submit [types]="gs.enums.buttons.button75" fs="1.4" cursor="pointer" text="Submit"
                (click)="submit()"></m-button>
        </div>
    </form>
</div>

<ng-template #rating let-rating="coustom">
    <form [formGroup]='form'>
        <div a-style display="flex" [mt]="rating.marginTop" formGroupName="rating">
            <m-input [type]="gs.enums.inputs.checkbox" formControlName="{{rating.control}}" [labelTemplate]="star"
                onlyInput=true [columns]=false></m-input>
            <ng-template #star let-data=data>
                <div a-style display="flex" cursor=pointer>
                    <img [src]="rating.path03" a-style h="0.9" w="0.9" ml="0.6">
                    <img [src]="rating.path04" a-style h="0.9" w="0.9" ml="0.6">
                    <img [src]="rating.path05" a-style h="0.9" w="0.9" ml="0.6">
                    <img [src]="rating.path06" a-style h="0.9" w="0.9" ml="0.6">
                    <p a-style color="#333333" fs="1.3" ml="0.6" mt="0" textWrap="nowrap">{{rating.text01}}</p>
                </div>
            </ng-template>
        </div>
    </form>
</ng-template>




<!-- Brand -->
<!-- <div a-style mt="1.2">
    <m-input type="checkbox" label="HP" onlyInput=true></m-input>
    <m-input type="checkbox" label="LG" onlyInput=true></m-input>
    <m-input type="checkbox" label="Philips" onlyInput=true></m-input>
    <m-input type="checkbox" label="Nova" onlyInput=true></m-input>
    <m-input type="checkbox" label="Samsung" onlyInput=true></m-input>
    <m-input type="checkbox" label="Nose" onlyInput=true></m-input>
    <m-input type="checkbox" label="Boat" onlyInput=true></m-input>
</div> -->

<!-- filter List -->
<!-- <div a-style display="flex" flexWrap="wrap" p="1" >
    <ng-container [ngTemplateOutlet]="filterrep" [ngTemplateOutletContext]="{filtermain:{text5:'Samsung', path01:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYusdhFfAPnjIGMOG1v6XH9qMH6YrQq7IeEg&usqp=CAU'}}"> </ng-container>
    <ng-container [ngTemplateOutlet]="filterrep" [ngTemplateOutletContext]="{filtermain:{text5:'1 & Above', path02:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/FA_star.svg/1200px-FA_star.svg.png', path01:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYusdhFfAPnjIGMOG1v6XH9qMH6YrQq7IeEg&usqp=CAU'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="filterrep" [ngTemplateOutletContext]="{filtermain:{text5:'Samsung', path01:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYusdhFfAPnjIGMOG1v6XH9qMH6YrQq7IeEg&usqp=CAU'}}"> </ng-container>
</div> -->
<!-- <ng-template #filterrep let-passData="filtermain">
    <div a-style display="flex" br="1px solid #e4e4e4" align="center" px="0.6" py="0.4" mb=1 mr="1" bg="#f8f8f8">
        <m-image [src]='passData.path02' a-style h="1.2" w="1.2" mr="0.6"></m-image>
        <m-text [type]="gs.enums.texts.paragraph38" text="{{passData.text5}}"></m-text>
        <m-image [src]="passData.path01" a-style h="0.9" w="0.9" ml="0.6" mr="0.6"> </m-image>
    </div>
</ng-template> -->



<!-- pradeep filter code -->
<div a-style bg="#fff" brRadius="0.6" br="1px solid #e4e4e4" p="2.4">
    <div a-style mb="2.4">
        <p a-style color="#333" fs="1.6" m="0">Filters</p>
    </div>
    <div a-style mb="2.4">
        <div a-style display="grid" gridTemplateColumns="1fr 1fr" gap="2.4" mb="2.4">
            <div a-style p="2.4" br="1px solid #e4e4e4" brRadius="0.6">
                <div a-style mb="1.6">
                    <p a-style color="#333" fs="1.6" m="0">Price Range</p>
                </div>
                <div a-style display="flex" w="100%" flexGap="2.4">
                    <m-input a-style label="Currency" m="0" mt="0" pl="0" pr="0" [type]="gs.enums.inputs.dropdown"
                        [columns]="1/2" maxWidth="13.7" minWidth="13.7" dropdownHeight="fit-content"
                        (click)="form?.get('price_range_form')?.reset();form?.get('price_range_to')?.reset();">
                    </m-input>
                    <m-input [columns]="1/3" px=1  label="Currency"
                        [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content" bindLabel='label'
                        bindValue="label" [list]="gs.masters['Currency']"></m-input>
                    <m-input a-style label="Price range" pl="0" pr="0" [type]="gs.enums.inputs.dropdown" [columns]="1/2"
                        maxWidth="22.7" minWidth="22.7" dropdownHeight="fit-content"
                        (click)="form?.get('price_range_form')?.reset();form?.get('price_range_to')?.reset();">
                    </m-input>
                    <!-- a-style cursor=pointer (click)="form?.get('price_range_form')?.reset();form?.get('price_range_to')?.reset(); -->
                </div>
            </div>
            <div a-style p="2.4" br="1px solid #e4e4e4" brRadius="0.6">
                <div a-style mb="1.6">
                    <p a-style color="#333" fs="1.6" m="0">BrandBrand</p>
                </div>
                <div a-style display="flex" w="100%" flexGap="2.4">
                    <m-input label="Brand name" pl="0" pr="0" [type]="gs.enums.inputs.dropdown"
                        dropdownHeight="fit-content"></m-input>
                </div>
            </div>
        </div>
        <div a-style display="grid" gridTemplateColumns="1fr 1fr" gap="2.4">
            <div a-style p="2.4" br="1px solid #e4e4e4" brRadius="0.6" w="44">
                <div a-style mb="1.6">
                    <p a-style color="#333" fs="1.6" m="0">Customer Rating</p>
                </div>
                <div a-style display="flex" justify="space-between">
                    <div a-style w="4" align="center" h="7">
                        <div a-style mb="1">
                            <p a-style color="#333" fs="1.3" m="0">1+</p>
                        </div>
                        <div a-style w="4" h="4" br="1px solid #FFBA08" brRadius="50%"></div>
                    </div>
                    <div a-style w="4" align="center" h="7">
                        <div a-style mb="1">
                            <p a-style color="#333" fs="1.3" m="0">2+</p>
                        </div>
                        <div a-style w="4" h="4" br="1px solid #FFBA08" brRadius="50%"></div>
                    </div>
                    <div a-style w="4" align="center" h="7">
                        <div a-style mb="1">
                            <p a-style color="#333" fs="1.3" m="0">3+</p>
                        </div>
                        <div a-style w="4" h="4" br="1px solid #FFBA08" brRadius="50%"></div>
                    </div>
                    <div a-style w="4" align="center" h="7">
                        <div a-style mb="1">
                            <p a-style color="#333" fs="1.3" m="0">4+</p>
                        </div>
                        <div a-style w="4" h="4" class="rating-shadow" bg="#FFBA08" br="1px solid #FFBA08"
                            brRadius="50%"></div>
                    </div>
                    <div a-style w="4" align="center" h="7">
                        <div a-style mb="1">
                            <p a-style color="#333" fs="1.3" m="0">5</p>
                        </div>
                        <div a-style w="4" h="4" br="1px solid #FFBA08" brRadius="50%"></div>
                    </div>
                </div>
            </div>
            <div a-style p="2.4" br="1px solid #e4e4e4" brRadius="0.6">
                <div a-style mb="1.6">
                    <p a-style color="#333" fs="1.6" m="0">Location</p>
                </div>
                <div a-style display="flex" w="100%" flexGap="2.4">
                    <!-- <m-input a-style pl="0" pr="0" label="Search Location" [type]="gs.enums.inputs.dropdown"
                        dropdownHeight="fit-content"></m-input> -->
                    <!-- <m-input [columns]="1/3" px=1 formControlName="location" label="Location"
                        placeHolder="Type to Search..." [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content">
                    </m-input> -->
                    <m-input [columns]="1/3" px=1  label="Location"
                        placeHolder="Type to Search..." [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content">
                    </m-input>
                </div>
            </div>
        </div>
    </div>
    <div a-style display="flex" gap="3" justify="flex-end">
        <button a-style brRadius="0.6" br="1px solid #05A6F0" px="1.4" py="0.5" color="#333" h="3" bg="white"
            opacity="1">Clear Filter</button>
        <button a-style brRadius="0.6" br="1px solid #05A6F0" px="1.4" py="0.5" color="#333" h="3" bg="white"
            opacity="1">Apply Filter</button>
    </div>
</div>