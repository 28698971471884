import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

   
@Component({
  selector: 'm-slider',
  templateUrl: './slider.component.html',
  styles: [
  ]
})

export class SliderComponent implements OnInit {
  value: any;
isForm:boolean=false;
  @Input() multiple = true;
  @Input() aspect_ratio = 1;
  isClicked:boolean=false;
  @Output() changed = new EventEmitter();
  hovered:boolean=false;
  
  form: FormGroup | undefined;
  application:'product'
  component:'product'
  query_params: any = {
    _id: '',
  }
  control: FormControl | undefined;
  organization_detail:any

  


onChange: any = (event: any) => {
  console.log('Hahahaha')
  this.changed.emit(event)
}

  constructor(public gs: GlobalService, public fb: FormBuilder,public ar:ActivatedRoute,  public injector: Injector) { }

  async ngAfterContentInit() {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }}
  ngOnInit(): void {
    this.ar.queryParams.subscribe(query_params => this.handleParams(query_params))
  }

  handleParams = async (query_params:any) => {
    this.query_params = query_params
    console.log(this.query_params);
    
    this.initialize_form();
    if (query_params._id) {
      try {
        this.organization_detail = await this.gs.hps.get('idm', 'group', query_params._id); 
        console.log(this.organization_detail);
        if(this.organization_detail.apppearance_form){
              this.form?.patchValue(this.organization_detail.apppearance_form)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  colors: any[] = [
    { color: 'red' },
    { color: 'pink' },
    { color: 'green' },
    { color: 'blue' },
    { color: 'yellow' },
    { color: 'black' }
  ];
  size: any[] = [
    { size: 'small' },
    { size: 'medium' },
    { size: 'large' },

  ];

  initialize_form() {
    this.form = this.fb.group({
      gropuControl:[null],
      upload_banner: [null],
      product_description:[null],
      product_details:[null],
      product_tags:[null],
      // welcome: this.welcome,
      // discount:this.discount,
      // team_member:this.team_member,
      colors: [null],
      size: [null],
      logo:[null],
      welcomes: this.fb.array([this.welcome]),
      discounts: this.fb.array([this.discount]),
      team_members:this.fb.array([this.team_member]),
    });
  }
  get welcome_group() {
    return ((this.form as FormGroup).get('welcomes') as FormArray).controls;
  }
  get welcome() {
    return this.fb.group({
      upload: [null],
      greet_title: [null],
      title: [null],
      subtitle: [null],
      description: [null]
    })

  }
 
  add_welcome() {
    (<FormArray>this.form?.get('welcomes')).push(this.welcome)

  }
  get discount_group() {
    return ((this.form as FormGroup).get('discounts') as FormArray).controls;
  }
  get discount() {
    return this.fb.group({
      discount_title: [null],
      discount_description: [null],
      upload_discount_image: [null],
    })
  }
  add_discount() {
    (<FormArray>this.form?.get('discounts')).push(this.discount)
  }

  get team_member_group() {
    return ((this.form as FormGroup).get('team_members') as FormArray).controls;
  }
  get team_member() {
    return this.fb.group({

      employee_name: [null],
      upload_profile_image: [null],
      employee_designation: [null],
      employee_description: [null],
      social_media_link: [null],
    })

  }
  add_team_member() {
    (<FormArray>this.form?.get('team_members')).push(this.team_member)
  }

    
  set_image(event: any) {
    if (this.multiple) {
      this.value = this.value || [];
      console.log(this.value);
      
      this.value.push(event.url)
    } else {
      this.value = event.url
    }
    this.onChange(this.value)
  }

 slideIndex = 1;
 nextSlide(n:any){
  this.showslide(this.slideIndex += n);
 }

 showslide( n : any){
   let i ;
   let x = document.querySelectorAll<HTMLElement>('.mySlides');
   if (n > x.length) {this.slideIndex = 1}
   if (n < 1) {this.slideIndex = x.length}
   for (i = 0; i < x.length; i++) {
     x[i].style.display = "none";  
   }
   x[this.slideIndex-1].style.display = "block"; 
 }

  async submit() {
    let body:any = this.organization_detail;
    console.log(body);
    body['apppearance_form'] = this.form?.value;
    if (this.query_params._id) {
      let response = await this.gs.hps.put('idm', 'group', undefined, body);
      this.gs.toast('Organization', 'Modified Successfully', 'success')
    }
    


    

  }

  // async change_appearence_setting() {
  //   if () {
  //     this.gs.router.navigate(['/'], { queryParams: { type: 'sign-in', otp_id: response.otp_id } });
  //   }
  // }
}

// {"$and":[{"application":"Product"},{"component":"Product"},{[`meta.roles.owner.group.${organization_id}`]:true}]}