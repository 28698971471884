<!-- {{columns}} -->


<ng-container>
    <ng-container *ngIf="onlyInput" [ngTemplateOutlet]="inputs"></ng-container>

    <div *ngIf="onlyInput && columns" a-style [minWidth]="minWidth?minWidth:35" [maxWidth]="maxWidth?maxWidth:'unset'"
        [w]="(columns*100 || 100) + '%'" [px]="px?px:1" [mb]="mb?mb:1" [bg]="bg">
        <ng-container *ngIf="onlyInput && !columns" [ngTemplateOutlet]="inputs"></ng-container>
        <m-text [type]="gs.enums.texts.paragraph72" *ngIf="control?.errors?.message && control?.touched"
            [text]="control?.errors?.message" mt="0.8"></m-text>
    </div>
    <div *ngIf="!onlyInput" a-style [w]="(columns*100 || 100) + '%; 750:100%'" [minWidth]="minWidth?minWidth:35"
        [maxWidth]="maxWidth?maxWidth:'unset'" [flexGrow]="flexGrow || 1" [px]="px?px:0" [mb]="mb?mb:1">
        <div a-style display=flex justify=space-between alignItems=center>
            <m-text *ngIf="label && type != 'media' " [type]="gs.enums.texts.paragraph9" text="{{label}}" mb="1.6">
            </m-text>
            <img a-style [mt]="" *ngIf="info" h=1.4 [title]="info"
                src="https://mesbro.com/assets/images/information.svg" alt="">
        </div>
        <ng-container [ngTemplateOutlet]="inputs"></ng-container>
        <m-text [type]="gs.enums.texts.paragraph72" *ngIf="control?.errors?.message && control?.touched"
            [text]="control?.errors?.message" mt="0.8"></m-text>
    </div>
</ng-container>

<!-- <div>
    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="(type==gs.enums.inputs.text || type==gs.enums.inputs.password)  && (prefix || postfix || postfix_text || prefix_text || postfix2 )" a-style [shadow]="'hov:'+shadow" [h]="3.9" [maxWidth]="w?w:'100%'" [pl]='pl?pl:1' [pr]="outer_pr?outer_pr:1" bg="#ffffff" display="flex" align="center" brRadius="0.6" [style.border]="'1px solid '+((control?.invalid && control?.touched) ?'red':'#e4e4e4')">
        <div a-style h="100%" display="flex" alignItems="center" [pl]="prefix_pl" [pr]="country?1:0"><img (click)="prefix_clicked.emit()" *ngIf="prefix" [src]="country?'https://new-api.mesbro.com/uploads/'+country_dropDown.image:prefix" a-style [h]="prefix_h?prefix_h:2.3" [w]="prefix_w?prefix_w:2.3"></div>
        <div *ngIf="country" a-style fs="1.3" px=1>{{country_dropDown.country_code}}</div>
        <div *ngIf="prefix_text" textWrap=nowrap a-style a-style fs=1.3 fw=400>{{prefix_text}}</div>
        <input [bg]="bg? bg : 'initial' " (keyup.enter)="postfix_clicked.emit()" [type]="type" a-style [pl]="prefix?1:0" [pr]="p?p:1" h="3.6" [fs]="fs?fs:1.1" [placeholder]="placeHolder" [cursor]="cursor" [(ngModel)]="value" (ngModelChange)="capitalize && value = capitalize_text(value); onChange($event); control?.markAsTouched();clearIcon='cross'" br=none outline="none" w="100%">
        <img [style.visibility]="value && hovered?'unset':'hidden'" (click)="control?.reset();control?.markAsTouched();" (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'" [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3" w="1.3" cursor="pointer" mr=2>
        <div a-style [bg]="postfix_bg" h="3.9" w="max-content" [px]="postfix_px?postfix_px:0" py="1.1" align="center" brRadius="3px" display="flex" [brTopLeftRadius]="brTopLeftRadius?brTopLeftRadius:0" [brTopRightRadius]="brTopRightRadius?brTopRightRadius:0" [brBottomRightRadius]="brBottomRightRadius?brBottomRightRadius:0" [brBottomLeftRadius]="brBottomLeftRadius?brBottomLeftRadius:0">
            <span *ngIf="postfix_text" a-style fs=1.3 fw=400> {{postfix_text}} </span>
            <img *ngIf="postfix2" (click)="postfix2_clicked.emit()" [src]="postfix2" a-style h="1.7" w="1.7" cursor="pointer">
            <img *ngIf="postfix"   (click)="postfix_clicked.emit()" [src]="postfix" a-style h="1.7" w="1.7" cursor="pointer">
            <img *ngIf="postfix"    (click)="postfix_clicked.emit()" a-style [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+searchIcon+'.svg'" (mouseover)="searchIcon='search-blue'" (mouseleave)="searchIcon='search'"  h="1.7" w="1.7" cursor="pointer">
            <img *ngIf="postfix"    (click)="postfix_clicked.emit()" a-style [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+searchIcon+'.svg'" (mouseover)="searchIcon='search-../assets/images/assets/Icon-search-coloured.svg'" (mouseleave)="searchIcon='search'"  h="1.7" w="1.7" cursor="pointer">
        </div>
    </div>
</div> -->



<ng-template #inputs>
    <!-- Number inputs -->
    <div [style.background]=" disabled? '#e4e4e4' : 'unset' " (mouseover)="hovered=true" (mouseleave)="hovered = false" 
        *ngIf="(type==gs.enums.inputs.text || type==gs.enums.inputs.number || type == gs.enums.inputs.password) && !(prefix || postfix || postfix_text || prefix_text )"
        a-style display="flex" alignItems="center" [maxWidth]="w?w:'100%'"
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        brRadius="0.4" w="100%" h="3.9" [minWidth]="minWidth?minWidth:32.7" px="1" >
        <input #input_value [bg]="bg? bg : 'initial'  " [color]="color? color : '#000000'  " [disabled]="disabled"
            [(ngModel)]="value" [type]="type" [fs]="fs" [placeholder]="placeHolder"
            (ngModelChange)="capitalize && value = capitalize_text(value);uppercases && value=uppercase(value); onChange($event); control?.markAsTouched();clearIcon='cross'"
            a-style w="100%" h="100%" pl="0" br="none" brRadius="none" autofocus="on">
        <img [style.visibility]="value && hovered && !disabled?'unset':'hidden'"
            (click)="control?.reset();control?.markAsTouched();input_value.focus()" (mouseover)="clearIcon='cross-red'"
            (mouseleave)="clearIcon='cross'"
            [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3"
            w="1.3" cursor="pointer" [mr]="mr?mr:0 ">
    </div>
    <!-- [p]="paddingNone?'1.6':'none'" -->
    <div (mouseover)="hovered=true" (mouseleave)="hovered = false"
        *ngIf="(type==gs.enums.inputs.text || type==gs.enums.inputs.password)  && (prefix || postfix || postfix_text || prefix_text || postfix2 )"
        a-style [shadow]="'hov:'+shadow" [h]="h?h:3.9" [maxWidth]="w?w:'100%'" [pl]='pl?pl:1' [pr]="outer_pr?outer_pr:1"
        bg="#ffffff" display="flex" align="center" brRadius="0.6" 
        [style.border]="'1px solid '+((control?.invalid && control?.touched) ?'red':'#e4e4e4')">
        <div a-style h="100%" display="flex" alignItems="center" [pl]="prefix_pl" [pr]="country?1:0"><img
                (click)="prefix_clicked.emit()" *ngIf="prefix" 
                [src]="country?'https://new-api.mesbro.com/uploads/'+country_dropDown.image:prefix" a-style
                [h]="prefix_h?prefix_h:2.3" [w]="prefix_w?prefix_w:2.3"></div>
        <!-- add country code in master of coutry -->
        <!-- <div *ngIf="prefix=='flag'" a-style fs="1.3" px=1>{{country.countryCode}}</div>  -->
        <div *ngIf="country" a-style fs="1.3" px=1>{{country_dropDown.country_code}}</div>
        <div *ngIf="prefix_text" textWrap=nowrap a-style a-style fs=1.3 fw=400 mt=".2" mr=".5">{{prefix_text}}</div>
        <!-- (keyup.enter)="postfix_clicked.emit()" removed from below input -->
        <input [bg]="bg? bg : 'initial' " [type]="type"  a-style   autofocus="on" [pl]="prefix?1:0" [pr]="p?p:1" [h]="h?h:'3.6'"
            [fs]="fs?fs:1.1" [placeholder]="placeHolder" [cursor]="cursor" [(ngModel)]="value"
            (ngModelChange)="capitalize && value = capitalize_text(value);uppercases && value=uppercase(value); onChange($event); control?.markAsTouched();clearIcon='cross'"
            br=none outline="none" w="100%" >
        <img [style.visibility]="value && hovered?'unset':'hidden'" (click)="control?.reset();control?.markAsTouched();"
            (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'"
            [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3"
            w="1.3" cursor="pointer" mr=2>
        <div a-style [bg]="postfix_bg" h="3.9" w="max-content" [px]="postfix_px?postfix_px:0" py="1.1" align="center"
            brRadius="3px" display="flex" [brTopLeftRadius]="brTopLeftRadius?brTopLeftRadius:0"
            [brTopRightRadius]="brTopRightRadius?brTopRightRadius:0"
            [brBottomRightRadius]="brBottomRightRadius?brBottomRightRadius:0"
            [brBottomLeftRadius]="brBottomLeftRadius?brBottomLeftRadius:0">
            <!-- minWidth?minWidth:35 -->
            <span *ngIf="postfix_text" a-style fs=1.3 fw=400> {{postfix_text}} </span>
            <img *ngIf="postfix3" (click)="postfix2_clicked.emit()" [src]="postfix3" a-style h="2" w="2" ml="1.3"
                cursor="pointer">
            <img *ngIf="postfix2" (click)="postfix2_clicked.emit()" [src]="postfix2" a-style h="2" w="2" ml="1.3"
                cursor="pointer">
            <img *ngIf="postfix" (click)="postfix_clicked.emit()" [src]="postfix" a-style h="2" w="2" ml="1.3"
                cursor="pointer">
            <!-- <img *ngIf="postfix"    (click)="postfix_clicked.emit()" a-style [src]="postfix" [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+searchIcon+'.svg'" (mouseover)="searchIcon='search-blue'" (mouseleave)="searchIcon='search'"  h="1.7" w="1.7" cursor="pointer"> -->
            <!-- <img *ngIf="keyimage && value" [src]="keyimage" a-style h="1.3" w="1.3" cursor="pointer"> -->
        </div>
    </div>





    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.checkbox" a-style
        display="flex" [alignItems]="alignItems?alignItems:'center'" flexGap="1" [mt]="mt? mt :1.2"
        [brRadius]="onlyInput? '50%' : 0" [p]="p" [bg]="bg">
        <input a-style [bg]="bg? bg : '#CFEEFC' " id="check" type="checkbox" a-style [h]="h?h:'2'" [w]="h?h:'2'"
            cursor=pointer [id]="label"
            [style.border]="'1px solid  #e4e4e4'+((control?.invalid && control?.touched) ?'#e4e4e4':'#e4e4e4')"
            (ngModelChange)="onChange($event); changed.emit(value)" [(ngModel)]="value">
        <label *ngIf="label" a-style mt="0.1" fs="1.3" color="#333333" display="flex" flexGap="0.2" cursor=pointer
            [for]="label" h=auto textWrap=nowrap> {{label}}</label>
        <ng-container *ngIf="labelTemplate" [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{passedData :passedData }"></ng-container>
    </div>

    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.radioButton" a-style
        display="flex" align="center" flexGap="1" [mt]="mt? mt :1.2" [brRadius]="onlyInput? '50%' : 0">
        <input a-style [bg]="bg? bg : 'initial' " cursor=pointer type="radio" [id]="label" [name]="radioName"
            [value]="label" (change)="onChange(label)">
        <label [for]="label" a-style mt="0.1" fs="1.3" color="#333333" align="center" cursor=pointer display="flex"
            flexGap="0.2" textWrap=nowrap> {{label}}</label>
    </div>
    <!-- <div [style.background]=" disabled? '#e4e4e4' : 'unset' " (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="(type==gs.enums.inputs.text || type == gs.enums.inputs.password) && !(prefix || postfix || postfix_text || prefix_text )" a-style display="flex" alignItems="center"
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}" brRadius="0.4" w="100%" h="3.9" px="1">
        <input [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}" (mouseover)="hovered=true" (mouseleave)="hovered = false"   [(ngModel)]="value" (ngModelChange)="onChange($event); control?.markAsTouched();clearIcon='cross'" type="number" a-style outline="none" [mt]="mt?mr:'1'" [br]="br? br : '1px solid #e4e4e4'" px="1" brRadius="0.4" w="100%" h="3.9">
    </div> -->
    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.color" a-style
        display="flex" flexWrap="wrap" flexGap="2.4">
        <div a-style h="5" w="5" br="1px solid #e4e4e4" bg="#FFFFFF" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#F35325" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#05A6F0" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#FF8C00" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#FFF100" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#F38BA0" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#8764B8" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#00BCF2" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#AB620D" [brRadius]="brRadius"></div>
        <div a-style h="5" w="5" bg="#BEBEBE" [brRadius]="brRadius"></div>
    </div>











    <div [style.background]=" disabled? '#e4e4e4' : 'unset' " (mouseover)="hovered=true" (mouseleave)="hovered = false"
        *ngIf="type==gs.enums.inputs.dropdown" a-style w="100%" position="relative" [shadow]="'hov:'+shadow">

        <div a-style [style.border]="br? br : '1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')"
            w="100%" brRadius=0.6 [px]="input_px?input_px:1" h="3.9" textCenter=justify display="flex"
            alignItems="center" [mb]="mb">
            <div a-style w='100%' [h]="h? h : 3.9" display="flex" justify="space-between">
                <input #input_value [disabled]="disabled" [bg]="bg? bg : 'initial' "
                    (click)="!disabled && is_visible=true; filtered_list=list;"
                    (keydown)="dropdown_keyboard_actions($event)"
                    (focus)="filtered_list=list; is_visible=true; focused.emit('Focused')"
                    (blur)="is_visible = false; blurred.emit('Focused')" [type]="gs.enums.inputs.text"
                    [placeholder]="placeHolder" a-style [px]='prefix?1:0' h="100%" flexGrow=1
                    [w]="inputWidth?inputWidth:'100%'" maxWidth="maxWidth?maxWidth:100%" br="none"
                    [(ngModel)]="inputValue.input">
                <div a-style display="flex" alignItems="center" (click)="input_value.focus()">
                    <img *ngIf="!disabled && value && hovered"
                        (click)="is_visible=false;control?.reset();control?.markAsTouched();inputValue.input=undefined;selected_item.image=undefined;"
                        (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'"
                        [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style
                        h="1.3" w="1.3" cursor="pointer">
                    <img src="../assets/images/assets/downarrow.svg" *ngIf="!value || !hovered" cursor=pointer a-style
                        w="1.3" h="1.3" (click)="!disabled && is_visible=!is_visible;">
                </div>
            </div>
        </div>
        <div *ngIf="is_visible" a-style w="100%" bg=white position="absolute" bottom="0"
            [style.outline]="filtered_list?'0.5px solid #e4e4e4':'none'" transform=translatey(100%) left=0 zIndex=2
            [h]="" minWidth="9" [maxHeight]="maxHeight?maxHeight:20" overflow="auto" class="no_scroll" brRadius="0.4" id='scroll'>
            <div a-style display="flex" gap="1" *ngFor="let item of filtered_list ;let i=index " p="1"
                alignItems="center" (mousedown)="select_dropdown( $event, item);" (mouseover)="hover[i]=true"
                (mouseleave)="hover[i]=false" [style.background-color]="hover[i]?'#EEF5FB':'white'"
                [style.background]="selected_item==item?'#e4e4e4':'white'">
                <m-image *ngIf="bindImage && (item.image || item[bindImage])"
                    [src]="direct_image? (item.image || item[bindImage]) :  'https://new-api.mesbro.com/uploads/'+ (item.image || item[bindImage])"
                    h="2.5" w="auto"></m-image>
                <p a-style #country fs=1.3 textCenter=justify cursor=pointer>{{ (item[bindLabel] || item)+'' }} </p>
            </div>
        </div>
    </div>



    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.tags" a-style p="1"
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        brRadius="0.4" display="flex" justify="flex-start" flexGap="1" flexWrap="wrap" w="100%" align="center"
        position="relative">
        <div a-style bg="#d2d2d273" bg fs="1.1" fw="400" display="flex" alignItems="center" flexGap="0.8" brRadius="0.5"
            py="0.2" px="1" *ngFor="let item of value;let i = index">
            <!-- fw="700" -->
            <!-- bg="#d2d2d273" -->
            <!-- rgba(0, 0, 0, 0.1) -->
            <span [attr.contenteditable]="contentEditables[i]" (click)="contentEditables[i]=true"
                (blur)="changeSingleTag($event,i)">{{item}}</span>
            <img a-style (click)="remove(i)"
                src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross-red.svg" h="0.8" w="0.8"
                cursor=pointer>
        </div>

        <input #input_value type="text" [bg]="bg? bg: 'white' " (paste)="onPaste($event,input_value)"
            (focus)="is_visible=true" (blur)="is_visible=false" [placeholder]="placeHolder" a-style h="2" flexGrow=1 w=2
            br="none" (keydown.backspace)="pop($event)" (keydown.enter)="push($event)" [(ngModel)]="inputValue.input">
    </div>

    <div [style.background]=" disabled? '#e4e4e4' : 'unset' " (mouseover)="hovered=true" (mouseleave)="hovered = false"
        *ngIf="type==gs.enums.inputs.multiSelect" a-style w="100%" position="relative">
        <div a-style w="100%" [brRadius]="brRadius?'brRadius':'0.6'" [pb]="pb?pb:0.7 " [px]="input_px?input_px:1"
            [minHeight]="minHeight?minHeight:3" [maxHeight]="maxHeight?maxHeight:0" position=relative textCenter=justify
            gap=1 alignItems="center" [mb]="mb"
            [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}">
            <div *ngIf="placeHolder" a-style position=absolute [style.top]="value?.length?'0':'0.4rem'">
                <p a-style fs="1.5" color="#b2b2b2">{{placeHolder}}</p>
            </div>
            <div a-style w='100%' display="flex" [ml]="ml?ml:3">
                <div (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style display="flex"
                    justify="flex-start" flexGap="1" flexWrap="wrap" w="100%" alignItems="center" [pt]="pt?pt:1"
                    position="relative">
                    <div a-style bg="#FFFFFF" br="0.5px solid #E4E4E4" fs="1.2" fw="600" display="flex"
                        alignItems="center" flexGap="0.8" brRadius=".5" py="0.3" px="1.4"
                        *ngFor="let item of value;let i = index">
                        <span [attr.contenteditable]="contentEditables[i]" (click)="contentEditables[i]=true"
                            (blur)="changeSingleTag($event,i)">{{item[bindLabel] || item}}</span>
                        <!-- <img a-style (click)="remove(i)"
                            src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross-red.svg" h="0.8"
                            w="0.8" cursor=pointer> -->
                        <img a-style (click)="remove(i)" src="../assets/images/Icon_Bank/Remove_Coloured.svg" h="0.8"
                            w="0.8" cursor=pointer>
                    </div>
                    <input #input_value [disabled]="disabled" [bg]="bg? bg : 'initial' "
                        (click)="!disabled && is_visible=true; filtered_list=list;"
                        (keydown)="dropdown_keyboard_actions($event)"
                        (focus)="filtered_list=list; is_visible=true; focused.emit('Focused')"
                        (blur)="is_visible = false; blurred.emit('Focused');push($event)"
                        (keydown.backspace)="pop($event)" (keydown.enter)="inputValue.input=undefined;push($event)"
                        [type]="gs.enums.inputs.text" a-style [px]='prefix?1:0' h="3" flexGrow=1 maxWidth="100%"
                        br="none" [brRadius]="brRadius?'brRadius':'0.6'" [(ngModel)]="inputValue.input">
                </div>
                <div a-style display="flex" [alignItems]="alignItems?alignItems:'flex-end'"
                    (click)="input_value.focus()">
                    <!-- <div [style.visibility]="value && hovered?'unset':'hidden'" a-style w="2.2" h="2.2" bg="hov:#e4e4e4"
                        brRadius="50%" cursor=pointer mb=0.5 [mr]="clearIcon_mr?clearIcon_mr:2" display="grid"
                        placeContent="center"
                        (click)="is_visible=false;control?.reset();control?.markAsTouched();inputValue.input=undefined;value=[]"
                        (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'">
                        <img [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'"
                            a-style h="0.9" w="0.9">
                    </div> -->
                    <!-- <img *ngIf="is_arrow" src="https://mesbro.in/assets/images/down-arrow-black.svg" cursor=pointer
                        a-style w="0.9" (click)="!disabled && is_visible=!is_visible;"> -->
                </div>
                <div a-style [bg]="postfix_bg" h="3.9" w="max-content" [px]="postfix_px?postfix_px:0" py="1.1"
                    align="center" brRadius="3px" display="flex" [brTopLeftRadius]="brTopLeftRadius?brTopLeftRadius:0"
                    [brTopRightRadius]="brTopRightRadius?brTopRightRadius:0"
                    [brBottomRightRadius]="brBottomRightRadius?brBottomRightRadius:0"
                    [brBottomLeftRadius]="brBottomLeftRadius?brBottomLeftRadius:0">
                    <!-- minWidth?minWidth:35 -->
                    <span *ngIf="postfix_text" a-style fs=1.3 fw=400 color="#006bff"
                        (click)="postfix_clicked.emit(); postfix_text=''; postfix2_text=''"> {{postfix_text}} </span>
                    <span *ngIf="postfix2_text" a-style mr="2.4" fs=1.3 fw=400 ml="1" color="#006bff"
                        (click)="postfix2_clicked.emit(); postfix2_text=''; postfix_text=''"> {{postfix2_text}} </span>
                    <img *ngIf="postfix" (click)="postfix3_clicked.emit()" [src]="postfix" a-style h="1.7" w="1.7"
                        cursor="pointer">
                    <!-- <img *ngIf="keyimage && value" [src]="keyimage" a-style h="1.3" w="1.3" cursor="pointer"> -->
                </div>
            </div>

        </div>
        <div *ngIf="is_visible" a-style w="fit-content" [style.outline]="filtered_list?'0.5px solid #e4e4e4':''"
            bg=white position="absolute" bottom=" 0" transform=translatey(100%) left=0 zIndex=2 h="auto" brRadius="0.6"
            [maxHeight]="dropdownHeight?dropdownHeight:20" [maxWidth]="dropdownWidth?dropdownWidth: 'fit-content'"
            overflow="auto" id='scroll'>
            <p a-style #country fs=1.3 p="1" textCenter=justify cursor=pointer
                (mousedown)="select_dropdown( $event, item);" (mouseover)="hover[i]=true" (mouseleave)="hover[i]=false"
                [style.background-color]="hover[i]?'lightgray':'white'"
                [style.background]="selected_item==item?'lightgray':'white'"
                *ngFor="let item of filtered_list ;let i=index ">{{ (item[bindLabel] || item)+'' | titlecase }} </p>
        </div>
    </div>

    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.emailTags" a-style p="1"
        brBottom='1px solid #e4e4e4' brRadius="0.4" display="flex" flexGap="1" flexWrap="wrap" w="100%" align="center"
        mt="1" position="relative">
        <p a-style fs="1.3" color="#b2b2b2" *ngIf="value?.length>=1">{{placeHolder}}</p>
        <div a-style [br]="br? br : '1px solid #e4e4e4'" brRadius="0.4" py="0.1" px="0.8"
            *ngFor="let item of value;let i = index">
            <span [attr.contenteditable]="contentEditables[i]" (click)="contentEditables[i]=true"
                (blur)="changeSingleTag($event,i)">{{item}}</span>
            <!-- <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" a-style ml="1" w="0.7"
                (click)="remove(i)"> -->
            <img [src]="postfix" alt="">
        </div>
        <input type="text" [placeholder]="value?.length? '' : placeHolder" (focus)="is_visible=true"
            (blur)="is_visible=false" a-style h="2" flexGrow=1 br="none" (keydown.backspace)="pop($event)"
            (keydown.enter)="push($event)" [(ngModel)]="inputValue.input">
        <div *ngIf="postfix">
            <p a-style color="#006BFF" fs="1.3">Bcc</p>
        </div>
    </div>

    <div *ngIf="type==gs.enums.inputs.text_area" [style.background]=" disabled? '#e4e4e4' : 'unset' "
        (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style display="flex" alignItems=flex-end
        [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}"
        brRadius="0.6" w="100%" p=1 overflow=auto>
        <textarea class="no_scroll" autosize minRows="15" [mt]="mt" [m]="m" [rows]="rows" name="" id=""
            [placeholder]="placeHolder" style="resize: none;" [disabled]="disabled" [(ngModel)]="value"
            [placeholder]="placeHolder" (ngModelChange)="onChange($event); control?.markAsTouched();clearIcon='cross'"
            a-style [fs]="fs" [bg]="bg? bg : 'initial' " w="100%" pl="0" [pr]="pr" br="none"></textarea>
        <img [style.visibility]="value && hovered && !disabled?'unset':'hidden'"
            (click)="control?.reset();control?.markAsTouched();" (mouseover)="clearIcon='cross-red'"
            (mouseleave)="clearIcon='cross'"
            [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3"
            w="1.3" cursor="pointer" [mr]="mr?mr:0 ">

        <!-- <div *ngIf="type==gs.enums.inputs.text_area" [style.background]=" disabled? '#e4e4e4' : 'unset' " (mouseover)="hovered=true" (mouseleave)="hovered = false" a-style display="flex" alignItems=flex-end [ngStyle]="border?{'border-bottom': '1px solid #e4e4e4','border-top': 'none','border-left': 'none','border-right': 'none'}:br?{border: br}:{'border':'1px solid '+((control?.invalid && control?.touched) ? 'red' : '#e4e4e4')}" brRadius="0.4" w="100%" p=1 overflow=auto>
        <ea autosize minRows="15" [rows]="rows" name="" id="" [placeholder]="placeHolder" style="resize: none;" [disabled]="disabled" [(ngModel)]="value" [placeholder]="placeHolder" (ngModelChange)="onChange($event); control?.markAsTouched();clearIcon='cross'" a-style [fs]="fs" [bg]="bg? bg : 'initial' " w="100%" h="100%" pl="0" [pr]="pr" br="none"  ></textarea>
        <img [style.visibility]="value && hovered && !disabled?'unset':'hidden'" (click)="control?.reset();control?.markAsTouched();" (mouseover)="clearIcon='cross-red'" (mouseleave)="clearIcon='cross'" [src]="'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/'+clearIcon+'.svg'" a-style h="1.3" w="1.3" cursor="pointer" [mr]="mr?mr:0 "> -->
    </div>

    <!-- <ng-content></ng-content> -->
    <!-- <ng-content select="[file]"></ng-content> -->

    <div *ngIf="type==gs.enums.inputs.file" (click)="image.openModal()">
        <ng-content></ng-content>
        <m-modal #image width="60" [close_on_overlay]="false">
            <m-image-croppr *ngIf="image.is_visible" [value]="value" [aspect_ratio]="aspect_ratio"
                (file_upload)="image.closeModal(); set_image($event)"></m-image-croppr>
        </m-modal>
    </div>

    <div *ngIf="type==gs.enums.inputs.mail_attachments" (click)="file_input.click()">
        <ng-content></ng-content>
        <input #file_input type="file" [multiple]="true" name="" [(ngModel)]="value" (change)="upload_event($event)"
            hidden id="">
    </div>

    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.date" a-style w="100%"
        display="flex" flexWrap=1 mb="0" gap="0.6" position=relative>
        <ng-container [formGroup]="date_form_group">
            <m-input minWidth="unset" (ngModelChange)="control?.markAsTouched()" placeHolder="Month"
                formControlName="month" [type]="gs.enums.inputs.dropdown" [columns]="0.35" px=0 mb="0" [list]="months">
            </m-input>
            <m-input minWidth="unset" (ngModelChange)=" control?.markAsTouched()" placeHolder="Day"
                formControlName="day" [type]="gs.enums.inputs.dropdown" [columns]="0.32" px=0 mb="0" [list]="days">
            </m-input>
            <m-input minWidth="unset" (ngModelChange)="control?.markAsTouched()" placeHolder="Year"
                formControlName="year" [type]="gs.enums.inputs.dropdown" [columns]="0.36" px=0 mb="0" [list]="years">
            </m-input>
        </ng-container>
    </div>

    <div *ngIf="type==gs.enums.inputs.select" a-style [p]="p?p:0" w=100%>
        <m-text *ngIf="title" [type]="gs.enums.texts.paragraph9" [text]="title"></m-text>
        <div a-style [overflowX]="overflowX" class="no_scroll_bar">
            <div a-style align="left" display="flex" [flexWrap]="wrap" gap="1" [mb]="card_mb">
                <ng-container *ngFor="let type of list;let i=index" [ngTemplateOutlet]="detailCard"
                    [ngTemplateOutletContext]="{passedData:{type: type,index:i , w:w}}"></ng-container>
            </div>
        </div>

    </div>

    <ng-container *ngIf="type==gs.enums.inputs.media" [ngTemplateOutlet]="media"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.mediaGallary" [ngTemplateOutlet]="mediaGallary"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.mediaMulti" [ngTemplateOutlet]="mediaMultiImage"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>

    <ng-container *ngIf="type==gs.enums.inputs.mediaNew" [ngTemplateOutlet]="mediaNew"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.mediaCover" [ngTemplateOutlet]="mediaCover"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : 0.4} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.mediaBrochure" [ngTemplateOutlet]="mediaBrochure"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.file_upload" [ngTemplateOutlet]="file_upload"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <ng-container *ngIf="type==gs.enums.inputs.media_flip" [ngTemplateOutlet]="media_flip"
        [ngTemplateOutletContext]="{passedData : {name : label, aspect_ratio : aspect_ratio} }"></ng-container>
    <div *ngIf="type==gs.enums.inputs.switch">
        <label class="switch" a-style display="flex"> <input (ngModelChange)="onChange(value)" [(ngModel)]="value"
                type="checkbox" [attr.checked]="value" [checked]="value"> <span class="slider round"></span> <span
                a-style color="#333333" ml="5"></span> </label>
    </div>
    <div *ngIf="type==gs.enums.inputs.ck_editor">
        <ckeditor id="text" [config]="{placeholder:placeHolder}" [(ngModel)]="value" id="editor"
            (ngModelChange)="internal_change();onChange($event)" [editor]="Editor" [h]="h?'h':'500rem'" a-style
            [style.height]="100">
        </ckeditor>
    </div>
   
    <div *ngIf="type==gs.enums.inputs.ck_editor2">
        <ckeditor id="text" [config]="{placeholder:placeHolder}" [(ngModel)]="value" id="editor2"
            (ngModelChange)="internal_change();onChange($event)" [editor]="Editor" [h]="h?'h':'500rem'" a-style
            [style.height]="100">
        </ckeditor>
    </div>

    <!-- <div>
    <angular-editor formControlName="htmlContent" [config]="editorConfig"></angular-editor>
</div> -->
    <!-- <div *ngIf="type==gs.enums.inputs.ck_editormail">
    <ckeditor [editor]="Editor" data="<p>Hello, world!</p>" (ready)="onReady($event)"></ckeditor>
  </div> -->


    <div *ngIf="type==gs.enums.inputs.date_picker">
        <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>



    <div (mouseover)="hovered=true" (mouseleave)="hovered = false" *ngIf="type==gs.enums.inputs.time" a-style w="100%"
        display="flex" flexWrap=1 gap="0.6" position=relative>
        <ng-container [formGroup]="time_form_group">
            <m-input minWidth="unset" (ngModelChange)="control?.markAsTouched()" placeHolder="Hours"
                formControlName="hours" [type]="gs.enums.inputs.dropdown" [columns]="0.31" px=0 [list]="hours">
            </m-input>
            <m-input minWidth="unset" (ngModelChange)=" control?.markAsTouched()" placeHolder="Minutes"
                formControlName="minutes" [type]="gs.enums.inputs.dropdown" [columns]="0.37" px=0 [list]="minutes">
            </m-input>
            <m-input minWidth="unset" (ngModelChange)="control?.markAsTouched()" placeHolder="Period"
                formControlName="   " [type]="gs.enums.inputs.dropdown" [columns]="0.35" px=0 [list]="period">
            </m-input>
        </ng-container>
    </div>

</ng-template>


<ng-template #detailCard let-passedData="passedData">
    <m-modal #image width="80" [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style pt="2.4" [w]="passedData.w" align="center" brRadius="0.6"
        [style.box-shadow]="value==passedData.type.title?'0 3px 6px #b2b2b2':'none'"
        [style.border]="value==passedData.type.title?'1px solid #05A6F0':'1px solid #e2e2e2'"
        (click)="select(passedData.index,passedData.type)">
        <img *ngIf="passedData.type.type=='image'" [src]="passedData.type.src" a-style w="9.1" mb="1.2">
        <img *ngIf="passedData.type.type=='imageupload'" [src]="passedData.type.src" a-style w="9.1" mb="1.2"
            (click)="image.openModal()">
        <m-text *ngIf="passedData.type.type=='text'" [type]="gs.enums.texts.paragraph41"
            text="{{passedData.type.title}}" mb="1.2" width="22.5"></m-text>
        <p *ngIf="passedData.type.type=='imageupload'" a-style fs="1.3" mb="2.6" w="22.5">{{passedData.type.title}}</p>
        <!-- <m-text *ngIf="passedData.type.type=='imageupload'" [type]="gs.enums.texts.paragraph49" text="{{passedData.type.title}}" ></m-text> -->
        <m-text *ngIf="passedData.type.type=='image'" [type]="gs.enums.texts.paragraph49"
            text="{{passedData.type.title}}" mb="2.6" width="22.5"></m-text>
        <m-text *ngIf="passedData.type.type=='text'" [type]="gs.enums.texts.paragraph39" text="{{passedData.type.text}}"
            mb="1.2" width="22.5"></m-text>
        <m-text *ngIf="passedData.type.for=='certificate'" [type]="gs.enums.texts.paragraph10"
            text="{{passedData.type.description}}" text_align="justify" width="22.5"></m-text>
    </div>
</ng-template>

<!-- <ng-template #media let-passedData='passedData'>
    <m-modal #image width="80" [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style display="flex" justify=space-between flexGrow="1" br="1px solid #e2e2e2" p="1" [mr]="mr?mr:1.6"
        [mt]="mt?mt:1.4" flexGap=1 brRadius="0.6" [minWidth]="minWidth" [maxWidth]="maxWidth?maxWidth:29.7"
        [h]="h?h:18">
        <div a-style display=flex justify=space-between flexDirection=column>
            <div>
                <m-text [type]="gs.enums.texts.paragraph43" text="{{passedData.name | case : 'capital' }}" mb="1">
                </m-text>
                <p a-style fs="1.1" mb="1" [w]="desc_width" [h]="desc_height" class="lineClamp4"> {{ description ||
                    'Lorem ipsum dolor sit amet, consetetur sdipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat.' }} </p>
                <ng-container>
                    <div a-style display=flex gap=2 *ngIf="multiple && value?.length" w="33" overflow="auto"
                        class="no_scroll_bar">
                        <m-image *ngFor="let image of value || []" br="1px solid #e4e4e4" (click)="image.openModal()"
                            [src]="image? ('https://new-api.mesbro.com/uploads/'+image):gs.enums.images.image154" h="5"
                            w="5" brRadius=0.6> </m-image>
                    </div>
                </ng-container>
            </div>
            <div>
                <m-button [types]="gs.enums.buttons.button18" text="Add Photo" mt=1 (click)="image.openModal()">
                </m-button>
            </div>
        </div>
        <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
            [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):gs.enums.images.image154"
            h="7" mt=1 w="7" brRadius=0.6> </m-image>
    </div>
</ng-template> -->
<div *ngIf="type==gs.enums.inputs.file" (click)="image.openModal(); " a-style h=100%>
    <ng-content select="[file]"></ng-content>
    <ng-content select="[post]"></ng-content>
    <m-modal #image width="60">
        <m-image-croppr #cropper (cancel)="image.closeModal()" [modal]="image" *ngIf="image.is_visible" [value]="value"
            [aspect_ratio]="aspect_ratio" (file_upload)="image.closeModal(); set_image($event)"></m-image-croppr>
    </m-modal>
</div>
<ng-template #media let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <label *ngIf="label" a-style mt="0.1" fs="1.3" color="#333333" display="flex" flexGap="0.2" cursor=pointer
        [for]="label" h=auto textWrap=nowrap> {{label}}</label>
    <div a-style display="flex" align="center" gap="2.4" brRadius="0.6" h="20" w="20">
        <div a-style br="1px solid #E4E4E4" brRadius="0.6" w=20 h=20>
            <div a-style justify="center" align="center" h="19.8" px=4.2 py="2.4" brRadius="0.6" *ngIf="!multiple">
                <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
                    [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):'../assets/images/assets/new-organization/Browse (1).svg'"
                    h="8.5" mt=1 w="9.4" brRadius=0.6> </m-image>
                <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()">{{ control?.value? 'Edit Photo' :
                    'Add Photo'}}</p>
            </div>
            <div a-style justify="center" align="center" h="19.8" px=4.2 py="2.4" brRadius="0.6" *ngIf="multiple">
                <m-image (click)="image.openModal()" src="../assets/images/assets/new-organization/Browse (1).svg"
                    h="8.5" mt=1 w="9.4" brRadius=0.6> </m-image>
                <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()">{{'Add Photo'}}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #mediaGallary let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style display="flex" align="center" gap="2.4" brRadius="0.6" h="20" w="20">
        <div a-style br="1px solid #E4E4E4" brRadius="0.6" w=20 h=20>
            <div a-style justify="center" align="center" h="19.8" px=4.2 py="2.4" brRadius="0.6" *ngIf="!multiple">
                <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
                    src='../assets/images/assets/new-organization/Browse (1).svg' h="8.5" mt=1 w="9.4" brRadius=0.6>
                </m-image>
                <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()">{{'Add Photo'}}</p>
            </div>
            <div a-style justify="center" align="center" h="19.8" px=4.2 py="2.4" brRadius="0.6" *ngIf="multiple">
                <m-image (click)="image.openModal()" src="../assets/images/assets/new-organization/Browse (1).svg"
                    h="8.5" mt=1 w="9.4" brRadius=0.6> </m-image>
                <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()">{{'Add Photo'}}</p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #mediaNew let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false" top="0" h="100vh">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style brRadius="0.6">
        <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
            [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):'../assets/images/Icon_Bank/Browse_Coloured.svg'"
            h="6" mt=1 w="6" brRadius=0.6> </m-image>
    </div>
</ng-template>
<ng-template #mediaCover let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style w="41.1" h="20" br="1px solid #e4e4e4" brRadius=".4" mx="1.7">
        <div a-style display="flex" justify="space-around" align="center" h="19.8">
            <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
                [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):'assets/images/assets/product/coverphoto.svg'"
                h="11.8" mt=1 w="15.3" brRadius=0.6> </m-image>
            <p a-style fs=1.6 color=#707070 mt=1.5 (click)="image.openModal()">{{ control?.value? 'Edit Cover Photo' :
                'Add Cover Photo'}}</p>
        </div>
    </div>
</ng-template>
<ng-template #mediaBrochure let-passedData='passedData'>
    <m-modal #image [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style w="20" h="20" br="1px solid #e4e4e4" brRadius=".5" mx="4">
        <div a-style brRadius=".4" align="center" h="19.8" p="2.6" display="flex" flexDirection="column">

            <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
                [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):'assets/images/assets/product/brochure.svg'"
                h="8.5" mt=1 w="8.5" brRadius=0.6> </m-image>
            <p a-style fs=1.6 w="18" color=#707070 mt=1.5 (click)="image.openModal()">{{ control?.value? 'Edit Brochure' : 'Company Brochure'}}</p>
        </div>
    </div>
</ng-template>
<ng-template #file_upload let-passedData='passedData'>
    <m-modal #image width="60" [close_on_overlay]="false">
        <m-file-upload (file_upload)="image.closeModal(); set_file($event)"></m-file-upload>
    </m-modal>
    <div a-style display="flex" justify=space-between flexGrow="1" br="1px solid #e2e2e2" p="1" mr="1.6" mt="1.6"
        flexGap=1 brRadius="0.5">
        <div>
            <m-text [type]="gs.enums.texts.paragraph43" text="{{passedData.name | case : 'capital' }}" mb="1"></m-text>
            <p a-style fs="1.1" mb="1"> {{ description || 'Lorem ipsum dolor sit amet, consetetur sdipscing elitr, seddiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.' }} </p>
            <ng-container>
                <!-- {{multiple && value?.length}} -->
                <div a-style display=flex gap=2 *ngIf="multiple && value?.length">
                    <div *ngFor="let file of value || []" br="1px solid #e4e4e4" (click)="image.openModal()" h="7"
                        w="auto" brRadius=0.4> </div>
                </div>
            </ng-container>
            <m-button [types]="gs.enums.buttons.button18" text="Add File" mt=1 (click)="image.openModal()"></m-button>
        </div>
        <!-- <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()" [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):gs.enums.images.image154" h="7" mt=1 w="auto" brRadius=0.4> </m-image> -->
        <div *ngIf="value" a-style bg=lightgray w=20 brRadius="0.6" position=relative>
            <p a-style ellipsis=true w=100% position=absolute m=0 fs=2 fw=500 bottom=0 px=1 py=0.5 bg=#1d8ecf>
                {{value.original_name}}</p>
        </div>
    </div>
</ng-template>
<ng-template #media_flip let-passedData='passedData'>
    <m-modal #image width="80" [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')  "
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal(); set_image($event)"></m-image-croppr>
    </m-modal>
    <label a-style w="35">
        <input type="checkbox" a-style display="none" />
        <div class="card">
            <div class="front" (click)="image.openModal()">
                <img src="https://image.shutterstock.com/image-vector/add-image-vector-icon-260nw-1042853482.jpg"
                    a-style w="35" h="18" brRadius="0.6">
            </div>
            <div *ngIf="multiple" class="back">
                <m-image *ngFor="let image of value || []" br="1px solid #e4e4e4" (click)="image.openModal()"
                    [src]="image? ('https://new-api.mesbro.com/uploads/'+image):'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png'"
                    w="35" h="18" brRadius=0.6> </m-image>
            </div>
            <div class="back">
                <m-image *ngIf="value && !multiple"
                    [src]="value? ('https://new-api.mesbro.com/uploads/'+value):'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png'"
                    a-style w="35" h="18" brRadius="0.6"></m-image>
            </div>
        </div>
    </label>
</ng-template>
<ng-template #detailCard let-passedData="passedData">
    <m-modal #image width="80" [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style pt="2.4" [w]="passedData.w" align="center" brRadius="0.6"
        [style.box-shadow]="value==passedData.type.title?'0 3px 6px #b2b2b2':'none'"
        [style.border]="value==passedData.type.title?'1px solid #05A6F0':'1px solid #e2e2e2'"
        (click)="select(passedData.index,passedData.type)">
        <img *ngIf="passedData.type.type=='image'" [src]="passedData.type.src" a-style w="9.1" mb="1.2">
        <img *ngIf="passedData.type.type=='imageupload'" [src]="passedData.type.src" a-style w="9.1" mb="1.2"
            (click)="image.openModal()">
        <m-text *ngIf="passedData.type.type=='text'" [type]="gs.enums.texts.paragraph41"
            text="{{passedData.type.title}}" mb="1.2" width="22.5"></m-text>
        <p *ngIf="passedData.type.type=='imageupload'" a-style fs="1.3" mb="2.6" w="22.5">{{passedData.type.title}}</p>
        <!-- <m-text *ngIf="passedData.type.type=='imageupload'" [type]="gs.enums.texts.paragraph49" text="{{passedData.type.title}}" ></m-text> -->
        <m-text *ngIf="passedData.type.type=='image'" [type]="gs.enums.texts.paragraph49"
            text="{{passedData.type.title}}" mb="2.6" width="22.5"></m-text>
        <m-text *ngIf="passedData.type.type=='text'" [type]="gs.enums.texts.paragraph39" text="{{passedData.type.text}}"
            mb="1.2" width="22.5"></m-text>
        <m-text *ngIf="passedData.type.for=='certificate'" [type]="gs.enums.texts.paragraph10"
            text="{{passedData.type.description}}" text_align="justify" width="22.5"></m-text>
    </div>
</ng-template>
<ng-template #mediaMultiImage let-passedData='passedData'>
    <m-modal #image width="80" [close_on_overlay]="false">
        <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')"
            [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()"
            (file_upload)="image.closeModal();set_image($event)"></m-image-croppr>
    </m-modal>
    <div a-style display="flex" justify=space-between flexGrow="1" br="1px solid #e2e2e2" p="1" [mr]="mr?mr:1.6"
        [mt]="mt?mt:1.4" flexGap=1 brRadius="0.6" [minWidth]="minWidth" [maxWidth]="maxWidth?maxWidth:29.7"
        [h]="h?h:18">
        <div a-style display=flex justify=space-between flexDirection=column>
            <div>
                <m-text [type]="gs.enums.texts.paragraph43" text="{{passedData.name | case : 'capital' }}" mb="1">
                </m-text>
                <p a-style fs="1.1" mb="1" [w]="desc_width" [h]="desc_height" class="lineClamp4"> {{ description ||
                    'Lorem ipsum dolor sit amet, consetetur sdipscing elitr, sed diam nonumy eirmod tempor invidunt utlabore et dolore magna aliquyam erat.' }} </p>
                <ng-container>
                    <!-- {{multiple && value?.length}} -->
                    <div a-style display=flex gap=2 *ngIf="multiple && value?.length" w="33" overflow="auto"
                        class="no_scroll_bar">
                        <m-image *ngFor="let image of value || []" br="1px solid #e4e4e4" (click)="image.openModal()"
                            [src]="image? ('https://new-api.mesbro.com/uploads/'+image):gs.enums.images.image154" h="5"
                            w="5" brRadius=0.6> </m-image>

                    </div>

                </ng-container>

            </div>
            <div>
                <m-button [types]="gs.enums.buttons.button18" text="Add Photo" mt=1 (click)="image.openModal()">
                </m-button>
            </div>

        </div>
        <m-image *ngIf="!value?.length || !multiple" (click)="image.openModal()"
            [src]="control?.value? ('https://new-api.mesbro.com/uploads/'+control?.value):gs.enums.images.image154"
            h="7" mt=1 w="7" brRadius=0.6> </m-image>

    </div>
</ng-template>