<p a-style color="#333333" fs="1.3" pb="1">Name</p>
<div a-style display="flex" flexGap="2.2">
    <div a-style br="1px solid lightgray" w="48.8" h="3.9" px="1" py="1">
        <input a-style type="text" placeholder="Enter label name" br="none">
    </div>
    <img src="https://mesbro.in/assets/images/like-grey.svg" alt="">
</div>
<p a-style color="#333333" fs="1.3" pt="1.6">Change color</p>
<div a-style display="flex" gap="2" py="2.1">
    <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
    <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
    <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>

    <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
    <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
</div>

<div *ngIf="false" a-style br="1px solid lightgray" px="2.4" py="2.4">
    <p a-style color="#333333" fs="1.4" fw="600" pb="1.6">Edit label</p>
    <p a-style color="#333333" fs="1.3" pb="1">Name</p>
    <div a-style display="flex" flexGap="2.2">
        <div a-style br="1px solid lightgray" w="48.8" h="3.9" px="1" py="1">
            <input a-style type="text" placeholder="School" br="none">
        </div>
        <img src="https://mesbro.in/assets/images/like-grey.svg" alt="">
    </div>
    <p a-style color="#333333" fs="1.3" pt="1.6">Change color</p>
    <div a-style display="flex" gap="2" py="2.1">
        <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
        <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
        <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>

        <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
        <div a-style h="5" w="5" bg="#4D94FF" brRadius="50%"></div>
    </div>
    <div a-style mt="22" display="flex" flexGap="1.6" justify="flex-end">
        <button a-style fs="1.3" color="#707070" bg="#FFFFFF" px="2" py="0.7" br=none>Cancel</button>
        <button a-style fs="1.3" color="#FFFFFF" bg="#006BFF" px="2" py="0.7" br=none brRadius="0.4">Save</button>
    </div>
</div>