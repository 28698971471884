import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-product-gallary',
  templateUrl: './product-gallary.component.html',
  styles: [
  ]
})
export class ProductGallaryComponent implements OnInit {
  // org_product_list:any
  params:any={
    type: '',
    query:'',
    product_type: '',
    v:'',
  }
  @Input() details: any;
  item : boolean = false
  @Input() detail: any;
  icon: boolean = false
  compare_title = 'Add To Compare';
  save_title = 'Saved Product';
  share_title = 'Share Product';
  favourite_title = 'Favourite Product';
  message_title = 'Message Title';
  call_title = 'Call';


  isVisible_compare: boolean = false;
  isVisible_bookmark: boolean = false;
  isVisible_favorite: boolean = false;
  isVisible_share: boolean = false;


  activities: any = {
    save_title: false,
    favourite_title: false,

  }
  viewCount: any

  org_product_list:{
    list:[],
    count:0,
  }
  primary_categories: any[] = []
  param: any = {
    type: ''
  }
  hovered_id = undefined;
  @Output() select = new EventEmitter();


  favourite_list: any = {
    list: [],
    count: 0
  }

  save_list: any = {
    list: [],
    count: 0
  }

  brand_list:{
    list:[],
    count:0,
  }

  related_product_list: {
    list: [];
    count: 0
  }
  productLists: {
    list: [];
    count: 0
  }
  recipeLists: {
    list: [];
    count: 0
  }
  serviceLists:{
    list:[],
    count:0
  }
  spiritualLists={
    list:[],
    count:0
  }
  searchList={
    list:[],
    count:0
  }
  product :any
  default_image: any = '../assets/images/assets/My-Product-tranperant.gif'
  constructor(public ar: ActivatedRoute, public gs: GlobalService) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(async (params: any)=> {
      this.params = params;
      this.searchProduct(this.params.query)
    })
     
    if (this.params.type=='product') {
      this.productList()
    }
    if (this.params.type=='recipe') {
      this.recipeList()
    }
    if (this.params.type=='service') {
      this.serviceList()
    }
    if (this.params.type=='spirituals'){
      this.spiritualList()
    }
    if(this.params.type=='favourite products'){
      this.favourite()
    }else if (this.params.type=='save products') {
      this.save()
    }
    // this.product_list();
    this.brand()
    this.get_primary_categories();
    this.related_Product()

  }

  async favourite() {
    let body = {
      ["meta.roles.owner.user." + this.gs.user.users?.activeUser?._id] :true,
      activity :"favourite",
      'parents.data.application' :"Product",
      'parents.data.component' :"Product",
    }
    this.favourite_list = await this.gs.hps.post('generic', 'activity', 'list', body)
  }


  async get_primary_categories() {
    // let response = (await this.gs.get_list('structure', 'Category', 'Primary', 'Product', [] )).list
    let body = {
     "sub_type":"Primary",
     "application":"Product",
     "size":500
    }
    let response = await this.gs.hps.post('generic', 'structure', 'list', body);
    this.primary_categories = response?.list;
    // console.log(this.primary_categories);
  }

  async save() {
    let body = {
      ["meta.roles.owner.user." + this.gs.user.users?.activeUser?._id] :true,
      activity :"save",
      'parents.data.application' :"Product",
      'parents.data.component' :"Product",
    }
   
    
    this.save_list = await this.gs.hps.post('generic', 'activity', 'list', body)
    console.log(this.save_list); 
  }

//  async product_list(){
//    let product_list :any = this.org_product_list?.list;
//    let product_type : any = product_list?.parents?.parent?.title;
//     let body = {
//       component: 'Product',
//       query:this.params.query,
//     }
    
//       this.org_product_list = await this.gs.hps.post('generic', 'data', 'list', body)
//       console.log(this.org_product_list);
//   }
 

  async brand(){
    let body ={
      component: "Brand"
    }
    this.brand_list = await this.gs.hps.post('generic', 'data', 'list', body)

  }
  
  // home search
  async searchProduct(search:any){
    let body ={
      query:search
    }
    this.searchList = await this.gs.hps.post('business', 'products', 'list', body)
  }

  // category type search
  async productList() {
    let body = {
      "categoryInfo.typeId": {
          "$eq":this.params.typeId
      }
    }
    this.productLists = await this.gs.hps.post('business', 'products', 'list', body);
  }
  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  }


  current: boolean = false;


  add_to_compare() {
    // console.log('Function called', this.detail)
    if (!this.detail) {
      return;
    }
    let current = this.gs.localStorageGet('compare-items') || {};
    current[this.detail._id] = this.detail;
    this.gs.localStorageSet('compare-items', current)
    this.gs.toast('Compare Product', 'Product was added to compare list', 'success', 3)
    this.current = !this.current
    this.gs.$refresh_compare.next()
  }


  async related_Product() {
    let body = {
      application: 'Product',
      component: 'Product',
      filters: [
        {
          id: this.params?.id,
          type: this.params?.id
        }
      ]
    }
    this.related_product_list = await this.gs.hps.post('generic', 'data', 'related', body);
    // console.log(this.related_product_list);
    
  }


  async recipeList() {
    let body = {
      "typeId": this.params.typeId
    }
    this.recipeLists = await this.gs.hps.post('food', 'recipes', 'list', body);
  }
  async serviceList() {
    let body = {
      "categoryInfo.typeId":{
        "$eq":this.params.typeId
      }
    }
    this.serviceLists = await this.gs.hps.post('business', 'services', 'list', body);
    console.log(this.serviceLists);
    
  }
  async spiritualList() {
    let body =  {
      "categoryInfo.typeId":{
        "$eq":this.params.typeId
      }
    }
    this.spiritualLists = await this.gs.hps.post('spiritual', 'spirituals', 'list', body);
    console.log(this.spiritualLists);
    
  }
}