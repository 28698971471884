<!-- page2 -->
<div  a-style display="flex" minHeight="53.2" maxHeight="53.2">
    <div a-style maxWidth="72.6" h="auto" shadow="0px 3px 6px #17171728" brRadius="0.6" bg="#fff" p="2.4"
        [tabindex]="1">
        <p a-style fs="2.4" display="flex" justify="center">One Request, Multiple Quotes</p>
        <div *ngIf="first">
            <!-- <div a-style mt="4.1">
                <m-input a-style [tabindex]="2" prefix="../assets/images/assets/new-organization/Text search icon.svg"
                    [formControl]="search" (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)" h="4.6"
                    minWidth="62.8" mb="0" outline=none px=0 fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" outline=none
                    [capitalize]=false [uppercases]=false (ngModelChange)="onChange($event)"
                    placeHolder="Search Product Here" display="900:none" position="relative">
                </m-input>
            </div> -->
            <div a-style display="flex" gap="1.4" flexWrap="wrap" mt="3.1">
                <m-input [type]="gs.enums.inputs.dropdown" placeHolder="Primary Categories" [list]="primaryArray"
                    a-style label="Primary Category" minWidth="32.7" maxWidth="32.7" [(ngModel)]="primaryName"
                    (ngModelChange)="primaryName?getsecondaryByName():''">
                </m-input>
                <m-input [type]="gs.enums.inputs.dropdown" placeHolder="Secondary Categories" [list]="secondaryArray"
                    a-style label="Secondary Category" minWidth="32.7" [(ngModel)]="secondaryName"
                    (ngModelChange)="secondaryName?getTertiaryByName():'secondaryArray'" maxWidth="32.7">
                </m-input>
                <m-input [type]="gs.enums.inputs.dropdown" placeHolder="Tertiary Categories" [list]="tertiaryArray"
                    a-style label="Tertiary Category" minWidth="32.7" [(ngModel)]="tertiaryName"
                    (ngModelChange)="getTypeByName()" maxWidth="32.7">
                </m-input>
                <!-- <m-new-input *ngIf="gs.router.url.includes('/service/home')" [type]="gs.enums.inputs.dropdown"
                    placeHolder="Service Type" label="Service Type" minWidth="32.7"></m-new-input> -->
                <m-input [type]="gs.enums.inputs.dropdown" placeHolder="Product Type" [list]="typeArray" a-style
                    label="Product Type" minWidth="32.7" maxWidth="32.7" [(ngModel)]="typeName"
                    (ngModelChange)="getName(typeName)">
                </m-input>
                <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.dropdown.sourcingType" label="Sourcing Type"
                placeHolder="Sourcing Type" cleareIcon="true" minWidth="32.7" maxWidth="32.7"
                [(ngModel)]="sourcingType">
                 </m-input>
                <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.dropdown.sourcingPurpose" 
                 label="Sourcing Purpose" maxWidth="32.7" placeHolder="Sourcing Purpose"
                cleareIcon="true" minWidth="32.7" maxWidth="32.7"  maxHeight="16" minHeight="0" [(ngModel)]="sourcingPurpose">
                 </m-input>
                <div a-style display="flex" flexDirection="column" gap="1.6">
                    <p a-style align="left" fs="1.3">Quantity</p>
                    <input type="text" placeholder="Quality" outline="none" a-style br="0.5px solid #e4e4e4"
                        brRadius="0.6" w="32.7" h="3.9" p="1" [(ngModel)]="quantity">
                </div>
                <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.masters['Unit']" bindValue="label"
                    bindLabel="label" label="Unit" maxWidth="32.7" placeHolder="Unit" cleareIcon="true" minWidth="32.7" maxHeight="12" minHeight="0"
                    [(ngModel)]="unit" maxWidth="32.7">
                </m-input>
            </div>
            <div a-style w="66.8" display="flex" justify="flex-end" mt="1">
                <m-button [types]="gs.enums.buttons.button0" text="Next" (click)="next()">
                </m-button>
            </div>
        </div>
        <div *ngIf="second" a-style display="flex" gap="1.4" flexWrap="wrap" mt="3">
            <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.masters['Trade Terms']" bindValue="label"
                bindLabel="label" label="Trade Terms" maxWidth="32.7" placeHolder="Trade Terms" cleareIcon="true"
                minWidth="32.7" maxWidth="32.7" [(ngModel)]="tradeTerms">
            </m-input>
            <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.dropdown.paymentTerm" label="Payment Term"
                maxWidth="32.7" placeHolder="Payment Term" cleareIcon="true" minWidth="32.7" maxWidth="32.7"
                [(ngModel)]="paymentTerm">
            </m-input>
            <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.dropdown.shippingMethod"  label="Shipping Method" maxWidth="32.7" placeHolder="Shipping Method"
                cleareIcon="true" minWidth="32.7" maxWidth="32.7" [(ngModel)]="shippingMethod">
            </m-input>
            <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Max Budget" label="Max Budget"
            minWidth="32.7" [(ngModel)]="currency"></m-new-input>
            <m-new-input [type]="gs.enums.inputs.newText_area"
                    placeHolder="Other requirement details"
                    label="Is there any other requirement details like specification, usage, preference, etc. that you'd like to tell us?"
                    rows="3" a-style class="no_scroll" [(ngModel)]="descrption">
                </m-new-input>
                <div a-style display="flex" align="end" gap="0.6" >
                    <m-new-input [type]="gs.enums.inputs.newCheckbox" [(ngModel)]="tersmAndCondition"></m-new-input>
                    <p a-style fs="1.3">I agree to and send this RFQ to <a a-style color="#2479AB" href="/center/help-detail?_id=62dbd42ffc3e08139061695c" target="_blank">Terms of Service</a>
                        the all suppliers who can fulfill the requirement.</p>
                </div>
                <div a-style w="66.8" display="flex" justify="space-between" mt="1">
                    <m-button [types]="gs.enums.buttons.button0" text="Back" h=2.9 (click)="first = true ; second=false ">
                    </m-button>
                    <m-button [types]="gs.enums.buttons.button0" text="Submit" h=2.9
                    (click)="fourth=false;third=false;submit();first=true">
                </m-button>
                </div>
            </div>
            <!-- <span a-style color="#2479AB" href="/center/home" target="blank" cursor="pointer">Terms of Service</span> -->
            <!-- <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.masters['Sea Port']" bindValue="label"
                bindLabel="label" label="Destination Sea Port" maxWidth="32.7" placeHolder="Destination Sea Port"
                cleareIcon="true" minWidth="32.7" maxWidth="32.7" [(ngModel)]="seaPort">
            </m-input> -->
            <!-- <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.masters['Nearest Airport']" bindValue="label"
                bindLabel="label" label="Destination Airport" maxWidth="32.7" placeHolder="Nearest Airport"
                cleareIcon="true" minWidth="32.7" maxWidth="32.7" [(ngModel)]="airport">
            </m-input>
            <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Location" label="Location" minWidth="32.7"
                [(ngModel)]="location">
            </m-new-input> -->
            
        </div>
        <div *ngIf="third">
            <div a-style display="flex" gap="2.4" flexWrap="wrap" mt="3">
                <!-- <m-input [type]="gs.enums.inputs.dropdown" [list]="gs.dropdown.CurrencyCode" label="Currency"
                    maxWidth="32.7" placeHolder="Currency" cleareIcon="true" minWidth="32.7" maxWidth="32.7"
                    [(ngModel)]="maxBudget">
                </m-input> -->
              
                <!-- <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Expected Delivery Time In Days"
                    label="Expected Delivery Time In Days" minWidth="32.7" [(ngModel)]="deliveryTime"></m-new-input>
                <m-new-input [type]="gs.enums.inputs.newText" placeHolder="Expected Certifications"
                    label="Expected Certifications" minWidth="32.7" [(ngModel)]="expectedCertifications"></m-new-input> -->
                
            <div a-style display="flex" justify="space-between" mt="2">
                <!-- <button type="text" a-style w="7.1" h="2.9" br="1px solid #2479AB" brRadius="0.6" bg="#fff"
                    (click)="second=true ; third=false">Back</button> -->
                <m-button [types]="gs.enums.buttons.button0" text="Back" h=2.9 (click)="second=true ; third=false">
                </m-button>
                <!-- <button type="text" a-style w="8.5" h="2.9" br="1px solid #2479AB" brRadius="0.6" bg="#fff"
                    (click)="fourth=true ; third=false">Submit</button> -->
               
            </div>
        </div>
    </div>
</div>
<div class="no_scroll_bar" a-style w="67.8" maxHeight="20.6" br="1px solid #e4e4e4" brTop="none" bg="#fff"
    position="absolute" top="14.5" left="2.4" overflowX="auto" *ngIf="searchDropdown">
    <div class="Edit" *ngFor="let detail of typeProductList.list" a-style display="flex" justify="space-between" p="1.6"
        align="center">
        <div a-style display="flex" gap="1.6">
            <img src="../assets/images/Icon_Bank/Text_Search_Grey.svg" alt="" a-style h="2" w="2">
            <p a-style fs="1.3" align="left">{{detail?.basic?.name}}</p>
        </div>
        <p a-style fs="1.3" color="#E4E4E4">Remove</p>
    </div>
</div>

<!-- page6-->
<!-- <div *ngIf="fourth" a-style w="56.0" h="27.5" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center"
    shadow="0px 3px 6px #17171728" position="absolute" top="14" left="12">
    <div a-style w="47.6" h="21.5" display="flex" flexDirection="column" align="center" gap="2.4"
        (click)='fourth=false ; first=true'>
        <img src="../assets/images/GIF_Bank/Other/Green_Tick.gif" alt="" a-style w="14" h="14">
        <p a-style fs="2">RFQ Successfully Submitted</p>
        <p a-style fs="1.3" color="#2479AB">Rest assured to get faster and better response from the right suppliers. Let
            suppliers give you the best deal for your request.</p>
    </div>
</div> -->
<!-- ithe click ="profile" -->
<!-- ithe *ngIf="form?.get" -->
<!-- <div *ngIf="form?.get('attachments')?.value && gs.temp_display" a-style mx="2.6">
                    <m-mail-attachments #attachments [attachments]="form?.get('attachments')?.value"></m-mail-attachments>
                </div>
                <ng-container [formGroup]="form">
                    <m-input (ngModelChange)="gs.toggle_display()" [type]="gs.enums.inputs.mail_attachments" formControlName="attachments" [onlyInput]="true" [columns]="false" [aspect_ratio]="0.4">
                        <div a-style display=flex alignItems=center h=100%>
                            <m-image src="../assets/images/assets/attachment_grey.svg" hover="../assets/images/assets/attchment_colour.svg" ></m-image>
                        </div>
                    </m-input>
                </ng-container> -->
<!-- (click)="profile.image.openModal()"
                                        <m-input [type]="gs.enums.inputs.file"  #profile  formControlName="picture"  [onlyInput]="true" [columns]="false" [aspect_ratio]="1"></m-input> -->
<script>
    document.querySelector('[tabindex="-1"]').focus()
</script>