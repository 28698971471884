<div a-style px="2" py="3">
<m-input a-style placeHolder="Search" mb="4" px=0 a-style postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" [columns]="1/3"></m-input>
<div a-style display="flex" justify="space-around" brBottom="1px solid #e2e2e2" p="2">
    <m-text [type]="gs.enums.texts.paragraph38" text="Date"></m-text>
    <m-text [type]="gs.enums.texts.paragraph38" text="Description"></m-text>
    <m-text [type]="gs.enums.texts.paragraph38" text="Receiver Name"></m-text>
    <m-text [type]="gs.enums.texts.paragraph38" text="Status"></m-text>
    <m-text [type]="gs.enums.texts.paragraph38" text="Action"></m-text>
</div>
<div a-style p="2" display="flex" justify="space-around" gap="7" brBottom="1px solid #e2e2e2" minWidth="40">
    <div a-style minWidth="14" align="left">
        <m-text [type]="gs.enums.texts.paragraph38" text="26/04/98" mb="1"></m-text>
        <m-text [type]="gs.enums.texts.paragraph38" text="7.36PM"></m-text>
    </div>
    <div a-style  maxWidth="60">
        <m-text [type]="gs.enums.texts.paragraph38" text="DescriptionDescriptionDescription to and we  Description  Description " mb="1.5"></m-text>
        <m-text [type]="gs.enums.texts.paragraph38" text="Quantity"></m-text>
        <m-text [type]="gs.enums.texts.paragraph38" text="Product Name"></m-text>
    </div>
    <m-text [type]="gs.enums.texts.paragraph38" text="Amruta Kor" minWidth="14" align="center"></m-text>

    <m-input minWidth="10%" placeHolder="Status" [mb]="0" [type]="gs.enums.inputs.dropdown" [columns]="0.10" px=0 [list]="statusList" bindValue="status" bindLabel="status"></m-input>

    <m-button [types]="gs.enums.buttons.button18" text="Message" mb="6" mr="2"></m-button>

</div>
</div>

