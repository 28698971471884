
<!-- <m-input onlyInput=true a-style mt="1.6"></m-input>
<div a-style display="flex" justify="flex-end" mt=2.4 flexGap=1.6 align=center>
    <p a-style fs=1.3 (click)="modal.closeModal()">Cancel</p> -->
    <!-- <button a-style px="2.4" py="0.8" bg="#006BFF" br="1px solid #006BFF" color="#FFFFFF" brRadius="0.4" outline="0">Set</button> -->
    <!-- <m-button   [types]="gs.enums.buttons.button18" text="Set" ></m-button>
</div> -->


<div a-style width="54.5" h="fit-content" bg="#FFF3D2" brRadius="0.6" p="2.4" maxHeight=80.2>
    <div a-style display="flex" justify="flex-end">
        <img src="../assets/images/assets/Group 80767.svg" alt="" a-style w="0.64" h="2">



    </div>
    <p a-style fs="1.3" mt="1.3" h="fit-content" overflow="auto" class="no_scroll_bar">
        {{details?.data?.text}}
    </p>
    <div a-style br="0.2px  solid lightgray" mt="0.8">
    </div>
    <div a-style display="flex" align="center" justify="space-between" mt="2">
        <div a-style display="flex" align="center">
            <img src="../assets/images/assets/label_coloured (2).svg" alt="" a-style w=2 h=1.6>
            <p a-style fs="1.3" ml="0.9" textCenter="left">Work, School, Office .</p>
        </div>

        <img src="../assets/images/assets/star yellow.svg" alt="" a-style w=1.97 h=1.96 ml="0.9">
    </div>
</div>
