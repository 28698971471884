import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-help',
  templateUrl: './help.component.html',
  styles: [
  ]
})
export class HelpComponent implements OnInit {

  @Input() modal:any
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    let scroll  = document.createElement('style')
    scroll.innerHTML  = `#scroll::-webkit-scrollbar {display: none;}`
    document.head.appendChild(scroll);
  }
  
  select_application(data: any) {
    this.gs.router.navigateByUrl(`${data.application.toLowerCase()}/home`)
  }
}
