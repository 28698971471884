<div a-style h="7.2"  brBottom="1px solid #e4e4e4" bg="#F8F8F8" br="0.5 solid #E4E4E4" >
    <div a-style h="7.2" box-shadow=' 0px 3px 6px #17171729' br="0.5px solid #E4E4E4" display="flex" align="center"
        justify="center" gap="2.5" shadow="0 3px 6px 0 #17171729">
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/service/home'])" [style.color]="gs.router.url.includes('/service/home')?'#2479AB':'#33333'">
            <!-- <img *ngIf="!gs.router.url.includes('/service/home')" src="../assets/images/ME/Home.svg" a-style w="2.6"
            h="2.6" routerLink="/service/home"> -->
        <img  a-style h=2.6 w=2.6 cursor="pointer"
            src="../assets/images/Icon_Bank/Home_Coloured.svg">
            <p a-style fs="1.4" cursor="pointer">Home</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"  (click)="gs.router.navigate(['/service/categories'],{queryParams:{category:'primary'}})" [style.color]="gs.router.url.includes('/service/categories')?'#2479AB':'#33333'">
            <!-- <img *ngIf="!gs.router.url.includes('/service/categories')" src="../assets/images/Icon_Bank/Categories.svg" a-style
                w="2.6" h="2.6" routerLink="/serive/categories" cursor="pointer"> -->
            <img  src="../assets/images/Icon_Bank/Category_Coloured.svg"
                a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Categories</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="compare_not_found()" [style.color]="gs.router.url.includes('/service/compare')?'#2479AB':'#33333'">
            <!-- <img *ngIf="!gs.router.url.includes('/service/compare')" routerLink="/service/compare" src="../assets/images/Icon_Bank/Compare1.svg" a-style w="2.6" h="2.6" cursor="pointer"> -->
            <img  src="../assets/images/Icon_Bank/Compare_Coloured.svg" a-style
            w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Compare</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"  [style.color]="gs.router.url.includes('/service/fav')?'#2479AB':'#33333'" (click)="gs.router.navigate(['/service/fav'])">
            <!-- <img *ngIf="!gs.router.url.includes('/service/compare')" routerLink="/service/compare" src="../assets/images/Icon_Bank/Compare1.svg" a-style w="2.6" h="2.6" cursor="pointer"> -->
            <img  src="../assets/images/Icon_Bank/Rating_Coloured.svg" a-style  
            w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Favourite</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"  [style.color]="gs.router.url.includes('/service/save')?'#2479AB':'#33333'" (click)="gs.router.navigate(['/service/save'])">
            <!-- <img *ngIf="!gs.router.url.includes('/service/compare')" routerLink="/service/compare" src="../assets/images/Icon_Bank/Compare1.svg" a-style w="2.6" h="2.6" cursor="pointer"> -->
            <img  src="../assets/images/Icon_Bank/Save_Coloured.svg" a-style  
            w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">My Collection</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/service/history'])" [style.color]="gs.router.url.includes('/service/history')?'#2479AB':'#33333'">
            <img src="../assets/images/Icon_Bank/History_Coloured.svg" a-style w="2.6" h="2.6"
            cursor="pointer">
        <!-- <img src="../assets/images/ME/History Control (1).svg" a-style w="2.6" h="2.6"
            *ngIf="!gs.router.url.includes('service/history')" cursor="pointer"> -->
            <p a-style fs="1.4" cursor="pointer">History</p>
        </div>
    </div>
</div>