import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-me-header',
  templateUrl: './me-header.component.html',
  styles: [
  ]
})
export class MeHeaderComponent implements OnInit {

  constructor(public gs:GlobalService, public ar:ActivatedRoute) { }

  ngOnInit(): void {
  }

}
