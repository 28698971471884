<!-- <div a-style br="1px solid #e2e2e2" brRadius="0.5" shadow="0 2px 10px rgb(0 0 0 / 20%)" p="2.5" align="center"
    position="relative" bg="#FFFFFF" *ngIf=false>
    <div a-style align="center" mb="3" mt="2">
        <p a-style fs="1.8" mb="1" fw="500" *ngIf="params.type=='bug'">REPORT A BUG OR AN ISSUE</p>
        <p a-style fs="1.3" color="#707070" *ngIf="params.type=='bug'">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>
        <p a-style fs="1." mb="1" fw="500" *ngIf="params.type=='feature'">SUGGEST FEATURE</p>
        <p a-style fs="1.3" color="#707070" *ngIf="params.type=='feature'" maxWidth="70">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>
    </div>
    <div a-style display="flex" justify="space-around" align="center" gap="6">
        <m-image a-style [src]="gs.enums.images.image19" w="28.2" display="850:none"></m-image>
        <div a-style align="left">
            <form [formGroup]="form">
                <m-form [brNone]=false [paddingNone]=false>
                    <m-input formControlName="title" label="Title" placeHolder="Title" pl='0'></m-input>
                    <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                        placeHolder="Description" a-style mt="0" label="Description" rows=8 px=0></m-input>
                    <m-text a-style [type]="gs.enums.texts.paragraph1" text="2000 characters remaining" a-style mt="0"
                        textCenter="right" w="100%"> </m-text>
                    <m-text a-style [type]="gs.enums.texts.paragraph9" text="Screenshot" mb="0"></m-text>
                    <m-input [type]="gs.enums.inputs.media" formControlName="upload" [onlyInput]="true" [columns]="1" [aspect_ratio]="1" label="Screenshot" description="Hello this is my description"> </m-input>
                    <m-input [columns]="1" flexGrow=0 [aspect_ratio]='1' mr=0 formControlName='upload'
                        [type]="gs.enums.inputs.media" maxWidth="100%" description="   " pl="0" pr="0" mt="1">
                    </m-input>
                </m-form>
            </form>
        </div>
    </div>
    <div a-style display="flex" justify="flex-end" mt=2.4 flexGap=1.6 align=center>
        <m-button [types]="gs.enums.buttons.button18" text="Submit" (click)="feedback();"></m-button>
        <m-button [types]="gs.enums.buttons.button81" px=1 color="#333333" bg="#ffffff" text="Cancel"
            (click)="modal.closeModal()" br="1px solid #b2b2b2" br="1px solid rgba(228, 228, 228, 0.47)"></m-button>
    </div>
</div>-->





<!--
<div a-style br="1px solid #e2e2e2" brRadius="0.6" shadow="0 2px 10px rgb(0 0 0 / 20%)" p="2.4" align="center"
    position="relative" bg="#FFFFFF" *ngIf=true>
    <div a-style align="left" mb="1.4">
        <p a-style fs="1.6" mb="2.2" fw="500" color="#333333" *ngIf="params.type=='bug'">Report Bug</p>
        <p a-style fs="1.3" color="#333333" *ngIf="params.type=='bug'">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>

        <p a-style fs="1.6" mb="2.2" fw="500" color="#333333" *ngIf="params.type=='feature'">Suggest Feature</p>
        <p a-style fs="1.3" color="#333333" *ngIf="params.type=='feature'">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>
    </div>


    <div a-style display="flex" justify="space-around" align="center" gap="2.4" brRadius="0.6">
        <div a-style br="1px solid #E4E4E4" p=1 brRadius="0.6">
            <div a-style bg=#F5F5F5 justify="center" align="center" px=4.2 py="2.4" brRadius="0.6">
                <img src="../assets/images/images/picture.svg" a-style >
                <p a-style fs=1.6 color=#707070 mt=1.5>Add Photo</p>
            </div>
        </div>
        <form [formGroup]="form">
            <m-input [type]="gs.enums.inputs.media" formControlName="original_name" [onlyInput]="true" [columns]="1/2"
                [aspect_ratio]="1" label="Add Image For Discount Banner" mt="3.8"
                description="Please Select an image for discount sections slider"> </m-input>
        </form>
        <div a-style align="left">
            <form [formGroup]="form">
                <m-form [brNone]=false [paddingNone]=false>
                    <m-input formControlName="title" label="Title" placeHolder="Title" px='0' a-style required="true">
                    </m-input>
                    <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                        placeHolder="Description" a-style mt="0" label="Description" rows=3 px=0></m-input>
                </m-form>
            </form>
        </div>
    </div>
    <div a-style display="flex" justify="space-between" mt=2.4 flexGap=1.6 align=center>
        <div a-style display="flex" gap=1>
            <img src="../assets/images/assets/attchment_coloured.svg" a-style h=1.6 w=1.6>
            <p a-style color="#B2B2B2" fs=1.1>0 Attachments (0MB)</p>
        </div>
        <m-button [types]="gs.enums.buttons.button87" text="Submit" (click)="feedback();"></m-button>
        <button a-style *ngIf="params.type=='bug'" cursor="pointer" (click)="report_bug();" class="button-modal" bg="#ffffff"
        br="1px solid #05A6F0" px=1.8 py=0.5 my=0.9 brRadius="0.6" >Submit</button>
        <button a-style *ngIf="params.type=='feature'" cursor="pointer" (click)="suggest_feature();" class="button-modal" bg="#ffffff"
        br="1px solid #05A6F0" px=1.8 py=0.5 my=0.9 brRadius="0.6" >Submit</button>
    </div>
</div> -->


<!-- <m-button [types]="gs.enums.buttons.button81" px=1 color="#333333" bg="#ffffff" text="Cancel" (click)="modal.closeModal()" br="1px solid #b2b2b2" br="1px solid rgba(228, 228, 228, 0.47)"></m-button> -->

<!-- {{form?.value|json}} -->




<div a-style br="1px solid #e2e2e2" brRadius="0.6" shadow="0 2px 10px rgb(0 0 0 / 20%)" p="2.4" align="center"
    position="relative" bg="#FFFFFF" *ngIf=false>
    <div a-style align="left" mb="1.4" *ngIf="params.type=='bug'">
        <p a-style fs="1.6" mb="2.2" fw="500" color="#333333">Report Bug</p>
        <p a-style fs="1.3" mb="2.4" color="#333333">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>

        <div a-style display="flex" justify="space-around" align="center" gap="2.4" brRadius="0.6">
            <form [formGroup]="form">
                <m-input [type]="gs.enums.inputs.media" formControlName="original_name" [onlyInput]="true"
                    [columns]="1/2" [aspect_ratio]="1" label="Add Image For Discount Banner" mt="3.8"
                    description="Please Select an image for discount sections slider"> </m-input>
            </form>
            <div a-style align="left">
                <form [formGroup]="form">
                    <m-form [brNone]=false [paddingNone]=false>
                        <m-input formControlName="titles" label="Title" placeHolder="Title" px='0' a-style
                            required="true">
                        </m-input>
                        <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                            placeHolder="Description" a-style mt="0" label="Description" rows=3 px=0></m-input>
                    </m-form>
                </form>
            </div>
        </div>
        <div a-style display="flex" justify="space-between" mt=2.4 flexGap=1.6 align=center>
            <div a-style display="flex" gap=1>
                <img src="../assets/images/assets/attchment_coloured.svg" a-style h=1.6 w=1.6>
                <p a-style color="#B2B2B2" fs=1.1>0 Attachments (0MB)</p>
            </div>
            <button a-style *ngIf="params.type=='bug'" cursor="pointer" (click)="report_bug()"  bg="#ffffff"
            br="1px solid #05A6F0" px=1.8 py=0.5 my=0.9 brRadius="0.6" >Submit</button>
        </div>
    </div>
    

    <div a-style align="left" mb="1.4" *ngIf="params.type=='feature'">
        <p a-style fs="1.6" mb="2.2" fw="500" color="#333333">Suggest Feature</p>
        <p a-style fs="1.3" mb="2.4" color="#333333">Thank you for trying to help us
            grow. We appreciate your feedback and will try to get it resolved as soon as possible.</p>

            <div a-style display="flex" justify="space-around" align="center" gap="2.4" brRadius="0.6">
                <form [formGroup]="form">
                    <m-input [type]="gs.enums.inputs.media" formControlName="original_name" [onlyInput]="true"
                        [columns]="1/2" [aspect_ratio]="1" label="Add Image For Discount Banner" mt="3.8"
                        description="Please Select an image for discount sections slider"> </m-input>
                </form>
                <div a-style align="left">
                    <form [formGroup]="form">
                        <m-form [brNone]=false [paddingNone]=false>
                            <m-input formControlName="title" label="Title" placeHolder="Title" px='0' a-style
                                required="true">
                            </m-input>
                            <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                                placeHolder="Description" a-style mt="0" label="Description" rows=3 px=0></m-input>
                        </m-form>
                    </form>
                </div>
            </div>
            <div a-style display="flex" justify="space-between" mt=2.4 flexGap=1.6 align=center>
                <div a-style display="flex" gap=1>
                    <img src="../assets/images/assets/attchment_coloured.svg" a-style h=1.6 w=1.6>
                    <p a-style color="#B2B2B2" fs=1.1>0 Attachments (0MB)</p>
                </div>
                <button a-style *ngIf="params.type=='feature'" cursor="pointer" (click)="suggest_feature()" class="button-modal" bg="#ffffff"
                br="1px solid #05A6F0" px=1.8 py=0.5 my=0.9 brRadius="0.6" >Submit</button>
            </div>
    </div>
</div>


<!--  -->
<div *ngIf="first" a-style bg="#fff" opacity="1" brRadius="0.6" br="1px solid #e4e4e4" p="2.4" >
<p a-style fs="1.6" mb="4.1" color="#333333">Report Bug</p>
<div a-style mb="4" align="center">
    <img src="../assets/images/assets/add-file10.svg" a-style h=13 w=11.5>
</div>
<!-- <div a-style mb="4" align="center">
    <form [formGroup]="form" a-style  align="center">
        <m-input [type]="gs.enums.inputs.media" formControlName="original_name" [onlyInput]="true"
            [columns]="1/2" [aspect_ratio]="1" label="Add Image For Discount Banner" mt="3.8"
            description="Please Select an image for discount sections slider"> 
        </m-input>
    </form>
</div> -->
<!-- <m-new-input formControlName="original_name" [type]="gs.enums.inputs.file" ></m-new-input> -->
<div a-style mb="1.4">
    <form [formGroup]="form">
        <m-form [brNone]=false [paddingNone]=false>
            <m-input [type]="gs.enums.inputs.text" formControlName="titles" label="Title" minWidth="32.7" maxWidth="32.7" placeHolder="Title" px='0' a-style
                required="true">
            </m-input>
            <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                placeHolder="Description" minWidth="32.7" maxWidth="32.7" a-style mt="0" label="Description" rows="8" px=0></m-input>
        </m-form>
    </form>
</div>
<div a-style display="flex" justify="flex-end">
    <m-button [types]="gs.enums.buttons.button102" a-style fs="1.3" color="#333" m="0" bg="#fff" brRadius="0.6" br="1px solid #2479AB" (click)="modal.openModal() ; first=false ">Submit</m-button>
</div>
</div>



<!-- open modal -->
<div>
    <m-modal a-style #modal>
    <div a-style py="3" bg="#fff" brRadius="0.6" opacity="1" align="center">
     <img src="../assets/images/assets/tick (3).svg" a-style w="10" h="10" bg="transparent" brRadius="50%" opacity="1" mb="2.4">
     <p a-style color="#333" fs="2" m="0">Bug Reported Successfully</p>
    </div>
    </m-modal>
</div>