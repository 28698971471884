<!-- @keyframes block{
    0%{left:480px;};
    100%{left:-40px;};
} -->
<m-center *ngIf="false">

    <div a-style display="flex" flexDirection="column" justify="center" p="3;680:2.4;400:1.4" align="center">
        <img src="/assets/images/logo.svg " a-style w="31;680:25;500:20;415:17;380:15" mb="2.3">
        <div a-style display="flex" mb="0.1">

        </div>

        prefix="../assets/images/assets/mesbro_logo.svg"
        <m-input [formControl]="search" (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)" a-style
            w="100" w="57;680:40;415:25;400:25;380;26;" h="4.6" mb="1.5" fs="1.3" shadow="0 3px 6px 0 #f1f1f1"
            minWidth="380:25" outline=none
            postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg"
            placeHolder="Search Mesbro or Type a URL"></m-input>
        <div a-style maxWidth="60.2" w="60.2;680:45;500:40;450:35;415:30;400:23;412:20" h="20.7" display="flex"
            flexWrap="wrap" overflow="auto" class='no_scroll'>
            <ng-container [ngTemplateOutlet]="apps" *ngFor="let item of gs.applications"
                [ngTemplateOutletContext]="{passedData: {src:item.src, application:item.application, link : item.link}}">
            </ng-container>
        </div>

    </div>
</m-center>

<div a-style display="flex" flexDirection="column" gap="16.9" bgUrl="../assets/images/ME/Search_Engine.gif"
    bgSize="contain" bgRepeat="no-repeat" cursor="pointer">
    <div a-style>

        <div a-style display="flex" flexDirection="column" align="center" justify="flex-end" h="40vh">
            <div a-style display="flex" align="center" flexDirection="column" gap="4">
                <img src="../assets/images/Doodle_Bank/Doodle_Bank/Doodle_1_maximize.gif" alt="" a-style w="60%">
                <!-- <input type="text" a-style fs="1.3" pl="5" placeholder="Search Mesbro or Type a URL"  a-style h="4.6"  br="none" w="54"   > -->
                <div display="flex" flexDirection="column" gap="3">
                    <!-- <m-input a-style [uppercases]=false [capitalize]=false prefix_h="2" prefix_w="2"
                        w="72" h="4.6" minWidth="58.2" maxWidth="58.2"
                        mb="0" outline=none px=0 fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" outline=none
                        postfix="../assets/images/assets/new-organization/Text search icon.svg"
                        postfix3="../assets/images/Mic_Coloured.svg" postfix2="../assets/images/img search icon.svg"
                        placeHolder="Search Mesbro or Type a URL" display="900:none" br="0.5px solid #e4e4e4"></m-input> -->
                    <m-input [uppercases]=false a-style
                        prefix="../assets/images/assets/new-organization/Text search icon.svg" [formControl]="search"
                        (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)" h="4.6" minWidth="58.2"
                        maxWidth="58.2" mb="0" outline=none px=0 fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" outline=none
                        placeHolder="Search here" display="900:none"></m-input>
                </div>
                <m-button [types]="gs.enums.buttons.button0" text="Search"></m-button>
            </div>
        </div>
    </div>
    <!-- prefix="../assets/images/assets/mesbro_logo.svg" -->
    <!-- Wheather -->
 
    <div a-style display="flex" gap="8" justify="center">

        <!-- wheather -->

        <div a-style h="16" w="30.2" br="0.5px solid #E4E4E4" shadow="0 3px 6px 0 #17171729" brRadius=".6" p="1.7">
            <div a-style display="flex" justify="space-between">
                <p a-style fs="1.3">WEATHER</p>
                <!-- <img src="../assets/images/Icon_Bank/Ellipse_Vertical_Coloured.svg" alt="" a-style w="2.6" h="2.6"> -->
            </div>
            <p a-style align="center" fs="1.3">{{my_weather?.name}} {{my_weather?.sys?.country}}</p>
            <div a-style display="flex" justify="space-between" align="center">
                <div a-style display="flex" align="center" gap="0.7">
                    <img src="../assets/images/Icon_Bank/Weather_Forecast_Image.svg" alt="" a-style h="3.5" w="4.5">
                    <p a-style><span a-style fs="4.2">{{my_weather?.main?.temp}}<sup a-style>°<span a-style
                                    fs="3">C</span> </sup></span></p>
                </div>
                <div>
                    <!-- <p a-style fs="1.3" *ngIf="my_weather?.weather[0]">{{my_weather?.weather[0]?.description | titlecase}}</p> -->
                    <span>{{my_weather?.main?.humidity}}% </span>&nbsp;&nbsp;<span>{{my_weather?.main?.pressure}}</span>
                </div>
            </div>
            <!-- <p a-style align="center" fs="1.3" color="#2479AB">See full forecast</p> -->
        </div>

        <!-- | number: '1.0-0' -->
        <!-- Sensex -->
        <div a-style h="16" w="30.2" br="0.5px solid #E4E4E4" shadow="0 3px 6px 0 #17171729" brRadius=".6" p="1.7">
            <div a-style display="flex" justify="space-between" align="center">
                <p a-style fs="1.3">MARKET</p>
                <!-- <img src="../assets/images/Icon_Bank/Ellipse_Vertical_Coloured.svg" alt="" a-style w="2.6" h="2.6"> -->
            </div>
            <div a-style display="flex" flexDirection="column" mt="2" gap="2.4">
                <div a-style display="flex" justify="space-between">
                    <p a-style fs="1.3">SENSEX</p>
                    <p a-style fs="1.3">59,744.65</p>
                    <p a-style fs="1.3" color="#81BC06">+0.24%</p>
                </div>
                <div a-style display="flex" justify="space-between">
                    <p a-style fs="1.3">SENSEX</p>
                    <p a-style fs="1.3">59,744.65</p>
                    <p a-style fs="1.3" color="#81BC06">+0.24%</p>
                </div>
            </div>
        </div>

        <!-- Cricket -->
        <div a-style h="16" w="30.2" br="0.5px solid #E4E4E4" shadow="0 3px 6px 0 #17171729" brRadius=".6" a-style
            align="center" px="1.4" pt="1.7">
            <div a-style display="flex" flexDirection="column">
                <div a-style display="flex" justify="space-between">
                    <p a-style fs="1.3">LIVE CRICKET</p>
                    <div a-style display="flex" justify="space-between" align="center">
                        <img src="../assets/images/Icon_Bank/Left_Arrow_Coloured.svg" (click)="prevItem()" a-style
                            w="2.2" h="2.2">
                        <p a-style fs="1.1">&nbsp;&nbsp;{{this.value +
                            1}}/{{this.my_score?.data?.matches.length}}&nbsp;&nbsp;</p>
                        <img src="../assets/images/Icon_Bank/Right_Arrow_Coloured (1).svg" (click)="nextItem()" a-style
                            w="2.5" h="2.5">
                    </div>
                </div>
                <div a-style display="flex" align="center" gap="4.6" mt="1.6">
                    <p a-style fs="1.3" class="lineClamp1" align="left">
                        {{my_score?.data?.matches?.[this.value]?.series?.name}}</p>
                    <!-- <img src="../assets/images/Icon_Bank/Ellipse_Vertical_Coloured.svg" a-style w="2.6" h="2.6"> -->
                </div>

                <div *ngFor="let teams of this.my_score?.data?.matches[this.value]?.teams" a-style display="flex"
                    justify="space-between" mt="1">
                    <!-- {{teams | json}} -->
                    <!-- <div a-style display="flex" gap="8" align="center"> -->
                    <!-- <div  a-style display="flex" gap="1" align=center> -->
                    <p a-style fs="1.1" class="lineClamp1" align="left" ellipsis="true" w="10">{{teams.team?.slug |
                        uppercase}}</p>
                    <!-- </div> -->
                    <p a-style fs="1.1" align="right">{{teams?.score}} </p>
                    <!-- </div> -->
                    <!-- <img  src="../assets/images/Icon_Bank/Flag_Coloured.svg" a-style w="2.8" h="1.8" mb="0.3"> -->
                </div>

            </div>

        </div>

        <!-- Map -->
        <!-- <div a-style  h="16" w="30.2" shadow="0 3px 6px 0 #17171729" br="0.5px solid #E4E4E4" brRadius=".6" >   </div> -->

        <!-- <div a-style  h="16.5" w="30.2" shadow="0 3px 6px 0 #17171729" br="0.5px solid #E4E4E4" brRadius=".6"
           >
        </div> -->
    </div>
    
</div>


<ng-template #apps let-passedData="passedData">
    <div (click)="select_application(passedData)" cursor=pointer a-style w=7.5 flexGrow=1 h=11.0 align=center mx=1
        py=0.9>
        <div a-style w="7.5" h="7.5">
            <img [src]="passedData.src" a-style h="100%" w="5.5">
        </div>
        <m-text [type]="gs.enums.texts.paragraph11" [text]="passedData.application"></m-text>
    </div>
</ng-template>


<!-- <div class="no_scroll" *ngIf="true" a-style h="15" w="30.2" br="0.5px solid #E4E4E4" brRadius=".6" a-style display="flex" gap="2"
align="center" px="1.4" overflow="scroll">
<div *ngFor="let data of my_score?.data?.matches">
    <div a-style w="27.0" display="flex" align="center" justify="flex-end" gap="4.6">
        <p a-style fs="1.3" class="lineClamp1">{{data?.series?.name}}</p>
        <img src="../assets/images/Icon_Bank/Horizontal_Menu_Grey.svg" a-style w="2.6" h="2.6">
    </div>
    <div a-style br="0.5px solid #E4E4E4" brRadius="0.6" display="flex" w="27.0" h="6.4" p="0.7"
        align="center" gap="2.3" mt="1.2">
        <div>
            <div a-style *ngFor="let teams of data?.teams" display="flex">
                <div a-style display="flex" gap="3">
                    <div *ngFor="let data of my_score?.data?.matches?.teams" a-style display="flex" gap="1">
                        <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" a-style w="2.8" h="1.8">
                        <p a-style fs="1.1" class="lineClamp1" align="left">{{teams.team?.slug}}</p>
                    </div>
                    <p  a-style fs="1.1">{{teams?.score}} </p>
                </div>
                <div a-style display="flex" gap="3" mt="0.5">
                    <div a-style display="flex" gap="1">
                        <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" a-style w="2.8" h="1.8">
                        <p a-style fs="1.1" class="lineClamp1" align="left">{{teams.team?.slug}}</p>
                    </div>
                    <p a-style fs="1.1">{{teams?.score}} </p>
                </div>
            </div>
        </div>
        <p a-style fs="1.1">{{data?.state}}</p>
    </div>

    <div a-style w="27.0" display="flex" justify="space-between" align="center" mt="0.7">
        <div a-style display="flex" align="center" gap="0.3">
            <img src="../assets/images/Icon_Bank/Slider_Left_Arrow_Coloured.svg" a-style w="2.2" h="2.2">
            <p a-style fs="1.1">2/5</p>
            <img src="../assets/images/Icon_Bank/Slider_Right_Arrow_Coloured.svg" a-style w="2.2" h="2.2">
        </div>
        <p a-style fs="1.1">See more Cricket International</p>
        <img src="../assets/images/Icon_Bank/Slider_Right_Arrow_Coloured.svg" (click)="nextItem()" a-style
            w="2.5" h="2.5">
    </div>
</div>
</div> -->