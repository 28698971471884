import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[a-style]'
})
export class AstyleDirective implements AfterViewInit {

  @Input() p: any = 0;
  @Input() py: any = 0;
  @Input() px: any = 0;
  @Input() pb: any = 0;
  @Input() pt: any = 0;
  @Input() pr: any = 0;
  @Input() pl: any = 0;
  @Input() m: any = 0;
  @Input() mt: any = 0;
  @Input() mx: any = 0;
  @Input() my: any = 0;
  @Input() mb: any = 0;
  @Input() mr: any = 0;
  @Input() ml: any = 0;
  @Input() textCenter: string = '';
  @Input() display: string = '';
  @Input() gridTemplateRows: string = '';
  @Input() gridTemplateColumns: string = '';
  @Input() gridColumn: string = '';
  @Input() gridRow: string = '';
  @Input() rowGap: any = 0;
  @Input() columnGap: any = 0;
  @Input() gap: any = 0;
  @Input() placeContent: string = '';
  @Input() justify: string = '';
  @Input() align: string = '';
  @Input() alignContent: string = '';
  @Input() alignItems: string = '';
  @Input() bg: string = '';
  @Input() bgUrl: string = '';
  @Input() bgRepeat: string = '';
  @Input() bgSize: string = '';
  @Input() bgPosition: string = '';
  @Input() color: string = '';
  @Input() resize: string = '';
  @Input() overflow: string = '';
  @Input() overflowY: string = '';
  @Input() h: any = 0;
  @Input() hFull: any = 0;
  @Input() w: any = 0;
  @Input() minWidth: any = 0;
  @Input() maxHeight: any = 0;
  @Input() minHeight: any = 0;
  @Input() maxWidth: any;
  @Input() fs: any = 0;
  @Input() fw: any = 0;
  @Input() br: any = 0;
  @Input() brTop: any = 0;
  @Input() brBottom: any = 0;
  @Input() brLeft: any = 0;
  @Input() brRight: any = 0;
  @Input() borderCollapse: string = '';
  @Input() brRadius: any = 0;
  @Input() brTopLeftRadius: any = 0;
  @Input() brTopRightRadius: any = 0;
  @Input() brBottomLeftRadius: any = 0;
  @Input() brBottomRightRadius: any = 0;
  @Input() listStyleType: string = '';
  @Input() textDecoration: string = '';
  @Input() alignSelf: string = '';
  @Input() justifySelf: string = '';
  @Input() outline: string = '';
  @Input() position: string = '';
  @Input() top: any = 0;
  @Input() bottom: any = 0;
  @Input() left: any = 0;
  @Input() right: any = 0;
  @Input() listStyle: string = '';
  @Input() subsidebar: string = '';
  @Input() overflowX: string = '';
  @Input() cursor: string = '';
  @Input() shadow: string = '';
  @Input() letterSpacing: any = 0;
  @Input() row: string = '';
  @Input() flexGap: any = 0;
  @Input() flexWrap: string = '';
  @Input() flexBasis: string = '';
  @Input() flexGrow: string = '';
  @Input() textOverflow: string = ''
  @Input() flexDirection: string = ''
  @Input() zIndex: any = 0
  @Input() transform: any;
  @Input() ellipsis: any;
  @Input() wordWrap: any = '';
  @Input() wordBreak: any = '';
  @Input() wordSpace: any = '';
  @Input() float: string = '';
  @Input() textWrap: string = '';
  @Input() opacity: any = '';
  @Input() visibility: any = '';
  @Input() verticalAlign: any = '';
  @Input() lineHeight: any = '';
  @Input() lineClamp: any = '';
  @Input() prefix: any = '';
  @Input() title: any = '';
  @Input() brNone: any = '';




  before_hover_values: any = {}



  set_value(value: string, style_name?: string, rem?: boolean) {
    value = value + '';
    let current_width = window.innerWidth
    style_name = style_name || 'NONE'
    // console.log(current_width)
    let main_seperator = ';';

    let seperator = ':';
    let max_width = 5000;
    let last_width = 5000;
    let last_value = '';
    value = value?.trim?.();
    if (typeof value === 'string' && (value.includes(';') || value.includes(':') || value.includes('hov'))) {
      let value_array = value.split(main_seperator);
      // console.log(value_array);
      let print = false;
      if (JSON.stringify(value_array).includes('hov')) {
        print = true;
      }
      let main_values = value_array.map((value: any) => {
        value = value.trim();
        let split_value = value.split(seperator);
        if (split_value.length == 1) {
          return [max_width, value]
        }
        if (!isNaN(split_value[0]))
          split_value[0] = parseInt(split_value[0])
        return split_value
      });
      let hover_exists = false;
      main_values.forEach(v => {
        if (v[0] != 'hov') {
          if (v[0] <= last_width && v[0] >= current_width) {
            // console.log(v[0],v[1],'...................');
            last_width = parseInt(v[0] + '');
            last_value = v[1] + ''
            // console.log(last_value,last_width);

          }
        } else {
          hover_exists = true;
          this.el.nativeElement.addEventListener('mouseover', (event: any) => {
            if (style_name) {
              this.el.nativeElement.style[style_name] = v[v.length - 1] + (rem ? 'rem' : '');
            }
          })
          this.el.nativeElement.addEventListener('mouseout', (event: any) => {
            if (style_name) {
              let value = this.before_hover_values[style_name];
              this.el.nativeElement.style[style_name] = value + (rem ? 'rem' : '');
            }
          })
        }
      })
      if (hover_exists) {
        this.before_hover_values[style_name] = last_value || 'initial'
      }

      return last_value + (rem && !isNaN(<any>last_value) ? 'rem' : '')
    }
    return value + (rem && !isNaN(<any>value) ? 'rem' : '')
  }

  public setStyles() {

    try {


      if (this.flexGrow) this.el.nativeElement.style.flexGrow = this.set_value(this.flexGrow);
      if (this.display) this.el.nativeElement.style.display = this.set_value(this.display, 'display', true);
      if (this.transform) this.el.nativeElement.style.transform = this.set_value(this.transform);
      if (this.bgPosition) this.el.nativeElement.style.backgroundPosition = this.set_value(this.bgPosition);
      if (this.zIndex) this.el.nativeElement.style.zIndex = this.set_value(this.zIndex);
      if (this.p) this.el.nativeElement.style.padding = this.set_value(this.p, 'p', true);
      if (this.py || this.pb) this.el.nativeElement.style.paddingBottom = this.set_value(this.py || this.pb, 'paddingBottom', true);
      if (this.py || this.pt) this.el.nativeElement.style.paddingTop = this.set_value(this.py || this.pt, 'paddingTop', true);
      if (this.px || this.pr) this.el.nativeElement.style.paddingRight = this.set_value((this.pr || this.px), 'paddingRight', true);
      if (this.px || this.pl) this.el.nativeElement.style.paddingLeft = this.set_value((this.pl || this.px), 'paddingLeft', true);
      if (this.m) this.el.nativeElement.style.margin = this.set_value(this.m, 'm', true);
      if (this.mb || this.my) this.el.nativeElement.style.marginBottom = this.set_value(((this.my || this.mb) + 'rem'), 'marginBottom', true);
      if (this.mt || this.my) this.el.nativeElement.style.marginTop = this.set_value(((this.my || this.mt) + 'rem'), 'marginTop', true);
      if (this.mr || this.mx) this.el.nativeElement.style.marginRight = this.set_value(((this.mx || this.mr) + 'rem'), 'marginRight', true);
      if (this.ml || this.mx) this.el.nativeElement.style.marginLeft = this.set_value((this.mx || this.ml), 'marginLeft', true);
      if (this.textCenter) this.el.nativeElement.style.textAlign = this.set_value(this.textCenter);
      if (this.gridTemplateColumns) this.el.nativeElement.style.gridTemplateColumns = this.set_value(this.gridTemplateColumns);
      if (this.gridTemplateRows) this.el.nativeElement.style.gridTemplateRows = this.set_value(this.gridTemplateRows);
      if (this.gridColumn) this.el.nativeElement.style.gridColumn = this.set_value(this.gridColumn);
      if (this.gridRow) this.el.nativeElement.style.gridRow = this.set_value(this.gridRow);
      // if (this.gap) this.el.nativeElement.style.gap = this.set_value(this.gap, 'gap', true);
      if (this.gap) this.el.nativeElement.style.gap = this.set_value(isNaN(this.gap) ? this.gap : this.gap + 'rem', 'gap', true);

      if (this.columnGap) this.el.nativeElement.style.columnGap = this.set_value(isNaN(this.columnGap) ? this.columnGap : this.columnGap + 'rem');
      if (this.rowGap) this.el.nativeElement.style.rowGap = this.set_value(this.rowGap + 'rem');
      if (this.justify) this.el.nativeElement.style.justifyContent = this.set_value(this.justify);
      // this.el.nativeElement.style.justifyItems = this.set_value( this.justify);
      // this.el.nativeElement.style.justifySelf = this.set_value( this.justify);
      if (this.align) this.el.nativeElement.style.alignItems = this.set_value(this.align);
      if (this.alignItems) this.el.nativeElement.style.alignItems = this.set_value(this.alignItems);
      if (this.resize) this.el.nativeElement.style.resize = this.set_value(this.resize);
      if (this.alignContent) this.el.nativeElement.style.alignContent = this.set_value(this.alignContent);
      if (this.placeContent) this.el.nativeElement.style.placeContent = this.set_value(this.placeContent);
      //  if(this.minWidth) this.el.nativeElement.style.width = this.set_value( this.minWidth + 'rem');
      if (this.cursor) this.el.nativeElement.style.cursor = this.cursor;
      // if (this.h) this.el.nativeElement.style.height = this.set_value(isNaN(this.h) ? this.h : this.h + 'rem');
      if (this.h) this.el.nativeElement.style.height = this.set_value(this.h, 'h', true);
      if (this.minWidth) this.el.nativeElement.style.minWidth = this.set_value(isNaN(this.minWidth) ? this.minWidth : this.minWidth + 'rem', 'minWidth', true);
      if (this.maxWidth) this.el.nativeElement.style.maxWidth = this.set_value(isNaN(this.maxWidth) ? this.maxWidth : this.maxWidth + 'rem', 'minWidth', true);
      if (this.flexBasis) this.el.nativeElement.style.flexBasis = this.set_value(isNaN(parseFloat(this.flexBasis)) ? this.flexBasis : this.flexBasis + 'rem');
      if (this.maxHeight) this.el.nativeElement.style.maxHeight = this.set_value((isNaN(this.maxHeight) ? this.maxHeight : this.maxHeight + 'rem'), 'maxHeight', true);
      if (this.minHeight) this.el.nativeElement.style.minHeight = this.set_value((isNaN(this.minHeight) ? this.minHeight : this.minHeight + 'rem'), 'minHeight', true);
      if (this.w) this.el.nativeElement.style.width = this.set_value(this.w, 'w', true);
      if (this.maxHeight) this.el.nativeElement.style.maxHeight = this.set_value((isNaN(this.maxHeight) ? this.maxHeight : this.maxHeight + 'rem'), 'maxHeight', true);
      if (this.br) this.el.nativeElement.style.border = this.set_value(this.br, 'br', false);
      if (this.brTop) this.el.nativeElement.style.borderTop = this.set_value(this.brTop);
      if (this.brBottom) this.el.nativeElement.style.borderBottom = this.set_value(this.brBottom);
      if (this.brLeft) this.el.nativeElement.style.borderLeft = this.set_value(this.brLeft);
      if (this.brRight) this.el.nativeElement.style.borderRight = this.set_value(this.brRight);
      if (this.brRadius) this.el.nativeElement.style.borderRadius = this.set_value(isNaN(this.brRadius) ? this.brRadius : this.brRadius + 'rem');
      if (this.brTopLeftRadius) this.el.nativeElement.style.borderTopLeftRadius = this.set_value(isNaN(this.brTopLeftRadius) ? this.brTopLeftRadius : this.brTopLeftRadius + 'rem');
      if (this.brTopRightRadius) this.el.nativeElement.style.borderTopRightRadius = this.set_value(isNaN(this.brTopRightRadius) ? this.brTopRightRadius : this.brTopRightRadius + 'rem');
      if (this.brBottomLeftRadius) this.el.nativeElement.style.borderBottomLeftRadius = this.set_value(isNaN(this.brBottomLeftRadius) ? this.brBottomLeftRadius : this.brBottomLeftRadius + 'rem');
      if (this.brBottomRightRadius) this.el.nativeElement.style.borderBottomRightRadius = this.set_value(isNaN(this.brBottomRightRadius) ? this.brBottomRightRadius : this.brBottomRightRadius + 'rem');
      if (this.color) this.el.nativeElement.style.color = this.set_value(this.color, 'color', false);
      if (this.fs) this.el.nativeElement.style.fontSize = this.set_value(this.fs, 'fs', true);
      if (this.fw) this.el.nativeElement.style.fontWeight = this.set_value(this.fw);
      if (this.textDecoration) this.el.nativeElement.style.textDecoration = this.set_value(this.textDecoration);
      if (this.overflow) this.el.nativeElement.style.overflow = this.set_value(this.overflow);
      if (this.overflowY) this.el.nativeElement.style.overflowY = this.set_value(this.overflowY);
      if (this.borderCollapse) this.el.nativeElement.style.borderCollapse = this.set_value(this.borderCollapse);
      if (this.justifySelf) this.el.nativeElement.style.justifySelf = this.set_value(this.justifySelf);
      if (this.flexGap) this.el.nativeElement.style.gap = this.set_value(this.flexGap, 'gap', true);
      if (this.flexDirection) this.el.nativeElement.style.flexDirection = this.set_value(this.flexDirection);
      if (this.flexWrap) this.el.nativeElement.style.flexWrap = this.set_value(this.flexWrap, 'flexWrap', false);
      if (this.alignSelf) this.el.nativeElement.style.alignSelf = this.set_value(this.alignSelf);
      if (this.position) this.el.nativeElement.style.position = this.set_value(this.position, 'position', true);
      if (this.bg) this.el.nativeElement.style.backgroundColor = this.set_value(this.bg, 'bg', false);
      if (this.bgUrl) this.el.nativeElement.style.backgroundImage = 'url( ' + this.bgUrl + ' )';
      if (this.bgRepeat) this.el.nativeElement.style.backgroundRepeat = this.set_value(this.bgRepeat);
      if (this.bgSize) this.el.nativeElement.style.backgroundSize = this.set_value(this.bgSize);
      if (this.outline) this.el.nativeElement.style.outline = this.set_value(this.outline);
      if (this.top) this.el.nativeElement.style.top = this.set_value(isNaN(this.top) ? this.top : this.top + 'rem');
      if (this.bottom) this.el.nativeElement.style.bottom = this.set_value(this.bottom, 'bottom', true);
      if (this.right) this.el.nativeElement.style.right = this.set_value(this.right + 'rem', 'right', true);
      if (this.left) this.el.nativeElement.style.left = this.set_value(isNaN(this.left) ? this.left : this.left + 'rem');
      if (this.listStyle) this.el.nativeElement.style.listStyle = this.set_value(this.listStyle);
      if (this.overflowX) this.el.nativeElement.style.overflow = this.set_value(this.overflowX);
      if (this.shadow) this.el.nativeElement.style.boxShadow = this.set_value(this.shadow, 'shadow', false);
      if (this.letterSpacing) this.el.nativeElement.style.letterSpacing = this.set_value(this.letterSpacing);
      if (this.wordWrap) this.el.nativeElement.style.wordWrap = this.set_value(this.wordWrap);
      if (this.wordBreak) this.el.nativeElement.style.wordBreak = this.set_value(this.wordBreak);
      if (this.wordSpace) this.el.nativeElement.style.wordSpace = this.set_value(this.wordSpace);
      if (this.textOverflow) this.el.nativeElement.style.textOverflow = this.set_value(this.textOverflow);
      if (this.ellipsis) this.el.nativeElement.style.whiteSpace = this.set_value('nowrap');
      if (this.ellipsis) this.el.nativeElement.style.overflow = this.set_value('hidden');
      if (this.ellipsis) this.el.nativeElement.style.textOverflow = this.set_value('ellipsis');
      if (this.float) this.el.nativeElement.style.float = this.set_value(this.float);
      if (this.textWrap) this.el.nativeElement.style.whiteSpace = this.set_value(this.textWrap);
      if (this.opacity) this.el.nativeElement.style.opacity = this.set_value(this.opacity);
      if (this.visibility) this.el.nativeElement.style.visibility = this.set_value(this.visibility);
      if (this.verticalAlign) this.el.nativeElement.style.verticalAlign = this.set_value(this.verticalAlign);
      if (this.lineHeight) this.el.nativeElement.style.lineHeight = this.set_value(this.lineHeight);
      if (this.lineClamp) this.el.nativeElement.style.lineClamp = this.set_value(this.lineClamp);
    } catch (error) {
      console.log(error)
    }




  }

  constructor(public el: ElementRef) { }

  ngAfterViewInit(): void {
    this.setStyles()

  }
}