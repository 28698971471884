import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ModalComponent } from 'src/mesbro/modules/shared/components/modal/modal.component';

@Component({
  selector: 'm-upload',
  templateUrl: './upload.component.html',
  styles: [
  ]
})
export class UploadComponent implements OnInit {

  @Input() modal: any;

  // @Output() onConfirm = new EventEmitter()

  ngOnInit(): void {
  }

  confirm() {
    this.modal?.closeModal()
    // this.onConfirm.emit('https://sfdjkhsdfjksad.con/asdjkhasajskdgh');
  }
}
