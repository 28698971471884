import {
  FormArray,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input, Pipe, PipeTransform
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IStructure } from 'src/mesbro/modules/shared/interfaces/IType';
import { snakeCase } from 'snake-case';
import merge from 'deepmerge';
import { deleted_categories } from '../../components/side-bar/side-bar-data';
import { ATTRIBUTE_GROUP_HEADINGS, ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT, ATTRIBUTE_IMAGES_FOR_EDIT_FOR_PRODUCT } from '../../../../configuration/data/constAttribute';
import { validate } from 'uuid';
import { isThisISOWeek } from 'date-fns';
const _ = require('lodash');

@Component({
  selector: 'm-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.css'],
})
export class GenericFormComponent implements OnInit {
  basicDetails: boolean = false;
  edit: any;
  orgdetails: any;
  groupHeadings: string[];
  allAttributes: any[];
  editAttributes: any;
  subHeadingCurrent: any;
  attGrpHead: any;
  attGrpHeadIndex: any;
  validation: any
  productdetail:any
  @Input() activities: any = undefined;
  // media_list = [{ aspect_ratio: 1, name: 'profile_picture' }, { aspect_ratio: 0.4, name: 'cover_picture' }]
  groupControl = new FormControl(undefined, [
    <any>this.gs.form_validations.required('Organizaition'),
  ]);
  faq: boolean = false
  queryParams = {
    _id: <string>'',
    id: '',
    application: <string>'',
    step: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'Form'>'Primary',
    details: '',
    type: '',
    subheading: '',
    primaryId: '',
    secondaryId: '',
    tertiaryId: '',
    edit: ''
  };
  answer:any
  images: string[];
  detail: any;
  @Input() hide: string = '';
  public selected = {
    Primary: <IStructure | undefined>undefined,
    Secondary: <IStructure | undefined>undefined,
    Tertiary: <IStructure | undefined>undefined,
    Type: <IStructure | undefined>undefined,
  };
  open: boolean = true;
  list = {
    Primary: <any[]>[],
    Secondary: <any[]>[],
    Tertiary: <any[]>[],
    Type: <any[]>[],
    brand: <any[]>[],
    offer: <any[]>[],
  };

  attForm: FormGroup;
  form: FormGroup;
  steps = ['Form'];

  attributeData: any;
  structure: IStructure | undefined = undefined;

  fixed_attributes: any = {};

  get currentStepIndex() {
    return this.steps.findIndex((step) => step == this.queryParams.step);
  }

  get previousStep() {
    return <'Primary' | 'Secondary' | 'Tertiary' | 'Type'>(
      this.steps[this.currentStepIndex - 1]
    );
  }

  get nextStep() {
    return <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'Form'>(
      this.steps[this.currentStepIndex + 1]
    );
  }

  get attribute_group() {
    return this.fb.group({ value: [undefined], unit: [undefined] });
  }

  get attributes() {
    return <FormGroup>this.attForm?.get('attributes');
  }

  first_time = true;
  constructor(
    public gs: GlobalService,
    public fb: FormBuilder,
    public ar: ActivatedRoute
  ) {
    this.subHeadingCurrent = ATTRIBUTE_GROUP_HEADINGS[0];
    this.attGrpHead = ATTRIBUTE_GROUP_HEADINGS;
    this.attGrpHeadIndex = 0;


  }

  faq_list: any;
  async ngOnInit() {
    this.initializeForm();
   
    this.groupHeadings = ATTRIBUTE_GROUP_HEADINGS;
    this.images = ATTRIBUTE_IMAGES_FOR_EDIT_FOR_PRODUCT
    this.gs.$groups.next(<any>await this.gs.get_groups());
    this.ar.queryParams.subscribe(async (params: any) => {
      this.queryParams = { ...params };
      this.edit = this.queryParams.edit;
      if (this.queryParams.id) {
        this.orgdetails = await this.gs.hps.get(
          'idm',
          'group',
          this.queryParams.id
        );
      }
      if (this.queryParams._id && this.edit && this.queryParams.application != 'service') {
        this.productdetail = await this.gs.hps.get(
          'business',
          'products',
          this.queryParams._id
        );
        this.editAttributes = this.productdetail;
        this.getFinalAttributesEdit();
      }
      else {
        deleted_categories[params.application]?.forEach((category: any) => {
          this.steps = this.steps.filter((e) => e != category);
        });
        if (this.queryParams.type) {
          this.redirectToStep('Form');
          this.detail = await this.gs.hps.get(
            'business',
            'producttypes',
            this.queryParams.type
          );
          // console.log(this.detail);
          await this.get_fixed_attribute();
          this.getFinalAttributes();

          await this.patch_initial_form(this.detail.basic);
        } else if (this.first_time) {
          // this.redirectToStep('Primary');
          this.get_list();
        }
        this.first_time = false;
      }
      if (this.queryParams._id && this.edit && this.queryParams.application == 'service') {
        let productdetail = await this.gs.hps.get(
          'business',
          'servicetypes',
          this.queryParams._id
        );
        this.editAttributes = productdetail;
        console.log(JSON.stringify(productdetail));

        this.getFinalAttributesEdit();
      }
      else {
        deleted_categories[params.application]?.forEach((category: any) => {
          this.steps = this.steps.filter((e) => e != category);
        });
        if (this.queryParams.type) {
          this.redirectToStep('Form');
          this.detail = await this.gs.hps.get(
            'business',
            'producttypes',
            this.queryParams.type
          );
          // console.log(this.detail);
          await this.get_fixed_attribute();
          this.getFinalAttributes();

          await this.patch_initial_form(this.detail.basic);
        } else if (this.first_time) {
          // this.redirectToStep('Primary');
          this.get_list();
        }
        this.first_time = false;
      }
    });

  }
  // fixedattribute/fixedattributetypes/list

  async get_fixed_attribute() {
    let body = {
      'basic.name': {
        $eq: 'product',
      },
    };
    // let filters = { "$and": [{ "application": this.queryParams.application }, { "type": "Fixed" }, { "sub_type": "Fixed" }] }
    let response = await this.gs.hps.post(
      'fixedattribute',
      'fixedattributetypes',
      'list',
      body
    );

    if (response.count) {
      this.fixed_attributes = response.list[0];
      // console.log(this.fixed_attributes);
    }
  }
  getFinalAttributes() {
    this.allAttributes = _.concat(
      this.fixed_attributes.attributes,
      this.detail.attributes
    );
   
    this.allAttributes.map((x) => {
 
      this.attForm.addControl(x.id, new FormControl());
    });

    //var uniqueHeadings = other
  }

  getFinalAttributesEdit() {
    this.allAttributes = []
    for (const [key, value] of Object.entries(this.editAttributes)) {
      if (_.includes(ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT, key)) {
        console.log(`${key}`);

        let dd: any = value;

        if (dd.attributes && dd.attributes.length > 0) {
          dd.attributes.map((x: any) => {
            x.groupHeading = x.groupHeading.replaceAll("_", " ");
            this.allAttributes.push(x)
          });
        }
      }
    }
    this.allAttributes.map((x) => {
      this.attForm.addControl(x.id, new FormControl(x.value));
    });

    //var uniqueHeadings = other

  }
  setHeadingValue(value: any) {
    this.subHeadingCurrent = value;
    var ind = _.indexOf(ATTRIBUTE_GROUP_HEADINGS, this.subHeadingCurrent, 0)
    this.attGrpHeadIndex = ind + 1;

    // console.log('setHeadingValue', this.subHeadingCurrent);
  }
  newGh: any
  async submit() {
    if (this.queryParams.edit) {
      var result = Object.keys(this.attForm.value).map((key) => [
        key,
        this.attForm.value[key],
      ]);
      let dataToSent: any = {};
      let attributesListToSent: {}[] = [];
      ATTRIBUTE_GROUP_HEADINGS.forEach((gh) => {
        let attr = this.allAttributes.filter(d =>
          d.groupHeading == gh
        );
        this.newGh = gh.replace(/\s+/g, '_');
        attr.forEach((att) => {
          let attTosent = {};
          if (att.groupHeading == gh) {
            attTosent = {
              id: att.id,
              dataType:this.productdetail.dataType,
              name: att.name,
              groupHeading: this.newGh,
              value: this.attForm.value[att.id],
            };
            attributesListToSent.push(attTosent);
          }
        });
        let temp = attributesListToSent;
        attributesListToSent = [];
        dataToSent[this.newGh] = { attributes: temp };
      });
      let body = dataToSent
      body.categoryInfo = {
        typeId: this.productdetail.categoryInfo.typeId
      }
      body.parent = {
        parentId: this.productdetail.parent.parentId, //organization id
        status: this.productdetail.parent.status, //organization approval status defalut value 
      }
      body._id=this.queryParams._id
      let product = await this.gs.hps.post('business', 'products', 'update-product', body)
      this.gs.router.navigate(['/org/product'], { queryParams: { section: 'B2B Products', type: 'All', _id: this.productdetail.parent.parentId } });
    } else {
    var result = Object.keys(this.attForm.value).map((key) => [
      key,
      this.attForm.value[key],
    ]);
    let dataToSent: any = {};
    let attributesListToSent: {}[] = [];

    ATTRIBUTE_GROUP_HEADINGS.forEach((gh) => {

      let attr = this.allAttributes.filter(d =>
        d.groupHeading == gh
      );
      this.newGh = gh.replace(/\s+/g, '_');
      // console.log("attr.length", attr.length)
      attr.forEach((att) => {
        let attTosent = {};
        if (att.groupHeading == gh) {
          attTosent = {
            id: att.id,
            dataType:att.dataType,
            name: att.name,
            groupHeading: this.newGh,
            value: this.attForm.value[att.id],
          };
          attributesListToSent.push(attTosent);
        }
      });
      let temp = attributesListToSent;
      attributesListToSent = [];
      dataToSent[this.newGh] = { attributes: temp };
    });
    let body = dataToSent
    body.primaryId = this.queryParams.primaryId,
      body.secondaryId = this.queryParams.secondaryId,
      body.tertiaryId = this.queryParams.tertiaryId,
      body.categoryInfo = {
        typeId: this.queryParams.type
      }
    body.parent = {
      parentId: this.queryParams._id, //organization id
      status: "Pending" //organization approval status defalut value 
    }
    body.faq=[
            {
              'question':'',
              'answer':this.attForm.value.answer
            }
    ]
    console.log(body);
    
    // let product = await this.gs.hps.post('business', 'products', 'add-product', body)
    this.attForm.reset()
    this.gs.toast('Success', 'Product submissted successfully', 'success')
    this.gs.router.navigate(['/org/product'], { queryParams: { section: 'B2B Products', type: 'All', _id: this.queryParams._id } });
    // console.log(product);

  }
}
  async patch_initial_form(details: any) {
    this.selected.Primary = details?.primaryId;
    this.selected.Secondary = details?.secondaryId;
    this.selected.Tertiary = details?.tertiaryId;
    this.selected.Type = details?.tertiaryId;
    // this.set_structure();
    // this.set_initial_attributes(this.structure);
    // this.groupControl.patchValue(Object.keys(details?.meta?.roles?.owner?.group)[0]);

    this.attForm?.patchValue(details);
  }

  initializeForm() {
    this.attForm = this.fb.group({
      answer:''
    });
  }

  getAttributes() {
    return new FormGroup({
      attributeF: new FormControl(''),
    });
  }
  deleteNextSelectionAndLists() {
    for (let i = this.currentStepIndex; i < this.steps.length - 1; i++) {
      let step = <'Primary' | 'Secondary' | 'Tertiary' | 'Type'>this.steps[i];
      this.selected[step] = undefined;
    }
  }

  async get_list() {
    if (this.queryParams.step == 'Form') {
      return;
    }
    this.selected[this.queryParams.step] = undefined;
    let body: any = {
      'parents.primary': this.selected.Primary?._id || undefined,
      'parents.secondary': this.selected.Secondary?._id || undefined,
      'parents.tertiary': this.selected.Tertiary?._id || undefined,
      sub_type: this.queryParams.step,
      application: this.queryParams.application,
    };
    let action = 'list';
    let component = 'structure';
    let list: any[] = (
      await this.gs.hps.post('generic', component, action, body, 2)
    ).list;
    this.list[this.queryParams.step] = list;
    let brand = await this.gs.get_data_list('Generic', 'Brand', []);
    this.list.brand = brand.list;
    this.list.brand = this.list.brand.map((d) => d.data);
    let offer = await this.gs.get_data_list('Generic', 'Offer', []);
    this.list.offer = offer.list;
    this.list.offer = this.list.offer.map((d) => d.data);
  }
  async redirectToNextStep() {
    this.redirectToStep(this.nextStep);
    if (this.queryParams.step != 'Form') {
      this.get_list();
    } else {
      // this.initializeForm();
      // this.set_structure();
      // this.set_initial_attributes(this.structure);
    }
  }
  redirectToPreviousStep() {
    this.redirectToStep(this.previousStep);
  }
  redirectToStep(step: 'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'Form') {
    this.queryParams.step = step;
    this.queryParams.application = this.queryParams.application || 'Product';
    this.gs.router.navigate([], { queryParams: this.queryParams });
  }
  searchList(
    listType: 'Primary' | 'Secondary' | 'Tertiary' | 'Type',
    searchText: string
  ) {
    let filter: any = (item: any) =>
      item.name.toLowerCase().includes(searchText.toLowerCase());
    return (<any[]>this.list[listType]).filter(filter);
  }
  select(listType: 'Primary' | 'Secondary' | 'Tertiary' | 'Type', item: any) {
    this.deleteNextSelectionAndLists();
    delete item.meta;
    this.selected[listType] = item;
    this.redirectToNextStep();
  }


  // async set_initial_attributes(details: any) {
  //   let groups = details?.attributes;
  //   if (groups) {
  //     Object.keys(groups).forEach(group => {
  //       this.add_group(group);
  //       let attributes = groups[group];
  //       Object.keys(attributes).forEach(attribute => {
  //         this.add_attribute(group, attribute);
  //       });
  //     });
  //   }
  // }

  // add_group(heading: string) {
  //   this.attributes.addControl(heading, this.fb.group({}));
  // }

  // add_attribute(heading: string, attribute_name: string) {
  //   if (!heading || !attribute_name) {
  //     return;
  //   }
  //   heading = snakeCase(heading);
  //   attribute_name = snakeCase(attribute_name);
  //   let heading_group = (<FormGroup>this.attributes.get(heading));
  //   heading_group.addControl(attribute_name, this.attribute_group);
  // }

  async submitNOtInWork() {
    try {
      // if (this.form?.invalid || this.groupControl?.invalid) {
      this.attForm?.markAllAsTouched();
      this.groupControl.markAllAsTouched();
      // return;
      // }
      let body = { ...this.attForm?.value };
      console.log(body);

      if (this.attForm?.value._id) {
        let response = await this.gs.hps.put(
          'generic',
          'data',
          undefined,
          body
        );
      } else {
        body.meta = {
          group_id: this.groupControl.value,
        };
        let parents = {
          fixed: this.fixed_attributes,
          parent: this.selected.Type,
          group: this.orgdetails,
        };
        body.parents = parents;
        let response = await this.gs.hps.post(
          'generic',
          'data',
          'add',
          body,
          2
        );
      }
      this.gs.router.navigate(['/product/created'], {
        queryParams: { state: 'productCreated' },
      });
      this.gs.toast(
        this.queryParams.application,
        this.queryParams._id ? 'Upadated Successfully' : 'Added Successfully'
      );
    } catch (error) { }
  }

  redirectToMyProducts() { }

  prev() {
    for (let i = 1; i <= Object.keys(this.attributeData).length; i++) {
      console.log(Object.keys(this.attributeData)[i]);
      let title = Object.keys(this.attributeData)[i];

      if (title == this.queryParams.details) {
        let a = Object.keys(this.attributeData)[i - 1];
        this.gs.router.navigate([], {
          queryParams: {
            application: 'Product',
            step: 'Form',
            type: 'product',
            details: a,
          },
        });
      }
    }
  }

  next() {
    console.log(this.attributeData);
    console.log(Object.keys(this.attributeData));

    for (let i = 0; i <= Object.entries(this.attributeData).length - 1; i++) {
      console.log(Object.keys(this.attributeData)[i]);
      let title = Object.keys(this.attributeData)[i];
      if (title == this.queryParams.details) {
        let a = Object.keys(this.attributeData)[i + 1];
        this.gs.router.navigate([], {
          queryParams: {
            application: 'Product',
            step: 'Form',
            type: 'product',
            details: a,
          },
        });
      }
    }
  }

  handleNextBtn() {
    this.setValidation();

    var ind = _.indexOf(ATTRIBUTE_GROUP_HEADINGS, this.subHeadingCurrent, 0)
    this.setHeadingValue(ATTRIBUTE_GROUP_HEADINGS[ind + 1]);
    this.attGrpHeadIndex = ind + 1;
  }

  handlePrevBtn() {
    var ind = _.indexOf(ATTRIBUTE_GROUP_HEADINGS, this.subHeadingCurrent, 0)
    this.setHeadingValue(ATTRIBUTE_GROUP_HEADINGS[ind - 1]);
    this.attGrpHeadIndex = ind - 1;

  }
  setValidation() {
    this.allAttributes
    let valueControl: FormControl = <FormControl>this.attForm.get('value');
    console.log(valueControl);

  }
}
function x(x: any, arg1: (any: any) => void) {
  throw new Error('Function not implemented.');
}
