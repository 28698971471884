<button [type]="button_type" *ngIf="types==gs.enums.buttons.button1" a-style cursor=pointer color="#ffffff" display="flex" gap="1" py="1" px="2" minWidth=15 justify=center brRadius="0.5" align="center" bg="#1d8ecf" br="1px solid #006BFF"> <img [src]="src" a-style cursor=pointer> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button2" a-style cursor=pointer color="#006BFF" display="flex" gap="1" [px]="px?px:1.6" [py]="py?py:0.8" brRadius="0.5" align="center" br="1px solid #006BFF" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2"> <img [src]="src">{{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button3" a-style cursor=pointer bg="#CFEEFC" color="#333333" display="flex" gap="1" [py]="py?py:0.8" [px]="px?px:1.2" brRadius="0.5" align="center" br="1px solid #E4E4E4"> <img [src]="src">
    {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button4" a-style cursor=pointer color="#ffffff" display="flex" gap="1" py="0.8" h="3.5" px="1.2" brRadius="0.5" align="center" bg="#1d8ecf" br="1px solid #006BFF">
    <img [src]="src" a-style cursor=pointer w="1"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button5" a-style cursor=pointer bg="#1d8ecf" px="1" h="3.9" brRadius="0 4px 4px 0"> <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-#ffffff.svg" a-style cursor=pointer w="1.7" mt="1"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button6" a-style cursor=pointer color="#B2B2B2" py="0.8" px="1.6" brRadius="0.5" bg="#ffffff;hov:#b2b2b2c2" br="1px solid #E4E4E4" align="center"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button7" a-style color="#ffffff" py="0.8" px="1.6" brRadius="0.5" align="center" bg="#1d8ecf" shadow="hov:0px 3px 6px 0px #b2b2b2" br=none [style.background]="disable?  '#1d8ecf' : '##006BFF'" [style.cursor]="disable?  'not-allowed' : 'pointer'"> {{text}}
</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button8" a-style cursor=pointer color="#ffffff" py="0.8" px="2.4" br="none" outline=none brRadius="0.5" align="center" bg="#1d8ecf" [mt]=mt> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button9" a-style cursor=pointer color="#ffffff" py="0.8" px="2" brRadius="0.5" align="center" bg="#1d8ecf" br="none"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button10" a-style cursor=pointer color="#B2B2B2" py="0.8" px="1.6" brRadius="0.5" align="center" br="#e4e4e4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button11" a-style cursor=pointer color="#333333" py="0.8" px="1.6" brRadius="0.5" align="center" br="#e4e4e4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button12" a-style cursor=pointer color="#B2B2B2" py="0.8" px="2" brRadius="0.5" align="center" br="#e4e4e4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button13" a-style cursor=pointer color="#ffffff" py="0.8" px="2" brRadius="0.5" align="center" bg="#E93728"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button14" a-style cursor=pointer color="#006BFF" py="0.8" px="2" brRadius="0.5" align="center"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button15" a-style cursor=pointer color="#ffffff" py="0.8" px="1.2" brRadius="0.5" align="center" bg="#1d8ecf" br="none" outline=none [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr">
    {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button16" a-style cursor=pointer color="#B2B2B2" py="1" px="1.6" brRadius="0.5" align="center" br="#e4e4e4" [mt]="mt" [mb]="mb"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button17" a-style cursor=pointer color="#006BFF" py="1" px="2.4" brRadius="0.5" align="center" br="#006BFF"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button18" a-style cursor=pointer color="#ffffff" shadow="hov:0px 5px 5px 0px #f1f1f1" bg="#1d8ecf" py="0.8" px="2.4" brRadius="0.5" align="center" outline=none br="none" [mr]="mr" [mt]="mt" [mb]="mb" [style.cursor]="disable?'not-allowed':'pointer'" [style.border]="disable? 'none': '1px solid white'"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button19" a-style cursor=pointer color="#006BFF" py="1" px="2" brRadius="0.5" align="center" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2" br="1px solid #006BFF"> {{text}}
</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button20" a-style cursor=pointer color="#ffffff" py="1" px="4" brRadius="0.5" align="center" bg="#1d8ecf" outline=none br="none" [mt]="mt" [mb]="mb" [ml]="ml" [mr]="mr">
    {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button21" a-style cursor=pointer color="#333333" py="1" px="4" brRadius="0.5" align="center" br="#e4e4e4" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2" [mr]="mr"> {{text}}
</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button22" a-style cursor=pointer color="#ffffff" py="1" px="3" brRadius="0.5" align="center" bg="#1d8ecf"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button23" a-style cursor=pointer color="#ffffff" py="0.8" px="2.4" brRadius="0.5" align="center" bg="#e86125" bg="#e86125;hov:#e86125d9"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button24" a-style cursor=pointer color="#333333" py="0.8" px="1.6" brRadius="0.5" align="center" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button25" a-style cursor=pointer color="#ffffff" py="1" px="1.5" brRadius="0.5" align="center" br="#ffffff"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button26" a-style cursor=pointer color="#006BFF" py="0.8" px="2" brRadius="0.5" align="center" br="#1d8ecf"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button27" a-style cursor=pointer color="#ffffff" py="1" px="2" brRadius="0.5" align="center" br="#ffffff"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button28" a-style cursor=pointer color="#ffffff" py="0.8" px="1.6" br="none" brRadius="0.5" align="center" bg="#F23A0B" bg="#F23A0B;hov:#f23a0bad"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button29" a-style cursor=pointer color="#ffffff" py="0.8" px="1.6" brRadius="0.5" align="center" bg="#F23A0B" bg="#F23A0B;hov:#f23a0bad"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button30" a-style cursor=pointer br=none color="#ffffff" outline=none [py]="py?py:1" [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" [px]="px?px:6" textWrap="nowrap" brRadius="0.4" justify="center" [shadow]="shadow" display="flex" align="center" [maxWidth]="maxWidth" [style.background]="disable?  '#f78f71' : '#f35325'" [position]="position" [top]="top" [left]="left" (click)="clicked.emit()"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button31" a-style cursor=pointer shadow="hov:0px 5px 5px 0px #5c5b5b" [top]="top" [right]="right" [position]="position" color="#ffffff" px="0.5" fs="1.4" py="0.5 " br=none [p]="padding?padding:0.5" display="flex" gap="0.5" [mt]="mt" textWrap="nowrap" brRadius="0.4" align="center" bg="#333333" bg="#333333;hov:#797979"> <img [src]="src" a-style cursor=pointer [w]="width?width:1"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button32" a-style cursor=pointer color="#ffffff" [ml]="ml" [px]="px?px:2" [py]="py?py:1" outline="none" [mt]="mt" textWrap="nowrap" brRadius="0.4" [br]="br" display="flex" align="center" bg="#1d8ecf" bg="#1d8ecf;hov:#1d8ecfcc"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button33" a-style cursor=pointer color="#333333" px="2" h="3" justify="center" w="12.6" textWrap="nowrap" display="flex" bg="#FFFFFF" bg="#FFFFFF;hov:#b2b2b2c2" brRadius="0.5" align="center" br="1px solid #e4e4e4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button333" a-style cursor=pointer color="#ffffff" px="2" h="3" justify="center" w="12.6" textWrap="nowrap" display="flex" bg="#1d8ecf" brRadius="0.5" align="center" br="1px solid #e4e4e4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button34" a-style cursor=pointer color="#ffffff" bg="#1d8ecf" px="2.4" brRadius="0.4" [h]="h" align="center" color="#ffffff" outline=none br="none">{{text}}
    <img src="https://mesbro.in/assets/images/down-arrow-#ffffff.svg" a-style cursor=pointer w="1.2" mt="0.6"></button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button35" a-style cursor=pointer color="#ffffff" bg="#1d8ecf" outline=none br="none" brRadius="0.5" px="2" display="flex" justify="flex-end" align="center" [h]="h" [w]="w" [maxWidth]="maxWidth">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button36" a-style cursor=pointer color="#ffffff" bg="#1d8ecf" fs="1.4" outline=none br="none" brRadius="0.5" display="flex" justify="space-between" align="center" [h]="h" [w]="w">{{text}}<img src="https://mesbro.in/assets/images/down-arrow-white.svg" a-style cursor=pointer w="1.2" mt="0.6"></button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button37" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color=black br="1px solid #e4e4e4" fs="2" flexGrow="1" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2" w=9.6 h=5.5 align=center brRadius="0.4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button38" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color=black br="1px solid #e4e4e4" fs="2" flexGrow="1" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2" w=14.7 h=5.5 align=center brRadius="0.4"> {{text}} </button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button39 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="black " [py]="py " [px]="px" flexGrow="1" fs="2 " brRadius="0.5 " w=14.7 h=5.5 align="center " br="1px solid #e4e4e4 " bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2">{{text}}<sup a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="2 ">n</sup></button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button40 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff " [py]="py " [px]="px " fs="2 " flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#FF7417" bg="#FF7417;hov:#ef660b">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button41 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#FF7417 " [py]="py " [px]="px " fs="2 " flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button42 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff " [py]="py " [px]="px " fs="2 " flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#FBB615" bg="#FBB615;hov:#f7af08">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button43 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#FBB615 " [py]="py " [px]="px " fs="2 " flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button44 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff " [py]="py " [px]="px " fs="2 " flexGrow="1" brRadius="0.5" w=14.7 h=5.5 align="center " br="1px solid #e4e4e4 " bg="#81BD41" bg="#81BD41;hov:#76b92f">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button45 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#81BD41 " [py]="py " [px]="px " fs="2 " flexGrow="1" brRadius="0.5" w=14.7 h=5.5 align="center " br="1px solid #e4e4e4 " bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button46 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#E93728" [py]="py " [px]="px " fs="2" flexGrow="1" brRadius="0.5" w=14.7 h=5.5 align="center " br="1px solid #e4e4e4 " bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button47" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff" bg="#006bff" [px]="px" [py]="py" brRadius="0.6" flexGrow="1" [fs]="fs" w=14.7 h=5.5 justify="space-between" isplay="flex" outline=none br="none">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button48 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff " [py]="py " [px]="px " fs="2" flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#E93728" bg="#E93728;hov:#e93728c2">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button49 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff " [py]="py " [px]="px " fs="2" flexGrow="1" w=14.7 h=5.5 brRadius="0.5" align="center " br="1px solid #e4e4e4 " bg="#785CB4" bg="#785CB4;hov:#785cb4ba">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button50 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#E93728 " [py]="py " [px]="px " fs="2.4" flexGrow="1" w=8 h=11.2 brRadius="0.5 " align="center " br="1px solid #e4e4e4 " bg="#EBEBEB" bg="#EBEBEB;hov:#d2d1d1">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button51 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#FBB615 " [py]="py " [px]="px " fs="2.4" flexGrow="1" w=8 h=11.2 brRadius="0.5 " align="center " br="1px solid #e4e4e4 " bg="#EBEBEB" bg="#EBEBEB;hov:#d2d1d1">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button52 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#3082FF " [py]="py " [px]="px " fs="2.4" flexGrow="1" w=8 h=11.2 brRadius="0.5 " align="center " br="1px solid #e4e4e4 " bg="#EBEBEB" bg="#EBEBEB;hov:#d2d1d1">{{text}}</button>
<button [type]="button_type " *ngIf="types==gs.enums.buttons.button53 " a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#81BD41 " [py]="py " [px]="px " fs="2.4" flexGrow="1" w=8 h=11.2 brRadius="0.5 " align="center " br="1px solid #e4e4e4 " bg="#EBEBEB" bg="#EBEBEB;hov:#d2d1d1">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button54" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#333333" bg="transparent" bg="transparent;hov:#727272" fs="1.4" outline=none br="none" brRadius="0.5" display="flex" px="1" br="1px solid #E4E4E4" justify="space-between" align="center" w="10" h="3.6">{{text}}<img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" w=" 1"
        h=" 1"></button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button55" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" textWrap="nowrap" px="10.2" py="1" bg="#1d8ecf" color="#FFFFFF" brRadius="0.4" br="1px solid #E4E4E4" mb="0.9">{{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button56" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" textWrap="nowrap" px="3" py="0.8" bg="#1d8ecf" color="#FFFFFF" brRadius="0.4" br="1px solid #E4E4E4">{{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button57" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff" py="1" bg="#1d8ecf" px="2.5" brRadius="0.4" [h]="h" [mt]="mt" align="center" color="#ffffff" outline=none br="none">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button58" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#333333" py="1" px="2.5" justify="center" textWrap="nowrap" display="flex" bg="#FFFFFF" bg="#FFFFFF;hov:#b2b2b2c2" brRadius="0.5" align="center" br="1px solid #e4e4e4">
    {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button59" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" br=none bg="#008000" bg="#008000;hov:#008000ab" color="#ffffff" outline=none [py]="py?py:1" [w]="w" [mt]="mt" [mx]="mx" [px]="px?px:6" textWrap="nowrap" brRadius="0.4" justify="center" display="flex" align="center"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button60" a-style cursor=pointer fs="1.3" align="center" px=2.4;1386:1.6 py=1;1386:0.8 mx="0" color="#ffffff" brRadius=0.6 marginTop=1.6 bg="transparent" br="1px solid white">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button61" a-style cursor=pointer shadow=" hov:0 3px 6px 0 #f1f1f1" color="#ffffff" px="2" justify="flex-end" [mt]="mt" br=none textWrap="nowrap" brRadius="0.6" display="flex" align="center" bg="#1d8ecf" bg="#1d8ecf;hov:#0 3px 6px 0 #f1f1f1"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button62" a-style cursor=pointer color="#333333" bg="transparent" justify="flex-end" py="1" px="2.4" mx="0" br=none brRadius="0.6" br="1px solid #e4e4e4" align="center" [mt]=mt [mb]=mb>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button63" a-style [style.cursor]="disable?'not-allowed':'pointer'" fs="1.3;1366:0.9" [br]="br?br:'none'" color="#ffffff" bg="transparent" brRadius=0.4 [py]="py?'py':1" [px]="px?'px':'2;1366:1.5'" display="flex" justify="center" [mt]=mt [mb]=mb>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button64" a-style cursor=pointer shadow="hov:0px 5px 5px 0px #b2b2b2" fs="1.3" br="none" px=2.4 py=0.8 color="#ffffff" bg="#1d8ecf" brRadius=0.4 mt="1.6">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button65" a-style cursor=pointer shadow="hov:0px 5px 5px 0px #b2b2b2" fs="1.3" br="none" px=2 py=0.8 color="#ffffff" bg="#1d8ecf" brRadius=0.4 mt="1.6">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button66" a-style cursor=pointer shadow="hov:0 3px 6px 0 #f1f1f1" fs="1.3" br="none" px=2.4 py=0.8 color="#ffffff" bg=#E86125 bg="#E86125;hov:#ff6622" brRadius=0.6 [ml]="ml">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button67" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #f1f1f1f" fs="1.3" br="none" px=2 py=0.8 [mb]=mb color="#006BFF" bg=#ffffff bg="#ffffff;hov:#b2b2b247" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button68" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.3" br="none" px=2 py=0.8 color="#ffffff" bg=#EA2601 bg="#ea2601;hov:#ea2601b0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button69" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.4" fw=500 px=1 py=0.1 bg="#ffffff" br="1px solid #2479AB " fw="500" color="#2479AB" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button70" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.3" px=1 py=0.5 bg="#1d8ecf" br="1px solid #e4e4e4 " [ml]="ml" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button71" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.3" px=2 py=1 bg="#1d8ecf" br="1px solid #e4e4e4 " [ml]="ml" [mt]="mt" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button72" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#ffffff" display="flex" gap="1" py="1" px="2" minWidth=13 justify=center brRadius="0.5" align="center" bg="#F91C42" bg="#F91C42;hov:#f91c42b3"> <img [src]="src" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button73" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.4" px=1 py=0.7 bg="#ea2601" bg="#ea2601;hov:#ea2601b0" br="1px solid #FF1414" [fw]="fw?fw:1" [ml]="ml" [mr]="mr" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button74" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.4" px=1 py=0.5 bg="#333" bg="#333;hov:#797979" br="1px solid #333" [fw]="fw?fw:1" [ml]="ml" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button75" a-style shadow="hov:0px 3px 6px 0px #b2b2b2" br=none color="#ffffff" outline=none [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" textWrap="nowrap" brRadius="0.6" justify="center" display="flex" align="center" [maxWidth]="maxWidth" [style.background]="disable?  '#f78f71' : '#f35325'" [style.cursor]="disable?  'not-allowed' : 'pointer'" [py]="py?py:1" [px]="px?px:2.4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button76" a-style fs="1.3" px=1 py=0.5 [style.cursor]="disable?'not-allowed':'pointer'" [style.background]="disable?  '#b2b4b4' : '#006BFF'" br="1px solid #e4e4e4 " [ml]="ml" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button77" a-style cursor=pointer fs="1.4" px=2 py=0 bg="#ea2601" bg="#ea2601;hov:#ea2601b0" br="1px solid #FF1414" [fw]="fw?fw:1" [ml]="ml" [h]="h" [mr]="mr" color="#ffffff" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button78" a-style cursor=pointer color="#ffffff" bg="#1d8ecf" bg="#1d8ecf;hov:#1d8ecfcc" fs="1.4" px="2.8" display="flex" brRadius="0.4" [h]="h" align="center" color="#ffffff" outline=none br="none">
    <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/plus-white.svg" a-style cursor=pointer w="1.9" mr="1"> {{text}}</button>
<!-- 0.5rem 1rem -->
<!-- <button routerLink="/product/rfq" [queryParams]="{type: 'rfqHome'}" a-style cursor=pointer  fs="1.3" br="none" px=2.4 py=0.8 color="#ffffff" bg=#E86125 brRadius=0.4 ml="3">RFQ</button> -->
<!-- <m-button a-style cursor=pointer  text="Join Now" fs="1.3" justify="flex-end" px=1.6 py=0.8 mx="0" bg="#ffffff" bg="#ffffff;hov:#b2b2b2c2" color="#333333" brRadius=0.4 marginTop=1.6></m-button> -->
<!-- 57//58//59   //54 //55 //56 -->
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button79" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" br="1px solid #e4e4e4" outline=none fs=1.3 align="center" [px]="px?px:2.4" py=1 [mx]=mx color=#333333 bg="#ffffff" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button80" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" br="none" outline=none fs=1.3 align="center" [px]="px?px:2.8" py=1 [mx]=mx color=#ffffff bg="#1d8ecf" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button81" a-style [br]="br?br:'none'" [h]="h" shadow="hov:0px 3px 6px 0px #b2b2b2" [color]="color?color:'#ffffff'" [bg]="bg?bg:'#006bff'" outline=none [py]="py?py:1" [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" [px]="px?px:6" textWrap="nowrap" brRadius="0.4" justify="center" display="flex" align="center" [maxWidth]="maxWidth" [bg]="bg?bg:'#006bff'" cursor=pointer py="1" px="2.4"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button82" a-style shadow="hov:0px 3px 6px 0px #b2b2b2" br=none color="#ffffff" outline=none [py]="py?py:1" [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" [px]="px?px:6" textWrap="nowrap" brRadius="0.4" justify="center" display="flex" align="center" [maxWidth]="maxWidth" [style.background]="disable?  '#97ceee' : '#1d8ecfcc'" [style.cursor]="disable?  'not-allowed' : 'pointer'" [br]="br"> {{text}} </button>

<!-- <button [type]="button_type" *ngIf="types==gs.enums.buttons.button83" a-style cursor=pointer  color="#333333" p="1.5" brRadius="0.6" br="1px solid #E4E4E4" bg="#ffffff"  align="center" {{text}}><img src="https://mesbro.in/assets/images/mail/sent-Faded.svg" a-style cursor=pointer > </button> -->
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button83" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" color="#000000" bg="transparent" fs="1.4" outline=none br="none" brRadius="0.5" display="flex" px="1" br="1px solid #E4E4E4" justify="space-between" align="center" w="10" h="3.6">{{text}}<img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" a-style cursor=pointer w=" 1" h=" 1"></button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button84" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" bg="#ffffff" color="#000000" fs="1.4" outline=none br="none" brRadius="0.5" display="flex" px="1" br="1px solid #E4E4E4" justify="space-between" align="center" gap="1.5" w="auto" h="3.6">{{text}}<img src="https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/29.png" a-style cursor=pointer w=" 1" h=" 1"></button>

<button [type]="button_type" *ngIf="types==gs.enums.buttons.button85" a-style br=none color="#ffffff" outline=none [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" textWrap="nowrap" brRadius="0.4" justify="center" display="flex" align="center" [maxWidth]="maxWidth" [style.background]="disable?  '#8EC7E7' : '#1D8ecf'" [style.cursor]="disable?  'not-allowed' : 'pointer'" [py]="py?py:1" [px]="px?px:2.4"> {{text}} </button>

<button [type]="button_type" *ngIf="types==gs.enums.buttons.button87" a-style cursor=pointer [h]="h" shadow="hov:0px 3px 6px 0px #b2b2b2" px="1.4" py="0.5" color="#333333" bg="#CFEEFC" fs="13px" fw="300" brRadius="0.6" br=" 0.5px solid #05A6F0">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button86" a-style br=" 0.5px solid #05A6F0" bg="#CFEEFC" color="#333333" outline=none [w]="w" [fs]="fs" [mt]="mt" [mx]="mx" textWrap="nowrap" brRadius="0.4" justify="center" display="flex" align="center" [maxWidth]="maxWidth" [style.background]="disable?  '#8EC7E7' : '#1D8ecf'" [style.cursor]="disable?  'not-allowed' : 'pointer'" [py]="py?py:1" [px]="px?px:2.4"> {{text}}
</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button88" a-style cursor=pointer br="1px solid #05A6F0" outline=none fs=1.3 align="center" [px]="px?px:2" w=8.8 h="2.9" [mx]=mx color="#333333" bg="#CFEEFC" display="flex" brRadius=0.6><img [src]="src" mr="1.3" w="1.3" h=1.3 a-style>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button89" a-style cursor=pointer br="1px solid #F35325" outline=none fs=1.3 align="center" [px]="px?px:1.8" [py]="py?py:0.5" h="2.9" [mx]=mx color="#333333" [bg]="bg?bg:'#FFE6DF'" display="flex" fs="1.3" [brRadius]="brRadius?brRadius:0.6">{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button90" a-style cursor=pointer [br]="br?br:'1px solid #05A6F0'" outline=none fs=1.3 align="center" [px]="px?px:1.8" [py]="py?py:0.5" h="2.9" [mx]=mx color="#333333" bg="#CFEEFC" display="flex" fs="1.3" brRadius=0.6>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button91" a-style cursor=pointer color="#333333" display="flex" gap="1" py="0.5" h="3" px="1.4" brRadius="0.6" align="center" bg="#FFF0EC" br="1px solid #F35325">
    <img [src]="src" a-style cursor=pointer w="1"> {{text}}
</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button93" a-style cursor=pointer [bg]="bg?bg:'#CFEEFC'" color="#333333" py="0.5" px="1.4" brRadius="0.5" align="center" [br]="br?br:'1px solid #E4E4E4'"> {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button94" a-style cursor=pointer shadow="hov:0px 3px 6px 0px #b2b2b2" fs="1.3" fw=400 px=1.4 py=0.5 bg="#ffffff" br="1px solid #2479AB " fw="400" color="#333333" outline="0" brRadius=0.4>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button95" a-style cursor=pointer  fs="1.1" fw=400 px=2.4 py=0.7 bg="#ffffff" br="1px solid #2479AB " fw="500" color="#333333" outline="0" brRadius=0.6>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button96" a-style cursor=pointer  fs="1.3" fw=400 px=1.2 py=0.5 bg="#ffffff" br="1px solid #05A6F0 " fw="500" color="#333333" outline="0" opacity="1" brRadius=0.6>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button97" a-style cursor=pointer  fs="1.3" fw=400 px=1.4 py=0.5 bg="#FFF0EC" br="1px solid #F35325 " fw="500" color="#333333" outline="0" opacity="1" brRadius=0.6>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button98" a-style cursor=pointer  fs="1.3" fw=400 px=2 py=0.5 bg="#fff" br="1px solid #2479AB " fw="500" color="#333333" [opacity]="1" outline="0" opacity="1" brRadius=0.6>{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button99" a-style cursor=pointer  fs="1.3" fw=400  bg="#fff" br="1px solid #2479AB " fw="500" color="#333333" [opacity]="1" outline="0" opacity="1" brRadius=50%>{{text}}</button>





<button [type]="button_type" *ngIf="types==gs.enums.buttons.button100" a-style cursor=pointer color="#333333" display="flex" gap="1" py=".8" px=".5" minWidth=103px justify=center brRadius="0.5" align="center" bg="white" br="1px solid #2479AB" minWidth="12"> <img [src]="src" a-style cursor=pointer > {{text}} </button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button101" a-style cursor=pointer bg="white" [fs]="fs" color="#333333" display="flex" gap="1" p="0.8" brRadius="0.6" align="center" br="1px solid #2479AB"> <img [src]="src" >{{text}}</button>
<button [type]="button_type" *ngIf="types==gs.enums.buttons.button102" a-style cursor=pointer bg="#FFFFFF " [fs]="fs" color="#333333"  py="0.5" px="2" brRadius="0.6"  br="1px solid #2479AB">{{text}}</button>






<button [type]="button_type" *ngIf="types==gs.enums.buttons.button0" a-style cursor="pointer" fs="1.3" [py]="py? py : 0.5"  [px]="px ? px: 2" [mr]="mr" [ml]="ml" fw="400" [opacity]="1" outline="0" brRadius=0.6 [bg]="bg?bg:'#fff'" [color]="color? color: '#333333'" [br]="br? br : '0.5px solid #26C6DA'"  [position]="position" [top]="top" [bottom]="bottom" [right]="right" [left]="left" color="#333333">{{text}}</button>