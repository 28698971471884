<div minHeight='calc(100vh - 166px)' display="flex" justify="center" alignItems="center" a-style>
    <div w="70" h="36" a-style>
    <div a-style w="33.6" h="33.6" display="flex" position="relative" justify="flex-end" flexDirection="column" alignItems="center" brRadius="50%" bg="#f2f5f8" zIndex="-1">
        <div a-style display="flex" gap="2" position="absolute" left="10" top="11">
            <m-image a-style [src]="gs.enums.images.image308"> </m-image>
            <div a-style w="56" h="25" align="left">
                <!-- <m-text [type]="gs.enums.texts.heading1" text="SUCCESS"></m-text> -->
                <m-text [type]="gs.enums.texts.paragraph60" text="SUCCESSFULL"></m-text>
                <m-text [type]="gs.enums.texts.paragraph12" mb="1" text="your transation is  successfull"></m-text>
                <m-text [type]="gs.enums.texts.paragraph58" text=" Transation Successfull "></m-text>
                <m-button button_type=submit [types]="gs.enums.buttons.button56" w="10" mt="2.8" fs="1.4" a-style cursor="pointer" text="Sumit"></m-button>
            </div>
        </div>
    </div>
</div>
</div>