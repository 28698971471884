import { Component, Input, OnInit } from '@angular/core';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-location',
  templateUrl: './generic-location.component.html',
  styles: [
  ]
})
export class GenericLocationComponent implements OnInit {

  @Input() details: any;

  users: IUserData;
  //  _getDistanceFromLatLonInKm: any;

  _lat1: any;
  _lon1 : any;
  _lat2: any;
  _lon2 : any;
  _d: any
  constructor(public gs: GlobalService) {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
    })
  }

  ngOnInit(): void {
    console.log(this.details, '111111111111111111111111111111111111');
    // console.log(this._d, 'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq');
    this._lat1 = this.users?.activeUser?.location_details?.latitude;
    this._lon1 = this.users?.activeUser?.location_details?.longitude;
    this._lat2 = this.details?.attributes?.material_available_location?.search_material_available_location?.value?.latitude;
    this._lon2 = this.details?.attributes?.material_available_location?.search_material_available_location?.value?.longitude;


    console.log(this._lat2);
    this._d =  +
      Math.round(this._getDistanceFromLatLonInKm(this._lat1, this._lon1, this._lat2, this._lon2) * 100) / 100 +
      " km";
    console.log(this._d);
    
  }



  _getDistanceFromLatLonInKm(lat1: any, lon1: any, lat2: any, lon2: any) {
    var R = 6371; // Radius of the earth in kilometers
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in KM
    return d;
  }

  deg2rad(deg: any) {
    return deg * (Math.PI / 180)
  }


  // precise value

  // var _d = "Precise value: " + _getDistanceFromLatLonInKm(_lat1, _lon1, _lat2, _lon2);console.log(_d); // alert(_d);
  //  _d = "Precise value: " + this._getDistanceFromLatLonInKm(this._lat1, this._lon1, this._lat2, this._lon2);




  // console.log(_d); 
}
