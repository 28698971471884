<div a-style brRadius="0.6" bg="#fff" opacity="1" class="edit-comment" p="1">
<div a-style display="flex" gap="2" class="edit" align="center" h="3">
    <img a-style src="../assets/images/assets/edit-pencil.svg" w="1.5" h="1.5" align="center" a-style>
    <p a-style fs="1.3" color="#333333" m="0">Edit</p>
</div>
<div a-style display="flex" gap="2" class="edit" align="center" h="3">
    <img a-style src="../assets/images/assets/delete-comment.svg" w="1.5" h="1.5" align="center" a-style>
    <p a-style fs="1.3" color="#333333" m="0">Delete</p>
</div>
</div>
