<div a-style bg="#FFFFFF"  brBottom="1px solid #E4E4E4"  w="100%" >
  <div a-style   display="flex" justify="space-between" alignItems="center" ml="18" maxWidth="71.9">
    <div a-style display="flex" gap="2.3" >
      <ng-container *ngFor="let title of search_items" [ngTemplateOutlet]="Heading" [ngTemplateOutletContext]="{item:title}"></ng-container>
    </div>
  </div>
</div>

<ng-template #Heading let-item=item>
  <div (click)="gs.router.navigate([],{queryParams : {application : item.title} })" a-style display="flex" gap="1" pt="1.7" pb="1.2">
    <m-image src={{item.image}} w="2" h="2"></m-image>
    <m-text [type]="gs.enums.texts.paragraph5" text=" {{item.title}}" ml="0"></m-text>
  </div>
</ng-template>