import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-temp-conversation',
  templateUrl: './temp-conversation.component.html',
  styles: [
  ]
})
export class TempConversationComponent implements OnInit {

  @Input() data: any = undefined;

  application = 'generic';
  component = 'conversation';
  user: any;
  type: string = ''
  form_data = {
    text: null,
    file: null
  }
  list_url = `/${this.application}/${this.component}/list`;
  chat_list: any[] = []

  list = {
    list: <any>[],
    count: 0
  }

  receiver = {}

  form: FormGroup | undefined;
  constructor(public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.get_list()
    this.initializeForm();
    // this.get_list()
    this.gs.notifications$.subscribe((notification: any) => {
      notification = JSON.parse(notification)
      console.log(notification);
      this.chat_list.push(notification);
    })

    this.set_receiver();
  }

  set_receiver() {
    console.log(this.data)
    let alloted_to = Object.keys(this.data?.meta?.alloted?.alloted_to?.user || {})[0] || undefined;
    console.log(alloted_to);
    let owner = Object.keys(this.data?.meta?.roles?.owner?.user || {})[0]
    let is_owner = this.data?.meta?.roles?.owner?.user?.[this.gs.user.users.activeUser?._id || ''] || false;
    console.log('Owner is ', is_owner, this.gs.user.users.activeUser?._id, this.data?.meta?.roles?.owner?.user)
    if (!is_owner) {
      this.receiver = {
        user: {
          [owner]: true
        }
      }
    } else {
      this.receiver = {
        [alloted_to ? 'user' : 'group']: {
          [alloted_to || this.data.parents?.data?.parents?.group._id]: true
        }
      }
    }
  }

  initializeForm() {
    let receiver = {}
    this.form = this.fb.group({
      application: 'Generic',
      component: 'Conversation',
      // channel_id: this.channel_id,
      type: 'chat',
      sender: {
        user: {
          [this.gs.user?.users?.activeUser?._id || '']: true,
        }
      },
      data: this.fb.group(this.form_data)
    })
  }

  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }

  async get_list() {
    if (this.data._id) {
      let filters = { parents: { data: this.data._id } }
      let response = await this.gs.hps.post(this.application, this.component, 'list', filters)
      this.chat_list = response?.list;
    }
  }

  async submit() {
    let parent = {
      group: '',
      data: '',
      
    }
    let body = this.form?.value;
    body.receiver = this.receiver;
    body.parents = {
      group: this.data.parents.data.parents.group._id,
      data: this.data._id,
    }
    let response = await this.gs.hps.post('generic', 'conversation', 'add', body);
    body.meta = {
      roles: {
        owner: {
          [this.gs.user.users?.activeUser?._id || '']: true
        }
      },
      activity: {
        updated_by: {
          first_name: this.gs.user.users.activeUser?.personal_details.first_name,
          last_name: this.gs.user.users.activeUser?.personal_details.last_name,
        }
      }
    }
    this.chat_list.push(body);
  }
}
