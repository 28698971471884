import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-invoice',
  templateUrl: './invoice.component.html',
  styles: [
  ]
})
export class InvoiceComponent implements OnInit {

  query_params = {
    _id: '' || '60fba0a4a52f9d1896be5029'
  }

  application = "";
  component = "";
  invoices = {
    list: [],
    count: 0
  }

  details: any = undefined;
date = new Date()
  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((query_params: any) => {
      this.query_params = { ...query_params }
      this.get_order()
      // setTimeout(() => {
      //   this.gs.print_component('printSection')
      // }, 3000);
    })
  }

  async get_order() {
    this.details = await this.gs.hps.get('idm', 'payment', this.query_params._id || '60fba0a4a52f9d1896be5029');
    console.log(this.details)
  }

}














