import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import Croppr from 'croppr';

@Component({
  selector: 'm-image-croppr',
  templateUrl: './image-croppr.component.html',
  styles: []
})
export class ImageCropprComponent implements OnInit {

  @Input() aspect_ratio = 1;
  @Input() value = '';

  @Input() before: any;
  @Input() modal: any;


  @Output() file_upload = new EventEmitter();
  @Output() cancel = new EventEmitter();

  file: any;
  is_visible: Boolean = false;

  @ViewChild('croppr') croppr: any;

  constructor(public gs: GlobalService,) {

  }

  url = ''
  reinitialize() {
    this.url = ''
  }

  ngOnInit(): void {

  }

  cropper_instance: any;

  set_cropper() {
    let onCropEnd = async (data: { x: number, y: number, width: number, height: number }) => {
      try {
        // console.log("On Crop End: ", data);
        const canvas = <HTMLCanvasElement>document.getElementById('myCanvas')
        const context = canvas.getContext('2d');

        canvas.width = data.width;
        canvas.height = data.height;

        context?.drawImage(this.croppr.nativeElement, data.x, data.y, data.width, data.height, 0, 0, canvas.width, canvas.height);

        const dataUrl = canvas.toBlob(async (blob: any) => {
          // console.log(blob)
          let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })
          this.file = file;
        }, 'image/jpeg');
      } catch (error) {
        console.log(error)
      }
      // console.log("data url", dataUrl)
    }
    this.cropper_instance = new Croppr(this.croppr.nativeElement, {
      aspectRatio: this.aspect_ratio,
      onCropEnd: onCropEnd
    });

    this.cropper_instance.resizeTo(200, 200 * this.aspect_ratio)
    this.cropper_instance.moveTo(0, 0)
  }

  async upload_pic(file: any) {
    // console.log("sushant");
    
    let selected_file = file;
    let form_data = new FormData()
    form_data.append('name', 'avatar');
    form_data.append('avatar', selected_file);
    let options = {
      headers: {
        token: this.gs.user.users.activeUser?.token
      }
    }
    // console.log('Before Api Call', form_data, selected_file)
    // console.log('form_data.........', form_data);

    let data = await this.gs.hps.post('', '', 'upload', form_data)
    // console.log('Emitted ', data)
    this.file_upload.emit(data)
    this.url = ''
    this.cropper_instance.destroy()
  }

  upload_event(event: any) {
    // console.log("madhuri");
    
    let selectedFile = event.target.files[0];
    let reader = new FileReader();
    let imgtag: any = document.getElementById("myimage");
    if (imgtag) {
      imgtag.title = selectedFile.name;
      reader.onload = (event: any) => {
        if (imgtag) {
          imgtag.src = event.target.result;
          this.url = event.target.result;
        }
        setTimeout(() => {
          this.set_cropper()
        }, 100);
      }
    }
    reader.readAsDataURL(selectedFile);
  }

}
