<div *ngIf="is_visible" [top]="top ? top : '14.5'" left="0" zIndex="1000" a-style bg="rgb(0,0,0,0.2)" display="flex"
    justify="center" alignItems="center" position="fixed" [h]="h? h :'calc(100vh - 7.6rem - 6.5rem)'" w="100vw">
    <ng-container [ngTemplateOutlet]="modalWithFormat"></ng-container>
</div>
<ng-template #modalWithFormat>
    <div a-style w="56.0" h="21.2" brRadius="0.6" bg="#FFFFFF" display="flex" justify="center" align="center">
        <div a-style display="flex" flexDirection="column" align="center" gap="2.4">
            <img src="../assets/images/GIF_Bank/Other/Green_Tick.gif" alt="" a-style w="14" h="14">
            <p a-style fs="2">{{title}}</p>
        </div>
    </div>
</ng-template>