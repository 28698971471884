<div a-style align=center m=1.6>
    <m-image src="../assets/images/assets/share_modal.jpg" mb=2.4 w=100%></m-image>
    <m-text type="1" text="You can share through :" mb=2.4></m-text>
    <div a-style display=flex flexWrap="wrap" br="1px solid #e4e4e4" brRadius=0.6>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('mesbro_mail')" a-style h="2.8" cursor="pointer"src="../assets/images/assets/mailIcon.svg">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('chat')" a-style h="3" cursor="pointer"src="../assets/images/assets/chat.svg">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('facebook')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/128/145/145802.png">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="space-evenly">
            <img (click)="handle_redirect('whatsapp')" a-style h="2.5" m=1 cursor="pointer"src="https://image.flaticon.com/icons/png/128/733/733585.png">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('twitter')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/128/179/179342.png">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('linked_in')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/128/179/179330.png">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('mail')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/128/937/937819.png">
        </div>
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('gmail')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/512/888/888853.png">
        </div>
        
        <div a-style p=1 w=5.2 brRight="1px solid #e4e4e4" display=flex justify="center" align=center>
            <img (click)="handle_redirect('instagram')" a-style h="2.5" cursor="pointer"src="https://image.flaticon.com/icons/png/512/2111/2111463.png">
        </div>
        
        <div a-style p=1 w=5.2 display=flex justify="center" align=center>
            <img (click)="handle_redirect('telegram')" a-style h="2.8" cursor="pointer"src="https://image.flaticon.com/icons/png/128/2111/2111646.png">
        </div>
        
    </div>
</div>