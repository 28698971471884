<!-- type =='vehical_card_1' || type =='vehical_card_11' || type == 'tyre_12' -->
<ng-container [ngTemplateOutlet]="card_1" *ngIf="type =='card_1' || type =='card_11'"></ng-container>

<!-- type=='article_card' || type=='article_card_2' -->
<ng-container [ngTemplateOutlet]="card_3" *ngIf="type=='card_3' || type=='card_32'"></ng-container>

<!-- type =='types' -->
<ng-container [ngTemplateOutlet]="card_4" *ngIf="type =='card_4' || type =='card_41'"></ng-container>

<!-- type =='vehicle_brand' || type =='tyre_brand' -->
<ng-container [ngTemplateOutlet]="card_5" *ngIf="type =='card_5' || type =='card_51'"></ng-container>

<!-- type =='left_side_card' -->
<ng-container [ngTemplateOutlet]="card_6" *ngIf="type =='card_6'"></ng-container>

<!-- type =='right_side_card' -->
<ng-container [ngTemplateOutlet]="card_7" *ngIf="type =='card_7'"></ng-container>

<!-- type =='video' -->
<ng-container [ngTemplateOutlet]="card_8" *ngIf="type =='card_8'"></ng-container>


<ng-template #card_1>
    <!-- {{detail | json}} -->
    <div  (mouseover)="icon=true" (mouseleave)="icon=false" a-style br="1px solid rgba(228, 228, 228, 0.47)" bg="#ffffff" p="1.2" brRadius=0.6 position=relative shadow="hov:0 3px 6px 0 #f1f1f1" cursor="pointer" minWidth=30.5rem maxWidth=30.5rem>
        <div *ngIf="icon" a-style bg="#333333" h=2 p=0.5 brRadius="0.6" display="flex" gap=0.6 align="center" position="absolute" top=0.5 right=0.5>
            <m-image src="../assets/images/assets/Group 74964.svg" w=1.7 (click)="gs.action('save',detail)"></m-image>
            <m-image [src]='gs.enums.images.image332' hover="../assets/images/assets/favourite hover.svg" a-style w=1.5 (click)="gs.action('favourite',detail)"></m-image>
            <m-image src="../assets/images/assets/compare_white_bg.svg" w=1.5 (click)="add_to_compare()"></m-image>
        </div>
        <div a-style align=center>
            <m-image [src]="'https://new-api.mesbro.com/uploads/'+(detail?.profile_picture || detail?.gallery[0])" h=13.9 mb=0.8 w=100% a-style (click)="redirect_to_detail(detail)"></m-image>
        </div>
        <div a-style display=flex justify="space-between" mt="0.8" mb=.4>
            <m-text [type]="gs.enums.texts.paragraph38" a-style [text]="detail?.title" (click)="redirect_to_detail(detail)" [ellipsis]=true w=26></m-text>
        </div>
        <m-text [type]="gs.enums.texts.paragraph23" mb=0.4 a-style [text]="detail?.parents?.parent?.title" [ellipsis]=true lineClamp=1></m-text>
        <div a-style display=flex justify="space-between">
            <m-text [type]="gs.enums.texts.paragraph38" a-style text="{{detail?.attributes?.price_details?.ex_showroom_price?.value?detail?.attributes?.price_details?.ex_showroom_price?.value:0}} - {{detail?.attributes?.price_details?.on_road_price?.value?detail?.attributes?.price_details?.on_road_price?.value:0}}"
            [ellipsis]=true lineClamp=1 w=22.5></m-text>

            <m-image *ngIf="detail?.meta?.roles?.owner?.user" [src]="gs.enums.images.image32" a-style w=2 h=2></m-image>
            <!-- <m-image *ngIf="detail?.meta?.roles?.owner?.user && type =='card_1'" [src]="gs.enums.images.image70" a-style w=2 h=2.4 (click)="delete.emit(detail._id)"></m-image> -->
        </div>
    </div>

</ng-template>
<div *ngIf="type == 'card_12'" a-style br="1px solid rgba(228, 228, 228, 0.47)" bg="#ffffff" p="1.2" brRadius=0.6 shadow="hov:0 3px 6px 0 #f1f1f1" cursor="pointer" [maxWidth]="maxWidth ? maxWidth : 20.8">
    <div a-style align=center>
        <m-image src="https://ie.ceat.com/images/tyre/model/listing/securadrive1.png" h=13.9 mb=0.8 w=auto a-style></m-image>
    </div>
    <div a-style display=flex justify="space-between" mt="0.8" mb=.4>
        <m-text [type]="gs.enums.texts.paragraph38" a-style text="CEAT SecuraDrive" class="ellipsis" style="overflow: hidden;text-overflow: ellipsis;width: 150;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"></m-text>
    </div>
    <m-text [type]="gs.enums.texts.paragraph23" mb=0.4 a-style text="185/65 R15 88H - Tubeless" class="ellipsis" style="overflow: hidden;text-overflow: ellipsis;width: 150;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"></m-text>
    <div a-style display=flex justify="space-between">
        <m-text [type]="gs.enums.texts.paragraph38" a-style text="INR 8,511 - INR 15,511" class="ellipsis" style="overflow: hidden;text-overflow: ellipsis;width: 225.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;"></m-text>
    </div>
</div>

<ng-template #card_3 let-data=data>
    <div a-style *ngIf="type == 'card_3' || type == 'card_32'" bg="#ffffff" brRadius=0.6 maxWidth=30.5 fs="1.3" a-style shadow=" hov:0 3px 6px 0 #f1f1f1" br="1px solid rgba(228, 228, 228, 0.47)">
        <m-image src="https://media.evo.co.uk/image/upload/v1568646775/evo/2019/09/2019%20Audi%20A4-3.jpg" brTopLeftRadius=0.6 brTopRightRadius=0.6 h=16.2 w=100% mb=0.8></m-image>
        <div a-style px=1.2>
            <m-text [type]="gs.enums.texts.paragraph35" *ngIf="type=='card_3'" text="2020 Audi A4 Facelift: First Drive Review" ellipsis=true></m-text>
            <m-text [type]="gs.enums.texts.paragraph38" text="With a cabin that reminds you of an electric car with its quietness, and a top speed of ..." mt=0.5 class="lineClamp"></m-text>
        </div>
        <div a-style display=flex justify="space-between" p=1.2 [brTop]="brTop ? brTop : ''">
            <m-text [type]="gs.enums.texts.paragraph23" a-style text="Tech Desk"></m-text>
            <m-text [type]="gs.enums.texts.paragraph23" a-style text="1 hour ago"></m-text>
        </div>
    </div>
</ng-template>

<ng-template #card_4 let-data=data>
    <!-- text="Bike" [src]="gs.enums.images.image346" -->
    <div bg="#ffffff" *ngIf="type == 'card_4'" a-style shadow="hov:0 3px 6px 0 #f1f1f1">
        <div a-style brRadius=0.6 br="1px solid #e4e4e4" h=23.1 w=19.8 px=3.2 display=flex flexDirection=column align=center justify=center fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1">
            <m-image src="../assets/images/assets/car.svg" h=13.4 w=13.4></m-image>
            <m-text [type]="gs.enums.texts.paragraph38" text="Car" mt=1.6></m-text>
        </div>
    </div>
    <div bg="#ffffff" *ngIf="type == 'card_41'" a-style shadow="hov:0 3px 6px 0 #f1f1f1">
        <div a-style brRadius=0.6 br="1px solid #e4e4e4" h=23.1 w=19.8 px=3.2 display=flex flexDirection=column align=center justify=center fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1">
            <m-image src="../assets/images/assets/mumbai_vehicle.svg" h=13.4 w=13.4></m-image>
            <m-text [type]="gs.enums.texts.paragraph38" text="Mumbai" mt=1.6></m-text>
        </div>
    </div>
</ng-template>

<ng-template #card_5 let-data=data>
    <div a-style *ngIf="type =='card_5' || type =='card_51'" bg="#ffffff" brRadius=0.6 br="1px solid #e4e4e4" shadow="hov:0 3px 6px 0 #f1f1f1" w=16.8 h=18.4 p=1.2 display=flex flexDirection=column align=center justify=center fs="1.3" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <m-image src="https://www.car-brand-names.com/wp-content/uploads/2016/03/Suzuki-logo.png" h=11.6 w=auto *ngIf="type =='card_5'"></m-image>
        <m-text [type]="gs.enums.texts.paragraph38" text="Maruti" mt=1.6 *ngIf="type =='card_5'"></m-text>
        <m-image src="https://cdn.freelogovectors.net/wp-content/uploads/2019/10/ceat-logo.png" h=11.6 w=auto *ngIf="type =='card_51'"></m-image>
        <m-text [type]="gs.enums.texts.paragraph38" text="Ceat" mt=1.6 *ngIf="type =='card_51'"></m-text>
    </div>
</ng-template>

<ng-template #card_6 let-data=data>
    <div a-style brRadius=0.6 pt=2.2 br="1px solid #e4e4e4" bg="#ffffff" w=100% shadow=" hov:0 3px 6px 0 #f1f1f1" align=center>
        <m-image src="../assets/images/assets/sell_car.png" w=100% h=25></m-image>
        <div a-style mx=1.6 mb=1.6 mt=2.4>
            <m-text [type]="gs.enums.texts.paragraph40" mb=0.6 text="Sell Your Car At Best Price"></m-text>
            <m-text [type]="gs.enums.texts.paragraph10" text="Instant money transfer I Free RC Transfer I Home Inspection" style="overflow: hidden;text-overflow: ellipsis;max-width: 226px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"></m-text>
            <div a-style mt=1.8 p=1 display=flex align=center br="1px solid #e4e4e4" brRadius=0.6>
                <m-image src="https://mesbro.in/assets/images/app-icon/policy.svg" h=3.3 w=auto></m-image>
                <m-text [type]="gs.enums.texts.paragraph14" text="COVID safety assured - Contactless experience in whole proces" style="overflow: hidden;text-overflow: ellipsis;max-width: 226px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;"></m-text>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #card_7 let-data=data>
    <div a-style display=flex flexDirection=column justify=space-between gap=2.4>
        <div a-style bg="#ffffff" brRadius=0.6 br="1px solid #e4e4e4" p=2.1 display=flex flexDirection=column align=center justify=center fs="1.3" shadow=" hov:0 3px 6px 0 #f1f1f1">
            <m-image src="../assets/images/assets/vehicle_rental.png" h=12.4></m-image>
            <m-text [type]="gs.enums.texts.paragraph40" text="Rental" mt=2.4></m-text>
        </div>
        <div a-style bg="#ffffff" brRadius=0.6 br="1px solid #e4e4e4" p=2.1 display=flex flexDirection=column align=center justify=center fs="1.3" shadow=" hov:0 3px 6px 0 #f1f1f1">
            <m-image src="../assets/images/assets/auto_loan_&_finance.png" h=12.4></m-image>
            <m-text [type]="gs.enums.texts.paragraph40" text="Auto Loan & Finance" mt=2.4></m-text>
        </div>
    </div>
</ng-template>

<ng-template #card_8>
    <!-- <div a-style display=flex overflowX=auto class="no_scroll_bar" gap=2.4> -->
    <video controls a-style w="27.2" shadow=" hov:0 3px 6px 0 #f1f1f1" brRadius="0.6">
        <source src="http://techslides.com/demos/sample-videos/small.mp4" type="video/mp4">
    </video>
    <!-- </div> -->
</ng-template>

<ng-template #card2 let-data=data>
    <div a-style bg="#ffffff" pt=5.5 px=2.5 brRadius=0.6 br="1px solid rgba(228, 228, 228, 0.47)" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <div a-style justify="space-between" display="flex" pb=3.5>
            <m-input *ngIf="!gs.router.url.includes('idm')" shadow="hov:0 3px 6px 0 #b2b2b2" a-style placeHolder="Search " h="4" w="60" outline=none px=0 a-style display="900:none" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg"></m-input>
            <m-button [types]="gs.enums.buttons.button78" text=" Add Vehicle " h=4></m-button>
        </div>
        <div a-style display="flex" flexGap=0.5>
            <ng-container [ngTemplateOutlet]="vehicle_card"></ng-container>
        </div>
    </div>

    <ng-template #vehicle_card let-data=data>
        <div display="flex" bg="#ffffff" a-style position="relative" shadow=" hov:0 3px 6px 0 #f1f1f1">
            <div a-style w="30.4" br="1px solid rgba(228, 228, 228, 0.47)" brRadius=0.6 mb="1">
                <div a-style w=100% h=13.6>
                    <m-image src='https://media.gq-magazine.co.uk/photos/5d13a6eab6fee944ccc9fb7f/master/pass/royal.jpg' w=auto h=13.9></m-image>
                </div>
                <div a-style justify="space-between" p=1.2>
                    <m-text [type]="gs.enums.texts.paragraph20" mb=0.5 style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" text='Royal Enfield Himalayan BS6 Granite Black'></m-text>
                    <div a-style display="flex" justify="space-between">
                        <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" text='Touring Bikes'></m-text>
                        <m-text [type]="gs.enums.texts.paragraph23" style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" text='10 Views'></m-text>
                    </div>
                </div>
            </div>
            <div a-style bg="#333333" w="5" h=2 p=0.5 brRadius=0.6 display="flex" align="center" position="absolute" top="1.5" right=1.5>
                <m-image [src]='gs.enums.images.image331' a-style w=1.5 mr=1></m-image>
                <m-image [src]='gs.enums.images.image332' a-style w=1.5></m-image>
            </div>
        </div>
    </ng-template>
</ng-template>