import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit,EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { AstyleDirective } from '../../directives/a-style.directive';
import { side_bar_items } from './side-bar-data';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';

@Component({
  selector: 'm-side-bar',
  templateUrl: './side-bar.component.html',
  styles: [
  ]
})
export class SideBarComponent implements OnInit {
  @ViewChild(AstyleDirective) styleDirective: any;
 @Input() width:any = 28;
 @Input() minWidth:any = 25;
  side_bar_items = side_bar_items;
 
  params: any = {
    type: ''
  }
  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((param: any) => {
      this.params = { ...param }
      console.log(this.side_bar_items);
    })
  }

  drag(event: any) {
    console.log(event);
    
    if (event.x) {
      this.width = event.x / 10;
      this.styleDirective.setStyles();
    }
  }
  navigate(item?: any) {
    let path = item.toLowerCase()
    this.gs.router.navigate(["dashboard/" + path])
  }

}
