import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-mail-label-form',
  templateUrl: './mail-label-form.component.html',
  styles: [
  ]
})
export class MailLabelFormComponent implements OnInit {

  constructor(public gs: GlobalService ) { }

  ngOnInit(): void {
  }

}
