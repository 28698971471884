import {
    Pipe, PipeTransform 
  } from '@angular/core';


@Pipe({
    name: 'replaceUnder'
  })
  export class ReplaceUC implements PipeTransform {
       transform(str: string,arg1: any, arg2: any): string {
        return str?.replace(arg1, arg2)
        }
   }
  