import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-common-layout',
  templateUrl: './common-layout.component.html',
  styles: [
  ]
})
export class CommonLayoutComponent implements OnInit {
  @Input() class:any

  constructor(public gs : GlobalService) { }

  ngOnInit(): void {
  }

}
