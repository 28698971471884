<div a-style w="100%" brRadius=4px mt=5.4  p="2.4">
  <form [formGroup]="form">
      <m-form columns="2" p="1.6" h=6 title="Shortlink" overflowX="hidden;786:auto;320:scroll" title_mb=0.8 title_mt=0.6 fs="2" labelWeight="400" brBottom="1px solid #e2e2e2" >
          <div a-style w=100% display="flex;1024:''">
              <m-input [columns]="1/4" minWidth="35;480:32.5;321:30" flexGrow="0;320:1;1024:1;1280:1;1336:1" formControlName="shortlink" label="Shortlink* " placeHolder="Shortlink" minWidth="5%" required="true"></m-input>
              <m-input [columns]="1/4" minWidth="35;480:32.5;321:30" flexGrow="0;320:1;1024:1;1280:1;1336:1" formControlName="title" label="Title* " placeHolder="Title" minWidth="5%" required="true"></m-input>
              <m-input [maxWidth]="40" label="Select Organization" bindLabel="title" placeHolder="Select Organization"  bindValue="_id" [list]="gs.$groups | async" formControlName="groupControl" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" [columns]="1/4"></m-input>
          </div>
          <m-input [columns]="1/4" minWidth="35;480:32.5;321:30" flexGrow="0;320:1;1024:1;1280:1;1336:1" [aspect_ratio]='1' label="Shortlink Logo" description="Upload your Shortlink logo here" formControlName='image' [type]="gs.enums.inputs.media"></m-input>
          <div a-style display="flex" justify="flex-end" w="100%" mt=2.4>
              <m-button [types]="gs.enums.buttons.button32" text="Submit" py=1.2 px=2.4 (click)="submit()" br="none"></m-button>
          </div>
      </m-form>
  </form>
</div>
