import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-card',
  templateUrl: './generic-card.component.html',
  styles: [
  ]
})
export class GenericCardComponent implements OnInit {
  @Input() type: any;
  @Input() types: any;
  @Input() detail: any;
  @Input() owner: any;
  @Input() text: any;
  @Input() src: any;
  @Input() name: any;
  @Input() desc: any;
  @Input() price: any;
  @Input() minWidth: any;
  @Input() maxWidth: any;
  @Input() brTop: any;
  @Input() activities: any;
  @Input() ratings: any
  @Output() delete = new EventEmitter();
  @Output() activity = new EventEmitter();
  @Input() mydetails: any;
@Output() deleted = new EventEmitter()
  typeId:any
  favorite: boolean = false
  icon: Boolean = false;
  rating=0
  _images: any = {
    fav: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/star-border.svg',
    hov_fav: '../assets/images/assets/favourite hover.svg',
  }

  list = {
    list: [],
  };
  today = new Date()
  queryParams = {
    _id: '',
    type: '',
    application: ''
  }
  viewCount:any
  card_1: any = {
    Vehicle: true,
    Recipe: true,
    Service: true,
    Horoscope: true,
    Spiritual: true,
    Activity: true,
    Project: true,
    Subsidy: true,
    Property: true
  }

  card_2: any = {
    Nursery: true,
    Product: true,
    Scrap: true,
    Refurbished: true,
    Used: true,
    Template: true
  }

  show_compare: any = {
    Horoscope: false,
    Spiritual: false,
    Article: true,
    Vehicle: true,
    Recipe: true,
    Subsidy: true,
    Service: true,
    Nursery: true,
  };
  params: any;


  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((param: any) => {
      this.params = param
    })
    this.getType()
  }
  redirect_to_form(item: any) {
    this.gs.router.navigate(['service', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_recipe_form(item: any) {
    this.gs.router.navigate(['recipe', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_article_form(item: any) {
    this.gs.router.navigate(['article', 'form'], { queryParams: { _id: item, edit: true, step: 'form', application: 'Product', subheading: "Basic Detail" } });
  }
  redirect_to_job_form(item: any) {
    this.gs.router.navigate(['jobs', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_project_form(item: any) {
    this.gs.router.navigate(['project', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_nursery_form(item: any) {
    this.gs.router.navigate(['nursery', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_used_form(item: any) {
    this.gs.router.navigate(['used', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_property_form(item: any) {
    this.gs.router.navigate(['properties', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_guardian_form(item: any) {
    this.gs.router.navigate(['guardian', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_spritual_form(item: any) {
    console.log('madhrui');
    this.gs.router.navigate(['spiritual', 'form'], { queryParams: { _id: item, edit: true,application:'Product', step: 'Form', subheading: "Basic Details" } });
  }

  async getType() {
    // this.typeId = await this.gs.hps.get('spiritual', 'spiritualtypes', this.detail?.categoryInfo.typeId)
  }
  async deleteService(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('business', 'services', 'delete-service', body)
    this.deleted.emit('')
    this.gs.toast('Service', ' Successfully')
  }
  async deleteRecipe(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('food', 'recipes', 'delete-recipe', body)
    this.deleted.emit('')
    this.gs.toast('Recipe', ' Successfully')
  }
  async deleteArticle(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('article', 'articles', 'delete-article', body)
    this.deleted.emit('')
    this.gs.toast('Article', ' Successfully')
  }
  async deleteJob(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('job', 'jobs', 'delete-job', body)
    this.deleted.emit('')
    this.gs.toast('Job', ' Successfully')
  }
  async deleteNursery(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('nursery', 'nurseries', 'delete-nursery', body)
    this.deleted.emit('')
    this.gs.toast('Nursery', ' Successfully')
  }
  async deleteUsed(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('used', 'useds', 'delete-used', body)
    this.deleted.emit('')
    this.gs.toast('Used', ' Successfully')
  }
  async deleteProperty(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('property', 'properties', 'delete-property', body)
    this.deleted.emit('')
    this.gs.toast('Property', ' Successfully')
  }
  async deleteGuardian(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('animal', 'animals', 'delete-animal', body)
    this.deleted.emit('')
    this.gs.toast('Guardian', ' Successfully')
  }
  async deleteSpritual(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('spiritual', 'spirituals', 'delete-spiritual', body)
    this.deleted.emit('')
    this.gs.toast('Guardian', ' Successfully')
  }
  async deleteProject(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('project', 'projects', 'delete', body)
    this.deleted.emit('')
    this.gs.toast('Project', ' Successfully')
  }
  redirect_to_detail(mydetails: any) {
    console.log(mydetails)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: mydetails._id } });
  }
  add_to_compare() {
    console.log('Function called', this.detail)
    if (!this.detail) {
      return;
    }
    let current = this.gs.localStorageGet('compare-items') || {};
    current[this.detail._id] = this.detail;
    this.gs.localStorageSet('compare-items', current)
    this.gs.toast('Compare ' + this.detail.application, this.detail.application + ' was added to compare list', 'success', 3)
    this.gs.$refresh_compare.next()
  }
  redirect_to_article(id: any) {
    this.gs.router.navigate(['article/article-detail'], { queryParams: { _id: id._id , type :'article'} });
  }
  redirect_to_recipe(id: any) {
    this.gs.router.navigate(['recipe/recipe-detail'], { queryParams: { _id: id._id , type:'recipe'} });
  }

  redirect_to_service(id: any) {
    this.gs.router.navigate(['service/Detail'], { queryParams: { _id: id._id ,type:'productInfo'} });
  }
  redirect_to_guardian(id: any) {
    this.gs.router.navigate(['guardian/guardian-detail'], { queryParams: { _id: id._id  , type:'guardian'} });
  }
  redirect_to_spiritual(id: any) {
    this.gs.router.navigate(['spiritual/spiritual-detail'], { queryParams: { _id: id._id , type:'spiritual'} });
  }
  redirect_to_project(id: any) {
    this.gs.router.navigate(['project/project-detail'], { queryParams: { _id: id._id , type:'Project' } });
  }
  redirect_to_used(id: any) {
    this.gs.router.navigate(['used/used-detail'], { queryParams: { _id: id._id } });
  }
  redirect_to_refurbished(id: any) {
    this.gs.router.navigate(['refurbished/refurbished-detail']);
  }
  redirect_to_nursery(id: any) {
    this.gs.router.navigate(['nursery/nursery-detail'], { queryParams: { _id: id._id } });
  }
  redirect_to_jobs(id: any) {
    this.gs.router.navigate(['jobs/job-detail'], { queryParams: { _id: id._id , type:'jobs'} });
  }
  redirect_to_Vehicle(id: any) {
    this.gs.router.navigate(['vehicles/vehicle/detail'], { queryParams: { _id: id._id } })
  }
  redirect_to_properties(id: any) {
    this.gs.router.navigate(['properties/detail'], { queryParams: { _id: id._id , type:'properties'} })
  }
  redirect_to_helpCenter(id:any){
    this.gs.router.navigate(['center/help-detail'], { queryParams: { _id: id._id} })
  }
  async getViewCount(id: any, application:any,component:any) {
    try {
      let body = {
        "service_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountused(id: any, application:any,component:any) {
    try {
      let body = {
        "used_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountProperties(id: any, application:any,component:any) {
    try {
      let body = {
        "property_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountproject(id: any, application:any,component:any) {
    try {
      let body = {
        "project_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountarticle(id: any, application:any,component:any) {
    try {
      let body = {
        "article_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountRecipe(id: any, application:any,component:any) {
    try {
      let body = {
        "recipe_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCounGuardian(id: any, application:any,component:any) {
    try {
      let body = {
        "animal_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCounNursery(id: any, application:any,component:any) {
    try {
      let body = {
        "nursery_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountjobs(id: any, application:any,component:any) {
    try {
      let body = {
        "job_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
  async getViewCountspiritual(id: any, application:any,component:any) {
    try {
      let body = {
        "spiritual_id": id
      }
      this.viewCount = await this.gs.hps.post(application, component, 'view', body)
    } catch (error) {

    }
  }
}
