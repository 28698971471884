import { components } from 'angular-routing';
import { application } from 'express';
import { Component, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-product-banner',
  templateUrl: './product-banner.component.html',
  styles: [
  ]
})
export class ProductBannerComponent implements OnInit {
  @Input() details: any
  @Input() detail: any;
  @Input() productImage: string = '';
  @Output() activity = new EventEmitter();
  @Input() types: any

  @Input() visible = false;
  @Input() activities: any;
  isVisible: boolean = false;
  image_src: any = "https://new-api.mesbro.com/uploads/{{details?.profile_picture}}";
  @Output() addProduct = new EventEmitter<any>();
  @Input() modal: any;


  application = "Generic";
  component = "Report";
  type = "report product"

  isVisible_like: boolean = false;
  isVisible_dislike: boolean = false;
  isVisible_compare: boolean = false;
  isVisible_bookmark: boolean = false;
  like_count: number = 0
  password_title = 'Password must contain at least 8 characters , including UPPER/lowercase , numbers and special characters';

  flag_title = 'India';
  like_title = 'Like';
  dislike_title = 'Dislike';
  compare_title = 'Add To Compare';
  save_title = 'Saved Product';
  favourite_title = 'Favourite Product'
  view = 'view';
  compare_object: any = this.gs.localStorageGet('compare-items');


  print = 'Print';
  favourite = 'favourite'
  QR_title = 'Show QR Code';
  share_title = 'Share'
  listener: any;
  current: boolean = false;
  @Input() is_visible: boolean = false;
  // is_visible: boolean = false;
  compare_count: any

  isVisible_favourite: boolean = false;
  users: IUserData;
  form!: FormGroup
  form_data: any = {
    text: null,
    html: null,
    file: null,
  }


  // constructor(public gs: GlobalService) { }
  constructor(public renderer: Renderer2, public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) {
    this.listener = this.renderer.listen('document', 'keyup', (event) => {
      if (event && event.key == 'Escape') {
        this.closeModal()
      }
    })
  }
  queryParams = {
    _id: ''
  }

  queryParam = {
    _id: <string>'',
    application: <string>'',
    step: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'productList'>'Primary',
    parent_id: '',
    type: ''
  };
  async ngOnInit() {
    this.current = Object.keys(this.gs.localStorageGet('compare-items') || {}).includes(this.details?.parent?._id)
    console.log(this.current);
    console.log(this.details, 'ggggggggggggggggggggggggggggggggggggggggggggggggggggg');
    

    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    })
    this.ar.queryParams.subscribe(async (params: any) => {
      this.queryParams = params;
      console.log(this.queryParams);
    })
    this.compare_object = this.gs.localStorageGet('compare-items');
    this.compare_count = this.gs.localStorageGet('compare-items');
    this.compare_count = Object.keys(this.compare_object).length
    this.initialize_form();

  }
  // goto_saved_list() {
  //   this.addProduct.emit(this.detail);
  // }
  // redirect_to_detail(item: any) {
  //   console.log(item)
  //   this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  // }


 
  first_time = true;  

  openModal() {
    this.is_visible = true;
  }

  closeModal() {
    this.is_visible = false;
    this.first_time = true;
  }
  add_to_compare() {
    if (this.compare_count <= 3) {
      let current = this.gs.localStorageGet('compare-items') || {};
      console.log(current);
      current[this.details._id] = this.details;
      this.gs.localStorageSet('compare-items', current)
      this.gs.toast('Compare Product', 'Product was added to compare list', 'success', 3)
      this.current = !this.current
      this.gs.$refresh_compare.next()
      console.log(this.compare_count, 'sd5rtftgyhgb')
    }
    else (this.compare_count == 4); {
      this.gs.toast('Compare Product', 'Already Four products are added', 'success', 4)
      console.log(this.compare_count, 'cfgcchgvtd 45e5')
    }
  }

  toggle(activities: any, toggle_key: string, current_key: string) {
    console.log(activities);
    activities.my_activity[current_key] = !activities.my_activity[current_key]
    if (activities?.my_activity?.[current_key]) {
      activities.count[current_key] = activities?.count[current_key] + 1
    } else {
      activities.count[current_key] = activities?.count[current_key] - 1
    }
    if (activities?.my_activity?.[toggle_key]) {
      activities.count[toggle_key] = activities?.count[toggle_key] - 1
      activities.my_activity[toggle_key] = false
    }
  }

  //  select_image(index:any){
  //    console.log(index);
  //    for(let i=index;i<=this.details.gallery.length;i++){
  //     console.log(this.details.gallery[index]);
  //     return this.details.gallery[index]
  //     this.image_src=this.details.gallery[index]
  //     return this.image_src;

  //    }
  // }
  // change_image(){

  //   this.image_src=true;

  // }

  // changemood(index:any){
  // this.image_src=true;
  // this.image_src=this.details.gallery[index]
  // return this.image_src;
  // console.log(index)
  // console.log(this.details.gallery[index])
  // this.image_src=this.details.gallery[index];
  // let gallary_image = this.details.gallery[index]
  // return gallary_image;
  // console.log(this.image_src)

  // }
  // index:any = '';
  // gallary_image = this.details.gallery[this.index]



  // watchImage:string="https://new-api.mesbro.com/uploads/{{item}}";
  //  repalceImage:string="https://new-api.mesbro.com/uploads/{{details?.profile_picture}}";
  //  addImage(i:number):void{
  //    var temp=this.watchImage;
  //    this.watchImage=this.repalceImage;
  //    this.repalceImage=this.watchImage;
  //    this.repalceImage=temp;
  //  }
  // let image();
  // change(y:any){
  //     if(y.src == "../assets/images/assets/watches.jpg"){
  //       y.src= "../assets/images/assets/Machine supply.jpg";

  //     }
  //     else
  //     y.src ="../assets/images/assets/watches.jpg";
  //   }
  change_image: any;
  select_image(path: any) {
    console.log(path);
    this.change_image = path
  }


  async add_activity(detail: any, activity: any) {
    let body = {
      application: "Product",
      component: "Product",
      activity: activity,
      parents: {
        data: detail,

      }
    }
    console.log(body);
    let response = await this.gs.hps.post('generic', 'activity', 'add', body)
    // this.list = response;
    // console.log(this.list);
    this.gs.toast('Add Favourite', ' Successfully')
  }

  params = {
    type: ''
  }

  async report_product() {
    this.form?.markAllAsTouched()
    let body = this.form?.value;
    body.application = 'Generic',
      body.component = 'Feedback',
      body.type = 'report product'
    if (this.form?.valid) {
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.form?.reset();
      this.modal.closeModal();
    }

  }


  async add_conversation(details: any) {
    try {
      let current_user = this.gs.user.users.activeUser;
      let body = {
        subtype: 'personal',
        members: {
          users: {
            [current_user?._id || '']: {
              first_name: current_user?.personal_details.first_name,
              last_name: current_user?.personal_details.last_name,
              profile_picture: current_user?.personal_details.profile_picture,
              emailID: current_user?.contact_details.email_address_primary,
              mobile_number: current_user?.contact_details.mobile_number_primary,
              country_code: current_user?.location_details.country_calling_code,
              _id: current_user?._id
            },
            [details?._id || '']: {
              first_name: details.parents.parent.parents.parent.meta.activity.updated_by.first_name,
              last_name: details.parents.parent.parents.parent.meta.activity.updated_by.last_name,
              _id: details.parents.parent.parents.parent.meta.activity.updated_by?.userId,
            }

          },
        },
      }
      await this.gs.hps.post('generic', 'conversation-group', 'add', body);

    } catch (error: any) {
      this.gs.toast('Security', error?.error?.message, 'error')
    }

  }


  initializaForm() {
    let receiver: any = undefined
    if (this.details?.title) {
      let user_ids = Object.keys(this.details.members.users);
      user_ids = user_ids.filter((u: any) => u != this.gs.user?.users?.activeUser?._id);
      user_ids.forEach(u => {
        receiver = receiver || {}
        receiver.user = receiver.user || {}
        receiver.user[u] = true;
      })
    }

    this.form = this.fb.group({
      application: 'Generic',
      component: 'Conversation',
      type: 'chat',
      sender: {
        user: {
          [this.gs.user?.users?.activeUser?._id || '']: true,
        }
      },
      receiver: receiver || {
        user: {
          [this.details?.receiver?._id || '']: true,
        }
      },
      data: this.fb.group(this.form_data),
      parents: {
        list: this.details?._id
      }
    });

  }


  initialize_form() {
    this.form = this.fb.group({
      application: this.application,
      component: this.component,
      description: [null, this.gs.form_validations.required('Description')],

    });

  }



}
// }
