<div a-style p="3" bg="white" h="100%">
<m-text [type]="gs.enums.texts.heading2" mb="3" text="My Dashboard"></m-text>
<div a-style br="1px solid #e4e4e4" brRadius="0.4" p="3" h="50">
<div a-style mb="3" display=flex justify="space-between">
    <div a-style display="flex" align="center" flexGap="2">
    <m-input  [type]="gs.enums.inputs.dropdown" minWidth="7" maxWidth="7" mb="0" [list]="[1,2,3]" flexGrow=1 px=0 bg="#ffffff"></m-input>
    <m-input  [type]="gs.enums.inputs.dropdown" minWidth="10" maxWidth="10" mb="0" [list]="[1,2,3,4,5]" flexGrow=1 px=0 bg="#ffffff"></m-input>
    <m-input  [type]="gs.enums.inputs.dropdown" minWidth="8" maxWidth="8" mb="0" [list]="[2020,2021,2023]" flexGrow=1 px=0 bg="#ffffff"></m-input>
    <m-button a-style [types]="gs.enums.buttons.button18" text="search"></m-button>
    </div>
    <m-button a-style (click)="check.openModal()" [types]="gs.enums.buttons.button20" text="Checkout"></m-button>
</div>
<div a-style p="3" br="1px solid #e4e4e4" brRadius="0.4" w="29">
    <m-text [type]="gs.enums.texts.paragraph38" mb="3" text="IMAGE"></m-text>
    <m-text [type]="gs.enums.texts.paragraph105" text="1 (Updated)"></m-text>
</div>
</div>
</div>






<m-modal #check>
    <m-check-in></m-check-in>
</m-modal>