import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-product-sidebar',
  templateUrl: './product-sidebar.component.html',
  styles: [
  ]
})
export class ProductSidebarComponent implements OnInit {

  params = {
    type: ' ',
    subtype:'',
    // query: this.form?.value?.text
  }

  constructor( public gs:GlobalService, public ar:ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params
      console.log(this.params)
    })
  }

}
