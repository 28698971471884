import { materialize } from "rxjs-compat/operator/materialize";

export const ATTRIBUTE_GROUP_HEADINGS = [
  'Basic Details',
  'Product Details',
  'Technical Details',
  'Other Details',
  'Payment Details',
  'Material Available Location',
  'Media',
  'Tags',
  'FAQ'
];


export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT = [
  'Basic_Details',
  'Product_Details',
  'Technical_Details',
  'Other_Details',
  'Payment_Details',
  'Material_Available_Location',
  'Media',
  'Tags',
  'FAQ'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_SERVICE = [
  'Basic Details',
  'Service Details',
  'Technical Details',
  'Other Details',
  'Payment Details',
  'Media',
  'Tags',
  'FAQ'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_SERVICE = [
  'Basic_Details',
  'Service_Details',
  'Technical_Details',
  'Other_Details',
  'Payment_Details',
  'Media',
  'Tags',
  'FAQ'
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_RECIPE = [
  'Basic Details',
  'Ingredients list',
  'How To Make',
  'Cuisine',
  'Nutrition Facts',
  'Media',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_RECIPE = [
  'Basic_Details',
  'Ingredients_list',
  'How_To_Make',
  'Cuisine',
  'Nutrition_Facts',
  'Media',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_ARTICLE = [
  'Basic Detail',
  'Media',
  'Preferred Audience',
  'FAQ'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_ARTICLE = [
  'Basic_Detail',
  'Media',
  'Preferred_Audience',
  'FAQ'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_REFURBISHED = [
  'Basic Details',
  'Product Details',
  'Technical Details',
  'Cycling Accessories',
  'Other Details',
  'Payment Details',
  'Material Available Location',
  'Media',
  'Tags',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_REFURBISHED = [
  'Basic_Details',
  'Product_Details',
  'Technical_Details',
  'Cycling_Accessories',
  'Other_Details',
  'Payment_Details',
  'Material_Available_Location',
  'Media',
  'Tags',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_USED = [
  'Basic Details',
  'Material Available Location',
  'Media',
  'Tags',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_USED = [
  'Basic_Details',
  'Material_Available_Location',
  'Media',
  'Tags',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_PROPERTIES = [
  'Basic Details',
  'About Property',
  'Area Details',
  'Pricing Details',
  'Distance Details',
  'Near By Places',
  'Property Location Details',
  'Media',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_PROPERTIES = [
  'Basic_Details',
  'About_Property',
  'Area_Details',
  'Pricing_Details',
  'Distance_Details',
  'Near_By_Places',
  'Property_Location_Details',
  'Media',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_SPIRITUAL = [
  'Basic Details',
  'Reference Documents',
  'Media',
  'Tags',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_SPIRITUAL = [
  'Basic_Details',
  'Reference_Documents',
  'Media',
  'Tags',
];
export const ATTRIBUTE_IMAGES_FOR_EDIT_FOR_SPIRITUAL = [
  '../assets/images/spiritual/Basic_Details_Coloured.svg',
  '../assets/images/spiritual/Referance_Documents_Coloured.svg',
  '../assets/images/spiritual/Media_Coloured.svg',
  '../assets/images/spiritual/Tags_Coloured.svg',
];
export const ATTRIBUTE_IMAGES_FOR_EDIT_FOR_PRODUCT = [
  '../assets/images/spiritual/Basic_Details_Coloured.svg',
  '../assets/images/spiritual/Referance_Documents_Coloured.svg',
  '../assets/images/Icon_Bank/Technical_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Other_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Payment_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Material_Available_Location_Coloured.svg',
  '../assets/images/Icon_Bank/Media_Coloured.svg',
  '../assets/images/Icon_Bank/Tags_Coloured.svg',
  '../assets/images/Icon_Bank/People_Also_Ask_Coloured.svg',
];
export const ATTRIBUTE_IMAGES_FOR_EDIT_FOR_SERVICE = [
  '../assets/images/spiritual/Basic_Details_Coloured.svg',
  '../assets/images/spiritual/Basic_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Technical_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Other_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Payment_Details_Coloured.svg',
  '../assets/images/Icon_Bank/Media_Coloured.svg',
  '../assets/images/Icon_Bank/Tags_Coloured.svg',
  '../assets/images/Icon_Bank/People_Also_Ask_Coloured.svg',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_ANIMAL = [
  'Basic Details',
  'Description',
  'Media',
  'Tags',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_ANIMAL = [
  'Basic_Details',
  'Description',
  'Media',
  'Tags',
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_VEHICLE = [
  'Basic Details',
  'Price Details',
  'Key Specifications',
  'Performance',
  'Design & Build',
  'Comfort',
  'Safety',
  'Others',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_VEHICLE = [
  'Basic_Details',
  'Price_Details',
  'Key_Specifications',
  'Performance',
  'Design_&_Build',
  'Comfort',
  'Safety',
  'Others',

];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_JOB = [
  'Basic Details',
  'Requirements',
  'Preference Details',
  'Work Details',
  'Payment Details',
  'Job Location',
  'Job Description',
  'Role Responsibilities & Duties',
  'Recruitment Process',
  'How To Apply',
  'Interview Location',
  'Interview Time Schedule',
  'Recruiter Details',
  'Work Place Facilities',
  'Media',
  'Tags'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_JOB = [
  'Basic_Details',
  'Requirements',
  'Preference_Details',
  'Key_Specifications',
  'Work_Details',
  'Payment_Details',
  'Job_Location',
  'Job_Description',
  'Role_Responsibilities_&_Duties',
  'Recruitment_Process',
  'How_To_Apply',
  'Interview_Location',
  'Interview_Time_Schedule',
  'Recruiter_Details',
  'Work_Place_Facilities',
  'Media',
  'Tags'
];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_SCRAP = [
  'Basic Details',
  'Product Details',
  'Chemical Analysis',
  'Physical Properties Of Product',
  'Payment Details',
  'Product Delivery Details',
  'Packing Details',
  'Product Features',
  'Product Usage Details',
  'Material Available Location',
  'Media',
  'Tags'

];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_SCRAP = [
  'Basic_Details',
  'Product_Details',
  'Chemical_Analysis',
  'Physical_Properties_Of_Product',
  'Payment_Details',
  'Product_Delivery_Details',
  'Packing_Details',
  'Product_Features',
  'Product_Usage_Details',
  'Material_Available_Location',
  'Media',
  'Tags'

];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_PROJECT = [
  'Basic Details',
  'Short Description',
  'How to Participate',
  'Donation We Collect',
  'Beneficiary Groups',
  'Contact Details',
  'Media'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_PROJECT = [
  'Basic_Details',
  'Short_Description',
  'How_to_Participate',
  'Donation_We_Collect',
  'Beneficiary_Groups',
  'Contact_Details',
  'Media'

];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_NURSERY = [
  'Basic Details',
  'Product Details',
  'Technical Details',
  'Material Available Location',
  'Payment Details',
  'Other Details',
  'Media',
  'Tags'
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_NURSERY = [
  'Basic_Details',
  'Product_Details',
  'Technical_Details',
  'Material_Available_Location',
  'Payment_Details',
  'Other_Details',
  'Media',
  'Tags'

];
export const ATTRIBUTE_GROUP_HEADINGS_FOR_ACTIVITY = [
  'Basic Details',
  'About Activity',
  'Contact Details',
  'Media',
];

export const ATTRIBUTE_GROUP_HEADINGS_FOR_EDIT_FOR_ACTIVITY = [
  'Basic_Details',
  'About_Activity',
  'Contact_Details',
  'Media',
];




