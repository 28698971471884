import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-account-tray',
  templateUrl: './account-tray.component.html',
  styles: [
  ]
})
export class AccountTrayComponent implements OnInit {
  @Output() close_modal = new EventEmitter();
  user_object: any = this.gs.localStorageGet('add-user');
  users: IUserData | any;
  is_visible: boolean;
  first_time = true;
  current: boolean = false;
  _id: any
  user: any
  constructor(public gs: GlobalService, public ar: ActivatedRoute, public router: Router) {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
    })
  }
  async sign_out(id: any) {
    let token = this.users.activeUser?.token + '';
    try {
      await this.gs.hps.post('idm', 'user', 'sign-out', {});
      this.gs.user.remove_user(token);
      // window.location.reload()
      let current = this.gs.localStorageGet('removedUser') || {};
      current[id] = this.users?.activeUser;
      this.gs.localStorageSet('removedUser', current)
      this.current = !this.current
      this.gs.$refresh_removeUser.next()
      delete this.user_object[id]
      this.gs.localStorageSet('add-user', this.user_object)
      this.gs.$refresh_user.next()

    } catch (error) {
      this.gs.user.remove_user(token);
      // console.log(error);

    }
    this.close_modal.emit('Close')
  }
  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
      this._id = this.users.activeUser?._id
    })

    let scroll = document.createElement('style')
    scroll.innerHTML = `#scroll::-webkit-scrollbar {display: none;}`
    document.head.appendChild(scroll);

  }

}

