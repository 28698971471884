
<m-search-header></m-search-header>
<div a-style ml="16">
  <m-generic-list  *ngIf="isSearch && params.application == 'Product'" [global_search]="true"  type="card_1"  [filters]= "{query:params.query,component:params.application}" [(ngModel)]="search" [application]="params.application" [component]="params.application" px="1.2" py="1.2"  flexWrap="wrap"  ></m-generic-list>
  <m-generic-list  *ngIf="isSearch && params.application!='News' && params.application!='Product'" [global_search]="true"  type="generic_card"  [filters]= "{query:params.query,component:params.application}" [(ngModel)]="search" [application]="params.application" [component]="params.application" px="1.2" py="1.2"  flexWrap="wrap"  ></m-generic-list>
  <m-generic-list  *ngIf="isSearch && params.application=='News'" gap="0.5" py="0" [filters]= "{query:params.query,component:params.application}" [news_search]="true"  type="news_card"   [(ngModel)]="search" [application]="params.application" [component]="params.application"  py="1.2"  flexWrap="wrap"  ></m-generic-list>
</div>
  <m-web-search-context  *ngIf="params.application == 'All' " ></m-web-search-context>






  




















<!-- <ng-template #image_count let-passedData="passedData">
    <div a-style display="flex" flexDirection="column">
      <m-image src="../assets/images/assets/s.svg" a-style w="2" h="4"></m-image>
       <m-text [type]="gs.enums.texts.paragraph1" mt="0" text={{passedData.value1}} ></m-text>
    </div>
  </ng-template> -->
<!-- <div a-style display="flex" gap="5.4" px=2.4 pt="2.4"  justify="space-between">
    <m-image src="../assets/images/assets/mesbro_logo.svg" h=4.6 w=auto></m-image>
    <m-input  [formControl]="search"   (postfix_clicked)='onKeyUp($event)' (keydown.enter)="onKeyUp($event)"   src="/assets/images/logo.svg" a-style h="4.6" fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" brRadius="2.5" placeHolder="Search Mesbro or Type a URL " w="71.9" outline=none px=0 a-style postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg"></m-input>
  </div>  -->

  <!-- <div *ngIf="params.application == 'Product' " a-style mt="3">
   <m-text [type]="gs.enums.texts.paragraph123" text="Products"></m-text> 
   <div class="no_scroll_bar" a-style display="flex" flexWrap="nowrap" overflowX="auto" gap="1"> 
  </div> 
</div> -->

<!-- <m-generic-list  type="product_card_5" [global_search]=true [filters]="query : params.query , component : params.application" [application]="params.application" [component]="params.application"></m-generic-list> -->