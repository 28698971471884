<m-form [formGroup]="form" brNone>
    <div formGroupName="basicDetails">
        <m-input [type]="gs.enums.inputs.text" formControlName="first_name" label="First Name"></m-input>
        <m-input [type]="gs.enums.inputs.text" formControlName="last_name" label="Last Name"></m-input>
        <m-input [type]="gs.enums.inputs.text" formControlName="mobile" label="Mobile No."></m-input>
        <m-input [type]="gs.enums.inputs.text" formControlName="country_code" label="Country Code"></m-input>
        <m-input [type]="gs.enums.inputs.media" formControlName="profile" label="Profile Photo" description="Upload the profile picture."></m-input>
    </div>        
</m-form>
<div a-style ml=2.4>
    <m-button [types]="gs.enums.buttons.button79" text="Add Contact" (click)="submit()"></m-button>
</div>