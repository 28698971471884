<div a-style mt=7.4>
  <m-form title_mt="1.5" ml=2.4 title_mb="1.5" fs="1.6" paddingNone labelWeight="500" mx="2.4" my="2.4" w="initial" pb="1.5">
    <!-- <hr a-style color="#e4e4e4" w="100%" mb="1"> -->
    <div a-style ml="1.4">
      <m-input flexGrow=none label="Search" [columns]="1/4"  cleareIcon="true"></m-input>
    </div>
    
    <table a-style mx="1" mt="2.4" p="1.4" br="none">
      <tr>
        <th a-style align="center" br="1px solid #e4e4e4" py="1">Sr No</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1">{{query_params?.component}} Image</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1">{{query_params?.component}} Name</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1">Organization Name</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1" *ngIf="query_params.status=='Pending' || query_params.status== 'Reported'">Approve</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1" *ngIf="query_params.status=='Pending'">Reject</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1" *ngIf="query_params.status =='Pending' || query_params.status =='Reported' || query_params.status =='Approved'">Block</th>
        <th a-style align="center" br="1px solid #e4e4e4" py="1" *ngIf="query_params.status =='Blocked'">Unblock</th>
      </tr>
      <tr *ngFor="let item of list?.list let i=index">
        <td a-style align="center" br="1px solid #e4e4e4">{{i+1}} </td>
        <td a-style align="center" br="1px solid #e4e4e4"><m-image src='https://new-api.mesbro.com/uploads/{{item?.profile_picture || item?.data.image}}' w="11.6" h="11.6" my="1.6" mx="2.6"> </m-image></td>
        <td a-style align="center" br="1px solid #e4e4e4">{{item.title || item.data.brand}}</td>
        <td a-style align="center" br="1px solid #e4e4e4">{{item?.parents?.group?.title}}</td>
        <td a-style align="center" br="1px solid #e4e4e4" *ngIf="query_params.status=='Pending' || query_params.status== 'Reported'">
          <m-button [types]="gs.enums.buttons.button9" (click)="change_status(item._id,'Approve')" text="Approve"></m-button>
        </td>
        <td a-style align="center" br="1px solid #e4e4e4" *ngIf="query_params.status=='Pending'">
          <m-button [types]="gs.enums.buttons.button68"  (click)="change_status(item._id,'Reject')" text="Reject"></m-button>
        </td>
        <td a-style align="center" br="1px solid #e4e4e4" *ngIf="query_params.status =='Pending' || query_params.status =='Reported' || query_params.status =='Approved'">
          <m-button [types]="gs.enums.buttons.button68" (click)="change_status(item._id,'Block')" text="Block"></m-button>
        </td>
        <td a-style align="center" br="1px solid #e4e4e4" *ngIf="query_params.status =='Blocked'">
          <m-button [types]="gs.enums.buttons.button9" (click)="change_status(item._id,'Unblock')" text="Unblock"></m-button>
        </td>
      </tr>
    </table>
  </m-form>
</div>