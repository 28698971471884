<!-- Group_Card_1 -->
<ng-container *ngIf="type =='Card_1'" [ngTemplateOutlet]="Card_1"></ng-container>
<!-- Group_Card_2 -->
<ng-container *ngIf="type =='Card_2'" [ngTemplateOutlet]="Card_2"></ng-container>
<!-- Group_Card_3 -->
<ng-container *ngIf="type =='Card_3'" [ngTemplateOutlet]="Card_3"></ng-container>
<!-- Group_Card_4 -->
<ng-container *ngIf="type =='card_4'" [ngTemplateOutlet]="card_4"></ng-container>


<ng-template #Card_1 let-passedData="passedData">
    <div a-style h="17.4" cursor="pointer" br="1px solid #e4e4e4" mb="2.4" brRadius="0.6" ml="2.4;525:1"
        shadow=" hov:0 3px 6px 0 #f1f1f1" maxWidth="43.2" minWidth="43.2" p="1" flexGrow="1" brRadius="0.4">
        <div a-style display="flex" justify="space-between">
            <div>
                <m-text [type]="gs.enums.texts.paragraph43" a-style text={{detail?.basic_details?.legal_name}}
                    style="overflow: hidden;text-overflow: ellipsis;max-width: 340.4;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;">
                </m-text>
                <m-text [type]="gs.enums.texts.paragraph38" mt="1.3" text='Status :' + detail?.meta?.status></m-text>
                <m-text [type]="gs.enums.texts.paragraph38" mt="1.3" text='Calling Pin :' +
                    detail?.security?.calling_pin></m-text>
                <m-text [type]="gs.enums.texts.paragraph38" mt="1.3" text='MBIN :' + detail?.meta?.support?.mbin>
                </m-text>
            </div>
            <div>
                <m-image *ngIf="detail?.files?.profile_picture"
                    [src]="'https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture" w="10"> </m-image>
            </div>
        </div>
        <div a-style display="flex" justify="space-between" mt="1.3">
            <m-text [type]="gs.enums.texts.paragraph38" text='Validity :' +
                detail?.meta?.package?.expiry?(detail?.meta?.package?.expiry : 0)></m-text>
            <div>
                <m-image [src]="gs.enums.images.image299" routerLink="/dashboard/group/form"
                    [queryParams]="{_id : detail._id}" w="1.8" h="1.8"> </m-image>
                <m-image [src]="gs.enums.images.image68" routerLink="/dashboard/group/form"
                    [queryParams]="{_id : detail._id}" (click)="delete.emit(detail._id)" w="1.8" h="1.8" ml="1.5">
                </m-image>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #Card_2 let-passedData="passedData">
    <ng-template #status_info let-pass_data=pass_data>
        <div a-style display="flex" flexGap="1.2" pt="1.2">
            <m-image src="{{pass_data.src}}" w="1.6" title=""></m-image>
            <m-text [type]="gs.enums.texts.paragraph35" text={{pass_data.text}}></m-text>
        </div>
    </ng-template>
    <div bg=white cursor="pointer" *ngIf="type =='Card_2'" brRadius="0.6" shadow=" hov:0 3px 6px 0 #f1f1f1"
        cursor="pointer" flexGrow="1" maxWidth=40.5 minWidth=40.5 a-style display="flex" justify=space-between
        br="1px solid #e2e2e2" p="1.2" brRadius="0.5">
        <div routerLink='/dashboard/group/form' [queryParams]="{_id : detail?._id }">
            <m-text [type]="gs.enums.texts.paragraph26" text={{detail?.basic_details?.legal_name}}
                title={{detail?.basic_details?.legal_name}} textCenter=initial ellipsis=ellipsis w=25 maxWidth="35.1"
                mb="1.2"></m-text>
            <ng-container [ngTemplateOutlet]="status_info"
                [ngTemplateOutletContext]="{pass_data:{src:'../assets/images/assets/group_status.svg',text:'Status :'+' '+ detail?.meta?.status}}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="status_info"
                [ngTemplateOutletContext]="{pass_data:{src:'../assets/images/assets/group_calling.svg',text:'Calling Pin :'+' '+ detail?.security?.calling_pin}}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="status_info"
                [ngTemplateOutletContext]="{pass_data:{src:'../assets/images/assets/group_mbin.svg',text:'MBIN :'+' '+ detail?.meta?.support?.mbin}}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="status_info"
                [ngTemplateOutletContext]="{pass_data:{src:'../assets/images/assets/group_validity.svg',text:'Validity :'+' ' + detail?.meta?.package?.expiry}}">
            </ng-container>
            <!-- <ng-container [ngTemplateOutlet]="HeaderTabs" [ngTemplateOutletContext]="{passedData : {label : 'History', icon : '../assets/images/assets/history grey.svg',hov_icon : '../assets/images/assets/historyhover.svg', link:'/product/history', queryParams :{type:'product'},color:'#333333', title:title.History_title }}"></ng-container> -->

        </div>
        <div a-style display="flex" flexDirection="column" justify="space-between" alignItems="flex-end">
            <img [src]="'https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture" a-style w="10" h="10"
                routerLink='/dashboard/group/form' [queryParams]="{_id : detail?._id}">
            <div a-style display="flex" align="center">
                <div a-style align="center" display="flex" gap="1.5">

                    <m-image src="../assets/images/assets/edit colour.svg" routerLink="/dashboard/group/form"
                        title="Edit" [queryParams]="{_id : detail._id}" w="1.8" h="1.8"> </m-image>
                    <m-image src="../assets/images/assets/payment.svg" routerLink="/dashboard/payment/feature"
                        title="Payment" [queryParams]="{group_id : detail._id}" w="1.8" h="2"> </m-image>
                    <!-- <m-text [type]="gs.enums.texts.paragraph9" routerLink="/dashboard/payment/feature" mb=0 [queryParams]="{group_id : detail._id}" text="Pay" ></m-text> -->
                </div>
                <m-image src="../assets/images/assets/delete colour.svg" title="Delete"
                    (click)="delete.emit(detail._id)" w="1.8" h="2" ml="1.5"> </m-image>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #Card_3>
    <div a-style display="flex" (click)="redirect_to_detail(detail)" w="100%" mb="2.4" flexGap="2.4" align="center">
        <m-input formControlName="product_list" type="checkbox" a-style mt="0" [columns]=false onlyInput=true></m-input>
        <div a-style display="flex" p="1.5" justify="space-between" shadow=" hov:0 3px 6px 0 #f1f1f1" brRadius="0.6"
            br="1px solid #e2e2e2" w="100%">
            <div a-style display="flex" gap="1">
                <m-image a-style w="12.5" src="https://new-api.mesbro.com/uploads/{{detail?.files?.profile_picture}}">
                </m-image>
                <ng-container [ngTemplateOutlet]="verticaldata"
                    [ngTemplateOutletContext]="{passedData : {text : detail?.basic_details?.legal_name , text2:detail?.basic_details?.type}}">
                </ng-container>
            </div>
            <ng-container
                *ngFor="let item of [{text :'Date & Time' , text5:detail?.meta?.activity.updated_at | date :'dd MMM yyyy'},{text :'language'  , text2:detail?.language },{text : 'Device' , text2:detail.device?.device?.type | titlecase}, {text : 'Location' , text2:detail?.parents?.group?.location?.primary_address?.city}]"
                [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : item}"></ng-container>
            <div>
                <m-image a-style w="1.5" [src]="gs.enums.images.image26"> </m-image>
            </div>
        </div>
    </div>
    <ng-template #verticaldata let-passedData="passedData">
        <div a-style display="flex" flexDirection="column" maxWidth="25">
            <m-text [type]="gs.enums.texts.paragraph9" mb="0" a-style text="{{passedData.text}}" align="left"></m-text>
            <m-text [type]="gs.enums.texts.paragraph23" a-style text="{{passedData.text5}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph29" text="{{passedData.text2}}" align="left"></m-text>

            <m-text [type]="gs.enums.texts.paragraph9" a-style text="{{passedData.text3}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph9" a-style text="{{passedData.text4}}"></m-text>
        </div>
    </ng-template>
    <!-- {{detail | json}} -->
</ng-template>

<ng-template #card_4>
    <!-- <div a-style cursor="pointer" shadow=" hov:0 3px 6px 0 #f1f1f1" br="1px solid #e4e4e4" brRadius="0.6" maxWidth=23.7
        minWidth=23.7 p=1>
        <div a-style w=100% position="relative">
            <div>
                <m-image
                    [src]="detail?.files?.profile_picture ? ('https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture) : default_image"
                    a-style h="20.8" w="100%" w="20.8" [title]="detail?.title" (click)="redirect_to_detail(detail)"
                    brRadius="0.6">
                </m-image>
            </div>
            <div a-style brBottom="1px solid #e4e4e4" pb="0.5">
                <m-text [type]="gs.enums.texts.paragraph110" textWrap="wrap" textCenter="center" a-style
                    [text]="detail?.basic_details?.type" w="20" class="lineClamp" (click)="redirect_to_detail(detail)"
                    color="#8a8383;hov:#1d8ecf"></m-text>
                <m-text [type]="gs.enums.texts.paragraph131" textCenter="center" a-style w=13 ellipsis=true
                    text="{{detail?.meta?.support?.mbin}}" (click)="redirect_to_detail(detail)"
                    color="#c87065;hov:#C8A165"></m-text>
            </div>
            <div a-style display="flex" justify="center" mt="0.9" align="center">
                <m-image src="../assets/images/assets/user (14).svg " a-style w="0.6" h="0.8" mr="0.5"></m-image>
                <m-text [type]="gs.enums.texts.paragraph110" text="{{detail?.basic_details?.legal_name}}"></m-text>
            </div>
            <div a-style display="flex" justify="center">
                <m-text [type]="gs.enums.texts.paragraph10"
                    [text]="detail?.location?.primary_address?.city+ ',' +detail?.location?.primary_address?.region+','+detail?.location?.primary_address?.country"
                    textCenter="center"></m-text>,
            </div>
        </div>
    </div> -->

    <!-- <div a-style p=1 br="0.5px solid #E4E4E4" brRadius="0.6" w=24.2 h=34 [mt]="mt?mt:2.4">
        <div a-style h=22.2 w=22.2>
            <m-image  h=100% w=100% brRadius="0.6"
                [src]="detail?.files?.profile_picture ? 'https://new-api.mesbro.com/uploads/'+ detail?.files?.profile_picture : default_image">
            </m-image>
        </div>
        <div a-style h=1.8 w=22.2 textCenter="center" mt=1>
            <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=1.1 class=lineClamp
                text="Validity: 1 Jan 2023" ></m-text>
        </div>
        <div a-style h=1.8 w=22.2 textCenter="center" mb="0.5">
            <m-text [type]="gs.enums.texts.paragraph131" fs="1.1" color="#2479AB" ellipsis="true"
                [text]="'MBIN: '+ detail?.meta?.support?.mbin">
            </m-text>
        </div>
        <hr color="#e4e4e4" size="0.5">
        <div a-style mt=1 h=1.8 textCenter=center display="flex" justify="center" align="center">
          <m-image src="../assets\images\assets\verified.svg" w=12px h=11px></m-image>
            <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=1.1 ellipsis="true"
                [text]="detail?.basic_details?.legal_name ? detail?.basic_details?.legal_name : ''">
            </m-text>
        </div>
        <div a-style h=1.6 display="flex" justify=center gap=0.4>
            <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                [text]="detail?.location?.primary_address?.city ? detail?.location?.primary_address?.city+ ', ' : '' "
                 textCenter=center></m-text>
            <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                [text]="detail?.location?.primary_address?.region ? detail?.location?.primary_address?.region+', ' :''"
                 textCenter=center> </m-text>
            <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                [text]="detail?.location?.primary_address?.country ? detail?.location?.primary_address?.country :''"
                 textCenter=center>
            </m-text>

        </div>
        <div *ngIf="gs.router.url.includes('/product/history')"  a-style mt="1.4" display="flex" justify="space-between" >
          <div a-style display="flex" gap=1>
          <m-image src="../assets\images\assets\eye1.svg" w=14px h=9px></m-image>
          <p a-style color="#707070" fs="9px"  h=12px>22 sep 2021 00:00AM</p>
        </div>
          <div >
          <m-image src="../assets\images\assets\delete1.svg" w=16px h=16px></m-image>
        </div>
        </div>

    </div> -->

    <!-- 
    <div>
        <div class="MagicScroll" a-style p=1 w="24" h="30" br="0.5px solid #E4E4E4" brRadius="0.6" bg="#FFFFFF;hov:#333333">
          <div a-style position="relative">
            <m-image (click)="redirect_to_detail(detail)" h=22 w=22 brRadius="0.6"
              [src]="detail?.files?.profile_picture ? 'https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture : default_image">
            </m-image>
            <m-image [src]="gs.enums.images.image62" a-style title="India" a-style w=2.5 h=2.5 top=0.5 right="0.5"
              position="absolute"> </m-image>
            <m-image *ngIf="false" src="../assets/images/assets/product/ad.svg" a-style w=2.6 h=1.6 title="Compare" a-style
              w=2.5 h=2.5 top=0.5 left="0.9" position="absolute"> </m-image>
          </div>
          <div a-style h=1.8 textCenter=center align="center" gap="0.3" justify="center">
            <div a-style h=1.8 textCenter=center align="center" gap="0.3" justify="center" display="flex">
              <m-image src="../assets/images/assets/verified_user.svg"></m-image>
              <m-text *ngIf="detail?.basic_details?.legal_name" [type]="gs.enums.texts.paragraph129"
                color="#333333" fs=1.1 ellipsis="true" [text]="detail?.basic_details?.legal_name"
                (click)="redirect_to_detail(detail)">
              </m-text>
            </div>
            <m-text [type]="gs.enums.texts.paragraph131" fs="1.1" color="#2479AB" ellipsis="true"
              [text]="'MBIN: '+ detail?.meta?.support?.mbin">
            </m-text>
            <div a-style h=1.6 display="flex" justify=center gap=0.4>
              <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                [text]="detail?.location?.city ? (detail?.location?.city+ ', ') : ''"
                (click)="redirect_to_detail(detail)" textCenter=center></m-text>
              <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                [text]="detail?.location?.region ? (detail?.location?.region+', '):''"
                (click)="redirect_to_detail(detail)" textCenter=center> </m-text>
            </div>
          </div>
        </div>
        <div a-style mt="1.2" display="flex" justify="space-between">
          <div a-style display="flex" gap=1>
            <m-image src="../assets\images\assets\eye1.svg" w=14px h=9px></m-image>
            <p a-style color="#707070" fs="9px" h=12px>22 sep 2021 00:00AM</p>
          </div>
          <div (click)="delete.emit(detail._id)">
            <m-image src="../assets\images\assets\delete1.svg" w=16px h=16px></m-image>
          </div>
        </div>
      </div> -->

    <div>
        <!-- <div class="MagicScroll" a-style p=1 w="24.7" br="0.5px solid #E4E4E4" brRadius="0.6" -->
        <div (click)="redirect_to_detail(detail)" class="MagicScroll" a-style p=1 w="24.7"  br="0.5px solid #E4E4E4" brRadius="0.6"
            bg="#FFFFFF;hov:#333333">
            <div a-style>
                <m-image h=22 w=22 brRadius="0.6"
                    [src]="detail?.files?.profile_picture ? 'https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture : default_image">
                </m-image>
            </div>
            <div a-style  textCenter=center align="center" gap="0.3" justify="center">
                <div a-style  textCenter=center align="center" gap="0.5" justify="center" display="flex">
                    <m-image src="../assets/images/assets/verified_user.svg"></m-image>
                    <m-text *ngIf="detail?.basic_details?.legal_name" [type]="gs.enums.texts.paragraph129"
                        color="#333333" fs=1.1 ellipsis="true" [text]="detail?.basic_details?.legal_name"
                       >
                    </m-text>
                </div>
                <m-text [type]="gs.enums.texts.paragraph138" fs="1.1" color="#2479AB" ellipsis="true"
                    [text]="'MBIN: '+ detail?.meta?.support?.mbin">
                </m-text>
                <!-- {{detail?.location | json}} -->
                <div a-style  display="flex" justify=center gap=0.4 >
                    <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                        [text]="detail?.location?.city ? (detail?.location?.city+ ', ') : ''"
                        textCenter=center></m-text>
                    <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true"
                        [text]="detail?.location?.region ? (detail?.location?.region+' '):''"
                        textCenter=center> </m-text>
                </div>
            </div>
        </div>
    </div>
</ng-template>