<div  a-style>
    <form [formGroup]="form">
        <p a-style fs=1.3 mb="1">Enter Verification Code</p>
        <m-form columns="12" [brNone]=false [paddingNone]=false>
            <div a-style display="flex" align="center" gap=2.4 >
                <div a-style align="left" mt="1.2">
                    <m-input px="0" pr=0 [type]="gs.enums.inputs.password" formControlName="otp" [otp]=true minWidth="15" maxWidth="15"
                         prefix_text='M :' placeHolder="Enter 6 digit"  [uppercases]=false
                        clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" [error]="error"
                        [disable]="form?.invalid" w="15" outer_pr="0" fs="1.3" p="0"></m-input>
                </div>
                <div a-style display=flex gap=4.7>
                    <p fs=1.3 color=#2479AB a-style (click)="verify_otp()"> Verify</p>
                <p fs=1.3 color=#2479AB  a-style (click)="resendOTP()">Resend</p>
                <p fs=1.3 color=#2479AB  a-style w=8>Call instead</p>
                </div>
            </div>
        </m-form>
    </form>
    <p a-style fs=1.3 align="center" mt=2.4>Your personal information is private & safe with Mesbro.</p>
    <m-text align="center" [type]="gs.enums.texts.paragraph72" text="{{showMyMessage}}" mt="0.8" mb="2"></m-text>
    <m-text *ngIf="params?.type=='sign-up'" routerLink="/idm/sign-up"
        [queryParams]="{ type : 'change-account-details' }" [type]="gs.enums.texts.paragraph2" textCenter="center"
        mb="2" a-style align="center" cursor="pointer" text="Change account details" (click)="resendOTP()"></m-text>
</div>