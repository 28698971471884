import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-image',
  templateUrl: './image.component.html',
  styles: [
  ]
})
export class ImageComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'

  @Output() clicked = new EventEmitter()

  @Input() h: any = '';
  @Input() w:any = "auto";
  @Input() notificationDot = '';
  @Input() dotColor = '';
  @Input() notificationCount = '';
  @Input() text = '';
  @Input() type = '';
  @Input() src = '';
  @Input() ml = '';
  @Input() mx = '';
  @Input() mt = '';
  @Input() px = '';
  @Input() mb = '';

  @Input() bgRepeat = '';
  @Input() bg = '';
  @Input() bgSize = '';
  @Input() zIndex = '';

  @Input() mr = '';
  @Input() top = '';
  @Input() bottom = '';
  @Input() left = '';
  @Input() align = '';
  @Input() position = '';
  @Input() br = '';
  @Input() brRadius = '';
  @Input() pl = '';
  @Input() pr = '';
  @Input() maxWidth = '';
  @Input() minWidth = '';
  @Input() textCenter = '';
  @Input() justify = '';

  @Input() shadow: string = "";
  @Input() right = '';
  @Input() title: any;
  @Input() hover: any;
  mouse_over: boolean = false;
  @Input() current_value = false;
  @Input() brTopLeftRadius: any;
  @Input() brTopRightRadius: any;

  constructor(public gs: GlobalService,) { }

  ngOnInit(): void {
  }

}
