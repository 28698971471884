import { OrganizationConversationComponent } from './../modules/shared/pages/organization-conversation/organization-conversation.component';
import { GenericSettingComponent } from './../modules/shared/components/generic-setting/generic-setting.component';
import { DashboardHomeComponent } from './../modules/shared/pages/dashboard-home/dashboard-home.component';
import { CommanListComponent } from './../modules/shared/pages/comman-list/comman-list.component';
import { StatusListComponent } from './../modules/shared/pages/status-list/status-list.component';
import { PolicesComponent } from './../modules/shared/pages/polices/polices.component';
import { Routes } from "@angular/router";
import { CommonLayoutComponent } from "../modules/shared/components/common-layout/common-layout.component";
import { DashboardLayoutComponent } from "../modules/shared/components/dashboard-layout/dashboard-layout.component";
import { GenericDetailsComponent } from "../modules/shared/pages/generic-details/generic-details.component";
import { GenericFormComponent } from "../modules/shared/pages/generic-form/generic-form.component";
import { HelpComponent } from "../modules/shared/pages/help/help.component";
import { MesbroHomeComponent } from "../modules/shared/pages/mesbro-home/mesbro-home.component";
import { FaqComponent } from '../modules/shared/pages/faq/faq.component';
import { ImageCropprComponent } from '../modules/shared/components/image-croppr/image-croppr.component';
import { ErrorPageComponent } from '../modules/shared/pages/error-page/error-page.component';
import { SuccessPageComponent } from '../modules/shared/pages/success-page/success-page.component';
import { FileManagerModalComponent } from '../applications/file-manager/modals/file-manager-modal/file-manager-modal.component';
import { ReviewListComponent } from '../modules/shared/pages/review-list/review-list.component';
import { DomianListComponent } from '../modules/shared/pages/domian-list/domian-list.component';
import { UserListComponent } from '../modules/shared/pages/user-list/user-list.component';
import { TempConversationComponent } from '../modules/shared/components/temp-conversation/temp-conversation.component';
import { GroupListComponent } from '../applications/organization/pages/group-list/group-list.component';
import { FeedbackListComponent } from '../modules/shared/components/feedback-list/feedback-list.component';
import { SentEnquiriesComponent } from '../modules/shared/components/sent-enquiries/sent-enquiries.component';
import { InvoiceComponent } from '../modules/shared/components/invoice/invoice.component';
import { ApplicationListComponent } from '../modules/shared/modals/application-list/application-list.component';
import { CollaboratorComponent } from '../modules/shared/modals/collaborator/collaborator.component';
import { LeadsListComponent } from '../modules/shared/pages/leads-list/leads-list.component';
import { GenericFeedbackListComponent } from '../modules/shared/pages/generic-feedback-list/generic-feedback-list.component';
import { ContentFormComponent } from '../modules/shared/pages/content-form/content-form.component';
import { ProductFeedbackComponent } from '../modules/shared/components/product-feedback/product-feedback.component';
import { GenericSearchComponent } from '../modules/shared/generic-search/generic-search.component';
import { PrivilegeFormComponent } from '../modules/shared/pages/privilege-form/privilege-form.component';
import { PrivilegeListComponent } from '../modules/shared/pages/privilege-list/privilege-list.component';
import { MyProfileFormComponent } from '../applications/idm/pages/my-profile-form/my-profile-form.component';
import { SliderComponent } from '../modules/shared/modals/slider/slider.component';
import { WebSearchContextComponent } from '../modules/shared/components/web-search-context/web-search-context.component';
import { AddUserComponent } from '../applications/organization/modals/add-user/add-user.component';
import { CampaignFormComponent } from '../modules/shared/components/campaign-form/campaign-form.component';
import { CampaignListComponent } from '../modules/shared/components/campaign-list/campaign-list.component';
import { ShortlinkListComponent } from '../modules/shared/components/shortlink-list/shortlink-list.component';
import { ShortlinkFormComponent } from '../modules/shared/components/shortlink-form/shortlink-form.component';
import { GenericContactsComponent } from '../modules/shared/components/generic-contacts/generic-contacts.component';
import { GenericContactListComponent } from '../modules/shared/components/generic-contact-list/generic-contact-list.component';
import { ReviewComponent } from '../modules/shared/pages/review/review.component';
import { GenericDashboardComponent } from '../applications/organization/pages/generic-dashboard/generic-dashboard.component';
import { QuotenowBannerComponent } from '../modules/shared/pages/quotenow-banner/quotenow-banner.component';
import { NewInputComponent } from '../modules/shared/components/new-input/new-input.component';
import { ReferanceInputsComponent } from '../pages/referance-inputs/referance-inputs.component';
import { CardsComponent } from '../modules/shared/components/cards/cards.component';
import { ProductGallaryComponent } from '../applications/product/pages/product-gallary/product-gallary.component';

let allPaths = [
    { path: 'product', loadChildren: () => import('../applications/product/product.module').then((m) => m.ProductModule) },
    { path: 'service', loadChildren: () => import('../applications/mesbro-service/mesbro-service.module').then((m) => m.MesbroServiceModule) },
    { path: 'recipe', loadChildren: () => import('../applications/mesbro-recipe/mesbro-recipe.module').then((m) => m.MesbroRecipeModule) },
    { path: 'article', loadChildren: () => import('../applications/article/article.module').then((m) => m.ArticleModule) },
    { path: 'jobs', loadChildren: () => import('../applications/jobs/jobs.module').then((m) => m.JobsModule) },
    { path: 'scrap', loadChildren: () => import('../applications/scrap/scrap.module').then((m) => m.ScrapModule) },
    // { path: 'refurbished', loadChildren: () => import('../applications/refurbished/refurbished.module').then((m) => m.RefurbishedModule) },
    { path: 'used', loadChildren: () => import('../applications/used/used.module').then((m) => m.UsedModule) },
    { path: 'properties', loadChildren: () => import('../applications/properties/properties.module').then((m) => m.PropertiesModule) },
    { path: 'vehicles', loadChildren: () => import('../applications/vehicles/vehicles.module').then((m) => m.VehiclesModule) },
    { path: 'nursery', loadChildren: () => import('../applications/nursery/nursery.module').then((m) => m.NurseryModule) },
    { path: 'guardian', loadChildren: () => import('../applications/guardian/guardian.module').then((m) => m.GuardianModule) },
    // notworking
    { path: 'activities', loadChildren: () => import('../applications/activites/activites.module').then((m) => m.ActivitesModule) },
    { path: 'project', loadChildren: () => import('../applications/project/project.module').then((m) => m.ProjectModule) },
    { path: 'spiritual', loadChildren: () => import('../applications/spiritual/spiritual.module').then((m) => m.SpiritualModule) },

    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'generic', loadChildren: () => import('../applications/generic/generic-application.module').then((m) => m.GenericApplicationModule) },
    { path: 'attribute', loadChildren: () => import('../applications/attribute/attribute.module').then((m) => m.AttributeModule) },
    { path: 'group', loadChildren: () => import('../applications/organization/organization.module').then((m) => m.OrganizationModule) },
    { path: 'payment', loadChildren: () => import('../applications/payment/payment.module').then((m) => m.PaymentModule) },
    { path: 'idm', loadChildren: () => import('../applications/idm/idm.module').then((m) => m.IdmModule) },
    { path: 'mail', loadChildren: () => import('../applications/mail/mail.module').then((m) => m.MailModule) },
    { path: 'short-link', loadChildren: () => import('../applications/short-link/short-link.module').then((m) => m.ShortLinkModule) },
    { path: 'chat', loadChildren: () => import('../applications/chat/chat.module').then((m) => m.ChatModule) },
    { path: 'calculator', loadChildren: () => import('../applications/calculator/calculator.module').then((m) => m.CalculatorModule) },
    { path: 'keep', loadChildren: () => import('../applications/keep/keep.module').then((m) => m.KeepModule) },
    { path: 'currency', loadChildren: () => import('../applications/currency/currency.module').then((m) => m.CurrencyModule) },
    { path: 'translator', loadChildren: () => import('../applications/translator/translator.module').then((m) => m.TranslatorModule) },
    { path: 'dictionary', loadChildren: () => import('../applications/dictionary/dictionary.module').then((m) => m.DictionaryModule) },
    { path: 'file-manager', loadChildren: () => import('../applications/file-manager/file-manager.module').then((m) => m.FileManagerModule) },
    { path: 'master', loadChildren: () => import('../applications/masters/masters.module').then((m) => m.MastersModule) },
    { path: 'transfer', loadChildren: () => import('../applications/file-transfer/file-transfer.module').then((m) => m.FileTransferModule) },
    { path: 'videos', loadChildren: () => import('../applications/videos/videos.module').then((m) => m.VideosModule) },
    { path: 'drive', loadChildren: () => import('../applications/drive/drive.module').then((m) => m.DriveModule) },
    { path: 'news', loadChildren: () => import('../applications/news/news.module').then((m) => m.NewsModule) },
    { path: 'weather', loadChildren: () => import('../applications/weather/weather.module').then((m) => m.WeatherModule) },
    { path: 'thought', loadChildren: () => import('../applications/thoughts/thoughts.module').then((m) => m.ThoughtsModule) },
    { path: 'jokes', loadChildren: () => import('../applications/jokes/jokes.module').then((m) => m.JokesModule) },
    { path: 'subsidy', loadChildren: () => import('../applications/subsidy/subsidy.module').then((m) => m.SubsidyModule) },
    { path: 'new-chat', loadChildren: () => import('../applications/new-chat/new-chat.module').then((m) => m.NewChatModule) },
    { path: 'center', loadChildren: () => import('../applications/help-center/help-center.module').then((m) => m.HelpCenterModule) },
    { path: 'mesbro-central', loadChildren: () => import('../applications/mesbro-central/mesbro-central.module').then((m) => m.MesbroCentralModule) },
    { path: 'org', loadChildren: () => import('../applications/new-organization/organization.module').then((m) => m.OrganizationModule) },
    { path: 'contact', loadChildren: () => import('../applications/contact/contact.module').then((m) => m.ContactModule) },
    { path: 'me', loadChildren: () => import('../applications/me/me.module').then((m) => m.MeModule) },
    { path: 'website', loadChildren: () => import('../applications/website/website.module').then((m) => m.WebsiteModule) },
    // { path: 'social', loadChildren: () => import('../applications/mesbro-social/mesbro-social.module').then((m) => m.MesbroSocialModule) },
    { path: 'social', loadChildren: () => import('../applications/social/social.module').then((m) => m.SocialModule) },
    { path: 'landing-page', loadChildren: () => import('../applications/landing-page/landing-page.module').then((m) => m.LandingPageModule) },

    { path: 'home', component: MesbroHomeComponent },
    { path: 'generic/form', component: GenericFormComponent },
    { path: 'generic/detail', component: GenericDetailsComponent },
    { path: 'generic/list', component: GroupListComponent },
    { path: 'help', component: HelpComponent },
    { path: 'polices', component: PolicesComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'image-corppr', component: ImageCropprComponent },
    { path: 'error-page', component: ErrorPageComponent },
    { path: 'success-page', component: SuccessPageComponent },
    { path: 'domain/list', component: DomianListComponent },
    { path: 'user/list', component: UserListComponent },
    { path: 'comman/list', component: CommanListComponent },
    { path: 'file-manager-modal', component: FileManagerModalComponent },
    { path: 'success-page', component: SuccessPageComponent },
    { path: 'file-manager-modal', component: FileManagerModalComponent },
    { path: 'review-list', component: ReviewListComponent },
    { path: 'success-page', component: SuccessPageComponent },
    { path: 'domain/list', component: DomianListComponent },
    { path: 'product-status', component: StatusListComponent },
    { path: 'conversation', component: TempConversationComponent },
    { path: 'file-manager-modal', component: FileManagerModalComponent },
    { path: 'invoice', component: InvoiceComponent },
    { path: 'feedback/list', component: FeedbackListComponent },
    { path: 'sent-enquiries', component: SentEnquiriesComponent },
    { path: 'app-list', component: ApplicationListComponent },
    { path: 'collaborator', component: CollaboratorComponent },
    { path: 'dashboard-home', component: DashboardHomeComponent },
    { path: 'leads/list', component: LeadsListComponent },
    { path: 'generic-feedback/list', component: GenericFeedbackListComponent },
    { path: 'content/form', component: ContentFormComponent },
    { path: 'generic/search', component: GenericSearchComponent },
    { path: 'product/feedback/list', component: ProductFeedbackComponent },
    { path: 'privilege-user/form', component: PrivilegeFormComponent },
    { path: 'privilege-user/list', component: PrivilegeListComponent },
    { path: 'profile/form', component: MyProfileFormComponent },
    { path: 'slider', component: SliderComponent },
    { path: 'add/user', component: AddUserComponent },
    { path: 'web/search', component: WebSearchContextComponent },
    { path: 'campaign/form', component: CampaignFormComponent },
    { path: 'campaign/list', component: CampaignListComponent },
    { path: 'shortlink/form', component: ShortlinkFormComponent },
    { path: 'shortlink/list', component: ShortlinkListComponent },
    { path: 'contact/form', component: GenericContactsComponent },
    { path: 'contact/list', component: GenericContactListComponent },
    { path: 'review', component: ReviewComponent },
    { path: 'quotenow', component: QuotenowBannerComponent },
    { path: 'generic/setting', component: GenericSettingComponent },
    { path: 'organization/conversation', component: OrganizationConversationComponent },
    { path: 'input', component: NewInputComponent },
    { path: 'referance-input', component: ReferanceInputsComponent },
    { path: 'cards', component: CardsComponent },
    { path: 'generic/gallary', component: ProductGallaryComponent },

]

export const mesbroRoutes: Routes = [
    {
        path: 'dashboard', component: DashboardLayoutComponent, children: allPaths
    },
    {
        path: 'gdashboard', component: GenericDashboardComponent, children: allPaths
    },
    {
        path: '', component: CommonLayoutComponent, children: allPaths
    },
];
