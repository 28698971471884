import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';
import { ProductServiceService } from '../../service/product-service.service';


@Component({
  selector: 'm-product-list',
  templateUrl: './product-list.component.html',
  styles: [
  ]
})
export class ProductListComponent implements OnInit {
  bgP: boolean = true;
  bgO: boolean = false;
  icon: boolean = false
  application = 'Product';
  component = 'Product';
  // @Input() details: any
  @Input() details: any
  @Input() detail: any

  @ViewChild('product_list') product_list: any;

  filter_results_visible = false;

  default_image: any = '../assets/images/assets/My-Product-tranperant.gif'


  favourite_list: any = {
    list: [],
    count: 0
  }

  // list = {
  //   list: [],
  //   count: 0
  // }
  org_list: any = {
    list: [],
    count: 0,
  }
  org_product_list: any = {
    list: [],
    count: 0,
  }
  list = {
    list: <any[]>[],
    count: 0,
  }
  params: any = {
    type: undefined,
    query: '',
    application: '',
    _id : '',
    org_id:''
  }

  queryParams = {
    application: '',
    component: '',
    step: '',
    type_id: '',
    type: '',
    page: 0,
    query: <string | undefined>undefined,
  }

  activities: any
  data: any;

  check = true

  toggle = true;

  custom_filter: any = {
    'product': undefined
  }
  
  visible = {
    savedProductlist: false,
  };
  sort = ['Featured', 'Popularity', 'Price Range', 'Customer Review', 'Newest First'];

  constructor(public hps: HttpProviderService, public gs: GlobalService, public router: Router, public ar: ActivatedRoute, public ps :ProductServiceService) { }
  user: any
  ngOnInit(): void {

    this.get_organization()
    this.get_org_products()
    this.gs.user.users$.subscribe((users) => {
      this.user = users.activeUser
      // console.log(this.user);

    })
    //  this.get_list ()
    this.ar.queryParams.subscribe(async (param: any) => {
      this.toggle = false;
      setTimeout(() => {
        this.toggle = true
      }, 100);
      this.queryParams = { ...param }
      this.get_list()
      this.custom_filter['product'] = this.queryParams.type_id ? [{ 'parents.parent._id': this.queryParams.type_id }] : []
      let filter: any = [{
        $or: [
        ],
        application: 'Product',
        component: 'Product'
      }]
      // console.log(this.gs.group_ids)
      this.gs.group_ids.forEach((id: string) => {
        filter[0].$or.push({
          ['meta.roles.owner.group.' + id]: true
        })
      })
      filter[0].$or.push({ ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true })
      this.custom_filter['my-products'] = filter || { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true }
      this.custom_filter['my-organization'] = { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true }
      this.custom_filter['saved-products'] = { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true, activity: 'save', application: 'Product', component: 'Product' }
      this.custom_filter['favourite-prod'] = { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true, activity: 'favourite', application: 'Product', component: 'Product' }
      // console.log(this.custom_filter['my-products'])
      this.custom_filter['my-products'] = filter || { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true }
    });
    // async get_list(){
    //   this.list = await this.gs.get_list('structure', this.queryParams.type, this.queryParams.sub_type, this.queryParams.application,[],{page:this.queryParams.page,size:20,query : this.queryParams.query})
    // console.log(this.list);

    // }


  }
  async get_list() {
    let response = await this.gs.get_data_list(this.application, this.component, [], { page: this.queryParams.page, size: 5, query: this.queryParams.query })
    this.list = response
    // console.log(this.list);
  }
  // async get_list() {
  //   let response = await this.gs.get_data_list(this.application, this.component, [],{page:this.queryParams.page,size:20,query : this.queryParams.query})
  //   this.list = response;
  // }
  // async get_list(){
  //   this.list = await this.gs.get_list('structure', this.queryParams.type, this.queryParams.sub_type, this.queryParams.application,[],{page:this.queryParams.page,size:20,query : this.queryParams.query})
  // console.log(this.list);

  // }
  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }

  saved_product_map(element: any, index: number) {
    // console.log(element)
    return element?.parents?.data;
  }
  favourite_products_map(element: any, index: number) {
    // console.log(element)
    return element?.parents?.data;
  }
  card_1: [] = [];
  card_3: [] = [];
  activeImage: [] = [];

  // slider_list(){
  //   for(let i=0; i<this.product_list.length; i++){
  //     this.product_card_5[i].addEventListener('mouseover',  function(){
  //       console.log("activeImage")
  //       if(activeImage.length)
  //     })
  //   }
  // }

  // async getList() {
  //   for (let i = 0; i < this.list.length; i++) {
  //     const element = this.list[i];
  //   }

  // };

 org : any
  async get_organization() {
    let id: any
    let body = {  
    ["meta.roles.owner.user." + this.gs.user.users.activeUser?._id]: true ,
    "$and": [{"meta": {"status": {"$eq": "Approved"}}}] }
    this.org_list = await this.gs.hps.post('idm', 'group', 'list', body)
    for (let i = 0; i < this.org_list.list.length; i++) {
      this.org = this.org_list.list[i];
      id = this.org?._id
      if (this.params?.org_id == id) {
        this.get_org_products();
      }
    }
    return this.org_list
  
  }
  async get_org_products() {
    let body = {
      application: 'Product',
      component: 'Product',
      ["meta.roles.owner.user." + this.gs.user.users.activeUser?._id]: true
    }
    this.org_product_list = await this.gs.hps.post('generic', 'data', 'list', body)
    console.log(this.org_product_list);
    
  }

  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  }


}












