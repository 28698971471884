<div a-style zIndex="1">
            <!-- <div a-style bgUrl="../assets/images/ME/B2B_Product_home_image.gif" h="59.3" position="relative">
                <div a-style w="70.9" display="flex" flexDirection="column" align="center" gap="1.6" position="absolute"
                    zIndex="1" top="19" left="12">
                    <p a-style fs="3.2">Newsletter</p>
                    <p a-style fs="1.6">Often used a lead magnets to capture subscribers, training material makes a
                        business
                        newsletter something your customers look forward to receiving.</p>
                    <button type="submit" a-style br="1px solid #2479AB" brRadius="6px" bg="#fff" w="10.4" h="2.9"
                        cursor="pointer">Subscribe</button>
                </div> -->
                <div *ngIf="!gs.router.url.includes('/product/category') && !gs.router.url.includes('/product/compare') &&
                 !gs.router.url.includes('/product/history') && !gs.router.url.includes('/generic/form') && !gs.router.url.includes('/social/home') 
                   && !gs.router.url.includes('/article/article-detail') && !gs.router.url.includes('recipe/recipe-detail')
                 && !gs.router.url.includes('scrap/detail') && !gs.router.url.includes('spiritual/spiritual-detail') && !gs.router.url.includes('/product/history') && !gs.router.url.includes('/org/organization-bank')"
                
                   flexWrap="wrap" px="2.4" zIndex="1" a-style py="1.90" display="flex" align="center" justify="space-between"
                align="center" color="#FFFFFF" w="100%" bg="transparent" >
                <div a-style display="flex" minWidth="4 " align="center" fs="1.1" color="#B2B2B2">
                    <span a-style fs="1.7" mr="0.8">© </span>
                    <m-text [type]="gs.enums.texts.paragraph46" text="2022 Mesbro"></m-text>
                </div>
                <div a-style display="flex" m=1 fs="1.1" color="#B2B2B2" justify=flex-end>
                    <m-text [type]="gs.enums.texts.paragraph46" routerLink='/mesbro-help/all' text="Help Center" mr="1"
                        (click)="gs.router.navigate(['center/home'])">
                    </m-text>
                    <m-modal #reportBug width="85" top="14.5">
                        <m-report-bug [modal]='reportBug'></m-report-bug>
                    </m-modal>
                </div>
              </div>
              <div *ngIf="gs.router.url.includes('/product/category') || gs.router.url.includes('/product/compare') || gs.router.url.includes('/product/history') || gs.router.url.includes('/payment/feature') || gs.router.url.includes('/center/help-detail')" flexWrap="wrap" px="2.4" zIndex="1" a-style py="1.90" display="flex" align="center" justify="space-between"
              align="center" color="#FFFFFF" w="100%" [bg]="gs.router.url.includes('/product/history')?'transparent':'white'" position="fixed" bottom="0">
              <div a-style display="flex" minWidth="4 " align="center" fs="1.1" color="#B2B2B2">
                  <span a-style fs="1.7" mr="0.8">© </span>
                  <m-text [type]="gs.enums.texts.paragraph46" text="2022 Mesbro"></m-text>
              </div>
              <div a-style display="flex" m=1 fs="1.1" color="#B2B2B2" justify=flex-end>
                  <m-text [type]="gs.enums.texts.paragraph46" routerLink='/mesbro-help/all' text="Help Center" cursor="pointer" mr="1"
                      (click)="gs.router.navigate(['center/home'])">
                  </m-text>
                  <m-modal #reportBug width="85" top="14.5">
                      <m-report-bug [modal]='reportBug'></m-report-bug>
                  </m-modal>
              </div>
            </div>
</div> 
