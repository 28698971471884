import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Buttons } from 'src/enums/buttons';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-button',
  templateUrl: './button.component.html',
  styles: [
  ]
})
export class ButtonComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'
  @Input() types: undefined;
  @Input() type: Buttons = this.gs.enums.buttons.primary;
  @Input() text: string = 'Submit';
  @Input() align: string = 'right';
  @Input() color: string = '';
  @Input() textcolor: boolean = false;
  @Input() marginTop: boolean = false;
  @Input() mt: string = '';
  @Input() mx: boolean = false;
  @Input() pr: string = '';
  @Input() buttonWidth: string = '';
  @Input() px: string = '';
  @Input() h: string = '';
  @Input() w: string = '';
  @Input() ml: string = '';
  @Input() mr: string = '';
  @Input() bg: string = '';
  @Input() brRadius: string = '';

  @Input() fw: string = '';

  @Input() fs: string = '';
  @Input() width: string = '';
  @Input() br: string = '';
  @Input() justify: string = '';
  @Input() padding: string = '';
  @Input() button_type = 'button';
  @Input() py: boolean = false;
  @Input() disable: boolean = false;
  @Input() border: boolean = false;
  @Input() src: any;
  @Input() shadow: any;
  @Input() top: string = '';
  @Input() right: string = '';
  @Input() left: string = '';
  @Input() position: string = '';
  @Input() maxWidth : string = '';
  @Output() clicked = new EventEmitter()
  @Input() mb: string = '';
  @Input() bottom: string = '';

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

}
