<div *ngIf="isForm" a-style p="3" bg="white">
    <form [formGroup]="form">
        <div a-style display="flex" mt="2.4" mb="3">
            <p a-style fs="1.6" fw="600">Appearance Settings Form</p>
            <p a-style fs="1.3" fw="400">(Please fill all the fields to make your website more attractive)</p>
        </div>
        <!-- <m-input label="Select Organization" bindLabel="title" bindValue="_id" pl=0 [list]="gs.$groups | async" formControlNam="gropuControl" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" placeHolder="Select Organization" [columns]="1/3"></m-input> -->

        <!-- <m-input [columns]="1/3" formControlName="product_tags" label="Product tags " pl=0 placeHolder="Add product tags here"></m-input>
        <div a-style display="flex">
            <m-input [columns]="1/2" [type]="gs.enums.inputs.text_area" pl=0 formControlName="product_description" placeHolder="Add Product Description Here" label="Product Description" rows=8 px=1></m-input>
            <m-input [columns]="1/2" [type]="gs.enums.inputs.text_area" pl=0 formControlName="product_details" placeHolder="Add Product Details Here" label="Product Details" rows=8 px=1></m-input>
        </div> -->

        <div a-style br="1px solid #e2e2e2" p="2" pt="2" mb="3" align="center">
            <p a-style fs="1.6" fw=600>Welcome Section</p>
            <ng-container formArrayName="welcomes">
                <m-form title_mb=1 columns="9" *ngFor="let item of welcome_group; let i=index" formGroupName="{{i}}" mt="1.5">
                    <m-input [columns]="1/3" formControlName="greet_title" label="Greet Title" placeHolder="Welcome Text..."></m-input>
                    <m-input [columns]="1/3" formControlName="title" label="Title " placeHolder="Organization Name"></m-input>
                    <m-input [columns]="1/3" formControlName="subtitle" label="Subtitle" placeHolder="Add subtitle..."></m-input>
                    <m-input [columns]="1/3" [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description" placeHolder="Description" label="Description" rows=8 px=1></m-input>
                    <!-- <m-input [type]="gs.enums.inputs.media_flip" formControlName="upload" [columns]="1/3" mt="3.8" [aspect_ratio]="0.4"> </m-input> -->
                </m-form>
                <p a-style fs="1.6" (click)="add_welcome()" color="#006bff" mt="1.5">Add another welcome section</p>
                <!-- <m-button [types]="gs.enums.buttons.button19" a-style mt="1" py="1" text="+" (click)="add_welcome()"></m-button> -->
            </ng-container>
        </div>

        <div a-style br="1px solid #e2e2e2" p="2" pt="2" mb="3">
            <p a-style fs="1.6" fw=600>Discount Section</p>
            <ng-container formArrayName="discounts">
                <m-form title_mb=1 columns="9" *ngFor="let item of discount_group;let i=index" formGroupName="{{i}}" mt="1.5" px="1.5">
                    <m-input [columns]="1" formControlName="discount_title" label="Discount title" placeHolder="Discount title..."></m-input>
                    <m-input [type]="gs.enums.inputs.text_area" formControlName="discount_description" [columns]="1/2" placeHolder="Description" label="Description" rows=8 px=1></m-input>
                    <m-input [type]="gs.enums.inputs.media" formControlName="upload_discount_image" [onlyInput]="true" [columns]="1/2" [aspect_ratio]="0.4" label="Add Image For Discount Banner" mt="3.8" description="Please Select an image for discount sections slider"> </m-input>
                </m-form>
                <p a-style fs="1.6" (click)="add_discount()" color="#006bff" mt="1.5">Add another discount section</p>
            </ng-container>
        </div>

        <div a-style br="1px solid #e2e2e2" p="2" pt="2" mb="3">
            <p a-style fs="1.6" fw=600>Team Members Section</p>
            <ng-container formArrayName="team_members">
                <m-form title_mb=1 columns="9" *ngFor="let item of team_member_group;let i=index" formGroupName="{{i}}" mt="1.5" px="1.5">
                    <m-input [columns]="1/3" formControlName="employee_name" label="Employee Name" placeHolder="Name of Employee"></m-input>
                    <m-input [columns]="1/3" formControlName="employee_designation" label="Employee Designation" placeHolder="Designation of Employee"></m-input>
                    <m-input [columns]="1/3" formControlName="social_media_link" label="Employee's Social Media Links" placeHolder="Add link here ..."></m-input>
                    <m-input [type]="gs.enums.inputs.text_area" formControlName="employee_description" [columns]="1/2" placeHolder="Description" label="Description" rows=8 px=1></m-input>
                    <m-input [type]="gs.enums.inputs.media" formControlName="upload_profile_image" [onlyInput]="true" mt="3.8" [columns]="1/2" [aspect_ratio]="1" label="Select profile image of employee" description="Please Select image"> </m-input>
                </m-form>
                <p a-style fs="1.6" (click)="add_team_member()" color="#006bff" mt="1.5">Add more Employees</p>
            </ng-container>
        </div>
        <m-input [aspect_ratio]='0.4' pl="0" label="Select banner image" [columns]="1/3" multiple="true" formControlName='upload_banner' [type]="gs.enums.inputs.media"></m-input>
        <!-- <div a-style display="flex">
            <input type="color" id="favcolor" name="favcolor" value="#ff0000" a-style w="15" h="5" mt="2.5">
            <m-input formControlName="colors" [type]="gs.enums.inputs.dropdown" [list]="colors" bindLabel="color" bindValue='color' [columns]="1/3" label="Colors" placeHolder="Select Colors"></m-input>
            <m-input formControlName="size" [type]="gs.enums.inputs.dropdown" [list]="size" bindLabel="size" bindValue='size' [columns]="1/3" label="Size" placeHolder="Select Size"></m-input>
        </div> -->

    </form>
    <div a-style display="flex" justify="flex-end" mt=2.4 flexGap=1.6 align=center>
        <m-button [types]="gs.enums.buttons.button18" text="Submit" (click)="submit();"></m-button>
    </div>
</div>