import { Component, OnInit, ɵɵresolveBody } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-contacts',
  templateUrl: './generic-contacts.component.html',
  styles: [
  ]
})
export class GenericContactsComponent implements OnInit {

  form: FormGroup | undefined;
  users: IUserData;

  constructor(public gs: GlobalService, public fb: FormBuilder) { }
  ngOnInit(): void {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
    })
    this.initialization();
  }
  initialization () {
    this.form = this.fb.group({
      basicDetails: this.fb.group({
        first_name: '',
        last_name: '',
        mobile: '',
        country_code: '',
        profile: [null],
      }),
     
    })
  }

  async submit() {
    let body =this.form?.value
      body.parent_id =this.users?.activeUser?._id,
      body.type = 'generic'
    let response = await this.gs.hps.post('idm', 'contacts', 'add', body);
  }
}
