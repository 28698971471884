
<div a-style p="4" pb=0 pt="10" overflowX="auto">
    <div a-style display="flex" align="center" justify="space-between">
        <div a-style w="23%">
            <p *ngIf="params.category=='primary' || params.category=='secondry' || params.category=='tertiary'" a-style
                color="#2479AB" fs="1.6" align="left">
                {{params.category | titlecase}} Categories
            </p>
            <p *ngIf="params.category=='type'" a-style color="#2479AB" fs="1.6" align="left">
                Product Categories
            </p>
        </div>
        <m-input [uppercases]=false a-style [formControl]="search" prefix_h="2" prefix_w="2"
            prefix="../assets/images/assets/new-organization/Text search icon.svg" w="67.8" h="3.9" minWidth="47.6"
            mb="0" outline=none px=0 fs="1.3" shadow="hov:0 3px 6px 0 #f1f1f1" outline=none [(ngModel)]="searchTypeInput"
            (keydown.enter)="searchTypeName()" placeHolder="Search Product Type" (prefix_clicked)="searchTypeName()"
            display="900:none"></m-input>
        <m-input *ngIf="params.category=='primary'" [type]="gs.enums.inputs.dropdown" [(ngModel)]="primaryName"
            placeHolder="Search {{params.category | titlecase}} Categories" [list]="primaryArray"
            (ngModelChange)="searchprimary()" a-style minWidth="32.7" maxWidth="32.7">
        </m-input>
        <m-input *ngIf="params.category=='secondry'" [type]="gs.enums.inputs.dropdown" [(ngModel)]="secondaryName"
            placeHolder="Search {{params.category | titlecase}} Categories" [list]="secondaryArray" a-style
            minWidth="32.7" maxWidth="32.7" (ngModelChange)="getsecondaryByName()">
        </m-input>
        <m-input *ngIf="params.category=='tertiary'" [type]="gs.enums.inputs.dropdown" [(ngModel)]="tertiaryName"
            placeHolder="Search {{params.category | titlecase}} Categories" [list]="tertiaryArray"
            (ngModelChange)="searchTertiary()" a-style minWidth="32.7" maxWidth="32.7">
        </m-input>
        <m-input *ngIf="params.category=='type'" [type]="gs.enums.inputs.dropdown" [(ngModel)]="searchType"
            placeHolder="Search Product {{params.category | titlecase}}" (ngModelChange)="searchTypeName()" [list]="typeArray" a-style minWidth="32.7"
            maxWidth="32.7">
        </m-input>
    </div>
    <div a-style display="flex" justify="center" mt="2.4" flexGap="0.5" textCenter="center" cursor="pointer">
        <p *ngIf="!gs.router.url.includes('category == primary') && params.category != 'primary'" a-style fs="1.3"
            (click)="gs.router.navigate(['/product/category'],{queryParams:{category:'primary',type:'product'}})">
            {{selectedPrimary}}</p>
        <img *ngIf="selectedsecondary && params.category != 'secondry' && params.category != 'primary'"
            src="../assets/images/Icon_Bank/Slider_Right_Arrow_Coloured.svg" a-style w="1.9" h="1.9">
        <p *ngIf="!gs.router.url.includes('category == primary') && params.category != 'secondry' && params.category != 'primary'"
            a-style fs="1.3"
            (click)="gs.router.navigate(['/product/category'],{queryParams:{category:'secondry', type:'product', primaryId:'_id'}})">
            {{selectedsecondary}}</p>
        <img *ngIf="selectedTertiary && params.category != 'primary' && params.category != 'secondry' && params.category != 'tertiary'"
            src="../assets/images/Icon_Bank/Slider_Right_Arrow_Coloured.svg" a-style w="1.9" h="1.9">
        <p *ngIf="!gs.router.url.includes('category == primary') && params.category != 'tertiary' && params.category != 'secondry' &&  params.category != 'primary'"
            a-style fs="1.3"
            (click)="gs.router.navigate(['/product/category'],{queryParams:{category:'tertiary',type:'product',primaryId:['5e359364cb294e4710f2e928'] , secondaryId: ['5e3597a165007f35f84d91eb']}})">
            {{selectedTertiary}}</p>
    </div>
    <!-- B2B categories -->
    <div a-style mt="2.4" *ngIf="params.category=='primary'" pb="7.5">
        <p a-style fs="1.3" align=center> </p>
        <div a-style display="flex" gap="2.6" textCenter="center" flexWrap="wrap" justify="">
            <div a-style *ngFor="let primary of primaryList?.content;">
                <m-category-card type="categoryCard3" [detail]="primary"
                    (click)="categoryRedirectPrimary(primary._id);selectedCategory(primary?.basic?.name)">
                </m-category-card>
            </div>
        </div>
    </div>
    <div a-style mt="2.4" *ngIf="params.category=='secondry'" pb="7.5">
        <p a-style fs="1.3" align=center></p>
        <div a-style display="flex" flexGap="2.4" textCenter="center" flexWrap="wrap" justify="" mt="2.4">
            <div a-style *ngFor="let secondary of secondaryList?.content;">
                <m-category-card type="categoryCard3" [detail]="secondary"
                    (click)="categoryRedirectSecondary(secondary._id);selectedCategorysecondary(secondary.basic.name)">
                </m-category-card>
            </div>
        </div>
    </div>
    <div a-style mt="2.4" *ngIf="params.category=='tertiary'" pb="7.5">
        <p a-style fs="1.3" align=center> </p>
        <div a-style display="flex" flexGap="2.4" textCenter="center" flexWrap="wrap" justify="" mt="2.4">
            <div a-style *ngFor="let tertiary of tertiaryList?.content;">
                <m-category-card type="categoryCard3" [detail]="tertiary"
                    (click)="categoryRedirecttertiary(tertiary._id);selectedCategoryTertiary(tertiary.basic.name)">
                </m-category-card>
            </div>
        </div>
    </div>
    <div a-style mt="2.4" *ngIf="params.category=='type' && !searchTypeInput && !this.params.typeName" pb="7.5">
        <p a-style fs="1.3" align=center></p>
        <div a-style display="flex" flexGap="2.4" textCenter="center" flexWrap="wrap" justify="" mt="2.4">
            <div a-style *ngFor="let type of typeList?.list;">
                <m-category-card type="typecard" [detail]="type" (click)="redirect(type._id)">
                </m-category-card>
            </div>
        </div>
    </div>
    <div a-style mt="2.4" *ngIf="searchTypeInput || this.params.typeName" pb="7.5">
        <p a-style fs="1.3" align=center></p>
        <div a-style display="flex" flexGap="2.4" textCenter="center" flexWrap="wrap" justify="" mt="2.4">
            <div a-style *ngFor="let type of typeListForSearch?.list;">
                <m-category-card type="typecard" [detail]="type" (click)="redirect(type._id)">
                </m-category-card>
            </div>
        </div>
    </div>
  
</div>
