<div a-style h="7.1" brBottom="1px solid #e4e4e4" bg="#F8F8F8" br="0.5 solid #E4E4E4">
     <div a-style h="7.2" box-shadow=' 0px 3px 6px #17171729' br="0.5px solid #E4E4E4" display="flex" align="center" justify="center" gap="2.5" shadow="0 3px 6px 0 #17171729">
         <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['me/home'])" [style.color]=" gs.router.url.includes('/me/home')?'#2479AB':'black'">
             <!-- <img *ngIf="!gs.router.url.includes('/me/home') && !gs.router.url.includes('/me/form')" src="../assets/images/ME/Home.svg" a-style w="2.6" h="2.6" routerLink="/me/home"> -->
             <img  a-style h=2.6 w=2.6 cursor="pointer" src="../assets/images/Icon_Bank/Home_Coloured.svg" >
             <p *ngIf="!gs.router.url.includes('/me/home')" a-style fs="1.4" >Home</p>
             <p *ngIf="gs.router.url.includes('/me/home')" a-style fs="1.4" color="#2479AB">Home</p>
             <!-- *ngIf="gs.router.url.includes('/me/home') || gs.router.url.includes('me/form')" -->
         </div>
         <!-- <div  a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['me/personalization'])">
            <img *ngIf="!gs.router.url.includes('/me/personalization')" src="../assets/images/ME/Personalization (2).svg" a-style w="2.6" h="2.6">
            <img *ngIf="gs.router.url.includes('/me/personalization')" src="../assets/images/ME/PersonalizationColored.svg" a-style w="2.6" h="2.6">
            <p a-style fs="1.4">Personalization</p>
        </div> -->
        <div  a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['me/history-control'])" [style.color]=" gs.router.url.includes('/me/history-control')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/History_Coloured.svg" a-style w="2.6" h="2.6" >
            <!-- <img src="../assets/images/ME/History Control (1).svg" a-style w="2.6" h="2.6" *ngIf="!gs.router.url.includes('me/history-control')"> -->
            <p a-style fs="1.4">History Control</p>
        </div>
        <div  a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['me/settings'])" [style.color]=" gs.router.url.includes('/me/settings')?'#2479AB':'black'">
            <!-- <img  *ngIf="!gs.router.url.includes('me/settings')" src="../assets/images/ME/settings (1).svg" a-style w="2.6" h="2.6"> -->
            <img   src="../assets/images/Icon_Bank/Settings_Coloured.svg" a-style w="2.6" h="2.6">
            <p a-style fs="1.4">Settings</p>
        </div>
     </div>
</div>