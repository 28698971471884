import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import { localStorageGet, localStorageSet } from '../../functions/common';

@Component({
  selector: 'm-language-tray',
  templateUrl: './language-tray.component.html',
  styles: [
  ]
})
export class LanguageTrayComponent implements OnInit {

  constructor(public gs: GlobalService) {

  }

  first_time = true;

  search: FormControl = new FormControl()
  country: FormControl = new FormControl(localStorageGet('country'));
  ngOnInit(): void { }

  languages = ['English', 'Hindi', 'Spanish', 'Standard Arabic', 'Bengali', 'French', 'Russian', 'Portuguese', 'Urdu', 'Japanese', 'Standard German', 'Indonesian', 'Marathi', 'Telugu', 'Turkish', 'Tamil', 'Yue Chinese', 'Korean', 'Hausa', 'Italian', 'Amharic']
  currencyList = [
    { country: 'India', flag: "india" },
    { country: 'Australia', flag: "australia" },
    { country: 'Bhutan', flag: "bhutan" },
    { country: 'Brazil', flag: "brazil" },
    { country: 'China', flag: "china" },
    { country: 'Colombia', flag: "colombia" },
    { country: 'Cuba', flag: "cuba" },
    { country: 'Denmark', flag: "denmark" },
    { country: 'Egypt', flag: "egypt" },
    { country: 'Indonesia', flag: "indonesia" },
    { country: 'Afghanistan', flag: "afghanistan" },
    { country: 'Iraq', flag: "iraq" },
    { country: 'Israel', flag: "israel" },
    { country: 'Japan', flag: "japan" },
    { country: 'Kenya', flag: "kenya" },
    { country: 'Maldives', flag: "maldives" },
    { country: 'Mexico', flag: "mexico" },
    { country: 'Nepal', flag: "nepal" },
    { country: 'Oman', flag: "oman" },
    { country: 'Pakistan', flag: "pakistan" },
    { country: 'Sweden', flag: "sweden" },
  ]
  // this.gs.$currency.subscribe(item => {
  //   this.country = item;
  // })

  select_language(language: string) {
    this.gs.$language.next(language);
    localStorageSet('language', language)
    this.gs.toast(language, 'Your default language was changed')
  }

  select_country(country: any) {
    let value = country.country || country;
    console.log(value)
    this.gs.$country.next(country);
    localStorageSet('country', country)
    this.gs.toast(value, 'Your default country was changed')
  }

}
