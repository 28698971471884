<div a-style>
    <p a-style color="#333333" fs="1.4" pb="1" mt="1.4">Title</p>
    <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{ passedData: { text: 'I m running late'  } }"></ng-container>
    <p a-style color="#333333" fs="1.4" pb="1" pt="1.6">Description</p>
    <div a-style px="1" mb="1.6" py="1" br="1px solid lightgray" brRadius="0.4">
        <p a-style fs="1.3" pb="5.5">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt rem ipsum dolor.</p>
    </div>

</div>
<ng-template #input let-passedData="passedData">
    <div a-style br="1px solid #e2e2e2" p="1" display="flex" justify="space-between">
        <p a-style fs='1.3'>{{passedData.text}}</p>
        <p a-style fs='1.3' color="#006BFF">{{passedData.text2}}</p>
    </div>
</ng-template>