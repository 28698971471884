import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-comment',
  templateUrl: './comment.component.html',
  styleUrls: []
})
export class CommentComponent implements OnInit {
  @Input() post_id: any;
  @Input() user: any;
  post_comment: any
  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }
  ngOnInit() {
    this.get_comments()
  }


  async add_comment(id: any) {
    let body: any = {
      parent: {},
      data: {}
    }
    body.parent._id = id;
    body.data.text = this.post_comment;
    let response = await this.gs.hps.post('generic', 'comment', 'add', body)
    body._id = response._id;
    body.meta = {
      "activity": {
        "created_at": new Date(),
        "updated_at": new Date(),
        "updated_by": {
          "first_name": this.gs.user.users.activeUser?.personal_details.first_name,
          "last_name": this.gs.user.users.activeUser?.personal_details.last_name,
          "profile_picture": this.gs.user.users.activeUser?.personal_details.profile_picture,
          "_id": this.gs.user.users.activeUser?._id
        }
      },
    }
    this.post_comment = undefined;
    this.comment_list.list.unshift(body)
    this.comment_list.count++


  }
  comment_list: any = {
    count: '',
    list: []
  }

  async get_comments() {
    let body = {
      'parent._id': this.post_id
    }
    let response = await this.gs.hps.post('generic', 'comment', 'list', body)
    this.comment_list = response;

  }

}
