<div *ngIf="false">
    <div>
        <div a-style h="calc(100vh - 15rem)" class="no_scroll_bar" overflowY="auto" *ngIf="true">
            <div a-style pt="2.4" px="4">
              <div a-style display="flex" justify="space-between" align="center" mb="2">
                 <div a-style flexGrow="1">
                    <div a-style display="flex" gap="1.6" align="center">
                        <img a-style h=4.2 w=4.2 src="../assets/images/assets/chat1.svg">
                        <p a-style m=0 fs=2 color=#333333>Bharat Furniture Pvt. Ltd.</p>
                    </div>
                 </div>
                 <div>
                    <img a-style w="2.5" h="2.5"  src="../assets/images/assets/product-add.svg">
                 </div>
              </div>
              <div a-style h="calc(100vh - 7.5rem - 23rem)" class="no_scroll_bar" overflowY="scroll">
                <div a-style py="2" px="2.4" display="flex" gap="2" align="center" cursor="pointer" (click)="gs.router.navigate(['mesbro-help/details'],{queryParams : {type : 'help-features'}})">
                  <img a-style w="3" h="3"  src="../assets/images/assets/chat1.svg">
                  <p a-style m=0 fs="1.6" color=#333333>Features</p>
                </div>
              </div>
            </div>
            <div>
              <m-help-footer></m-help-footer>
            </div>
          </div>
        <div>
            hbb
        </div>
    </div>
</div>

<div a-style h="calc(100vh - 7.5rem - 7.1rem)" mt="7.1" class="no_scroll_bar" overflowY="auto" *ngIf="true">
    <div a-style pt="2.4" px="4">
      <div a-style display="flex" justify="space-between" align="center" mb="2">
         <div a-style flexGrow="1">
            <div a-style display="flex" gap="1.6" align="center">
                <img a-style h=4.2 w=4.2 src="../assets/images/assets/chat1.svg">
                <p a-style m=0 fs=2 color=#333333>Bharat Furniture Pvt. Ltd.</p>
            </div>
         </div>
         <div a-style (click)="gs.router.navigate(['mesbro-help/details'],{queryParams : {type : ''}})">
            <img a-style w="2.5" h="2.5"  src="../assets/images/assets/product-add.svg">
         </div>
      </div>
      <div a-style h="calc(100vh - 7.5rem - 23rem)" class="no_scroll_bar" overflowY="scroll">
        <div a-style py="2" px="2.4" display="flex" gap="2" align="center" cursor="pointer" (click)="gs.router.navigate(['mesbro-help/details'],{queryParams : {type : 'help-features'}})">
          <img a-style w="3" h="3"  src="../assets/images/assets/chat1.svg">
          <p a-style m=0 fs="1.6" color=#333333>Features</p>
        </div>
      </div>
    </div>
    <div>
      <m-help-footer></m-help-footer>
    </div>
  </div>