<div a-style mt=7.4 mx=2.4 mb=2.4 br="1px solid #e4e4e4" brRadius=0.6 bg="#ffffff">
    <div a-style brBottom="1px solid #e4e4e4" p=2.4>
        <m-text [type]="gs.enums.texts.paragraph21" text="Privilege User List {{list?.count}}"></m-text>
    </div>
    <div a-style p=2.4 pt=1.6 overflowX="none;1024:auto;460:scroll" class="no_scroll_bar">
        <div a-style w=100%>
            <m-input flexGrow="0;460:1" minWidth="35;460:30" label="Search" [columns]="1/4" px=0 cleareIcon="true"></m-input>
        </div>
        <table a-style mt=2.4 p=0 br="none">
            <tr>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Sr No"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1"  minWidth=6.5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Profile"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1"  minWidth=14>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Name"></m-text>
                </th>

                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1"  minWidth=14>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Username"></m-text>
                </th>

                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1" minWidth=10>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Updated At"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="0px" br="1px solid #e4e4e4" py="1"  minWidth=5>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Edit"></m-text>
                </th>
                <th a-style align="center" brLeft="1px solid #e4e4e4" brRight="1px solid #e4e4e4" br="1px solid #e4e4e4" py="1"  minWidth=7>
                    <m-text [type]="gs.enums.texts.paragraph20" text="Delete"></m-text>
                </th>
            </tr>
            <tr *ngFor="let item of list?.list; let i=index">
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=5>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{i+1}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=6.5>
                    <m-image src='https://new-api.mesbro.com/uploads/{{item?.data?.image}}' w="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7;460:3.7;320:3" h="11.6;1440:9.7;1336;8.7;1280:7.6;1024:5.7;786:4.7" my="1.6" mx="2.6"> </m-image>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=14>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{item.data?.name}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=14>
                    <m-text [type]="gs.enums.texts.paragraph35" text="{{item.data?.username}}"></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=10>
                    <m-text [type]="gs.enums.texts.paragraph35" text=" {{item.meta.activity?.updated_at | date : 'dd MMM YYYY'}} "></m-text>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="0px" br="1px solid #e4e4e4" minWidth=5>
                    <m-image routerLink="/dashboard/privilege-user/form" [queryParams]="{_id:item._id}" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVhnUBDsSFZy2lScmmUXBfDePZvaFDBGC5KMxos6LaPAlIknifNst2K3AkOJKAPTuwfYpSxA9Rw5kUmQ&usqp=CAU" h="1.5" w="1.5"></m-image>
                </td>
                <td a-style align="center" brLeft="1px solid #e4e4e4" brTop="0px" brRight="1px solid #e4e4e4" minWidth=7>
                    <m-image (click)="delete(item._id,i)" src="https://mesbro.in/assets/images/delete-red.svg" h="1.5" w="1.5"></m-image>
                </td>
            </tr>
        </table>
    </div>
</div>