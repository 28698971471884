<ng-container [ngTemplateOutlet]="Share_with_me" *ngIf="params.type=='Share_with_me'"></ng-container>
<ng-container [ngTemplateOutlet]="Shared_by_me" *ngIf="params.type=='Shared_by_me'"></ng-container>


<ng-template #Share_with_me>
    <div a-style display="flex" flexGrow="1" p="1.5" justify="space-between"cursor="pointer"  shadow="hov:0 3px 6px 0 #b2b2b2" bg="#ffffff" br="1px solid #e2e2e2" mb="1.5">
        <div a-style display="flex" gap="1">
            <img src="https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg" a-style w="12.5">
            <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'FITNACE Hexa Dumbbells (Set of 2) Hexagon Hex Dumbles for Home Workout' , text2 :'Mesbro Essential Private Limited' }}"></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text :'Listing Policy' , text2:'Approved'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Category' , text2:'Approved' }}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Product Status' , text2:'Active'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Product Validity' , text2:'Jun 30, 2021'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Views' , text2:'4565'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Reviews' , text2:'46124'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Controls' , text2:'Controls'}}"></ng-container>
    </div>
</ng-template>
<ng-template #Shared_by_me>
    <div a-style display="flex" flexGrow="1" p="1.5" justify="space-between" bg="#ffffff" br="1px solid #e2e2e2" mb="1.5"  shadow="hov:0 3px 6px 0 #b2b2b2" cursor="pointer">
        <div a-style display="flex" gap="1">
            <img src="https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg" a-style w="12.5">
            <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'FITNACE Hexa Dumbbells (Set of 2) Hexagon Hex Dumbles for Home Workout' , text2 :'Mesbro Essential Private Limited' }}"></ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text :'Listing Policy' , text2:'Approved'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Category' , text2:'Approved' }}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Product Status' , text2:'Active'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Product Validity' , text2:'Jun 30, 2021'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Views' , text2:'4565'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Reviews' , text2:'46124'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="verticaldata" [ngTemplateOutletContext]="{passedData : {text : 'Controls' , text2:'Controls'}}"></ng-container>
    </div>
</ng-template>

<ng-template #verticaldata let-passedData="passedData">
    <div a-style display="flex" flexDirection="column">
        <p a-style fs="1.3">{{passedData.text}}</p>
        <p a-style fs="1.3" color="#707070">{{passedData.text2}}</p>
        <p a-style fs=1.3>{{passedData.text3}}</p>
        <p a-style fs=1.3>{{passedData.text4}}</p>
    </div>
</ng-template>