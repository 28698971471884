import { GlobalService } from './../../../../services/global.service';
import { Component, Input, OnInit } from '@angular/core';
import { IUserData } from '../../../../applications/idm/interfaces/i-user';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'm-comman-list',
  templateUrl: './comman-list.component.html',
  styles: [
  ]
})
export class CommanListComponent implements OnInit {
  @Input() index: any;
  @Input() detail: any;
  @Input() type: any;
  @Input() list: any;
  users: IUserData;
  application: string | undefined = undefined;

  filter = undefined;

  params = {
    type: ' ',
    subtype:'',
    // query: this.form?.value?.text
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {

    this.ar.queryParams.subscribe((params: any) => {
      this.params = params
      console.log(this.params)
    })
    

    this.gs.user.users$.subscribe(users => {
      this.users = users
      console.log(this.users);

    })
    this.ar.queryParams.subscribe(query_params => {
      this.application = query_params.application;
    })

    let filter: any = [{
      $or: [
      ]
    }]
    setTimeout(() => {
      this.gs.group_ids.forEach((id: string) => {
        filter[0].$or.push({
          ['meta.roles.owner.group.' + id]: true
        })
      })
      filter[0].$or.push({
        ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true
      })
      this.filter = filter;
    }, 500);
  }


}
