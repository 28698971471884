import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HmacRIPEMD160 } from 'crypto-js';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-suggest-features',
  templateUrl: './suggest-features.component.html',
  styles: [
  ]
})
export class SuggestFeaturesComponent implements OnInit {
  @Input() modal:any;
  isVisible:boolean=false;
  form: FormGroup | undefined;
  application = 'Generic';
  component = 'Feedback';
  params = {
    type: ''
  }
  list = {
    list: [],
    count: 0
  }
  
  visible:boolean=true;

  constructor( public gs:GlobalService , public fb: FormBuilder,public ar:ActivatedRoute ) { }

  ngOnInit(): void {
    this.initialize_form();
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
    })
  }

  initialize_form() {
    this.form = this.fb.group({
      application: this.application,
      component: this.component,
      titles: [null, this.gs.form_validations.required('title name')],
      description: [null],
      original_name:[null],
     });
   }

   async suggest_feature(){
    console.log('gorakh');
    this.form?.markAllAsTouched()
    let body = this.form?.value;
    body.application = 'Generic';
    body.component = 'Feedback';
    // body.type = 'feature';
    if(this.form?.invalid){
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.gs.toast('Suggested feature','Submitted Successfully')
      this.form?.reset()
      this.modal.closeModal();
    }
  }

  // openModal(){
  //   this.isVisible=true
  // }

  closeModal(){
    this.isVisible=true
  }
 }
