
<div a-style p=2.4>
    <m-text [type]="gs.enums.texts.paragraph104" text="Share With" mb=1.6></m-text>
    <m-input [type]="gs.enums.inputs.text" label="Contact Name" placeHolder="Search Contact" onlyInput="true"></m-input>
    <m-text [type]="gs.enums.texts.paragraph38" text="Contacts shared with" mb=1 mt=1.6></m-text>
    <div a-style br="1px solid #e4e4e4" mb=2.4>
        <ng-container *ngFor="let i of [1,2,3,4,5]"  [ngTemplateOutlet]=contact [ngTemplateOutletContext]="{details:{src:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/edit-pencil-black.svg', name:'Akash Sadavarte', email:'akashsadavarte@mesbro.com'}}"></ng-container>
    </div>
    <div a-style display="flex"  justify="flex-end">
        <m-button [types]="gs.enums.buttons.button6" text="Cancel"></m-button>
        <m-button [types]="gs.enums.buttons.button7" text="Done"></m-button>
    </div>
</div>
<ng-template #contact let-details=details>
    <div a-style display=flex p=0.8>
        <m-image src="{{details.src}}" h=4 w=4 brRadius=50%></m-image>
        <div a-style ml=0.8 w=90%>
            <m-text [type]="gs.enums.texts.paragraph38" text="{{details.name}}" mb=0.5></m-text>
            <m-text [type]="gs.enums.texts.paragraph10" text="{{details.email}}" mb=0.5></m-text>
        </div>
        <m-input [type]="gs.enums.inputs.dropdown" placeHolder="Contributer" [columns]="1/6"></m-input>
    </div>
</ng-template>
