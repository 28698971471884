import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-banner',
  templateUrl: './banner.component.html',
  styles: [
  ]
})
export class BannerComponent implements OnInit {
  @Input() height: any = 0
  @Input() image: string = ''
  @Input() type = ''
  @Input() fw = ''
  @Input() h = ''
  @Input() Width = ''
  @Input() mt = ''
  @Input() p = ''
  @Input() my = ''
  @Input() color = ''
  @Input() brRadius: any

  @Input() maxWidth = ''
  @Input() title: string = ''
  @Input() description: string = ''
  @Input() description1: string = ''
  @Input() globalCollection: boolean = false
  @Input() emailCollection: boolean = false
  @Input() padwithBorder: boolean = false

  application = 'Subscription';
  component = 'Newsletter';

  btn_txt: any
  btn: boolean = true


  list_url = `/dashboard/${this.application}/${this.component}/list`;

  // form: FormGroup | undefined;
  error: string;
  users: IUserData;
  email: any

  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    // this.initialize_form();
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;

      this.email = this.users?.activeUser?.contact_details?.email_address_primary
    });

    this.ar.queryParams.subscribe(async (params: any)=> {
      this.params = params;
      console.log(this.params)

    })


  }

  // initialize_form() {
  //   this.form = this.fb.group({
  //     application: this.application,
  //     component: this.component,
  //     email: [null, [this.gs.form_validations.email_address, Validators.required, Validators.email]],
  //     //  [this.gs.form_validations.email_address]
  //   });

  // }
  params: any = {
    type: ''
  }
  queryParams = {
    application: '',
    component: '',
    step: '',
    type_id: '',
    type: '',
    page: 0,
    query: <string | undefined>undefined,
  }
  async submit() {
    if (!this.users.activeUser?._id) {
      this.gs.router.navigate(['/idm/sign-in']);
    }
    else {
      if (this.btn == false) {
        try {
          let body = {
            application: this.application,
            component: this.component,
            email: this.email
          };
          let response = await this.gs.hps.post('generic', 'data', 'add', body);
          this.gs.toast('Newsletter', 'You have subscribed successfully')
        }
        catch (error) {
          console.log(error);
        }
      }else {
        this.gs.toast('Newsletter', 'You have Unsubscribed successfully')

      }
    }
  }

}
