import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-recipe-header',
  templateUrl: './recipe-header.component.html',
  styles: [
  ]
})
export class RecipeHeaderComponent implements OnInit {

  constructor(public gs:GlobalService) { }

  ngOnInit(): void {
  }

}
