<div class="cards" a-style w="23.4" a-style br="1px solid #e4e4e4" brRadius="0.6" p="1" position="relative">
    <!-- <img  src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" w="2" h="2" a-style position="absolute" top="2" right="2"  > -->
    <!-- <img src="../assets/images/assets/Suspended.svg" alt="" w="8.7" h="3.4" a-style position="absolute" bottom="1.6" right="1.6" *ngIf="!gs.router.url.includes('org/organization-home') && !gs.router.url.includes('org/organization-block') && !gs.router.url.includes('mesbro-central/verification-list?type=verification-pending') && !gs.router.url.includes('mesbro-central/verification-list?type=verified')"> -->
    <div a-style gap="1.6">
        <div a-style>
            <img [src]="detail?.files?.profile_picture? ('https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture) : '../assets/images/Icon_Bank/Organization_Coloured.svg'"
                a-style w="21.4" h="21" brRadius="0.6" (click)="redirection()">
        </div>
        <div a-style display="flex" justify="space-between" flexDirection="column" gap="0.5" align="left" flexGrow="1"
            mt="0.5">
            <p a-style fs="1.3" color="#2479AB" h="2" class=lineClamp (click)="redirection()">
                {{detail?.basic_details?.name}}</p>
            <p a-style fs="1.3">MOIN : <span a-style fs="1.3" color="#2479AB">{{detail?.meta?.support?.moin}}</span></p>
            <p a-style fs="1.3">Validity : <span a-style fs="1.3" color="#81BC06"> {{detail?.meta?.package?.expiry |
                    date}}</span></p>
            <!-- <p a-style fs="1.3">Verification Status : <span a-style fs="1.3" [style.color]="detail?.meta?.status == 'Approved' ? '#81BC06' : '#F35325'"> {{detail?.meta?.status}}</span></p> -->
            <p a-style fs="1.3">Active Status : <span
                    [style.color]="detail?.isActive == true ? '#81BC06' : '#F35325'">{{detail?.isActive?'Active' :
                    'Deactive'}}</span></p>
            <p a-style fs="1.3" *ngIf="istype=='collaborator'">Role : <span a-style fs="1.3">Collaborator</span></p>
            <p a-style fs="1.3">Collaborators : <span a-style color="#81BC06">0</span></p>
            <m-button *ngIf="!gs.router.url.includes('mesbro-central/verification-list') && istype!='collaborator'"
                [types]="gs.enums.buttons.button0" text="Delete Organization" maxWidth="21.4"
                (click)="otp.openModal(); delete_organization(detail?._id)"></m-button>
        </div>
    </div>
</div>
<!-- <div a-style mt="1" display="flex" justify="space-between" *ngIf="!gs.router.url.includes('mesbro-central/verification-list')">
    <p a-style fs="1.3" color="#2479AB" >0 Collaborators</p>
    <p a-style fs="1.3" color="#B2B2B2">Last updated on {{detail?.meta?.activity?.updated_at | date : 'dd MMM YYYY'}}, {{detail?.meta?.activity?.updated_at | date : 'shortTime'}} by {{detail?.meta?.activity?.updated_by}}</p>
</div> -->


<m-modal #otp width="49">
    <div a-style w="49" p=2.4 br="1px solid #e4e4e4" brRadius="0.6" align=left maxWidth="492px" h=fit-content>
        <img src="../assets/images/images/Mesbro_log.png" routerLink="/home">
        <p mb=1.3 color="##333333" a-style fs=2 mt=2.4>Verifying your mobile
            number</p>
        <p mb=1.3 color="##333333" a-style fs=1.3 mt=2.4>For your security,
            Mesbro wants to make sure that it's
            really you. We will send 6 digit verification code on your mobile.</p>
        <p a-style color=#81BC06 fs=1.6>{{users.activeUser?.contact_details?.mobile_number_primary}}</p>
        <m-otp [otp_id]=response?.otp_id [type]="type"
            [mobileNumber]="users.activeUser?.contact_details?.mobile_number_primary">
        </m-otp>
        <div a-style mt=5 display="flex" justify="space-between">
            <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5"
                (click)="otp.closeModal()">Back</button>
            <button a-style fs="1.3" br="1px solid #2479AB" bg="white" brRadius="0.5" px="1.4" py="0.5"
                (click)="otp.closeModal();delete_organization_with_product()">close</button>
        </div>
    </div>
</m-modal>


<!-- <m-success-modal #sucess title="Organization Deleted Successfully" >
</m-success-modal> -->