import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { th } from 'date-fns/locale';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-list',
  templateUrl: './generic-list.component.html',
  styles: [
  ]
})
export class GenericListComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('scrollMe') scrollMe: ElementRef

  @Input() application: string = '';
  @Input() component: string = '';
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() scroll: string = '';
  @Input() card: boolean = true;
  @Input() card_application: string = '';
  @Input() wrap: string = 'wrap';
  @Input() px: any;
  @Input() gap: any;
  @Input() size: any;
  @Input() minWidth: any;
  @Input() maxWidth: any;
  @Input() py: any;
  @Input() w: any;
  @Input() my = ''
  @Input() mb = ''
  @Input() ml = ''
  @Input() mt = ''
  @Input() mr = ''
  @Input() bg = ''
  @Input() justify = ''
  @Input() align = ''
  @Input() h = ''
  @Input() flexDirection = ''
  @Input() data_action = ''
  @Input() filters: any = {};
  @Input() global_search: boolean = false;
  @Input() news_search: boolean = false;
  @Input() load_more: boolean = false;
  @Input() index: any;
  @Input() flexWrap: any;
  @Input() add_card: boolean = false;
  @Input() add_item_text: string = '';
  @Input() add_item_link: string = '';
  @Input() selected_data: any = undefined;
  @Input() map_function: any;
  @Input() display: any;
  @Input() add_item_margin: any;
  @Input() add_card_mr: any;
  @Input() add_card_ml: any;
  @ViewChild('conversation_modal') conversation_modal: any;
  @ViewChild('catalogue_modal') catalogue_modal: any;
  @Input() has_query = undefined;
  activities: any = undefined;
  @Input() empty_image_show = false;
  @Input() scrollCategory: any;
  @Input() tpb: any = ''
  @Input() src: any;

  add_card_width: any = {
    Product: 43.75,
    idm: 40.5,
    Vehicle: 30.3,
    Recipe: 30.5,
    Subsidy: 43.75,
    Article: 43.75,
  }
  add_card_height: any = {
    Product: '',
    idm: '',
    Vehicle: '',
    Recipe: '',
    Article: '',
  }
  list: any = {
    list: [],
    count: 0
  };
  queryParams: any = {
    _id: '',
    type: '',
    application: ''
  }
  page = -1;

  card_type: any = {
    Vehicle: 'card_1',
    Recipe: 'card_1',
    Service: 'card_1',
    Horoscope: 'card_1',
    Spiritual: 'card_1',
    Activity: 'card_1',
    Project: 'card_1',
    Subsidy: 'card_1',
    Property: 'card_1',
    Article: 'card_1',
    Nursery: 'card_2',
    Product: 'card_2',
    Scrap: 'card_2',
    Refurbished: 'card_2',
    Used: 'card_2',
    Template: 'card_2',
  }

  constructor(public gs: GlobalService, public ar: ActivatedRoute) {
  }



  async ngOnInit() {

    await this.getList(this.filters);
    this.ar.queryParams.subscribe((params: any) => {
      this.queryParams = params
    })

    let scroll = document.createElement('style')
    scroll.innerHTML = `#scroll::-webkit-scrollbar {display: none;}`
    document.head.appendChild(scroll);

  }

  async getList(filter?: any) {

    // console.log(filter, "-Filte--");
    if (this.has_query) {
      filter.query = this.has_query
    }
    this.page = this.page + 1

    if (this.data_action) {
      this.list = await this.gs.hps.post('generic', 'data', this.data_action, { application: this.application, component: this.component,filters:filter}, 1);
      return;
    }
    if ((this.application == 'idm') || (this.component == 'activity' || this.component == 'shortlink' || this.component == 'campaign' || this.component == 'post')) {
      filter = filter || {}
      // filter.page = this.page
      let response = await this.gs.hps.post(this.application, this.component, 'list', filter || {}, 1);
      if (this.page == 0) {
        this.list = response
      } else {
        this.list.list = [...this.list.list, ...response.list]
      }
      if (this.map_function) {
        this.list.list = this.list.list.map(this.map_function)
      }
      return;
    }

    if (this.global_search) {
      filter.page = this.page
      
      let response = await this.gs.hps.post('generic', 'data', 'list', filter);
      if (this.page == 0) {
        this.list = response
      } else {
        this.list.list = [...this.list.list, ...response.list]
      }
      return
    }

    if (this.news_search) {
      let response: any = await (this.gs.hps.http.get('https://api.mesbro.com/api/generic/news/search?q=' + filter.query).toPromise());
      this.list = {
        count: response?.content?.articles?.length,
        list: response.content?.articles
      }
      return
    }
    let body = {
      application: this.application,
      component: this.component
    }

    // let response = await this.gs.get_data_list(this.application, this.component || this.application, filter || [], 1, this.page,this.has_query);
    let response = await this.gs.get_data_list(this.application, this.component || this.application, body || filter || [], 1, this.page, this.has_query);


    if (this.page == 0) {
      this.list = response
    } else {
      this.list.list = [...this.list.list, ...response.list]
    }
    // console.log(this.list);
  }

  async delete(id: any, index: number) {
    if (this.application === 'Idm' || this.application == 'idm' || this.component == 'shortlink') {
      await this.gs.hps.delete(this.application, this.component, id);
      this.list.list.splice(index, 1);
      return;
    } else {
      await this.gs.delete_from_list('data', id);
      this.list.list.splice(index, 1);
    }
  }
  action(label: string, data: any) {
    this.selected_data = data;
    if (label == 'Chat') {
      this.conversation_modal?.openModal()
    }
    if (label == 'Send Catalogue') {
      this.catalogue_modal?.openModal()
    }
  }

  card_width: any
  card_height: any
  addCardWidth() {
    if (this.gs.router.url.includes('product/list?type=my-organization')) {
      this.card_width = '242px'
      this.card_height = '340px'
      return this.card_width
      // return this.card_height
    }
    if (this.gs.router.url.includes('product/list?type=my-products')) {
      this.card_width = '244px'
      return this.card_width
    }
  }
  set_scroller() {
    let button: any = document.getElementById('scroller');
    let container: any = document.getElementById('scrollCategory')
    button.onclick = function () {
      container.scrollLeft += 20;
    }
  }

  right() {
    this.gs.scrollHorizontalRight(this.myScrollContainer)
    console.log('dfcggvgh')
  }


}



  // ngOnChanges(changes: SimpleChanges): void {
  //   this.getList();
  // }

  // if (this.global_search) {
  //   this.list = await this.gs.hps.post ('generic', 'data' , 'list', this.filters ) ;
  // return
  // }
