import {
  Component,
  OnInit,
  Input,
  HostBinding,
  forwardRef,
  Injector,
  ChangeDetectorRef,
  ViewChild,
  EventEmitter,
  Output,
  ContentChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Inputs } from 'src/enums/inputs';
// import { EditorModule } from '@tinymce/tinymce-angular';
import { GlobalService } from 'src/mesbro/services/global.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true,
};
// https://nominatim.openstreetmap.org/?addressdetails=1&q=mumbai&type=city&format=json
@Component({
  selector: 'm-input',
  templateUrl: './input.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class InputComponent implements ControlValueAccessor, OnInit {

  change_temp(v: any) {
    // console.log('8888888888888888888888', v)
  }

  @ViewChild('input_value') input_value: any;
  @ViewChild('image') image: any;
  @ContentChild('file') file: any;
  @HostBinding('style.display') display = 'contents';
  @Input() type: Inputs = this.gs.enums.inputs.text;
  @Input() direct_image: boolean = false;
  @Input() flexGrow: string = '';
  @Input() fixedWidth: string = '';
  @Input() label: string = '';
  @Input() passedData: any;
  @Input() cursor: string = '';
  @Input() mt: string = '1.8';
  @Input() minWidth: string = '35;1366:28.5';
  @Input() maxWidth: string = '';
  @Input() maxHeight: string = '';
  @Input() minHeight: string = '';
  @Input() m: string = '';
  @Input() mb: string = '';
  @Input() card_mb: string = '';
  @Input() p: string = '';
  @Input() pr: string = '';
  @Input() pl: string = '';
  @Input() pb: string = '';
  @Input() pt: string = '';
  @Input() flexGap: string = '';
  @Input() src: any = '';
  @Input() autofocus: any = '';
  @Input() prefix_mt: any = '';
  @Input() prefix_mr: any = '';
  @Input() margin_right: any = '';

  @Input() h: any = '';
  @Input() w: string = '';
  @Input() desc_width: string = '';
  @Input() desc_height: string = '';
  @Input() px: string = '';
  @Input() py: string = '';
  @Input() bg: string = '';
  @Input() alignItems: string = '';
  @Input() fs: string = '';
  @Input() top: string = '';

  @Input() ml: string = '';

  @Input() position: string = '';
  @Input() mr: string = '';
  @Input() info: string = '';
  @Input() description: string = '';
  @Input() input_px: string = '';
  @Input() clearIcon_mr: string = '';
  @Input() shadow: string = '';
  @Input() brRight: string = '';
  @Input() prefix_h: string = '';
  @Input() prefix_w: string = '';
  @Input() prefix_pl: string = '';
  @Input() bindLabel: string = '';
  @Input() bindValue: string = '';
  @Input() bindImage: string = '';
  @Input() border: boolean = false;
  @Input() placeHolder: string = '';
  @Input() onlyInput: boolean = false;
  @Input() labelTemplate: any = undefined;
  @Input() prefix: string = '';
  @Input() postfix: string = '';
  @Input() postfix2: string = '';
  @Input() postfix3: string = '';
  @Input() postfix_text: string = '';
  @Input() postfix2_text: string = '';
  @Input() postfix3_text: string = '';
  @Input() prefix_text: string = '';
  @Input() postfix_bg: any = '';
  @Input() outer_pr: string = '';
  @Input() postfix_px: string = '';
  @Input() dropdownHeight: string = '';
  @Input() dropdownWidth: string = '';
  @Input() factorDropdown: string = '';
  @Input() inputWidth: string = '';
  @Input() columns: any = 1;
  @Input() link: string = '';
  @Input() brRadius: any;
  @Input() br: any;
  @Input() disabled: Boolean = false;
  @Input() marginTop: string = '';
  @Input() marginBottom: string = '';
  @Input() otp: boolean = false;
  @Input() keyimage: string = '';
  @Input() flags: string = '';
  @Input() list: any;
  @Input() filtered_list: any;
  @Input() securitylist: any;
  @Input() radioName: any = '';
  @Input() data_type: any = 'string';
  @Input() error: any = undefined;
  @Input() rows: any;
  @Input() minRows: any;
  @Input() capitalize = true;
  @Input() uppercases = true;
  @Input() lowercase = false;
  @Input() selected: any;
  @Input() title: any;
  @Input() color: any;
  @Input() brTopLeftRadius: string = '';
  @Input() brTopRightRadius: string = '';
  @Input() brBottomRightRadius: string = '';
  @Input() brBottomLeftRadius: string = '';
  @Input() hide: string = '';
  @Input() wrap: string = 'wrap';
  @Input() overflowX: string = 'initial';
  @Input() maxLength: any;
  @Input() aspect_ratio = 1;
  @Input() min: any;
  @Input() multiple = false;
  @Input() is_arrow: boolean = true;
  @Input() country: boolean = false;
  @Input() placeholder=''
  @Output() clicked = new EventEmitter();
  @Output() prefix_clicked = new EventEmitter();
  @Output() postfix_clicked = new EventEmitter();
  @Output() postfix2_clicked = new EventEmitter();
  @Output() postfix3_clicked = new EventEmitter();
  @Output() country_code = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() profile_picture = new EventEmitter();
  @Output() selected_box = new EventEmitter();
  @Output() debounce = new EventEmitter();

  public Editor = ClassicEditor;

  value: any;
  value2: any = false;
  hovered = false;
  searchText: any;
  date: any = {
    year: undefined,
    month: undefined,
    day: undefined,
  };
  date_form_group: FormGroup | undefined;
  time_form_group: FormGroup | undefined;
  isTag: boolean = false;

  @Input() min_date = {
    year: 1850,
    month: 7,
    day: 10,
  };

  @Input() max_date = {
    year: 2021,
    month: 5,
    day: 2,
  };

  inputValue: any = { input: '', image: '' };
  selected_item: any = {};
  selected_index: any = -1;
  contentEditables: any = {};
  is_visible: boolean = false;
  hover: any = {};
  clearIcon = 'cross';
  searchIcon = 'search';
  country_dropDown: any = {
    label: 'India',
    country_code: '+91',
    image: '99/99/99/99/99/91/66.jpg',
  };
  control: FormControl | undefined;

  public selectedDate: any = { date: '27', month: 'july', year: '2021' };

  hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  minutes = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60,
  ];
  period = ['AM', 'PM'];

  years: any[] = [];
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  constructor(
    public gs: GlobalService,
    public injector: Injector,
    public cdr: ChangeDetectorRef,
    public fb: FormBuilder
  ) { }

  async ngAfterContentInit() {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  initialize_date() {
    if (this.type == 'date') {
      // console.log(this.value);
      if (this.value) {
        setTimeout(() => {
          let date = new Date(this.value);
          // console.log(
          //   '9999999999999999999999999999999',
          //   this.value,
          //   date.getFullYear(),
          //   date.getMonth(),
          //   date.getDate()
          // );
          this.date_form_group?.get('year')?.setValue(date.getFullYear());
          this.date_form_group?.get('month')?.setValue(date.getMonth() + 1);
          this.date_form_group?.get('day')?.setValue(date.getDate());
        }, 1000);
      }
    }
  }

  initialize_dropdowns() {
    setTimeout(() => {
      if (this.type == 'dropdown') {
        this.filtered_list = this.list;
        if (this.value) {
          this.inputValue.input = this.value;
        }
        if (this.bindValue && this.value) {
          this.inputValue.input = this.value;
          let item = this.filtered_list?.filter(
            (f: any) => f[this.bindValue] == this.value
          );
          this.selected_item = item[0];
          if (item) {
            this.inputValue.input =
              item[this.bindLabel] || item?.[0]?.[this.bindLabel];
          }
        }
      }
    }, 1000);
  }
  async ngOnInit() {
    this.gs.$country.subscribe((item) => {
      this.country_dropDown = item;
      // console.log(this.country_dropDown);
    });

    this.initial_date_settings();
    setTimeout(() => {
      // console.log('********************** Rows *****************************',this.minRows);
    });
    if (<any>document.querySelector('ck-editor__editable_inline')) {
      (<any>document.querySelector('ck-editor__editable_inline')).style.height =
        '200px';
      // console.log(<any>document.querySelector('ck-editor__editable_inline'));
    }

  }

  initial_date_settings() {
    if (this.type == 'date') {
      this.date_form_group = this.fb.group({
        year: null,
        month: null,
        day: null,
      });
      this.set_date_validations({});
      this.date_form_group?.valueChanges.subscribe((value) => {
        this.value = new Date(value.day + ' ' + value.month + ' ' + value.year);
        this.onChange(this.value);
        console.log(this.value, value);

        this.set_date_validations(value);
      });

      let touchedInterval = setInterval(() => {
        if (this.control?.touched) {
          this.date_form_group?.markAllAsTouched();
          clearInterval(touchedInterval);
        }
      }, 200);
    }
  }

  public focus() {
    this.input_value?.nativeElement.focus();
  }

  onChange: any = (event: any) => {
    // console.log('Hahahaha',event)
    this.changed.emit(event);
    this.internal_change();
  };
  onBlur: any = () => { };
  onTouch: any = () => {
    console.log(this.control);
  };

  debounce_time = 500;
  debounce_timeout: any = undefined;
  internal_change() {
    if (this.debounce_timeout) {
      clearTimeout(this.debounce_timeout);
      // console.log('Timeout Cleared')
    }
    this.debounce_timeout = setTimeout(() => {
      this.debounce.emit(this.value);
      // console.log('Debounce Emitted')
    }, this.debounce_time);
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.type == 'date') {
      this.initialize_date();
    }
    if (this.type == 'dropdown' || this.type == 'tags') {
      this.initialize_dropdowns();
    }
    if (this.type == 'checkbox') {
      // console.log(this.control, this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  capitalize_text(text: string) {
    if (!text) {
      return text;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  uppercase(text: string) {
    if (!text) {
      return text;
    }
    return text.toUpperCase();
  }
  set_date_validations(value: any) {
    this.years = [];
    for (let i = this.min_date.year; i <= this.max_date.year; i++) {
      this.years.push(i);
      this.date_form_group
        ?.get('year')
        ?.setValidators([
          Validators.required,
          Validators.max(this.max_date.year),
        ]);
    }

    if (value.year == this.min_date.year || value.year == this.max_date.year) {
      this.months = [];
      if (value.year == this.min_date.year) {
        for (let i: any = this.min_date.month; i <= 12; i++) {
          this.months.push(i);
        }
        this.date_form_group
          ?.get('month')
          ?.setValidators([
            Validators.required,
            Validators.min(this.min_date.month),
          ]);
        this.date_form_group
          ?.get('month')
          ?.updateValueAndValidity({ emitEvent: false });
      }
      if (value.year == this.max_date.year) {
        for (let i: any = 1; i <= this.max_date.month; i++) {
          this.months.push(this.months[i.value]);
        }
        this.date_form_group
          ?.get('month')
          ?.setValidators([
            Validators.required,
            Validators.max(this.max_date.month),
          ]);
        this.date_form_group
          ?.get('month')
          ?.updateValueAndValidity({ emitEvent: false });
      }

      if (
        value.month == this.min_date.month ||
        value.month == this.max_date.month
      ) {
        this.days = [];
        if (value.month == this.min_date.month) {
          for (let i = this.min_date.day; i <= 31; i++) {
            this.days.push(i);
          }
          this.date_form_group
            ?.get('day')
            ?.setValidators([
              Validators.required,
              Validators.min(this.min_date.day),
            ]);
        }
        if (value.month == this.max_date.month) {
          for (let i = 1; i <= this.max_date.day; i++) {
            this.days.push(i);
          }
          this.date_form_group
            ?.get('day')
            ?.setValidators([
              Validators.required,
              Validators.max(this.max_date.day),
            ]);
        }
      } else {
        this.days = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];
        this.date_form_group
          ?.get('day')
          ?.setValidators([Validators.required, Validators.max(31)]);
      }
    } else {
      this.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ];
      this.months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ];
      this.date_form_group
        ?.get('day')
        ?.setValidators([Validators.required, Validators.max(31)]);
      this.date_form_group
        ?.get('month')
        ?.setValidators([Validators.required, Validators.max(12)]);
      this.date_form_group
        ?.get('year')
        ?.updateValueAndValidity({ emitEvent: false });
      this.date_form_group
        ?.get('month')
        ?.updateValueAndValidity({ emitEvent: false });
      this.date_form_group
        ?.get('day')
        ?.updateValueAndValidity({ emitEvent: false });
    }
  }

  push(event: any) {
    console.log(this.value + '');
    this.value = Array.isArray(this.value) ? this.value : [];
    let values: string = event.target.value || this.inputValue.value;
    // if (this.bindLabel || this.bindValue) {
    //   value = { [this.bindLabel]: value, [this.bindValue]: value, }
    // }
    console.log(this.value, values);
    if (values) {
      let comma_seperated_values = values.split(',');

      comma_seperated_values.forEach((value: any) => {
        let index = this.value.findIndex((v: any) => {
          return v == value;
        });
        if (index == -1) {
          this.value.push(value);
        }
      });
    }
    this.inputValue.input = undefined;
    this.onChange(this.value);
  }

  pop(event: any) {
    let value = event.target.value;
    if (!value && !this.inputValue.input) {
      this.value.pop(value);
      this.onChange(this.value);
    }
  }

  remove(item: any) {
    this.value.splice(item[this.bindLabel] || item, 1);
    this.onChange(this.value);
  }
  changeSingleTag(event: any, index: any) {
    this.value[index] = event.target.innerText;
    this.onChange(this.value);
  }

  dropdown_keyboard_actions(event: any) {
    if (event.code == 'ArrowDown') {
      this.highlight_dropdown(event, 'down');
    } else if (event.code == 'ArrowUp') {
      this.highlight_dropdown(event, 'up');
    } else if (event.code == 'Escape') {
      this.is_visible = false;
    } else if (event.code == 'Enter') {
      // if(this.type != 'multiSelect'){
      this.select_dropdown(
        event,
        this.filtered_list[this.selected_index] || this.filtered_list[0]
      );
      // }
      this.is_visible = false;
    } else if (event.code == 'Tab') {
      this.select_dropdown(
        event,
        this.filtered_list[this.selected_index] || this.filtered_list[0]
      );
    } else {
      this.is_visible = true;
      setTimeout(() => {
        this.filtered_list = this.filter_list(event.target.value);
      }, 50);
    }
  }

  filter_list(search_text: string) {
    if (!search_text) {
      return this.list;
    }
    return this.list?.filter((item: any) => {
      let bind_item = (item[this.bindLabel] || item) + '';
      return bind_item.toLowerCase().includes((search_text + '').toLowerCase());
    });
  }

  highlight_dropdown(event: any, action: string) {
    if (!this.is_visible) {
      this.filtered_list = this.list;
      this.is_visible = true;
      return;
    }
    if (
      action == 'down' &&
      this.selected_index < this.filtered_list.length - 1
    ) {
      this.selected_index++;
    } else if (
      action == 'up' &&
      this.selected_index >= 1 &&
      this.filtered_list.length != 1
    ) {
      this.selected_index--;
    }
    let item = this.filtered_list[this.selected_index];
    this.selected_item = item;
  }

  select_dropdown(event: any, item: any) {
    this.clearIcon = 'cross';
    if (this.type == this.gs.enums.inputs.multiSelect) {
      this.value = Array.isArray(this.value) ? this.value : [];
      this.value.push(
        event?.target?.value || this.bindValue ? item[this.bindValue] : item
      );
      this.onChange(this.value);
      this.inputValue.input = undefined;
      return;
    }
    this.value = this.bindValue ? item[this.bindValue] : item;
    this.inputValue.input = this.bindLabel ? item[this.bindLabel] : item;

    this.selected_item = item;

    if (this.country) {
      this.country_dropDown = item;
      this.gs.$country.next(item);
      // console.log(item);
    }
    // console.log(this.control);
    this.onChange(this.value);
    this.searchText = undefined;
  }

  change(event: any) {
    console.log(event.target.checked);
    this.value = event.target.checked;
    this.onChange(this.value);
    console.log(this.value);
    
  }

  async upload_pic(type: string, event: any) {
    let selected_file = await event.target.files[0];
    console.log(selected_file);
    let form_data = new FormData();
    form_data.append('name', 'avatar');
    form_data.append('avatar', selected_file);
    let options = {
      headers: {
        token: this.gs.user.users.activeUser?.token,
      },
    };
    let data = await this.gs.hps.post('', '', 'upload', form_data);
  }

  set_image(event: any) {
    if (this.multiple) {
      this.value = this.value || [];
      this.value.push(event[0].url);
    } else {
      this.value = event[0].url;
    }
    console.log(this.value);
    this.onChange(this.value);
  }

  set_file(event: any) {
    if (this.multiple) {
      this.value = this.value || [];
      this.value.push(event.url);
    } else {
      this.value = event;
    }
    this.onChange(this.value);
  }
  select_index: any;
  select(index: any, selected_item: any) {
    this.select_index = index;
    // this.selected_box.emit(selected_item)
    this.value = selected_item.title;
    this.onChange(this.value);
    console.log(this.value);
  }

  switch_toggle(event: any) {
    console.log(event.target.checked);
    this.value = event.target.checked;
    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  upload_event(event: any) {
    let selectedFile = event.target.files[0];
    // console.log(selectedFile);
    this.upload_file(selectedFile);
  }

  attachments: any = [];

  async upload_file(file: any) {
    let selected_file = file;
    // console.log(selected_file);
    let form_data = new FormData();
    form_data.append('name', 'avatar');
    form_data.append('avatar', selected_file);
    // console.log('Before Api Call');
    let data = await this.gs.hps.post('', '', 'upload', form_data);

    data.content_type = data.mimetype;
    data._id = data?._id?._id;
    delete data.meta;
    // console.log('Emitted ', data);
    this.attachments.push(data);
    // console.log(this.attachments);
    this.onChange(this.attachments);
  }

  onPaste(event: any, input: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let body = { target: { value: pastedText } };
    setTimeout(() => {
      this.push(body);
    }, 50);
  }

//   editorConfig: AngularEditorConfig = {
//     editable: true,
//     spellcheck: true,
//     height: 'auto',
//     minHeight: '0',
//     maxHeight: 'auto',
//     width: 'auto',
//     minWidth: '0',
//     translate: 'yes',
//     enableToolbar: true,
//     showToolbar: true,
//     placeholder: 'Enter text here...',
//     defaultParagraphSeparator: '',
//     defaultFontName: '',
//     defaultFontSize: '',
//     fonts: [
//       { class: 'arial', name: 'Arial' },
//       { class: 'times-new-roman', name: 'Times New Roman' },
//       { class: 'calibri', name: 'Calibri' },
//       { class: 'comic-sans-ms', name: 'Comic Sans MS' }
//     ],
//     customClasses: [
//       {
//         name: 'quote',
//         class: 'quote',
//       },
//       {
//         name: 'redText',
//         class: 'redText'
//       },
//       {
//         name: 'titleText',
//         class: 'titleText',
//         tag: 'h1',
//       },
//     ],
//     uploadUrl: 'v1/image',
//     upload: (file: File) => { ... }
//   uploadWithCredentials: false
//   sanitize: true
//   toolbarPosition: 'bottom'
//   toolbarHiddenButtons: [
//     ['bold', 'italic'],
//     ['fontSize']
//   ]
// };
}

