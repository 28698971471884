<div a-style p="2" position="relative">
    <div a-style align="center">
        <m-text a-style mb="1" [type]="gs.enums.texts.paragraph12" text="Sign In Required"></m-text>
        <m-text a-style mb="1" [type]="gs.enums.texts.paragraph1" text="Proceed to Sign In?"></m-text>
        <div a-style display="flex" gap="1" justify="center">
            <m-button a-style px="1.5" h="2.6" py="1.5" bg="#126EB4" [types]="gs.enums.buttons.button81" text="May be Later"></m-button>
            <m-button a-style px="1.5" h="2.6" py="1.5" bg="#f35325" [types]="gs.enums.buttons.button81" text="Sign In"  routerLink="/idm/sign-in"></m-button>
        </div>
    </div>
    <div>
        <!-- <m-image a-style position="absolute" top="1" right="1" a-style w="2" h="2" [src]="gs.enums.images.image256"> </m-image> -->
    </div>
</div>