import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-application-tray',
  templateUrl: './application-tray.component.html',
  styles: [
  ]
})
export class ApplicationTrayComponent implements OnInit {
  @Input() modal: any
  @Output() close_modal = new EventEmitter();
  users: IUserData | undefined;
  loginRequired: any
  params = {
    type:''
    // messageId:'',
  }
  @ViewChild('signin_rquired') signin_rquired : any;
  constructor(public gs: GlobalService) { }

  search: FormControl = new FormControl();
  ngOnInit(): void {
    this.gs.user.users$.subscribe(users => {
      this.users = users;
    })
  }

  select_application(data: any) {
    this.loginRequired = this.users?.activeUser?._id
    // if (!this.loginRequired) {
    //   this.gs.router.navigateByUrl('idm/sign-in')
    // }
    if (data.link) {
      if ((data.application=="ME" ||  data.application=="Chat" ||  data.application=="Organization" || data.application=="Mail" || data.application=="Contact") &&  !this.gs.user.users.activeUser?._id ) {
        this.gs.router.navigateByUrl('idm/sign-in')
        return
    }
      this.gs.router.navigateByUrl(`/${data.link}`)
    } else {
      this.gs.router.navigateByUrl(`${data.application.toLowerCase()}/home`)
    }
    this.close_modal.emit('Close')
  }
  onKeyUp(event: any) {
    console.log(this.search)
    this.gs.router.navigate(['/product', 'list'], { queryParams: { type: 'product', query: this.search.value }});
  }
}




