import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-add-campaign',
  templateUrl: './add-campaign.component.html',
  styles: [
  ]
})
export class AddCampaignComponent implements OnInit {

  @Input() modal: any
  verify = false;
  notVerify =  false;
  user: any;
   @Output() cancel = new EventEmitter();

  application = 'generic';
  component = 'campaign';

  records:any;
  


  form_data = {
    campaign_name: this.fb.control('',[Validators.required],),
    campaign_picture: '',

  }

  queryParams: any = {
    _id: ''
  }

  form: FormGroup | undefined;


  constructor(public renderer: Renderer2, public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))

  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'campaign', params._id);
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: undefined,
      token: undefined,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }

  // async dns_record(){
  //   let body = this.domain;
  //   let response = await this.gs.hps.post('Idm', this.component, body,[])
  //   console.log(response);
    
  // }
 

  async submit() {
    let body = this.form?.value;
    console.log(body);

    if (this.form?.value._id) {
      let response = await this.gs.hps.put('generic', 'campaign', undefined, body);
    } else {
      let response = await this.gs.hps.post('generic', 'campaign', 'add', body);
      this.gs.toast('Campaign', 'Added Successfully', 'success')
      this.gs.router.navigate(['short-link', 'list'], { queryParams: { type: 'Campaign' } });
    }
  }

  closeModel(){
    this.verify=false;
    console.log('close model');
  }
}
