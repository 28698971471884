<div a-style display="flex" ml="18" gap="18">
  <div>
    <div a-style display="flex" alignItems="center" mt="1.6">
      <m-text [type]="gs.enums.texts.paragraph5" ml=0 text="About 3,94,00,00,000 results (0.50 seconds)"></m-text>
    </div>
    <div a-style display="flex" gap="10">
      <div a-style overflowY="auto" maxWidth="71.9">
        <ng-container *ngIf="params.application == 'All' " [ngTemplateOutlet]="text_list" [ngTemplateOutletContext]="{passedData : {value1 : 'https://maps.google.co.in', value2 :'Mesbro' , value3 :' Find local businesses, view maps and get driving directions in akshay Maps.'  , value4 :'You visited this page on 6/8/21.'  }}"></ng-container>
        <div a-style mt="3" brBottom="1px solid #E4E4E4">
          <m-text [type]="gs.enums.texts.paragraph123" text="People also ask"></m-text>
          <ng-container *ngFor="let title of faq_items" [ngTemplateOutlet]="faq" [ngTemplateOutletContext]="{item:title}"></ng-container>
        </div>
        <div a-style mt="3">
          <m-text [type]="gs.enums.texts.paragraph123" text="Related Searches"></m-text>
          <div a-style display="flex" flexWrap="wrap" gap="1">
            <ng-container *ngFor="let item of [1,1,1,1,1,1,1,1,1,1] " [ngTemplateOutlet]="related" [ngTemplateOutletContext]="{passedData : {value1 : 'akshay', value2 :' auchar' }}"></ng-container>
          </div>
        </div>
        <div a-style maxWidth="71.9" mt="4.4">
          <div a-style display="flex" justify="center">
            <div a-style display="flex">
              <div a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/m (1).svg" a-style h="4"></m-image>
              </div>
              <div *ngFor="let item of [1,1,1,1,1] " a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/e.svg" a-style h="2"></m-image>
              </div>
              <div a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/s.svg" a-style h="2"></m-image>
              </div>
              <div a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/b.svg" a-style h="2"></m-image>
              </div>
              <div a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/r.svg" a-style h="1.6"></m-image>
              </div>
              <div a-style display="flex" px="1" justify="center" br="1px solid #E4E4E4" alignItems="center">
                <m-image src="../assets/images/assets/o.svg" a-style h="2"></m-image>
              </div>
            </div>
          </div>

          <div a-style display="flex" justify="center" gap="1" maxWidth="71.9">
            <div a-style display="flex" gap="3">
              <div a-style display="flex" justify="center">
                <ng-container *ngFor="let title of number_items" [ngTemplateOutlet]="number " [ngTemplateOutletContext]="{item:title}"></ng-container>

              </div>
              <div a-style display="flex" justify="center" alignItems="center" gap="1">
                <!-- <m-text [type]="gs.enums.texts. paragraph39"  text="Next"></m-text> -->
                <p a-style fs="1.4" fw="400" color=" hov: #4285f4" cursor="pointer">Next</p>
                <m-image src="../assets/images/assets/arrow.svg" a-style></m-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div a-style br="1px solid #E4E4E4" mr="2.4" maxWidth="36.8" h="60" brRadius="0.6">
        <div a-style position=relative>
          <div a-style bgUrl="https://mesbro.in/assets/images/temp-images/landingimg-6.png " w=100% h=15 brTopLeftRadius="0.6" brTopRightRadius="0.6" bgRepeat="no-repeat" bgSize="cover"> </div>
          <div a-style py="1.2" px="1.5">
            <div a-style display="flex" align="center" justify="space-between">
              <m-text [type]="gs.enums.texts.paragraph119" text=" akshay"></m-text>
              <m-image src="https://mesbro.in/assets/images/share-grey.svg" a-style w="2.4" h="2.4"></m-image>
            </div>
            <m-text [type]="gs.enums.texts.paragraph39" text="Website" color="#70757A"></m-text>
          </div>
          <div a-style display="flex" gap="2.4" px="1.5" br=" 1px solid #e4e4e4" py="0.8">
            <m-image src="https://mesbro.in/assets/images/share-grey.svg" a-style w="2.4" h="2.4"></m-image>
            <m-text [type]="gs.enums.texts.paragraph39" text="Google.com" color="#1A0DAB"></m-text>
          </div>
          <div a-style display="flex" gap="2.4" px="1.5" py="0.8">
            <m-text [type]="gs.enums.texts.paragraph120" text="People also search for" color="#1A0DAB"></m-text>
            <m-text [type]="gs.enums.texts.paragraph39" text="Views 10+ more" color="#70757A"></m-text>
          </div>
          <hr a-style color="#E4E4E4">
        </div>
        <div a-style py="1.2" px="1.5">
          <m-text [type]="gs.enums.texts.paragraph39" color="#4D5156" text="Google Meet is a video-communication service developed by Google.
    It is one of two apps that constitute the replacement for Google Hangouts, the other being Google Chat. Wikipedia" a-style mt="0"></m-text>
        </div>
        <div a-style display="flex" gap="0.5" px="1.5" py="0.8">
          <m-text [type]="gs.enums.texts.paragraph39" text="Date Launched : " color="#222222" fw="600"></m-text>
          <m-text [type]="gs.enums.texts.paragraph39" text=" 27 july 2021" color="#222222"></m-text>
        </div>
      </div>
    </div>
  </div>
</div>




<ng-template #number let-item=item>
  <div a-style display="flex" w="4.2" h="5" justify="center" alignItems="center">
    <p a-style fs="1.4" fw="400" color=" hov: #4285f4" cursor="pointer">{{item.title}}</p>
  </div>
</ng-template>

<ng-template #related let-passedData="passedData">
  <div a-style display="flex" alignItems="center" minWidth="31.8" h="4.8" px="1.7" bg="#f1f3f4" brRadius="20" mt="1.6">
    <div a-style p="1">
      <m-image a-style src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" w="1.5" h="1.5"></m-image>
    </div>
    <m-text [type]="gs.enums.texts.paragraph122" text={{passedData.value1}} ml="1.6"></m-text>
    <m-text a-style [type]="gs.enums.texts.paragraph121" text={{passedData.value2}} ml="0.5"></m-text>
  </div>
</ng-template>

<ng-template #text_list let-passedData="passedData">
  <div a-style mt="1.6">
    <m-text [type]="gs.enums.texts.paragraph39" text={{passedData.value1}} color="#202124"></m-text>
    <m-text [type]="gs.enums.texts.paragraph118" text={{passedData.value2}} color="#660099"></m-text>
    <m-text [type]="gs.enums.texts.paragraph39" text={{passedData.value3}} text="Find local businesses, view maps and get driving directions in akshay Maps." color="#4D5156"></m-text>
    <m-text [type]="gs.enums.texts.paragraph39" text={{passedData.value4}} text="You visited this page on 6/8/21." color="#70757A"></m-text>
  </div>
</ng-template>

<ng-template #faq let-item=item>
  <div (click)="is_visible.faq_answer = true" a-style display="flex" alignItems="center" py="1" mt="0.8" justify="space-between" brTop="1px solid #E4E4E4" maxWidth="71.9">
    <m-text [type]="gs.enums.texts.paragraph121" text=" {{item.title}}"></m-text>
    <img src="../assets/images/assets/dropdown.png" cursor=pointer a-style w="1" h="1">
  </div>
  <div a-style *ngIf="is_visible.faq_answer">
    <p> akshay</p>
  </div>
</ng-template>
