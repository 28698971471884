<!-- <div a-style display="flex" align="center" flexWrap=wrap gap=2.4 px="2.4" mt=2.4 mb="2.4" *ngIf="queryParams._id">
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Primary',subtitle:'Agriculture',bg:'#16e816ed',ml:'-2'}}"></ng-component>
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Secondary',subtitle:'Agriculture',bg:'#3090b8'}}"></ng-component>
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Tertiary',subtitle:'Agriculture',bg:'#f6be08'}}"></ng-component>
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Attribute Count',subtitle:'Agriculture',bg:'#7063f3'}}"></ng-component>
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Faqs Count',subtitle:'Agriculture',bg:'#fa3658'}}"></ng-component>
    <ng-component [ngTemplateOutlet]="imgAtr" [ngTemplateOutletContext]="{txt:{title:'Tag Count',subtitle:'Agriculture',bg:'#362929'}}"></ng-component>
</div> -->

<ng-template #imgAtr let-txt=txt>
    <div a-style bg={{txt.bg}} br="1px solid #e4e4e4" brRadius="0.6" textCenter="center" pt="1.5" minWidth=22 [ml]="txt.ml">
        <m-text [type]="gs.enums.texts.paragraph48" text={{txt.title}}></m-text>
        <div a-style brTop="1px solid #e4e4e4" bg="#ffffff" textCenter="center" mt="1.5" py="1.5">
            <m-text [type]="gs.enums.texts.paragraph49" text={{txt.subtitle}}></m-text>
        </div>
    </div>
</ng-template>
<div mt=4.8 a-style></div>
<m-text *ngIf="!queryParams._id" a-style ml="2.4" mt=2.6 text="Create {{queryParams.sub_type == 'Primary' || queryParams.sub_type == 'Secondary' || queryParams.sub_type == 'Tertiary'? queryParams.sub_type: '' }} {{queryParams.type}}" [type]="gs.enums.texts.heading1"></m-text>
<m-text *ngIf="queryParams._id" a-style ml="2.4" text="Update {{form.value.title}} {{queryParams.type}}" [type]="gs.enums.texts.heading1"></m-text>
<form *ngIf="form" [formGroup]="form">
    <div>
        <m-form *ngIf="queryParams.sub_type!='Primary'" w="initial" formGroupName="parents" title='' px="0.6" mx="2.4" [mt]="2.4">
            <m-input minWidth="35;321:30" *ngIf="dropdowns.Primary.length" [capitalize]=false [list]="dropdowns.Primary" bindLabel=title bindValue=_id (blurred)="queryParams.sub_type!='Secondary' && refresh_list('Secondary')" formControlName="primary" [columns]="1/3" mt=1.8 label="Primary  Category" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content" placeHolder="Primary Select Category" mb=0></m-input>
            <m-input minWidth="35;321:30" [list]="dropdowns.Secondary" bindLabel=title bindValue=_id (blurred)="queryParams.sub_type!='Tertiary' &&  refresh_list('Tertiary')" formControlName="secondary" [columns]="1/3" mt=1.8 label="Secondary Category" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content" placeHolder="Secondary Category" *ngIf="queryParams.sub_type!='Secondary' && dropdowns.Secondary.length" mb=0></m-input>
            <m-input minWidth="35;321:30" [list]="dropdowns.Tertiary" bindLabel=title bindValue=_id formControlName="tertiary" [columns]="1/3" mt=1.8 label="Tertiary Category" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content" placeHolder="Tertiary Category" *ngIf="queryParams.sub_type!='Tertiary' && queryParams.sub_type!='Secondary' && dropdowns.Tertiary.length" mb=0></m-input>
        </m-form>
    </div>
    <div>
        <m-form w="initial" title='Basic Details' px="1.4" m="2.4">
            <m-input minWidth="35;321:30" [disabled]="true"  formControlName="application" label="Application" [type]="gs.enums.inputs.dropdown" [list]="dropdowns.applications" [columns]="1/4" mb=1></m-input>
            <m-input minWidth="35;321:30" [disabled]="true"   formControlName="type" label="Type" [type]="gs.enums.inputs.dropdown" [list]="dropdowns.structure_types" [columns]="1/4" mb=1></m-input>
            <m-input minWidth="35;321:30" [disabled]="true" formControlName="sub_type" label="Sub Type" [type]="gs.enums.inputs.dropdown" [list]="dropdowns.structure_sub_types" [columns]="1/4" mb=1></m-input>
            <m-input minWidth="35;321:30" *ngIf="queryParams.sub_type=='Type'" formControlName="unit" label="Default Unit" [columns]="1/4" mb=0></m-input>
            <m-input minWidth="35;321:30" formControlName="title" [capitalize]=false label="Title" [columns]="1" mb=0></m-input>
            <m-input minWidth="35;321:30" formControlName="application" [capitalize]=false *ngIf="queryParams.sub_type =='Type'" label="Application" [columns]="1/3" mb=0></m-input>
            <m-input minWidth="35;321:30" formControlName="component" [capitalize]=false *ngIf="queryParams.sub_type =='Type'" label="Component" [columns]="1/3" mb=0></m-input>
            <m-input minWidth="35;321:30" formControlName="description" [capitalize]=false label="Description" [columns]="1" mb=0></m-input>
            <m-input minWidth="35;321:30" formControlName="tags" [capitalize]=false type=tags label="Tags" [columns]="1" mb=0></m-input>
        </m-form>
    </div>

    <m-form w=" initial" title='Frequently Asked Questions' px="0.6" mx="2.4" [mt]="2.4" *ngIf="(queryParams.sub_type =='Type') && (queryParams.group =='faq')">
        <div a-style display="flex" mt=2.4 alignItems="center" w="100%">
            <m-input minWidth="35;321:30" placeHolder="Faq Content" columns="1" flexGrow="1" mb=0></m-input>
            <m-button text="Add Faq" [types]="gs.enums.buttons.button1"></m-button>
        </div>

        <m-text text="Faqs" [type]="gs.enums.texts.paragraph15" mt="2.4" ml=1.6></m-text>

        <div a-style display="flex" alignItems="center" w="100%">
            <m-text a-style color="red" flexGrow="1" w="100%" text="My Questionb" [type]="gs.enums.texts.paragraph1" mt="1" ml=1></m-text>
            <m-button text="Remove" marginTop="0"></m-button>
        </div>
    </m-form>

    <div a-style m="2.4">
        <m-form title='Add Attribute' px="2.4" ml="0">
            <div a-style w=100%>
                <table formGroupName="attributes" a-style mt="1.4" W=100% borderCollapse=collapse>
                    <tr a-style align="center">
                        <th w=17 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Heading</th>
                        <th w=10% a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Title</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Columns</th>
                        <th w=15 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Type</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Required</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Dropdowns</th>
                        <th w=10% a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Units</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Filter</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Multiple</th>
                        <th w=4 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Action</th>
                    </tr>
                    <tr>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input #heading_input bg=transparent px=0 br='none' type=dropdown minWidth=none [list]="dropdowns.headings" [(ngModel)]="attribute_form.group" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent minWidth=none px=0 br='none' [(ngModel)]="attribute_form.title" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent px=0 br='none' type=dropdown minWidth=none [list]=[1,2,3,4,5,6,7,8,9,10,11,12] [(ngModel)]="attribute_form.columns" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent px=0 br='none' type=dropdown minWidth=none [list]="dropdowns.types" [(ngModel)]="attribute_form.type" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent px=0 br='none' type=dropdown minWidth=none [list]="dropdowns.confirmation" [(ngModel)]="attribute_form.validations.required" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent px=0 br='none' type=tags mt=0 [(ngModel)]="attribute_form.data.items" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent minWidth=none px=0 br='none' type=tags mt=0 [(ngModel)]="attribute_form.units" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent type=dropdown minWidth=none br=none [list]="dropdowns.confirmation" px=0 [(ngModel)]="attribute_form.filter" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" a-style br="1px solid #e4e4e4">
                            <m-input bg=transparent type=dropdown minWidth=none br=none [list]="dropdowns.confirmation" px=0 [(ngModel)]="attribute_form.multiple" [ngModelOptions]="{standalone: true}" columns="1/6" flexGrow="1" mb=0></m-input>
                        </td>
                        <td p=00 px="0.5;1280:0.4;1024:0.2" br="1px solid #e4e4e4" align="center" a-style cursor="pointer" (click)="add_attribute(attribute_form.group || '',attribute_form.title || ''); heading_input.focus()">
                            <!-- <m-button text="Add" [types]="gs.enums.buttons.button7"></m-button> -->
                            <m-image src="../assets\images\assets\add.svg" h="1.5" w="1.5"></m-image>
                        </td>
                    </tr>

                </table>
            </div>
        </m-form>

        <m-form px="2.4" mt=2 ml="0" [title]="heading.key | case : 'capital'" *ngFor="let heading of (attributes.controls | keyvalue); let i= index;">
            <div a-style w=100%>
                <table formGroupName="attributes" a-style mt="1.4" w=100% borderCollapse=collapse>
                    <tr a-style align="center">
                        <th w=3 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Sr No</th>
                        <th w=20 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Title</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Columns</th>
                        <th w=15 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Type</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Required</th>
                        <th a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Dropdowns</th>
                        <!-- <th w=10% a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Units</th> -->
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Filter</th>
                        <th w=10 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Multiple</th>
                        <th w=4 a-style br="1px solid #e4e4e4" p="1.6;1280:1.4;1024:1.2">Action</th>
                    </tr>

                    <ng-container [formGroupName]="heading.key">
                        <ng-container [formGroupName]="attribute.key" *ngFor="let attribute of (heading.value.controls | keyvalue) | sort : 'product-type-index'; let j=index">
                            <tr a-style>
                                <td p=00 px="00" textCenter=center a-style br="1px solid #e4e4e4"> {{i+1}}.{{j+1}} </td>

                                <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <!-- <p a-style fs=1.3 pl=1 >  {{form.value.attributes[heading.key][attribute.key].index}}  </p > -->

                                    <m-input p=0 minWidth="35;321:30" br='none' formControlName="title" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td>
                                <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <m-input minWidth="35;321:30" px=0 br='none' type=dropdown [list]=[1,2,3,4,5,6,7,8,9,10,11,12] formControlName="columns" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td>
                                <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <m-input minWidth="35;321:30" px=0 br='none' type=dropdown [list]="dropdowns.types" formControlName="type" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td>
                                <ng-container formGroupName="validations">
                                    <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                        <m-input minWidth="35;321:30" px=0 br=none type=dropdown [list]="dropdowns.confirmation" formControlName="required" columns="1/6" flexGrow="1" mb=0></m-input>
                                    </td>
                                </ng-container>
                                <ng-container formGroupName="data">
                                    <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                        <m-input minWidth="35;321:30" px=0 br='none' type=tags mt=0 formControlName="items" columns="1/6" flexGrow="1" mb=0></m-input>
                                    </td>
                                </ng-container>
                                <!-- <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <m-input minWidth="35;321:30" px=0 br='none' type=tags mt=0 formControlName="units" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td> -->
                                <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <m-input minWidth="35;321:30" minWidth=0 px=0 type=dropdown br=none [list]="dropdowns.confirmation" formControlName="filter" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td>
                                <td p=00 px="00" a-style br="1px solid #e4e4e4">
                                    <m-input minWidth="35;321:30" px=0 type=dropdown br=none [list]="dropdowns.confirmation" formControlName="multiple" columns="1/6" flexGrow="1" mb=0></m-input>
                                </td>

                                <!-- <td a-style p=00 px="00" br="1px solid #e4e4e4" align="center" a-style cursor="pointer" >

                                </td> -->
                                <!-- src="../assets/images/assets/luggage bages,Cases.jpg" -->
                                <td a-style p=00 px="00" br="1px solid #e4e4e4" align="center" a-style cursor="pointer" >
                                    <!-- <m-image  (click)="gs.delete_from_list('structure',structure._id,list.list,i  src="../assets/images/assets/delete colour.svg" h="1.5" w="1.5"></m-image> -->

                                    <m-image (click)="selected.group = heading.key; selected.attribute = attribute.key; attribute_modal.openModal(); toggle_value()" src="../assets/images/assets/edit colour.svg" h="1.5" w="1.5"></m-image>
                                    <m-image (click)="remove_attribute(heading.key,attribute.key); selected.group=undefined; selected.attribute=undefined" a-style ml=1 src="../assets/images/assets/delete colour.svg" h="1.5" w="1.5"></m-image>

                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </m-form>
    </div>

    <m-form *ngIf="queryParams.sub_type!= 'Fixed'" columns="3" title="Media" m=2.4 px="2.4" w=auto>
        <!-- <ng-conntainer [ngTemplateOutlet]="media" *ngFor="let item of media_list" [ngTemplateOutletContext]="{passedData : item }"></ng-conntainer> -->
        <ng-conntainer *ngIf="queryParams.sub_type =='Primary' || queryParams.sub_type =='Secondary'  || queryParams.sub_type == 'Tertiary'" [ngTemplateOutlet]="media" [ngTemplateOutletContext]="{passedData : {name:'faded', aspect_ratio:'1'} }"></ng-conntainer>
        <ng-conntainer *ngIf="queryParams.sub_type =='Primary' || queryParams.sub_type == 'Secondary'  || queryParams.sub_type == 'Tertiary'" [ngTemplateOutlet]="media" [ngTemplateOutletContext]="{passedData : {name:'colored', aspect_ratio:'1'} }"></ng-conntainer>
        <ng-conntainer *ngIf="queryParams.sub_type == 'Type'" [ngTemplateOutlet]="media" [ngTemplateOutletContext]="{passedData : {name:'image', aspect_ratio:'1'} }"></ng-conntainer>
    </m-form>


    <div a-style display=flex justify=flex-end m=2.4>
        <m-button (click)="submit()" [types]="gs.enums.buttons.button18" [text]="form.value._id? 'Update' : 'Submit'"></m-button>
    </div>
</form>

<ng-template #media let-passedData='passedData'>
    <div a-style display="flex" br="1px solid #e2e2e2" p="1" mr="1.6" mt="1.6" brRadius="0.5">
        <div [formGroup]='form'>
            <m-text [type]="gs.enums.texts.paragraph43" text="{{passedData.name | case : 'capital' }} / Logo" mb="1"></m-text>
            <p a-style fs="1.1" maxWidth="19.1" mb="1">Lorem ipsum dolor sit amet, consetetur sdipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
            <ng-container formGroupName="images">
                <m-input minWidth="35;321:30" [aspect_ratio]="passedData.aspect_ratio" #file [type]="gs.enums.inputs.file" [formControlName]="passedData.name" [onlyInput]="true" [columns]="false">
                    <m-button file [types]="gs.enums.buttons.button18" text="Add Photo"></m-button>
                </m-input>
            </ng-container>
        </div>
        <div>
            <m-image [src]="form.value.images[passedData.name]? ('https://new-api.mesbro.com/uploads/'+form.value.images[passedData.name]):gs.enums.images.image154" h="7" w="auto" brRadius=0.4 (click)="file.image.openModal()"> </m-image>
        </div>
    </div>
</ng-template>

<m-modal #attribute_modal width="100">
    <form *ngIf="form" [formGroup]="form">
        <ng-container formGroupName='attributes'>
            <m-form *ngIf="selected.group" title='Basic Details' [formGroupName]='selected.group'>
                <ng-container *ngIf="toggle" [formGroupName]="selected.attribute">
                    <m-input minWidth="35;321:30" formControlName="title" label="Title" [columns]="1" mb=0  [capitalize]=false></m-input>
                    <m-input minWidth="35;321:30" type=dropdown [list]="dropdowns.headings" formControlName="group" label="Group" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                    <m-input minWidth="35;321:30" formControlName="type" type=dropdown [list]="dropdowns.types" label="Type" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                    <m-input minWidth="35;321:30" formControlName="multiple" type=dropdown [list]="dropdowns.confirmation" label="Multiple" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                    <ng-container formGroupName="validations">
                        <m-input minWidth="35;321:30" type=dropdown [list]="dropdowns.confirmation" formControlName="required" label="Required" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                    </ng-container>
                    <ng-container formGroupName="display">
                        <m-input minWidth="35;321:30" type=dropdown [list]="dropdowns.confirmation" formControlName="hide_label" label="Hide Label" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                        <m-input minWidth="35;321:30" type=dropdown [list]="dropdowns.confirmation" formControlName="hide_field" label="Hide Field" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                        <m-input minWidth="35;321:30" type=dropdown [list]="dropdowns.confirmation" formControlName="hide_in_public_view" label="Hide in Public View" [columns]="1/3" mb=0 [capitalize]=false></m-input>
                    </ng-container>
                    <ng-container>
                        <m-input minWidth="35;321:30" [capitalize]=false type=dropdown [list]="dropdowns.filter" formControlName="filter" label="Filter" [columns]="1/3" mb=0></m-input>
                    </ng-container>
                    <ng-container formGroupName="data">
                        <m-input minWidth="35;321:30" type=tags formControlName="items" [capitalize]=false label="Dropdown Items" [columns]="1" mb=0></m-input>
                    </ng-container>
                </ng-container>
            </m-form>
        </ng-container>
    </form>
</m-modal>
