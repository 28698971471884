import { Component, Input, OnInit } from '@angular/core';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';


@Component({
  selector: 'm-print',
  templateUrl: './print.component.html',
  styles: [
  ]
})
export class PrintComponent implements OnInit {
  @Input() details: any
  @Input() title: string = 'modal';
  details_type: any = 'basic_details'
  active : any 
  @Input() structure: any;
  users: IUserData;
  product_br: boolean = true;
  company_br: boolean = false;
  brand_br: boolean = false;

  
  visible : any =  {
    confirmphno: false,
    confidential: true
  }
  queryParams = {
    type: '',
    application: '',
    details_type: '',
    _id: ''
  }

  constructor(public gs: GlobalService) { 
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users
    })
  }

  attributes = {}


  ngOnInit(): void {
    
  }

  check_details(key: any) {
    this.details_type = key;
    this.active = key;
    console.log(this.active);
  }

}
