<div  a-style w="56.4" py="2.4"  px="2.4">
  <div a-style display="flex" align="center" justify="space-between" mb=2.4 px="1" >
     <!-- <m-text [type]="gs.enums.texts.paragraph43" px=0 text="EXPRESS YOUR MIND"></m-text> -->
     <div a-style display="flex" gap="1.6" >
        <img [src]="gs.user.users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+gs.user.users?.activeUser?.personal_details?.profile_picture) : gs.enums.images.image293" alt="" a-style h="5.3" w="5.3" brRadius="50%">
        <div a-style display="flex" gap=".4" flexDirection="column" >
           <p a-style color="#333333" fs="1.3">{{gs.user.users.activeUser?.personal_details?.first_name}} {{gs.user.users.activeUser?.personal_details?.last_name}}</p>
           <p a-style color="#707070" fs="1.3"> 20 Feb 2022,<span a-style color="#2479AB" fs="1.3" position="relative" (click)="openContext()"> Public </span></p>
        </div>
     </div>
     <m-button a-style px="1.5" h="2.6" py="0.5"   [types]="gs.enums.buttons.button0" (click)="submit()" text="Post"></m-button>
  </div>
  <div *ngIf="feeling_activity" a-style display="flex" gap="1" px=1 pt=1 pb=2 >
     <m-text [type]="gs.enums.texts.paragraph43" px=0 [text]="feeling_activity.label"></m-text>
     <img [src]="feeling_activity.image"  a-style h=2 w=2 shadow="0 3px 6px 0 #f1f1f1"  cursor="pointer" >
  </div>
  <div a-style px=1 >
     <img *ngIf="form?.get('data')?.get('image')?.value" [src]="'https://new-api.mesbro.com/uploads/'+form?.get('data')?.get('image')?.value" alt="" srcset="" a-style h=25 w=25 mb=1.5  >
     <m-felling-activity *ngIf="is_visible['Feeling']"  (activity)="feeling_activity=$event;is_visible={}" ></m-felling-activity>
     <div [formGroup]="form" a-style display="flex" gap="1" *ngIf="is_visible['Tag Friend']" >
     <m-text [type]="gs.enums.texts.paragraph43" text="Tag Friends"></m-text>
     <ng-container formGroupName="data" >
        <m-input formControlName="tag_friends" [type]="gs.enums.inputs.multiSelect"  border="border-bottom" brRadius=none pr='0' ml=0 [columns]="3/5"  bindLabel="name" bindImage="profile_piture" [list]="friendList" dropdownHeight="15" minWidth=unset></m-input>
     </ng-container>
  </div>
  <m-social-pools *ngIf="is_visible['Opening Polls']"  (createPoll)="poll($event)" ></m-social-pools>
  <div a-style display="flex" align="center" brTop="1px solid #e4e4e4" brBottom="1px solid #e4e4e4" justify="space-between" my="1.6">
     <div a-style ml="-2" display="flex" gap="2.4" align="center">
        <ng-container [formGroup]="form">
           <ng-container formGroupName="data" >
              <m-input [type]="gs.enums.inputs.file" formControlName="image"  [onlyInput]="true" [columns]="false" [aspect_ratio]="1">
              <!-- (mouseover)="is_hov['photo']=true" (mouseleave)="is_hov['photo']=false" -->
              <div file a-style display="flex" align="center" gap="1" shadow="hov:0 3px 6px 0 #f1f1f1"  brRadius="0.6" p="1">
                 <m-image src="../assets/images/Icon_Bank/Photo_Coloured.svg"></m-image>
                 <m-text [type]="gs.enums.texts.paragraph43" text="Photo"></m-text>
              </div>
              </m-input>
           </ng-container>
        </ng-container>
        <div *ngFor="let item of post_tab;let i=index" a-style cursor="pointer" display="flex" align="center" gap="1" shadow="hov:0 3px 6px 0 #f1f1f1"  brRadius="0.6" p="1" (click)="isClicked(item)">
        <!-- (mouseover)="is_hov[i]=true" (mouseleave)="is_hov[i]=false"  -->
        <m-image [src]="is_hov[i]?item.hov_image:item.image"></m-image>
        <m-text [type]="gs.enums.texts.paragraph43" [text]="item.label"></m-text>
     </div>
  </div>
</div>
<!-- <form [formGroup]="form" a-style mb="1">
  <ng-container formGroupName="data" >
        <div a-style display=flex gap=2 brBottom="1px solid #e4e4e4" mx=1 >
          <m-image a-style display="block" [src]="gs.enums.images.image293" w="5.2" h="auto" brRadius="50%"></m-image>
            <m-input formControlName="description" rows="4" br="none" [type]="gs.enums.inputs.text_area" placeHolder="Write something here..."></m-input>
        </div>
      </ng-container>
  </form> -->
 <form [formGroup]="form">
   <ng-container formGroupName="data">
    <m-input  formControlName="description" [type]="gs.enums.inputs.text_area" maxLength="2000"
    placeHolder="Write here" mt="0" px="0" rows=7 br="none" maxHeight="19"  align="left" > </m-input>
   </ng-container>
 </form> 
</div>
<div a-style display="flex" gap="1.6">
  <div a-style position="relative">
     <img src="" alt="" a-style w="11.7" h="11.7">
     <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="1.5" h="1.5" position="absolute" top="0.6" right="0.6">
  </div>
  <div a-style position="relative">
     <img src="" alt="" a-style w="20.8" h="11.7">
     <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="1.5" h="1.5" position="absolute" top="0.6" right="0.6">
  </div>
  <div a-style position="relative">
     <img src="" alt="" a-style w="11.7" h="11.7">
     <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="1.5" h="1.5" position="absolute" top="0.6" right="0.6">
  </div>
</div>
<div  a-style display="flex" justify="space-between" mt="1.6">
  <img src="../assets/images/Icon_Bank/Post_Slider_Left_Arrow_Coloured.svg" alt="" a-style w="1.1" h="1.1">
  <img src="../assets/images/Icon_Bank/Post_Slider_Right_Arrow_Coloured.svg" alt="" a-style w="1.1" h="1.1">
</div>
</div>
<m-modal #open_image_model width="56" height="70">
  <m-social-image ></m-social-image>
</m-modal>
<div *ngIf="public" a-style w="20" bg="#fff" position="absolute" top="5.5" brRadius="0.6" left="23"
  shadow="0 3px 6px 0 #17171729">
  <div class="Edit" a-style px="2.4" py="1">
     <p a-style fs="1.3">Public</p>
  </div>
  <div class="Edit" a-style px="2.4" py="1">
     <p a-style fs="1.3">Followers</p>
  </div>
  <div class="Edit" a-style px="2.4" py="1">
     <p a-style fs="1.3">Family Members</p>
  </div>
  <div class="Edit" a-style px="2.4" py="1">
     <p a-style fs="1.3">Only Me</p>
  </div>
</div>