import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { snakeCase } from "snake-case";

@Pipe({
  name: 'attributeType'
})
export class AttributeTypePipe implements PipeTransform {

  constructor(public gs: GlobalService) { }

  transform(attribute: any): any {
    // console.log(attribute);
    if (attribute?.dropdowns?.length>0) { return this.gs.enums.inputs.dropdown }
    if (attribute?.dataType == 'String') { return this.gs.enums.inputs.text }
    if (attribute?.dataType == 'Number') { return this.gs.enums.inputs.number }
    if (attribute?.dataType == "Image" )  { return this.gs.enums.inputs.media }
    if (attribute?.dataType == "Video" )  { return this.gs.enums.inputs.media }
    if (attribute?.dataType == "Markdown") { return this.gs.enums.inputs.text_area }
    if (attribute?.dataType == "Tags") { return this.gs.enums.inputs.tags }
    if (attribute?.dataType == "CheckBox") { return this.gs.enums.inputs.dropdown }
    if (attribute?.dataType == "Icon") { return this.gs.enums.inputs.media }
    return this.gs.enums.inputs.text
  }

}