import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-shortlink-list',
  templateUrl: './shortlink-list.component.html',
  styles: [
  ]
})
export class ShortlinkListComponent implements OnInit {

  list = {
    list: [],
    count: 0
  }
  application = 'generic';
  component = 'shortlink';
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    this.get_list()

  }
  async get_list() {
    // let response = await this.gs.get_data_list('generic', 'campaign', [])
    let response = await this.gs.hps.post('generic', 'shortlink', 'list', {});
    this.list = response;
    console.log(this.list);
    
  }
  async delete(id: any, index: number) {
    await this.gs.hps.delete('generic','shortlink', id)
    this.list.list.splice(index, 1);
  }

}
