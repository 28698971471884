<div a-style br="1px solid #E4E4E4" [w]="w" brRadius="0.6">
    <div a-style position=relative>
     
        <m-image [src]="detail?.files?.cover_picture? ('https://new-api.mesbro.com/uploads/'+detail?.files?.cover_picture) : 'assets/images/assets/5739473.jpg'" w=100% h=15 bgRepeat="no-repeat" bgSize="cover" alt="" srcset=""> </m-image>
     
        <m-image  [src]="detail?.files?.profile_picture? ('https://new-api.mesbro.com/uploads/'+detail?.files?.profile_picture): 'assets/images/assets/organization img (1).svg'"  h="10" w="10" position=absolute top=8 left="12" shadow="0 3px 6px 0 #f1f1f1" brRadius="50%"> </m-image>
    </div>
    <div a-style px=1.6 pb="1.6">
        <div a-style mt=3>
            <m-text [type]="gs.enums.texts.paragraph26" textCenter="center" text={{detail?.basic_details?.legal_name}}></m-text>
            <m-text [type]="gs.enums.texts.paragraph29" textCenter="center" text={{detail?.basic_details?.type}}></m-text>
        </div>
        <div a-style mt="0.8" display="flex"  align="center" mb="1.4" justify="center">
                <div a-style w="3" h="3" align="center" justify="center" display="flex">
                    <m-image src="https://mesbro.in/assets/images/app-icon/mail.svg" a-style h="auto" w=2.3 cursor="pointer" (click)="select_application('mail')"></m-image>

                </div>
                <div a-style w="3" h="3" align="center" justify="center" display="flex">
                    <m-image src="https://mesbro.in/assets/images/app-icon/chat.svg" a-style h="auto" w=2.3 cursor="pointer" (click)="select_application('chat')"></m-image>

                </div>
        </div>
        <div a-style mt=1.2>
            <m-text [type]="gs.enums.texts.paragraph1" text="Contact Details"></m-text>
            <div a-style display="flex" alignItems=center *ngIf="detail?.contact_details?.mobile || true" gap="1" mt="1">
                <!-- <m-image src="/assets/images/telephone.svg" a-style h="1.8" w="auto" cursor="pointer"></m-image> -->
               
                <m-text [type]="gs.enums.texts.paragraph1" text="Mobile :" mt="0" gap="1.5"></m-text>
                <m-text [type]="gs.enums.texts.paragraph124" [text]="detail?.contact_details?.mobile || '123456'"></m-text>
            </div>
            <div a-style display="flex" alignItems=center *ngIf="detail?.contact_details?.email || true" gap="1">
                <!-- <m-image src="/assets/images/email.svg" a-style h="1.8" w="auto" cursor="pointer"></m-image> -->
                <m-text [type]="gs.enums.texts.paragraph1" text="Email Id:" mt="0"></m-text>
                <m-text [type]="gs.enums.texts.paragraph124" [text]="detail?.contact_details?.email || 'akshay@mesbro.com'"></m-text>
            </div>
            <div a-style display="flex" alignItems=center *ngIf="detail?.contact_details?.website || true" gap="1">
                <!-- <m-image src="/assets/images/web.svg" a-style h="1.8" w="auto" cursor="pointer"></m-image> -->
                <m-text [type]="gs.enums.texts.paragraph1" text="Website:" mt="0"></m-text>
                <a [href]="'http://localhost:4200/home?id='+detail?._id+'?token='+gs.hps.token" target="_blank">
                    <m-text [type]="gs.enums.texts.paragraph124" [text]="detail?.contact_details?.website || 'www.mesbro.com'"></m-text>
                </a>
            </div> 
            <div a-style display="flex" alignItems=center *ngIf="detail?.contact_details?.email || true" gap="1">
                <!-- <m-image src="/assets/images/email.svg" a-style h="1.8" w="auto" cursor="pointer"></m-image> -->
                <m-text [type]="gs.enums.texts.paragraph1" text="Toll Free:" mt="0"></m-text>
                <m-text [type]="gs.enums.texts.paragraph124" [text]="detail?.contact_details?.toll || ''"></m-text>
            </div>
          
        </div>
        <!-- <div a-style mt="1.5" display="flex" justify="space-around">
            <div a-style justify="center"> -->
                
                <!-- <m-text [type]="gs.enums.texts.paragraph3" [mt]=0.4 text="{{detail?.meta?.chat?.response_time.hours | date: 'h:mma'}} ddd"></m-text> -->
                <!-- <m-text [type]="gs.enums.texts.paragraph124" [mt]=0.4 text='{{detail?.meta?.chat?.response_time}}'></m-text>

                <m-text [type]="gs.enums.texts.paragraph38" text="Response Time "></m-text> -->
            <!-- </div>
            <div>
                <m-text [type]="gs.enums.texts.paragraph124" [mt]=0.4 text="{{detail?.meta?.chat?.response_rate}}"></m-text> -->
                <!-- <m-text [type]="gs.enums.texts.paragraph3" [mt]=0.4 text="{{detail?.meta?.chat?.response_rate}}"></m-text> -->
<!-- 
                <m-text [type]="gs.enums.texts.paragraph38" text="Response Rate "></m-text>
            </div>
        </div> -->
        <!-- <div a-style mt="0.8" display="flex"  align="center" mb="1.5">
            <div a-style display="flex" flexDirection="column">
                <div a-style w="4" h="4" align="center" justify="center" display="flex">
                    <m-image src="https://mesbro.in/assets/icons/call.svg" a-style h="auto" w=2.3 cursor="pointer" (click)="call_modal.openModal()"></m-image>
                </div>
                <m-text [type]="gs.enums.texts.paragraph38" text="Call" cursor="pointer" (click)="call_modal.openModal()"></m-text>
            </div>
            <div a-style display="flex" flexDirection="column">
                <div a-style w="4" h="4" align="center" justify="center" display="flex">
                    <m-image src="https://mesbro.in/assets/images/app-icon/chat.svg" a-style h="auto" w=3.5 cursor="pointer" (click)="select_application('chat')"></m-image>
                </div>
                <m-text [type]="gs.enums.texts.paragraph38" text="Chat" cursor="pointer" (click)="select_application('chat')"></m-text>
            </div>
            <div a-style display="flex" flexDirection="column">
                <div a-style w="4" h="4" align="center" justify="center" display="flex">
                    <m-image src="https://mesbro.in/assets/images/app-icon/mail.svg" a-style h="auto" w=3.5 cursor="pointer" (click)="select_application('mail')"></m-image>
                </div>
                <m-text [type]="gs.enums.texts.paragraph38" text="Mail" cursor="pointer" (click)="select_application('mail')"></m-text>
            </div>
        </div> -->
        <div a-style minWidth=100% display=flex flexDirection=column flexGrow=1>
            <m-review type="Organization" [details]="detail" [brNone]=false *ngIf="detail" [brBottom]=false [mt]="false" [p]="false"></m-review>
        </div>
    </div>
</div>
<ng-template #contact let-passedData=links>
    <div a-style display="flex" flexDirection="column">
        <div a-style w="4" h="4" align="center" justify="center" display="flex">
            <img src="https://mesbro.in/assets/images/app-icon/{{passedData.icon}}.svg" a-style h="3.5" w="3.5" cursor="pointer">
        </div>
        <m-text [type]="gs.enums.texts.paragraph38" mt="1" text={{passedData.text}}></m-text>
    </div>
</ng-template>
<ng-template #review let-passedData=range>
    <div a-style display="flex" flexGap="1.8" align="center" justify="center">
        <m-text [type]="gs.enums.texts.paragraph1" text={{passedData.label}}></m-text>
        <m-image [src]="gs.enums.images.image48" a-style w="14.4" h="0.7"> </m-image>
    </div>
</ng-template>

<ng-template #rating_snapshot let-pass_data=pass_data>
    <div a-style display=flex flexGap=0.6 align="center">
        <m-text [type]="gs.enums.texts.paragraph11" text="{{pass_data.text1}}" align=end a-style w="0.7"></m-text>
        <m-image [src]="gs.enums.images.image99" w=0.8 h=0.8></m-image>
        <div a-style br="1px solid lightgray" brRadius="0.4" h="0.5" w="13.8" bg="#b2b2b2" position="relative">
            <div a-style w="7" h="0.4" bg="#ff973d" brRadius="0.4" position="absolute"></div>
        </div>
        <m-text [type]="gs.enums.texts.paragraph11" text="{{pass_data.text2}}" align=end a-style w="2.3"></m-text>
    </div>
</ng-template>
<m-call #call_modal></m-call>