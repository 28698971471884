<div *ngIf="type=='More'" a-style mt=2>
    <m-text [type]="gs.enums.texts.paragraph26" text="More from Seller"></m-text>
    <div a-style display="flex" overflow="auto">
        <div a-style minWidth=23.2 h="31.7" *ngFor="let i of[1,1,1,1]">
            <m-image [src]="gs.enums.images.image51" h="20.8" w="20.8"> </m-image>
            <div a-style display="flex" gap="1.5" mt="0.8">
                <div>
                    <!-- <m-text [type]="gs.enums.texts.paragraph38" text="Helpful" mr="2"></m-text> -->
                    <m-text [type]="gs.enums.texts.paragraph38" text="Sandisk Pendrive 32 GB"></m-text>
                    <m-text [type]="gs.enums.texts.paragraph38" text="Bharat Electronics PVT LTD"></m-text>
                    <m-text [type]="gs.enums.texts.paragraph38" text="INR 500 - INR 650"></m-text>
                </div>
                <div a-style display="flex" flexDirection="column" align="flex-end">
                    <m-image [src]="gs.enums.images.image2" w="3" h="2.1" mb="2.1"> </m-image>
                    <m-image [src]="gs.enums.images.image43" w="2" h="2.4" mt=1.5> </m-image>
                </div>
            </div>
        </div>
    </div>
</div>

