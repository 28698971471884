import { Component, OnInit, ViewChild, Input, HostBinding } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isThisHour } from 'date-fns/esm';
import { IUser, IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { AddDomainComponent } from 'src/mesbro/applications/organization/modals/add-domain/add-domain.component';
import { GlobalService } from 'src/mesbro/services/global.service';


@Component({
  selector: 'm-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') class = 'hidden-print';
  @Input() isVisible: boolean = false;
  @Input() type: '';
  hover: boolean = false;
  users: IUserData | undefined;
  public url: string = "";
  form: FormGroup | undefined;


  application = 'generic';
  component = 'notification';
  list = {
    list: [],
    count: 0
  }
  query_params: any = {
    type: '',
    application: '',
  }
  first_time = true;
  visible: any = {
    profile: false,
    language: false,
    applications: false,
    call: false,
    notifications: false
  };
  searchList = {
    list: [],
    count: 0
  }
  country: any = { label: 'India', countryCode: '+91', image: "99/99/99/99/99/96/66.jpg" };
  constructor(public gs: GlobalService, private router: Router, public ar: ActivatedRoute) {
    this.gs.user.users$.subscribe(users => {
      this.users = users;
      // console.log(this.users);
    })
    this.gs.$country.subscribe(async (item: any) => {
      this.country = await item;
      console.log(this.country);
      

    })

  }
  searchDropdown = false
  images = {
    false: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg',
    true: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg',
  }

  global_search = '';
  search: any
  sidebar_toggle: boolean
  ngOnInit(): void {
    this.gs.$click.subscribe(event => this.click_event(event))
    this.gs.$notifications.subscribe(event => this.click_event(event));
    this.ar.queryParams.subscribe((param: any) => {
      this.query_params = { ...param }
      if (this.query_params.query) {
        this.global_search = this.query_params.query
      }
    })
    this.search = new FormControl('')
  }

  open_pop_over(name: 'profile' | 'language' | 'applications' | 'call' | 'notifications') {
    setTimeout(() => {
      if (this.visible[name]) {
        this.visible[name] = false
      } else {
        this.visible[name] = true;
      }
      this.close_other_pop_overs(name)
    }, 20);
  }

  close_other_pop_overs(name: 'profile' | 'language' | 'applications' | 'call' | 'notifications' | 'all') {
    Object.keys(this.visible).forEach((key: any) => {
      if (key != name) {
        this.visible[key] = false;
      }
    })
  }

  click_event(event: any) {
    if (!this.first_time) {
      let is_modal = false;
      event.path.forEach((path: any) => {
        let class_list = path.classList?.value;
        if (class_list?.includes('modal')) {
          is_modal = true;
        }
      });
      if (!is_modal) {
        this.first_time = true;
        this.close_other_pop_overs('all')
      }
    } else {
      this.first_time = false;
    }
  }


  onKeyUp(event: any) {
    this.gs.router.navigate(['/generic/gallary'], { queryParams: { query: this.search.value } });
    //  this.search.reset()
  }

  suggested_users = []


  async get_list() {
    let filters = { 'user._id': this.gs.user.users?.activeUser?._id }
    let response = await this.gs.hps.post(this.application, this.component, 'list', filters)
    this.list = response;
  }

  async ProductListComponent(event: string) {
    console.log(event);
   if(this.gs.router.url.includes('/product/home')){
     
    let body = {
      "query": event
    }
    let response = await this.gs.hps.post('business', 'products', 'list', body)
    this.searchList = response
    if (event!='') {
      this.searchDropdown=true
    }else{
      this.searchDropdown=false
    }
   }
  }

  onChange: any = (event: string) => {
    this.ProductListComponent(event)
  };
  redirect_to_detail(item: any) {
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  }
}



  // onKeyUp(event: any) {
  //   this.submit();
  // }

  // async tagsSuggestion() {
  // try {
  //   let body = this.form?.value;
  //   let response = await this.gs.hps.post('idm', 'user', 'suggest-user', body);
  //   this.suggested_users = response.usernames
  // }
  // catch (error) {
  //   throw error;
  // }


  // }


    // async submit() {
  //   try {
  //     let body = this.global_search;
  //     this.gs.router.navigate([], { queryParams: { ...this.query_params, ...{ query: this.global_search } } });
  //   } catch (error) {
  //     console.log(error);

  //   }

  // }