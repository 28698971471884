import { GlobalService } from 'src/mesbro/services/global.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-toast',
  templateUrl: './toast.component.html',
  styles: [
  ]
})
export class ToastComponent implements OnInit {
  @Input() is_visible: boolean = false;
  @Input() index = 0
  @Input() toast : any
  @Input() type : any
  @Input() toastmodal : any

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }


}
