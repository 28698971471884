import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'm-category',
  templateUrl: './category.component.html',
  styles: [
  ]
})
export class CategoryComponent implements OnInit {
  first: boolean = true;
  second: boolean = false;
  Third: boolean = false;
  public application = 'business';
  public component = "productcategories";
  public category: any;
  public totalCount: any;
  public categorySearch: any;
  public page: any;
  public categoryList: any
  primaryArray: any;
  secondaryArray: any
  tertiaryArray: any
  typeArray: any
  primaryObject: any = {}
  secondaryObject: any = {}
  tertiaryObject: any = {}
  primaryList: any
  primary: any
  secondaryList: any
  tertiaryList: any
  typeList: any
  typeListForSearch: any
  typeSearch: any
  params = {
    category: '',
    primaryId: '',
    secondaryId: '',
    tertiaryId: '',
    step: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'productList'>'Primary',
    type: '',
    typeId: '',
    subtype: '',
    _id: '',
    typeName:''
  }
  primaryName: any
  secondaryName: any
  tertiaryName: any
  selectedPrimary: any
  selectedsecondary: any
  selectedTertiary: any
  public selected: any = {
    Primary: undefined,
    Secondary: undefined,
    Tertiary: undefined,
    Type: undefined,
  };
  primary_name: any
  secondary_name: any
  tertiary_name: any
  type_name: any
  searchType: any
  getTypeName: any
  searchTypeInput: any
  constructor(public gs: GlobalService, public ar: ActivatedRoute) {
    this.primaryArray = []
    this.secondaryArray = []
    this.tertiaryArray = []
    this.typeArray = []
  }
  search = new FormControl('');

  async ngOnInit() {
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
      if (this.params.category == 'primary') {
        this.getPrimaryList()
      }
      if (this.params.category == 'secondry' || this.primary) {
        this.getSecondaryList()
      }
      if (this.params.category == 'tertiary') {
        this.getTertiaryList()
      }
      if (this.params.category == 'type') {
        this.getTypeList()
      }
      if (this.params.typeName) {
        this.searchTypeName()
      }
    })
  }

  async getPrimaryList() {
    let requestObj = {
      'basic.type': {
        "$eq": "primary"
      }
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'list', requestObj)
    this.primaryList = response;
    this.primaryList.content.forEach((element: any) => {
      this.primaryArray.push(element.basic.name)
    });

  }
  async getSecondaryList() {
    let requestObj = {
      "$and": [
        {
          "basic.type": {
            "$eq": "secondary"
          }
        },
        {
          "basic.primaryId": {
            "$eq": this.params.primaryId
          }
        }
      ]
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'list', requestObj)
    this.secondaryList = response;
    this.secondaryList.content.forEach((element: any) => {
      this.secondaryArray?.push(element?.basic?.name)
    });

  }
  async getTertiaryList() {
    let requestObj = {
      "$and": [
        {
          "basic.type": {
            "$eq": "tertiary"
          }
        },
        {
          "basic.secondaryId": {
            "$eq": this.params.secondaryId
          }
        }
      ]
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'list', requestObj)
    this.tertiaryList = response;
    this.tertiaryList?.content?.forEach((element: any) => {
      this.tertiaryArray?.push(element?.basic?.name)
    });
  }
  async getTypeList() {
    let requestObj = {
      "$and": [
        {
          "basic.type": "product"
        },
        {
          "basic.primaryId": {
            "$eq": this.params.primaryId
          }
        },
        {
          "basic.secondaryId": {
            "$eq": this.params.secondaryId
          }
        },
        {
          "basic.tertiaryId": {
            "$eq": this.params.tertiaryId
          }
        }
      ]
    }
    let response: any = await this.gs.hps.post('business', 'producttypes', 'list', requestObj)
    this.typeList = response;
    this.typeList.list.forEach((element: any) => {
      this.typeArray?.push(element?.basic?.name)
    });
  }
  async getsecondaryByName() {
    let requestObj = {
      "name": this.secondaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'search-secondary', requestObj)
    this.secondaryList = response;
  }
  async searchprimary() {
    let requestObj = {
      "name": this.primaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'search-primary', requestObj)
    this.primaryList = response;
  }
  async searchTertiary() {
    let requestObj = {
      "name": this.tertiaryName
    }
    let response: any = await this.gs.hps.post('business', 'productcategories', 'search-tertiary', requestObj)
    this.tertiaryList = response;
  }
  requestObj: any
  async searchTypeName() {
    if (this.searchTypeInput) {
      this.requestObj = {
        "name": this.searchTypeInput
      }
    }
    if (this.searchType) {
      this.requestObj = {
        "name": this.searchType
      }
    }
    if (this.params.typeName) {
      this.requestObj = {
        "name": this.params.typeName
      }
    }
    let response: any = await this.gs.hps.post('business', 'producttypes', 'search', this.requestObj)
    this.typeListForSearch = response;
    if (this.searchTypeInput) {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'type', type: 'product', primaryId: response?.list[0]?.basic?.primaryId, secondaryId: response?.list[0]?.basic?.secondaryId, tertiaryId: response?.list[0]?.basic?.tertiaryId } })
      
    }
  }
  selected_category(name: any, type: any) {
    if (type = 'primary') {
      this.primary_name = name
    }
    if (type = 'secondary') {
      this.secondary_name = name
    }
  }
  redirect(id: any) {
    if (this.params.type == 'product') {
      this.gs.router.navigate(['/generic/gallary'], { queryParams: { typeId: id, type: 'product' } })
    }
    else {
      this.gs.router.navigate(['/generic/form'], { queryParams: { step: 'form', type: id, subheading: 'Basic Details', primaryId: this.params.primaryId, secondaryId: this.params.secondaryId, tertiaryId: this.params.tertiaryId, _id: this.params._id } })
    }
  }
  categoryRedirectPrimary(id: any) {
    if (this.params.subtype == 'form') {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'secondry', subtype: 'form', primaryId: id, _id: this.params._id } })
    } else {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'secondry', type: 'product', primaryId: id } })
    }
  }
  categoryRedirectSecondary(id: any) {
    if (this.params.subtype == 'form') {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'tertiary', subtype: 'form', primaryId: this.params.primaryId, secondaryId: id, _id: this.params._id } })
    } else {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'tertiary', type: 'product', primaryId: this.params.primaryId, secondaryId: id } })
    }
  }
  categoryRedirecttertiary(id: any) {
    if (this.params.subtype == 'form') {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'type', subtype: 'form', primaryId: this.params.primaryId, secondaryId: this.params.secondaryId, tertiaryId: id, _id: this.params._id } })
    } else {
      this.gs.router.navigate(['/product/category'], { queryParams: { category: 'type', type: 'product', primaryId: this.params.primaryId, secondaryId: this.params.secondaryId, tertiaryId: id } })
    }
  }
  selectedCategory(name: any) {
    this.selectedPrimary = name
  }
  selectedCategorysecondary(name: any) {
    this.selectedsecondary = name
  }
  selectedCategoryTertiary(name: any) {
    this.selectedTertiary = name
  }
  onKeyUp(event: any) {
    // this.getTypesearch(event.key)
  }
}
