import { GenericSettingComponent } from './modules/shared/components/generic-setting/generic-setting.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MesbroHomeComponent } from './modules/shared/pages/mesbro-home/mesbro-home.component';
import { GenericDetailsComponent } from './modules/shared/pages/generic-details/generic-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { MainComponent } from './components/main/main.component';
import { RouterModule, Routes } from '@angular/router';
import { mesbroRoutes } from './routes/mesbro-routes';
import { SharedModule } from './modules/shared/shared.module';
import { TopApplicationsComponent } from './pages/top-applications/top-applications.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewListComponent } from './modules/shared/pages/review-list/review-list.component';
import { GenericFeedbackComponent } from './modules/shared/pages/generic-feedback/generic-feedback.component';
import { GenericDetailsGroupComponent } from './modules/shared/pages/generic-details-group/generic-details-group.component';
import { ReferanceInputsComponent } from './pages/referance-inputs/referance-inputs.component';




@NgModule({
  declarations: [MesbroHomeComponent, GenericDetailsComponent, MainComponent, TopApplicationsComponent, ReviewListComponent, GenericFeedbackComponent, GenericDetailsGroupComponent, GenericSettingComponent, ReferanceInputsComponent],
  imports: [
    RouterModule.forRoot(mesbroRoutes, {
      scrollPositionRestoration: 'enabled',
    }),
    CommonModule,
    BrowserModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  providers: [],
  bootstrap: [MainComponent],
  schemas: [
  ]
})
export class MesbroModule { }
