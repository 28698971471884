<!-- <div top="0" left="0" zIndex="2000" a-style bg="rgb(0,0,0,0.8)" display="grid" placeContent="center" position="fixed" h="100vh" w="100vw"> -->
  <div a-style display="flex">
    <div a-style bg="#1A73E8" p="4" textWrap='nowrap'>
      <m-text text="Add User" [type]="gs.enums.texts.paragraph56" mb="4"></m-text>
      <div a-style display=flex alignItems=flex-end>
        <div a-style brRadius=50% h=1.8 w=1.8 bg="white" display=grid placeContent=center mr=1.5>1</div>
        <m-text text="Add New User" [type]="gs.enums.texts.paragraph57"></m-text>
      </div>
    </div>
    
    <div a-style bg=white pt="4" pb="2.4">
      <form *ngIf="form" [formGroup]="form">
        <m-form columns="2" w=76 [brNone]=false [paddingNone]=false>
          <ng-container formGroupName="personal_details">
            <m-input formControlName="first_name" px="1.5" [columns]="1/2" minWidth=unset placeHolder="First Name" label="First Name"></m-input>
            <m-input formControlName="last_name" px="1.5" [columns]="1/2" minWidth=unset placeHolder="Last Name" label="Last Name"></m-input>
            <m-input formControlName="date_of_birth" px="1.5" [columns]="1/2" minWidth=unset label="Date of Birth" [type]="gs.enums.inputs.date"></m-input>
            <m-input formControlName="gender" px="1.5" [columns]="1/2" minWidth=unset label="Gender" [list]="gs.masters.Gender" prefix="gender" bindLabel="label" bindValue="label" bindImage="image" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
          </ng-container>
          <ng-container formGroupName="security_details">
            <m-input [lowercase]="true" formControlName="username" px="1.5" [columns]="1/2" minWidth=unset placeHolder="Username" label="Username"></m-input>
            <m-input formControlName="domain" px="1.5" [columns]="1/2" minWidth=unset label="Domain" [list]="domains" bindLabel="domain_name" bindValue="domain_name" [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
          </ng-container>
          <ng-container formGroupName="contact_details">
            <m-input formControlName="mobile_number_primary" px="1.5" [columns]="1/2" minWidth=unset placeHolder="Mobile Number" label="Mobile Number"></m-input>
            <m-input formControlName="email_address_secondary" [lowercase]="true" px="1.5" [columns]="1/2" minWidth=unset placeHolder="Communication Email ID" label="Recovery Email"></m-input>
          </ng-container>
        </m-form>
      </form>
      <div a-style display=flex justify="flex-end" gap=1.6 px=1.5 mt=8>
        <button *ngIf="!verify" a-style px=1.6 py=0.8 brRadius=0.4 bg=#ffffff br="1px solid #E4E4E4" outline=none cursor=pointer (click)="this.modal.closeModal()">cancel </button>
        <m-button *ngIf="!verify" [types]="gs.enums.buttons.button7" a-style fs="1.4" text="Add User" (click)="submit(); this.modal.closeModal()"></m-button>
      </div>
    </div>
  </div>
    
<!-- </div> -->