<div a-style h="7.2"  brBottom="1px solid #e4e4e4" bg="#F8F8F8" br="0.5 solid #E4E4E4">
    <div a-style h="7.2" box-shadow=' 0px 3px 6px #17171729' br="0.5px solid #E4E4E4" display="flex" align="center"
        justify="center" gap="2.5" shadow="0 3px 6px 0 #17171729">
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['recipe/home'])"  [style.color]=" gs.router.url.includes('/recipe/home')?'#2479AB':'black'">
            <!-- <img *ngIf="!gs.router.url.includes('/recipe/home')" src="../assets/images/ME/Home.svg" a-style w="2.6"
            h="2.6" routerLink="/recipe/home"> -->
        <img  a-style h=2.6 w=2.6 cursor="pointer"
            src="../assets/images/Icon_Bank/Home_Coloured.svg">
            <p a-style fs="1.4" cursor="pointer">Home</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6"  (click)="gs.router.navigate(['/recipe/categories'],{queryParams:{category:'primary',type:'recipe'}})" [style.color]=" gs.router.url.includes('/recipe/categories')?'#2479AB':'black'">
            <!-- <img *ngIf="!gs.router.url.includes('/recipe/categories')" src="../assets/images/B2B/Categories.svg" a-style
                w="2.6" h="2.6" routerLink="/recipe/categories" cursor="pointer"> -->
            <img  src="../assets/images/Icon_Bank/Category_Coloured.svg"
                a-style w="2.6" h="2.6" cursor="pointer">
            <p a-style fs="1.4" cursor="pointer">Categories</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/recipe/fav'])" [style.color]=" gs.router.url.includes('/recipe/fav')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
        <!-- <img src="../assets/images/ME/History Control (1).svg" a-style w="2.6" h="2.6"
            *ngIf="!gs.router.url.includes('recipe/history')" cursor="pointer"> -->
            <p a-style fs="1.4" cursor="pointer">Favourite</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/recipe/save'])" [style.color]=" gs.router.url.includes('/recipe/save')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/Save_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
        <!-- <img src="../assets/images/ME/History Control (1).svg" a-style w="2.6" h="2.6"
            *ngIf="!gs.router.url.includes('recipe/history')" cursor="pointer"> -->
            <p a-style fs="1.4" cursor="pointer">My Collection</p>
        </div>
        <div a-style display="flex" align="center" gap="1.6" (click)="gs.router.navigate(['/recipe/history'])" [style.color]=" gs.router.url.includes('/recipe/history')?'#2479AB':'black'">
            <img src="../assets/images/Icon_Bank/History_Coloured.svg" a-style w="2.6" h="2.6" cursor="pointer">
        <!-- <img src="../assets/images/ME/History Control (1).svg" a-style w="2.6" h="2.6"
            *ngIf="!gs.router.url.includes('recipe/history')" cursor="pointer"> -->
            <p a-style fs="1.4" cursor="pointer">History</p>
        </div>
    </div>  
</div>


<!-- *ngIf="gs.router.url.includes('recipe/history')" routerLink="/recipe/history" -->