import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import addHours from 'date-fns/addHours'
import { localStorageGet, localStorageSet } from '../modules/shared/functions/common';

interface IResponse {
  code: number,
  content: any,
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class HttpProviderService {

  token: string | undefined;

  constructor(public http: HttpClient, public user: UserService) {
    this.user.users$.subscribe(users => {
      this.token = users?.activeUser?.token;
      // console.log(this.token);

    })
  }

  get = async (application: string, component: string, action: string, time?: any) => {
    try {
      let url = this.generateLink(application, component, action)
      let response = this.local_storage_get(url, undefined, time);
      if (!response) {
        response = <IResponse>await this.http.get(url, this.getHeaders()).toPromise()
      }
      return response;
    } catch (error) {
      // console.log(error)
      throw error;
    }

  }

  post = async (application: string,component: string, action: string, body: any,time?: any) => {
    try {
      let url = this.generateLink(application, component, action)
      let response = this.local_storage_get(url, body, time);
      if (!response) {
        response = <IResponse>await this.http.post(url, body, this.getHeaders()).toPromise()
        this.local_storage_set(url, body, response, time);
      }
      return response;
    } catch (error) {
      // console.log(error)
      throw error;
    }
  }

  put = async (application: string, component: string, action: string | undefined, body: any) => {
    let url = this.generateLink(application, component, action)
    let response: any = <IResponse>await this.http.put(url, body, this.getHeaders()).toPromise()
    return response;
  }

  delete = async (application: string, component: string, action: string) => {
    let url = this.generateLink(application, component, action)
    let response: any = <IResponse>await this.http.delete(url, this.getHeaders()).toPromise()
    return response;
  }

  private generateLink(application: string, component: string, action?: string) {
    return environment.base_url + (application ? (application + '/') : '') + (component ? component + (action ? '/' : '') : '') + (action ? action : '')
  }

  getHeaders() {
    let token = this.token || '';
    if (token) {
      return {
        headers: { token: token }
      }
    } else {
      return {}
    }
  }

  local_storage_set = (url: string, body: any, result: any, time: string) => {
    return;
    if (!time) {
      return;
    }
    const expiry = addHours(new Date(), 10)
    result.cache_expiry = expiry
    let key = url + (!body || JSON.stringify(body))
    let cache = localStorageGet('Cache') || {}
    cache[key] = result;
    localStorageSet('Cache', cache);
  }

  local_storage_get = (url: string, body: any, time: string) => {
    if (!time) {
      return;
    }
    return undefined
    let key = url + (!body || JSON.stringify(body))
    let cache = localStorageGet('Cache')
    let value = cache?.[key]
    if (!value) {
      return undefined;
    }
    if (new Date().toISOString() <= value.cache_expiry) {
      return value;
    } else {
      console.log(new Date().toISOString(), value, value.cache_expiry)
      delete cache[key];
      localStorageSet('Cache', cache);
      return undefined
    }
  }

}
