<div *ngIf="false" zIndex="9999" bg=white a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex justify=space-between align=center flexGap=2 p=1 w=39 brRadius=1 mb=5 mr=2 [style.bottom]="3.6+ 9.5 * (index)+'rem'" right=0 >
  <div a-style [style.border]="'2px solid '+  toast.type == 'success'? '#275f47' : 'red' " h=5.5></div>
  <div a-style brRadius=50% h=4.8 w=4.8 [style.background]=" toast.type == 'success'? '#275f47' : 'red' ">
    <m-text [type]="gs.enums.texts.paragraph79" [text]="toast.type == 'success'? 's' : 'i' " mt="0.4"></m-text>
    <!-- <img [src]="toast.type == 'success'? '../assets/images/Icon_Bank/Tick_Coloured.svg' : 'i' " alt="" a-style w="6.6" h="6.6" mt="0.4"> -->
  </div>
  <div a-style align=start flexGrow=1>
    <m-text [type]="gs.enums.texts.paragraph20" [text]="toast?.title ||  'Did you Know'"></m-text>
    <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
  </div>
  <span a-style aria-hidden="true" fs=2>&times;</span>
</div>

<!-- <div *ngIf="type == toastmodal" top="0" left="0" zIndex="2000" a-style bg="rgb(0,0,0,0.8)" display="grid"
placeContent="center" cursor="pointer" position="fixed" h="100vh" w="100vw">
<div a-style bg="white" fs="16px" brRadius="6px" maxWidth="55.4" minWidth="821px" h="auto"
  p=2.4;1366:1.7>
  <div a-style align="center">
    <div a-style bg="hov:gray" brRadius="50%" placeContent="center">
      <img src="../assets/images/assets/closemodal.svg" a-style w="2.5;1366:1.77" h="2.5;1366:1.77" align="right">
    </div>
    <img src="../assets/images/org_tick.svg" alt="" a-style w="10" h="10" mb=1.9;1366:1.3>
    <p a-style fs="2" color="#333333" >Organization Submitted Successfully</p>
    <p a-style fs=1.3 align=center mt=2.4>To achieve stable competitiveness and sustainability for your organization,
      you must provide easy data availability and accessibility which will ensure trust of users. In order to
      provide greater transparency evaluation of
      your organization is crucial. Once the evaluation is over you will be notified and the organization will
      get displayed on the platform.</p>
    <p a-style color=#2479AB align=center fs=1.3 mt=1.6>Organization evaluation is in progress..</p>
  </div>
</div>
</div> -->
<!-- #FFD200 -->
<!-- bg="#25C6DA" -->

<!-- <div zIndex="9999" a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex align="center" h="11.4" [style.background-color]="toast.type == 'success'? '#FBFFF2' : '#FBE7E3' || toast.type == 'warning'? '#FFFADD' : '' || toast.type == 'Info'? '#E3F8FB' : ''"  brRadius=0.6 w=39 [style.bottom]="3.6+ 9.5 * (index)+'rem'" position=fixed right=4>
  <div a-style h="11.4" w="1.2"  [style.background-color]="toast.type == 'success'?'#81BC06':'red' || toast.type == 'warning'?'#FFD200':''"  mr="1.6" brTopLeftRadius="0.6" brBottomLeftRadius="0.6"></div>
  <div a-style  h=5.5></div>
  <img [src]="toast.type == 'success'? '../assets/images/Toast_Message_Icon_Bank/Success.svg' : '../assets/images/Toast_Message_Icon_Bank/Error.svg' || toast.type == 'warning'? '../assets/images/Toast_Message_Icon_Bank/Warning.svg' : '' || toast.type == 'Info'? '../assets/images/Toast_Message_Icon_Bank/Information.svg' : ''" alt="" a-style w="6.6" h="6.6" mt="0.4">
  <div a-style align=start flexGrow=1 ml="1.6">
  <p *ngIf="toast?.title || 'Did you Know'" a-style fs="1.6">{{toast.title}}</p>
  <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
  </div>
  </div> -->
  
  <div *ngIf="toast.type == 'success'" zIndex="9999" a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex align="center" h="11.4" bg="#FBFFF2" brRadius=0.6 w=39 [style.bottom]="3.6+ 9.5 * (index)+'rem'" position=fixed right=4>
    <div a-style h="11.4" w="1.2"  bg="#81BC06"  mr="1.6" brTopLeftRadius="0.6" brBottomLeftRadius="0.6"></div>
    <div a-style  h=5.5></div>
   
    <img src="../assets/images/Toast_Message_Icon_Bank/Success.svg" alt="" a-style w="6.6" h="6.6" mt="0.4">
  
    <div a-style align=start flexGrow=1 ml="1.6">
 
    <p *ngIf="toast?.title || 'Did you Know'" a-style fs="1.6">{{toast.title}}</p>
    <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
    </div>
   
    </div>

    <div *ngIf="toast.type == 'error'" zIndex="9999" a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex align="center" h="11.4" bg="#FBE7E3" brRadius=0.6 w=39 [style.bottom]="3.6+ 9.5 * (index)+'rem'" position=fixed right=4>
      <div a-style h="11.4" w="1.2"  bg="#FC573B"  mr="1.6" brTopLeftRadius="0.6" brBottomLeftRadius="0.6"></div>
      <div a-style  h=5.5></div>
      
      <img src="../assets/images/Toast_Message_Icon_Bank/Error.svg" alt="" a-style w="6.6" h="6.6" mt="0.4">
    
      <div a-style align=start flexGrow=1 ml="1.6">
     
      <p *ngIf="toast?.title || 'Did you Know'" a-style fs="1.6">{{toast.title}}</p>
      <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
      </div>
      
      </div>

  <div *ngIf="toast.type == 'warning'" zIndex="9999" a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex align="center" h="11.4" bg="#FFFADD" brRadius=0.6 w=39 [style.bottom]="3.6+ 9.5 * (index)+'rem'" position=fixed right=4>
        <div a-style h="11.4" w="1.2"  bg="#FFD200"  mr="1.6" brTopLeftRadius="0.6" brBottomLeftRadius="0.6"></div>
        <div a-style  h=5.5></div>
       
        <img src="../assets/images/Toast_Message_Icon_Bank/Warning.svg" alt="" a-style w="6.6" h="6.6" mt="0.4">
      
        <div a-style align=start flexGrow=1 ml="1.6">
        
        <p *ngIf="toast?.title || 'Did you Know'" a-style fs="1.6">{{toast.title}}</p>
        <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
        </div>
        
        </div>

 <div *ngIf="toast.type == 'Info'" zIndex="9999" a-style shadow="0 2px 10px rgb(0 0 0 / 10%)" display=flex align="center" h="11.4" bg="#E3F8FB" brRadius=0.6 w=39 [style.bottom]="3.6+ 9.5 * (index)+'rem'" position=fixed right=4 pr="1">
          <div a-style h="11.4" w="1.2"  bg="#25C6DA"  mr="1.6" brTopLeftRadius="0.6" brBottomLeftRadius="0.6"></div>
          <div a-style  h=5.5></div>
         
          <img src="../assets/images/Toast_Message_Icon_Bank/Information.svg" alt="" a-style w="6.6" h="6.6" mt="0.4">
        
          <div a-style align=start flexGrow=1 ml="1.6">
          
          <p *ngIf="toast?.title || 'Did you Know'" a-style fs="1.6">{{toast.title}}</p>
          <m-text [type]="gs.enums.texts.paragraph8" [text]="toast?.message || 'here is somthing that you might like to know'"></m-text>
          </div>
          
          </div>