import { Component, Input, OnInit , EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-faq-modal',
  templateUrl: './faq-modal.component.html',
  styles: [
  ]
})
export class FaqModalComponent implements OnInit {
  application = 'Generic';
  component = 'Question';
  @Input() modal: any
  @Input() details: any;
  @Output() submit = new EventEmitter()
  constructor(public gs:GlobalService , public fb: FormBuilder) { }
  form_data = {
    description: [null, [this.gs.form_validations.required('Question')]],
  }
  form: FormGroup | undefined;
  ngOnInit(): void {
    this.initializeForm()
  }
  initializeForm() {
    this.form = this.fb.group({
      _id: null,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data),
    })
  }
  submit_qus(){
    if (this.form?.invalid) {
      console.log('submit,,,,,,,,,,,,,,');
      return;
    }
    this.submit.emit(this.form?.value)
  }
}
