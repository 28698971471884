<ng-container *ngIf="type =='test'" [ngTemplateOutlet]="test"></ng-container>
<ng-container *ngIf="type =='cardWithToggleButton'" [ngTemplateOutlet]="cardWithToggleButton"></ng-container>
<ng-container *ngIf="type =='scrapCard'" [ngTemplateOutlet]="scrapCard"></ng-container>
<ng-container *ngIf="type =='VerifiedSuppiers'" [ngTemplateOutlet]="VerifiedSuppiers"></ng-container>
<ng-template #test>
  <div *ngIf="detail?.Basic_Details != null && detail?.blocked?.block!=true && !detail?.activity?.delete" a-style p="1" br="0.5px solid #e4e4e4"
    w="24" h="33.2" position="relative" brRadius="0.6"
    (click)="redirect_to_detail(detail);getViewCount(detail?._id);gs.history('history',detail._id,'business','products','add-product-to-history')">
    <img *ngIf="params.type != 'Reviews'"
      src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' alt="" a-style w="22" h="22"
      brRadius="0.6"
      (click)="redirect_to_detail(detail);getViewCount(detail?._id);gs.history('history',detail._id,'business','products','add-product-to-history')">
    <img *ngIf="params.type == 'Reviews'"
      src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' alt="" a-style w="22" h="22"
      brRadius="0.6"
      (click)="gs.router.navigate([] , {queryParams:{type:'Review',section:'B2B Products',_id:detail?._id ,hid:detail?.id}})">
    <p a-style fs="1.3" color="#2479AB">{{ detail?.Basic_Details?.currency?.value ?
      detail?.Basic_Details?.currency?.value : 'INR'}} {{detail?.Basic_Details?.attributes[6]?.value ?
      detail.Basic_Details.attributes[6].value : 0}} - {{detail?.Basic_Details?.attributes[7]?.value ?
      detail.Basic_Details.attributes[7].value : 0}}</p>
    <p *ngIf="params.type != 'Reviews'" a-style fs="1.3" h="3.6" maxWidth="22" class=lineClamp
      (click)="redirect_to_detail(detail)">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p *ngIf="params.type == 'Reviews'" a-style fs="1.3" h="3.6" maxWidth="22" class=lineClamp>
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between" py="0.5">
      <div *ngIf="ratings">
        <ng-container [ngTemplateOutlet]="rating_input"
          [ngTemplateOutletContext]="{passed_data : {rating : ratings, editable : true}}">
        </ng-container>
      </div>
      <div *ngIf="!ratings">
        <ng-container [ngTemplateOutlet]="rating_input"
          [ngTemplateOutletContext]="{passed_data : {rating : rating, editable : true}}">
        </ng-container>
      </div>
      <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" alt="" a-style w="2" mt="0.5">
    </div>
  </div>
</ng-template>

<ng-template #cardWithToggleButton>
  <div a-style p="1" br="0.5px solid #e4e4e4" position="relative" brRadius="0.6" w="24">
    <img src='https://new-api.mesbro.com/uploads/{{detail.Media.attributes[0].value[0]}}' alt="" a-style w="22" h="22"
      (click)="redirect_to_detail(detail)">
    <div *ngIf="params.type=='All' || params.type=='Deactivate'" a-style display="flex" w="20" position="absolute"
      top="2" left="2" justify="space-between">
      <m-input #switch [(ngModel)]="toggleProduct" [type]="gs.enums.inputs.switch" [onlyInput]='true' (ngModelChange)="activeDeactiveProduct(detail?._id)" 
        [columns]='undefined' a-style top="1">ffefefefe</m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteProduct(detail?._id)">
      </div>
    </div>
    <div *ngIf="params.type=='My Collection'" a-style position="absolute" top="2" right="2">
      <img src="../assets/images/Icon_Bank/Save_Coloured.svg" alt="" a-style w="2.5" h="2.5">
    </div>
    <p a-style fs="1.3" color="#2479AB">{{ detail?.Basic_Details?.currency?.value ?
      detail?.Basic_Details?.currency?.value : 'INR'}} {{detail.Basic_Details.attributes[6].value ?
      detail.Basic_Details.attributes[6].value : 0}} - {{detail.Basic_Details.attributes[7].value ?
      detail.Basic_Details.attributes[7].value : 0}}</p>
    <p a-style fs="1.3 " w="22" (click)="redirect_to_detail(detail)" class=lineClamp h="6">
      {{detail.Basic_Details.attributes[0].value}}</p>
    <div a-style display="flex" justify="space-between" align="center">
      <div a-style display="flex" gap="0.3">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
      </div>
      <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" alt="" a-style w="2" h="1.2">
    </div>
  </div>
</ng-template>

<ng-template #scrapCard>
  <div a-style p="1" br="0.5px solid #e4e4e4" w="24" h="32.2" position="relative" brRadius="0.6"
    (click)="redirect_to_detail_scrap(detail);getViewCountForScrap(detail?._id);gs.history('history',detail._id,'scrap','scraps','add-scrap-to-history')">
    <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' alt="" a-style w="22"
      h="22" brRadius="0.6">
    <div *ngIf="params.type=='All' && params.section=='B2B Scrap'" a-style display="flex" w="20" position="absolute"
      top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" formControlName="holiday" [onlyInput]='true'
        [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_scrap_form(detail?._id);getViewCountForScrap(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteScrap(detail?._id)">
      </div>
    </div>
    <div *ngIf="params.type=='My Collection' && params.section=='B2B Scrap'" a-style position="absolute" top="2"
      right="2">
      <img src="../assets/images/Icon_Bank/Save_Coloured.svg" alt="" a-style w="2.5" h="2.5">
    </div>
    <p a-style fs="1.3" color="#2479AB">INR {{detail?.Basic_Details?.attributes[4].value}} -
      {{detail?.Basic_Details?.attributes[5].value}}</p>
    <p a-style fs="1.3" h="3.6" maxWidth="22" class=lineClamp>{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3" mt="0.5">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
      </div>
      <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" alt="" a-style w="2" mt="0.5">
    </div>
  </div>
</ng-template>

<ng-template #VerifiedSuppiers>
  <div a-style p="1" br="0.5px solid #e4e4e4" w="24" h="32.2" position="relative" brRadius="0.6">
    <img src='' alt="" a-style w="22" h="22" brRadius="0.6">
    <div a-style display="flex" gap="0.5">
      <img src="" alt="" a-style w="1.9" h="1.9" brRadius="50%">
      <p a-style fs="1.3" color="#2479AB">Global Furniture Pvt. Ltd.</p>
    </div>
    <p a-style fs="1.3" maxWidth="22" class=lineClamp>Manufacturer & Exporter</p>
    <p a-style fs="1.3" color="#B2B2B2">Mumbai, Maharashtra</p>
  </div>
</ng-template>

<div *ngIf="type=='websitecard'">
  <div a-style w="24.0" h="32.2" br="0.5px solid #e4e4e4" brRadius="6px" p="1" display="flex" flexDirection="column"
    gap="1">
    <img src="https://new-api.mesbro.com/uploads/{{detail.Media.attributes[0].value[0]}}" a-style w="22" h="22"
      brRadius="0.6">
    <div a-style align="left">
      <p a-style fs="1.3" color=" #2479AB">{{ detail?.Basic_Details?.currency?.value ?
        detail?.Basic_Details?.currency?.value : 'INR'}} {{detail.Basic_Details.attributes[6].value ?
        detail.Basic_Details.attributes[6].value : 0}} - {{detail.Basic_Details.attributes[7].value ?
        detail.Basic_Details.attributes[7].value : 0}}</p>
      <p a-style fs="1.3" class=lineClamp>{{detail.Basic_Details.attributes[1].value}}</p>
      <div a-style display="flex" justify="space-between">
        <div a-style display="flex" gap="0.3"><img src="../assets/images/Path 73132.svg" a-style w="1.2" h="1.2"><img
            src="../assets/images/Path 73132.svg" a-style w="1.2" h="1.2"><img src="../assets/images/Path 73132.svg"
            a-style w="1.2" h="1.2"><img src="../assets/images/Path 73132.svg" a-style w="1.2" h="1.2"><img
            src="../assets/images/Path 73132.svg" a-style w="1.2" h="1.2"></div>
        <img src="https://new-api.mesbro.com/uploads/old_files/country-flags/india.svg" a-style w="2" h="1.2">
      </div>
    </div>
  </div>
</div>
<ng-template #rating_input let-passed_data="passed_data">
  <div a-style display="flex" align=center>
    <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:1.9"
      [style.color]="passed_data.rating<1? '#f1f1f1' : 'gold'" (click)="passed_data.editable && rating=1">★
    </p>
    <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:1.9"
      [style.color]="passed_data.rating<2? '#f1f1f1' : 'gold'" (click)="passed_data.editable && rating=2">★
    </p>
    <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:1.9"
      [style.color]="passed_data.rating<3? '#f1f1f1' : 'gold'" (click)="passed_data.editable && rating=3">★
    </p>
    <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:1.9"
      [style.color]="passed_data.rating<4? '#f1f1f1' : 'gold'" (click)="passed_data.editable && rating=4">★
    </p>
    <p cursor=pointer a-style ml=0.3 [fs]="passed_data.star_size?passed_data.star_size:1.9"
      [style.color]="passed_data.rating<5? '#f1f1f1' : 'gold'" (click)="passed_data.editable && rating=5">★
    </p>
    <!-- {{rating}} -->
  </div>
</ng-template>