export enum Images {
    image1 = 'https://mesbro.in/assets/images/idm-sign-in.png',
    image2 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg',
    image3 = 'https://png.pngtree.com/png-vector/20190307/ourmid/pngtree-vector-edit-profile-icon-png-image_762931.jpg',
    image4 = 'https://png.pngtree.com/png-vector/20190120/ourmid/pngtree-key-vector-icon-png-image_470666.jpg',
    image5 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg',
    image6 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/pencil-white.svg',
    image7 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/business-blue-icon.svg',
    image8 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/video-red-icon.svg',
    image9 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/mail-blue-icon.svg',
    image10 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/chat-green-icon.svg',
    image11 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/news-violet-icon.svg',
    image12 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/article-orange-icon.svg',
    image13 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/property.svg',
    image14 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cars.svg',
    image15 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/file-transfer.svg',
    image16 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/setting-black.svg',
    image17 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/qr-code.svg',
    image18 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/share-yellow.svg',
    image19 = 'https://mesbro.in/assets/images/bug.png',
    image20 = 'https://mesbro.in/assets/images/add-image.svg',
    image21 = 'https://mesbro.in/assets/images/feature.png',
    image22 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/43/23/602cebbec76ddbd65d63f47f/60371af0bf7db17c5dfdb0e9.jpeg',
    image23 = 'https://mesbro.in/assets/images/temp-images/home-page-banner.png',
    image24 = 'https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg',
    image25 = 'https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg',
    image26 = 'https://mesbro.in/assets/images/delete-faded.svg',
    image27 = 'https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg',
    image28 = 'https://mesbro.in/assets/images/rfq-buyer.svg',
    image29 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
    image30 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/22/49/602cecd2c76ddbd65d63f499/6035bd4fbf7db17c5dfd93c9.png',
    image31 = 'https://mesbro.in/assets/images/temp-images/home-page-banner.png',
    image32 = 'https://mesbro.in/assets/images/bookmark-faded.svg',
    image33 = 'https://mesbro.in/assets/images/category-image-color.svg',
    image34 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg',
    image35 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjD8HMsodcy1-rVIdBDrd5TJA_wV87-KBTdA&usqp=CAU',
    image36 = 'https://miro.medium.com/max/1200/1*mk1-6aYaf_Bes1E3Imhc0A.jpeg',
    image37 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/22/49/602cecd2c76ddbd65d63f499/6035ffebbf7db17c5dfd9e5f.png',
    image38 = 'https://cdn1.iconfinder.com/data/icons/basic-ui-icon-rounded-colored/512/icon-06-512.png',
    image39 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/22/49/602cecd2c76ddbd65d63f499/6035e2eebf7db17c5dfd9a15.jpg',
    // generic details page compair 
    image40 = 'https://mesbro.in/assets/images/like-grey.svg',
    image41 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/dislike.svg',
    image42 = 'https://mesbro.in/assets/images/compare.svg',
    image43 = 'https://mesbro.in/assets/images/bookmark-faded.svg',
    image44 = 'https://mesbro.in/assets/images/print.svg',
    image45 = 'https://mesbro.in/assets/images/qr.svg',
    image46 = 'https://mesbro.in/assets/images/share-grey.svg',
    ///
    image47 = 'https://mesbro.in/assets/images/one-call.svg',
    image48 = 'https://htmlcolorcodes.com/assets/images/colors/neon-orange-color-solid-background-1920x1080.png',
    image49 = 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Five_Pointed_Star_Solid.svg/1087px-Five_Pointed_Star_Solid.svg.png',
    image50 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-black.svg',
    image51 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/43/23/602cebbec76ddbd65d63f47f/60371af0bf7db17c5dfdb0e9.jpeg',
    image52 = 'https://mesbro.in/assets/images/temp-images/temp-banner-2.png',
    image53 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-black.svg',
    image54 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
    image55 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/09794630-d073-11e9-8bf1-cf96fd5dbf2b.png',
    image56 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-black.svg',
    image57 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
    image58 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/09794630-d073-11e9-8bf1-cf96fd5dbf2b.png',
    image59 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/09794630-d073-11e9-8bf1-cf96fd5dbf2b.png',
    image60 = 'https://mesbro.in/assets/images/one-call.svg',
    image61 = 'https://wallpaperaccess.com/full/1745604.jpg',
    image62 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg',
    image63 = 'https://mesbro.in/assets/images/temp-images/home-page-banner.png',
    image64 = 'https://mesbro.in/assets/images/temp-images/home-page-banner.png',
    image65 = 'https://png.pngtree.com/element_our/20190601/ourmid/pngtree-lightning-icon-image_1338425.jpg',
    image66 = 'https://mesbro.in/assets/images/temp-images/remaining-place-banner.png',
    image67 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/22/49/602cecd2c76ddbd65d63f499/6033a6eff63ac4555ef489fd.jpg',
    image68 = 'https://mesbro.in/assets/images/delete-red.svg',
    image69 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/43/23/602cebbec76ddbd65d63f47f/603dfae7b3b5ba1f750846fb.jpg',
    image70 = 'https://mesbro.in/assets/images/delete-faded.svg',
    image71 = 'https://mesbro.in/assets/images/mail/plus-withCircle-faded.svg',
    image72 = 'https://png.pngtree.com/element_our/20190601/ourmid/pngtree-lightning-icon-image_1338425.jpg',
    image73 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg',
    image74 = 'https://mesbro.in/assets/images/bookmark-faded.svg',    
    image75 = 'https://mesbro.in/assets/images/idm-sign-up.png',
    //sub header
    image76 = 'https://mesbro.in/assets/images/compare-black.svg',
    image77 = 'https://mesbro.in/assets/images/Product_Package-black.svg',
    image78 = 'https://mesbro.in/assets/images/bookmark-black.svg',
    image79 = 'https://mesbro.in/assets/images/history-color.svg',
    // categories
    image80 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b4e3a880-d05f-11e9-8bf1-cf96fd5dbf2b.png',
    image81 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',
    image82 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e53a65c505ea500342226d9.png',
    image83 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5e8affcce084ef00344f6c46.png',
    image84 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/6c5dabe0-d075-11e9-8bf1-cf96fd5dbf2b.png',
    image85 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e53a728505ea5003422270a.png',
    image86 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e53a293b288ae003489598e.png',
    image87 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e53a14db288ae0034895960.png',
    image88 = 'https://mesbro.in/assets/images/side-menu-icon-blue.svg',
    //right side
    image89 = 'https://mesbro.in/assets/images/temp-images/landingimg-7.png',
    image90 = 'https://mesbro.in/assets/images/temp-images/landingimg-8.png',
    //banner image 
    image91 = 'https://mesbro.in/assets/images/temp-images/landingimg-6.png',
    //job , Property, Business Email
    image92 = 'https://mesbro.in/assets/images/temp-images/landingimg-2.png',
    image93 = 'https://mesbro.in/assets/images/temp-images/landingimg-1.png',
    image94 = 'https://mesbro.in/assets/images/temp-images/landingimg-3.png',
    // newsletter
    image95 = 'https://mesbro.in/assets/images/temp-images/landingimg-5.png',
    /// input sub header  bar image 
    image96 = 'https://mesbro.in/assets/images/product-service-icon.svg',
    // generic details contact details
    image97 = 'https://mesbro.in/assets/images/message.svg',
    image98 = 'https://mesbro.in/assets/images/phone.svg',
    ///user details 
    image99 = 'https://mesbro.in/assets/images/star-faded.svg',
    // generic details page compair  color madhe
    image100 = 'https://mesbro.in/assets/images/like-color-blue.svg',
    image101 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/dislike-black.svg',
    image102 = 'https://mesbro.in/assets/images/bookmark-red.svg',
    // product list 
    image103 = 'https://mesbro.in/assets/images/temp-images/remaining-place-banner.png',
    image104 = 'https://mesbro.in/assets/images/add-card-image/add-product-card.svg',
    image105 = 'https://mesbro.in/assets/images/product-not-found.png',
    image106 = 'https://mesbro.in/assets/images/temp-images/my-product-organization.png',
    image107 = 'https://mesbro.in/assets/images/product-not-found.png',
    image108 = 'https://mesbro.in/assets/images/productnotfound.svg',
    // sub header home
    image109 = 'https://mesbro.in/assets/images/home.svg',
    // all categories
    image110 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e539d97b288ae00348958c2.png',
    image111 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e539baab288ae0034895855.png',
    image112 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/66cde1b0-d05f-11e9-8bf1-cf96fd5dbf2b.png',
    image113 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/09794630-d073-11e9-8bf1-cf96fd5dbf2b.png',
    image114 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/5afb47d0-d071-11e9-8bf1-cf96fd5dbf2b.png',
    image115 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/ae496c40-cfd2-11e9-8bf1-cf96fd5dbf2b.png',
    image116 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/7d8f83f0-cfd2-11e9-8bf1-cf96fd5dbf2b.png',
    image117 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/3cfb8870-cfd2-11e9-8bf1-cf96fd5dbf2b.png',
    image118 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/d46ffe00-cfcf-11e9-8bf1-cf96fd5dbf2b.png',
    image119 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/522da840-cfcd-11e9-8bf1-cf96fd5dbf2b.png',
    image120 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b98d6590-0b67-11ea-8bf1-cf96fd5dbf2b.png',
    image121 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/9d9e37e0-011e-11ea-8bf1-cf96fd5dbf2b.png',
    image122 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/01e6be40-011d-11ea-8bf1-cf96fd5dbf2b.png',
    image123 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/ccc32550-d06b-11e9-8bf1-cf96fd5dbf2b.png',
    image124 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/daec97c0-d06a-11e9-8bf1-cf96fd5dbf2b.png',
    image125 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/62951900-d06a-11e9-8bf1-cf96fd5dbf2b.png',
    image126 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/7ca0b3b0-d06d-11e9-8bf1-cf96fd5dbf2b.png',
    image127 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/779d8630-d064-11e9-8bf1-cf96fd5dbf2b.png',
    image128 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/46a7cc60-d06f-11e9-8bf1-cf96fd5dbf2b.png',
    image129 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/0d8a9aa0-d071-11e9-8bf1-cf96fd5dbf2b.png',
    image130 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/296b8e60-d070-11e9-8bf1-cf96fd5dbf2b.png',
    image131 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/9c45a030-d05f-11e9-8bf1-cf96fd5dbf2b.png',
    image132 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/8444e950-d05f-11e9-8bf1-cf96fd5dbf2b.png',
    image133 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/60b75eb0-d072-11e9-8bf1-cf96fd5dbf2b.png',
    image134 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/38a74e70-d05f-11e9-8bf1-cf96fd5dbf2b.png',
    image135 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/91127540-d05e-11e9-8bf1-cf96fd5dbf2b.png',
    image136 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b20a9d40-d072-11e9-8bf1-cf96fd5dbf2b.png',
    image137 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/5fde4280-0b67-11ea-8bf1-cf96fd5dbf2b.png',
    image138 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b6030b60-cfd4-11e9-8bf1-cf96fd5dbf2b.png',
    image139 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/a575ba30-ced6-11e9-8bf1-cf96fd5dbf2b.png',
    //filter image 
    image140 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/thunder-blue.svg',
    // notification bell
    image141 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/bells-orange.svg',
    // delete image 
    image142 = 'https://mesbro.in/assets/images/delete-faded.svg',
    // mesbro compare
    image143 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/mesbro-compare-logo.svg',
    ///browse product
    image144 = 'https://mesbro.in/assets/images/Product_Package-grey.svg',
    // rfq image
    image145 = 'https://mesbro.in/assets/images/rfq-buyer.svg',
    image146 = 'https://mesbro.in/assets/images/rfq-supplier.svg',
    //clear save list
    image147 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/delete-blue.svg',
    // idm part
    // sign in 
    image149 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/eye.svg',
    // sign-up
    image150 = 'https://mesbro.in/assets/images/information.svg',
    //otp verification key 
    image151 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/key.svg',
    // sent enquiries
    image152 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg',
    // edit profile pencile
    image153 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/pencil-white.svg',
    image154 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/edit-pencil-black.svg',
    // edit var click kela tar  account setting 
    image155 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/user-white.svg',
    image156 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/location-black.svg',
    image157 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/contact-black.svg',
    image158 = 'https://mesbro.in/assets/icons/share.svg',
    image159 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/lock-black.svg',
    // idm socal media 
    image160 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/facebook_colored.png',
    image161 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/whatsapp_buisness_colored.png',
    image162 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/qq_Colored.png',
    image163 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/we_chat_colored.png',
    image164 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/qzone_colored.png',
    image165 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/tumblr_colored.png',
    image166 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/instagram_colored.png',
    image167 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/twitter_colored.png',
    image168 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/google-plus_colored.png',
    image169 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/baidu_tieba_colored.png',
    image170 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/skype_colored.png',
    image171 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/viber_colored.png',
    image172 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/sina_weibo_colored.png',
    image173 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/line_colored.png',
    image174 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/snap_chat_colored.png',
    image175 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/yy_colored.png',
    image176 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/v_kontakte_colored.png',
    image177 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/pinterest_colored.png',
    image178 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/linkedin_colored.png',
    image179 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/telegram_colored.png',
    image180 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/reddit_colored.png',
    image181 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/taringa_colored.png',
    image182 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/foursquare_colored.png',
    image183 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/renren_colored.png',
    image184 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/tagged_colored.png',
    image185 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/badoo_colored.png',
    image186 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/myspace_colored.png',
    image187 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/stumble_upon_colored.png',
    image188 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/the_dots_colored.png',
    image189 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/kiwibox_colored.png',
    image190 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/skyrock_colored.png',
    image191 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/delicious_colored.png',
    image192 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/snapfish_colored.png',
    image193 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/reverbnation_colored.png',
    image194 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/flixster_colored.png',
    image195 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/care2_colored.png',
    image196 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/cafemom_colored.png',
    image197 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/ravelry_colored.png',
    image198 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/nextdoor_colored.png',
    image199 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/wayn_coloredd.png',
    image200 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/cellufun_colored.png',
    image201 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/youtube_colored.png',
    image202 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/classmates_colored.png',
    image203 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/my_heritage_colored.png',
    image204 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/viadeo_colored.png',
    image205 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/xing_colored.png',
    image206 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/xanga_colored.png',
    image207 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/live_journal_colored.png',
    image208 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/friendster_colored.png',
    image209 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/funny_or_die_colored.png',
    image210 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/gaia_online_colored.png',
    image211 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/we_heart_it_colored.png',
    image212 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/buzznet_colored.png',
    image213 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/devianart_colored.png',
    image214 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/flickr_colored.png',
    image215 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/meet_me_colored.png',
    image216 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/meetup_colored.png',
    image217 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/tout_colored.png',
    image218 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/mixi_colored.png',
    image219 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/douban_colored.png',
    image220 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/vero_colored.png',
    image221 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/quora_colored.png',
    image222 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/social-media/spreely_colored.png',
    // mail start 
    // mail sub header
    image223 = 'https://mesbro.in/assets/images/home-outline.svg',
    image224 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/pulse-white.svg',
    image225 = 'https://mesbro.in/assets/images/mail/filter.svg',
    image226 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-white.svg',
    image227 = 'https://mesbro.in/assets/images/history-outline.svg',
    // mail side bar first color image blue color wale
    image228 = 'https://mesbro.in/assets/images/mail/inboxColored.svg',
    image229 = 'https://mesbro.in/assets/images/mail/archiveColored.svg',
    image230 = 'https://mesbro.in/assets/images/mail/draftColored.svg',
    image231 = 'https://mesbro.in/assets/images/mail/favouriteColored.svg',
    image232 = 'https://mesbro.in/assets/images/mail/sentColored.svg',
    image233 = 'https://mesbro.in/assets/images/mail/spamColored.svg',
    image234 = 'https://mesbro.in/assets/images/mail/trashColored.svg',
    image235 = 'https://mesbro.in/assets/images/mail/snoozedColored.svg',
    image236 = 'https://mesbro.in/assets/images/mail/scheduledColored.svg',
    // mail side bar first gray color 
    image237 = 'https://mesbro.in/assets/images/mail/inbox-Faded.svg',
    image238 = 'https://mesbro.in/assets/images/mail/archive-Faded.svg',
    image239 = 'https://mesbro.in/assets/images/mail/draft-Faded.svg',
    image240 = 'https://mesbro.in/assets/images/mail/favourite-Faded.svg',
    image241 = 'https://mesbro.in/assets/images/mail/sent-Faded.svg',
    image242 = 'https://mesbro.in/assets/images/mail/spam-Faded.svg',
    image243 = 'https://mesbro.in/assets/images/mail/trash-Faded.svg',
    image244 = 'https://mesbro.in/assets/images/mail/snoozed-Faded.svg',
    image245 = 'https://mesbro.in/assets/images/mail/scheduled-Faded.svg',
    // general side bar
    image246 = 'https://mesbro.in/assets/images/mail/sideMenu-add.svg',
    image247 = 'https://mesbro.in/assets/images/mail/groupSelect.svg',
    // arrow first last sub header
    image248 = 'https://mesbro.in/assets/images/mail/mail-left-direction.svg',
    image249 = 'https://mesbro.in/assets/images/mail/mail-right-direction.svg',
    // side bar image 
    image250 = 'https://mesbro.in/assets/images/mail/envelop-faded.svg',
    image251 = 'https://mesbro.in/assets/images/mail/plus-withCircle-faded.svg',
    image252 = 'https://mesbro.in/assets/images/mail/mail-forward-withSpace-faded.svg',
    image253 = 'https://mesbro.in/assets/images/mail/mail-bookmark-faded.svg',
    image254 = 'https://mesbro.in/assets/images/mail/more-withExtraSpace-faded.svg',
    image255 = 'https://mesbro.in/assets/images/mail/mark-Imp-faded.svg',
    image256 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg',
    //mail detail page 
    image257 = 'https://mesbro.in/assets/images/mail/starred-Faded.svg',
    image258 = 'https://mesbro.in/assets/images/mail/forward-faded.svg',
    image259 = 'https://mesbro.in/assets/images/mail/reply-faded.svg',
    image260 = 'https://mesbro.in/assets/images/mail/reply-faded.svg',
    image261 = 'https://mesbro.in/assets/images/mail/reply-all-faded.svg',
    image262 = 'https://mesbro.in/assets/images/mail/print-faded.svg',
    image263 = 'https://mesbro.in/assets/images/mail/more-widthSpace-faded.svg',
    // pdf download icon
    image264 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/download-lite-white.svg',
    image265 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/file-extensions/pdf.svg', //pdf image 
    image266 = 'https://mesbro.in/assets/images/mail/right-arrow-faded.svg',//attachment file
    image267 = 'https://mesbro.in/assets/images/mail/plus-withCircle-faded.svg',
    ///mail right side bar 
    image268 = 'https://mesbro.in/assets/images/app-icon/keep.svg',
    image269 = 'https://mesbro.in/assets/images/app-icon/calender.svg',
    image270 = 'https://mesbro.in/assets/images/mail/gear.svg',
    // no new mails
    image271 = 'https://mesbro.in/assets/images/mail-view-placeholder.svg',
    // draft mail details page creoss icon
    image272 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg',
    // discard scheduled all 
    image273 = 'https://mesbro.in/assets/images/mail/attachment-faded.svg',
    image274 = 'https://mesbro.in/assets/images/mail/smile-faded.svg',
    image275 = 'https://mesbro.in/assets/images/mail/important-delivery-faded.svg',
    image276 = 'https://mesbro.in/assets/images/mail/compose-envelope-faded.svg',
    image277 = 'https://mesbro.in/assets/images/mail/more-horizontal-faded.svg',
    image278 = 'https://mesbro.in/assets/images/mail/delete-faded.svg',
    image279 = 'https://mesbro.in/assets/images/mail/schedule-blue.svg',
    image280 = 'https://mesbro.in/assets/images/mail/forward-white.svg',
    // pdf mail modal color cross icon
    image281 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross-white.svg',
    // setting var click kela asel tar setting page open hot left side bar 
    image282 = 'https://mesbro.in/assets/images/mail/mail-setting-withSpace-faded.svg',
    image283 = 'https://mesbro.in/assets/images/mail/calender-faded.svg',
    image284 = 'https://mesbro.in/assets/images/mail/people-faded.svg',
    // setting madhe general  page storage 
    image285 = 'https://mesbro.in/assets/images/share.svg',
    // general pudhe write click color madhe 
    image286 = 'https://mesbro.in/assets/images/mail/groupColored.svg',
    // mail madhe click kela tar attachment madhe Storage accounts
    image287 = 'https://mesbro.in/assets/icons/drive.svg',
    image288 = 'https://mesbro.in/assets/icons/dropbox.svg',
    //mail madhe click kela tar label madhe 
    image289 = 'https://mesbro.in/assets/images/plus.svg',
    // mail madhe click kela  ki rules madhe 
    image290 = 'https://mesbro.in/assets/images/rubbish-bin-delete-button.svg',
    image291 = 'https://mesbro.in/assets/translator/pencil.svg',
    // idm edit mobile image
    image292 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/mobile.svg',
    // idm profile image 
    image293 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/female-avatar.png',
    //translator arrow
    image294 = 'https://mesbro.in/assets/images/down-arrow-white.svg',
    image295 = 'https://mesbro.in/assets/translator/exchange.svg',
    image296 = 'https://mesbro.in/assets/translator/text.svg',
    image297 = 'https://mesbro.in/assets/translator/speaker.svg',
    image298 = 'https://mesbro.in/assets/translator/keyboard.svg',
    image299 = 'https://mesbro.in/assets/translator/pencil.svg',
    image300 = 'https://mesbro.in/assets/translator/mic.svg',
    image301 = 'https://mesbro.in/assets/translator/bookmark.svg',
    image302 = 'https://mesbro.in/assets/translator/copy.svg',
    image303 = 'https://mesbro.in/assets/translator/share.svg',
    image304 = 'https://mesbro.in/assets/translator/download.svg',
    image305 = 'https://mesbro.in/assets/images/right-faded.svg',
    image306 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/clock-border.svg',
    image307 = 'https://colorlib.com/etc/404/colorlib-error-404-18/img/emoji.png',
    image308 = 'https://www.pngfind.com/pngs/m/628-6288712_smiley-blanc-png-wink-smiley-face-black-and.png',
    image309 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/mesbro-logo.png',
    image310 = 'https://mesbro.in/assets/images/temp-images/landingimg-4.png',
    // product header //
    image311 = 'https://mesbro.in/assets/images/Product_Package.svg',
    image312 = 'https://mesbro.in/assets/images/compare-outline.svg',
    image313 = 'https://mesbro.in/assets/images/bookmark.svg',
    image314 = 'https://mesbro.in/assets/images/history-outline.svg',
    image315 = "https://s3.ap-south-1.amazonaws.com/files.mesbro.c…64/image/bbb03a70-d05f-11e9-8bf1-cf96fd5dbf2b.png",
    image316 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…64/image/1b35a890-d060-11e9-8bf1-cf96fd5dbf2b.png',
    image317 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…34b1b993/Navin_Singh/5e53a653505ea500342226d5.png',
    image318 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…5bbf86a5ba0034b1b993/5e8b005be084ef00344f6c7e.png',
    image319 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…64/image/73562fd0-d075-11e9-8bf1-cf96fd5dbf2b.png',
    image320 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…34b1b993/Navin_Singh/5e53a71d505ea50034222706.png',
    image321 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…34b1b993/Navin_Singh/5e53a6d7505ea500342226f5.png',
    image322 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…08f31/Sudhir_Gaikwad/5e53a236b288ae0034895979.png',
    image323 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.c…08f31/Sudhir_Gaikwad/5e53a143b288ae003489595c.png',
    image324 = 'https://mesbro.in/assets/images/side-menu-icon.svg',
    image325 = 'https://mesbro.in/assets/icons/call.svg',
    image326 = 'https://mesbro.in/assets/images/app-icon/chat.svg',
    image327 = 'https://mesbro.in/assets/images/app-icon/mail.svg',
    image328 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/like.svg',
    image329 = 'https://image.flaticon.com/icons/png/512/1828/1828817.png',
    image330 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/Mesbro-text.png',
    image331 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/clock-border.svg',
    image332 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/star-border.svg',
    image333 = 'https://mesbro.in/assets/images/Product_Package-black.svg',
    // Features Images
    image334 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/93/40.svg',
    image335 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/93/39.svg',
    image336 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/93/38.svg',
    image337 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/93/37.svg',
    image338 = 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross-red.svg',
    // image339 ='https://new-api.mesbro.com/uploads/99/99/99/99/99/99/30.svg',
    //Vehicle Application icons
    image339 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/55.svg', // auto image
    image340 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/54.svg', // delhi-ncr
    image341 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/53.svg', // delhi
    image342 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/52.svg', // dealers & services
    image343 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/51.svg', // compare
    image344 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/50.svg', // car
    image345 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/49.svg', // bus
    image346 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/48.svg', // bike
    image347 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/47.svg', // bicycle
    image348 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/46.svg', // ask_our_experts
    image349 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/45.svg', // gurgaon
    image350 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/44.svg', // hyderabad
    image351 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/43.svg', // jaipur
    image352 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/42.svg', // low_weight_vehicle
    image353 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/41.svg', // mumbai
    image354 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/40.svg', // my_vehicle
    image355 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/39.svg', // new
    image356 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/38.svg', // heavy_motor_vehicles
    image357 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/37.svg', // history
    image358 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/36.svg', // kolkata
    image359 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/35.svg', // noida
    image360 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/34.svg', // used
    image361 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/33.svg', // vehicle_logo
    image362 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/31.svg', // van
    image363 = 'https://mesbro.in/assets/images/mail/sideMenu-add.svg',  //mail
    image364 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/30.svg',
    image365 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/76.svg',  //social birthday image
    image366 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/73.svg',  //social birthday and party
    image367 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/72.png',   // friend card image
    image368 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/71.svg',   //friends icon
    image369 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/63.png',     ///
    image370 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/62.svg',
    image371 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/60.png',   //post image
    image372 = 'https://mesbro.in/assets/images/mail/favourite-Faded.svg',
    image373 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/54.svg',   //social pencil
    image374 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/55.svg',   //social photo
    image375 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/56.svg',   //video
    image376 = 'https://mesbro.in/assets/images/mail/starred-Faded.svg',
    image377 = 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Star_icon-72a7cf.svg/768px-Star_icon-72a7cf.svg.png',
    image378 = 'https://mesbro.in/assets/images/mail/starred-Faded.svg',
    image379 = 'https://mesbro.in/assets/images/mail/archive-noextra-faded.svg',
    image380 = 'https://mesbro.in/assets/images/mail/delete-faded.svg',
    image381 = 'https://mesbro.in/assets/images/mail/mail-forward-withSpace-faded.svg',
    image382 = 'https://mesbro.in/assets/images/mail/more-withExtraSpace-faded.svg',
    image383 = 'https://mesbro.in/assets/images/mail/groupSelect.svg',
    image384 = 'https://new-api.mesbro.com/uploads/99/99/99/99/99/98/30.svg',      //social plus
    image385 = 'https://mesbro.in/assets/images/one-call.svg',
    image386 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/95.png',      //social event img  
    image387 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/94.svg',
    image388 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/92.svg',
    image389 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/93.svg',
    image390 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/91.png',
    image391 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/90.png',
    image392 = 'https://www.clipartkey.com/mpngs/m/225-2256387_free-thin-right-arrow-icon-png-vector-black.png',
    image393 = 'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-back-512.png',
    image394 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/83.svg',// compare green 
    image395 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/81.svg',// yellow star
    image396 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/80.svg',// share blue 
    image397 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/79.svg',// save red 
    image398 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/58.svg', 
    image399 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/51.svg',
    image400 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/50.svg',
    image401 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/52.svg',
    image402 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/29.png',
    image403 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/09.svg',
    image404 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/10.svg',
    image405 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/11.svg',
    image406 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/04.svg',
    image407 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/03.svg',
    image408 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/02.svg',
    image409 = 'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/98/01.svg',
    image410 = 'assets/images/assets/profile img.svg',

}
