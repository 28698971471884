export const rfq_component_items = [
    {
      title: 'Machinery & Industrial Supplies',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/b4e3a880-d05f-11e9-8bf1-cf96fd5dbf2b.png',
     
    },
 
    {
      title: 'Musical Instrument',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/04d00410-d060-11e9-8bf1-cf96fd5dbf2b.png',

    },
    {
      title: 'Minerals & Metallurgy',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e53a65c505ea500342226d9.png',
      
    },
    {
      title: 'Gym & Fitness',
      image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/5e8affcce084ef00344f6c46.png',
    
 
    },
    {
        title: 'Animals & Pet Supplies',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/6c5dabe0-d075-11e9-8bf1-cf96fd5dbf2b.png',
      
   
      },
      {
        title: 'Plastic Industry',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e53a728505ea5003422270a.png',
      
   
      },
      {
        title: 'Rubber Industry',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e53a6de505ea500342226f9.png',
      
   
      },
      {
        title: 'Construction Industry',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e53a293b288ae003489598e.png',
      
   
      },
      {
        title: 'Packaging Industry',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e53a14db288ae0034895960.png',
      
   
      },
      {
        title: 'Parts & Accessories',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/58/36/5e3a651df56bfd0034908f31/Sudhir_Gaikwad/5e539d97b288ae00348958c2.png',
      
   
      },
      {
        title: 'Sports & Entertainment',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/45/29/5e4f5bbf86a5ba0034b1b993/Navin_Singh/5e539baab288ae0034895855.png',
      
   
      },
      {
        title: 'Home & Kitchen',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/66cde1b0-d05f-11e9-8bf1-cf96fd5dbf2b.png',
      
   
      },
      {
        title: 'Toys & Games',
        image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/56/89/6fd4e2a0-55f2-11e9-8bcb-05efaf725b64/image/09794630-d073-11e9-8bf1-cf96fd5dbf2b.png',
      
   
      },
    
  ]