import { Component, Input, OnInit } from '@angular/core';
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-bar-code',
  templateUrl: './bar-code.component.html',
  styles: [
  ]
})
export class BarCodeComponent implements OnInit {
  // public elementType = NgxQrcodeElementTypes.URL;
  // public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  // public value: any = 'http://localhost:4200/generic/detail?_id=60f984398faed13ed2d03331'
  @Input() details: any;

  elementType = 'url';
  // value = 'https://mesbro.com/generic/detail?_id=';
  // @Input() visible = false;
  // https://mesbro.com/generic/detail?_id=this.details._id
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
  }

  // show() {
  //   this.visible = true;
  // }

}
