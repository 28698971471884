<div  a-style display="flex" flexDirection="column" gap="2.4" maxWidth="72.7"  h="55.8" br="0.5px solid #E4E4E4" brRadius="6px" box-shadow="0px 3px 6px #17171729"
p="2.4">
<p a-style fs="1.6" align="left">Billing Details</p>
<form [formGroup]="form">
  <div a-style mt="2.4" display="flex" flexWrap="wrap" gap="2.4"   maxWidth="67.2" >
    <m-new-input formControlName="orgName" [type]="gs.enums.inputs.newText" placeHolder="Organization Name"
      label="Organization Name" minWidth="32.4"></m-new-input>
    <m-new-input formControlName="personName" [type]="gs.enums.inputs.newText" placeHolder="Person Name"
      label="Person Name" minWidth="32.4"></m-new-input>
    <m-new-input formControlName="mobile" [type]="gs.enums.inputs.newText" placeHolder="Mobile Number"
      label="Mobile Number" minWidth="32.4">
    </m-new-input>
    <m-new-input formControlName="email" [type]="gs.enums.inputs.newText" placeHolder="Email Address"
      label="Email Address" minWidth="32.4"></m-new-input>
    <m-new-input formControlName="gstin" [type]="gs.enums.inputs.newText" placeHolder="GSTIN" label="GSTIN"
      minWidth="32.4">
    </m-new-input>
    {{details | json}}
    <m-input formControlName="billingAdd" [type]="gs.enums.inputs.text_area" maxLength="2000" mt="0" px="0"
      placeHolder="Billing Address"
      label="Billing Address" rows=2 minWidth="32.7"></m-input>
  </div>
  <div a-style display="flex" justify="center" align="center" w="67.2" >
    <button a-style w="8.5" h="2.9" br="0.5px solid #2479AB" bg="#FFFFFF" color="#2479AB" px="0.5" brRadius="0.6" (click)="sucess.openModal()">Submit</button>
   </div>
</form>
</div>


<!-- <m-modal #sucess>
  <div a-style display="flex" align="center" justify="center" h="100%">
    <div a-style br="1px solid #e4e4e4" h="21.2" w="55.0" align="center" justify="center" brRadius="0.6"
      position="relative" display="flex">
      <div a-style>
        <img src="https://cdn0.iconfinder.com/data/icons/round-ui-icons/512/tick_green.png" alt="" a-style h="10.0"
          w="10.0" jalign="center">
        <p a-style fs="2.0" align="center" color="#333333">Billing Address Added successfully</p>
      </div>
    </div>
  </div>
</m-modal> -->
<m-success-modal #sucess title="Billing Address Added successfully" >
</m-success-modal>