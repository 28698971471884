
<div a-style *ngFor="let item of [1,2]">
    <ng-container [ngTemplateOutlet]="solidColor" [ngTemplateOutletContext]="{colorData : {type:'color' }}"></ng-container>
</div>
<div a-style display="flex" justify="flex-end" mt=2.4 flexGap=1.6 align=center>
    <p a-style fs=1.3 (click)="modal.closeModal()">Cancel</p>
    <!-- <button a-style px="2.4" py="0.8" bg="#006BFF" br="1px solid #006BFF" color="#FFFFFF" brRadius="0.4" outline="0">Set</button> -->
    <m-button   [types]="gs.enums.buttons.button18" text="Set" ></m-button>
</div>

<ng-template #solidColor let-colorData="colorData">
    <div a-style align="center" display=flex>
        <m-input type={{colorData.type}}></m-input>
    </div>
</ng-template>