import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

let required = (name?: string) => {
    let required_validation_method = (control: FormControl): ValidationErrors | null => {
        let value_exists = control.value;
        let error_message = { message: `${name} is required` }
        if (value_exists == null || value_exists == undefined || value_exists == '') {
            return error_message
        } else {
            return null
        }
    };
    return required_validation_method
}
let number = (number: 1 ) => {
    let required_validation_method = (control: FormControl): ValidationErrors | null => {
        let value_exists = control.value;
        let error_message = { message: `${name} is required` }
        if (value_exists == null || value_exists == undefined || value_exists == '') {
            return error_message
        } else {
            return null
        }
    };
    return required_validation_method
}

let price = (type: string) => {
    let required_validation_method = (control: FormControl): ValidationErrors | null => {
        let error_message = { message: '' }
        let current_value = control.value;
        if (current_value == null || current_value == undefined || current_value == '') {
            return error_message
        }
        if (type == 'from') {
            error_message.message = 'Should be lesser than Price To value'
            control.parent?.get('price_to')?.markAllAsTouched()
            if (current_value > control.parent?.get('price_to')?.value) {
                return error_message
            }
        }
        if (type == 'to') {
            control.parent?.get('price_to')?.markAllAsTouched()
            error_message.message = 'Should be greater than Price To value'
            if (current_value < control.parent?.get('price_from')?.value) {
                return error_message
            }
        }

        return null
    };
    return required_validation_method
}

let length_equals = (length: number ) => {
    let required_validation_method = (control: FormControl): ValidationErrors | null => {
        let value = control.value;
        let valid_length = value && !isNaN(value)
        let error_message = {  message:'' }
        if (value == null || value == undefined || value == '' || value.length != length || !valid_length) {
            return error_message
        } else {
            return null
        }
    };
    return required_validation_method
}


let password = (control: FormControl): ValidationErrors | null => {
    let value = control.value;
       let regex= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    // let valid_password = value && value.length >= 8
    let valid_password = regex.test(value);
    let error_message = { message: 'Invalid Password' }
    if (!valid_password) {
        return error_message
    } else {
        return null
    }
};

let confirm_password = (control: FormControl): ValidationErrors | null => {
    let value = control.value;
    let password_value = control.parent?.get('password')?.value || control.parent?.get('new_password')?.value
    let valid_password = value == password_value
    let error_message = { message: 'Invalid Password' }
    if (!valid_password) {
        return error_message
    } else {
        return null
    }
};

let mobile_number = (control: FormControl): ValidationErrors | null => {
    let value: any = control.value;
    value = value?.replace(/ /g, '')
    let valid_mobile_number = value && !isNaN(value) && value.length >= 4 && value.length <= 13
    let error_message = { message: 'Invalid Mobile Number' }
    if (!valid_mobile_number) {
        return error_message
    } else {
        return null
    }
};

let email_address = (control: FormControl): ValidationErrors | null => {
    let value: any = control.value;
    let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let valid_email_address = regex.test(value)
    let error_message = { message: 'Invalid Email Address' }
    if (!valid_email_address) {
        return error_message
    } 
    else {
        return null
    }
};

let domain_name = (control: FormControl): ValidationErrors | null => {
    let value: any = control.value;
    let regex = /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/;
    let valid_domain_name = regex.test(value)
    let error_message = { message: 'Invalid Domain Name' }
    if (!valid_domain_name) {
        return error_message
    } else {
        return null
    }
};

let ageValidation = (control: FormControl): ValidationErrors | null  => {
    let value = control?.value;
    let date = new Date();
    let DOB = new Date(value);
    let timeDiff = Math.abs(date.getTime() - DOB.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    let error_message = { message: 'Age Must be greater than 18' }
    if(age<18){
        console.log(error_message);
        
      return error_message
    }
    else {
        return null
    }
   }

export const form_validations = {
    number,
    required,
    price,
    length_equals,
    password,
    confirm_password,
    mobile_number,
    email_address,
    domain_name,
    ageValidation,
}
