<div a-style p=3>
    <m-text [type]="gs.enums.texts.paragraph12" text="< Customer Reviews" mb=3></m-text>
    <div a-style display=flex w=100% pb=1.6>
        <div a-style brBottom="1px solid #006BFF" pb=1><m-text [type]="gs.enums.texts.paragraph34" text="All" mx=1.6 (click)="gs.router.navigate([],{queryParams : {type : 'all'}})"></m-text></div>
        <div a-style brBottom="1px solid #e4e4e4" pb=1><m-text [type]="gs.enums.texts.paragraph12" text="Replied" mx=1.6 (click)="gs.router.navigate([],{queryParams : {type : 'replied'}})"></m-text></div>
        <div a-style brBottom="1px solid #e4e4e4" pb=1 w=100%><m-text [type]="gs.enums.texts.paragraph12" text="Haven't replied" mx=1.6 (click)="gs.router.navigate([],{queryParams : {type : 'havent_replied'}})"></m-text></div>
    </div>
    <ng-container [ngTemplateOutlet]="all" *ngIf="params.type=='all'"></ng-container>
    <ng-container [ngTemplateOutlet]="replied" *ngIf="params.type=='replied'"></ng-container>
    <ng-container [ngTemplateOutlet]="havent_replied" *ngIf="params.type=='havent_replied'"></ng-container>
</div>

<ng-template #all>
    <div a-style br="1px solid #e4e4e4" p=1 mb=1.6 *ngFor="let i of [1,2,3,]">
        <div a-style display=flex bg=#ffffff >
            <m-image src="https://mesbro.in/assets/images/temp-images/my-product-organization.png" h=6 w=6 mr=1></m-image>
            <div>
                <div a-style display=flex justify="space-between" align=center>
                    <m-text [type]="gs.enums.texts.paragraph38" text="Angela Thomas" ></m-text>
                    <div a-style display=flex>
                        <m-text [type]="gs.enums.texts.paragraph11" text="5 Mins Ago"></m-text>
                        <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/like-grey.svg', text:'0'}}"></ng-container>
                        <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/mail/reply-faded.svg', text:'Reply'}}"></ng-container>
                    </div>
                </div>
                <m-image [src]="gs.enums.images.image99" w=1.2 h=1.2 mr=0.5 my=0.5 *ngFor="let i of [1,1,1,1,1]"></m-image>
                <m-text [type]="gs.enums.texts.paragraph11" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing."></m-text>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reply let-items=items>
    <div a-style ml=1 display=flex align=center>
        <m-image src={{items.icon}} w=1.3 h=1.2 mr=0.5></m-image>
        <m-text [type]="gs.enums.texts.paragraph11" text={{items.text}}></m-text>
    </div>
</ng-template>

<ng-template #replied>
    <div a-style br="1px solid #e4e4e4" p=1 mb=1.6 *ngFor="let i of [1,2,3,]">
        <div a-style display=flex bg=#ffffff >
            <m-image src="https://mesbro.in/assets/images/temp-images/my-product-organization.png" h=6 w=6 mr=1></m-image>
            <div>
                <div a-style display=flex justify="space-between" align=center>
                    <m-text [type]="gs.enums.texts.paragraph38" text="Angela Thomas" ></m-text>
                    <div a-style display=flex>
                        <m-text [type]="gs.enums.texts.paragraph11" text="5 Mins Ago"></m-text>
                        <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/like-grey.svg', text:'0'}}"></ng-container>
                        <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/mail/reply-faded.svg', text:'Reply'}}"></ng-container>
                    </div>
                </div>
                <m-image [src]="gs.enums.images.image99" w=1.2 h=1.2 mr=0.5 my=0.5 *ngFor="let i of [1,1,1,1,1]"></m-image>
                <m-text [type]="gs.enums.texts.paragraph11" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing."></m-text>
            </div>
        </div>
        <div a-style ml=7 mt=1.6 >
            <div a-style display=flex justify="space-between" align=center>
                <m-text [type]="gs.enums.texts.paragraph23" text="Your reply"></m-text>
                <div a-style display=flex>
                    <m-text [type]="gs.enums.texts.paragraph11" text="5 Mins Ago"></m-text>
                    <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/like-grey.svg', text:'0'}}"></ng-container>
                    <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/mail/reply-faded.svg', text:'Reply'}}"></ng-container>
                    <ng-container [ngTemplateOutlet]="reply" [ngTemplateOutletContext]="{items:{icon:'https://mesbro.in/assets/images/mail/delete-faded.svg', text:'Delete'}}"></ng-container>
                </div>
            </div>
            <m-text [type]="gs.enums.texts.paragraph11" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."></m-text>
        </div>
</div>
</ng-template>


<ng-template #havent_replied>
    <div a-style display=flex justify=center align=center flexDirection=column h="calc(100vh - 320px)">
        <m-image src="https://static.vecteezy.com/system/resources/thumbnails/002/425/432/small/customer-experience-review-icon-vector.jpg" h=16 w=16></m-image>
        <m-text [type]="gs.enums.texts.paragraph12" text="You haven't replied to ant one"></m-text>
    </div>
</ng-template>



