import { Component, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import EventEmitter from 'events';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-shortlink-form',
  templateUrl: './shortlink-form.component.html',
  styles: [
  ]
})
export class ShortlinkFormComponent implements OnInit {

  @Input() modal: any
  verify = false;
  notVerify =  false;
  user: any;
   @Output() cancel = new EventEmitter();

  application = 'generic';
  component = 'shortlink';

  records:any;
  

  form_data = {
    shortlink: this.fb.control('',[Validators.required],),
    title: '',
    groupControl:'',
    image: [null],

  }

  queryParams: any = {
    _id: ''
  }

  form: FormGroup | undefined;

  constructor(public renderer: Renderer2, public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))

  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'shortlink', params._id);
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: undefined,
      token: undefined,
      application: this.application,
      component: this.component,
      shortlink: this.fb.control('',[Validators.required],),
      title: '',
      groupControl:'',
      image: [null],
    })
  }

 

  async submit() {
    let body = this.form?.value;
    console.log(body);
    if (this.form?.value._id) {
      let response = await this.gs.hps.put('generic', 'shortlink', undefined, body);
    } else {
      let response = await this.gs.hps.post('generic', 'shortlink', 'add', body);
      this.gs.toast('shortlink', 'Added Successfully', 'success')
      this.gs.router.navigate([ '/dashboard/shortlink/list' ] );
    }
  }

}
