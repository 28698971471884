<div a-style p="1" display="flex" gap="2" align="center" mb="">
    <div>
        <m-image a-style [src]="gs?.user?.users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+gs.user?.users?.activeUser?.personal_details?.profile_picture) : gs.enums.images.image293" w="5.2" h="auto" brRadius="50%"></m-image>
    </div>
    <m-input [(ngModel)]="post_comment" (postfix_clicked)="add_comment(post_id)" a-style [type]="gs.enums.inputs.text" mb="0" brRadius="2.5" placeHolder="Write Comment..."  minHeight="4.6" [uppercases]="false" [capitalize]=false></m-input>
</div>
<div a-style display="flex" align="center" justify="space-between" w="45.3" mt="1"  ml="9" pb="1.6" pr="1.6">
    <div a-style display="flex" align="center" gap="1.6">
        <img src="../assets/images/Icon_Bank/Photo_gallery_Coloured.svg" a-style h="2" w="2" >
        <img src="../assets/images/Icon_Bank/GIF_Coloured.svg" a-style h="2" w="2" >
        <img src="../assets/images/Icon_Bank/Sticker_Coloured.svg" a-style h="2" w="2">
    </div>
    <div a-style display="flex" align="center" gap="1">
       <img src="../assets/images/Icon_Bank/Send_Coloured.svg" alt="" a-style h="2" w="2">
       <p a-style color="#333333" fs="1.3" (click)="add_comment(post_id)">Send</p>
    </div>
</div>

<div a-style display="flex" gap="1" p="1" *ngFor="let comment of comment_list.list">
    <div>
        <m-image a-style display="block" [src]="comment?.meta?.activity.updated_by.profile_picture? ('https://new-api.mesbro.com/uploads/'+comment?.meta?.activity.updated_by.profile_picture) : gs.enums.images.image293" w="5.2" h="auto" brRadius="50%"></m-image>
    </div>
    <div a-style flexGrow=1>
        <div a-style bg="#F8F8F8" mb="1" p="1">
            <div a-style display="flex" justify="space-between">
                <div a-style display="flex" gap="2" align="center">
                    <m-text [type]="gs.enums.texts.paragraph43" text="{{comment?.meta?.activity?.updated_by?.first_name | case : 'capital' }} {{comment?.meta?.activity?.updated_by?.last_name | case : 'capital' }}"></m-text>
                    <m-text [type]="gs.enums.texts.paragraph46" text=" {{comment?.meta?.activity?.created_at | date : 'dd/MM/yyyy hh:mm a'}}"></m-text>
                </div>
                <m-image *ngIf="false" a-style w="2.4" src="../assets/images/assets/social-more .svg" (click)="profile_model.openModal()"></m-image>
            </div>
            <div>
                <m-text a-style fs="1.2" mt="0.5" [type]="gs.enums.texts.paragraph1" text="{{comment.data.text}}"></m-text>
            </div>
        </div>
        <div *ngIf="false" a-style display="flex" gap="2" align="center">
            <m-image src="../assets/images/assets/social_like.svg"></m-image>
            <m-text a-style [type]="gs.enums.texts.paragraph1" mt="0" text="10 Likes"></m-text>
            <m-text a-style [type]="gs.enums.texts.paragraph1" mt="0" text="Reply"></m-text>
        </div>
    </div>
</div>


<m-modal #profile_model>
    <m-profile-model></m-profile-model>
</m-modal>