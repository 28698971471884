<div a-style h="100vh" (window:resize)="onResize($event,sidebar)">
    <m-header ></m-header>
    <div a-style display="flex" w="100%" h="calc(100vh - 75px)"  >
        <div>
            <m-side-bar #sidebar [width]=width [minWidth]="minWidth" ></m-side-bar>
        </div>
        <div [style.width]="toggle?'calc(100% - 28rem)':'calc(100% - 0rem)'"  >
            <div  a-style h="calc(100vh - 150px)" w='100%' bg="#F6F8FB"  overflowY=auto position="relative"   > 
                <div a-style mt="2.4">
                    <m-image (clicked)="toggle_sidebar(sidebar);" src="https://cdn2.iconfinder.com/data/icons/android-application-solid/32/Menu_5-512.png" a-style w=3 h=3 position="absolute" top="2.5" left="2" title="Toggle"></m-image>
                </div>
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
            <m-footer a-style h="7.5"></m-footer>
        </div>
    </div>
</div>
