import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import { IUser, IUserData } from '../../interfaces/i-user';

@Component({
  selector: 'm-my-profile-form',
  templateUrl: './my-profile-form.component.html',
  styles: [
  ]
})
export class MyProfileFormComponent implements OnInit {

  form: FormGroup | undefined;
  user: IUser | undefined;

  constructor(public gs: GlobalService, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.initialize_form();
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.user = users.activeUser;
      console.log(this.user);
      if (this.user) {
        this.form?.patchValue(this.user);
      }
      if (this.user?.personal_details.gender == 'Male') {
        (this.form?.controls.personal_details as FormGroup).controls.appellation.patchValue('Mr.')
      } else if (this.user?.personal_details.gender == 'Female') {
        (this.form?.controls.personal_details as FormGroup).controls.appellation.patchValue('Miss')
      }
    })
  }

  locationList = [
    { value: 'Type to Search' },

  ]
  security = [
    { label: 'Yes', value : true },
    { label: 'No', value : false }
  ]
  authentication = [
    { label: 'Yes', value : true },
    { label: 'No', value : false }
  ]
  drivingLicence = [
    { label: 'Yes', value : true },
    { label: 'No', value : false }
  ]
  passport =[
    { label: 'Yes', value : true },
    { label: 'No', value : false }
  ]

  seniorCitizenCard = [
    { label: 'Yes', value : true },
    { label: 'No', value : false }
  ]
  disability = [
    { label:'Yes', value : true},
    { label:'No', value : false}
  ]
  zodic_sign = ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo',
     'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius',  'Pisces']

 blood_groups= ['A+','A-','B+','B-','AB+','AB-','O+','O-']
 relationship = ['Single','Live-In','Engaged']
 Marital_status = ['Married','Un-Married','Widowed','Divorced','Separated']
 education = ['Post-Graduation','Graduation','Diploma','HSC','SSC']

  initialize_form() {
    this.form = this.fb.group({
      _id: undefined,
      token: undefined,
      personal_details: this.fb.group({
        first_name: null,
        middle_name: null,
        last_name: null,
        appellation: null,
        profile_picture: null,
        cover_picture: null,
        date_of_birth: [null],
        gender: null,
        blood_group:null,
        age:null,
        zodic_sign:null,
        weight:null,
        height:null,
        birth_time:null,
        birth_place:null,
        name_of_hospital:null,
        nationality:null,
        profession:null,
        hobbies:null,
        bio:null,
      }),
      relationship_status: this.fb.group({
        relationship: null,
        marital_status: null,
      }),
      contact_details: this.fb.group({
        mobile_number_primary: null,
        mobile_number_secondary: null,
        email_address_primary: null,
        email_address_secondary: null,
        alternate_website: null,
        website: null,

      }),

        educations: this.fb.array([this.edu]),

      security_details: this.fb.group({
        domain: null,
        username: null,
        two_factor_authentication: null,
        new_device_authentication: null,
        private: null,
      }),
      Identity_details:this.fb.group({
        driving_licence:null,
        aadhar_card_no:null,
        pan_card_no:null,
        voting_card_no:null,
        Passport:null,
        senior_citizen_card:null,
        disability:null
      }),

      location_details: this.fb.group({
        ip: null,
        version: null,
        city: null,
        region: null,
        region_code: null,
        country: null,
        country_name: null,
        country_code: null,
        country_code_iso3: null,
        country_capital: null,
        country_tld: null,
        continent_code: null,
        in_eu: null,
        postal: null,
        latitude: null,
        longitude: null,
        timezone: null,
        utc_offset: null,
        country_calling_code: null,
        currency: null,
        currency_name: null,
        languages: null,
        country_area: null,
        country_population: null,
        district: null,
        asn: null,
        org: null,
        address_line_1: null,
        address_line_2: null,
        Search_Location: null,

      }),
      social_media: this.fb.group({
        facebook: null,
        whatsapp_business: null,
        instagram: null,
        twitter: null,
        google: null,
        skype:null,
        telegram:null,
        linked_in:null,
        output:null,
        youtube:null
      }),
      profilePic:this.fb.group({
        profile_picture:null,
        cover_picture:null

      })
    })
  }
  get education_group() {
    return ((this.form as FormGroup).get('educations') as FormArray).controls;
  }
  get edu() {
    return this.fb.group({
      educational_qualification: [null],
      marks: [null],
      university_name: [null],
      passout_year: null,
    })
  }
 
  add_education() {
    (<FormArray>this.form?.get('educations')).push(this.edu)
  }

  async submit() {
    try {
      if (this.form?.invalid) {
        this.form?.markAllAsTouched()
        return;
      }
      let body = { ...this.form?.value };
      // delete body.token;
      // delete body.contact_details.mobile_number_primary;
      // delete body.contact_details.email_address_primary;
      console.log(body);
      console.log(this.form?.value);
      
      
      await this.gs.hps.put('idm', 'user', '', body);
      this.gs.user.update_user(this.form?.value);
      this.gs.toast('User Modified', 'User modified successfully', 'success', 2)
    } catch (error) {
      console.log(error)
      throw error
    }

  }

  
}
