import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-content-form',
  templateUrl: './content-form.component.html',
  styles: [
  ]
})
export class ContentFormComponent implements OnInit {
  application = 'Generic';
  component = 'Content';
  form: FormGroup | undefined;
  // form_data = {
  //   brand: [null, this.gs.form_validations.required('Brand')],
  //   link: '',
  //   image: [null]
  // }
  queryParams: any = {
    _id: ''
  }
  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))
  }
  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'data', params._id); //update list
      this.form?.patchValue(details)
    }
  }
  initializeForm() {
    this.form = this.fb.group({
      front_banner_link: this.fb.group({
        banner_text: null,
        banner_link: null,
        tradeshow_text: null,
        tradeshow_link: null,
        subsideries_text: null,
        subsideries_link: null,
      }),
      join_now: this.fb.group({
        text:null,
        link:null,
      }),
      job_banner: this.fb.group({
        text:null,
        link:null,
      }),
      property_banner: this.fb.group({
        text:null,
        link:null,
      }),
      business_email_banner: this.fb.group({
        text:null,
        link:null,
      }),
      global_marketplace: this.fb.group({
        heading:null,
        sub_heading:null,
        sub_heading_two:null,
        sub_heading_three:null,
        sub_heading_four:null,
        description:null,
        link:null,
      }),
      newsletter_banner: this.fb.group({
        heading:null,
        description:null,
        link:null
      }),
    })
  }
  async submit() {
    try {
      let body = this.form?.value;
      if (this.form?.value._id) {
        let response = await this.gs.hps.put('generic', 'data', undefined, body);
        this.gs.toast('Content', 'Modified Successfully', 'success')
      } else {
        let response = await this.gs.hps.post('generic', 'data', 'add', body);
        this.gs.toast('Content', 'Added Successfully', 'success')
        // this.gs.router.navigate(['dashboard/group/brand/list']);
      }
    } catch (error) {
      console.log(error);
      
    }
  }

}
