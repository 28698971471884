import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'm-delete',
  templateUrl: './delete.component.html',
  styles: [
  ]
})
export class DeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
