<div a-style p="3.4" brRadius="0.6" br="1px solid #E4E4E4" bgUrl="../assets/images/assets/Plenux-Effects.gif"
  bgRepeat="none" bgSize="cover" display=flex align=center flexDirection=column>
  <P a-style fs="3.2" color=white>Quote now</P>
  <div a-style display=flex gap=2.7 align=center mt=1.8>
    <p a-style color="#FFFFFF" fs="1.6">Lead generated by bharat furniture Pvt. Ltd. on 22 Sept 2021, 00:00 AM</p>
  </div>
</div>

<div a-style p=2.4 br="0.5px solid #e4e4e4" brRadius="0.6" bg="#ffffff" gap="2.4" mt="2.4" display="flex">
  <div>
    <div a-style minWidth="37.8" maxWidth="37.8" h="37.8">
      <m-image src="https://new-api.mesbro.com/uploads/{{change_image ? change_image : details?.profile_picture}}"
        br="1px solid #e4e4e4" brRadius="0.6" w="100%" h="100%">
      </m-image>

    </div>
    <div a-style display="flex" h="11" mt="2.4">
      <m-image src="https://new-api.mesbro.com/uploads/{{details?.profile_picture}}"
        (click)="select_image(details?.profile_picture)" a-style w="11" h="11" brRadius=8px br="1px solid #e4e4e4"
        shadow="hov:0 3px 6px 0 #f1f1f1" bgSize="cover" bgRepeat="no-repeat">
      </m-image>
      <m-image src='https://new-api.mesbro.com/uploads/{{item}}' brRadius="0.6"
        *ngFor="let item of details?.gallery; let i=index" (click)="select_image(item)" a-style w="11" h="11"
        brRadius=8px br="1px solid #e4e4e4" shadow="hov:0 3px 6px 0 #f1f1f1" bgSize="cover" bgRepeat="no-repeat">
      </m-image>
    </div>
  </div>
  <div>
    <div a-style w=100% class="ellipsis" h=6.8>
      <!-- <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=2 fw=600 text="{{details?.title}}">
      </m-text> -->
      <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=2 fw=600 text="Supreme Fusion 02 Multi Purpose for Home Medium Size Plastic Cupboard (Finish Color - Dark Beige & Globus Brown)">
      </m-text>
    </div>
    <div a-style display="flex" align="end" gap=0.4 mt="1">
      <m-text [type]="gs.enums.texts.paragraph70" a-style text="Price : "></m-text>
      <m-text [type]="gs.enums.texts.paragraph132" textCenter="center"
        text=" INR  {{details?.attributes?.basic_details?.price_from?.value  ? details?.attributes?.basic_details?.price_from?.value  : 0}} - {{details?.attributes?.basic_details?.price_to?.value ? details?.attributes?.basic_details?.price_to?.value : 0}}"
        color="#c87065;hov:#C8A165"></m-text>
      <m-text [type]="gs.enums.texts.paragraph133" a-style text="Negotiable"></m-text>
    </div>
    <div a-style gap="2.4" display="flex" mt="9.7">
      <div a-style br="1px solid #E4E4E4" minWidth="52" maxWidth="52" brRadius="0.6" p="2.4" h="30.6">
        <div a-style pb="1.6" brBottom="0.5px solid #E4E4E4">
          <m-text [type]="gs.enums.texts.paragraph40" a-style text="Seller's Details"></m-text>
        </div>
        <div a-style>
          <m-text [type]="gs.enums.texts.paragraph140" a-style text="BHARAT FURNITURE PVT LTD" mt="1.6"></m-text>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/location (2).svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  Shahapur, Thane, Maharashtra, India."></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/mobile.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  +91 7057218152, +91 9876543211."></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/mail (2).svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/website.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/gstin.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
        </div>
      </div>
      <div a-style br="1px solid #E4E4E4" minWidth="52" maxWidth="52" h="30.6" brRadius="0.6" p="2.4">
        <div a-style pb="1.6" brBottom="0.5px solid #E4E4E4">
          <m-text [type]="gs.enums.texts.paragraph40" a-style text="Buyer's Details"></m-text>
        </div>
        <div a-style>
          <m-text [type]="gs.enums.texts.paragraph140" a-style text="BHARAT FURNITURE PVT LTD" mt="1.6"></m-text>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/location (2).svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  Shahapur, Thane, Maharashtra, India."></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/mobile.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  +91 7057218152, +91 9876543211."></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/mail (2).svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/website.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
          <div a-style display="flex" mt="1.6">
            <m-image a-style w=2 h=2 src="../assets/images/assets/product/gstin.svg" mr="1.6"> </m-image>
            <m-text [type]="gs.enums.texts.paragraph141" a-style text="  vbs209ulb@gmail.com"></m-text>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- <m-rfq-form *ngIf="details" [details]="details" mb=0 gap="1.83" mr=2.4 px=2.4 py="2.4" title="Buyer's Requirements" bg="#ffffff">
  </m-rfq-form> -->

  <m-organization-conversation a-style w="31"></m-organization-conversation>

</div>


  <div a-style p="2.4" br="1px solid #e4e4e4" mt=1.3 brRadius="0.6">
      <div a-style mt="2.4">
          <form *ngIf="form" [formGroup]="form">
              <m-form formGroupName="basic_details" title="Product Basic Information" fs="1.6" labelWeight="500"
                  title_mb="1.7" br="1px solid rgba(228, 228, 228, 0.47)">
                  <div a-style display=flex w=100% brTop="1px solid #e4e4e4">
                      <div a-style display=flex w=60% flexWrap=wrap columns="3">
                          <m-input [columns]="1/3" px=1 formControlName="sourcing_type" label="Sourcing Type "
                              placeHolder="Select Sourcing Type" bindValue="label" bindLabel="label"
                              bindImage="image" [list]="gs.dropdown.sourcingType"
                              [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content">
                          </m-input>
                          <m-input [columns]="1/3" px=1 formControlName="sourcing_purpose"
                              [list]="gs.dropdown.sourcingPurpose" label="Sourcing Purpose" bindValue="label"
                              bindLabel="label" placeHolder="Select Sourcing Purpose"
                              [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content"></m-input>
                          <m-input [columns]="1/3" px=1 formControlName="trade_terms"
                              [list]="gs.dropdown.tradeTerms" label="Trade Terms  " bindLabel='label'
                              placeHolder="FOB" bindValue="label" [type]="gs.enums.inputs.dropdown"
                              dropdownHeight="fit-content"></m-input>
                          <m-input [columns]="1/3" px=1 formControlName="quantity" label="Quantity " type="number"
                              min=0 placeHolder="Quantity"></m-input>
                          <m-input [columns]="1/3" px=1 formControlName="unit" [list]="gs.dropdown.units"
                              label="Unit " placeHolder="Unit" bindLabel="label" bindValue="label"
                              [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content">
                          </m-input>
                          <m-input [columns]="1/3" px=1 formControlName="location" label="Location"
                              placeHolder="Type to Search..." [type]="gs.enums.inputs.dropdown"
                              dropdownHeight="fit-content">
                          </m-input>
                          <m-input [columns]="1/3" px=1 formControlName="price_from" placeHolder="Price Form"
                              type="number" label="Price from" min="0">
                          </m-input>
                          <m-input [columns]="1/3" px=1 formControlName="price_to" type="number"
                              placeHolder="Price to" label="Price to" min="0">
                          </m-input>
                          <m-input [columns]="1/3" px=1 formControlName="currency" label="Currency"
                              [type]="gs.enums.inputs.dropdown" dropdownHeight="fit-content" bindLabel='label'
                              bindValue="label" placeHolder="Currency" [list]="gs.dropdown.CurrencyCode">
                          </m-input>
                      </div>
                      <div a-style display=flex w=40%>
                          <m-input (ngModelChange)="calculate_percentage()" formControlName="description"
                              [columns]="1" px=1 label="Description " rows=8 [type]="gs.enums.inputs.text_area">
                          </m-input>
                      </div>
                  </div>
              </m-form>

              <m-input formControlName="buying_req" type="checkbox"
                  label="I agree to Terms of Service and send this RFQ to the all suppliers who can fulfill the requirement."
                  onlyInput="true"></m-input>
              <div a-style w="100%" align="center">
                  <m-button [types]="gs.enums.buttons.button87" gap=0 text="Enquire Now" br="none" a-style
                      (click)="submit()"></m-button>
              </div>
          </form>
      </div>
  </div>


<div></div>
<!-- <m-add-rfq type="add_rfqNew"></m-add-rfq> -->
<!-- <m-rfq-form>sdfgbn</m-rfq-form> -->
<div>

  <m-generic-location mb=0 gap="1.83" mr=2.4 px=2.4 py="2.4" title="Supplier Location" bg="#ffffff">
    <!-- {{details | json}} -->
  </m-generic-location>
  <!-- <m-mail-home [details]="details" (myoutput)="make($event,details)"></m-mail-home> -->
  <!-- <div a-style w=100vw h=20 bg=yellow>
    <m-mail-home></m-mail-home>
  </div> -->
  <!-- type="message" -->
  <!-- <m-review class="printHidden" (activity)="gs.action($event,details)" *ngIf="details" type="Product" [activities]="activities" [details]="details"></m-review> -->
  <!-- <m-review class="printHidden" (activity)="gs.action($event,details)" *ngIf="details" type="Product" [activities]="activities" [details]="details"></m-review> -->
</div>

<m-banner image="https://mesbro.in/assets/images/temp-images/landingimg-6.png" title="Newsletter" description="Often used a lead magnets to capture subscribers, training material makes a business newsletter something your customers look forward to receiving."
    [emailCollection]="true" height="23.3"></m-banner>


    