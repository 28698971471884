import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-feedback-list',
  templateUrl: './generic-feedback-list.component.html',
  styles: [
  ]
})
export class GenericFeedbackListComponent implements OnInit {
  list = {
    list: [],
    count: 0
  }
  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    this.get_list()
  }
  async get_list() {
    // console.log('hello');
    let response = await this.gs.get_data_list('Generic', 'Feedback', []);
    this.list = response;
    // console.log(this.list);
    
  }
  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }
}
