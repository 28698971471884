import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-add-domain',
  templateUrl: './add-domain.component.html'
})
export class AddDomainComponent implements OnInit {
  // @Output() close_modal = new EventEmitter();
  @Input() disable: boolean = false;
  @Input() modal: any
  verify = false;
  notVerify = false;
  user: any;
  @Output() cancel = new EventEmitter();

  application = 'Generic';
  component = 'Domain';

  records: any;
  ;
  dns_records = {
    mx: {
      'mail.theContrast.com': true,
    },
    txt: {
      'abhijeet': true
    }
  };

  form_data = {
    domain_name: this.fb.control('', [Validators.required],),
    select_registar: '',
    upload_pic: '',
    add_txt: '',
    add_mx: '',
  }

  queryParams: any = {
    _id: ''
  }


  form: FormGroup | undefined;


  constructor(public renderer: Renderer2, public gs: GlobalService, public ar: ActivatedRoute, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))

    setInterval(async () => {
      let body = {
        domain: 'abhijeet.com',
      }
      this.records = await this.gs.hps.post('idm', 'domain', 'dns-record', body);
      console.log(body, 'set interval is called.');
    }, 10000);
  }

  handleParams = async (params: Params) => {
    this.queryParams = params
    this.initializeForm();
    if (params._id) {
      let details = await this.gs.hps.get('generic', 'data', params._id);
      this.form?.patchValue(details)
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      _id: undefined,
      token: undefined,
      application: this.application,
      component: this.component,
      data: this.fb.group(this.form_data)
    })
  }

  // async dns_record(){
  //   let body = this.domain;
  //   let response = await this.gs.hps.post('Idm', this.component, body,[])
  //   console.log(response);

  // }



  async submit() {
    let body = this.form?.value;
    console.log(body);

    if (this.form?.value._id) {
      let response = await this.gs.hps.put('generic', 'data', undefined, body);
    } else {
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
    }
  }

  closeModel() {
    this.verify = false;
    console.log('close model');
  }
}
