<div class="no_scroll" a-style  *ngIf="true">
  <div a-style display="flex" flexDirection="column" gap="2.4">
     <div a-style display="flex" gap="1.6" >
        <img [src]="user.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+user.personal_details?.profile_picture) : gs.enums.images.image293" a-style h="5.3" w="5.3" brRadius="50%" >
        <div a-style display="flex" gap="1" w="47.9" align="center" br="1px solid #e4e4e4" brRadius="2.7" h="5.3">
           <input type="text" placeHolder="Write here..." pl="3.1"   a-style h="4.5" align="center" w="44" mt="0.2"  br="none"  brRadius="2.7" >
           <img src="../assets/images/Icon_Bank/Add_Post_Coloured.svg" (click)="click_function()" a-style cursor="pointer" h="1.9" mr="1.5" w="1.9" >
        </div>
     </div>
  </div>
  <div a-style mb="2" *ngFor="let item of list.list; let i=index" mt="2.4">
     <div a-style bg="ffffff" w="54.8"  br="1px solid #e4e4e4" brRadius="0.6"> 
        <div a-style p="1.6" >
           <div a-style display="flex" align="center" justify="space-between">
              <div a-style display="flex" align="center" gap="1.6">
                 <m-image (click)="gs.router.navigate([],{queryParams:{type:'profile',id:item?.meta?.activity?.updated_by?._id}})" a-style display="block" [src]="item?.meta?.activity?.updated_by?.profile_picture? ('https://new-api.mesbro.com/uploads/'+item?.meta?.activity?.updated_by?.profile_picture) : gs.enums.images.image293" w="5.3" h="5.3" brRadius="50%"></m-image>
                 <div>
                    <div a-style display=flex gap=1 alignItems=center>
                       <m-text (click)="gs.router.navigate([],{queryParams:{type:'profile',id:item?.meta?.activity?.updated_by?._id}})" a-style cursor=pointer [type]="gs.enums.texts.paragraph43" text="{{item?.meta?.activity?.updated_by?.first_name}} {{item?.meta?.activity?.updated_by?.last_name}}"></m-text>
                       <!-- <div  a-style display="flex" gap="1">
                          <img [src]="item?.data?.feeling_activity?.image" a-style h=2 w=2 shadow="0 3px 6px 0 #f1f1f1" cursor="pointer">
                          <m-text [type]="gs.enums.texts.paragraph43" px=0 [text]="item?.data?.feeling_activity?.label"></m-text>
                          </div> -->
                    </div>
                    <!-- <m-text [type]="gs.enums.texts.paragraph46" text="{{item?.meta?.activity?.created_at | date : 'dd/MM/yyyy hh:mm a'}}"></m-text> -->
                    <p a-style mt="0.4" p="0" align="left" fs="1.3" color="#707070">{{item?.meta?.activity?.created_at | date : 'dd/MM/yyyy hh:mm a'}}</p>
                 </div>
              </div>
              <div class="vertical" a-style display="flex" gap="1">
                 <img src="../assets/images/Icon_Bank/Horizontal_Menu_Grey.svg" alt="" a-style h=".5" w="2.1" (click)="contextToggle(item?._id)" id="contextMenu" position="relative">
                 <div *ngIf="socialContext" a-style br="1px solid #e4e4e4" h="auto" w="20"  brRadius=".6" bg="#FFFFFF"  display="flex"  flexDirection="column" position="absolute" right="28.4" right="51.1" pointer-events="auto">
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1" >
                       <img src="../assets/images/Icon_Bank/Edit_Post_Coloured.png" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Edit post</p>
                    </div>
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1">
                       <img src="../assets/images/Icon_Bank/Tag_Post_Coloured.svg" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Tag Post</p>
                    </div>
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1">
                       <img src="../assets/images/Icon_Bank/Pin_Coloured.png" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Pin Post</p>
                    </div>
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1">
                       <img src="../assets/images/Icon_Bank/Copy_Link_Coloured.svg" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Copy Link</p>
                    </div>
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1">
                       <img src="../assets/images/Icon_Bank/Turn_Off_Notification_Coloured.svg" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Turn Off Notification</p>
                    </div>
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1">
                       <img src="../assets/images/Icon_Bank/Change_Privacy_Coloured.svg" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3">Change Privacy</p>
                    </div>
                    <!-- {{item._id | json}} -->
                    <div a-style display="flex" gap="1.6" h="4" pl="1.6" py="1" (click)="delete(item._id,i)" *ngIf=" item.meta?.activity?.updated_by?._id == gs.user?.users?.activeUser?._id">
                       <img src="../assets/images/Icon_Bank/Delete_Coloured.svg" alt="" a-style h="2" w="2">
                       <p a-style color="#333333" fs="1.3"  >Delete Post</p>
                    </div>
                 </div>
                 <!-- <m-image (click)="delete(item._id,i)" *ngIf=" item.meta?.activity?.updated_by?._id == gs.user?.users?.activeUser?._id"  src="https://mesbro.in/assets/images/delete-red.svg" a-style h="1.5" w="1.5"></m-image>  -->
                 <!-- <m-image a-style w="2.4" src="../assets/images/assets/social-more .svg" (click)="profile_model.openModal()"></m-image> -->
              </div>
           </div>
           <div a-style>
              <m-text a-style fs="1.2" my="1" [type]="gs.enums.texts.paragraph1" [innerHTML]="item?.data?.description" [text]="item?.data?.description"></m-text>
           </div>
           <m-image  *ngIf="item?.data?.image" type="multiple" a-style align="center" h="27.1" w="51.6" brRadius="0.6" src='https://new-api.mesbro.com/uploads/{{item?.data?.image}}'></m-image>
           <div *ngIf="item.data.social_poll" a-style display=flex flexDirection=column gap=1>
              <ng-container *ngFor="let poll of item.data.social_poll.option ">
                 <div a-style py="0.4" bg=lightgray>
                    <m-input [type]="gs.enums.inputs.radioButton" mt=0 [label]="poll" [onlyInput]=true radioName="poll"></m-input>
                 </div>
              </ng-container>
           </div>
        </div>
        <div a-style p="1" display="flex" justify="space-between" *ngIf="false">
           <div a-style display="flex" gap="1">
              <m-image src="../assets/images/assets/red_heart.svg"></m-image>
              <m-text [type]="gs.enums.texts.paragraph43" text="{{item?.meta?.activity?.count?.like || 0}}"></m-text>
           </div>
           <p a-style fs=1.4 fw=500 cursor=pointer>{{5}} comments </p>
        </div>
        <div a-style display="flex" justify="space-around" align="center"  pb="1.6" px="1.6">
           <div a-style display="flex" align="center" gap="1" (click)="like(item,i)">
              <!-- <m-image src=".../assets/images/Icon_Bank/Like_Grey.svg" [current_value]="is_liked || item.meta.activity.like?item?.meta?.activity?.like[user._id]:false" count_key="like"></m-image> -->
              <img src="../assets/images/Icon_Bank/Like_Grey.svg" alt="" a-style h="1.5" w="1.5" [current_value]="is_liked || item.meta.activity.like?item?.meta?.activity?.like[user._id]:false" count_key="like">
              <!-- <m-text [type]="gs.enums.texts.paragraph43"  text="{{item?.meta?.activity?.count?.like || 0}} Like"></m-text> -->
              <p a-style fs="1.3" color="#333333" >{{item?.meta?.activity?.count?.like || 0}} Like</p>
              <!-- <m-text [type]="gs.enums.texts.paragraph75" text={{activities?.count?.like}}></m-text> -->
           </div>
           <div a-style display="flex" gap="1" align="center">
              <img src="../assets/images/Icon_Bank/Repost_Coloured.svg" alt="" a-style h="1.5" w="1.5" >
              <p a-style color="#333333" fs="1.3">Repost</p>
           </div>
           <div a-style display="flex" gap="2" (click)="is_comment[i] = true;comment = true">
              <img src="../assets/images/Icon_Bank/Comment_Coloured.svg" alt="" a-style h="1.5" w="1.5" >
              <!-- <m-text [type]="gs.enums.texts.paragraph43" text="Comment"></m-text> -->
              <p a-style color="#333333" fs="1.3">Comment</p>
           </div>
           <!-- <div a-style display="flex" gap="2" (click)="share_modal.openModal()">
              <m-image src="../assets/images/assets/sharegrey.svg" hover="../assets/images/assets/sharecolour.svg" ></m-image>
              <m-text [type]="gs.enums.texts.paragraph43" text="Share"></m-text>
              </div> -->
           <!-- <div a-style display="flex" gap="2" *ngIf="true">
              <m-image src="../assets/images/assets/save normal.svg" hover="../assets/images/assets/save hover.svg" (click)="gs.action('save',item)"></m-image> 
              
              <m-text [type]="gs.enums.texts.paragraph43" text="Save"></m-text>
              </div> -->
        </div>
        <m-comment *ngIf="is_comment[i]" [post_id]='item._id'></m-comment>
        <!-- <div *ngIf="comment">
           <div a-style display="flex" gap="1.6" px="1.7">
             <img  [src]="users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.profile_picture) : gs.enums.images.image293" a-style h="5.3" w="5.3" brRadius="2.7">
             <div a-style w="45.3"  br="1px solid #e4e4e4" brRadius="0.6" h="4.6">
                <input type="text" placeHolder="Write here..." pl="3.1"  a-style h="4.2" w="40"  br="none" brRadius="2.7" >
             </div>
           </div>
           <div a-style display="flex" justify="space-between" w="45.3" mt="1.6"  ml="9" pb="1.6" pr="1.6">
           <div a-style display="flex" gap="1.6">
               <img src="../assets/images/Icon_Bank/Photo_gallery_Coloured.svg" a-style h="2" w="2" >
               <img src="../assets/images/Icon_Bank/Gif_Coloured.svg" a-style h="2" w="2" >
               <img src="../assets/images/Icon_Bank/Sticker_Coloured.svg" a-style h="2" w="2">
           </div>
           <div a-style display="flex" align="center" gap="1">
              <img src="../assets/images/Icon_Bank/Send_Coloured.svg" alt="" a-style h="2" w="2">
              <p a-style color="#333333" fs="1.3">Send</p>
           </div>
           </div>
           </div> -->
     </div>
  </div>
</div>
<m-modal #profile_model>
  <m-profile-model></m-profile-model>
</m-modal>
<m-modal #share_modal>
  <m-share [title]="details?.title" [url]="'https://new.mesbro.com/generic/detail?_id='+ details?._id"></m-share>
</m-modal>
<m-modal #post_model width=56.4>
  <m-create-post (cancel) ="post_model.closeModal()"  ></m-create-post>
</m-modal>
<!-- <ng-template #bookmark let-data=data>
  <div a-style display="flex" align="center">
      <m-image [src]="data.src" w="1.7" mr="0.8" title="Save Product" [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src" [current_value]="activities?.my_activity?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities.my_activity.save?'unsave':data.type || data.label);activities.my_activity.save =!activities.my_activity.save"></m-image>
  </div>
  </ng-template> -->
<!-- <ng-template #bookmarks let-data=data>
  <div a-style display="flex" align="center">
      <m-image [src]="data.src" w="1.5" mr="0.8"  [hover]="isVisible_bookmark || activities?.save ?data.hover_src:data.src" [current_value]="activities?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activities.save?gs.action('unsave',detail):gs.action('save',detail)"></m-image>
  </div>
  </ng-template> -->