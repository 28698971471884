import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-referance-inputs',
  templateUrl: './referance-inputs.component.html',
  styles: [
  ]
})
export class ReferanceInputsComponent implements OnInit {

  constructor(public gs:GlobalService) { }

  ngOnInit(): void {
  }

}
