<div a-style br="1px solid #e4e4e4" p="2">
    <div a-style brRadius="0.4" br="1px solid #e4e4e4">
        <img *ngIf="!file" width="100%" height="auto" id="myimage" src="https://new-api.mesbro.com/uploads/99/99/99/99/99/94/83.jpg"  alt="" a-style >
   <div *ngIf="file" a-style  fs=2 fw=400 display=flex justify=center align=center  >
       <div a-style h=10  bg=lightgary >
        {{file.original_name}}
       </div>
   </div>
    </div>
    <div a-style display="flex" brRadius="0.4" br="1px solid #e4e4e4" mt=3 justify="space-between" p="3">
        <div  a-style  overflow=hidden (click)="file_input.click()" >
            <m-button   [types]="gs.enums.buttons.button30" a-style fs='1.4' text="Upload File" ></m-button>
            <input #file_input  type="file" a-style display=none cursor=pointer  [(ngModel)]="file" (change)='upload_event($event)' />
        </div>
        <m-button  (click)="upload_file()"  [types]="gs.enums.buttons.button30" a-style fs='1.4' text="Submit" ></m-button>
    </div>
</div>


