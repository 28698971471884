<!-- <div a-style p=2>
    <div a-style id="printSection">
        <ng-container>
            <table a-style br="1px solid black" borderCollapse=collapse w="100%">
                <tr>
                    <td a-style p="0" br="1px solid black" colspan="11">
                        <m-text [type]="gs.enums.texts.heading2" text="TAX INVOICE" align=center></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="Form GST INV - 1  |  Under Rule 7 referred to Section-31 of the CGST ACT, 2017" align=center></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style br="1px solid black" colspan="11" align="center">
                        <m-text [type]="gs.enums.texts.heading2" text="Mesbro Technologies Pvt Ltd" align="center"></m-text>
                        <div a-style>
                            <m-text [type]="gs.enums.texts.paragraph63" text="CIN : U21099MH2017PTC302804     |     Email : gorakh.avhad@mesbro.com | Udyam Reg. No : UDYAM-MH-33-0037523 " align=center></m-text>
                            <m-text [type]="gs.enums.texts.paragraph63" text="Reg. Office : 6B, 6th Floor, Bay House Edenwoods CHS,  Thane (W), Pin : 400 610, Maharashtra, India" align=center></m-text>
                            <m-text [type]="gs.enums.texts.paragraph63" text="Address : 990, Beside Pundalik Mhatre School, Kalyan (East), Pin : 421 306, Maharashtra, India" align=center></m-text>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td a-style pl="0.4" br="1px solid black" colspan="4">
                        <m-text [type]="gs.enums.texts.paragraph63" text="Invoice Number : PP/2021-22/3"></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" colspan="7">
                        <m-text [type]="gs.enums.texts.paragraph63" text="GSTIN : 27AAJCP5741M1ZV"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="Mode of Payment : Online Banking"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style pl="0.4" br="1px solid black" colspan="4">
                        <m-text [type]="gs.enums.texts.paragraph81" text="Details of Receiver "></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" [text]='"Name : "+details?.group?.basic_details?.legal_name'></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" [text]='"Address : " + details?.group?.location?.primary_address?.room + " , " +details?.group?.location?.primary_address?.floor +" , " + details?.group?.location?.primary_address?.premise + " , " +details?.group?.location?.primary_address?.road + " , " + details?.group?.location?.primary_address?.landmark + " , " + details?.group?.location?.primary_address?.locality + " , " +details?.group?.location?.primary_address?.city + " , " +details?.group?.location?.primary_address?.district + " , " +details?.group?.location?.primary_address?.region + " , " +details?.group?.location?.primary_address?.postal + " , " +details?.group?.location?.primary_address?.country'>
                        </m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" colspan="7" verticalAlign="initial">
                        <m-text [type]="gs.enums.texts.paragraph63" [text]='"State : " + details?.group?.location?.primary_address?.country'></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="State Code : 27"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" [text]='"GSTIN :  " + details?.group?.basic_details?.legal_name'></m-text>

                    </td>
                </tr>
                <tr>
                    <td a-style pl="0.4" br="1px solid black" rowspan="2">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="Sr. No."></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" rowspan="2" w="35% ">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="Description of Goods"></m-text>
                    </td>
                    <td a-style py="0.4" px="1" br="1px solid black" rowspan="2">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="Qty"></m-text>
                    </td>

                    <td a-style pl="0.4" br="1px solid black" rowspan="2" w="10%">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="Rate"></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" rowspan="2" w="10%">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="Taxable value"></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" colspan="2">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="CGST"></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" colspan="2">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="SGST"></m-text>
                    </td>
                    <td a-style pl="0.4" br="1px solid black" colspan="2">
                        <m-text [type]="gs.enums.texts.paragraph81" align="center" text="IGST"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Rate"></m-text>
                    </td>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Amt"></m-text>
                    </td>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Rate"></m-text>
                    </td>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Amt"></m-text>
                    </td>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Rate"></m-text>
                    </td>
                    <td a-style br="1px solid black" w="6%">
                        <m-text [type]="gs.enums.texts.paragraph81" align=center text="Amt"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="1" pl="0.4"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=" {{details?.package.data?.title | titlecase}} "></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="1"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=" {{((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))}} "></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.18"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="9%"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="9%"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="0%"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="0"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="Total" p="0.4"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=""></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="1"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=""></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.18"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=""></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=""></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09"></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text=""></m-text>
                    </td>
                    <td a-style br="1px solid black">
                        <m-text [type]="gs.enums.texts.paragraph63" align="center" text="0"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0.4" brTop="1px solid black" brRight="1px solid black" verticalAlign=initial colspan="3">
                        <m-text [type]="gs.enums.texts.paragraph63" text="Bank Details :"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="Name : Mesbro PVT. LTD."></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="A/C No : 7899887787" pl="0.4"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" text="IFSC Code:  KKBxxxxx" pl="0.4"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63 " text="Account Type: Current  " pl="0.4"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63 " text="Bank : SBI Bank" pl="0.4"></m-text>
                    </td>
                    <td a-style pl="0.4" brTop="1px solid black" brRight="1px solid black" colspan="8">
                        <m-text [type]="gs.enums.texts.paragraph81" text="DECLARATION :" pl="0.4"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" pl="0.4" text=" Certified that the particulars given above are true & correct and the amount indicated represents the
                    actually charged and that there is no flow of additional consideration directly from the buyer. We
                    hereby certify that our registration certificate under GST Act''2017 is enforced on the date on which
                    the supply of goods/services specified in this Invoice is made by us and that the supply of
                    goods/services covered by this invoice has effected by us and it shall be accounted for in the turnover
                    of goods/services."></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black " colspan="3">
                        <m-text [type]="gs.enums.texts.paragraph63 " text="Invoice Total :" pl="0.4"></m-text>
                    </td>
                    <td a-style p="0" br="1px solid black " colspan="8">
                        <m-text [type]="gs.enums.texts.paragraph63 " text="{{((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*1.18}}" pl="0.4"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black " colspan="3">
                        <m-text [type]="gs.enums.texts.paragraph63" text="Invoice Total (In Words) : " pl="0.4"></m-text>
                    </td>
                    <td a-style p="0" br="1px solid black " colspan="8">
                        <m-text [type]="gs.enums.texts.paragraph63 " text="{{((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*1.18 | amountInWords : 'INR' : 'RUPEES' | uppercase}} ONLY" pl="0.4"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style pl="0" br="1px solid black " colspan="11 " align="center">
                        <m-text [type]="gs.enums.texts.paragraph63" text="Amount of tax is not subject to reverse charge." pl="0.4"></m-text>
                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black " colspan="11">
                        <m-text [type]="gs.enums.texts.paragraph81" text="TERMS :" pl="0.4"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" pl="0.4" text="1) Payment must be made by cross order Payee's A/c Cheque or Online payment only."></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" pl="0.4" text="2) Interest @24% per Annum will be charged from the date of invoice in amount is not paid in time."></m-text>

                    </td>
                </tr>
                <tr>
                    <td a-style p="0" br="1px solid black " colspan="6" rowspan="2 ">
                        <m-text [type]="gs.enums.texts.paragraph63" p="0.4" text="Note : Tax should not be deducted on the tax component charged on the invoice as per Circular No. 1/2014 issued
                    by the Central Board of Direct Taxes, Ministry of Finance, Government of India"></m-text>
                    </td>
                    <td a-style p="0" br="1px solid black " colspan="5 ">
                        <m-text [type]="gs.enums.texts.paragraph63" p="0.4" text="For" mb="2"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" p="0.4" text="Authorised Signatory"></m-text>
                    </td>
                </tr>
                <tr>
                    <td p="0" a-style br="1px solid black " colspan="6 ">
                        <m-text [type]="gs.enums.texts.paragraph63" p="0.4" text="Name : Bhaskar Ajgaonkar"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph63" p="0.4" text="Designation : Director"></m-text>
                    </td>
                </tr>
            </table>
        </ng-container>
    </div>
</div>




 -->









<div a-style p=2>
    
    <div a-style display="flex" justify="space-between" mt="2">
        <m-text [type]="gs.enums.texts.paragraph109" p="0.4" text="Tax Invoice"></m-text>
        <m-text [type]="gs.enums.texts.paragraph25" p="0.4" text="1800 120 7454" a-style mr="2"></m-text>
    </div>
    <div a-style display="flex" >
    <m-text [type]="gs.enums.texts.paragraph15" pl="0.4" mb="2" text="No :"></m-text>
    <m-text [type]="gs.enums.texts.paragraph35" pl="0.4" mb="2" text="1906522646"></m-text>
</div>
<!-- {{details | json}} -->
    <hr>
    <m-text [type]="gs.enums.texts.paragraph15" pl="0.4" mt=2 text="Date :"></m-text>
    <m-text [type]="gs.enums.texts.paragraph12" pl="0.4" [text]="date | date:'MM dd YYYY'"></m-text>
    <m-text [type]="gs.enums.texts.paragraph15" pl="0.4" mt=2 text="MBIN / MUIN :"></m-text>
    <m-text [type]="gs.enums.texts.paragraph12" pl="0.4" text={{details?.meta?.support?.mbin}}></m-text>
    <div a-style w="35" h="15" mb="6">
        <m-text [type]="gs.enums.texts.paragraph15" pl="1.5" mt=1 text="BILL TO :"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" [text]="details?.group?.basic_details?.legal_name" mt="1"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" [text]="details?.group?.location?.primary_address?.room + details?.group?.location?.primary_address?.floor + details?.group?.location?.primary_address?.premise + details?.group?.location?.primary_address?.road + details?.group?.location?.primary_address?.landmark + details?.group?.location?.primary_address?.locality + details?.group?.location?.primary_address?.city + details?.group?.location?.primary_address?.district + details?.group?.location?.primary_address?.region + +details?.group?.location?.primary_address?.postal + details?.group?.location?.primary_address?.country" mt="1"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" [text]="details?.group?.contact_details?.mobile" mt="1"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" [text]='"GSTIN :" + details?.group?.basic_details?.gst_in' mt="1"></m-text>
    </div>
    <!-- <m-text [type]="gs.enums.texts.paragraph43" pl="1.5" text="PAYMENT" ></m-text>
    <div a-style display="flex" justify="space-between" mb="2">
        <m-text [type]="gs.enums.texts.paragraph40" pl="1.5" text="Visa ****2086"></m-text>
        <m-text [type]="gs.enums.texts.paragraph47" pl="1.5" text="$12.99"></m-text>
    </div> -->
    <div a-style brBottom="1px solid black">
    </div>
    <!-- <div a-style brBottom="1px solid black" my="2">
        <div a-style display="flex" justify="space-between">
            <m-text [type]="gs.enums.texts.paragraph25" pl="1.5" text="Previous Balance"></m-text>
            <m-text [type]="gs.enums.texts.paragraph111" pl="1.5" text="$12.99"></m-text>
        </div>
        <div a-style display="flex" justify="space-between" mb="1">
            <m-text [type]="gs.enums.texts.paragraph25" pl="1.5" text="Received Payment"></m-text>
            <m-text [type]="gs.enums.texts.paragraph111" pl="1.5" text="($12.99)"></m-text>
        </div>
    </div> -->
    <!-- <div a-style display="flex" justify="space-between" mb="2">
        <m-text [type]="gs.enums.texts.paragraph25" pl="1.5" text="Balance Due (USD)"></m-text>
        <m-text [type]="gs.enums.texts.paragraph15" pl="1.5" text="$0.00"></m-text>
    </div> -->
    <div a-style display="flex" justify="space-around" mt="3" >
        <div a-style flexGrow=1 w="30%">
            <m-text align=center [type]="gs.enums.texts.paragraph26" pl="1.5" text="Term" mt="2" ></m-text>
            <m-text align=left [type]="gs.enums.texts.paragraph35"  text="Quraterly (90 Days)" mt="1"></m-text>
        </div>
        <div a-style brRight="1px solid black" w=1%></div>
        <div a-style align="center" w=40%>
            <m-text [type]="gs.enums.texts.paragraph26" text="Product"  mt="2"></m-text>
            <div a-style mt="1" px=2>
                <m-text align=left [type]="gs.enums.texts.paragraph35" text="Mesbro Subscription" mt="1"></m-text>
                <m-text align=left [type]="gs.enums.texts.paragraph35" text="CGST" mt="1"></m-text>
                <m-text align=left [type]="gs.enums.texts.paragraph35" text="SGST"></m-text>
                <m-text align=left [type]="gs.enums.texts.paragraph35" text="IGST"></m-text>
            </div>
            <m-text align=left [type]="gs.enums.texts.paragraph35" pl="1.5" text="Total (INR)" mt="4"></m-text>
        </div>
        <div a-style brRight="1px solid black" w=1% ></div>
        <div a-style align="center"   w=28% px=2>
            <m-text [type]="gs.enums.texts.paragraph26" pl="1.5" text="Amount"  mt="2" ></m-text>
            <m-text align=right [type]="gs.enums.texts.paragraph35" text="$12.99" mt="1" ></m-text>
            <div a-style mt="1">
                <m-text align="right" [type]="gs.enums.texts.paragraph35" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09" ></m-text>
                <m-text align="right" [type]="gs.enums.texts.paragraph35" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*0.09" ></m-text>
                <m-text align="right" [type]="gs.enums.texts.paragraph35" [text]="((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*1.18" ></m-text>
            </div>
            <m-text  [type]="gs.enums.texts.paragraph40" pl="1.5" text=""></m-text>
            <m-text align="right" [type]="gs.enums.texts.paragraph35" pl="1.5" text="{{((details?.package.data?.amount || 0) - (details?.discount?.amount || 0))*1.18}}" mt="4"></m-text>
        </div>
    </div>
   
    <div a-style brBottom="1px solid Black" mt="3"></div>
    <!-- <div a-style display="flex" justify="space-between" m="2">
        <m-text [type]="gs.enums.texts.paragraph40" pl="1.5" text="Taxes"></m-text>
        <m-text [type]="gs.enums.texts.paragraph47" pl="1.5" text="$0.00"></m-text>
    </div> -->
    <div a-style w="30" h="15" mt="2">
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" text=" MESBRO TECHNOLOGIES Private Limited"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" text="Mesbro House, Mayuresh Square, Sector 15, CBD Belapur, New Mumbai 400614, Maharashtra, India"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" text="India"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" pl="1.5" text="GSTIN : 27AAMCM5152A1ZN"></m-text>
    </div>
</div>















<!-- new -->