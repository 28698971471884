import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { encryptData } from 'src/mesbro/modules/shared/functions/common';
import { GlobalService } from 'src/mesbro/services/global.service';
interface IParams {
  otp_id: string;
  type: string;
  _id: string;
}
@Component({
  selector: 'm-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  error: any;
  visible = {
    old_password: false,
    new_password: false,
    confirm_new_password: false,
  };
  images = {
    false: '../assets/images/hideeye.svg',
    true: '../assets/images/unhideeye.svg',
  };
  passwordVisible = false;

  params: IParams | undefined;

  form: FormGroup | undefined;
  details: any;
  mobilenumber: any;
  constructor(
    public fb: FormBuilder,
    public gs: GlobalService,
    public ar: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
      this.initalizeForm();
    });
    if (this.params?._id) {
      console.log(this.params?._id);
      this.details = await this.gs.hps.get('idm', 'user', this.params?._id);
      let mobile = this.details.contact_details.mobile_number_primary;
      this.mobilenumber = mobile.replace(/\d(?=\d{4})/g, '*');
    }
  }

  initalizeForm() {
    this.form = this.fb.group({
      // old_password: [
      //   null,
      //   this.params?.type == 'forgot-password'
      //     ? []
      //     : [this.gs.form_validations.required('Old Password')],
      // ],
      new_password: [
        null,
        [
          // this.gs.form_validations.password,
          // this.gs.form_validations.required('New Password'),
        ],
      ],
      confirm_new_password: [
        null,
        [
          this.gs.form_validations.required('Confirm Password'),
          this.gs.form_validations.confirm_password,
        ],
      ],
    });
  }

  submit() {
    console.log(this.form);
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    try {
      if (this.params?.type == 'change-password') {
        this.change_password();
      }
      if (this.params?.type == 'forgot-password') {
        this.forgot_password();
      }
    } catch (error) {
      throw error;
    }
  }

  async change_password() {
    try {
      let body = this.form?.value;
      body.new_password = encryptData(body.new_password);
      delete body.confirm_new_password;
      let response = await this.gs.hps.post(
        'idm',
        'user',
        'change-password',
        body
      );
      this.gs.router.navigate(['']);
      let token = this.gs.user.users.activeUser?.token + '';
      await this.gs.hps.post('idm', 'user', 'sign-out', {});
      this.gs.user.remove_user(token);
      this.gs.toast('Security', 'Password Changed Succesfully');
    } catch (error: any) {
      console.log(error);
      this.gs.toast('Security', error?.error?.message, 'error');
      throw error;
    }
  }

  async forgot_password() {
    try {
      let body = {
        otp_id: this.params?.otp_id,
        password: this.form?.value.new_password,
        confirm_password: this.form?.value.confirm_new_password,
      };

      if (body.password == body.confirm_password) {
        body.password = encryptData(body.password);
        delete body.confirm_password;
        let response = await this.gs.hps.post('idm', 'otp', 'verify-otp', body);
        this.gs.router.navigate(['idm/sign-in']);
      } else {
        this.error = 'New password and confirm password should be same';
      }
    } catch (error) {
      throw error;
    }
  }
}
