import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-vehicle-banner',
  templateUrl: './vehicle-banner.component.html',
  styles: [
  ]
})
export class VehicleBannerComponent implements OnInit {

  @Input() type: any;
  @Input() detail: any;
  @Input() vehicle_image: string = '';
  @Input() activities: any;
  isVisible: boolean = false;
  isVisible_like: boolean = false;
  isVisible_dislike: boolean = false;
  isVisible_compare: boolean = false;
  isVisible_bookmark: boolean = false;
  like_count: number = 0;
  current: boolean = false;

  @Output() activity = new EventEmitter;

  like_title = 'Like';
  dislike_title = 'Dislike';
  compare_title = 'Add To Compare';
  save_title = 'Saved Product';
  print = 'Print';
  QR_title = 'Show Qr Code';
  share_title = 'Share'

  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    console.log(this.detail);
  }

  add_to_compare() {
    console.log('Function called', this.detail)
    if (!this.detail) {
      return;
    }
    let current = this.gs.localStorageGet('compare-items') || {};
    current[this.detail._id] = this.detail;
    this.gs.localStorageSet('compare-items', current)
    this.gs.toast('Compare Vehicle', 'Vehicle was added to compare list', 'success', 3)
    this.current = !this.current
    this.gs.$refresh_compare.next()
  }

  toggle(activities: any, toggle_key: string, current_key: string) {
    activities.my_activity[current_key] = !activities.my_activity[current_key]
    if (activities?.my_activity?.[current_key]) {
      activities.count[current_key] = activities?.count[current_key] + 1
    } else {
      activities.count[current_key] = activities?.count[current_key] - 1
    }

    if (activities?.my_activity?.[toggle_key]) {
      activities.count[toggle_key] = activities?.count[toggle_key] - 1
      activities.my_activity[toggle_key] = false
      // activities.my_activity[current_key] = !activities.my_activity[current_key]
    }
  }

}
