import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'm-center',
  templateUrl: './center.component.html'
})
export class CenterComponent implements OnInit {

  constructor() { }

  @Input() minHeight: string = ""

  ngOnInit(): void {
  }

}
