<div a-style display="flex">
    <div a-style bg="#1A73E8" p="4" textWrap='nowrap'>
        <m-text text="Add Your Domain" [type]="gs.enums.texts.paragraph56" mb="4"></m-text>
        <div a-style display=flex flexDirection=column h=10>
            <div a-style display=flex alignItems=flex-end>
                <div a-style brRadius=50% h=1.8 w=1.8 bg="white" display=grid placeContent=center mr=1.5>1</div>
                <m-text text="Enter domain" [type]="gs.enums.texts.paragraph57"></m-text>
            </div>
            <div a-style flexGrow=1 w=.15 bg=white ml=0.8>
            </div>
            <div a-style display=flex>
                <div a-style brRadius=50% h=1.8 w=1.8 bg="white" display=grid placeContent=center mr=1.5>2</div>
                <m-text text="Verify Ownwership" [type]="gs.enums.texts.paragraph57"></m-text>
            </div>
        </div>
    </div>
    <div a-style br="1px solid #e2e2e2" display="flex" flexDirection='column' justify="space-between" bg="white" py="4" px="2.4">
        <m-form columns="2"  [formGroup]="form" w=55 [paddingNone]=false [brNone]="false">
            <ng-container formGroupName="data">
                <div *ngIf="!verify">
                    <m-text text="Lorem ipsum dolors it amet, consetetur sadipscing elitr, sed diam nonx eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." [type]="gs.enums.texts.paragraph23" mb="2.4"></m-text>
                    <div a-style display="flex" justify="space-between">
                        <m-input [lowercase]="true" formControlName='domain_name' label="Domain Name" clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" [columns]="1/2" placeHolder="Domain Name" pl="none" minWidth="unset"></m-input>
                </div>
                </div>
                <div *ngIf="verify" a-style w=100% brRadius=0.5 display="flex" justify="flex-end">
                    <div a-style w=cal(100%-28%)>
                        <m-text text="Follow the step below to create DNS (Domain Name System) record that prove to Mesbro that you own the domain." [type]="gs.enums.texts.paragraph38" mb="2.4"></m-text>
                        <m-input formControlName='add_txt' label="1. Add the TXT record below to the DNS configuration for startupmasiha.in" clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" placeHolder="" minWidth="unset"></m-input>
                        <m-input formControlName='add_mx' label="2. Add MX record below to the DNS configuration for startupmasiha.in" clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg" placeHolder="" minWidth="unset"></m-input>
                        <m-text text="3. Click on Verify for verify your DNS." [type]="gs.enums.texts.paragraph38" mt="2.4"></m-text>
                        <m-text text="When Mesbro finds this DNS record, we'll make you a verified owner of the domain. (Note: DNS changes may take some time. If we don't find the record immediately, we'll check for it periodically.) To stay verified, don't remove the TXT record, even after verification succeeds. Note: Adding this record won't affect your email flow or any other feature in any way."
                            [type]="gs.enums.texts.paragraph38" mb="2.4" mt="1"></m-text>
                    </div>
                </div>
            </ng-container>
            <div a-style display="flex" justify="flex-end" gap=1.6 px=1.5 mt=8>
                <button *ngIf="!verify" a-style px=1.6 py=0.8 brRadius=0.4 bg=#ffffff bg="#FFFFFF;hov:#b2b2b2c2" br="1px solid #E4E4E4" outline=none (click)="this.modal.closeModal()" cursor="pointer">cancel </button>
                <button *ngIf="verify" a-style px=1.6 py=0.8 brRadius=0.4 bg=#ffffff bg="#FFFFFF;hov:#b2b2b2c2" br="1px solid #E4E4E4" cursor="pointer" outline=none (click)="verify=false" cursor="pointer">Back </button>
                <m-button *ngIf="!verify" [types]="gs.enums.buttons.button7" a-style fs="1.4" w="35" text="continue" [disable]="form?.invalid" (click)="verify=true"></m-button>
                <m-button *ngIf="verify" [types]="gs.enums.buttons.button7" a-style fs="1.4" w="35" text="Verify" [disable]="form?.invalid" (click)="submit();modal.closeModal();"></m-button>
            </div>
        </m-form>
        <!-- <div a-style display="flex" justify="flex-end" gap=1.6 px=1.5 mt=8>
            <button *ngIf="!verify" a-style px=1.6 py=0.8 brRadius=0.4 bg=#ffffff bg="#FFFFFF;hov:#b2b2b2c2" br="1px solid #E4E4E4" outline=none (click)="this.modal.closeModal()" cursor="pointer">cancel </button>
            <button *ngIf="verify" a-style px=1.6 py=0.8 brRadius=0.4 bg=#ffffff br="1px solid #E4E4E4" cursor="pointer" outline=none (click)="verify=false" cursor="pointer">Back </button>
            <m-button *ngIf="!verify" [types]="gs.enums.buttons.button7" a-style fs="1.4" w="35" text="continue" (click)="verify=true"></m-button>
            <m-button *ngIf="verify" [types]="gs.enums.buttons.button7" a-style fs="1.4" w="35" text="Verify" (click)="submit();modal.closeModal();"></m-button>
        </div> -->
    </div>
</div>



