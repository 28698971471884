import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { side_menu } from '../configuration/data/side-menu-items';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  selected_mail :Subject<any> = new Subject();
  refresh_count :Subject<any> = new Subject();
  remove_mail = new Subject();
  index = new Subject();
  // folder_list : Subject<any> = new Subject();
  variables = {
    side_menu: side_menu
  }

  constructor() { }
}
