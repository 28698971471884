import { link } from 'fs';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';

export const applications = [
  {
    application: 'ME',
    src: '../assets/images/Logo_Bank/Me_Logo_Coloured.svg',
    link: '/me/home',
  },
  {
    application: 'Organization',
    src: './assets/images/Logo_Bank/Organization_Logo_Coloured.svg',
    link: '/org/organization-home',
  },
  {
    application: 'Mail',
    src: '../assets/images/Logo_Bank/Mail_Logo_Coloured.svg',
    link: '/mail/home?type=inbox',
  },
  {
    application: 'Chat',
    src: '../assets/images/Logo_Bank/Chat_Logo_Coloured.svg',
    link: '/new-chat/details?type=conversation',
  },
  {
    application: 'Social',
    src: '../assets/images/Logo_Bank/Social_Logo_Coloured.svg',
    link: '/social/home',
  },
  // {
    //   application: 'Product',
    //   src: '../assets/images/Logo_Bank/Project_Logo_Coloured.svg',
    // },
    {
    application: 'Contact',
    src: '../assets/images/Logo_Bank/Contact_Logo_Coloured.svg',
    link: '/contact/home?type=list',
  },
  {
    application: 'B2B Product',
    src: '../assets/images/Logo_Bank/B2B_Product_Logo_Coloured.svg',
    link: '/product/home',
  },
  
  {
    application: 'Service',
    src: '../assets/images/Logo_Bank/Service_Logo_Coloured.svg',
    link: '/service/home',
  },
  {
    application: 'Article',
    src: '../assets/images/Logo_Bank/Article_Logo_Coloured.svg',
    link: '/article/home',
  },

  {
    application: 'Recipe',
    src: '../assets/images/Logo_Bank/Recipe_Logo_Coloured.svg',
    link: '/recipe/home',
  },
  // {
    //   application: 'Central',
  //   src: '../assets/images/Logo_Bank/central.svg',
  //   link: '/mesbro-central/home',
  // },
  {
    application: 'Vehicle',
    src: '../assets/images/Logo_Bank/Vehicle_Logo_Coloured.svg',
    link: '/vehicles/home',
  },
  {
    application: 'Spiritual',
    src: '../assets/images/Logo_Bank/Spiritual_Logo_Coloured.svg',
    link: '/spiritual/home',
  },
  {
    application: 'Project',
    src: '../assets/images/Logo_Bank/Project_Logo_Coloured.svg',
    link: '/project/home',
  },
  {
    application: 'Property',
    src: '../assets/images/Logo_Bank/Property_Logo_Coloured.svg',
    link: '/properties/home',
  },
  {
    application: 'Guardian',
    src: '../assets/images/Logo_Bank/Guardian_Logo_Coloured.svg',
    link: '/guardian/home',
  },
  {
    application: 'Nursery',
    src: '../assets/images/Logo_Bank/Nursery_Logo_Coloured.svg',
    link: '/nursery/home',
  },
  {
    application: 'Career',
    src: '../assets/images/Logo_Bank/Career_Logo_Coloured.svg',
    link: '/jobs/home',
  },
  {
    application: 'Scrap',
    src: '../assets/images/Logo_Bank/Scrap_Logo_Coloured.svg',
    link: '/scrap/home',
  },
  {
    application: 'Used',
    src: '../assets/images/Logo_Bank/Used_Logo_Coloured.svg',
    link: '/used/home',
  },
  {
    application: 'Smart ERP',
    src: '../assets/images/Icon_Bank/Smart_ERP_Logo_Coloured.svg',
    link: '/org/organization-ERp',
  },
  // {
  //   application: 'Refurbished',
  //   src: '../assets/images/Logo_Bank/Help_Center_Logo_Coloured.svg',
  //   link: '/center/home',
  // },
  // {
  //   application: 'Activities',
  //   src: '../assets/images/Logo_Bank/Help_Center_Logo_Coloured.svg',
  //   link: '/center/home',
  // },
  // {
  //   application: 'Help Center',
  //   src: '../assets/images/Logo_Bank/Help_Center_Logo_Coloured.svg',
  //   link: '/center/home',
  // },
  // {
  //   application: 'Analytics',
  //   src: '../assets/images/Logo_Bank/Analytic_Logo_Coloured.svg',
  //   link: '/center/home',
  // },
  // {
  //   application: 'Event',
  //   src: '../assets/images/Logo_Bank/Event_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Scrap',
  //   src: '../assets/images/Logo_Bank/Scrap_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Nursery',
  //   src: '../assets/images/Logo_Bank/Nursery_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Shortner',
  //   src: '../assets/images/Logo_Bank/Shortener_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Calender',
  //   src: '../assets/images/Logo_Bank/Calender_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Activity',
  //   src: '../assets/images/Logo_Bank/Activity_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },
  // {
  //   application: 'Project',
  //   src: '../assets/images/Logo_Bank/Project_Logo_Coloured.svg',
  //   link: '/recipe/home',
  // },

  
  // {
  //   application: 'Settings',
  //   src: './assets/images/settings.svg',
  //   link: '/generic/setting',
  // },

  // { application: 'Videos', src: 'https://mesbro.in/assets/images/app-icon/video.svg', link : '/generic/home?application=Video' },
  // { application: 'Help Center', src: '../assets/images/assets/chat-help.svg' , link: 'mesbro-help'},
  // { application: 'Service', src: 'https://mesbro.in/assets/images/app-icon/service.svg', link: '/generic/home?application=Service' },
  // { application: 'Drive', src: 'https://mesbro.in/assets/images/app-icon/drive.svg', link: '/drive/home?type=file_card' },
  // { application: 'Article', src: 'https://mesbro.in/assets/images/app-icon/article.svg', link: '/generic/home?application=Article' },
  // { application: 'News', src: 'https://mesbro.in/assets/images/app-icon/news.svg', link: '/generic/search?application=News' },
  // { application: 'Properties', src: 'https://mesbro.in/assets/images/app-icon/property.svg', link: '/generic/home?application=Property' },
  // { application: 'Spiritual', src: 'https://mesbro.in/assets/images/app-icon/religion.svg', link: '/generic/home?application=Spiritual' },
  // { application: 'Horoscope', src: 'https://mesbro.in/assets/images/app-icon/horoscope.svg', link: '/generic/home?application=Horoscope' },
  // { application: 'Vehicles', src: 'https://mesbro.in/assets/images/app-icon/vehicle.svg', link: '/generic/home?application=Vehicle'},
  // { application: 'Recipe', src: 'https://mesbro.in/assets/images/app-icon/recipe.svg', link: '/generic/home?application=Recipe' },
  // { application: 'Scrap', src: 'https://mesbro.in/assets/images/app-icon/scrap.svg', link: '/generic/home?application=Scrap' },
  // { application: 'Nursery', src: 'https://mesbro.in/assets/images/app-icon/nursery.svg', link: '/generic/home?application=Nursery' },
  // { application: 'Creative', src: 'https://mesbro.in/assets/images/app-icon/creative.svg', link: '/generic/home?application=Creative' },
  // { application: 'Jobs', src: 'https://mesbro.in/assets/images/app-icon/job.svg', link: '/generic/home?application=Job' },
  // { application: 'Restaurants', src: 'https://mesbro.in/assets/images/app-icon/restaurant.svg', link: '/generic/home?application=Restaurant' },
  // { application: 'Refurbished', src: 'https://mesbro.in/assets/images/app-icon/used.svg', link: '/generic/home?application=Used' },
  // { application: 'Used', src: 'https://mesbro.in/assets/images/app-icon/drive.svg' },
  // { application: 'Activity', src: 'https://mesbro.in/assets/images/app-icon/activity.svg', link: '/generic/home?application=Activity' },
  // { application: 'Project', src: 'https://mesbro.in/assets/images/app-icon/project.svg', link: '/generic/home?application=Project' },
  // { application: 'SOS', src: 'https://mesbro.in/assets/images/app-icon/sos.svg', link: '/generic/home?application=Sos' },
  // { application: 'Rule', src: 'https://mesbro.in/assets/images/app-icon/policy.svg', link: '/generic/home?application=Policy' },
  // { application: 'Guardian', src: 'https://mesbro.in/assets/images/app-icon/guardian.svg', link: '/generic/home?application=Guardian' },
  // { application: 'Adoption', src: 'https://mesbro.in/assets/images/app-icon/adoption.svg', link: '/generic/home?application=Adoption' },
  // { application: 'Images', src: 'https://mesbro.in/assets/images/app-icon/images.svg', link: '/generic/home?application=Images' },
  // { application: 'Thought', src: 'https://mesbro.in/assets/images/app-icon/thought.svg', link: '/generic/home?application=Thought' },
  // { application: 'Jokes', src: 'https://mesbro.in/assets/images/app-icon/joke.svg', link: '/generic/home?application=Joke' },
  // { application: 'Events', src: 'https://mesbro.in/assets/images/app-icon/event.svg', link: '/generic/home?application=Event' },
  // { application: 'Places', src: 'https://mesbro.in/assets/images/app-icon/places.svg', link: '/generic/home?application=Places' },
  // { application: 'Subsidy', src: 'https://mesbro.in/assets/images/app-icon/subsidy.svg', link: '/generic/home?application=Subsidy' },
  // { application: 'Virus', src: 'https://mesbro.in/assets/images/app-icon/virus.svg', link: '/generic/home?application=Virus' },
  // { application: 'Lost items', src: 'https://mesbro.in/assets/images/app-icon/finder.svg', link: '/generic/home?application=Finder' },
  // { application: 'Template', src: 'https://mesbro.in/assets/images/app-icon/template.svg', link: '/generic/home?application=Template' },
  // { application: 'Social', src: 'https://mesbro.in/assets/images/app-icon/socialmedia.svg', link: '/social/home' },
  // { application: 'Transfer', src: 'https://mesbro.in/assets/images/app-icon/filetransfer.svg' },
  // { application: 'File Transfer', src: 'https://mesbro.in/assets/images/app-icon/filetransfer.svg' },
  // { application: 'Settings', src: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/setting-black.svg' },
  // { application: 'Short-link', src: 'https://mesbro.in/assets/images/app-icon/shortlink.svg' },
  // { application: 'Notes', src: 'https://mesbro.in/assets/images/app-icon/keep.svg', link:'keep/home' },
  // { application: 'Calculator', src: 'https://mesbro.in/assets/images/app-icon/calculator.svg' },
  // { application: 'Dictionary', src: 'https://mesbro.in/assets/images/app-icon/dictionary.svg' },
  // { application: 'Currency', src: 'https://mesbro.in/assets/images/app-icon/currencyconvertor.svg', link: 'currency/home?type=currency-update' },
  // { application: 'Weather', src: 'https://mesbro.in/assets/images/app-icon/weather.svg' },
  // { application: 'Translator', src: 'https://mesbro.in/assets/images/app-icon/translator.svg', link: '/translator/translator' },
];
export class SocketService {
  id: any;
  users: IUserData;
  constructor(public gs: GlobalService) {
    this.gs.user.users$.subscribe((users: IUserData) => {
      this.users = users;
      console.log(this.users);
      
    });
  }
}
