import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-solid-color',
  templateUrl: './solid-color.component.html',
  styles: [
  ]
})
export class SolidColorComponent implements OnInit {
  @Input() modal: any
  constructor(public gs : GlobalService) { }

  ngOnInit(): void {
  }

}
