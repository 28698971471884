<div *ngIf="title && card && list?.list?.length" a-style  [mb]="mb?mb:'2.4'" [mt]="mt?mt:'2.4'"
    overflowX="auto" brRadius="0.6" bg="#ffffff" title="">
    <div a-style display="flex" justify="space-between" alignItems="center" [mt]="mt?mt:'0'" [ml]="ml?ml:'4.8'"
        [mr]="mr?mr:'2.4'" [mb]="mb?mb:'0'"  pb=1.6>
        <div a-style display=flex align=center gap=1.6 >
            <!-- <m-text [type]="gs.enums.texts.paragraph40" [text]="title" [mb]="mb?mb:'0'"
                (click)="gs.router.navigate(['/product/gallary'],{queryParams:{type:title}})" color="#2479AB"></m-text> -->
            <p a-style color="#2479AB" fs="1.6">{{title}}</p>
        </div>
        <!-- <div a-style w=10 align=right>
            <m-image class="MagicScroll" id="scroller" src="../assets/images/assets/scroll.svg" hover="../assets/images/assets/Productscroll.svg" w=100% h=100% bgPosition="center" bgSize="cover" (click)="right()" a-style w=2 h=1.8 opacity=1 trasition=.3s></m-image>
        </div> -->
    </div>
    <ng-container #slideMe [ngTemplateOutlet]="main_list"></ng-container>
</div>
<div *ngIf="!card && title && list?.list?.length">
    <ng-container [ngTemplateOutlet]="main_list"></ng-container>
</div>
<!-- <div a-style align="center">
    <p a-style fs="1.6" color="#2479AB" mt="2" pb="0">View More</p>
</div> -->
<div *ngIf="queryParams.type=='my-organization'" a-style display=flex flexWrap=wrap gap=2.4 mx=2.4>
    <div  (click)="this.gs.router.navigate(['gdashboard','group', 'dashboardDetail'] ,{queryParams:{type:'organization'}})" mr=2.4>
        <div display=flex a-style br='1px solid #e2e2e2' brRadius="0.6" w="24.3" p=1 >
            <div a-style display="flex" flexDirection="column" align="center"
                br='1px solid #e2e2e2' brRadius="0.6" w=22.3 bg=#F5F5F5 h=28>
                <m-image src="../assets/images/assets/My-org-tranparent-2.gif" mt="3.8" w=14.9 h="14.9">
                </m-image>
                <m-text [type]="gs.enums.texts.paragraph129" text='Add Organization' mt="2.9" color="#707070" mb="4.1" color="#707070"
                    fs=2>
                </m-text>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let item of list?.list;let i=index">
        <m-group-card [detail]="item" *ngIf="application=='idm' && component=='group'" [type]="type" [index]="i" (delete)="delete(item._id,i)" mt=0></m-group-card>
    </ng-container>
  
</div>
<div a-style  display=flex flexWrap=wrap gap=2.4 >
    <!-- <div *ngIf=" queryParams.type=='my-organization'" (click)="this.gs.router.navigate(['gdashboard','group', 'dashboardDetail'] ,{queryParams:{type:'organization'}})" mr=2.4>
        <div display=flex a-style br='1px solid #e2e2e2' brRadius="0.6" w="24.3" p=1 >
            <div a-style display="flex" flexDirection="column" align="center"
                br='1px solid #e2e2e2' brRadius="0.6" w=22.3 bg=#F5F5F5 h=28>
                <m-image src="../assets/images/assets/My-org-tranparent-2.gif" mt="3.8" w=14.9 h="14.9">
                </m-image>
                <m-text [type]="gs.enums.texts.paragraph129" text='Add Organization' mt="2.9" color="#707070" mb="4.1" color="#707070"
                    fs=2>
                </m-text>
            </div>
        </div>
    </div> -->
    <ng-container *ngIf="!title && list?.list?.length" [ngTemplateOutlet]="main_list"></ng-container>
</div>

<ng-template #main_list>
    <div #scrollMe [minWidth]="minWidth" [maxWidth]="maxWidth" [flexGap]="gap" class="no_scroll" [mt]="mt" [mb]="mb" overflowX="auto" a-style [display]="display?display:'flex'" [flexWrap]="flexWrap" [ml]="ml" [mr]="mr" [flexDirection]="flexDirection ?flexDirection:'row' " [align]="align" [justify]="justify" [px]="px" [py]="py">

        <ng-container *ngFor="let item of list?.list;let i=index">
            <m-generic-card [detail]="item" *ngIf="type=='generic_card'" (delete)="delete(item._id,i)" [type]="card_type[queryParams.application]"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Service' && component=='Service' || card_application=='Service' " (delete)="delete(item._id,i)" type="card_1"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Nursery' && component=='Nursery' || card_application=='Nursery' " (delete)="delete(item._id,i)" type="card_2"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Spiritual' && component=='Spiritual' || card_application=='Spiritual' " (delete)="delete(item._id,i)" type="card_1"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Horoscope' && component=='Horoscope' || card_application=='Horoscope' " (delete)="delete(item._id,i)" type="card_1"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Property' && component=='Property' || card_application=='Property' " (delete)="delete(item._id,i)" type="card_1"></m-generic-card>
            <m-recipe-card [detail]="item" *ngIf="application=='Recipe' && component=='Recipe' || card_application=='Recipe' " (delete)="delete(item._id,i)" [type]="type"></m-recipe-card>
            <m-generic-card [detail]="item" *ngIf="application=='News' && component=='News' || card_application=='News' " (delete)="delete(item._id,i)" [type]="type"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Article' && component=='Article' || card_application=='Article' " (delete)="delete(item._id,i)" [type]="type"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Used' && component=='Used' || card_application=='Used' " (delete)="delete(item._id,i)" [type]="type"></m-generic-card>
            <m-generic-card [detail]="item" *ngIf="application=='Video' && component=='Video' || card_application=='Video' " (delete)="delete(item._id,i)" [type]="type"></m-generic-card>
            <!-- <m-generic-card [detail]="item" *ngIf="application=='generic' && component=='activity'" (delete)="delete(item._id,i)" [type]="type"></m-generic-card> -->
            <!-- <m-article-card [detail]="item" *ngIf="application=='Article' && component=='Article' || card_application=='Article' " (delete)="delete(item._id,i)" [type]="type"></m-article-card> -->
            <m-product-card [detail]="item" *ngIf="application=='Product' && component=='Product' && queryParams.type != 'my-products' || card_application=='Product' " (delete)="delete(item._id,i)" [type]="type"></m-product-card>

            <m-product-card [detail]="item" *ngIf="application=='Subsidy' && component=='Subsidy' || card_application=='Subsidy' " (delete)="delete(item._id,i)" [type]="type"></m-product-card>
            <m-news-card [detail]="item" *ngIf="application=='News' && component=='News' || card_application=='News' " (delete)="delete(item._id,i)" [type]="type"></m-news-card>
            <!-- <m-group-card [detail]="item" *ngIf="application=='idm' && component=='group'" [type]="type" [index]="i" (delete)="delete(item._id,i)" mt=0></m-group-card> -->
            <m-rfq-card [detail]="item" *ngIf="application=='Product' && component=='Lead'" [type]="type" (delete)="delete(item._id,i)"></m-rfq-card>
            <!-- <m-enquiry-card [detail]="item" *ngIf="application=='Generic' && component=='Enquiry'"
                (delete)="delete(item._id,i)" [type]="type"></m-enquiry-card> -->
            <!-- <m-generic-card [detail]="item"
                *ngIf="application=='Generic' && component=='Enquiry' "
                (delete)="delete(item._id,i)" type="card_1"></m-generic-card> -->
            <m-product-card [detail]="item" *ngIf="application=='Generic' && component=='Enquiry'  " (delete)="delete(item._id,i)" [type]="type"></m-product-card>
            <m-brand-card [detail]="item" *ngIf="application=='Generic' && component=='Brand'" [type]="type">
            </m-brand-card>
            <m-vehicle-card [detail]="item" *ngIf="application=='Vehicle' && component=='Vehicle' || card_application=='Vehicle'" (delete)="delete(item._id,i)" [type]="type"></m-vehicle-card>
            <m-chat-message [detail]="item" *ngIf="application=='Generic' && component=='Conversation'" [type]="type">
            </m-chat-message>
            <m-short-link-card [detail]="item" *ngIf="(application=='generic' && component=='shortlink') || type=='shortlink_card_1'" application="generic" [type]="type" [index]="i" (delete)="delete(item._id,i)"> </m-short-link-card>
            <m-short-link-card [detail]="item" *ngIf="application=='generic' && component=='campaign'" application="generic" [type]="type"> </m-short-link-card>
            <m-socialmedia-post [detail]="item" *ngIf="application=='Generic' && component=='Social'">
            </m-socialmedia-post>
            <m-product-card [detail]="item" *ngIf="application=='generic' && component=='activity'" (delete)="delete(item._id,i)" [type]="type"></m-product-card>
        </ng-container>
    </div>
    <div  a-style display=flex justify=center mt=2 mb=2 *ngIf="load_more">
        <m-button *ngIf="load_more" (click)="getList(filters)" [types]="gs.enums.buttons.button18" text="Load More">
        </m-button>
    </div>
</ng-template>
<div *ngIf="empty_image_show && list?.list?.length == 0" a-style display=flex flexDirection=column cursor=pointer flexGap=2 align=center justify=center my=2.4>
    <m-image src="https://mesbro.in/assets/images/add-card-image/add-product-card.svg"></m-image>
    <m-text [type]="gs.enums.texts.paragraph26" text="No Items Found"></m-text>
</div>
<!-- <div *ngIf="add_item_text && list?.count ==0" a-style display=flex flexDirection=column cursor=pointer flexGap=2
    align=center justify=center h=100% my=2.4 [my]="add_item_margin">
    <img src="https://mesbro.in/assets/images/add-card-image/add-product-card.svg" alt="" srcset="">
    <m-button [routerLink]="add_item_link" [queryParams]="{application:application}" [types]="gs.enums.buttons.button7"
        [text]="add_item_text"></m-button>
</div> -->