import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { IUser, IUserData } from '../applications/idm/interfaces/i-user';
import { localStorageGet, localStorageRemove, localStorageSet } from '../modules/shared/functions/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users: IUserData;
  permissions: any = undefined;
  users$ = new BehaviorSubject<IUserData>(this.get_users());

  get_users() {
    this.users = <IUserData>localStorageGet('users') || {
      activeUser: undefined,
      allUsers: []
    };
    return this.users
  }

  public add_user(user: IUser) {
    let users = this.users;
    let current_index = users?.allUsers?.findIndex(u => u._id == user._id);
    if (!current_index || current_index == -1) {
      console.log("madhuri");
      users = users || {};
      users.activeUser = user;
      users.allUsers = users.allUsers || [];
      users.allUsers.push(user);
      localStorageSet('users', users);
      this.users$.next(users);
    }
  }

  public update_user(user: IUser) {
    let users = this.users;
    let current_index = users?.allUsers?.findIndex(u => u._id == user._id);
    if (current_index >= -1) {
      users.activeUser = user;
      users.allUsers[current_index] = user;
      localStorageSet('users', users);
      this.users$.next(users);
    }
  }
  public switchUser(user: IUser) {
    let users = this.users
    users.activeUser = user
    localStorageSet('users',users)
    this.users$.next(users)
  }

  public remove_user(token: string) {
    let users: IUserData | undefined = this.users;
    let current_index = users?.allUsers?.findIndex(u => u.token == token)
    users.allUsers.splice(current_index, 1);
    let new_active_user = users.allUsers[0];
    if (new_active_user) {
      users.activeUser = new_active_user
      localStorageSet('users', users);
      this.users$.next(users)
    } else {
      users.activeUser = undefined;
      localStorageRemove('users');
      this.users$.next({ allUsers: [], activeUser: undefined })
    }
  }
  constructor() { }
}
