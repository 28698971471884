import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-global-banner',
  templateUrl: './global-banner.component.html',
  styles: [
  ]
})
export class GlobalBannerComponent implements OnInit {
  response:any
  constructor(public gs :GlobalService) { }

  ngOnInit(): void {
    this.get_count()
  }
  async get_count() {
    try {
      this.response = await this.gs.hps.post('generic', 'activity', 'product-global-marketplace', {})
      
    } catch (error) {
      console.log(error);
      
      
    }
    
  }
}
