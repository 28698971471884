import { Component, Input, OnInit } from '@angular/core';
import { IUserData } from 'src/mesbro/applications/idm/interfaces/i-user';
import { GlobalService } from 'src/mesbro/services/global.service';
import { HttpProviderService } from 'src/mesbro/services/http-provider.service';

@Component({
  selector: 'm-group-list',
  templateUrl: './group-list.component.html',
  styles: [
  ]
})
export class GroupListComponent implements OnInit {

  @Input() text :any;
  list = {
    list: [],
    count: 0
  }
  filters : any = undefined;
  users: IUserData

  constructor(public hps: HttpProviderService, public gs: GlobalService) { }

  application = 'social';
  component = 'group';

  ngOnInit(): void {
    this.gs.user.users$.subscribe(users => {
      this.users = users
      console.log(this.users);

    })
    setTimeout(() => {
      this.filters = { ['meta.roles.owner.user.' + this.gs.user.users.activeUser?._id]: true }
    }, 500);
    this.get_list()
  }
  async get_list() {
    let filters = {}
    let response = await this.gs.hps.post(this.application, this.component, 'list', filters)
    this.list = response;
  
  }
  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1);
  }



}

