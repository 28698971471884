import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-sent-enquiries',
  templateUrl: './sent-enquiries.component.html',
  styles: [
  ]
})
export class SentEnquiriesComponent implements OnInit {
  statusList = [
    { status: 'Pending' },
    { status: 'Acknowledged' },
    { status: 'Closed' },
  ]

  constructor(public gs:GlobalService) { }

  ngOnInit(): void {
  }

}
