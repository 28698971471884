import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Inputs } from 'src/enums/inputs';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import * as moment from 'moment';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NewInputComponent),
  multi: true
};

@Component({
  selector: 'm-new-input',
  templateUrl: './new-input.component.html',
  styles: [
  ],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class NewInputComponent implements OnInit {
  @ViewChild('input_value') input_value: any;
  @ViewChild('image') image: any;

  @Output() changed = new EventEmitter();
  @Output() debounce = new EventEmitter();
  @Output() clicked = new EventEmitter();
  @Output() prefix_clicked = new EventEmitter();
  @Output() postfix_clicked = new EventEmitter();
  @Output() postfix2_clicked = new EventEmitter();
  @Output() postfix3_clicked = new EventEmitter();
  @Output() prefix_div_clicked = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();
  // @Output() changed = new EventEmitter();

  @Input() align: any = "";
  @Input() aspect_ratio = 1
  @Input() autofocus: any = "";
  @Input() bg: any = "";
  @Input() none: any = "";
  @Input() bindLabel: string = "";
  @Input() bindValue: string = "";
  @Input() br: any = "";
  @Input() brBottomLeftRadius: string = "";
  @Input() brBottomRightRadius: string = "";
  @Input() brTopLeftRadius: string = "";
  @Input() brTopRightRadius: string = "";
  @Input() brRadius: any = "";
  @Input() color: any = "";
  @Input() country: boolean = false;
  @Input() columns: any = 1;
  @Input() cursor: string = "";
  @Input() file: any = "";
  @Input() flexGrow: any = "";
  @Input() flexDirection: any = "";
  @Input() fs: any = "";
  @Input() h: any = "";
  @Input() hide: string = "";
  @Input() info: string = "";
  @Input() input_px: string = ""
  @Input() label: string = ""
  @Input() mb: any = "";
  @Input() min: any = "";
  @Input() minWidth: any = "";
  @Input() maxWidth: any = "";
  @Input() mr: any = "";
  @Input() mt: any = "";
  @Input() outer_pr: string = ''
  @Input() p: any = "";
  @Input() pl: any = "";
  @Input() placeHolder: any = "";
  @Input() postfix_bg: any = '';
  @Input() postfix2: any = '';
  @Input() postfix3: any = '';
  @Input() postfix_px: any = '';
  @Input() pr: any = "";
  @Input() prefix_h: string = ""
  @Input() prefix_w: string = ""
  @Input() prefix_pl: string = ""
  @Input() px: any = "";
  @Input() py: any = "";
  @Input() shadow: any = "";
  @Input() src: any = "";
  @Input() text: any = "";
  @Input() title: any = "";
  @Input() type: Inputs = this.gs.enums.inputs.newText;
  @Input() radioName: any = ''
  // @Input() type: any = "";
  @Input() w: any = "";
  @Input() rows: any = "";
  @Input() m: any = "";
  @Input() alignItems: string = "";
  @Input() list: any;
  @Input() labelTemplate: any = undefined;
  @Input() passedData: any;
  @Input() click: any = "";
  @Input() upload_event: any = "";

  @Input() border: string = '' ; 
  @Input() postfix: string = '' ;
  @Input() prefix_div: string = '' ; 
  @Input() postfix_text: string = '' ; 
  @Input() prefix: string = '' ; 
  @Input() prefix_text: string = '' ; 
  @Input() modal: any;

  @Input() filtered_list: any;
  @Input() capitalize: Boolean = true;
  @Input() disabled: Boolean = false ;
  is_visible: boolean = false;
  @Input() multiple = false
  @Input() onlyInput: boolean = false
  selected_item: any = {};
  clearIcon = 'cross';

  debounce_time = 500;
  debounce_timeout: any = undefined;
  hovered : boolean = false;
  inputValue: any = { input: '', image: '', };
  value: any;
  selected_index: any = -1;
  searchText: any;

  @Input() inputWidth: string = ''
  hover: any = {}
  @Input() bindImage: string = "";
  @Input() direct_image: boolean = false;
  contentEditables: any = {}

  date: any =[undefined]
  time: any =[undefined]

  date_form_group: FormGroup | undefined;
  time_form_group: FormGroup | undefined;
  country_dropDown: any = { label: 'India', country_code: '+91', image: "99/99/99/99/99/91/66.jpg" };

  control: FormControl | undefined;


  
  constructor(public gs: GlobalService , public injector: Injector,public fb: FormBuilder , public cdr: ChangeDetectorRef,) { }
  
  async ngAfterContentInit() {
    const ngControl: NgControl | null = this.injector.get(NgControl, null);
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
      // Component is missing form control binding
    }
  }

  ngOnInit(): void {
    this.initial_date_settings()
    this.initial_time_settings()
  }

  capitalize_text(text: string) {
    if (!text) {
      return text
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
    
  }

  public focus() {
    this.input_value?.nativeElement.focus()
  }

  onChange: any = (event: any) => {
    this.changed.emit(event)
    this.internal_change()
  }
  onBlur: any = () => { }
  onTouch: any = () => {
    console.log(this.control);
  }
  internal_change() {
    if (this.debounce_timeout) {
      clearTimeout(this.debounce_timeout);
      // console.log('Timeout Cleared')
    }
    this.debounce_timeout = setTimeout(() => {
      this.debounce.emit(this.value)
      // console.log('Debounce Emitted')
    }, this.debounce_time);
  }

  writeValue(value: any): void {
    this.value = value;
      if (this.type == 'date') {
        this.initialize_date()
      }
  
  }

  
  initialize_date() {
    if (this.type == 'date') {
      console.log(this.value)
      if (this.value) {
        setTimeout(() => {
          let date = new Date(this.value)
          this.date_form_group?.get('date')?.setValue(date)
        }, 1000);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  set_image(event: any) {
    if (this.multiple) {
      this.value = this.value || [];
      this.value.push(event.url)
    } else {
      this.value = event.url
    }
    this.onChange(this.value)
  }

  select_dropdown(event: any, item: any) {
    this.clearIcon = 'cross';
    if (this.type == this.gs.enums.inputs.multiSelect) {
      this.value = Array.isArray(this.value) ? this.value : [];
      this.value.push(event.target.value || this.bindValue ? item[this.bindValue] : item);
      this.onChange(this.value);
      this.inputValue.input = undefined;
      return;
    }
    this.value = this.bindValue ? item[this.bindValue] : item;
    this.inputValue.input = this.bindLabel ? item[this.bindLabel] : item
    this.selected_item = item;

    if (this.country) {
      this.country_dropDown = item;
      this.gs.$country.next(item);
      console.log(item);

    }
    console.log(this.control);
    this.onChange(this.value);
    this.searchText = undefined;
  }

  highlight_dropdown(event: any, action: string) {
    if (!this.is_visible) {
      this.filtered_list = this.list
      this.is_visible = true;
      return;
    }
    if (action == 'down' && this.selected_index < (this.filtered_list.length - 1)) {
      this.selected_index++
    } else if (action == 'up' && this.selected_index >= 1 && this.filtered_list.length != 1) {
      this.selected_index--
    }
    let item = this.filtered_list[this.selected_index]
    this.selected_item = item;
  }

  filter_list(search_text: string) {
    if (!search_text) {
      return this.list;
    }
    return this.list?.filter((item: any) => {
      let bind_item = (item[this.bindLabel] || item) + '';
      return bind_item.toLowerCase().includes((search_text + '').toLowerCase())
    })
  }

  dropdown_keyboard_actions(event: any) {
    if (event.code == 'ArrowDown') {
      this.highlight_dropdown(event, 'down')
    } else if (event.code == 'ArrowUp') {
      this.highlight_dropdown(event, 'up')
    } else if (event.code == 'Escape') {
      this.is_visible = false
    } else if (event.code == 'Enter') {
      // if(this.type != 'multiSelect'){
      this.select_dropdown(event, this.filtered_list[this.selected_index] || this.filtered_list[0])
      // }
      this.is_visible = false
    } else if (event.code == 'Tab') {
      this.select_dropdown(event, this.filtered_list[this.selected_index] || this.filtered_list[0])
    } else {
      this.is_visible = true;
      setTimeout(() => {
        this.filtered_list = this.filter_list(event.target.value)
      }, 50);
    }
  }

  push(event: any) {
    console.log(this.value + '')
    this.value = Array.isArray(this.value) ? this.value : []
    let values: string = event.target.value || this.inputValue.value
    console.log(this.value, values)
    if (values) {
      let comma_seperated_values = values.split(',')

      comma_seperated_values.forEach((value: any) => {
        let index = this.value.findIndex((v: any) => { return v == value });
        if (index == -1) {
          this.value.push(value)
        }
      })

    }
    this.inputValue.input = undefined
    this.onChange(this.value)

  }

  pop(event: any) {
    let value = event.target.value
    if (!value && !this.inputValue.input) {
      this.value.pop(value)
      this.onChange(this.value)
    }
  }

  onPaste(event: any, input: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let body = { target: { value: pastedText } }
    setTimeout(() => {
      this.push(body);
    }, 50);
  }

  remove(item: any) {
    this.value.splice((item[this.bindLabel] || item), 1)
    this.onChange(this.value)
  }

  changeSingleTag(event: any, index: any) {
    this.value[index] = event.target.innerText
    this.onChange(this.value)
  }

  initial_date_settings() {
    if (this.type == 'date') {
      this.date_form_group = this.fb.group({
        date: null,
      })

      let touchedInterval = setInterval(() => {
        if (this.control?.touched) {
          this.date_form_group?.markAllAsTouched();
          clearInterval(touchedInterval);
        }
      }, 200)
    }
  }

  initial_time_settings() {
    if (this.type == 'time') {
      this.time_form_group = this.fb.group({
        time: null,
      })

      let touchedInterval = setInterval(() => {
        if (this.control?.touched) {
          this.time_form_group?.markAllAsTouched();
          clearInterval(touchedInterval);
        }
      }, 200)
    }
  }

}
