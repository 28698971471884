<div class="modal" a-style position="fixed" zIndex=100  h=auto maxWidth=400px w=100vw maxWidth=39 right=0.5 top="7.5" bg="white" br="1px solid #e2e2e2"  brRadius=.6 shadow="0 2px 10px rgb(0 0 0 / 20%)">
    <div a-style p="1.2" brBottom="1px solid #e2e2e2" bg="#EEF5FB">
        <div a-style display="flex" gap="1.5">
            <div a-style align="flex-end" justify="center">
                <!-- {{users?.activeUser?.personal_details | json}} -->
                <div bgSize=cover a-style w="8" h="8" position="relative" [bgUrl]="users?.activeUser?.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.profile_picture) : gs.enums.images.image293" br="1px solid #E4E4E4"
                    display="flex" zIndex="0" overflowX="hidden" align="flex-end" brRadius="50%" (click)="gs.router.navigate(['/social/profile'])">
                    <!-- <div a-style w="10" h="2.8" position="absolute" bg="#333333" display="flex" justify="center" align="center" zIndex="-1" opacity="0.5" justify="center">
                        <m-text routerLink="/idm/my-profile" [queryParams]="{type: 'Personal'}" [type]="gs.enums.texts.paragraph50" text="Change"></m-text>
                    </div> -->
                </div>
            </div>
            <div>
                <p a-style fs="1.6"  color=#333333> {{users?.activeUser?.personal_details?.first_name}} {{users?.activeUser?.personal_details?.last_name}} </p>
                <p a-style fs="1.3" color=#2479AB mb="0.6"> {{users?.activeUser?.contact_details?.email_address_primary}} </p>
                <p a-style fs="1.3" color="#81BC06"  mb="0.3" cursor="pointer" (click)="gs.router.navigate(['/me/form'],{queryParams:{type:'Personal Details'}})">My Profile</p>
                <!-- {{users?.activeUser?.personal_details?.profile_picture | json}} -->
                <!-- <m-button (click)="close_modal.emit('Close') " [types]="gs.enums.buttons.button32" routerLink="/idm/my-profile" [queryParams]="{type: 'Personal'}" align="left" br="none" a-style text="My Profile"></m-button> -->
            </div>
        </div>
    </div>
    <div a-style maxHeight="35.5" overflowY="scroll" id='scroll'>
        <ng-container *ngFor="let user of users?.allUsers">
           
            <div *ngIf="user?._id != users?.activeUser?._id" (click)="gs.user.switchUser(user);close_modal.emit();gs.router.navigate(['home'])" a-style display="flex" gap="1.5" overflow="auto" id='scroll' brBottom="1px solid #e2e2e2">
                <div bgSize=cover a-style w="5.6" h="5.6" ml="2" my="1" [bgUrl]="user.personal_details?.profile_picture? ('https://new-api.mesbro.com/uploads/'+user.personal_details?.profile_picture) : gs.enums.images.image293" br="1px solid #e2e2e2" brRadius="50%"> </div>
                <div a-style my="1" cursor="pointer">
                    <!-- {{user.personal_details | json}} -->
                    <p a-style fs="1.6"  mb="0.3">{{user.personal_details?.first_name}} {{user?.personal_details?.last_name}}</p>
                    <p a-style fs="1.3" mb="0.6" color=#2479AB>{{user.contact_details?.email_address_primary}}</p>
                </div>
                <!-- {{user?.personal_details?.profile_picture | json}} -->
            </div>
        </ng-container>
    </div>
    <div a-style py="1" brTop="none" justify="space-between" px="1.8" display="flex">
        <m-button [types]="gs.enums.buttons.button0" text="Add Account" h=4 w="35;400:32" routerLink="/idm/sign-in" (click)="close_modal.emit('')"></m-button>
        <m-button [types]="gs.enums.buttons.button0" text="Sign Out" h=4 w="35;400:32" routerLink="/home" routerLink="/idm/sign-in" (click)="sign_out(users.activeUser?._id);close_modal.emit('');"></m-button>
    </div>
</div>

