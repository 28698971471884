<div a-style br="1px solid #e4e4e4" p="2.4" *ngIf="before==true">
    <div a-style brRadius="0.6" p=1 br="1px solid #e4e4e4" *ngIf="before==true">
        <img width="100%" height="auto" id="myimage" [src]="url? url : value? 'https://new-api.mesbro.com/uploads/'+value : 'https://new-api.mesbro.com/uploads/99/99/99/99/99/99/67.jpg'" crossorigin="anonymous" #croppr alt="" a-style srcset="">
    </div>
    <div a-style display="flex" brRadius="0.6" br="1px solid #e4e4e4" mt=2.4 justify="space-between" p="3">
        <m-button (click)="file_input.click()" [types]="gs.enums.buttons.button30" a-style fs='1.4' text="Upload File"></m-button>
        <m-button (click)="upload_pic (file) " [types]="gs.enums.buttons.button30" a-style fs='1.4' text="Submit"></m-button>
    </div>
    <input #file_input type="file" name="" (change)="upload_event($event)" hidden id="">
</div>
<canvas a-style position=fixed id="myCanvas" style="visibility:hidden"></canvas>

<div a-style p="2.4" br="1px solid #e4e4e4">
    <div a-style display=flex justify="space-between" mb=2.4>
        <m-text [type]="gs.enums.texts.paragraph21" text="Upload Media"></m-text>
    </div>
    <div a-style display=flex brBottom="1px solid #e4e4e4" gap=2.4 shadow="hov:0 3px 0 0 #f1f1f182" mb=2.4 cursor=pointer>
        <ng-container [ngTemplateOutlet]=menu [ngTemplateOutletContext]="{pass_data:{menu:'Upload', editable: true}}"></ng-container>
        <ng-container [ngTemplateOutlet]=menu [ngTemplateOutletContext]="{pass_data:{menu:'Albums', editable: true}}"></ng-container>
        <ng-container [ngTemplateOutlet]=menu [ngTemplateOutletContext]="{pass_data:{menu:'Tagged Photos', editable: true}}"></ng-container>
    </div>
    <!-- <div a-style p=2.4 brRadius=0.6 mb=2.4 br="1px solid #e4e4e4" align=center> -->

    <div h="100%" a-style p=2.4 brRadius=0.6 mb=2.4 br="2px dashed #63a0f5a8" minWidth=15% flexDirection=column justify="space-between" align=center justify=center display=flex>
        <img a-style width="100%" height="auto" id="myimage" brRadius=0.6 [src]="url? url : value? 'https://new-api.mesbro.com/uploads/'+value : ''" crossorigin="anonymous" #croppr alt="" a-style srcset="">
        <!-- https://new-api.mesbro.com/uploads/99/99/99/99/99/99/67.jpg -->
        <div a-style mt=2.4 mb=2.4>
            <m-text [type]="gs.enums.texts.paragraph114" text="Drag a photo here" color="#b2b2b2" fs="1.6"></m-text>
            <m-text [type]="gs.enums.texts.paragraph46" text="Or, if you prefer..." smt=1 mb=0.5></m-text>
            <m-button [types]="gs.enums.buttons.button81" px=1 text="Select a photo from your computer" (click)="file_input.click()" shadow="hov:0px 5px 5px 0px #b2b2b2"></m-button>
            <!-- <m-button [types]="gs.enums.buttons.button18" px=1 text="Select a photo from your computer" (click)="file_input.click()" shadow="hov:0px 5px 5px 0px #b2b2b2"></m-button> -->

        </div>
    </div>
    <!-- </div> -->

    <div a-style display=flex gap=2.4>
        <m-button [types]="gs.enums.buttons.button18" px=1 text="Upload Photo" (click)="upload_pic(file)" shadow="hov:0px 5px 5px 0px #b2b2b2"></m-button>
        <!-- <m-button [types]="gs.enums.buttons.button81" px=1 color="#333333" bg="#ffffff" text="Cancel" (click)="cancel.emit()" br="1px solid #b2b2b2" br="1px solid rgba(228, 228, 228, 0.47)"></m-button> -->
        <m-button [types]="gs.enums.buttons.button81" px=1 color="#333333" bg="#ffffff" text="Cancel" (click)="this.modal.closeModal()"  br="1px solid #b2b2b2" br="1px solid rgba(228, 228, 228, 0.47)"></m-button>
        <!-- <m-image-croppr *ngIf="image.is_visible" [value]="multiple? '' : (value? value : '')  " [aspect_ratio]="aspect_ratio" (cancel)="image.closeModal()" (file_upload)="image.closeModal(); set_image($event)"></m-image-croppr> -->
        <!-- #ddd9d8 -->
    </div>
    <input #file_input type="file" name="" (change)="upload_event($event)" hidden id="">
</div>

<ng-template #menu let-pass_data=pass_data>
    <div a-style brBottom="1px solid #e4e4e4">
        <m-text [type]="gs.enums.texts.paragraph39" [text]="pass_data.menu" mb=0.5 a-style brBottom="1px solid #e4e4e4"></m-text>
        <!-- <m-text [type]="gs.enums.texts.paragraph39" [text]="pass_data.menu" mb=0.5 a-style brBottom="1px solid #e4e4e4"  [style.fontWeight]="pass_data.menu=='Upload' ? '400' : '600'" (click)="pass_data.editable"></m-text> -->
    </div>
</ng-template>