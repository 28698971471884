import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'm-search',
  templateUrl: './search.component.html',
  styles: [
  ]
})
export class SearchComponent implements OnInit {
  filter = false;
  isVisible: boolean = false;
  listener: any;
  constructor(public renderer: Renderer2) {
    this.listener = this.renderer.listen('document', 'keyup', (event) => {
      if (event && event.key == 'Escape') {
        this.closeModal()
      }
    })
  }

  ngOnInit() {

  }

  openModal() {
    this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
  }

  toggleModal() {
    this.isVisible = !this.isVisible;
  }
}
