import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any, order?: string): any {

    if (order == 'chat-ascending') {
      let return_array = value.sort((a: any, b: any) => {
        let value1: string = <string>(a?.meta?.activity?.updated_at || a?.meta?.activity?.created_at) + '';
        let value2: string = <string>(b?.meta?.activity?.updated_at || b?.meta?.activity?.created_at) + '';
        return value2.localeCompare(value1) ? -1 : 1
      })
      return return_array
    } else if (order == 'product-type-index') {
      let return_array = value.sort((a: any, b: any) => {
        let value1: string = a.value?.value?.index || 0;
        let value2: string = b.value?.value?.index || 0;
        return value2 > value1 ? -1 : 1
      })
      return return_array;
    }
  }

}
