import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUser } from 'src/mesbro/applications/idm/interfaces/i-user';
import { IStructure } from 'src/mesbro/modules/shared/interfaces/IType';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-generic-category',
  templateUrl: './generic-category.component.html',
  styles: [
  ]
})
export class GenericCategoryComponent implements OnInit {

  @Input() background = ''
  search_type = '';
  search_term = '';
  user: IUser | undefined;

  countryList = [
    { country: 'India', flag: 'india' },
    { country: 'Australia', flag: 'australia' },
    { country: 'Bhutan', flag: 'bhutan' },
    { country: 'Brazil', flag: 'brazil' },
    { country: 'China', flag: 'china' },
    { country: 'Colombia', flag: 'colombia' },
    { country: 'Cuba', flag: 'cuba' },
    { country: 'Denmark', flag: 'denmark' },
    { country: 'Egypt', flag: 'egypt' },
    { country: 'Indonesia', flag: 'indonesia' },
    { country: 'Afghanistan', flag: 'afghanistan' },
    { country: 'Iraq', flag: 'iraq' },
    { country: 'Israel', flag: 'israel' },
    { country: 'Japan', flag: 'japan' },
    { country: 'Kenya', flag: 'kenya' },
    { country: 'Maldives', flag: 'maldives' },
    { country: 'Mexico', flag: 'mexico' },
    { country: 'Nepal', flag: 'nepal' },
    { country: 'Oman', flag: 'oman' },
    { country: 'Pakistan', flag: 'pakistan' },
    { country: 'Sweden', flag: 'sweden' },
  ];

  primary = ['a', 'c', 's']
  list = {
    Primary: <any[]>[],
    Secondary: <any[]>[],
    Tertiary: <any[]>[],
    Type: <any[]>[]
  };

  queryParams = {
    _id: <string>'',
    application: <string>'',
    step: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'generic_category'>'Primary',
    parent_id: ''
  };

  public selected: any = {
    Primary: <IStructure | undefined>undefined,
    Secondary: <IStructure | undefined>undefined,
    Tertiary: <IStructure | undefined>undefined,
    Type: <IStructure | undefined>undefined,
  };


  steps = ['Primary', 'Secondary', 'Tertiary', 'Type', 'generic_category'];
  structure: IStructure | undefined = undefined;
  constructor(public gs: GlobalService, public ar: ActivatedRoute) {

  }

  async ngOnInit() {
    this.ar.queryParams.subscribe((params: any) => {
      this.queryParams = { ...params };
      if (this.first_time) {
        if (!this.queryParams.parent_id) {
          this.redirectToStep('Primary');
          this.get_list();
        } else {
          this.selected.Primary = this.gs.variables.temp?.[this.queryParams.parent_id]
          if (!this.selected.Primary) {
            this.redirectToStep('Primary');
            return;
          }
          this.get_list();
        }
      }
      this.first_time = false;
    })

  }
  get currentStepIndex() {
    return this.steps.findIndex(step => step == this.queryParams.step);
  }
  get previousStep() {
    return <'Primary' | "Secondary" | "Tertiary" | "Type">this.steps[this.currentStepIndex - 1];
  }
  get nextStep() {
    return <"Primary" | "Secondary" | "Tertiary" | "Type" | "generic_category">this.steps[this.currentStepIndex + 1];
  }
  first_time = true;
  deleteNextSelectionAndLists() {
    for (let i = this.currentStepIndex; i < this.steps.length - 1; i++) {
      let step = <"Primary" | "Secondary" | "Tertiary" | "Type">this.steps[i];
      this.selected[step] = undefined;
    }
  }
  async redirectToNextStep() {
    this.redirectToStep(this.nextStep);
    if (this.queryParams.step != 'generic_category') {
      this.get_list();
    } else {
      this.gs.router.navigateByUrl('all-application-list?application='+this.queryParams.application+'&type=product&type_id=' + this.selected?.Type?._id)
    }
    this.search_term = '';
  }

  redirectToPreviousStep() {
    this.redirectToStep(this.previousStep);
  }

  redirectToStep(step: 'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'generic_category') {
    // this.queryParams.parent_id = '';
    this.queryParams.step = step;
    this.queryParams.application = this.queryParams.application || 'Product';
    this.gs.router.navigate([], { queryParams: this.queryParams });
  }


  async get_list() {
    if (this.queryParams.step == 'generic_category') {
      return;
    }
    // this.selected[this.queryParams.step] = undefined
    console.log(this.queryParams)
    let body: any = {
      'parents.parent._id': this.queryParams.parent_id || undefined,
      'sub_type': this.queryParams.step,
      application : 'Product'
    };
    let action = 'list';
    let component = 'structure';
    let list: any[] = (await this.gs.hps.post('generic', component, action, body, 2)).list;
    this.list[this.queryParams.step] = list;
  }

  select(listType: 'Primary' | 'Secondary' | 'Tertiary' | 'Type', item: any) {
    console.log(item)
    this.queryParams.parent_id = item._id
    this.deleteNextSelectionAndLists();
    delete item.meta;
    this.selected[listType] = item;
    this.redirectToNextStep();
  }
}
