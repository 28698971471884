import { application, response } from 'express';
import { GlobalService } from 'src/mesbro/services/global.service';
import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'm-report-bug',
  templateUrl: './report-bug.component.html',
  styles: [
  ]
})
export class ReportBugComponent implements OnInit {
  @Input() modal:any;
  list = {
    list: [],
    count: 0
  }

  params = {
    type: ''
  }
  @Input() multiple = false
  value: any;
  @Input() aspect_ratio = 1
  application = 'Generic';
  component = 'Feedback';

  list_url = `/dashboard/${this.application}/${this.component}/list`;


  form: FormGroup | undefined;

  textarea = document.querySelector("textarea");
  @Output() cancel = new EventEmitter();

  first:boolean=true

  constructor( public gs:GlobalService , public fb: FormBuilder,public ar: ActivatedRoute ) { }

  ngOnInit(): void {
    this.initialize_form();
    this.ar.queryParams.subscribe((params: any) => {
      this.params = params;
    })
  }

  initialize_form() {
    this.form = this.fb.group({
      application: this.application,
      component: this.component,
      titles: [null, this.gs.form_validations.required('Title')],
      description: [null],
      original_name:[null],
     });
   }

  async report_bug(){
    console.log('madhuri');
    this.form?.markAllAsTouched()
    let body = this.form?.value;
    // body.type = this.params.type;
    body.application = 'Generic';
    body.component = 'Feedback';
    // body.type = 'bug';
    if(this.form?.invalid){
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.gs.toast('Bug Report ','Submitted Successfully')
      this.form?.reset()
      this.modal.closeModal();
    }
  }
 

  async suggest_feature(){
    console.log('gorakh');
    this.form?.markAllAsTouched()
    let body = this.form?.value;
    body.application = 'Generic';
    body.component = 'Feedback';
    body.type = 'feature';
    if(this.form?.invalid){
      let response = await this.gs.hps.post('generic', 'data', 'add', body);
      this.form?.reset()
      this.modal.closeModal();
    }
  }

}
