import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-emoji',
  templateUrl: './emoji.component.html',
  styles: [
  ]
})
export class EmojiComponent implements OnInit {

  @Input() isVisible: boolean = false;
  @Input() position: string = '';
  @Input() left: string = '';
  @Input() top: string = '';
  @Input() modal: any;
  @Input() bottom: any;
  @Input() transform: any;
  @Input() madhuri: any;
response:any




  listener : any;
  constructor(public renderer: Renderer2, public gs: GlobalService) {
    this.listener = this.renderer.listen('document', 'keyup', (event) => {
      if (event && event.key == 'Escape') {
        this.closeModal()
      }
    })
   }

  ngOnInit(): void {
   
  }
  closeModal() {
    this.isVisible = false;
  }

  async feedback() {

    // let response = await this.gs.hps.post('https://emoji-api.com/emojis?access_key=123412341234' );
  }

  
}




