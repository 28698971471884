<div
    *ngIf="queryParams.type=='favourite products' || queryParams.type=='save products' || queryParams.type=='my-organization' || queryParams.type=='my-products' ">
    <div bg=white a-style mx=2.4 mt=2.4>
        <!-- <m-banner image="../assets/images/assets/product/bussiness_banner.png" h="36.1"></m-banner> -->
        <div a-style brRadius="0.6" br="1px solid #E4E4E4" bgUrl="../assets/images/assets/Plenux-Effects.gif"
            bgRepeat="none" bgSize="cover" display=flex align=center flexDirection=column justify="center"
            align="center">
            <img src="../assets/images/assets/star_.gif" a-style h=8.2 w=8.2 mt="2" mb="2.2"
                *ngIf="queryParams.type=='favourite products'">
            <img src="../assets/images/assets/Save-2-Tranparent.gif" a-style h=8.2 w=8.2 mt="2" mb="2.2"
                *ngIf="queryParams.type=='save products'">
            <img src="../assets/images/assets/My-org-tranparent-2.gif" a-style h=8.2 w=8.2 mt="2" mb="2.2"
                *ngIf="queryParams.type=='my-organization'">
            <img src="../assets/images/assets/My-Product-tranperant.gif" a-style h=8.2 w=8.2 mt="2" mb="2.2"
                *ngIf="queryParams.type=='my-products'">
            <!-- <m-text [type]="gs.enums.texts.paragraph18" text="{{queryParams.type  | case : 'capital'}}" mb="1.9"></m-text> -->
            <p a-style fs="2.4" fw="400" color="#FFFFFF" mb="1.9"
                *ngIf="queryParams.type=='my-organization' || queryParams.type=='my-products'">
                {{queryParams.type | case : 'capital'}}</p>
            <p a-style fs="2.4" fw="400" color="#FFFFFF" mb="1.9"
                *ngIf="queryParams.type=='favourite products' ||  queryParams.type=='save products'">My
                {{queryParams.type | case : 'capital'}}</p>
        </div>
    </div>
    <div a-style px=2.4>
        <!-- pb=0 -->
        <div *ngIf="queryParams.type=='save products'" a-style display="flex" gap="1.6" align="center">
            <m-banner type="main"></m-banner>

        </div>
        <!-- p=2.4 -->
        <!-- <p a-style fs="3.2" text="poonam"></p> -->
        <!-- <m-banner type="main"></m-banner> -->
        <div *ngIf="queryParams.type=='favourite products'" a-style display="flex" gap="1.6" align="center">
            <!-- p=2.4 -->
            <m-banner type="main"></m-banner>
        </div>
        <div *ngIf="queryParams.type=='my-organization'" mt="2.4" a-style display="flex" gap="2.4" align="center">
            <!-- <m-banner type="main"></m-banner> -->
        </div>
        <ng-container [ngTemplateOutlet]="cards"></ng-container>
    </div>
</div>

<div *ngIf="queryParams.type=='my-products'">
    <ng-container *ngFor="let item of org_list?.list;let i=index">
        <div a-style p="2.4" br="0.5px solid #e4e4e4" brRadius="0.6" m=2.4 mt=0>
            <div a-style display="flex" justify="space-between" align="center" pb=1.5 brBottom="0.5px solid #e4e4e4">
                <div a-style display="flex" align="center" gap=1.6>
                    <p a-style fs=1.4>{{item?.basic_details?.legal_name}}</p>
                </div>
                <m-image src="../assets/images/assets/product/arrow-direction.svg" a-style h="2.5" w="2.5">
                </m-image>
            </div>

                <div a-style a-style display="flex" gap="1.8" mt=1.8 w="calc(100vw - 10rem)" overflowX="scroll"
                    id="scroll">
                    <div a-style w=24.6 br="1px solid #e4e4e4" brRadius="0.6" align=center p=1
                        (click)="gs.router.navigate(['gdashboard','group', 'dashboardDetail'],{queryParams:{id:item._id , type:'product'}});ps.selected_org.next(item); ">
                        <div a-style w=22.2 h=29.4 display=flex justify=center flexDirection=column
                            br="1px solid #e2e2e2" brRadius=0.6 bg="#F5F5F5" align=center>
                            <m-image src="../assets/images/assets/product/my product.svg" a-style mt="6.9" h="14.8"
                                w="14.8">
                            </m-image>
                            <p a-style pb="2.1" fs="2.3" mt="1.9" color="#707070">Add Product</p>
                        </div>
                    </div>
                    <ng-container *ngFor="let product of org_product_list?.list">
                        <div *ngIf="item._id == product?.parents?.group?._id">
                            <m-product-card type="card_1" [detail]="product">
                            </m-product-card>
                        </div>
                    </ng-container>
                </div>


        </div>

    </ng-container>
</div>

<div *ngIf="queryParams.type=='Quote-Now'" a-style p="1.6" brRadius="0.6" br="1px solid #E4E4E4" mx=2.4 mt=2.4
    bgUrl="../assets/images/assets/Plenux-Effects.gif" bgRepeat="none" bgSize="cover" display=flex align=center
    flexDirection=column pt=4 pb=2.4 h="23.5">
    <m-text [type]="gs.enums.texts.paragraph18" text="Quote Now"></m-text>
    <div a-style w=76.6 mt=1.6>
        <m-text [type]="gs.enums.texts.paragraph19"
            text="Often used a lead magnets to capture subscribers, training material makes a business newsletter something your customers look forward to receiving.">
        </m-text>
    </div>
</div>

<!-- <div *ngIf="false">
    <div *ngIf="toggle" a-style overflowY="auto" id='scroll' h="calc(100vh - 22.2rem)">
        <div bg=white a-style mx="2.4">
            <m-banner image="../assets/images/assets/product/bussiness_banner.png" h="36.1"></m-banner>
        </div>
        <div bg=white a-style>
            <div a-style brRadius="0.6">
                 <div a-style display="flex" px="2.4" py="2.4" justify="space-between" brBottom="1px solid #e4e4e4" flexWrap="nowrap;600:wrap">
                    <div a-style display="flex" flexGap="3" align="center" flexWrap="nowrap;600:wrap">
                        <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{passedData:{text:'Product' ,category : 'product' }}"></ng-container>
                        <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{passedData:{text:'My Products' ,category : 'my-products'}}"> </ng-container>
                        <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{passedData:{text:'Saved Products' ,category : 'saved-products'}}"></ng-container>
                        <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{passedData:{text:'Favourite' ,category : 'favourite-products'}}"></ng-container>
                        <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{passedData:{text:'My Organization',category : 'my-organization'}}"> </ng-container>
                    </div>
                    <div a-style display="flex" gap="1" align="center" justify="flex-end" flexWrap="wrap;600:nowrap">
                        <m-image *ngIf="queryParams.type =='product'" a-style h="1.6" w="0.8" src="../assets/images/assets/filter.svg" (click)="filter_results_visible = true;"> </m-image>
                        <m-text *ngIf="queryParams.type =='product'" textWrap="nowrap" [type]="gs.enums.texts.paragraph74" text="Filter Results" (click)="filter_results_visible = true;" a-style display="1000:none"></m-text>
                    </div>
            </div>

                <div a-style w="100%" display="flex" flexWrap="wrap">
                    <div a-style flexGrow=1>
                        <div a-style br="1px solid #E4E4E4" h="auto" brRadius="0.6" mx="2.4" mt="2.4" flexWrap="wrap"
                            pt=2.4 px=2.4>
                            <div a-style display="flex" alignItems="center" justify="space-between"
                                brBottom="1px solid #E4E4E4" pb=1.6>
                                <div *ngIf="queryParams.type=='All'" a-style display="flex" gap="1.6">
                                    <m-image src="../assets/images/assets/all hover.svg" a-style h="2.5" w="2.5"
                                        align="center"></m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="All Products"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='my-products'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/product/parcel.svg" a-style h="2.5" w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="My Products"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='saved-products'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/save hover.svg" a-style h="2.5" w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="Saved Products"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='favourite-products'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/favourite hover.svg" a-style h="2.5" w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="Favourite Products"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='my-organization'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/product/colored_group.svg" a-style h="2.5"
                                        w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="My Organizations"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='My Leads'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/product/leads.svg" a-style h="2.5" w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="My Leads"></m-text>
                                </div>
                                <div *ngIf="queryParams.type=='My Enquiries'" a-style display="flex" gap="1.6"
                                    align="center">
                                    <m-image src="../assets/images/assets/product/enquiry.svg" a-style h="2.5" w="2.5">
                                    </m-image>
                                    <m-text [type]="gs.enums.texts.paragraph26" text="My Enquiries"></m-text>
                                </div>
                                <div>
                                    <m-image *ngIf="queryParams.type!=='my-organization'" title="Filter"
                                        (click)="filter_modal.openModal();" src="../assets/images/assets/filter1.svg"
                                        w="2" h="2"></m-image>
                                </div>
                            </div>
                            <ng-container [ngTemplateOutlet]="cards"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<ng-template #heading let-passedData="passedData">
    <p a-style fs="1.4" [style.color]="queryParams.type==passedData.category? '#1d8ecf' : '#333333'" fw="400"
        cursor="pointer" textWrap="nowrap" (click)="gs.router.navigate([],{queryParams:{type: passedData.category}})">
        {{passedData.text}}</p>
</ng-template>
<!-- <ng-template #cards>
    <m-generic-list #product_list *ngIf="queryParams.type=='product' && custom_filter['product']" [filters]="custom_filter['product'] " component="Product" application="Product" type="card_1" px="2.4" flexWrap="wrap"></m-generic-list>
    <m-generic-list [add_card]="true" mt="2.4" mb="1.2" add_item_text="Create Organization" add_item_link="/dashboard/group/form" [filters]="custom_filter['my-organization'] " *ngIf="queryParams.type=='my-organization' && custom_filter['my-organization']" application="idm" component="group" type="Card_2" px=2.4 gap=2.4 flexWrap=wrap></m-generic-list>
    <m-product-notfound *ngIf="queryParams.type=='productdNotFound'"></m-product-notfound>
    <m-generic-list [add_card]="true" add_item_margin="10.4" mt="2.4" mb="1.2" add_item_text="Add Product" add_item_link="/dashboard/generic/form" [filters]=" custom_filter['my-products'] " *ngIf="queryParams.type=='my-products' && custom_filter['my-products']" component="Product" application="Product" gap="2.4" type="card_1" px="2.4" flexWrap="wrap" display="flex"></m-generic-list>
    <m-generic-list *ngIf="queryParams.type=='saved-products'" mt="1.2" [filters]="custom_filter['saved-products']" card_application=Product card_component="Product" [map_function]="saved_product_map" component="activity" application="generic" type="card_1" px="1.2" flexWrap="wrap"></m-generic-list>
    <m-generic-list [has_query]="queryParams.query" *ngIf="queryParams.type=='All'" [quer] [load_more]=true application="Product" type="card_1" component="Product" size=5 px="1.2" py="1.2" flexWrap="wrap" mb="0">
    </m-generic-list>
    <m-generic-list *ngIf="queryParams.type=='favourite-products'" card_application="Product" mt="1.2" [filters]="custom_filter['favourite-products']" type="favorite_card" [map_function]="favourite_products_map" px="1.2" component="activity" application="generic" flexWrap="wrap" wrap="nowrap" mb="0"></m-generic-list>
    <div *ngIf="details?.count ==0" a-style display=flex flexDirection=column cursor=pointer flexGap=2 align=center justify=center h=100%>
        <img src="https://mesbro.in/assets/images/add-card-image/add-product-card.svg" alt="" srcset="">
    </div>
</ng-template> -->
<ng-template #cards>

    <!-- <m-generic-list #product_list *ngIf="queryParams.type=='favourite-products'" [filters]="custom_filter['product'] " component="Product" application="Product" type="card_1" px="2.4" flexWrap="wrap"></m-generic-list> -->
    <m-generic-list *ngIf="queryParams.type=='my-organization' && custom_filter['my-organization']" [add_card]="true"
        add_item_text="Add Organization" [filters]="custom_filter['my-organization']" add_item_link="/group/gdashboard"
        application="idm" component="group" type="card_4" gap=2.4 flexWrap=wrap ></m-generic-list>


    <m-generic-list [add_card]="true" add_item_text="Add Product"
        *ngIf="queryParams.type=='my-products' && custom_filter['my-products']" [add_card]="true"
        add_item_text="Add Product" [filters]="custom_filter['my-products'] " component="Product" application="Product"
        gap="2.4" type="card_1" py="2.4" flexWrap="wrap" display="flex">
    </m-generic-list>

    <m-generic-list *ngIf="queryParams.type=='save products'" [filters]="custom_filter['save products']"
        [map_function]="saved_product_map" component="activity" application="generic" mb="0" mr=2.4 py="1.83" gap="1.83"
        type="card_1" flexWrap="wrap">
    </m-generic-list>

    <m-generic-list *ngIf="queryParams.type=='favourite products'" [filters]="custom_filter['favourite products']"
        [map_function]="favourite_products_map" component="activity" application="generic" mb="0" mr=2.4 py="1.83"
        gap="1.83" flexWrap="wrap" type="card_1"></m-generic-list>
    <!-- <m-generic-list [has_query]="queryParams.query" *ngIf="queryParams.type=='All'" [quer] [load_more]=true
        application="Product" type="card_1" component="Product" size=5 px="1.2" py="1.2" flexWrap="wrap" mb="0">
    </m-generic-list> -->
    <!-- <m-product-card *ngIf="queryParams.type=='favourite-products'" type="card_3" > </m-product-card> -->
    <div *ngIf="details?.count ==0" a-style display=flex flexDirection=column cursor=pointer flexGap=2 align=center
        justify=center h=100%>
        <img src="https://mesbro.in/assets/images/add-card-image/add-product-card.svg" alt="" srcset="">
    </div>
</ng-template>

<m-modal #filter_modal>
    <m-filter [modal]="filter_modal"></m-filter>
</m-modal>
<div>
    <m-modal #filter width="940px">
        <m-filter></m-filter>
    </m-modal>
</div>