<ng-container [ngTemplateOutlet]="home_page_banner" *ngIf="type=='home_page_banner'"></ng-container>

<ng-container [ngTemplateOutlet]="dream_car" *ngIf="type=='dream_car'"></ng-container>

<ng-container [ngTemplateOutlet]="tyre_type" *ngIf="type=='tyre_type'"></ng-container>

<ng-container [ngTemplateOutlet]="tyre_care" *ngIf="type=='tyre_care'"></ng-container>

<ng-container [ngTemplateOutlet]="vehicle_details_banner" *ngIf="type=='vehicle_details_banner'"></ng-container>


<ng-template #home_page_banner>
  <div a-style p=2.9 pr=3.3 position="relative" h=100% bgUrl="../assets/images/assets/vehicle_banner.png" bgRepeat="none" bgSize="cover" bgPosition="center" brRadius=0.6 display=flex flexDirection=column justify=center align="flex-end">
    <m-vehicle-form type="home_page_form1" zIndex="111" ></m-vehicle-form>
  </div>
</ng-template>

<ng-template #dream_car>
  <div a-style p=1.6 brRadius=0.6 bgRepeat="no-repeat" bgSize="100% 100%" bgPosition="center" bgUrl="../assets/images/assets/best_car_banner.png">
    <m-text [type]="gs.enums.texts.heading2" text="Not Sure, Which Car To Buy?"></m-text>
    <m-text [type]="gs.enums.texts.paragraph12" mt=1.1 mb=1.2 text="Let us help you find the dream car"></m-text>
    <m-text [type]="gs.enums.texts.paragraph23" maxWidth=65% class="lineClamp3" mb=1.7 text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."></m-text>
    <m-button [types]="gs.enums.buttons.button71" text="Show Me Best Car" mt="1617:1.7"></m-button>
  </div>
</ng-template>

<ng-template #tyre_type>
  <div a-style p=1.6 brRadius=0.6 bgRepeat="no-repeat" bgSize="100% 100%" bgPosition="center" bgUrl="../assets/images/assets/vehicle_tyres_banner.png">
    <m-text [type]="gs.enums.texts.heading2" text="Vehicle Tyres"></m-text>
    <m-text [type]="gs.enums.texts.paragraph38" maxWidth=49% class="lineClamp5" mt=1 mb="1.6;830:2" text="Choose from an array of tyres offered by renowned brands like MRF, Michelin, Apollo and many more for your Car. Simply select the model and its variant that you own and you will be presented with the available options. You can also compare various tyres before making your final decision."></m-text>
    <m-button [types]="gs.enums.buttons.button71" text="Choose Your Vehicle" a-style mt="1749:1.5"></m-button>
  </div>
</ng-template>

<ng-template #tyre_care>
    <div a-style p=1.6 brRadius=0.6 bgRepeat="none" bgSize="cover" bgPosition="center" bgUrl="../assets/images/assets/mesbro_care_banner.png">
        <m-text [type]="gs.enums.texts.paragraph16" text="Mesbro Care" mb=1></m-text>
        <m-text [type]="gs.enums.texts.paragraph19" mt=1.2 class="lineClamp4" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr."></m-text>
        <m-button [types]="gs.enums.buttons.button63" text="Tyre Care Reminder" mt="8;1370:6.7;1748:8" br="1px solid white"></m-button>
    </div>
</ng-template> 



<!-- --Vehicle Details Page-- -->
<ng-template #vehicle_details_banner>
  <div a-style brRadius=0.6 br="1px solid #e4e4e4" shadow="0 3px 6px 0 #e4e4e4">
    <div a-style brBottom="1px solid #e4e4e4">
      <div a-style position=relative>
        <m-image [src]="'https://new-api.mesbro.com/uploads/'+(detail?.profile_picture || detail?.gallery[0])" w=100% brTopLeftRadius=0.6 brTopRightRadius=0.6></m-image>
      </div>
      <div a-style display=flex position=relative align="flex-end" w=100%>
        <div a-style br="1px solid #e4e4e4" w=11.4 h=11.4 position=absolute left=2.4 bottom=2 zIndex=100>
          <m-image [src]="'https://new-api.mesbro.com/uploads/'+(detail?.profile_picture || detail?.gallery[0])" w=11.2 h=11.2 > </m-image>
        </div>
          <div a-style w=100% p=1.2 pb=2.2 pr=2 pl=16.1>
            <m-text [type]="gs.enums.texts.paragraph26" [text]="detail?.title"></m-text>
            <div a-style display=flex justify="space-between" mt=1>
              <div a-style display=flex gap=0.4>
                <m-text [type]="gs.enums.texts.paragraph49" color="#ff100d" [text]="detail?.basic_detail?.legal_name"></m-text>
                <!-- https://new-api.mesbro.com/idm/group/list -->
                <m-text [type]="gs.enums.texts.paragraph49" text="| Type :"></m-text>
                <m-text [type]="gs.enums.texts.paragraph49" color="#ff100d" [text]="detail?.basic_detail?.type"></m-text>
              </div>
              <m-text [type]="gs.enums.texts.paragraph49" text="Ex Showroom Price : 0" mb=0.2></m-text>
            </div>
          </div>
        </div>
      </div>
      <div a-style display="flex" justify="space-between" align="center" p="2.4" flexWrap="wrap">
        <m-text [type]="gs.enums.texts.paragraph38" text="{{detail?.meta?.activity?.count?.view? detail?.meta?.activity?.count?.view : 0}} View"></m-text>
        <div a-style display="flex" flexGap=2>
            <m-image *ngFor="let item of vehicle_image" [src]='item.src' [w]="item.width" [h]="item.height"> </m-image>
            <ng-container [ngTemplateOutlet]=like [ngTemplateOutletContext]="{data:{src:gs.enums.images.image40,hover_src:gs.enums.images.image100,text:activities?.count?.like? activities?.count?.like : 0, type : 'like'}}"></ng-container>
            <ng-container [ngTemplateOutlet]=dislike [ngTemplateOutletContext]="{data:{src:gs.enums.images.image41,hover_src:gs.enums.images.image101,text:activities?.count?.dislike? activities?.count?.dislike : 0, type : 'dislike'}}"></ng-container>
            <ng-container [ngTemplateOutlet]=compare [ngTemplateOutletContext]="{data:{src:gs.enums.images.image42,hover_src:gs.enums.images.image76}}"></ng-container>
            <ng-container [ngTemplateOutlet]=bookmark [ngTemplateOutletContext]="{data:{src:gs.enums.images.image43,hover_src:gs.enums.images.image102, type : 'save'}} "></ng-container>
            <ng-container [ngTemplateOutlet]=icons [ngTemplateOutletContext]="{data:{src:'https://mesbro.in/assets/images/print.svg',label : 'print'}}"></ng-container>
            <m-image a-style (click)="qr_code.openModal()" src="https://mesbro.in/assets/images/qr.svg" w=1.7 [title]="QR_title"></m-image>
            <m-image a-style (click)="share_modal.openModal()" src="https://mesbro.in/assets/images/share-grey.svg" w=1.7 [title]="share_title"></m-image>
        </div>
    </div>  
  </div>
</ng-template>
<!-- ---------------- -->

<ng-template #icons let-data=data>
  <div a-style display=flex align=center>
      <m-image src={{data.src}} w=1.7 mr=0.8 (click)="activity.emit(data.type || data.label);" [title]="print"></m-image>
      <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
  </div>
</ng-template>

<m-modal #qr_code width=70>
  <m-bar-code></m-bar-code>
</m-modal>

<m-modal #share_modal>
  <m-share [title]="detail?.title" [url]="'https://new.mesbro.com/generic/detail?_id='+ detail?._id"></m-share>
</m-modal>

<!-- like -->
<ng-template #like let-data=data>
  <div a-style display=flex align=center>
      <m-image [src]="data.src" w=1.7 mr=0.8 (clicked)="toggle(activities,'dislike','like')" [title]="like_title" [hover]="data.hover_src" [count]="activities?.count" count_key="like" [current_value]="activities?.my_activity?.like" (click)="activity.emit(data.type || data.label);"></m-image>
      <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
      <!-- text={{activities?.count?.like}} -->
  </div>
</ng-template>

<!-- dislike -->
<ng-template #dislike let-data=data>
  <div a-style display="flex" align="center">
      <m-image [src]="data.src" w="1.7" mr="0.8" (clicked)="toggle(activities,'like','dislike')" [title]="dislike_title" [hover]="data.hover_src" [count]="activities?.count" count_key="dislike" [current_value]="activities?.my_activity?.dislike" (click)="isVisible_dislike=!isVisible_dislike;activity.emit(data.type || data.label);"></m-image>
      <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
  </div>
</ng-template>

<!-- compare -->
<ng-template #compare let-data=data>
  <div a-style display="flex" align="center">
      <m-image [src]="data.src" w="1.7" mr="0.8" [hover]="data.hover_src" [title]="compare_title" [current_value]="current" (click)="isVisible_compare=!isVisible_compare;add_to_compare()"></m-image>
  </div>
</ng-template>

<!-- bookmark -->
<ng-template #bookmark let-data=data>
  <div a-style display="flex" align="center">
      <m-image [src]="data.src" w="1.7" mr="0.8" [title]="save_title" [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src" [current_value]="activities?.my_activity?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities.my_activity.save?'unsave':data.type || data.label);activities.my_activity.save =!activities.my_activity.save"></m-image>
  </div>
</ng-template>

