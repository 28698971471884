import { KeyValuePipe } from '@angular/common';
import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { listeners } from 'cluster';
import { timingSafeEqual } from 'crypto';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-product-card',
  templateUrl: './product-card.component.html',
  styles: [
  ]
})
export class ProductCardComponent implements OnInit {
  @HostBinding('style.display') display = 'contents'
  @Input() index: any;
  @Input() detail: any;
  deactivateProduct: boolean = false
  // @Input() details: any
  @Input() details: any;
  @Input() type: any;
  @Input() list: any;
  @Input() visible = false;
  @Output() activity = new EventEmitter;
  // @Input() visible = false;
  isVisible: boolean = false;
  isVisible_like: boolean = false;
  isVisible_dislike: boolean = false;
  isVisible_compare: boolean = false;
  isVisible_bookmark: boolean = false;
  isVisible_favorite: boolean = false;
  isVisible_share: boolean = false;

  default_image: any = '../assets/images/assets/My-Product-tranperant.gif'
  card1_width: any

  activities: any = {
    save: false,
    favourite_title: false,

  }
  
  // @Input() activities: any;
  rating = 0;
  flag_title = 'India';
  like_title = 'Like';
  compare_title = 'Add To Compare';
  save_title = 'Saved Product';
  share_title = 'Share Product';
  favourite_title = 'Favourite Product';
  message_title = 'Message Title';
  call_title = 'Call';
  icon: boolean = false
  color: boolean = false
  @Input() shadow: string = ""
  hovered = false;
  _id: any;
  @Output() delete = new EventEmitter();
  @Output() addProduct = new EventEmitter<any>();
  params: any;
  viewCount: any
  // @Input() addedProduct: Boolean = false;


  // this.addProduct.emit(this.detail);
  goto_saved_list() {
    this.addProduct.emit(this.detail);
  }
  @Input() hover: any = undefined;
  mouse_over: boolean = false;
  current: boolean = false;
  compare_object: any | undefined = this.gs.localStorageGet('compare-items');
  compare_count: any | undefined
  product: any
  first_product: any
  @Input() ratings: any
  constructor(public ar: ActivatedRoute, public gs: GlobalService) {
  }

  ngOnInit(): void {
    this.current = Object?.keys(this.gs.localStorageGet('compare-items') || {}).includes(this.details?.parent?._id)
    this.ar.queryParams.subscribe((param: any) => {
      this.params = param
    })

  }

  redirect_to_detail(item: any) {
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id,type:'productInfo' } });
  }

  redirect_to_detail_scrap(item: any) {
    this.gs.router.navigate(['scrap', 'detail'], { queryParams: { _id: item._id } });
  }
  redirect_to_form(item: any) {
    this.gs.router.navigate(['generic', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', application: 'Product', subheading: "Basic Details" } });
  }
  redirect_to_scrap_form(item: any) {
    this.gs.router.navigate(['scrap', 'form'], { queryParams: { _id: item, edit: true, step: 'Form', subheading: "Basic Details" } });
  }
  redirect_to_details(product: any, organization: any) {
    // console.log(product)
    // console.log(organization)
    this.gs.router.navigate(['gdashboard', 'group', 'product-details'], { queryParams: { _id: product, org_id: organization, type: 'product' } });
  }

  application = 'Product';
  component = 'Product';

  product_list = {
    list: [],
    count: 0,

  }
  @Output() deleted = new EventEmitter()

  org_product_list: any = {
    list: [],
    count: 0,
  }

  queryParams: any = {
    _id: '',
    type: '',
    hid:''
  }
  add_to_compare() {
    this.compare_count = Object.keys(this.compare_object)?.length;
    let item = Object.values(this.gs.localStorageGet('compare-items'))
    for (let i = 0; i <= item.length - 1; i++) {
      this.product = item[0]
      this.first_product = this.product?.parents?.parent?._id
      console.log(item[0]);
    }
    console.log(this.compare_count);

    if (!this.detail) {
      return;
    }
    if (this.compare_count == 0 || this.detail?.parents?.parent?._id == this.first_product) {
      let current = this.gs.localStorageGet('compare-items') || {};
      current[this.detail._id] = this.detail;
      this.gs.localStorageSet('compare-items', current)
      this.gs.toast('Compare Product', 'Product was added to compare list', 'success', 3)
      this.current = !this.current
      this.gs.$refresh_compare.next()
    } else {
      this.gs.toast('Compare Product', 'Please select product of same Type', 'warning', 3);
      // console.log('warninggggggggggggggggggg');
    }

  }

  toggle(activities: any, toggle_key: string) {
    if (activities?.my_activity?.[toggle_key]) {
      activities.count[toggle_key] = activities?.count[toggle_key] - 1
      activities.my_activity[toggle_key] = false
    }
  }
  fav_src: any
  async add_activity(detail: any, activity: any) {
    let body = {
      application: "Product",
      component: "Product",
      activity: activity,
      'parents.data': detail
    }

    // console.log(body);
    let response = await this.gs.hps.post('generic', 'activity', 'add', body)
    this.gs.toast('Add Favourite', ' Successfully')
  }
  async deleteProduct(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('business', 'products', 'delete-product', body)
    this.deleted.emit('')
    this.gs.toast('Product', ' Successfully')
  }
  async deleteScrap(id: any) {
    let body = {
      "key": [
        id
      ]
    }
    let response = await this.gs.hps.post('scrap', 'scraps', 'delete-scrap', body)
    this.deleted.emit('')
    this.gs.toast('Scarp', ' Successfully')
  }
  product_activity: any

  async getViewCount(id: any) {
    try {
      let body = {
        "product_Id": id
      }
      this.viewCount = await this.gs.hps.post('business', 'products', 'view', body)
    } catch (error) {

    }
  }

  async getViewCountForScrap(id: any) {
    try {
      let body = {
        "scrap_id": id
      }
      this.viewCount = await this.gs.hps.post('scrap', 'scraps', 'view', body)
    } catch (error) {

    }
  }
  toggleProduct=false
  value:any
  async activeDeactiveProduct(id: any) {
    let body = {
      "key": id,
      "orgId": this.params?._id
    }
    let response = await this.gs.hps.post('business', 'products', 'activate-deactivate', body)
    console.log(this.toggleProduct);
    let detail = await this.gs.hps.get('business', 'products', id)
    let keys = Object.keys(detail?.activity?.deactivate)
    this.value = Object.values(detail?.activity?.deactivate)
    if (keys[0]==this.gs.user.users.activeUser?._id && this.value==true)  {
      this.toggleProduct=true
      console.log('mahduri');
    }else{
      this.toggleProduct=false
    }
    
  }
}