import { Injectable } from '@angular/core';
import { NewChatService } from '../applications/new-chat/service/new-chat.service';
import { GlobalService } from './global.service';
let ioC = require("socket.io-client");

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket: any;
  madhuri: any;
  id: any;
  email: any

  setup_socket() {
    // this.socket = ioC("http://new-api.mesbro.com:4000?token=" + this.gs.user.users.activeUser?._id, { transports: ["websocket"] });
    this.socket = ioC("http://localhost:4000?token=" + this.gs.user.users.activeUser?._id, { transports: ["websocket"] });
    this.socket.on("connect", () => {
      // console.log(this.socket.id); // x8WIv7-mJelg7on_ALbx
      // console.log(this.socket);
    });
    this.socket.on("disconnect", () => {
      // console.log(this.socket.id); // undefined
    });
    this.socket.on("notification", (args: any) => {
      // console.log("Notification", typeof args, args);
      // console.log(args, "socket.................");
      this.gs.notifications$.next(args)
    });

    this.socket.on("data", (args: any) => {
      // console.log("Data", typeof args, args);
    });
    this.socket.on("mynewchat", (args:any) => {
      // console.log(args);
    });
    this.socket.io.on("mynewchat", (args:any) => {
      // console.log(args);
    });

    this.socket.io.on("reconnect", () => {
      // console.log("Recoonect");
    });

    this.socket.on("notification", (arg: any) => {
      // console.log(arg, "llllllllllllllllllllll"); // undefined
    });

    this.socket.on("batch-update", (arg: any) => {
      // console.log(arg); // undefined
    });

    this.socket.emit("is-online", (arg: any) => {
      // console.log(this.email); // undefined
    });

    this.socket.emit("is-online", (arg: any) => {
      // console.log(this.email); // undefined
    });
    // this.socket.on("online", (args: any) => {
    //   console.log("online////////////", typeof args, args);
    //   this.gs.user.users$.next(args)
    //   this.socket.emit("message");
    // });
  }
  constructor(public gs: GlobalService, public cs: NewChatService) {
    this.gs.user.users$.subscribe(users => {
      this.id = users;
      if (users?.activeUser) {
        this.setup_socket();
      }
    })
    this.cs.selected_chat.subscribe(details => {
      this.madhuri = details
      let keys = Object.keys(this.madhuri?.members?.users)
      for (let index = 0; index < keys.length; index++) {
        if (this.id.activeUser._id != keys[index]) {
          this.email = this.madhuri.members.users[keys[index]];
          // console.log(this.email);
        }
      }
    })
  }

  sendMessage(info:any){
    this.socket.emit("clientchat", info);

  }

  // web_socket: WebSocket;

  // constructor(public gs: GlobalService, public user: UserService) { }
  // is_connected = false;

  // reconnect_interval = 1;
  // reconnect_attempts = 0;
  // reconnect_interval_instance: any;
  // pong_interval: any = undefined;
  // public connect_socket() {
  //   this.user.users$.subscribe(users => {
  //     try {
  //       let token = users?.activeUser?.token;
  //       if (token) {
  //         this.web_socket = new WebSocket(SERVER_URL + users.activeUser?.token);
  //         clearInterval(this.pong_interval)
  //         this.pong_interval = setInterval(() => {
  //           try {
  //             this.web_socket.send("Pong")
  //           } catch (error) {

  //           }
  //         }, 3000)
  //         this.web_socket.onmessage = this.on_message
  //         this.web_socket.onerror = this.on_error
  //         this.web_socket.onclose = this.on_close
  //         this.web_socket.onopen = this.on_open
  //       }
  //     } catch (error) {
  //       console.log(error.message)
  //     }

  //   })

  // }

  // on_close = (event: any) => {
  //   console.log('Socket was disconnected');
  //   this.is_connected = false;
  //   if (!this.reconnect_interval_instance) {
  //     this.reconnect_interval_instance = setInterval(() => {
  //       console.log('Reconnection attempt made | Remaining attempts ' + this.reconnect_attempts);
  //       this.reconnect_attempts--;
  //       if (this.reconnect_attempts) {
  //         this.connect_socket();
  //       } else {

  //         clearInterval(this.reconnect_interval_instance);
  //         this.reconnect_interval_instance = undefined;
  //       }
  //     }, this.reconnect_interval * 1000)
  //   }
  // }

  // on_open = (event: any) => {
  //   this.is_connected = true;
  //   console.log('Socket was connected');
  //   this.reconnect_attempts = 5;
  //   clearInterval(this.reconnect_interval_instance);
  //   this.reconnect_interval_instance = undefined;
  // }

  // on_message = (event: any) => {
  //   try {
  //     let data_string = event.data;
  //     let data = JSON.parse(data_string)
  //     this.gs.notifications$.next(data);
  //   } catch (error) {

  //   }

  //   // console.log(data)
  //   // this.gs.toast(data.title, data.message, data.type, 3);
  // }

  // on_error = (event: any) => {
  //   console.log('Some error was encountered');
  //   console.log(event);
  // }

}
