import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-rfq-card',
  templateUrl: './rfq-card.component.html',
  styles: [
  ]
})
export class RfqCardComponent implements OnInit {
  // @HostBinding('style.display') display = 'contents'
  @ViewChild('catalogue_modal') catalogue_modal: any;
  @ViewChild('conversation_modal') conversation_modal: any;
  icon: boolean = false

  @Input() detail: any;
  @Input() type: any;
  @Input() index: any;
  @Input() list: any;
  @Input() details: any;
  @Output() delete = new EventEmitter()
  @Output() clicked = new EventEmitter()
  modal_display: boolean = false

  chat_clicked = false
  constructor(public gs: GlobalService, public ar: ActivatedRoute) { }

  action(label: string) {
    if (label == 'Send Catalogue' || label == 'Send Offer'  || label == 'Send Quotation') {
      this.catalogue_modal?.openModal()
    }
  }
  ngOnInit(): void {

  }

  async allot_data() {
    try {
      console.log(this.detail.meta?.alloted)
      if (this.detail.meta?.alloted || this.detail.meta?.roles?.owner?.user[this.gs.user?.users?.activeUser?._id || '']) {
        if (this.detail.meta.roles.owner.user[this.gs.user?.users?.activeUser?._id || '']) {
          console.log('Is Owner, Not Alloted')
        }
        return;
      }
      let body = {
        data_id: this.detail._id,
        "meta.roles.owner.user.603c732fb3b5ba1f75082a47": true,

      }
      let response = await this.gs.hps.post('generic', 'data', 'allot-data', body);
      this.detail.meta.alloted = true;
      this.gs.toast('RFQ Allotment', 'You are alloted to this RFQ')
    } catch (error) {
      console.log(error);
    }
  }
  
  redirect_to_detail(item: any) {
    console.log(item)
    this.gs.router.navigate(['generic', 'detail'], { queryParams: { _id: item._id } });
  }


  queryParams: any = {
    _id: '',
    type: ''
  }


}
