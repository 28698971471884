import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-similar-entities',
  templateUrl: './similar-entities.component.html',
  styles: [
  ]
})
export class SimilarEntitiesComponent implements OnInit {

  @Input() type:string=''
  
  constructor(public gs : GlobalService  ) { }

  ngOnInit(): void {
   
  }

}
