
<!--  new code -->

<div a-style bg="#ffffff" p="2.5" w="37.5" *ngIf="true">
  <div a-style align="left" pb="2.5">
    <!-- <m-text [type]="gs.enums.texts.heading2" align="left" text="Add new folder" mb="2"></m-text> -->
    <p a-style fs="1.6" >Add new folder</p>
  </div>
  <div>
    <form [formGroup]="form">
      <m-form columns="2" [brNone]=false [paddingNone]=false formGroupName="data">
        <m-input formControlName="folder_name" [type]="gs.enums.inputs.text" brBottom=true [capitalize]=false fs="1.3" [uppercases]=false
         placeHolder="Enter folder name" label="Folder name" [columns]="1/2" flexGrow="0" mt="0" px="0" minWidth="32.7" maxWidth="32.7"
          clearIcon="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/cross.svg"  ></m-input>
          <div>
            <div a-style mb="1">
              <p a-style color="#333333" fs="1.3">Description</p>
            </div>
            <div a-style br="1px solid #e4e4e4" brRadius="0.6" p="1" mb="1.6" minWidth="32.7" maxWidth="32.7">
              <textarea br="none" a-style h=15 minWidth="30.7" maxWidth="30.7" label="Description" formControlName="description" placeholder=""></textarea>
            </div>
            <!-- <div a-style mb="1.6">
              <m-input a-style p="0" m="0" [type]="gs.enums.inputs.dropdown" label="Nest folder under" minWidth="100%"
                [columns]="1/4" px="0" flexGrow="0;1024:1" dropdownHeight="fit-content"></m-input>
            </div> -->
            <div a-style display="flex" justify="space-between" align="center">
              <m-button a-style [types]="gs.enums.buttons.button0" cursor="pointer" (click)="this.modal.closeModal()"
                text="Cancel"></m-button>
              <m-button a-style [types]="gs.enums.buttons.button0" cursor="pointer" (click)="submit();success.openModal()" text="Create">
              </m-button>
            </div>
          </div>
      </m-form>
    </form>
  </div>

  <!-- <div a-style mt="2" display="flex" flexGap="1.6" justify="flex-end">
      <button a-style br="none" h="4" color="#707070" bg="#FFFFFF" fs="1.3" brRadius="0.4" cursor="pointer" (click)="this.modal.closeModal()">Cancel</button>
      <button a-style fs="1.3" br="none" color="#FFFFFF" bg="#006BFF" px="2" py="0.7" brRadius="0.4" cursor="pointer" (click)="submit()">Confirm</button>
    </div> -->
</div>


<!-- update folder -->


<m-success-modal #success title="folder Created Successfully"></m-success-modal>