<div a-style bg="fff">
<div a-style mb="2">
    <p a-style color="#333" fs="1.6">Related Searches</p>
</div>
<div a-style display="flex" gap="2.4" flexWrap="wrap">
    <div a-style display="flex" gap="2.4"  *ngFor="let user of [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]" >
        <div a-style brRadius="30px" bg="#f5f5f5" p="1.6" display="flex" gap="2.4" w="35.5" >
        <div>
           <img a-style src="../assets/images/assets/product-search.svg" w="1.7" h="1.7" align="center" a-style>
        </div>
        <div a-style flexGrow="1">
           <p a-style color="#333" fs="1.3">The quick, brown fox jumps</p>
        </div>
        </div>
    </div>
</div>
</div>
