<div a-style p=3>
    <m-text [type]="gs.enums.texts.paragraph12" text="< Sharvani Engineering Corporative Pvt. Ltd." mb=3></m-text>
    <div a-style display=flex flexGap=3 flexWrap=wrap>
        <ng-container [ngTemplateOutlet]="app_card" [ngTemplateOutletContext]="{data:{src:'https://mesbro.in/assets/images/app-icon/product.svg', text:'Product'}}"></ng-container>
        <ng-container [ngTemplateOutlet]="app_card" [ngTemplateOutletContext]="{data:{src:'https://mesbro.in/assets/images/app-icon/service.svg', text:'Service'}}"></ng-container>  
        <ng-container [ngTemplateOutlet]="app_card" [ngTemplateOutletContext]="{data:{src:'https://static.vecteezy.com/system/resources/thumbnails/002/425/432/small/customer-experience-review-icon-vector.jpg', text:'Customer Reviews'}}"></ng-container>  
        <ng-container [ngTemplateOutlet]="app_card" [ngTemplateOutletContext]="{data:{src:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/delete-blue.svg', text:'Delete Organization'}}"></ng-container>  
    </div>
</div>
<ng-template #app_card let-data=data>
    <div a-style br="1px solid #e4e4e4" bg=#ffffff w=37.2 align=center py=2.4>
        <m-image src="{{data.src}}" h=10 w=10></m-image>
        <m-text [type]="gs.enums.texts.paragraph12" text="{{data.text}}" mt=2.4></m-text>
    </div>
</ng-template>
