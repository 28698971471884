<ng-container *ngIf="type =='rfq_card_1' " [ngTemplateOutlet]="rfq_card_1"></ng-container>
<ng-container *ngIf="type =='rfq_card_3' " [ngTemplateOutlet]="rfq_card_3"></ng-container>
<ng-container *ngIf="type=='sent_rfq'" [ngTemplateOutlet]="sent_rfq"></ng-container>
<ng-container *ngIf="type=='received_rfq'" [ngTemplateOutlet]="received_rfq"></ng-container>

<ng-template #rfq_card_1>
    <div a-style w="32%" cursor="pointer" display="flex" br="1px solid #E4E4E4" shadow=" hov:0 3px 6px 0 #f1f1f1" brRadius="0.4" p="1" mt="2.4" mr="2.3">
        <m-image [src]='gs.enums.images.image67' w="17.5" h="17.5"></m-image>
        <div>
            <div a-style display="flex" justify="space-between" mb="1">
                <m-text [type]="gs.enums.texts.paragraph43" text="{{detail?.basic_details?.organization_name}}"></m-text>
                <m-image [src]='gs.enums.images.image2' w="3"></m-image>
            </div>
            <m-text [type]="gs.enums.texts.paragraph35" a-style mb="1" text="Required Quantity : {{detail?.basic_details?.quantity}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph35" a-style mb="1" text="Location : {{detail?.basic_details?.location}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph35" a-style mb="1" text="{{detail?.basic_details?.description}}"></m-text>
            <div a-style display="flex" justify="space-between">
                <m-text [type]="gs.enums.texts.paragraph23" a-style text="1 hour before"></m-text>
                <m-image [src]='gs.enums.images.image68' w="1.8"></m-image>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #rfq_card_2 let-passedData="passedData">
    <m-modal #requestSubmittedModal a-style display="contents">
        <ng-container [ngTemplateOutlet]="requestSubmitted"> </ng-container>
    </m-modal>
    <m-modal #QuoteNow a-style display="contents">
        <ng-container [ngTemplateOutlet]="modelQuoteNow"> </ng-container>
    </m-modal>
    <ng-template #requestSubmitted>
        <div a-style w="67.2" h="29.2" br="1px solid #E4E4E4" align="center" shadow=" hov:0 3px 6px 0 #f1f1f1" px="2.4" py="2.4" brRadius="0.4" *ngIf=false>
            <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style w=3 h=1.9 mb="1.6" mt="2">
            <m-text [type]="gs.enums.texts.heading1" a-style text="Request Submitted!"></m-text>
            <m-text [type]="gs.enums.texts.paragraph1" a-style text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus. "></m-text>
            <m-button align="center" a-style py="0.8" px="2" brRadius="0.4" bg="#006BFF" color="#FFFFFF" text="View Another RFQ's" mt="2.4"></m-button>
        </div>
    </ng-template>
    <ng-template #modelQuoteNow>
        <m-form columns="3" title="Title" labelWeight="600">
            <m-input [type]="gs.enums.inputs.text" label="First Name" [columns]="1/2"></m-input>
            <m-input [columns]="1/2" label="Last Name"></m-input>
            <m-input [columns]="1/2" label="Country"></m-input>
            <m-input [columns]="1/2" label="Organization Name"></m-input>
            <m-input [columns]="0.5" label="phone"></m-input>
            <m-input [columns]="1/2" label="Number"></m-input>
            <m-input [columns]="1/2" label="Business Type"></m-input>
            <m-input [columns]="1/2" label="Your Job Title"></m-input>
            <div a-style display="flex" justify="flex-end" w="66.4" mt="2.4" pr="1">
                <m-button a-style py="0.8" px="1.6" brRadius="0.4" bg="#ffffff" color="#707070" text="Cancel"></m-button>
                <m-button (click)="requestSubmittedModal.openModal()" a-style py="0.8" px="1.6" brRadius="0.4" bg="#006BFF" color="#FFFFFF" text="Submit"></m-button>
            </div>
        </m-form>
    </ng-template>
    <div a-style display="flex" flexGrow="1" p="1" cursor="pointer" maxWidth="100%" br="1px solid lightgray" brRadius="0.4">
        <div a-style w="18" h="18">
            <m-image a-style w="18" h="18" src="https://new-api.mesbro.com/uploads/{{detail.parents?.type?.images?.image}}"> </m-image>
        </div>
        <div a-style align="start" flexGrow=1 ml=1>
            <div a-style display="flex" justify="space-between">
                <p a-style fs="1.3" mb=1 fw="600">{{detail?.basic_details?.organization_name}}</p>
                <m-image a-style w="3" h="2.1" [src]="gs.enums.images.image2"> </m-image>
            </div>
            <m-text [type]="gs.enums.texts.paragraph1" flexGrow=1 mb=1 a-style text="Required Quantity :{{detail?.basic_details?.quantity}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph1" mb=1 a-style text="Location :{{detail?.basic_details?.location}}"></m-text>
            <m-text [type]="gs.enums.texts.paragraph1" mb=2 a-style text="{{detail?.basic_details?.description}}"></m-text>
            <div a-style display="flex" justify="space-between" mt="3.5">
                <m-text [type]="gs.enums.texts.paragraph1" mt=1 a-style text="{{detail?.meta?.activity.updated_at | date :'dd MMM yyyy'}}"></m-text>
                <m-button [types]="gs.enums.buttons.button32" (click)="QuoteNow.openModal()" px="2.9" py="1.3" align="center" br="none" a-style text="Quote Now"></m-button>
            </div>
        </div>
    </div>
</ng-template>










<ng-template #rfq_card_3>
    <!--    My Lead, My enquiries, Visit history Product  card  -->
    <div>
      <div class="MagicScroll" a-style p=1 br="0.5px solid #E4E4E4" brRadius="0.6" bg="#FFFFFF;hov:#333333">
        <div a-style position="relative">
          <m-image [src]="'https://new-api.mesbro.com/uploads/'+detail?.parents?.type?.images?.image" a-style h="21.8" w="21.8" [title]="detail?.title" (click)="redirect_to_detail(detail)" brRadius="0.6"> </m-image>
          <m-image [src]="gs.enums.images.image62" a-style w=2.6 h=1.6 title="India" a-style w=2.5 h=2.5 top=0.8 right="1.2" position="absolute"> </m-image>
          <m-image *ngIf="false" src="../assets/images/assets/product/ad.svg" a-style w=2.6 h=1.6 title="Compare" a-style w=2.5 h=2.5 top=0.5 left="0.9" position="absolute"> </m-image>
        </div>
        <div a-style h=3.6 w=22.2 px="1" textCenter="center" mt=0.5>
          <m-text [type]="gs.enums.texts.paragraph110" textWrap="wrap" textCenter="center" a-style [text]="detail?.parents?.type?.title" w="20" class="lineClamp" (click)="redirect_to_detail(detail)" color="#8a8383;hov:#1d8ecf"></m-text>
        </div>
        <div a-style h=1.6 w=20.8 textCenter="center" mb="0.5">
          <m-text [type]="gs.enums.texts.paragraph131" textCenter="center" a-style w=13 ellipsis=true text="INR  {{detail?.basic_details?.price_from ? detail?.basic_details?.price_from : 0}} - {{detail?.basic_details?.price_to ? detail?.basic_details?.price_to : 0}}" (click)="redirect_to_detail(detail)" color="#c87065;hov:#C8A165"></m-text>
        </div>
        <hr color="#e4e4e4" size="0.5">
        <div (mouseover)="icon=true" (mouseleave)="icon=false">
          <div a-style mt=1 h=1.8 textCenter=center align="center" gap="0.3" justify="center" display="flex">
            <m-image src="../assets/images/assets/verified_user.svg"></m-image>
            <m-text [type]="gs.enums.texts.paragraph110" text="{{detail?.basic_details?.trade_terms}}"></m-text>
          </div>
  
          <div a-style h=1.6 display="flex" justify=center gap=0.4>
            <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true" [text]="detail?.data?.location?.city " (click)="redirect_to_detail(detail)" textCenter=center></m-text>,
            <m-text [type]="gs.enums.texts.paragraph10" ellipsis="true" [text]="detail?.data?.location?.region" (click)="redirect_to_detail(detail)" textCenter=center></m-text>
          </div>
        </div>
      </div>
      <div a-style mt="0.6" display="flex" justify="space-between" align="center">
        <div a-style display="flex" gap=1>
          <m-image src="../assets\images\assets\eye1.svg" a-style w=17px h=13px mt="0.2"></m-image>
          <p a-style color="#707070" fs="11px" h=12px>22 sep 2021 00:00AM</p>
        </div>
        <div *ngIf="queryParams.type!='Closed'">
          <button a-style px="0.7" py="0.3" br="#F35325" bg="#FFE5DE" brRadius="0.6">Close</button>
        </div>
        <div *ngIf="queryParams.type=='Closed'">
            <m-image src="../assets\images\assets\delete1.svg" a-style w=16px h=16px></m-image>
          </div>
      </div>
    </div>
  </ng-template>



















<ng-template #sent_rfq>
    <div a-style br="1px solid #e4e4e4" flexGrow="1" overflowX="auto;460:scroll;320:scroll" brRadius="0.6" mb=2 bg="#ffffff" shadow=" hov:0 3px 6px 0 #f1f1f1" cursor="pointer">
        <div a-style display=flex p=1>
            <div>
                <m-image src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/22/49/602cecd2c76ddbd65d63f499/6035ffebbf7db17c5dfd9e5f.png" h="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5" w="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5"></m-image>
            </div>
            <table a-style br=none h="50%">
                <tr>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="{{detail?.basic_details?.subject}}"></m-text>
                    </th>
                    <!-- <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Requested By"></m-text>
                    </th> -->
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Required Quantity"></m-text>
                    </th>
                    <th a-style minWidth="480:17">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Requested Date & Time"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Mobile Number"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Email ID"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38" text="Location"></m-text>
                    </th>
                    <th a-style >
                        <m-image src="https://mesbro.in/assets/images/delete-faded.svg" h="1.6" w="1.2"></m-image>
                    </th>
                </tr>
                <tr>
                    <th a-style display="none">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.basic_details?.location}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.basic_details?.description}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.basic_details?.quantity}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.meta?.activity.updated_at | date :'dd MMM yyyy'}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.parents?.user?.contact_details?.mobile_number_primary}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.parents?.user?.contact_details?.email_address_secondary}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10" text="{{detail?.basic_details?.location}}"></m-text>
                    </th>

                </tr>
            </table>
        </div>
        <div a-style p=1 display=flex justify="space-between" brTop="1px solid #e4e4e4" align=center>
            <!-- <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg', label:detail?.basic_details?.organization_name}}"></ng-container> -->
            <div a-style display="flex" mr=mr cursor=pointer (click)="allot_data(); action(detail?.basic_details?.organization_name)">
                <m-image src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" w=1.9 h=1.9 mr=0.8></m-image>
                <m-text [type]="gs.enums.texts.paragraph38 " text={{detail?.basic_details?.organization_name}} ellipsis="1280:true" lineClamp="1280:2" minWidth="1280:15.7"></m-text>
            </div>
            <div a-style display="flex">
                <!-- <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/72.svg', label:'Send Catalogue',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/71.svg', label:'Send Offer',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/57.svg', label:'Send Quotation',mr:'2.4'}}"></ng-container> -->
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/chat.svg', label:'Chat',mr:'2.4'}}"></ng-container>
                <!-- <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/mail.svg', label:'Mail',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/70.svg', label:'SMS'}}"></ng-container> -->
            </div>
        </div>
    </div>
</ng-template>
<ng-template #received_rfq>
   
    <div a-style br="1px solid #e4e4e4" flexGrow="1" overflowX="auto;460:scroll" brRadius="0.6" shadow=" hov:0 3px 6px 0 #f1f1f1" mb=2 bg="#ffffff" cursor="pointer" shadow="hov:0 3px 6px 0 #b2b2b2">
        <div a-style display=flex p=1 gap=1>
            <div>
                <m-image src="https://new-api.mesbro.com/uploads/{{detail.parents?.type?.images?.image}}" h="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5" w="12.5;1440:11.5;1336:10.5;1280:9.5;1024:8.5;786:7.5"></m-image>
            </div>
            <table a-style br=none>
                <tr>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="{{detail?.basic_details?.subject}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Requested By"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Required Quantity"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Requested Date & Time"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Mobile Number"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Email ID"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph38 " text="Location"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-image (clicked)="delete.emit('')" src="https://mesbro.in/assets/images/delete-faded.svg" h="1.6" w="1.2"></m-image>
                    </th>
                </tr>
                <tr>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.basic_details?.description}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parent?.user?.personal_details?.first_name}}{{detail?.parent?.user?.personal_details?.last_name}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.basic_details?.unit}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.meta?.activity.updated_at | date :'dd MMM yyyy'}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parent?.user?.contact_details?.mobile_number_primary}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.parent?.user?.contact_details?.email_address_primary}}"></m-text>
                    </th>
                    <th a-style minWidth="480:12">
                        <m-text [type]="gs.enums.texts.paragraph10 " text="{{detail?.basic_details?.location}}"></m-text>
                    </th>
                </tr>
            </table>
        </div>
        <div a-style p=1 display=flex justify="space-between" brTop="1px solid #e4e4e4">
            <!-- <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg', label: detail?.basic_details?.organization_name}}"></ng-container> -->
            <div a-style display="flex" mr=mr cursor=pointer (click)="allot_data(); action(detail?.basic_details?.organization_name)">
                <m-image src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" w=1.9 h=1.9 mr=0.8></m-image>
                <m-text [type]="gs.enums.texts.paragraph38 " text={{detail?.basic_details?.organization_name}} ellipsis="1280:true" lineClamp="1280:2" minWidth="1280:15.7"></m-text>
            </div>
            <div a-style display="flex">
<!--     
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/72.svg', label:'Send Catalogue',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/71.svg', label:'Send Offer',mr:'2.4'}}"></ng-container>
                <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/57.svg', label:'Send Quotation',mr:'2.4'}}"></ng-container> -->
                <!-- <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/72.svg', label:'Send Catalogue',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/71.svg', label:'Send Offer',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/57.svg', label:'Send Quotation',mr:'2.4'}}"></ng-container>
                </div> -->
                <div a-style routerLink="/chat/chat-details">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/chat.svg', label:'Chat',mr:'2.4'}}"></ng-container>
                </div>
                <!-- <div a-style routerLink="/mail/home">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://mesbro.in/assets/images/app-icon/mail.svg', label:'Mail',mr:'2.4'}}"></ng-container>
                </div>
                <div a-style routerLink="/mail/home">
                    <ng-container [ngTemplateOutlet]="social" [ngTemplateOutletContext]="{media:{src:'https://new-api.mesbro.com/uploads/assets/99/99/99/99/99/99/70.svg', label:'SMS'}}"></ng-container> 
                </div> -->
            </div>
        </div>
    </div>
</ng-template>

<ng-template #social let-media="media ">
    <div a-style display="flex" mr={{media.mr}} cursor=pointer (click)="allot_data(); action(media.label)">
        <m-image src={{media.src}} w=1.9 h=1.9 mr=0.8></m-image>
        <!-- <div a-style display="1040:none"> -->
            <m-text [type]="gs.enums.texts.paragraph38 " text={{media.label}}></m-text>
        <!-- </div> -->
    </div>
</ng-template>

<m-modal #catalogue_modal>
    <m-send-catalogue></m-send-catalogue>
</m-modal>