<ng-container *ngIf="type =='shortlink_card_1'" [ngTemplateOutlet]="shortlink_card_1"></ng-container>
<ng-container *ngIf="type =='campaign_card'" [ngTemplateOutlet]="campaign_card"></ng-container>
<ng-template #shortlink_card_1>
    <div a-style br="1px solid #E4E4E4" brRadius="0.6" >
        <div a-style brBottom="1px solid #E4E4E4" p="1.6" gap="1" display="flex" justify="space-between" w="43.7">
            <div a-style maxWidth="30">
                <a a-style textDecoration="none" fs="2" ellipsis=true textWrap="nowrap" (click)="get_list();activities.save?gs.action('unsave',detail):gs.action('save',detail)" color="#333333" m="0" [href]="'https://new-api.mesbro.com/'+detail?.unique_key" target="_blank">{{detail?.shortUrl}}</a>
            <p a-style color=" #05A6F0" fs="1.3">Campaign Name</p>
                <p a-style color="#333333" fs="1.3" mt="1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque viverra sit amet velit vel fringilla.</p>
            </div>
            <div a-style display="flex" alignItems="center" justify="center" w="9" h="9" brRadius="0.6" br="1px solid #05A6F0">
                <div>
                    <p a-style color="#707070" fs="2.4">10000</p>
                    <p a-style color="#707070" fs="2">Clicks </p>
                </div>
            </div>
        </div>
        <div a-style p="1" brTop="#E4E4E4">
            <p a-style color="#333333" fs="2">This is my short link </p>
            <div a-style display="flex" gap="1.6" w="auto">
                <div a-style bgSize=cover w="6.5" h="5.5" mt="1" [bgUrl]="'https://new-api.mesbro.com/uploads/'+detail?.meta?.activity?.updated_by?.profile_picture" br="1px solid #e2e2e2" brRadius="50%"> </div>
                <div a-style w="-webkit-fill-available" my="1" cursor="pointer">
                    <div>
                        <p a-style color="#767676" fs="1.3" textCenter="left" ellipsis=true>{{detail?.meta?.activity?.updated_by?.first_name + ' ' + detail?.meta?.activity?.updated_by?.last_name | titlecase }}</p>
                    </div>
                    <div a-style display="flex" justify="space-between" alignItems="center">
                        <p a-style color="#333333" fs="1.2" textCenter="left" ellipsis=true>{{detail?.meta?.activity?.created_at | date: 'dd/MM/yyyy \'at\' HH:mm:ss' }}</p>
                        <div a-style display="flex" gap="1.6" position="relative">
                            <m-image src="../assets/images/assets/copy grey.svg" hover="../assets/images/assets/copy colour.svg" a-style w="1.8" h=1.8 [title]=""></m-image>
                            <m-image src="../assets/images/assets/share grey.svg" hover="../assets/images/assets/share colour.svg" w="1.8" h=1.8 a-style [title]=""></m-image>
                            <m-image hover="../assets/images/assets/delete colour.svg" a-style w="1.8" h=1.8 src="../assets/images/assets/delete grey.svg" (click)="delete.emit(detail._id);" title="Delete"></m-image>
                            <m-image (click)="open_pop_over('app')" src="../assets/images/assets/more.svg" hover="../assets/images/assets/menu colour.svg" a-style w="1.8" h=1.8 title="More"></m-image>
                            <div #app (close_modal)="visible1.app = false" *ngIf="visible1.app" a-style bg="#ffffff" br="1px solid #E4E4E4" display="flex" gap="1.6" p=1.6 brRadius="0.6" justify="space-around" position="absolute" right="0.5" top="2.8">
                                <m-image src="../assets/images/assets/visit colour.svg " a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/edit colour.svg" a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/qr code colour.svg" a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/statstics colour.svg " a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/password protection colour.svg" a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/campaign tracking colour.svg" a-style h="1.6" w="1.6"></m-image>
                                <m-image src="../assets/images/assets/set expirt date colour.svg" a-style h="1.6" w="1.6"></m-image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="queryParams.type=='Expiry' " a-style w="100%" display="flex" justify="flex-end" gap="1.6">
                <m-button [types]="gs.enums.buttons.button33" button_type=submit text="Reactivate"></m-button>
                <m-button [types]="gs.enums.buttons.button33" button_type=submit text="Delete"></m-button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #campaign_card>
    <div a-style br="1px solid #E4E4E4" brRadius="0.6" maxWidth="40" minWidth="40" p="1">
        <div a-style w=100% h=13.6>
            <m-image [src]="'https://new-api.mesbro.com/uploads/'+detail?.data?.campaign_picture" w=100% h="100%"></m-image>
        </div>
        <m-text [type]="gs.enums.texts.paragraph38" mt="1" [text]="detail?.data?.campaign_name"></m-text>
        <div a-style display="flex" gap="1">
            <m-text [type]="gs.enums.texts.paragraph38" text="Note"></m-text>
            <m-text [type]="gs.enums.texts.paragraph38" [text]=""></m-text>
        </div>
    </div>
</ng-template>

<ng-template #favorite let-data=data>
    <div title="Favourite Shortlink">
        <m-image [src]="data.src" hover="../assets/images/assets/favourite hover.svg" w="2" h="2" [title]="favourite_title" [hover]="isVisible_favorite || activities?.save ?data.hover_src:data.src" [current_value]="activities?.save" (click)="isVisible_favorite=!isVisible_favorite;activities.save?gs.action('unsave',detail):gs.action('save',detail)"></m-image>
    </div>
</ng-template>

<ng-template #bookmarks let-data=data>
    <div a-style display="flex" align="center" title="Save Shortlink">
        <m-image *ngIf="data" [src]="data?.src" w="2" h="2" [title]="save_title" [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src" [current_value]="activities?.my_activity?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities.my_activity.save?'unsave':data.type || data.label);activities.my_activity.save =!activities.my_activity.save"></m-image>
        <!-- <m-image [src]="data.src"  w="1.8" mr="0.8" [hover]="isVisible_bookmark || activities?.save ?data.hover_src:data.src" [current_value]="activities?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activities.save?gs.action('unsave',detail):gs.action('save',detail)"></m-image> -->
        <!-- <m-image #hov_image src="{{passedData.icon}}" [current_value]="params.type==passedData.queryParams.type&& passedData.label != 'History'?true:false" [hover]="passedData.hov_icon " a-style h="1.8" w="1.8" display="500:none" [title]=passedData.title></m-image> -->
    </div>
</ng-template>







<!-- <ng-container [ngTemplateOutlet]=bookmarks [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/save normal.svg',hover_src:'../assets/images/assets/save hover.svg' }}"></ng-container> -->
<!-- <ng-container [ngTemplateOutlet]=favorite [ngTemplateOutletContext]="{data:{src:gs.enums.images.image372,hover_src:gs.enums.images.image395,text:activities?.count?.like? activities?.count?.like : 0, type : 'save'}}"></ng-container> -->
<!-- <ng-container [ngTemplateOutlet]=favorite [title]="favourite_title" [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/save normal.svg',hover_src:'../assets/images/assets/favourite hover.svg',text:activities?.count?.like? activities?.count?.like : 0, type : 'save'}}"></ng-container> -->

<!-- <m-image hover="../assets/images/assets/colour.svg" a-style w="1.8" h=1.8 src="../assets/images/assets/delete grey.svg" (click)="delete.emit(detail._id);" title="Delete"></m-image> -->



<!-- <ng-container [ngTemplateOutlet]=bookmarks [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/save normal.svg',hover_src:'../assets/images/assets/save hover.svg' }}"></ng-container>
                            <ng-container [ngTemplateOutlet]=favorite [ngTemplateOutletContext]="{data:{src:gs.enums.images.image372,hover_src:gs.enums.images.image395,text:activities?.count?.like? activities?.count?.like : 0, type : 'save'}}"></ng-container> -->

<!-- <m-input *ngIf="application=='generic'" [type]="gs.enums.inputs.checkbox" ></m-input>
<div a-style display="flex" align="center" flexGap="0.8"> <img a-style src="https://mesbro.in/assets/images/one-call.svg" h="1.4" w="1.67">
    <p a-style fs="1.6" color="#333333" m="0">mesbro.com/yuurasvfoodvlog.32</p>
</div>
<p a-style fs="1.3" color="#333333" my="0.8">Food Vlog</p>
<div a-style display="flex" justify="space-between" align="center">
    <p a-style m="0" fs="1.1" color="#707070">13 December 2020, 14:24, Kolkata India</p>
    <div a-style display="flex" align="center" flexGap="1.42"> <img a-style src="https://mesbro.in/assets/images/one-call.svg" h="1.8" w="1.8"> </div>
</div> -->
<!-- <div  a-style display="flex" align="center" gap="2.4" w="100%">   
    <m-input [type]="gs.enums.inputs.checkbox" ></m-input>
      <div a-style a-style br="1px solid #e2e2e2" p="1.2" w="100%" display="flex" justify="space-between" flexWrap="wrap" flexGap="2" >
          <div a-style  mb="0.8">
              <div a-style display="flex"  mb="0.8">
                  <img src="https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg" a-style w="1.4" h="1.4">
                  <p a-style fs="1.3">{{item.basic.shortUrl}}</p>
              </div>
              <p a-style fs="1.3" mb="0.8" textCenter="left">{{item.basic.title?item.basic.title:'Mesbro.com'}}</p>
              <p a-style color="#707070" fs="1.3" textCenter="left">13 December 2020, 14:24, Kolkata India</p>
          </div>
                  <p a-style color="#707070" fs="1.3">{{item.basic.url?item.basic.url:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/80/3/5de8fd1f0535ff002fb31711/5f52374565ae04003423173e.webp'}}</p>
              <div>
                  <img src="https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg" a-style w="1.4" *ngFor="let item of [].constructor(13)" >
              </div>
      </div>
  </div> -->

<!-- <p minWidth="20" maxWidth="50%" a-style color="#707070" fs="1.3" ellipsis=true>{{passedData?.basic?.url?passedData.basic.url:'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/80/3/5de8fd1f0535ff002fb31711/5f52374565ae04003423173e.webp'}}</p>
  <div a-style minWidth="20" maxWidth="25%" textCenter="left" py="1">
      <img src="https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg" a-style w="1.4" *ngFor="let data of [].constructor(13)">
  </div> -->



<!-- {{detail?.meta?.activity?.created_at}} -->



<!-- <div a-style display="flex" justify="center">
    <div a-style display="flex" flexDirection="column" gap="2" p="1"> -->
<!-- <m-text [type]="gs.enums.texts.paragraph9" [text]="detail?.shortUrl"></m-text> -->
<!-- <a a-style textDecoration="none" fs="1.3" px="0.5" (click)="get_list()" color="#006bff" m="0" [href]="'https://new-api.mesbro.com/sl/'+detail?.unique_key" target="_blank">{{detail?.shortUrl}}</a> -->
<!-- <m-text [type]="gs.enums.texts.paragraph38" [text]="detail?.title"></m-text>
    </div>
</div> -->


<!-- <m-text [type]="gs.enums.texts.paragraph38" [text]="detail?.basic?.campaign"></m-text>
                <m-text [type]="gs.enums.texts.paragraph38" text="note"></m-text> -->
<!-- <p a-style color="#767676" fs="2" >{{detail?.basic?.campaign}} </p> -->

<!-- 
                <ng-template #bookmark let-data=data>
                    <div a-style display="flex" align="center">
                        <m-image [src]="data.src" w="1.5" mr="0.8"  title="Save Product" [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src" [current_value]="activities?.my_activity?.save" (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities.my_activity.save?'unsave':data.type || data.label);activities.my_activity.save =!activities.my_activity.save"></m-image>
                    </div>
                </ng-template> -->