import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-add-user',
  templateUrl: './add-user.component.html',
  styles: [
  ]
})
export class AddUserComponent implements OnInit {

  @Input() modal: any
  verify = false;

  application = 'Domain';
  component = 'User';

  domains: any = []


  queryParams: any = {
    _id: ''
  }
  form: FormGroup | undefined;
  isVisible: boolean;

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(params => this.handleParams(params))
  }

  async get_domains() {
    let response = await this.gs.get_data_list('Generic', 'Domain', [])
    this.domains = response.list
    this.domains = this.domains.map((data: any) => { data.domain_name = data.data.domain_name; return data; })
  }
  handleParams = async (params: Params) => {
    this.queryParams = params
    await this.get_domains();
    // this.initializeForm();
    this.initialize_form()
    if (params._id) {
      let details = await this.gs.hps.get('domain', 'user', params._id);

      https://new-api.mesbro.com/


      this.form?.patchValue(details)
    }
  }

  // initializeForm(){
  //   this.form = this.fb.group({
  //     _id: undefined,
  //     token: undefined,
  //     application: this.application,
  //     component: this.component,
  //     data: this.fb.group(this.form_data)
  //   })
  // }

  initialize_form() {
    this.form = this.fb.group({
      personal_details: this.fb.group({
        first_name: [null, this.gs.form_validations.required('First Name')],
        last_name: [null, this.gs.form_validations.required('Last Name')],
        date_of_birth: [null, this.gs.form_validations.ageValidation],
        gender: [null, this.gs.form_validations.required('Gender')],
        profile_picture: null,
        cover_picture: null,
        // location_details: [null, [this.gs.form_validations.required('location details')]],
      }),
      contact_details: this.fb.group({
        mobile_number_primary: [null, [this.gs.form_validations.required('Mobile Number '), this.gs.form_validations.mobile_number]],
        mobile_number_secondary: [null, []],
        email_address_primary: [null, []],
        email_address_secondary: [null, [this.gs.form_validations.email_address]],
      }),
      security_details: this.fb.group({
        domain: ['', this.gs.form_validations.required('Domain')],
        username: [null, [this.gs.form_validations.required('Username')]],
        password: [null, [this.gs.form_validations.password]],
        confirm_password: [null, this.gs.form_validations.confirm_password],
      }),
      location_details: this.fb.group({
        ip: null,
        version: null,
        city: null,
        region: null,
        region_code: null,
        country: null,
        country_name: ['India', [this.gs.form_validations.required('Country Name')]],
        country_code: null,
        country_code_iso3: null,
        country_capital: null,
        country_tld: null,
        continent_code: null,
        in_eu: null,
        postal: null,
        latitude: null,
        longitude: null,
        timezone: null,
        utc_offset: null,
        country_calling_code: null,
        currency: null,
        currency_name: null,
        languages: null,
        country_area: null,
        country_population: null,
        asn: null,
        org: null,
      }),
    });
  }

  async submit() {
    let body = {...this.form?.value};
    console.log(body);
    body.security_details.domain = body.security_details.domain.toLowerCase() 

    if (this.form?.value._id) {
      let response = await this.gs.hps.put('domain', 'user', undefined, body);
    } else {
      let response = await this.gs.hps.post('domain', 'user', 'add', body);
    }

  }

  openModal() {
    this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
  }


}
