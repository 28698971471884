<ng-container *ngIf="type =='categoryCard3'" [ngTemplateOutlet]="categoryCard3"></ng-container>
<ng-container *ngIf="type =='typecard'" [ngTemplateOutlet]="typecard"></ng-container>
<ng-container *ngIf="type =='recipecategoryCard'" [ngTemplateOutlet]="recipecategoryCard"></ng-container>
<ng-container *ngIf="type =='recipeTypeCard'" [ngTemplateOutlet]="recipeTypeCard"></ng-container>
<ng-container *ngIf="type =='categoryCardArticle'" [ngTemplateOutlet]="categoryCardArticle"></ng-container>
<ng-container *ngIf="type =='typeArticleCard'" [ngTemplateOutlet]="typeArticleCard"></ng-container>
<ng-container *ngIf="type =='vehicleCategory'" [ngTemplateOutlet]="vehicleCategory"></ng-container>
<ng-container *ngIf="type =='scrapCategoryCard'" [ngTemplateOutlet]="scrapCategoryCard"></ng-container>
<ng-container *ngIf="type =='guardiancategoryCard'" [ngTemplateOutlet]="guardiancategoryCard"></ng-container>
<div  *ngIf="false" class="test" a-style [style.paddingTop]="card_type=='categoryCard1'?  '1.4rem' : 0" [style.paddingBottom]="card_type=='categoryCard1'?  '0rem' : 0" [style.paddingLeft]="card_type=='categoryCard1'?  '0rem' : 0" [style.paddingRight]="card_type=='categoryCard1'? ' 0rem' : 0">
    <div *ngIf="(queryParams.step == 'Tertiary' || queryParams.step == 'Secondary' || queryParams.step == 'Primary' || queryParams.step == 'Type') && (!gs.router.url.includes('category') && !gs.router.url.includes('central/details?type=product&field=primary') && !gs.router.url.includes('rfq-form') )" a-style align="center" mb="4">
        <m-text [type]="gs.enums.texts.heading2" fs="3.2; 1336:1.8; 1024:1.6" text="SELECT {{type | uppercase}} CATEGORY"></m-text>
        <m-text [type]="gs.enums.texts.paragraph12" mt=1.6 text="There are numerous possibilities roaming around you. Grab them at right time."></m-text>
        <div a-style display="flex" justify="center" gap="1.2" align="center" mt=3 position=relative>
            <div a-style display="flex">
                <m-input mt=4 maxWidth="57" [type]="gs.enums.inputs.text" [columns]="1/2" [(ngModel)]="search_term1" (ngModelChange)="redirectTo()" placeHolder="Search Product Type" (keyup.enter)="onKeyUp($event)" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" postfix_bg="transparent"></m-input>
                <m-input mt=4 maxWidth="57" [(ngModel)]="search_term" [type]="gs.enums.inputs.text" [columns]="1/2" placeHolder="Search {{type }} Categories" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search.svg" postfix_bg="transparent"></m-input>
            </div>
        </div>
    </div>
    <div *ngIf="list" a-style display="flex" flexWrap="wrap" gap="2.4" justify=center>
        <ng-container *ngFor="let item of list[type] | search : search_term; let i = index">
            <ng-container [ngTemplateOutlet]="categoryCard" [ngTemplateOutletContext]="{passedData: { item: item, index : i}}" *ngIf="card_type== 'categoryCard1' ">
            </ng-container>
            <!-- {{card_type}} -->
            <!-- <div a-style mt="2"> -->
            <ng-container [ngTemplateOutlet]="categoryCard2" [ngTemplateOutletContext]="{passedData: { item: item, index : i}}" *ngIf="card_type== 'categoryCard2' ">
            </ng-container>
            <!-- </div> -->
        </ng-container>
    </div>
</div>

<ng-template #categoryCard let-passedData="passedData">
    <div class="category_card" cursor=pointer (mouseenter)="hovered_id=passedData.item._id;" (mouseleave)="hovered_id=undefined" (click)="select.emit(passedData.item)" a-style alignItems="center" justify="center" display="flex" py="1.2" flexDirection="column" br="1px solid #e4e4e4" bg="#ffffff" brRadius="0.6" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <m-image *ngIf="hovered_id != passedData.item._id" a-style h="11.8" w="11.9" mb=1.2 mx="4.6" [src]="(passedData.item.images?.faded )? 'https://new-api.mesbro.com/uploads/'+(passedData.item.images?.faded ) : gs.enums.images.image33">
        </m-image>
        <m-image *ngIf="hovered_id == passedData.item._id" a-style h="11.8" w="11.9" mb=1.2 mx="4.6" [src]="(passedData.item.images?.colored || passedData.item.images?.image)? 'https://new-api.mesbro.com/uploads/'+(passedData.item.images?.colored || passedData.item.images?.image) : gs.enums.images.image33">
        </m-image>
        <div a-style w="18.6" h="3.9">
            <m-text [type]="gs.enums.texts.paragraph38" textWrap="wrap" align="center" a-style text="{{passedData.item.title | titlecase}}"></m-text>
        </div>
    </div>
</ng-template>

<ng-template #categoryCard2 let-passedData="passedData">
    <div class="category_card" cursor=pointer (mouseenter)="hovered_id=passedData.item._id;" (mouseleave)="hovered_id=undefined" (click)="select.emit(passedData.item)" a-style alignItems="center" justify="center" display="flex" flexDirection="column" br="0.5px solid #e4e4e4" bg="#ffffff" brRadius="0.6" py="1.2" shadow=" hov:0 3px 6px 0 #f1f1f1">
        <m-image *ngIf="hovered_id != passedData.item._id" a-style h="11.8;1366:8.4" w="11.9;1366:8.5" mb=1.2 mx="4.6" [src]="(passedData.item.images?.faded || passedData.item.images?.image)? 'https://new-api.mesbro.com/uploads/'+(passedData.item.images?.faded || passedData.item.images?.image) : gs.enums.images.image33">
        </m-image>
        <m-image *ngIf="hovered_id == passedData.item._id" a-style h="11.8;1366:8.4" w="11.9;1366:8.5" mb=1.2 mx="4.6" [src]="(passedData.item.images?.colored || passedData.item.images?.image)? 'https://new-api.mesbro.com/uploads/'+(passedData.item.images?.colored || passedData.item.images?.image) : gs.enums.images.image33">
        </m-image>
        <div a-style w="18.6;1366:13.4" h="3.9;1366:2.9">
            <m-text [type]="gs.enums.texts.paragraph38" align="center" a-style text="{{passedData.item.title | titlecase}}">
            </m-text>
        </div>
    </div>
</ng-template>

<ng-template #categoryCard3 >
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail.basic.name)">
        <m-image *ngIf="detail.basic.fadedImage[0]" [src]="gs.imageUrl+detail.basic.fadedImage[0]" [hover]="gs.imageUrl+detail.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail.basic.fadedImage[0] && !detail.basic.coloredImage[0]" src="../assets/images/Icon_Bank/Service_Empty_Image.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #categoryCardArticle >
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail.basic.name)">
        <m-image *ngIf="detail.basic.fadedImage[0]" [src]="gs.imageUrl+detail.basic.fadedImage[0]" [hover]="gs.imageUrl+detail.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail.basic.fadedImage[0] && !detail.basic.coloredImage[0]" src="https://mesbro.in/assets/images/category-image-color.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #typecard>
    <div a-style w="20.7" h="19.4" p="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9">
        <m-image  [src]="gs.imageUrl+detail?.basic?.image[0]"  a-style h="10.5" w="10.5"></m-image>
        <p a-style  fs="1.3" w="18.3" class="lineClamp"  >{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #vehicleCategory>
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail.basic.name)">
        <m-image *ngIf="detail.basic.fadedImage[0]" [src]="gs.imageUrl+detail.basic.fadedImage[0]" [hover]="gs.imageUrl+detail.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail.basic.fadedImage[0] && !detail.basic.coloredImage[0]" src="../assets/images/Icon_Bank/Service_Empty_Image.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #typeArticleCard>
    <div a-style w="20.7" h="19.4" p="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9">
        <m-image  [src]="gs.imageUrl+detail?._source?.basic?.image[0]"  a-style h="10.5" w="10.5"></m-image>
        <p a-style  fs="1.3" w="18.3" class="lineClamp"  >{{detail._source.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #categoryCard4 >
    <div a-style w="20.7" h="19.4" py="2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" brRadius="0.6">
        <m-image *ngIf="detail.basic.fadedImage[0]" [src]="gs.imageUrl+detail.basic.fadedImage[0]" [hover]="gs.imageUrl+detail.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail.basic.fadedImage[0] && !detail.basic.coloredImage[0]" src="https://mesbro.in/assets/images/category-image-color.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" mt="1.9">{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #recipecategoryCard>
    <!-- {{detail| json}} -->
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail._source.basic.name)">
        <m-image *ngIf="detail?._source?.basic?.fadedImage[0]" [src]="gs.imageUrl+detail._source.basic.fadedImage[0]" [hover]="gs.imageUrl+detail._source.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail?._source?.basic?.fadedImage[0] && !detail._source.basic.coloredImage[0]" src="../assets/images/Icon_Bank/Recipe_Empty_Image.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail._source.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #recipeTypeCard>
    <div a-style w="20.7" h="19.4" py="2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" brRadius="0.6">
        <m-image  [src]="gs.imageUrl+detail?.basic?.image[0]"  a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" mt="1.9">{{detail.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #scrapCategoryCard>
    <!-- {{detail| json}} -->
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail._source.basic.name)">
        <m-image *ngIf="detail?._source?.basic?.fadedImage[0]" [src]="gs.imageUrl+detail._source.basic.fadedImage[0]" [hover]="gs.imageUrl+detail._source.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail?._source?.basic?.fadedImage[0] && !detail._source.basic.coloredImage[0]" src="../assets/images/Icon_Bank/Recipe_Empty_Image.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail._source.basic.name | titlecase}}</p>
      </div>
</ng-template>
<ng-template #guardiancategoryCard>
    <div a-style w="20.7" h="19.4" py="1.2" br="0.5px solid #e4e4e4" brRadius="0.6" align="center" display="flex" flexDirection="column" gap="1.9" (click)="select.emit(detail._source.basic.name)">
        <m-image *ngIf="detail?.basic?.fadedImage[0]" [src]="gs.imageUrl+detail.basic.fadedImage[0]" [hover]="gs.imageUrl+detail.basic.coloredImage[0]" a-style h="10.5" w="10.5"></m-image>
        <m-image *ngIf="!detail?.basic?.fadedImage[0] && !detail.basic.coloredImage[0]" src="../assets/images/Icon_Bank/Recipe_Empty_Image.svg" a-style h="10.5" w="10.5"></m-image>
        <p a-style h="3.6" fs="1.3" w="18.3" >{{detail?.basic?.name | titlecase}}</p>
      </div>
</ng-template>