export const search_items = [
  {
    title: 'All',
    image: '../assets/images/assets/all_search.svg',

  },
  {
    title: 'Product',
    image: '../assets/images/assets/product normal grey.svg',
  },
  
  {
    title: 'Recipe',
    image: ' ../assets/images/assets/receipe (1).svg ',
  },
  {
    title: 'Service',
    image: 'https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/location-black.svg ',
  },
  {
    title: 'Property',
    image: '../assets/images/assets/book.svg',

  },
  {
    title: 'News',
    image: '../assets/images/assets/news.svg ',

  },
  {
    title: 'Article',
    image: '../assets/images/assets/images.svg',

  },

  {
    title: 'More',
    image: '../assets/images/assets/more.svg',

  }, 



]


export const faq_items = [
  {
    title: 'How do I install Google Meet?',
    

  },
  {
    title: 'Can I download Google Meet on laptop?',

  },
  {
    title: 'How do I join Google Meet on my laptop?',
 

  },
  {
    title: 'How do I use Google Meet?',
  

  },
  {
    title: 'Is Google Meet free download?',


  },

  {
    title: 'How do I use Google Meet on my phone?',

  }, 



]


export const number_items = [
  {
    title: '1',
    

  },
  {
    title: '2',

  },
  {
    title: '3',
 

  },
  {
    title: '4',
  

  },
  {
    title: '5',


  },


]