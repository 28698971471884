import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { MesbroModule } from './mesbro/mesbro.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(MesbroModule)
  .catch((err : any) => console.error(err));
