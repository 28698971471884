import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attachment'
})
export class AttachmentPipe implements PipeTransform {

  transform(value: any[], type: any): any {
    // console.log(value);
    let return_array: any = []
    if (value?.filter) {
      value?.filter((attachment: any) => {
        if (type == 'image' && (attachment?.content_type?.includes('image') || attachment?.mimetype?.includes('image'))) {
          return_array.push(attachment)
        }
        else if (type != 'image' && !(attachment?.content_type?.includes('image') || attachment?.mimetype?.includes('image'))) {
          return_array.push(attachment)
        }
      })
    }

    return return_array

    // else {
    //    filter =  value.filter((attachment:any)=> {
    //      if(!attachment?.content_type.includes('image')){
    //       return attachment
    //      }
    //    } )
    // }

  }

}
