import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-leads-list',
  templateUrl: './leads-list.component.html',
  styles: [
  ]
})
export class LeadsListComponent implements OnInit {
  application = "Product";
  component = "Lead";
  list = {
    list: [],
    count: 0
  }


  constructor(public gs: GlobalService) { }

  ngOnInit(): void {
    this.get_list();
  }

  async get_list() {
    let response = await this.gs.get_data_list(this.application, this.component, [])
    this.list = response;
    console.log(this.list);
  }

  async delete(id: any, index: number) {
    await this.gs.delete_from_list('data', id)
    this.list.list.splice(index, 1)
  }

}
