<!-- new modal -->
<div *ngIf="visible" a-style bg="#fff" opacity="1" brRadius="0.6" br="1px solid #e4e4e4" p="2.4">
  <p a-style fs="1.6" mb="4.1" color="#333333">Suggest Feature</p>
  <div a-style mb="4" align="center">
    <img src="../assets/images/assets/add-file10.svg" a-style h=13 w=11.5 a-style>
  </div>
  <div a-style mb="1.4">
    <form [formGroup]="form">
      <m-form [brNone]=false [paddingNone]=false>
        <m-input [type]="gs.enums.inputs.text" formControlName="titles" label="Title" minWidth="32.7" maxWidth="32.7"
          placeHolder="Title" px='0' a-style></m-input>
        <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
          placeHolder="Description" minWidth="32.7" maxWidth="32.7" a-style mt="0" label="Description" rows="8" px=0>
        </m-input>
      </m-form>
    </form>
  </div>
  <div a-style display="flex" justify="flex-end">
    <m-button [types]="gs.enums.buttons.button102" a-style fs="1.3" color="#333" m="0" bg="#fff" brRadius="0.6"
      br="1px solid #2479AB" (click)="modal.openModal(); visible = false">Submit</m-button>
  </div>
</div>

<div>
  <m-modal a-style #modal width="560px">
    <div a-style py="3" bg="#fff" brRadius="0.6" opacity="1" align="center">
      <img src="../assets/images/assets/tick (3).svg" a-style w="10" h="10" bg="transparent" brRadius="50%" opacity="1"
        mb="2.4">
      <p a-style color="#333" fs="2" m="0">Feature Suggested Successfully</p>
    </div>
  </m-modal>
</div>