<div *ngIf="!gs.router.url.includes('gdashboard')" a-style p=2.4 br="0.5px solid #e4e4e4" brRadius="0.6" bg="#ffffff"
    display="grid" gridTemplateColumns="auto auto auto auto" gap="2.4" class="product-gap grid-1366 mobile-2">
    <div *ngIf="!gs.router.url.includes('gdashboard')" minWidth="fit-content" maxWidth="fit-content" overflowY="auto"
        a-style class="no_scroll_bar max-h-365 mobile-1" h="100%" display="flex" flexDirection="column" gap="2.4"
        maxHeight="51.2">
        <m-image src="https://new-api.mesbro.com/uploads/{{details?.profile_picture}}"
            (click)="select_image(details?.profile_picture)" a-style w="11" h="11" class="image-1" brRadius=8px
            br="1px solid #e4e4e4" shadow="hov:0 3px 6px 0 #f1f1f1" bgSize="cover" bgRepeat="no-repeat">
        </m-image>
        <m-image src='https://new-api.mesbro.com/uploads/{{item}}' brRadius="0.6"
            *ngFor="let item of details?.gallery; let i=index" (click)="select_image(item)" a-style
            class="image-1 mobile-1" w="11" h="11" brRadius=8px br="1px solid #e4e4e4" shadow="hov:0 3px 6px 0 #f1f1f1"
            bgSize="cover" bgRepeat="no-repeat">
        </m-image>
    </div>
    <div a-style minWidth="51.2" maxWidth="51.2" h="51.2" class="image-2 mobile-3">
        <m-image src="https://new-api.mesbro.com/uploads/{{change_image ? change_image : details?.profile_picture}}"
            br="1px solid #e4e4e4" brRadius="0.6" w="100%" h="100%">
        </m-image>
    </div>
    <div a-style display="flex">
        <div>
            <div a-style display="flex" mb="2.4" class="mb-8" gap=0.4 w=100% [ellipsis]="true"
                *ngIf="!gs.router.url.includes('gdashboard')">
                <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2"
                    *ngIf="details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title"
                    class="fs-9-1366"
                    text="{{details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title}} >"
                    (click)="queryParam.step = 'Primary'">
                </m-text>
                <!-- <p a-style fs="1.2" cursor="pointer"
                    *ngIf="details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title"
                    (click)="queryParam.step = 'Primary'">
                    {{details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title}}
                </p> -->

                <m-text a-style [type]="gs.enums.texts.paragraph134" a-style fs="1.2"
                    *ngIf="details?.parents?.parent?.parents?.parent?.parents?.parent?.title" class="fs-9-1366"
                    text="{{details?.parents?.parent?.parents?.parent?.parents?.parent?.title}} >"></m-text>
                <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2" class="fs-9-1366"
                    text="{{details?.parents?.parent?.parents?.parent?.title}} >">
                </m-text>
                <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2" *ngIf="details?.parents?.parent?.title"
                    class="fs-9-1366" text="{{details?.parents?.parent?.title}}">
                </m-text>
            </div>
            <div a-style w=100% class="ellipsis" h=6.8 class="h-58">
                <m-text [type]="gs.enums.texts.paragraph129" class="fs-16" color="#333333" fs=2 fw=600
                    text="{{details?.title}}">
                </m-text>
            </div>
            <div a-style display="flex" align="end" gap=0.4 mt="1" class="mt-5">
                <m-text class="fs-14" [type]="gs.enums.texts.paragraph70" a-style text="Price : "></m-text>
                <!-- <m-text [type]="gs.enums.texts.paragraph132" textCenter="center" text=" INR  {{details?.attributes?.basic_details?.price_from?.value  ? details?.attributes?.basic_details?.price_from?.value  : 0}} - {{details?.attributes?.basic_details?.price_to?.value ? details?.attributes?.basic_details?.price_to?.value : 0}}" color="#2479AB;hov:#2479AB"></m-text> -->
                <p a-style fs="1.8" class="fs-14" fw="400" color="#2479AB">INR
                    {{details?.attributes?.basic_details?.price_from?.value ?
                    details?.attributes?.basic_details?.price_from?.value : 0}} -
                    {{details?.attributes?.basic_details?.price_to?.value ?
                    details?.attributes?.basic_details?.price_to?.value : 0}}</p>
                <p a-style fs="1.3" class="fs-9" fw="400" color="#2479AB">Negotiable</p>
            </div>
            <div a-style w=100% display="flex" justify="space-between" gap="2">
                <div a-style w=100% mt=3 class="mt-5">
                    <!-- <m-text [type]="gs.enums.texts.paragraph139" color="#333333" fs=1.6 fw=600 text="{{details?.parents?.group?.basic_details?.legal_name}}">
                        </m-text> -->
                    <p a-style fs="1.6" color="#2479AB" fw="500" class="fs-11"
                        (click)="gs.router.navigate(['gdashboard','group', 'org-detail'],{queryParams :{id:details?.parents?.group?._id,type:'details'}})">
                        {{details?.parents?.group?.basic_details?.legal_name}}</p>
                    <div>
                        <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#333333" fs=1.3
                                text="Location :">
                            </m-text>
                            <div a-style display="flex" gap=0.4>
                                <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                    *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.city"
                                    text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.city}},">
                                </m-text>
                                <!-- {{details | json}} -->

                                <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                    *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.state"
                                    text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.state}},">
                                </m-text>
                                <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                    *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.country"
                                    text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.country}}">
                                </m-text>
                            </div>
                        </div>
                        <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                            <m-text [type]="gs.enums.texts.paragraph129" color="#333333" class="fs-9" fs=1.3
                                text="Origin Of Goods :">
                            </m-text>
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                text="{{details?.attributes?.basic_details?.origin_of_goods?.value}}">
                            </m-text>
                        </div>
                        <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#333333" fs=1.3
                                text="Min Order Quantity :">
                            </m-text>
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                text="{{details?.attributes?.basic_details?.minimum_order_quantity?.value}}">
                            </m-text>
                        </div>
                        <div a-style h=9.9 w="auto" class="" mt=1 overflowY="auto"
                            class="no_scroll_bar lineClamp6 product1 mt-5">
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3 mt="1"
                                text="{{details?.attributes?.product_details?.description?.value }}">
                            </m-text>
                        </div>
                    </div>
                    <div a-style display="flex" gap="2" mt="2.4" mb="1.9" class="mt-mb-12"
                        *ngIf="!gs.router.url.includes('gdashboard')">
                        <m-image *ngFor="let item of productImage" [src]='item.src' [w]="item.width" [h]="item.height">
                        </m-image>
                        <ng-container [ngTemplateOutlet]=favourite class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/star_.gif',hover_src:'../assets/images/images/star_.gif',text:activities?.count?.like? activities?.count?.like : 0, type : 'favourite'}} ">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]=bookmark class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/Save-2-Tranparent.gif',hover_src:'../assets/images/assets/Save-2-Tranparent.gif', type : 'save'}} ">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]=compare class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/images/Compare.gif',hover_src:'../assets/images/assets/compare hover1.svg'}}">
                        </ng-container>
                        <m-image a-style (click)="share_modal.openModal()" class="image-14"
                            src="../assets/images/assets/Group 93808.svg" w=2 h="2" [title]="share_title" title="Share">
                        </m-image>
                        <!-- <ng-container [ngTemplateOutlet]=icons class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/product/print.svg', hover_src:'../assets/images/assets/product/print.svg', label : 'print'}}">
                        </ng-container> -->
                        <m-image a-style (click)="printDetail.openModal()"
                            src="../assets/images/assets/product/print.svg" w=2 h="2" title="print">
                        </m-image>

                        <m-image a-style (click)="qr_code.openModal()" class="image-14"
                            src="../assets/images/assets/Group 93812.svg" w=2 h="2" [title]="QR_title"
                            title="Show Qr Code"></m-image>
                    </div>
                </div>

                <!-- <div bg="#ffffff" br="0.5px solid #e4e4e4" a-style br="0.5px solid #e4e4e4" brRadius=0.6 minWidth="24" h="28.5" px=3.5 py=3 mx="3">
                    <div a-style align=center display="1499:block">
                        <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=2.2 fw=400 [text]="details?.meta?.activity?.rating?.count?.averageRating"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=1.6 fw=400 text="Average Rating">
                        </m-text>
                        <m-text [type]="gs.enums.texts.paragraph23" text="Based on {{details?.meta?.activity?.rating?.count?.totalRating}} user Reviews"></m-text>
                    </div>
                    <div>
                        <div a-style flexGap=2 maxWidth=16.4 display="flex" flexWrap="wrap">
                            <div a-style flexGrow=1 mt="2.6">
                                <ng-container *ngFor="let item of [
                                                    {text1:'1', text2:details?.meta?.activity?.rating?.count?.one},
                                                    {text1:'2', text2:details?.meta?.activity?.rating?.count?.two},
                                                    {text1:'3', text2:details?.meta?.activity?.rating?.count?.three},
                                                    {text1:'4', text2:details?.meta?.activity?.rating?.count?.four},
                                                    {text1:'5', text2:details?.meta?.activity?.rating?.count?.five}]" [ngTemplateOutlet]="rating_snapshot" [ngTemplateOutletContext]="{pass_data: item }">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- 
                <div a-style br="1px solid #E4E4E4" brRadius="0.6" display=flex p=2.4 mb=2.6 maxWidth=24 class="width-17" flexGap=1 flexWrap="wrap" flexGap=0;1499:1 justify="space-around">
                    <div a-style align=center display="1499:block">
                        <m-text [type]="gs.enums.texts.paragraph78" class="fs-11"  [text]=details?.meta?.activity?.rating?.count?.averageRating></m-text>
                        <m-text [type]="gs.enums.texts.paragraph40" class="fs-9" text="Average Rating" mt=0.4 mb=0.6></m-text>
                        <m-text [type]="gs.enums.texts.paragraph23" class="fs-9" text='Based On {{details?.meta?.activity?.rating?.count?.totalRating}} Reviews'></m-text>
                    </div>
                    <div a-style flexGap=2  display="flex" flexWrap="wrap" minWidth=16.4 class="width-11">
                        <div a-style flexGrow=1>
                            <m-text [type]="gs.enums.texts.paragraph49" text="Rating Snapshot"></m-text>
                            <ng-container *ngFor="let item of [{text1:'1', text2:details?.meta?.activity?.rating?.count?.one},{text1:'2', text2:details?.meta?.activity?.rating?.count?.two},{text1:'3', text2:details?.meta?.activity?.rating?.count?.three},{text1:'4', text2:details?.meta?.activity?.rating?.count?.four},{text1:'5', text2:details?.meta?.activity?.rating?.count?.five}]" [ngTemplateOutlet]="rating_snapshot" [ngTemplateOutletContext]="{pass_data: item }"></ng-container>
                        </div>
                        <div a-style flexGrow=1 *ngIf="false">
                            <m-text [type]="gs.enums.texts.paragraph49" text="Average Customer Rating"></m-text>
                            <ng-container *ngFor="let item of [{text1:'Strong', text2:'4.8'},{text1:'Absorbent', text2:'4.5'},{text1:'My personal style', text2:'3.4'},{text1:'Decorative', text2:'4.1'},{text1:'Soft', text2:'4.8'}]" [ngTemplateOutlet]="average_rating" [ngTemplateOutletContext]="{pass_data: item}"></ng-container>
                        </div>
                    </div>
                </div> -->
                <div a-style display=flex gap="3">
                    <div>
                        <img src="assets/images/assets/pdfRed.svg" alt="" a-style w="5.8" h="7.8">
                        <p>Company Brochure</p>
                    </div>
                    <div>
                        <img src="assets/images/assets/pdfRed.svg" alt="" a-style w="5.8" h="7.8">
                        <p>Product Brochure</p>
                    </div>
                </div>
                <!-- <div bg="#ffffff" br="0.5px solid #e4e4e4" a-style br="0.5px solid #e4e4e4" brRadius=0.6 minWidth="23.9;1886:22.5;1748:21.5;750:22;580:20" maxWidth="23.9;1886:22.9;1550:23.9" h="28.4" px=3.5 py=3>
                    <div a-style align=center>
                        <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=2.2 fw=400 [text]="details?.meta?.activity?.rating?.count?.averageRating"></m-text>
                        <m-text [type]="gs.enums.texts.paragraph129" color="#333333" fs=1.6 fw=400 text="Average Rating">
                        </m-text>
                        <m-text [type]="gs.enums.texts.paragraph23" text='Based on {{details?.meta?.activity?.rating?.count?.totalRating}}  user Reviews'></m-text>
                    </div>
                    <div>
                        <div a-style flexGap=2 maxWidth="16.4" display="flex" flexWrap="wrap">
                            <div a-style flexGrow=1 mt="2.6">
                                <ng-container *ngFor="let item of [{text1:'1', text2:details?.meta?.activity?.rating?.count?.one},{text1:'2', text2:details?.meta?.activity?.rating?.count?.two},
                                        {text1:'3', text2:details?.meta?.activity?.rating?.count?.three}, {text1:'4', text2:details?.meta?.activity?.rating?.count?.four}, {text1:'5', text2:details?.meta?.activity?.rating?.count?.five}]" [ngTemplateOutlet]="rating_snapshot" [ngTemplateOutletContext]="{pass_data: item }">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div a-style brTop="1px solid #e4e4e4" pt=1.6 display="flex" justify="space-between" align="center">
                <div a-style display="flex" gap="2">
                    <button *ngIf="!gs.router.url.includes('gdashboard')" a-style py=0.5 px=1.2 class="product-button"
                        brRadius="0.6" br="0.5px solid #F35325" bg="#FFE6DF" (click)="reportProduct.openModal()">Report
                        Product</button>
                    <button a-style py=0.5 px=1.2 class="product-button" brRadius="0.6" br="0.5px solid #81BC06"
                        bg="#F5FFDF" (click)="id.openModal()">Get Latest
                        Price</button>
                    <button a-style py=0.5 px=1.2 class="product-button" brRadius="0.6" br="0.5px solid #05A6F0"
                        bg="#CFEEFC" (click)="rfqadd.openModal()">Get Quotation</button>
                </div>
                <div a-style display="flex" flexGap=2 *ngIf="!gs.router.url.includes('gdashboard')">
                    <m-image *ngFor="let item of productImage" [src]='item.src' [w]="item.width" [h]="item.height">
                    </m-image>
                    <ng-container [ngTemplateOutlet]=like class="image-14"
                        [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/product/like.svg',hover_src:'../assets/images/assets/product/like.svg',text:activities?.count?.like ? activities?.count?.like : 0, type : 'like'}}">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]=Views class="image-14"
                        [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/eye1.svg',hover_src:'../assets/images/assets/eye1.svg',text: details?.meta?.activity?.count?.view ? details?.meta?.activity?.count?.view : 0 }}">
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- <m-moblie-chat></m-moblie-chat> -->
        <!-- <m-moblie-chat a-style w=31.2 br="0.5px solid rgb(228, 228, 228)" shadow=" 0 3px 6px 0 #f1f1f1" brRadius="22px">
        </m-moblie-chat>
        <m-moblie-chat a-style w=31.2 br="0.5px solid rgb(228, 228, 228)" shadow=" 0 3px 6px 0 #f1f1f1" brRadius="22px">
        </m-moblie-chat> -->

        <!-- <m-chat-header [details]="details"></m-chat-header> -->
        <!-- <div a-style w=31.2 br="0.5px solid rgb(228, 228, 228)" shadow="hov:#17171729" shadow=" 0 3px 6px 0 #f1f1f1"
            brRadius="22px">

        </div> -->

        <!-- <m-image src="../assets/images/assets/Mobile Mockup (1).png" a-style w="33" h="53.2" mr="-0.4"></m-image> -->
    </div>

    <!-- <div *ngIf="!gs.router.url.includes('gdashboard')" a-style w=auto class="chat-mobile mobile-4"
        br="0.5px solid rgb(228, 228, 228)" shadow=" 0 3px 6px 0 #f1f1f1" brRadius="22px">
        <div a-style h=5 class="h-35" bg="#2479AB" brTopLeftRadius="22px" brTopRightRadius="22px" display="flex"
            justify="center" align="center">
            <m-image src="../assets/images/assets/product/Mesbro.png" a-style w="9.4" h="3.4"></m-image>
        </div>
        <div a-style minHeight="35" class="max-h-auto" p="1.6" brBottom="0.5px solid rgb(228, 228, 228)">
            <div a-style shadow="0 3px 6px 0 #f1f1f1" brRadius="6px" color="#FEFFFC" maxHeight="max-content"
                maxWidth="max-content" p=1.2 mr="7.512" p=".6 " class="no_scroll_bar max-h-40" overflowY="auto"
                maxHeight="5.9" mb="0.7">
                <m-text a-style [type]="gs.enums.texts.paragraph116" maxWidth="19.8" maxHeight="3.5"
                    text="As we await the surge of a third wave to come up in India, Covaxin ">
                </m-text>
            </div>
            <div a-style pl="1.2">
                <m-text a-style [type]="gs.enums.texts.paragraph10" pt=".7" mb="1.6" text="00:00 AM"></m-text>
            </div>
            <div a-style shadow="0 3px 6px 0 #f1f1f1" brRadius="6px" color="#FEFFFC" h=100% maxWidth="max-content" p=1.2
                ml="4" p=".6" class="no_scroll_bar max-h-100" overflowY="auto" maxHeight="16.6" mb="0.7">
                <m-text a-style [type]="gs.enums.texts.paragraph116" maxWidth="19.8" maxHeight="3.5"
                    text="As we await the surge of a third wave to come up in India, Covaxin has become one of the first vaccines to have been granted emergency use nod. As per emergency use nod. As per vaccines to have been granted emergency use nod. As per ">
                </m-text>
            </div>
            <div a-style display="flex">
                <m-image src="../assets/images/assets/product/viewicon.svg" a-style w="1.6" h="1.6" ml="8.6"></m-image>

                <m-text a-style [type]="gs.enums.texts.paragraph10" text="00:00 AM" ml="0.7"></m-text>

            </div>

        </div>
        <div a-style display="flex" gap="1" p="1.6" class="p-12" brBottom="0.5px solid rgb(228, 228, 228) ">
            <div>
                <m-image src="../assets/images/assets/attchment_coloured.svg" h="1.8" w="1.8"></m-image>
            </div>
            <div a-style flexGrow="1">
                <div a-style display="flex" justify="space-between" gap="1">
                    <m-text a-style [type]="gs.enums.texts.paragraph46" text="message..." minWidth="2"></m-text>
                    <m-image src="../assets/images/assets/Path.svg" a-style w="1.4" h="1.8" ml="1"
                        (click)="add_conversation(details)"></m-image>
                </div>
            </div>
        </div>
        <div a-style display="flex" p="1.6" class="p-12" justify="center" gap="1" h="5.8" class="h-auto" align="center">
            <m-text a-style text="powered by " [type]="gs.enums.texts.paragraph75"></m-text>
            <img *ngIf="gs.router.url != '/home'" src="/assets/images/logo.svg" routerLink="/" a-style w="5.1" h="1.9"
                cursor="pointer">
        </div>
    </div> -->

    <m-organization-conversation></m-organization-conversation>

</div>


<div *ngIf="types=='organization_detail'">
    <div a-style display="flex" class="mb-8" gap=0.4 w=100% py="2.8" [ellipsis]="true">
        <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2"
            *ngIf="details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title" class="fs-9-1366"
            text="{{details?.parents?.parent?.parents?.parent?.parents?.parent?.parents?.parent?.title}} >">
        </m-text>
        <m-text a-style [type]="gs.enums.texts.paragraph134" a-style fs="1.2"
            *ngIf="details?.parents?.parent?.parents?.parent?.parents?.parent?.title" class="fs-9-1366"
            text="{{details?.parents?.parent?.parents?.parent?.parents?.parent?.title}} >"></m-text>
        <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2" class="fs-9-1366"
            text="{{details?.parents?.parent?.parents?.parent?.title}} >">
        </m-text>
        <m-text [type]="gs.enums.texts.paragraph134" a-style fs="1.2" *ngIf="details?.parents?.parent?.title"
            class="fs-9-1366" text="{{details?.parents?.parent?.title}}">
        </m-text>
    </div>

    <div a-style p=2.4 br="0.5px solid #e4e4e4" brRadius="0.6" bg="#ffffff" display="flex" gap="2.4">
        <div a-style w="39.8" h="39.8">
            <m-image src="https://new-api.mesbro.com/uploads/{{change_image ? change_image : details?.profile_picture}}"
                br="1px solid #e4e4e4" brRadius="0.6" a-style w="39.8" h="39.8">
            </m-image>
        </div>
        <div a-style display="flex">
            <div>
                <div a-style w=100% class="ellipsis" h=6.8 class="h-58">
                    <m-text [type]="gs.enums.texts.paragraph129" class="fs-16" color="#333333" fs=2 fw=600
                        text="{{details?.title}}">
                    </m-text>
                </div>
                <div a-style display="flex" align="end" gap=0.4 mt="1" class="mt-5">
                    <m-text class="fs-14" [type]="gs.enums.texts.paragraph70" a-style text="Price : "></m-text>
                    <!-- <m-text [type]="gs.enums.texts.paragraph132" textCenter="center" text=" INR  {{details?.attributes?.basic_details?.price_from?.value  ? details?.attributes?.basic_details?.price_from?.value  : 0}} - {{details?.attributes?.basic_details?.price_to?.value ? details?.attributes?.basic_details?.price_to?.value : 0}}" color="#2479AB;hov:#2479AB"></m-text> -->
                    <p a-style fs="1.8" class="fs-14" fw="400" color="#2479AB">INR
                        {{details?.attributes?.basic_details?.price_from?.value ?
                        details?.attributes?.basic_details?.price_from?.value : 0}} -
                        {{details?.attributes?.basic_details?.price_to?.value ?
                        details?.attributes?.basic_details?.price_to?.value : 0}}</p>
                    <p a-style fs="1.3" class="fs-9" fw="400" color="#2479AB">Negotiable</p>
                </div>
                <div>
                    <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                        <div a-style display="flex" gap=0.4>
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.city"
                                text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.city}},">
                            </m-text>
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.state"
                                text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.state}},">
                            </m-text>
                            <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                                *ngIf="details?.attributes?.material_available_location?.search_material_available_location?.value?.country"
                                text="{{details?.attributes?.material_available_location?.search_material_available_location?.value?.country}}">
                            </m-text>
                        </div>
                    </div>
                    <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                        <m-text [type]="gs.enums.texts.paragraph129" color="#333333" class="fs-9" fs=1.3
                            text="Origin Of Goods :">
                        </m-text>
                        <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                            text="{{details?.attributes?.basic_details?.origin_of_goods?.value}}">
                        </m-text>
                    </div>
                    <div a-style display="flex" align="center" gap=0.4 mt="1" class="mt-5">
                        <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#333333" fs=1.3
                            text="Min Order Quantity :">
                        </m-text>
                        <m-text [type]="gs.enums.texts.paragraph129" class="fs-9" color="#707070" fs=1.3
                            text="{{details?.attributes?.basic_details?.minimum_order_quantity?.value}}">
                        </m-text>
                    </div>
                </div>
                <div a-style w="72.8" h="12" mt="4.6">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architect Sed ut
                    perspiciatis unde omnis Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                    architect Sed ut perspiciatis unde omnis eaque ipsa quae ab illo inventore veritatis et quasi
                    architect Sed ut perspiciatis unde omnis eaque ipsa quae ab illo inventore veritatis et quasi
                    architect Sed ut perspiciatis unde omnis
                </div>
                <div a-style pt=2.4 display="flex" justify="space-between" align="center">
                    <div a-style display="flex" gap="2">
                        <button a-style py=0.5 px=1.2 class="product-button" brRadius="0.6" br="0.5px solid #81BC06"
                            bg="#F5FFDF" (click)="id.openModal()">Get Latest
                            Price</button>
                        <button a-style py=0.5 px=1.2 class="product-button" brRadius="0.6" br="0.5px solid #05A6F0"
                            bg="#CFEEFC" (click)="rfqadd.openModal()">Get Quotation</button>
                    </div>
                    <div a-style display="flex" flexGap=2 *ngIf="!gs.router.url.includes('gdashboard')">
                        <m-image *ngFor="let item of productImage" [src]='item.src' [w]="item.width" [h]="item.height">
                        </m-image>
                        <ng-container [ngTemplateOutlet]=like class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/product/like.svg',hover_src:'../assets/images/assets/product/like.svg',text:activities?.count?.like ? activities?.count?.like : 0, type : 'like'}}">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]=Views class="image-14"
                            [ngTemplateOutletContext]="{data:{src:'../assets/images/assets/eye1.svg',hover_src:'../assets/images/assets/eye1.svg',text: details?.meta?.activity?.count?.view ? details?.meta?.activity?.count?.view : 0 }}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<ng-template #rating_snapshot let-pass_data=pass_data>
    <div a-style display=flex flexGap=0.6 align="center" maxWidth=16.4rem>
        <div a-style display=flex justify="flex-end">
            <m-text [type]="gs.enums.texts.paragraph11" text="{{pass_data.text1}}" align=end a-style w="0.7">
            </m-text>
        </div>
        <m-image [src]="gs.enums.images.image99" w=0.8 h=0.8></m-image>
        <div a-style br="1px solid #E4E4E4" brRadius="0.4" h="0.9" flexGrow=1 bg="#E4E4E4" position="relative">
            <div a-style maxWidth="{{pass_data.text2 }}" h="0.7" bg="gold" brRadius="0.4" position="absolute"></div>
        </div>

    </div>
</ng-template>
<ng-template #attribute let-passedData=passdata>
    <div a-style display="flex" flexGap="0.5" mt="1">
        <m-text [type]="gs.enums.texts.paragraph1" text={{passedData.label}} mt="0"></m-text>
        <m-text [type]="gs.enums.texts.paragraph23" mb="0" text={{passedData.value}}></m-text>
        <m-text [type]="gs.enums.texts.paragraph23" mb="0" text={{passedData.price_to}}></m-text>
    </div>
</ng-template>
<ng-template #Views let-data=data>
    <div a-style display=flex align=center gap="0.8">
        <m-image [src]=data.src w=2 [hover]="data.hover_src" [title]="view" title="view"></m-image>
        <!-- <m-text [type]="gs.enums.texts.paragraph38"
      text="{{details?.meta?.activity?.count?.view? details?.meta?.activity?.count?.view : 0}} View "></m-text> -->
        <p a-style fs="1.1" color="#2479AB">{{details?.meta?.activity?.count?.view? details?.meta?.activity?.count?.view
            : 0}} View </p>
    </div>
</ng-template>
<ng-template #icons let-data=data>
    <div a-style display=flex align=center>
        <m-image src={{data.src}} w=2 (click)="activity.emit(data.type || data.label);" [title]="print" title="Print">
        </m-image>
        <m-text [type]="gs.enums.texts.paragraph75" text={{data.text}}></m-text>
    </div>
</ng-template>
<ng-template #favourite let-data=data>
    <div a-style display=flex align=center>
        <!-- <m-image [src]="data.src" w="2" [title]="favourite_title" title="favourite Product"
            [hover]="isVisible_favourite || activities?.save ?data.hover_src:data.src"
            [current_value]="activities?.save"
            (click)="isVisible_favourite=!isVisible_favourite;activities.save?gs.action('unfavorite',details):gs.action('favourite',details)">
        </m-image> -->
    </div>
</ng-template>
<ng-template #like let-data=data>
    <div a-style display=flex align=center>
        <m-image [src]="data.src" w=1.7 (clicked)="toggle(activities,'dislike','like')" title="Like"
            [title]="like_title" [hover]="data.hover_src" [count]="activities?.count" count_key="like"
            [current_value]="activities?.my_activity?.like" (click)="activity.emit(data.type || data.label);">
        </m-image>
        <!-- <m-text [type]="gs.enums.texts.paragraph75" text={{activities?.count?.like}} ml="0.5"></m-text> -->
        <p a-style fs="1.1" color="#2479AB" ml="0.5">{{activities?.count?.like}}</p>
    </div>

</ng-template>
<ng-template #likes let-data=data>
    <div a-style display=flex align=center>
        <m-image [src]="data.src" w=1.7 mr=0.8 (clicked)="toggle(activities,'dislike','like')" title="Like"
            [title]="like_title" [hover]="data.hover_src" [count]="activities?.count" count_key="like"
            [current_value]="activities?.my_activity?.like" (click)="activity.emit(data.type || data.label);">
        </m-image>
        <m-text [type]="gs.enums.texts.paragraph75" text={{activities?.count?.like}}></m-text>
    </div>
</ng-template>
<ng-template #bookmark let-data=data>
    <div a-style display="flex" align="center">
        <m-image [src]="data.src" w="2" [title]="save_title" title="Save product"
            [hover]="isVisible_bookmark || activities?.my_activity?.save ?data.hover_src:data.src"
            [current_value]="activities?.my_activity?.save"
            (click)="isVisible_bookmark=!isVisible_bookmark;activity.emit(activities?.my_activity.save?'un'+(data.type || data.label):data.type || data.label);activities.my_activity.save =!activities.my_activity.save">
        </m-image>

    </div>

</ng-template>
<ng-template #compare let-data=data>
    <div a-style display="flex" align="center">
        <m-image [src]="data.src" w="2" [hover]="data.hover_src" [title]="compare_title" title="Compare"
            [current_value]="current" (click)="isVisible_compare=!isVisible_compare;add_to_compare()"></m-image>
    </div>
</ng-template>
<ng-template #average_rating let-pass_data=pass_data>
    <div a-style display=flex flexGap=0.6>
        <m-text [type]="gs.enums.texts.paragraph11" text="{{pass_data.text1}}" a-style w="9.6"></m-text>
        <div a-style br="1px solid lightgray" brRadius="0.4" h="0.5" flexGrow=1 bg="#b2b2b2" position="relative"
            mt="0.5">
            <div a-style w="5.5" h="0.4" bg="#006BFF" brRadius="0.4" position="absolute"></div>
        </div>
        <m-text [type]="gs.enums.texts.paragraph11" text="{{pass_data.text2}}" align=end></m-text>
    </div>
</ng-template>
<m-modal top="14.5" #share_modal>
    <m-share [title]="details?.title" [url]="'https://new.mesbro.com/generic/detail?_id='+ details?._id"></m-share>
</m-modal>
<m-modal #qr_code top="14.5" width=70>
    <m-bar-code [details]=details></m-bar-code>
</m-modal>
<m-modal #rfqadd a-style top="14.5">
    <m-add-rfq type="add_rfqNew" [details]="details"></m-add-rfq>
    <!-- <m-enquiry type="add_rfqNew"   [details]="details"></m-enquiry> -->
</m-modal>

<m-modal width=unset #id top="14.5">
    <div a-style p=2.4>
        <div a-style justify="space-between" align="center" color="#333333" pb="2.4">
            <img a-style w="50px" h="50px" src="/assets/images/assets/tick.2.gif">
            <p a-style fs="20px" pt="1.6" class="fs-16-modal">Request Submitted!</p>
        </div>
        <div a-style color="#333333" fs="13px" w=62.4 h="4.2" class="modal-width">
            <p a-style fs="1.3" color="#333" m="0" class="fs-11-modal">Your request for the latest price has been sent
                to the seller, you will get notified when the request is fulfilled</p>
        </div>
    </div>
</m-modal>
<m-modal width=unset #reportProduct top="14.5">
    <div a-style p=2.4 w="65">
        <p color="#333333" fw="500" a-style fs="1.6" mb="2.6">Report Product</p>
        <!-- <p color="#333333"  a-style fs="1.3" mb="1" class="fs-9">Reason for report</p> -->
        <div a-style>
            <!-- <form [formGroup]="form">
                    <m-form formGroupName="data" columns="3" [brNone]=false px=0 pt=0 pb=0 title_mb="0" ml="0" fs="2.4"
                        labelWeight="600" justify_title=center justify="flex-end" display="flex" w="100%">
                        <textarea br="1px solid #b2b2b2" p="1" bg="#fff" class="text-area-height" brRadius="0.6" a-style h=10 w=100% label=""  placeholder="lorem50" ></textarea>
                    </m-form>
                </form> -->
            <form [formGroup]="form">
                <m-form [brNone]=false [paddingNone]=false>
                    <m-input [type]="gs.enums.inputs.text_area" maxLength="2000" formControlName="description"
                        placeHolder="Reason for report" a-style mt="0" label="Reason for report" rows=3 px=0></m-input>
                </m-form>
            </form>
        </div>
        <div display="flex" justify="flex-end" a-style mt="2.4">
            <!-- <button a-style py=0.5 px=1.4 brRadius="0.6" br="0.5px solid #05A6F0" class="button-modal" bg="#CFEEFC">Submit</button> -->
            <!-- <m-button [types]="gs.enums.buttons.button87" text="Submit" (click)="report_product();"></m-button> -->
            <button a-style cursor="pointer" (click)="report_product();closeModal()" class="button-modal" bg="#ffffff"
                br="1px solid #05A6F0" px=1.8 py=0.5 my=0.9 brRadius="0.6">Submit</button>
        </div>
    </div>
</m-modal>

<m-modal #printDetail a-style height=100 width="70" m=2 top="14.5">
    <m-print [details]="details"></m-print>
</m-modal>