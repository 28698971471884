import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Texts } from 'src/enums/texts';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-text',
  templateUrl: './text.component.html',

})
export class TextComponent implements OnInit {


  @Input() type = undefined;
  @Input() text: any;
  @Input() align: any;
  @Input() text_align: any;
  @Input() host: any;
  @Input() mt: any;
  @Input() mb: any = 0;
  @Input() ml: any;
  @Input() my: any;
  @Input() mx: any;
  @Input() fw: any;
  @Input() title: any;
  @Input() justify: any;
  @Input() gap: any;

  @Input() innerHTML: any = undefined;




  @Input() mr: any;
  @Input() h: any;
  @Input() py: any;
  @Input() w: any;
  @Input() textleft: any;
  @Input() bg: any;
  @Input() bottom: any;
  @Input() brBottom: any;
  @Input() maxWidth: any;
  @Input() color: any;
  @Input() minWidth: any;
  @Input() minHeight: any;
  @Input() position: any;
  @Input() flexGrow: any;
  @Input() overflow: any;
  @Input() p: any;
  @Input() pb: any;
  @Input() pt: any;
  @Input() pl: any;
  @Input() px: any;
  @Input() textCenter: any;
  @Input() marginTop: any;
  @Input() textWrap: string = ""
  @Input() wordWrap: string = ""
  @Input() cursor: string = ""
  @Input() ellipsis: any;
  @Input() wordBreak: string = "";
  @Input() brRadius: string = "";
  @Input() brTopLeftRadius: string = "";
  @Input() brTopRightRadius: string = "";
  @Input() brBottomRightRadius: string = "";
  @Input() brBottomLeftRadius: string = "";
  @Input() lineHeight: string = "";
  @Input() fs: any = "";
  @Input() right: any = "";
  @Input() lineClamp: any;






  // lineHeight

  @Input() hover: any = undefined;
  mouse_over: boolean = false;
  @Input() current_value = false;



  @HostBinding('style.display') display: any;


  constructor(public gs: GlobalService,) { }

  ngOnInit(): void {
    this.display = this.host
  }

}
