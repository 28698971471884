<div class="modal" a-style w="63.2" br="1px solid lightgray" p="1.5" brRadius="0.4" bg="white" position="fixed" top="6.3" zIndex=10000 right="0.7" bg="white" shadow="0 2px 10px rgb(0 0 0 / 20%)">
    <m-input placeHolder="Search Language" [type]="gs.enums.inputs.text" px=0 prefix="https://mesbro.in/assets/images/language-icon.svg" postfix="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/icons/search-white.svg" postfix_bg="#1d8ecf" outer_pr="0" postfix_px="1" [formControl]="search"></m-input>
    <div a-style w="" h="32.2" display=flex flexWrap=wrap p="1.5" br="1px solid #f1f1f1" brRadius="0.4" overflowY="auto" my="1.6">
        <ng-container *ngFor="let item of languages | search : search.value " [ngTemplateOutlet]="language" [ngTemplateOutletContext]="{passedData : item}"></ng-container>
    </div>
    <m-input (ngModelChange)="select_country($event)" label="Select Currency" [formControl]="country" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" bindLabel="country" placeHolder="Select Currency" cursor="pointer" px=0 [list]="currencyList" [columns]="1/2"></m-input>
    <!-- <m-input label="Country *" [type]="gs.enums.inputs.dropdown" dropdownHeight="20" dropdownHeight="factorDropdown" [list]="currencyList" [columns]="1/2" mt=1.8></m-input> -->
</div>

<ng-template #language let-passedData="passedData">
    <span w=33% a-style [style.fontWeight]="(gs.$language | async) == passedData? 500 : 300  " w="33%" fs="1.3" cursor=pointer (click)="select_language(passedData)">{{passedData}}</span>
</ng-template>  