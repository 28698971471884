import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-category-card',
  templateUrl: './category-card.component.html',
  styles: [
  ]
})
export class CategoryCardComponent implements OnInit {

  @Output() previous_button = new EventEmitter();
  @Output() select = new EventEmitter();
  @Input() list: any;
  @Input() type: any;
  @Input() card_type: any = 'categoryCard1';
  @Input() search_term: any;
  @Input() search_term1: any;
  @Input() detail: any



  queryParams = {
    step: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'ProductList'>'Primary',
    page: 0,
    type: '',
    org_id: '',
  }
  hovered_id = undefined;

  constructor(public gs: GlobalService, public router: Router, public ar: ActivatedRoute) { }
  ngOnInit(): void {
    // console.log(this.search_term);

    this.ar.queryParams.subscribe((params: any) => {
      this.queryParams = params;
    })

  }
  onKeyUp(event: any) {

  }


  redirectTo() {
    this.gs.router.navigate(['generic/form'], { queryParams: { type: 'product', step: 'Type', org_id: this.queryParams.org_id, application: 'Product' } });
  }
}