import { HttpProviderService } from 'src/mesbro/services/http-provider.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/mesbro/services/global.service';
import { snakeCase } from 'snake-case';
import { deleted_categories } from 'src/mesbro/modules/shared/components/side-bar/side-bar-data';
  
@Component({
  selector: 'm-attribute-form',
  templateUrl: './attribute-form.component.html'
})
export class AttributeFormComponent implements OnInit {
  @Input() width: string = '';
  @Input() height: string = '';

  media_list = [{ aspect_ratio: 1, name: 'faded' }, { aspect_ratio: 1, name: 'colored' }, { aspect_ratio: 1, name: 'image' }]
  dropdowns = {
    applications: ['Product', 'Service'],
    headings: ['Basic Details', 'Technical Details', 'Other Details', 'Warranty Details'],
    types: ['Text', 'Dropdown', 'Number', 'Date', 'Tags'],
    structure_types: ['Category', 'Type', 'Fixed'],
    structure_sub_types: ['Primary', 'Secondary', 'Tertiary', 'Type'],
    confirmation: ['Yes', 'No'],
    filter: ['Yes', 'No'],
    Primary: [],
    Secondary: [],
    Tertiary: []
  }
  attribute_form = {
    group: '',
    title: '',
    type: '',
    columns: '',
    units: [],
    index:0,
    filter: 'No',
    multiple: 'No',
    validations: {
      required: 'No'
    },
    data: {
      items: []
    }
  }


  form: FormGroup;

  selected = {
    group: undefined,
    attribute: undefined
  };

  toggle = true;

  constructor(public gs: GlobalService, public fb: FormBuilder, public ar: ActivatedRoute, public hps: HttpProviderService) { }

  toggle_value(){
    this.toggle = false;
    setTimeout(() => {
      this.toggle = true;
    }, 50);
  }
  queryParams = {
    type: <'Category' | 'Type' | 'Fixed' | undefined>undefined,
    sub_type: <'Primary' | 'Secondary' | 'Tertiary' | 'Type' | 'Fixed' | undefined>undefined,
    _id: <string | undefined>undefined,
    application: <string | undefined>undefined,
    group: <'attribute' | 'image' | 'description' | 'faq' | 'application' | 'tags' | undefined>undefined
  }

  async upload_doc(type: string, event: any) {
    let selected_file = await event.target.files[0];
    let form_data = new FormData()
    let data = await this.gs.hps.post('', '', 'upload', form_data)
  }

  ngOnInit(): void {
    this.ar.queryParams.subscribe(async (params: any) => {
      this.queryParams = params;
      let initial_value = undefined;
      if (this.queryParams._id) {
        initial_value = await this.gs.hps.get('generic', 'structure', this.queryParams._id);
      }
      this.initializeForm();
      if (initial_value) {
        this.set_initial_attributes(initial_value)
        this.form.patchValue(initial_value)
      }
      this.set_initial_lists()
    })
  }




  initializeForm() {
    this.form = this.fb.group({
      _id: this.queryParams._id || null,
      title: [null, this.gs.form_validations.required('Title')],
      application: this.queryParams.application || null,
      component: this.queryParams.type || null,
      type: this.queryParams.type || null,
      unit: 'Units',
      sub_type: this.queryParams.sub_type || null,
      parents: this.fb.group({
        primary: [null, this.queryParams.sub_type == 'Primary' ? [] : this.gs.form_validations.required('Primary Category')],
        secondary: [null, this.queryParams.sub_type == 'Primary' || deleted_categories[this.queryParams.application || '']?.includes('Secondary') || this.queryParams.sub_type == 'Secondary' ? [] : this.gs.form_validations.required('Secondary Category')],
        tertiary: [null, (this.queryParams.sub_type == 'Primary' || this.queryParams.sub_type == 'Secondary' || deleted_categories[this.queryParams.application || '']?.includes('Tertiary') || this.queryParams.sub_type == 'Tertiary') ? [] : this.gs.form_validations.required('Tertiary Category')],
        parent: null,
      }),
      attributes: this.fb.group({}),
      tags: [],
      description: null,
      images: this.image_group,
    })
  }

  get image_group() {
    return this.fb.group({
      faded: '',
      colored: '',
      image: ''
    })
  }

  get attribute_group() {
    let blank_array: any = []
    return this.fb.group({
      title: '',
      group: '',
      index: 0,
      columns: 4,
      validations: this.fb.group({
        required: 'No',
      }),
      type:  [this.gs.enums.inputs.dropdown,this.gs.form_validations.required('Type')],
      filter: 'No',
      multiple: 'No',
      units: [],
      display: this.fb.group({
        hide_label: '',
        hide_field: '',
        hide_in_public_view: '',
      }),
      data: this.fb.group({
        items: this.fb.control([]),
        master: this.fb.group({
          application: null,
          component: null
        })
      })
    })
  }

  get attributes(): FormGroup {
    return <FormGroup>this.form.get('attributes')
  }

  add_group(heading: string) {
    this.attributes.addControl(heading, this.fb.group({}));
    // console.log(this.attributes)
  }

  remove_group(heading: string) {
    this.attributes.removeControl(heading);
  }

  async set_initial_attributes(details: any) {
    let groups = details.attributes;
    if (groups) {
      Object.keys(groups).forEach(group => {
        this.add_group(group);
        let attributes = groups[group];
        Object.keys(attributes).forEach(attribute => {
          this.add_attribute(group, attribute);
        })
      })
    }
  }

  add_attribute(heading: string, attribute_name: string) {
    if (!heading || !attribute_name) {
      return;
    }
    heading = snakeCase(heading)
    attribute_name = snakeCase(attribute_name)
    let heading_group = (<FormGroup>this.attributes.get(heading))
    if (!heading_group) {
      this.add_group(heading);

      heading_group = (<FormGroup>this.attributes.get(heading))
    }
    heading_group.addControl(attribute_name, this.attribute_group);
    this.attribute_form.index = Object.keys(heading_group.controls).length;
    heading_group?.get(attribute_name)?.patchValue(JSON.parse(JSON.stringify(this.attribute_form)))
    this.attribute_form.data.items = []
    this.attribute_form.title = ''
    this.attribute_form.units = []
  }

  remove_attribute(heading: string | null, attribute_name: string | null) {
    console.log(heading, attribute_name)
    if (!heading || !attribute_name) {
      return;
    }
    (<FormGroup>this.attributes.get(heading)).removeControl(attribute_name);
  }

  async set_initial_lists() {
    if (this.queryParams.sub_type != 'Primary' && this.queryParams.sub_type != 'Fixed') {
      this.dropdowns.Primary = (await this.gs.get_list('structure', 'Category', 'Primary', this.queryParams.application, [], { size: 2000 })).list
      if (this.queryParams.sub_type != 'Secondary') {
        if (this.form.value.parents.primary) {
          this.dropdowns.Secondary = (await this.gs.get_list('structure', 'Category', 'Secondary', this.queryParams.application, [{ 'parents.primary': this.form.value.parents.primary }], { size: 2000 })).list
          if (this.queryParams.sub_type != 'Tertiary') {
            if (this.form.value.parents.secondary) {
              this.dropdowns.Tertiary = (await this.gs.get_list('structure', 'Category', 'Tertiary', this.queryParams.application, [{ 'parents.secondary': this.form.value.parents.secondary }], { size: 2000 })).list
            }
          }
        }
      }
    }
  }

  async refresh_list(category: 'Primary' | 'Secondary' | 'Tertiary') {
    setTimeout(async () => {
      let custom_filters = []
      if (category == 'Secondary') {
        custom_filters.push({ 'parents.primary': this.form.value.parents.primary })
      }
      if (category == 'Tertiary') {
        custom_filters.push({ 'parents.secondary': this.form.value.parents.secondary })
      }
      this.dropdowns[category] = (await this.gs.get_list('structure', 'Category', category, this.queryParams.application, custom_filters, {size:2000})).list
    }, 300);

  }

  set_parent(body: any) {
    if (this.queryParams.sub_type == 'Secondary') {
      body.parents.parent = this.dropdowns.Primary.find((e: any) => e._id == body.parents.primary)
    }
    if (this.queryParams.sub_type == 'Tertiary') {
      body.parents.parent = this.dropdowns.Secondary.find((e: any) => e._id == body.parents.secondary)
    }
    if (this.queryParams.sub_type == 'Type') {
      body.parents.parent = this.dropdowns.Tertiary.find((e: any) => e._id == body.parents.tertiary) || this.dropdowns.Secondary.find((e: any) => e._id == body.parents.secondary) || this.dropdowns.Primary.find((e: any) => e._id == body.parents.primary)
    }
  }

  async submit() {
    let body: any = { ...this.form.value };

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      console.log(this.form)
      return;
    }


    try {
      this.set_parent(body);

      let response = undefined;
      if (!this.form.value._id && !this.form.invalid) {
        response = this.hps.post('generic', 'structure', 'add', body)
        this.gs.toast('Structure Added', 'Added ' + this.queryParams.sub_type + ' successfully', 'success');

      } else {
        response = this.hps.put('generic', 'structure', undefined, body)
        this.gs.toast('Structure Modified', 'Modified ' + this.queryParams.sub_type + ' structure successfully', 'success');

      }
      this.gs.router.navigateByUrl(`/dashboard/attribute/list?type=${this.queryParams.type}&sub_type=${this.queryParams.sub_type}&application=${this.queryParams.application}`)
      return response
    } catch (error) {
      console.log(error);
    }
  }



}
