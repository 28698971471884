export enum Buttons {
    primary = 'primary',
    second = 'second',
    third = 'third',
    four = 'four',
    five = 'five',
    six = 'six',
    ghost = 'ghost',
    button0 = 'button0',
    button1 = 'button1',
    button2 = 'button2',
    button3 = 'button3',
    button4 = 'button4',
    button5 = 'button5',
    button6 = 'button6',
    button7 = 'button7',
    button8 = 'button8',
    button9 = 'button9',
    button10 = 'button10',
    button11 = 'button11',
    button12 = 'button12',
    button13 = 'button13',
    button14 = 'button14',
    button15 = 'button15',
    button16 = 'button16',
    button17 = 'button17',
    button18 = 'button18',
    button19 = 'button19',
    button20 = 'button20',
    button21 = 'button21',
    button22 = 'button22',
    button23 = 'button23',
    button24 = 'button24',
    button25 = 'button25',
    button26 = 'button26',
    button27 = 'button27',
    button28 = 'button28',
    button29 = 'button29',
    button30 = 'button30',
    button31 = 'button31',
    button32 = 'button32',
    button33 = 'button33',
    button333 = 'button333',
    button34 = 'button34',
    button35 = 'button35',
    button36 = 'button36',
    button37 = 'button37',
    button38 = 'button38',
    button39 = 'button39',
    button40 = 'button40',
    button41 = 'button41',
    button42 = 'button42',
    button43 = 'button43',
    button44 = 'button44',
    button45 = 'button45',
    button46 = 'button46',
    button47 = 'button47',
    button48 = 'button48',
    button49 = 'button49',
    button50 = 'button50',
    button51 = 'button51',
    button52 = 'button52',
    button53 = 'button53',
    button54 = 'button54',
    button55 = 'button55',
    button56 = 'button56',
    button57 = 'button57',
    button58 = 'button58',
    button59 = 'button59',
    button60 = 'button60',
    button61 = 'button61',
    button62 = 'button62',
    button63 = 'button63',
    button64 = 'button64',
    button65 = 'button65',
    button66 = 'button66',
    button67 = 'button67',
    button68 = 'button68',
    button69 = 'button69',
    button70 = 'button70',
    button71 = 'button71',
    button72 = 'button72',
    button73 = 'button73',
    button74 = 'button74',
    button75 = 'button75',
    button76 = 'button76',
    button77 = 'button77',
    button78 = 'button78',
    button79 = 'button79',
    button80 = 'button80',
    button81 = 'button81',
    button82 = 'button82',
    button83 = 'button83',
    button84 = 'button84',
    button85 = 'button85',
    button87 = 'button87',
    button86 = 'button86',
    button88 = 'button88',
    button89 = 'button89',
    button90 = 'button90',
    button91 = 'button91',
    button92 = 'button92',
    button93 = 'button93',
    button94 = 'button94',
    button95 = 'button95',
    button96 = 'button96',
    button97 = 'button97',
    button98 = 'button98',
    button99 = 'button99',
    button100 = 'button100',
    button101 = 'button101',
    button102 = 'button102',







}
