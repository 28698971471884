
<m-header></m-header>
<div a-style display="flex" >
  <div *ngIf="params.type !='organization'" a-style minWidth=56.1 maxWidth=56.1 a-style brRight="1px solid #e2e2e2" h="calc(100vh - 7.5rem )" overflowY="scroll"
    class="no_scroll_bar">

    <div a-style position="relative">
      <!-- cover photo  -->
      <div a-style h="25.1">
        <!-- <img *ngIf="params.type=='organization'  "
          [src]="users?.activeUser?.personal_details?.cover_picture?('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.cover_picture):'assets/images/assets/receipeimage.png'"
          alt="" h=25 w="100%" a-style w="100%"> -->
        <ng-container>
          <img *ngIf="params.type=='product' || params.type=='details' || params.type=='dashboard'"
            [src]="org?.files?.cover_picture?('https://new-api.mesbro.com/uploads/'+org?.files?.cover_picture) : '../assets/images/images/Group 94192.svg'"
            alt="" h=25 w="100%" a-style w="100%">
        </ng-container>
      </div>
      <!-- profile picture -->
      <div a-style h="13" position="absolute" left="39%" top="8%" p="2.4" bg="#fff" brRadius="0.6">
        <!-- <img *ngIf="params.type=='organization'  "
          [src]="users?.activeUser?.personal_details?.profile_picture?('https://new-api.mesbro.com/uploads/'+users?.activeUser?.personal_details?.profile_picture):'assets/images/assets/dell.png'"
          a-style bg="#fff" alt="" h="8.2" w="8.2" brRadius="50%"> -->
        <img *ngIf="params.type=='product' || params.type=='details' || params.type=='dashboard'"
          [src]="org?.files?.profile_picture?('https://new-api.mesbro.com/uploads/'+org?.files?.profile_picture) : '../assets/images/images/Group 94192.svg'"
          a-style bg="#fff" alt="" h="8.2" w="8.2" brRadius="50%">
      </div>

      <!-- name & profession -->
      <div *ngIf="params.type !='organization'" a-style position="absolute" bottom="1.6" h="7" style="background-color:rgba(0, 0, 0, 0.5);" color="white"
        p="1.2" w="100%" zIndex="1">
        <div a-style align="center">
          <!-- <p *ngIf="params.type=='organization'  " a-style color="#ffffff" fs="2" m="0" zIndex=10000
            style="text-transform: camalcase;">
            {{users?.activeUser?.personal_details?.first_name}} {{users?.activeUser?.personal_details?.last_name}}</p>
          <p *ngIf="params.type=='organization'" a-style color="#ffffff" fs="1.3" m="0" zIndex=10000>Profession </p> -->
          <p *ngIf="params.type=='product' || params.type=='details' || params.type=='dashboard'" a-style
            color="#ffffff" fs="2" m="0" zIndex=10000 style="text-transform: camalcase;">
            {{org?.basic_details?.legal_name}}</p>
          <p *ngIf="params.type=='product'|| params.type=='details' || params.type=='dashboard'" a-style color="#ffffff"
            fs="1.3" m="0" zIndex=10000>
            {{org?.basic_details?.type}}</p>
        </div>
      </div>
    </div>

    <!-- icons -->
    <div a-style pt="1.6">
      <!-- <div a-style display="flex" gap="2.4" justify="center">
        <m-button *ngIf="params.type=='dashboard' || params.type=='product'"  [types]="gs.enums.buttons.button100" text="Edit Profile"
          src="../assets/images/assets/color-pencils.png"
          (click)="this.gs.router.navigate(['group','form'] ,{queryParams:{application:'idm',type:'organization', field:'We Are', id:params.id}})">
        </m-button>
        <m-button *ngIf="params.type=='organization'"  [types]="gs.enums.buttons.button100" text="Edit Profile"
          src="../assets/images/assets/color-pencils.png" (click)="gs.router.navigate(['idm','user','details'])">
        </m-button>
        <m-button *ngIf="params.type=='organization' || params.type=='dashboard' || params.type == 'product'"  [types]="gs.enums.buttons.button100" text="Boost Profile"
          src="../assets/images/Boost_Profile.svg"></m-button>
        <m-button *ngIf="params.type=='dashboard' || params.type == 'product' " [types]="gs.enums.buttons.button100" text="Public View"
          src="assets/images/assets/eye-self.svg" (click)="redirect_to_detail(org)"></m-button>
        <m-button *ngIf="params.type=='organization'" [types]="gs.enums.buttons.button100" text="Public View"
          src="assets/images/assets/eye-self.svg" ></m-button>
      </div> -->
      <!-- {{details|json}} -->


      <div *ngIf="params.type=='details'" a-style display="flex" gap="2.4" justify="center">
        <div a-style br="1px solid #2479AB" p="1" brRadius="0.5" w="4.5" h="4.5">
          <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2" (click)="goToLink('https://mesbro.com/new-chat/details?type=conversation')">
        </div>
        <div a-style br="1px solid #2479AB" p="1" brRadius="0.5" w="4.5" h="4.5">
          <img src="../assets/images/call.svg" alt="" h='2' a-style w="2">
        </div>
        <div a-style br="1px solid #2479AB" p="1" brRadius="0.5" w="4.5" h="4.5" (click)="block_org(org?._id)">
          <img src="../assets/images/block.svg" alt="" h='2' a-style w="2">
        </div>
        <div a-style br="1px solid #2479AB" p="1" brRadius="0.5" w="4.5" h="4.5" (click)="goToLink('https://mesbro.com/mail/home?type=inbox')">
          <img src="../assets/images/email.svg" alt="" h='2' a-style w="2">
        </div>
        <div a-style br="1px solid #2479AB" p="1" brRadius="0.5" w="4.5" h="4.5">
          <img src="../assets/images/follow.svg" alt="" h='2' a-style w="2">
        </div>

      </div>


      <!-- about -->
      <div *ngIf=" params.type=='dashboard' || params.type=='details' || params.type=='product'" a-style mb="2.4"
        mt="2.4" align="center">
        <!-- <p  a-style fs="1.3"  m="0" class=lineClamp3 maxWidth="46.5" align="left">Sed ut perspiciatis unde omnis iste natus 
          error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architect Sed ut 
          perspiciatis unde omnis Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium inventore veritatis et quasi architect Sed ut
           perspiciatis unde omnis Sed ut perspiciatis</p>  -->

        <p *ngIf="org?.basic_details?.description " a-style fs="1.3" m="0" class=lineClamp3 maxWidth="46.5"
          align="left">{{org?.basic_details?.description}}</p>
      </div>

      <div a-style h="calc(100vh - 7.5rem - 25rem - 8rem - 10rem)" overflowY=scroll class=no_scroll_bar>
        <!-- contact info -->
        <div a-style mb="1.2" ml="4" mt="2.4" h="calc(100vh - )">
          <!-- <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0">Founder & CEO at Mesbro Technologies Pvt. Ltd.</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Founder & CEO at Mesbro Technologies Pvt. Ltd.
              </p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Founder & CEO at Ajgaonkar Infotech LLP</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Founder & CEO at Sharvani Engineering
                Corporation
              </p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Studied PGD in Cyber Crime Investigation at
                Asian
                School Of Cyber Laws</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Studied PGD in Cyber Crime Investigation at
                Asian
                School Of Cyber Laws</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Studied PGD in Cyber Crime Investigation at
                Asian
                School Of Cyber Laws</p>
            </div>
          </div>
          <div
            *ngIf="params.type == 'organization' && 'org?.location?.city' && 'org?.location?.region' && 'org?.location?.country'"
            a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0">Lives in {{org?.location?.city}} , {{org?.location?.region}} ,
                {{org?.location?.country}}</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization' " a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">From Dadar, Mumbai</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Visited at Shirdi</p>
            </div>
          </div>
          <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Married to Madhuri Bhaskar Ajgaonkar</p>
            </div>
          </div> -->

          <div
            *ngIf="params.type == 'dashboard' || params.type == 'product' || params.type=='details'&& 'org?.location?.city' && 'org?.location?.region' && 'org?.location?.country'"
            a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Located at {{org?.location?.city}} ,
                {{org?.location?.region}} ,
                {{org?.location?.country}} </p>
            </div>
          </div>
          <div *ngIf="params.type == 'dashboard' || params.type == 'product' || params.type=='details' && 'org?.contact_details?.mobile'" a-style
            display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">{{org?.contact_details?.mobile}}</p>
            </div>
          </div>
          <div *ngIf="params.type == 'dashboard' || params.type == 'product' || params.type=='details' && 'org?.contact_details?.email'" a-style
            display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">{{org?.contact_details?.email}}</p>
            </div>
          </div>
          <div *ngIf="params.type == 'dashboard' || params.type == 'product'" a-style display="flex" gap="1.6"
            mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Followed By 5,00,169 people</p>
            </div>
          </div>
          <div *ngIf="params.type == 'dashboard' || params.type == 'product'  || params.type=='details' && 'org?.contact_details?.website'" a-style
            display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">{{org?.contact_details?.website}}</p>
            </div>
          </div>

          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Year of Established 2010</p>
            </div>
          </div>
          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Total Employees Upto 10 People</p>
            </div>
          </div>
          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Annual Turnover Rs 50 Lakh - 1 Crore</p>
            </div>
          </div>
          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Legal Status Individual - Proprietor</p>
            </div>
          </div>
          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Import Export Code 12345678956</p>
            </div>
          </div>
          <div *ngIf="params.type=='details'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">GSTIN 12345678956</p>
            </div>
          </div>

          <!-- <div *ngIf="params.type == 'organization'" a-style display="flex" gap="1.6" mb="1.7">
            <div>
              <img src="../assets/images/assets/product/g25.svg" alt="" h='2' a-style w="2">
            </div>
            <div>
              <p a-style fs="1.3" color="#707070" m="0" maxWidth="44.5">Following 5,00,169 people</p>
            </div>
          </div> -->
        </div>

        <div *ngIf="params.type=='dashboard' || params.type=='product'" a-style br="1px solid #e4e4e4" p="1.6" brRadius="0.5" mx="4.8">
          <p a-style fs="1.3" maxWidth="43.3">Your current subscription validity is as follows, you can upgrade it by
            clicking on Upgrade Subscription button.</p>
          <div a-style mt="1.7">
            <div a-style display=flex gap=.5>
              <p a-style fs="1.1">MBIN :</p>
              <p a-style fs="1.1" color="#05A6F0">{{org?.meta?.support?.mbin}}</p>
            </div>
            <div a-style display=flex gap=.5 mt="1.7">
              <p a-style fs="1.1">Validity :</p>
              <p a-style fs="1.1" color="#81BC06">Jun 30, 2021</p>
            </div>
            <div a-style display="flex" justify="flex-end">
              <button a-style br="1px solid #2479AB" py="0.5" brRadius="0.5" px="1.3" bg="white"
                (click)="this.gs.router.navigate(['payment','feature'] , {queryParams:{id:params.id}})">Upgrade
                Subscription</button>
            </div>
          </div>
        </div>

        <!-- gallary -->
        <!-- <div *ngIf="params.type == 'organization'  " a-style display="flex" gap="0.2" flexWrap="wrap" h="24"
        overflowY=scroll class="no_scroll_bar">
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
        <div>
          <img src="../assets/images/assets/bharat.jpg" alt="" h='11.4' bg="#fff" brRadius="0.6" br="1px solid #e4e4e4"
            bg="transparent" opacity="1" a-style w="11.4">
        </div>
      </div> -->

        <!-- button -->
        <!-- <div *ngIf="params.type == 'product' || params.type == 'organization'" a-style display=flex justify=space-between
        mt="10">

        <div a-style br="1px solid #F35325" brRadius="0.6" px=".5" py="0.5">
          <p a-style fs="1.3" (click)="this.gs.router.navigate(['payment','feature'] , {queryParams:{id:params.id}})">
            Upgrade Subscription</p>
        </div>
        <div a-style br="1px solid #F35325" brRadius="0.6" px=".5" py="0.5">
          <p a-style fs="1.3"
            (click)="this.gs.router.navigate(['group','form'] ,{queryParams:{application:'idm',type:'organization', field:'We Are', id:params.id}})">
            Edit Organization</p>
        </div>
        <div a-style br="1px solid #F35325" brRadius="0.6" px=".5" py="0.5">
          <p a-style fs="1.3">Boost Organization</p>
        </div>
      </div> -->


      </div>

    </div>
  </div>

  <div a-style w=100vw >
    <m-genric-header></m-genric-header>
    <router-outlet ></router-outlet>
  </div>

</div>