<div *ngIf="type=='video'">
  <div a-style w=30 class="box" br="1px solid #E4E4E4" brRadius="0.6" shadow=" hov:4px 3px 6px 0 #f1f1f1">
    <div a-style position=relative brTopLeftRadius=0.6 brTopRightRadius=0.6>
      <video controls a-style w=30 h=16.7 shadow=" hov:0 3px 6px 0 #f1f1f1" brTopLeftRadius=0.6 brTopRightRadius=0.6>
        <source [src]="'https://new-api.mesbro.com/uploads/'+(detail?.basic?.accessUrl)" type="video/mp4">
      </video>
    </div>
    <div (mouseover)="icon=true" (mouseleave)="icon=false" w=30 h=16.7>
      <div a-style mx="1.2" mt="1.2" w="27" h="4.2">
        <p a-style fs="1.3" fw="400" color="#333333"
          style="overflow: hidden;text-overflow: ellipsis;max-width: 277px;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">
          {{detail?.basic?.title}}</p>
      </div>
      <div *ngIf="icon" a-style bg="white" mx="1.2" bg="white" display="flex" align="center" gap="3.2" h=5.8 w="27.5"
        pt="2" px="9" pb="2.3" brRadius="0.6" justify="space-around" position="absolute" top="78%">
        <img src="../assets/images/assets/star_.gif" alt="" a-style w="2" h="2" cursor="pointer">
        <img src="../assets/images/assets/watch later.svg" alt="" a-style w="2" h="2" cursor="pointer">
        <img src="/assets/images/assets/new-chat/share_color.svg" alt="" a-style w="2" h="2" cursor="pointer">
      </div>
    </div>
  </div>
</div>

<!-- service home card -->
<div *ngIf="type=='servicecard'">
  <div a-style br="0.5px solid #E4E4E4" w="24.4" h="32.0" p="1" brRadius="0.6" mt="2.4"
    (click)="redirect_to_service(detail);getViewCount(detail?._id,'business','services');gs.history('history',detail._id,'business','services','add-service-to-history')" position="relative">
    <img src="https://new-api.mesbro.com/uploads//{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style h="22" w="22"
      brRadius="0.6">
    <p a-style color="#2479AB" class="lineClamp" h="2" fs="1.3">INR {{detail?.Basic_Details?.attributes[3]?.value}} -
      {{detail?.Basic_Details?.attributes[4]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="3.6" innerHtml="{{detail?.Basic_Details?.attributes[0]?.value}}"></p>
    <div a-style display="flex" justify="space-between" mt="0.5">
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style h="1.2"
        w="2.0">
    </div>
  </div>
</div>
<div *ngIf="type=='servicecardwithtoggle'">
  <div a-style br="0.5px solid #E4E4E4" h="28.4" w="43.0" p="1" brRadius="0.6" mt="2.4" position="relative">
    <img src="https://new-api.mesbro.com/uploads//{{detail?.Media.attributes[0].value[0]}}" alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_service(detail);getViewCount(detail?._id,'business','services')">
    <div a-style display="flex" w="39" position="absolute" top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteService(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="4" innerHtml="{{detail?.Service_Details?.attributes[0]?.value}}"></p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style h="1.2"
        w="2.0">
    </div>
  </div>
</div>
<!-- https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}} -->
<!-- Recipe Card -->
<div *ngIf="type=='recipecard'">
  <div *ngIf="detail?.Basic_Details != null" a-style w="43" br="0.5px solid #E4E4E4" brRadius="0.6" p="1" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}" alt="" a-style w="41"
      h="18.2" brRadius="0.6" (click)="redirect_to_recipe(detail);getViewCountRecipe(detail?._id,'food','recipes');gs.history('history',detail._id,'food','recipes','add-recipe-to-history')">
    <img *ngIf="detail?.basic?.primary?.name == 'Non Vegetarian'" src="../assets/images/Icon_Bank/Nonveg_Coloured.svg"
      a-style w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <img *ngIf="detail?.basic?.primary?.name == 'Vegetarian'" src="../assets/images/Icon_Bank/Veg_Coloured.svg" a-style
      w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <img *ngIf="detail?.basic?.primary?.name == 'Eggetarian'" src="../assets/images/Icon_Bank/Eggeterian_Coloured.svg"
      a-style w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <p a-style fs="1.3"  color="#2479AB">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="4" mt="0.2" innerHtml="{{detail?.Basic_Details?.attributes[1]?.value}}"></p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.5">
      <div a-style display="flex" gap="1.6">
        <div a-style display="flex" align="center" gap="0.5"> 
          <img src="../assets/images/Icon_Bank/Serve_People_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2">{{detail?.Basic_Details?.attributes[5]?.value}} Persons</p>
        </div>
        <!-- <div a-style display="flex" align="center" gap="0.5">
          <img src="../assets/images/Icon_Bank/Calories_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2">{{detail?.Nutrition_Facts?.attributes[1]?.value}}</p>
        </div> -->
        <div *ngIf="detail?.Cuisine?.attributes[0]?.value" a-style display="flex" align="center" gap="0.5">
          <img src="../assets/images/Icon_Bank/Cuisine_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2"> {{detail?.Cuisine?.attributes[0]?.value}}</p>
        </div>
      </div>
      <div a-style display="flex" gap="0.3">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77826.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77826.png" a-style w="1.2" h="1.2">
      </div>
    </div>
  </div>
</div>
<div *ngIf="type=='recipecardwithtoggle'">
  <div a-style w="43" br="0.5px solid #E4E4E4" brRadius="0.6" p="1" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}" alt="" a-style w="41"
      h="18.2" brRadius="0.6" (click)="redirect_to_recipe(detail)">
    <div a-style display="flex" w="39" position="absolute" top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_recipe_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteRecipe(detail?._id)">
      </div>
    </div>
    <img *ngIf="detail?.basic?.primary?.name == 'Non Vegetarian'" src="../assets/images/Icon_Bank/Nonveg_Coloured.svg"
      a-style w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <img *ngIf="detail?.basic?.primary?.name == 'Vegetarian'" src="../assets/images/Icon_Bank/Veg_Coloured.svg" a-style
      w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <img *ngIf="detail?.basic?.primary?.name == 'Eggetarian'" src="../assets/images/Icon_Bank/Eggeterian_Coloured.svg"
      a-style w="2.5" h="2.5" position="absolute" bottom="11.5" left="2">
    <p a-style fs="1.3" mt="1" color="#2479AB">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="4" mt="0.2">{{detail?.Basic_Details?.attributes[1]?.value}}</p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.5">
      <div a-style display="flex" gap="1.6">
        <div a-style display="flex" align="center" gap="0.5">
          <img src="../assets/images/Icon_Bank/Serve_People_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2">{{detail?.Basic_Details?.attributes[5]?.value}} Persons</p>
        </div>
        <div a-style display="flex" align="center" gap="0.5">
          <img src="../assets/images/Icon_Bank/Calories_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2">{{detail?.Nutrition_Facts?.attributes[1]?.value}}</p>
        </div>
        <div a-style display="flex" align="center" gap="0.5">
          <img src="../assets/images/Icon_Bank/Cuisine_Coloured.svg" a-style w="1.4" h="1.4">
          <p a-style fs="1.3" color="#B2B2B2"> {{detail?.Cuisine?.attributes[0]?.value}}</p>
        </div>
      </div>
      <div a-style display="flex" gap="0.3">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77822.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77826.png" a-style w="1.2" h="1.2">
        <img src="../assets/images/B2B/Path 77826.png" a-style w="1.2" h="1.2">
      </div>
    </div>
  </div>
</div>

<div *ngIf="type=='reciperealated'">
  <div a-style w="43.0" h="10.3" br="0.5px solid #e4e4e4" brRadius="6px" p="1" >
    <div a-style display="flex" gap="1">
      <!-- <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' a-style w="18.6" h="8.3" brRadius="6px"> -->
      <div a-style align="left">
          <p a-style color="#333333" fs="1.3"  >{{detail?.Basic_Details?.attributes[0]?.value}}</p>
          <p a-style color="#707070" fs="1.3" class="lineClamp" innerHtml="{{detail?.Basic_Details?.attributes[1]?.value}}"> </p>
      </div>
    </div>
  </div>
</div>

<!-- article Card -->
<div *ngIf="type=='articlecard' && detail?.Basic_Detail?.attributes[0]?.value">
  <div a-style br="0.5px solid #E4E4E4" h="29.6" w="43.0" p="1" brRadius="0.6" mt="1.6">
    <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_article(detail);getViewCountarticle(detail?._id,'article','articles');gs.history('history',detail._id,'article','articles','add-article-to-history')">
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1" (click)="redirect_to_article(detail);getViewCount(detail?._id,'article','articles');gs.history('history',detail._id,'article','articles','add-article-to-history')">
      {{detail?.Basic_Detail?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="4" innerHTML="{{detail?.Basic_Detail?.attributes[1]?.value}}" mt="0.3"></p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.6">
      <div a-style display="flex" gap="1" align="center">
        <img src="https://new-api.mesbro.com/uploads/{{detail?.meta?.user?.creator?.profilePicture}}" alt="" a-style
          w="2.2" h="2.2" brRadius="50%">
        <p a-style fs="1.3">{{detail?.meta?.user?.creator?.firstName}} {{detail?.meta?.user?.creator?.lastName}}</p>
      </div>
      <p a-style color="#B2B2B2" fs="1.3">{{detail?.meta?.createdAt?.$numberLong | date : 'dd MMM YYYY'}}</p>
    </div>
  </div>
</div>
<div *ngIf="type=='articlecardwithtoggle'">
  <div a-style br="0.5px solid #E4E4E4" h="29.6" w="43.0" p="1" brRadius="0.6" mt="2.4" position="relative">
    <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_article(detail);getViewCount(detail?._id,'article','articles')">
    <div a-style display="flex" w="39" position="absolute" left="2" top="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_article_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteArticle(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1" (click)="redirect_to_article(detail);getViewCount(detail?._id,'article','articles')">
      {{detail?.Basic_Detail?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" mt=0.5 innerHTML="{{detail?.Basic_Detail?.attributes[1]?.value}}"></p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.6">
      <div a-style display="flex" gap="1" align="center">
        <img src="https://new-api.mesbro.com/uploads/{{detail?.meta?.user?.creator?.profilePicture}}" alt="" a-style
          w="1.9" h="1.9" brRadius="50%">
        <p a-style fs="1.3">{{detail?.meta?.user?.creator?.username}}</p>
      </div>
      <p a-style color="#B2B2B2" fs="1.3">{{detail?.meta?.user?.creator?.email}}</p>
    </div>
  </div>
</div>

<div *ngIf="type=='articlerealated' ">
  <div a-style w="43.0" h="10.3" br="0.5px solid #e4e4e4" brRadius="6px" p="1">
    <div a-style display="flex" gap="1">
      <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' a-style w="18.6" h="8.3" brRadius="0.6">
      <div a-style align="left">
          <p a-style color="#000000" fs="1.3" class="lineClamp1" >{{detail?.Basic_Detail?.attributes[0]?.value}}</p>
          <p a-style color="#707070" fs="1.3" class="lineClamp" ellipsis="true" w="21.4" innerHTML="{{detail?.Basic_Detail?.attributes[1]?.value}}"></p>
          <p a-style color="#707070" fs="1.3">2 Days</p>
      </div>
    </div>
  </div>
</div>

<!-- job Card -->
<div *ngIf="type=='jobCard'">
  <div a-style br="0.5px solid #E4E4E4" h="28.4" w="43.0" p="1" brRadius="0.6" mt="2.4">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}" alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_jobs(detail);getViewCountjobs(detail?._id,'job','jobs');gs.history('history',detail._id,'job','jobs','add-job-to-history')">
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" h="3.5" class="lineClamp" innerHtml="{{detail?.Job_Description?.attributes[0]?.value}}"></p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="1" align="center">
        <img src="https://new-api.mesbro.com/uploads/{{detail?.meta?.user?.creator?.profilePicture}}" a-style h="1.9" w="1.9" brRadius="50%">
        <p a-style fs="1.3" h="1.9" w="10.5" ellipsis="true"  innerHtml="{{detail?.Recruiter_Details?.attributes[0]?.value}}"></p>
      </div>
      <p a-style fs="1.3" color="#B2B2B2">{{detail?.Basic_Details?.attributes[4]?.value}} Openings</p>
    </div>
  </div>
</div>
<div *ngIf="type=='jobCardWithToggle'">
  <div a-style br="0.5px solid #E4E4E4" h="28.4" w="43.0" p="1" brRadius="0.6" mt="2.4" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}" alt="" a-style h="18.2" (click)="gs.history('history',detail._id,'job','jobs','add-job-to-history')"
      w="41.0" brRadius="0.6" (click)="redirect_to_jobs(detail)">
    <div a-style display="flex" w="39" position="absolute" left="2" top="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_job_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteJob(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" h="3.5" class="lineClamp" innerHTML="">{{detail?.Job_Description?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="1" align="center">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.9" w="1.9">
        <p a-style fs="1.3">{{detail?.Recruiter_Details?.attributes[0]?.value}}</p>
      </div>
      <p a-style fs="1.3" color="#B2B2B2">{{detail?.Basic_Details?.attributes[4]?.value}} Openings</p>
    </div>
  </div>
</div>

<div *ngIf="type=='jobsrealated'">
  <div a-style w="43.0" h="10.3" br="0.5px solid #e4e4e4" brRadius="6px" p="1" >
    <div a-style display="flex" gap="1">
      <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' a-style w="18.6" h="8.3" brRadius="0.6">
      <div a-style align="left">
          <p a-style color="#000000" fs="1.3" class="lineClamp1" >{{typeId?.basic?.name}}</p>
          <p a-style color="#707070" fs="1.3" class="lineClamp3"> {{detail?.Basic_Details?.attributes[0]?.value}}</p>
      </div>
    </div>
  </div>
</div>

<!-- propcard -->
<div *ngIf="type=='propCard'">
  <div a-style br="0.5px solid #E4E4E4" h="27.4" w="43.0" p="1" brRadius="0.6" >
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}" alt="" a-style h="18.2"
      w="41.0" (click)="redirect_to_properties(detail);getViewCountProperties(detail?._id,'property','properties')">
    <p a-style color="#2479AB" fs="1.3" h="1.9" class="lineClamp1">{{detail?.Basic_Details?.attributes[12]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="1.9" mt="0.2">{{detail?.About_Property?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between" mt="0.5">
      <p a-style fs="1.3" color="#2479AB">INR {{detail?.Basic_Details?.attributes[13]?.value}}</p>
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
    </div>
  </div>
</div>
<div *ngIf="type=='propCardwithtoggle'">
  <div a-style br="0.5px solid #E4E4E4" h="27.4" w="43.0" p="1" brRadius="0.6" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style h="18.2"
      w="41.0" (click)="redirect_to_properties(detail);getViewCount(detail?._id,'property','properties')">
    <div a-style display="flex" w="39" position="absolute" left="2" top="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_property_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteProperty(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" h="1.9" class="lineClamp1">{{detail?.Basic_Details?.attributes[12].value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="1.9" mt="0.2">{{detail?.About_Property.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between" mt="0.5">
      <p a-style fs="1.3" color="#2479AB">INR {{detail?.Basic_Details.attributes[13]?.value}}</p>
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
    </div>
  </div>
</div>

<!-- guardiancard -->
<div class="card4" *ngIf="type=='guardiancard'">
  <div a-style br="0.5px solid #E4E4E4" h="28.4" w="43.0" p="1" brRadius="0.6" (click)="redirect_to_guardian(detail)">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style h="18.2" (click)="gs.history('history',detail._id,'animal','animals','add-animal-to-history')"
      w="41.0" >
    <p a-style color="#2479AB" fs="1.3" class="lineClamp" h="3.5"  innerHtml="{{detail?.Description?.attributes[0]?.value}}">
      </p>
    <p a-style fs="1.3" class="lineClamp" h="2" innerHTML=""></p>
    <div a-style display="flex" justify="space-between">
     <p a-style fs="1.3" h="1.9" class="lineClamp" ellpsis="true">{{detail?.Basic_Details?.attributes[0]?.value}}</p>
    </div>
  </div>
</div>
<div *ngIf="type=='guardiancardwithtoggle'">
  <div a-style br="0.5px solid #E4E4E4" h="28.4" w="43.0" p="1" brRadius="0.6" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style h="18.2"
      w="41.0" (click)="redirect_to_guardian(detail);getViewCount(detail?._id,'animal','animals');gs.history('history',detail._id,'animal','animals','add-animal-to-history')">
    <div a-style display="flex" w="39" position="absolute" top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_guardian_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteGuardian(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" class="lineClamp" h="3.5" (click)="redirect_to_guardian(detail);getViewCount(detail?._id,'animal','animals');gs.history('history',detail._id,'animal','animals','add-animal-to-history')">
      {{detail?.Description?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="2" innerHTML=""></p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style h="1.2"
        w="2.0">
    </div>
  </div>
</div>



<div *ngIf="type=='actCard'">
  <div a-style br="0.5px solid #E4E4E4" h="27.2" w="43.0" p="1" brRadius="0.6" mt="2.4">
    <img src='' alt="" a-style h="18.2" w="41.0" brRadius="0.6">
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1"></p>
    <p a-style fs="1.3" class="lineClamp" mt="1" h="3.5" innerHTML=""></p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.5">
      <div a-style display="flex" gap="1" align="center">
        <img src="" alt="" a-style w="1.9" h="1.9" brRadius="50%">
        <p a-style fs="1.3">data</p>
      </div>
      <p a-style color="#B2B2B2" fs="1.3">2 Days</p>
    </div>
  </div>
</div>
<div *ngIf="type=='organizationBrochureCard'">
  <div a-style w="23.4" h="32.2" br="0.5px solid #e4e4e4" p="1">
      <img src="" alt="" a-style h="21.4" w="21.4">
      <p a-style fs="1.3" color="#2479AB">Brochure Name.pdf</p>
      <p a-style fs="1.3">2.5 MB</p>
      <p a-style fs="1.3" class="lineClamp" h="3.6">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</p>
  </div>
</div>

<!-- spiritualcard -->
<div *ngIf="type=='spiritualcard'">
  <!-- <div *ngIf="detail?.Basic_Details != null" a-style br="0.5px solid #E4E4E4" h="25.5" w="43.0" p="1" brRadius="0.6" (click)="redirect_to_spiritual(detail);getViewCountspiritual(detail?._id,'spiritual','spirituals');gs.history('history',detail._id,'spiritual','spirituals','add-spiritual-to-history')">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}" alt="" a-style h="18.2"
      w="41.0"  (click)="redirect_to_spiritual(detail);getViewCount(detail?._id,'spiritual','spirituals');gs.history('history',detail._id,'spiritual','spirituals','add-spiritual-to-history')" brRadius="0.6">
    <p a-style color="#2479AB" fs="1.3" h="1.9" class="lineClamp1" (click)="redirect_to_spiritual(detail);getViewCount(detail?._id,'spiritual','spirituals')">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" mt="0.6">{{typeId?.basic?.name}}</p>
  </div> 
</div> -->
  <div *ngIf="type=='spiritualcard2'">
  <div *ngIf="detail?.Basic_Details != null" a-style br="0.5px solid #E4E4E4" h="25.5" w="43.0" p="1" brRadius="0.6" (click)="spiritual.openModal()">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}" alt="" a-style h="18.2"
      w="41.0"  (click)="spiritual.openModal()" brRadius="0.6">
    <p a-style color="#2479AB" fs="1.3" h="1.9" class="lineClamp1" (click)="spiritual.openModal()">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" mt="0.6">{{typeId?.basic?.name}}</p>
  </div>
</div>

<div *ngIf="type=='spiritualcard'">
  <div a-style br="0.5px solid #E4E4E4" h="27.2" w="43.0" p="1" brRadius="0.6" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0].value}}" alt="" a-style h="18.2"
      w="41.0"  (click)="spiritual.openModal()">
    <div *ngIf="!gs.router.url.includes('/spiritual/home')" a-style display="flex" w="39" position="absolute" top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_spritual_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteSpritual(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" h="3.5" class="lineClamp1" (click)="redirect_to_spiritual(detail);getViewCount(detail?._id,'spiritual','spirituals')">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
  </div>
</div>
<div *ngIf="type=='spiritualrealated'">
  <div a-style w="43.0" h="10.3" br="0.5px solid #e4e4e4" brRadius="6px" p="1" >
    <div a-style display="flex" gap="1">
      <!-- <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0].value[0]}}' a-style w="18.6" h="8.3" brRadius="0.6"> -->
      <div a-style align="left">
          <p a-style color="#000000" fs="1.3" class="lineClamp1" >{{typeId?.basic?.name}}</p>
          <p a-style color="#707070" fs="1.3" class="lineClamp3"> {{detail?.Basic_Details?.attributes[0]?.value}}</p>
      </div>
    </div>
  </div>
</div>


<!-- projectcard -->
<div *ngIf="type=='projectcard'">
  <div a-style br="0.5px solid #E4E4E4" h="27.4" w="43.0" p="1" brRadius="0.6" mt="2.4">
    <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}' alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_project(detail);getViewCountproject(detail?._id,'project','projects');gs.history('history',detail._id,'project','projects','add-project-to-history')">
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1" h="1.9" (click)="redirect_to_project(detail)">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="1.9" mt="0.5">{{detail?.Basic_Details?.attributes[4]?.value}}</p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.5">
      <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[5]?.value}}</p>
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
    </div>
  </div>
</div>
<div *ngIf="type=='projectcardwithtoggle'">
  <div a-style br="0.5px solid #E4E4E4" h="27.4" w="43.0" p="1" brRadius="0.6" mt="2.4" position="relative">
    <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value}}' alt="" a-style h="18.2"
      w="41.0" brRadius="0.6" (click)="redirect_to_project(detail);getViewCount(detail?._id,'project','projects')">
    <div a-style display="flex" w="39" position="absolute" top="2" left="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_project_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteProject(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3" class="lineClamp1" h="1.9" (click)="redirect_to_project(detail);getViewCount(detail?._id,'project','projects')">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="1.9" mt="0.5">{{detail?.Basic_Details?.attributes[4]?.value}}</p>
    <div a-style display="flex" justify="space-between" align="center" mt="0.5">
      <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[5]?.value}}</p>
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
    </div>
  </div>
</div>
<div *ngIf="type=='projectrealated'">
  <div a-style w="43.0" h="10.3" br="0.5px solid #e4e4e4" brRadius="6px" p="1" >
    <div a-style display="flex" gap="1">
      <img src='https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}' a-style w="18.6" h="8.3" brRadius="0.6">
      <div a-style align="left">
          <p a-style color="#000000" fs="1.3" class="lineClamp1" >{{typeId?.basic?.name}}</p>
          <p a-style color="#707070" fs="1.3" class="lineClamp3"> {{detail?.Basic_Details?.attributes[0]?.value}}</p>
      </div>
    </div>
  </div>
</div>

<!-- usedcard -->
<div *ngIf="type=='usedcard'">
  {{detail | json}}
  <div a-style w="24" h="32.2" p="1" br="0.5px solid #E4E4E4" brRadius="0.6">
    <img src="https://new-api.mesbro.com/uploads//{{detail?.Media?.attributes[0].value}}" alt="" a-style w="22"
      h="22" (click)="redirect_to_used(detail);getViewCountused(detail?._id,'used','useds');gs.history('history',detail._id,'used','useds','add-used-to-history')">
    <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[1]?.value}}
      {{detail?.Basic_Details?.attributes[2]?.value}}</p>
    <p a-style fs="1.3" (click)="redirect_to_used(detail)" class="lineClamp" h="2"> {{detail?.Basic_Details?.attributes[0]?.value}}
    </p>
    <p a-style fs="1.3" class="lineClamp1" >{{detail?.Material_Available_Location?.attributes[3]?.value}}
      {{detail?.Material_Available_Location?.attributes[4]?.value}}
      {{detail?.Material_Available_Location?.attributes[5]?.value}}</p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3" mt="0.5">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
      </div>
      <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" alt="" a-style w="2" mt="0.5">
    </div>
  </div>
</div>
<div *ngIf="type=='usedcardwithtoggle'">
  <div a-style w="24" h="32.2" p="1" br="0.5px solid #E4E4E4" brRadius="0.6" position="relative">
    <img src="https://new-api.mesbro.com/uploads//{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style w="22"
      h="22" (click)="redirect_to_used(detail);getViewCount(detail?._id,'used','useds');gs.history('history',detail._id,'used','useds','add-used-to-history')">
    <div a-style display="flex" w="20" position="absolute" left="2" top="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_used_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteUsed(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[1]?.value}}
      {{detail?.Basic_Details?.attributes[2]?.value}}</p>
    <p a-style fs="1.3" (click)="redirect_to_used(detail)"> {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <p a-style fs="1.3">{{detail?.Material_Available_Location?.attributes[3]?.value}}
      {{detail?.Material_Available_Location?.attributes[4]?.value}}
      {{detail?.Material_Available_Location?.attributes[5]?.value}}</p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3" mt="0.5">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Rating_Grey.svg" alt="" a-style w="1.2" h="1.2">
      </div>
      <img src="../assets/images/Icon_Bank/Flag_Coloured.svg" alt="" a-style w="2" mt="0.5">
    </div>
  </div>
</div>


<div *ngIf="type=='refurbishedcard'">
  <div a-style w="24" h="32.2" p="1" br="0.5px solid #E4E4E4" brRadius="0.6">
    <img src="https://new-api.mesbro.com/uploads//{{detail?.Media?.attributes[0].value[0]}}" alt="" a-style w="22"
      h="22" (click)="redirect_to_used(detail)">
    <p a-style color="#2479AB" fs="1.3"></p>
    <p a-style fs="1.3" (click)="redirect_to_used(detail)"></p>
    <p a-style fs="1.3"></p>
  </div>
</div>


<!-- nurserycard -->
<div *ngIf="type=='nurserycard'">
  <div a-style w="24" h="32.2" p="1" br="0.5px solid #E4E4E4" brRadius="0.6" bg="#fff">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}" alt="" a-style w="22"
      h="22" (click)="redirect_to_nursery(detail);getViewCounNursery(detail?._id,'nursery','nurseries');gs.history('history',detail._id,'nursery','nurseries','add-nursery-to-history')">
    <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[2]?.value ?
      detail?.Basic_Details?.attributes[3]?.value : 'INR'}} {{detail?.Basic_Details?.attributes[4]?.value}} -
      {{detail?.Basic_Details?.attributes[5]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="3.6" (click)="redirect_to_nursery(detail)">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between" mt="0.8">
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style h="1.2"
        w="2.0">
    </div>
  </div>
</div>
<div *ngIf="type=='nurserycardwithtoggle'">
  <div a-style w="24" h="32.2" p="1" br="0.5px solid #E4E4E4" brRadius="0.6" bg="#fff" position="relative">
    <img src="https://new-api.mesbro.com/uploads/{{detail?.Media?.attributes[0]?.value[0]}}" alt="" a-style w="22"
      h="22" (click)="redirect_to_nursery(detail);getViewCount(detail?._id,'nursery','nurseries');gs.history('history',detail._id,'nursery','nurseries','add-nursery-to-history')">
    <div a-style display="flex" w="20" position="absolute" left="2" top="2" justify="space-between">
      <m-input #switch [type]="gs.enums.inputs.switch" [onlyInput]='true' [columns]='undefined'></m-input>
      <div a-style display="flex" gap="1">
        <img src="../assets/images/Icon_Bank/Edit_With_Circle_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="redirect_to_nursery_form(detail?._id)">
        <img src="../assets/images/Icon_Bank/Cancel_Coloured.svg" alt="" a-style w="2.5" h="2.5"
          (click)="deleteNursery(detail?._id)">
      </div>
    </div>
    <p a-style color="#2479AB" fs="1.3">{{detail?.Basic_Details?.attributes[2]?.value ?
      detail?.Basic_Details?.attributes[3]?.value : 'INR'}} {{detail?.Basic_Details?.attributes[4]?.value}} -
      {{detail?.Basic_Details?.attributes[5]?.value}}</p>
    <p a-style fs="1.3" class="lineClamp" h="3.6" (click)="redirect_to_nursery(detail)">
      {{detail?.Basic_Details?.attributes[0]?.value}}</p>
    <div a-style display="flex" justify="space-between">
      <div a-style display="flex" gap="0.3">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
        <img src="https://mesbro.com/assets/images/ME/Path%2073132%20(2).svg" a-style h="1.2" w="1.2">
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" alt="" a-style h="1.2"
        w="2.0">
    </div>
  </div>
</div>

<div *ngIf="type=='vehicleCard'">
  <div a-style br="0.5px solid #E4E4E4" w="43" h="27.4" p="1" brRadius="0.6" mt="2.4"
    (click)="redirect_to_Vehicle(detail)">
    <img src="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/{{detail?.basic?.coverImage[0][0]}}" alt="" a-style
      w="41" h="18.2">
    <p a-style fs="1.3">{{detail?.basic?.title}}</p>
    <p a-style fs="1.3" color="#B2B2B2">{{detail?.basic?.type?.name}}</p>
    <div a-style display="flex" justify="space-between" mt="0.5">
      <p a-style fs="1.3" color="#2479AB">INR {{detail?.basic?.['b21a3bc2-5b48-4c00-8d29-42dd5d3fbe91']?.value}}</p>
      <div a-style display="flex" gap="0.1" align="center">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
      </div>
    </div>
  </div>
</div>

<div *ngIf="type=='upcoming_vehicleCard'">
  <div a-style br="0.5px solid #E4E4E4" w="43" h="27.4" p="1" brRadius="0.6" mt="2.4"
    (click)="redirect_to_Vehicle(detail)">
    <img src="https://s3.ap-south-1.amazonaws.com/{{detail?.basic?.coverImage[0][0]}}" alt="" a-style w="41" h="18.2">
    <p a-style fs="1.3">{{detail?.basic?.title}}</p>
    <p a-style fs="1.3" color="#B2B2B2">{{detail?.basic?.type?.name}}</p>
    <div a-style display="flex" justify="space-between" mt="0.5">
      <p a-style fs="1.3" color="#2479AB">INR {{detail?.basic?.['b21a3bc2-5b48-4c00-8d29-42dd5d3fbe91']?.value}}</p>
      <div a-style display="flex" gap="0.1" align="center">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
        <img src="../assets/images/Icon_Bank/Favourite_Coloured.svg" alt="" a-style w="1.2" h="1.2">
      </div>
    </div>
  </div>
</div>

<div  *ngIf="types=='Help_Center1'">
  <div a-style h="23.1" w="43" brRadius=".6" br="0.5px solid #E4E4E4" p="1" position="relative"   (click)="redirect_to_helpCenter(detail)">
    <div a-style a-style h="18.2" w="41" bgUrl="../assets/images/Image_Bank/Cover_Image.svg" display="flex" justify="center" align="center">
      <p a-style fs="1.6" color="#FFFFFF" align="center">{{detail?.Basic_Detail?.attributes[0]?.value}}</p>
    </div>
    <p a-style color="#707070" fs="1.3" mt="1">Published on 22 Sept 2021, 00:00 AM by Bhaskar</p>
 </div>
</div>
<m-modal #spiritual height="50" padding="2.4 0">
   <div class="no_scroll_bar" a-style align="center" overflowX="auto" h="50">
    <ng-container *ngFor="let data of detail?.Basic_Details.attributes">
      <p a-style fs="1.3"  w="30" innerHTML="{{data.value}}" my="5"></p>
   </ng-container>
   </div>
</m-modal>