<div a-style align="center" px="2.4" m="2.4" >
  <div a-style w="6" h="6" brRadius="50%" br="1px solid black" bgPosition="center" bgUrl="https://s3.ap-south-1.amazonaws.com/files.mesbro.com/country-flags/india.svg" bgSize="cover">
  </div>
  <m-text [type]="gs.enums.texts.paragraph41" text="Welcome, {{users?.activeUser?.personal_details?.first_name}} {{users?.activeUser?.personal_details?.last_name}}" mt="2.4"></m-text>
  <m-text [type]="gs.enums.texts.paragraph38" mt=1.6 text="It's important to add the products in your organization. Having more products will increases your chances of showing up in buyers' search results. This means you'll potentially get more exposure and secure more customers as a result."></m-text>

</div>
<div a-style display=flex align=center flexWrap="wrap" justify=center >
  <m-generic-list [load_more]="true" *ngIf="application=='Product' && filter" [filters]="filter" add_card_width=40.5 [add_card]="true" add_item_text="Add Product" add_item_link="/dashboard/generic/form" application="Product" component="Product" type="product_card_2" px=2.4 gap=2.4 flexWrap=wrap ></m-generic-list>
  <m-generic-list [load_more]="true" *ngIf="application=='Subsidy' && filter" [filters]="filter" add_card_width=40.5 [add_card]="true" add_item_text="Add Subsidy" add_item_link="/dashboard/generic/form" application="Subsidy" component="Subsidy" type="product_card_2" px=2.4 gap=2.4 flexWrap=wrap></m-generic-list>
  <m-generic-list [load_more]="true" *ngIf="application=='Recipe' && filter" [filters]="filter" add_card_width=30.5 [add_card]="true" add_item_text="Add Recipe" add_item_link="/dashboard/generic/form" application="Recipe" component="Recipe" type="Card_1" px=2.4 gap=2.4 flexWrap=wrap align=center justify=center></m-generic-list>
  <m-generic-list [load_more]="true" *ngIf="application=='Vehicle' && filter" [filters]="filter" add_card_width=40.5 [add_card]="true" add_item_text="Add Vehicle" add_item_link="/dashboard/generic/form" application="Vehicle" component="Vehicle" type="card_1" px=2.4 gap=2.4 flexWrap=wrap></m-generic-list>
  <m-generic-list [load_more]="true" *ngIf="application=='Article' && filter" [filters]="filter" add_card_width=40.5 [add_card]="true" add_item_text="Add Article" add_item_link="/dashboard/generic/form" application="Article" component="Article" type="card_1" px=2.4 gap=2.4 flexWrap=wrap></m-generic-list>
</div>

<!-- pradeep code -->
<div *ngIf="params.type==''">
  <div>
    <div a-style pl="2.4" py="1.6" pr="4" brBottom="1px solid #e4e4e4">
     <div a-style display="flex" justify="space-between" gap="3" align="center">
       <div a-style display="flex" gap="2" align="center">
        <div a-style h="5.2">
          <img a-style w="5.2" h="5.2"  src="../assets/images/assets/chat1.svg">
       </div>
       <div a-style flexGrow="1">
         <div a-style align="left">
          <p a-style mb=0.5 fs=2 color=#333333>Bharat Furniture Pvt. Ltd.</p>
         </div>
         <div a-style display="flex" gap="2" align="center">
          <div>
            <div a-style display="flex" gap="0.8" align="center">
              <img a-style w="1.6" h="1.6"  src="../assets/images/assets/card3.svg">
              <p a-style mb=0 fs="1.3" color=#333333>MBIN : <span a-style color="#05A6F0">98756789766789</span></p>
            </div>
          </div>
          <div>
            <div a-style display="flex" gap="0.8" align="center">
              <img a-style w="1.6" h="1.6"  src="../assets/images/assets/watch-product.svg">
              <p a-style mb=0 fs="1.3" color=#333333>Validity : <span a-style color="#F35325">Subscription expired</span></p>
            </div>
          </div>
         </div>
       </div>
       </div>
       <div>
        <div a-style>
          <m-button a-style h="3" py="0.5" px="1.4" bg="#FFF0EC"  [types]="gs.enums.buttons.button91" text="Renew your subscription" routerLink='home/compose' [queryParams]="{action:'compose'}" ></m-button>
        </div>
       </div>
     </div>
    </div>
    <div a-style p="2.4" display="flex" align="center" gap="2" justify="space-between">
     <div a-style w="90">
      <div a-style h="57">
        <p a-style mb=1 fs=2.4 color=#707070></p>
       </div>
       <div a-style mb=1 align="left">
        <p a-style  fs=2.4 color=#707070>What happens if you do nothing</p>
       </div>
        <div a-style align="left">
        <p a-style  fs=1.3 color=#707070>Lorem ipsum dolor sit amet di inciduicabo laudantium non sint. Vitae pariatur, iusto inciduicabo laudantium non sint. Vitae pariatur, iu inciduicabo laudantium non sint. Vitae pariatur, iu inciduicabo laudantium non sint. Vitae pariatur, iu inciduicabo laudantium non sint. Vitae pariatur, iu eligendi facilis, quos, cumque ipsum laudantiumpariatur officia enim veniam similique?</p>
        </div>
     </div>
     <div>
      <div a-style>
        <img a-style brRadius="0.6" br="1px solid #e4e4e4"  w="30" h="auto"  src="../assets/images/assets/add-product-landing.gif">
     </div>
     </div>
    </div>
  </div>
</div>
