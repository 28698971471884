import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';


@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {
  count :any = new Subject();
  selected_org :any = new Subject();
  $refresh_compare = new Subject();
  constructor() { }
}
