<div>
<div *ngIf="!filter" a-style position="relative">
    <ng-container [ngTemplateOutlet]="dropDown"
        [ngTemplateOutletContext]="{passedData:{label:'Search In Folder' ,br:'1px solid #e2e2e2',text:'All', p:'1' }}">
    </ng-container>
    <div a-style display="flex" gap="2">
        <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'From' , mb:'1'}}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'To', mb:'1' }}">
        </ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'Cc' , mb:'1'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'Subject' , mb:'1'}}">
    </ng-container>
    <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'Keyword', mb:'1' }}">
    </ng-container>

    <div display="flex" gap="2" a-style>
        <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'Start Date', mb:'1' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{passedData:{label:'End Date' , mb:'1'}}">
        </ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Attachment' }}">
    </ng-container>
</div>

    <div *ngIf="filter" a-style bg="#FFFFFF">
        <p a-style fs="1.3" mt="2" mb="1.5">When a message is an exact match for your search criteria</p>
        <ng-container [ngTemplateOutlet]="checktext"
            [ngTemplateOutletContext]="{passedData:{text:'Skip the Inbox (Archive it)' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Mark as read' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Star it' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Apply the label' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Forward it' }}">
        </ng-container>
        <!-- <p a-style fs="1.3" color="#006BFF" mt="1">Add forwarding address</p> -->
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Delete it' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext"
            [ngTemplateOutletContext]="{passedData:{text:'Never send it to spam' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext"
            [ngTemplateOutletContext]="{passedData:{text:'Always mark it as important' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext"
            [ngTemplateOutletContext]="{passedData:{text:'Never mark it as important' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Apply the label' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext" [ngTemplateOutletContext]="{passedData:{text:'Categorize as' }}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="checktext"
            [ngTemplateOutletContext]="{passedData:{text:'Also apply filter to 0 matching conversations' }}">
        </ng-container>
    </div>

    <div a-style display="flex" justify="flex-end">
        <button a-style py="0.8" px="1.6" br="1px solid #E4E4E4" color="#707070" brRadius="0.6" bg="white"
            mr="1.6" (click)="closeModal()">Cancel</button>
        <button a-style py="0.8" px="2" br="1px solid #E4E4E4" brRadius="0.6" bg="#006BFF" color="#FFFFFF"
            (click)="filter=true">Create
            filter</button>
    </div>
</div>

<ng-template #dropDown let-passedData="passedData">
    <label a-style fs="1.3">{{passedData.label}}</label>
    <div a-style minWidth=15 mt="1" mb="1" [p]="passedData.p" display="flex" justify="space-between" align="center"
        brRadius="0.4" [br]="passedData.br" maxWidth="100%">
        <p a-style fs="1.3">{{passedData.text}}</p>
        <img src="https://mesbro.in/assets/images/down-arrow-black.svg" a-style w="0.9">
    </div>
</ng-template>
<ng-template #input let-passedData="passedData">
    <div a-style [mb]="passedData.mb">
        <p a-style fs="1.3" mb="1">{{passedData.label}}</p>
        <div a-style br="1px solid #e2e2e2" brRadius="0.4" p="1" maxWidth="100%">
            <input a-style br="none" maxWidth="100%">
        </div>
    </div>
</ng-template>
<ng-template #checktext let-data="passedData">
    <div a-style display="flex" flexGap="0.8">
        <input type="checkbox" a-style h="1.8" w="1.8" />
        <p a-style mb="1.5" fs="1.3">{{ data.text }}</p>
    </div>
</ng-template>