import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';

@Component({
  selector: 'm-modal',
  templateUrl: './modal.component.html',
  styles: [
  ]
})
export class ModalComponent implements OnInit, AfterViewInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() height: number = 0;
  @Input() width: number = 50;
  @Input() is_visible: boolean = false;
  @Input() fs: string = ""
  @Input() close_on_overlay: boolean = true;
  @Input() close_button = true;
  @Input() maxHeight = true;
  @Input() top: any;
  @Input() bottom: any;
  @Input() h: any;




  first_time = true;

  constructor(public gs: GlobalService) {

  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.gs.$click.subscribe(event => this.click_event(event));
    this.gs.$keyup.subscribe(event => this.escape_event(event));
  }

  openModal() {
    console.log('Open Modal Called')
    this.is_visible = true;
  }

  closeModal() {
    this.is_visible = false;
    this.first_time = true;
    // console.log('close Modal',this.is_visible);
  }

  toggleModal() {
    this.is_visible = !this.is_visible;
  }

  click_event(event: any) {
    if (!this.first_time) {
      let is_modal = false;
      event.path.forEach((path: any) => {
        let class_list = path.classList?.value;
        if (class_list?.includes('modal')) {
          is_modal = true;
        }
      });
      if (!is_modal) {
        this.first_time = true;
        this.closeModal()
      }
    } else {
      this.first_time = false;
    }

  }

  escape_event(event: any) {
    if (event.code == 'Escape') {
      this.closeModal()
    }
  }
  
}
