import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/mesbro/services/global.service';
import { search_items } from 'src/mesbro/modules/shared/generic-search/search';
@Component({
  selector: 'm-search-header',
  templateUrl: './search-header.component.html',
  styles: [
  ]
})
export class SearchHeaderComponent implements OnInit {
  search_items = search_items;
  constructor( public gs : GlobalService) { }

  ngOnInit(): void {
  }

}
