<div a-style display=flex flexDirection=column  justify=space-between w="100%" h="calc(100vh )" overflowY=scroll

    [class]="'no_scroll_bar'? 'no_scroll':'no_scroll_bar'" >

    <m-header a-style zIndex=1000

        *ngIf="!gs.router.url.includes('idm/sign-up') && !gs.router.url.includes('idm/forgot-username') && !gs.router.url.includes('idm/forgot-password') && !gs.router.url.includes('idm/change-password') && !gs.router.url.includes('idm/otp') && !gs.router.url.includes('idm/sign-in') && !gs.router.url.includes('gdashboard') ">

    </m-header>

    <div a-style flexGrow="1">

        <router-outlet></router-outlet>

    </div>

    <m-footer a-style  flex-direction="column"  justify="flex-end"

        *ngIf="!gs.router.url.includes('chat')  && !gs.router.url.includes('website') && !gs.router.url.includes('central')  && !gs.router.url.includes('mail')  && !gs.router.url.includes('social')  && !gs.router.url.includes('contact')

        && !gs.router.url.includes('help') && !gs.router.url.includes('product') && !gs.router.url.includes('idm/sign-up') &&!gs.router.url.includes('idm/otp')

        &&!gs.router.url.includes('group/website') && !gs.router.url.includes('group/brand') && !gs.router.url.includes('gdashboard')

         && !gs.router.url.includes('idm/user/details') && !gs.router.url.includes('/service/home') && !gs.router.url.includes('/recipe/home') && !gs.router.url.includes('org/organization-form') && !gs.router.url.includes('/me/form')

         && !gs.router.url.includes('/vehicles/home') && !gs.router.url.includes('/article/home') && !gs.router.url.includes('/service/Detail') && !gs.router.url.includes('/scrap/home') && !gs.router.url.includes('/guardian/home')

         && !gs.router.url.includes('/spiritual/home')  && !gs.router.url.includes('/project/home')  && !gs.router.url.includes('/nursery/home')  && !gs.router.url.includes('/used/home')
         
         && !gs.router.url.includes('/refurbished/home') && !gs.router.url.includes('/jobs/job-detail') && !gs.router.url.includes('vehicle/detail') && !gs.router.url.includes('project/project-detail') && !gs.router.url.includes('/properties/properties/detail') && !gs.router.url.includes('jobs/home') && !gs.router.url.includes('properties/home') && !gs.router.url.includes('/guardian/guardian-detail') && !gs.router.url.includes('/org/organization-address') && !gs.router.url.includes('/org/organization-leads') && gs.router.url.includes('/product/history')">

    </m-footer>

</div>